<div class="myBody myBodySub">
  <div class="myHeaderBase d-flex justify-content-center">
    <div class="myHeaderCore">
      <div>
        <!-- <img src='../../../../../assets/images/thumbnail_ec22logo.jpg' class="ecLogo"> -->
      </div>
    </div>
  </div>
  <div class="myMain">
    <div clas="myMainLeft">
      <div class="myMainHalfTop">
        <!-- Left Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Left Bottom -->
      </div>
    </div>
    <div class="myMainCenter">
      <div class="myMainHalfTop d-flex justify-content-center">
        <!-- Main Top -->
        <mat-card class="mainCard b-radius">
          <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
          <div *ngIf="!loaded">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <form [formGroup]="myFormGroup" (ngSubmit)="onSubmit()">
            <div class="float-end stepNumber">4/8</div>
            <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Transportation':
              '交通手段'}}
              <i class="stepTitleSub">
                {{this.router.url.includes('EN')==true?'Required': '必須'}}
              </i>
            </h2>



            <div class="stepTitle2nd">
              <div *ngIf="this.router.url.includes('EN')!=true">
                {{'会場へのアクセス方法はホームページでご確認ください。'}}
                <a href="https://globalreturnees.org/grc23/index.php?option=com_content&view=article&id=216&Itemid=373&lang=ja"
                  target="_blank">
                  {{ 'アクセス' }}
                </a>
              </div>
              <div *ngIf="this.router.url.includes('EN')==true">
                {{'Please check the website for directions to the conference site.'}}
                <a href="https://globalreturnees.org/grc23/index.php?option=com_content&view=article&id=217:2015-04-23-01-31-53&catid=19&lang=en&Itemid=705"
                  target="_blank">
                  {{ 'Access' }}
                </a>
              </div>
            </div>



            <div class="stepTitle2nd">
              {{this.router.url.includes('EN')==true?'To the conference site':
              '会場まで'}}
              </div>
            <div class="registrationInputFormLg">
              <mat-radio-group formControlName="myTransportationMethodComing"
                  (change)="onChangeTransportationComingSelection($event)" class="radioBtnMain" >
                　<mat-radio-button class="radioBtnItem" value="Car"
                  [checked]="conference.TransportationMethodComing=='car'">
                      {{this.router.url.includes('EN')==true?'Car': '車'}}</mat-radio-button>
                      <mat-radio-button class="radioBtnItem"  value="GRC direct bus"
                      [checked]="conference.TransportationMethodComing=='GRC direct bus'">
                          {{this.router.url.includes('EN')==true?'GRC direct bus': 'GRC直行バス'}}
                      </mat-radio-button>
                  <mat-radio-button class="radioBtnItem" value="Train"
                  [checked]="conference.TransportationMethodComing=='train'">
                      {{this.router.url.includes('EN')==true?'public transportation: Train': '公共交通機関：電車'}}</mat-radio-button>

                  <mat-radio-button class="radioBtnItem"  value="Express bus"
                  [checked]="conference.TransportationMethodComing=='Express bus'">
                    {{this.router.url.includes('EN')==true?'public transportation: Express bus': '公共交通機関：高速バス'}}
                </mat-radio-button>
              </mat-radio-group>
              <mat-error *ngIf="myFormGroup.get('myTransportationMethodComing').hasError('required') && myFormGroup.get('myTransportationMethodComing').touched">
                  {{this.router.url.includes('EN')==true?'Required': '未入力'}}
              </mat-error>
              <div >
                <div *ngIf="conference.TransportationMethodComing!=null && conference.TransportationMethodComing=='GRC direct bus'">
                  <div class="stepTitle2nd">
                    {{this.router.url.includes('EN')==true?
                    'Outward: May 3rd (meet at 12:30, scheduled departure at 13:00, 3,300 yen for adult, 1,650 yen for child)'
                    :
                    '往路:5月3日(12:30集合 13:00出発予定 大人3,300円 子供1,650円)'
                  }}
                  </div>
                  <div class="stepTitle2nd">
                    <div>
                      <mat-form-field appearance="outline"  class="registrationInputForm">
                        <mat-label>{{this.router.url.includes('EN')==true?
                          'Number of adult':'大人の人数'}}</mat-label>
                        <input matInput formControlName="myGRCDirectBusAdultNumberComing" type="number" placeholder="Enter number"
                          min="0" (change)="onChangeNumberOfGRCDirectBusAdultNumberComing($event)">
                        <span matSuffix>名</span>
                      </mat-form-field>&nbsp;&nbsp;
                      <mat-form-field appearance="outline"  class="registrationInputForm">
                        <mat-label>{{this.router.url.includes('EN')==true?
                          'Number of child':'子供の人数'}}</mat-label>
                        <input matInput formControlName="myGRCDirectBusChildNumberComing" type="number" placeholder="Enter number"
                          min="0" (change)="onChangeNumberOfGRCDirectBusChildNumberComing($event)">
                        <span matSuffix>名</span>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <br><br>
          <div class="stepTitle2nd">
            {{this.router.url.includes('EN')==true?'From the conference site':
            '会場から'}}
            </div>
          <div class="registrationInputFormLg">
            <mat-radio-group formControlName="myTransportationMethodReturning"
                (change)="onChangeTransportationReturningSelection($event)" class="radioBtnMain" >
                <mat-radio-button class="radioBtnItem" value="Car"
                [checked]="conference.TransportationMethodReturning=='car'">
                    {{this.router.url.includes('EN')==true?'Car': '車'}}</mat-radio-button>
                    <mat-radio-button class="radioBtnItem"  value="GRC direct bus"
                    [checked]="conference.TransportationMethodReturning=='GRC direct bus'">
                        {{this.router.url.includes('EN')==true?'GRC direct bus': 'GRC直行バス'}}
                    </mat-radio-button>
                <mat-radio-button class="radioBtnItem" value="Train"
                [checked]="conference.TransportationMethodReturning=='Train'">
                    {{this.router.url.includes('EN')==true?'public transportation: Train': '公共交通機関：電車'}}</mat-radio-button>

                <mat-radio-button class="radioBtnItem"  value="Express bus"
                [checked]="conference.TransportationMethodReturning=='Express bus'">
                  {{this.router.url.includes('EN')==true?'public transportation: Express bus': '公共交通機関：高速バス'}}
              </mat-radio-button>
            </mat-radio-group>
            <mat-error *ngIf="myFormGroup.get('myTransportationMethodReturning').hasError('required') && myFormGroup.get('myTransportationMethodReturning').touched">
                {{this.router.url.includes('EN')==true?'Required': '未入力'}}
            </mat-error>
            <div >
              <div *ngIf="conference.TransportationMethodReturning!=null && conference.TransportationMethodReturning=='GRC direct bus'">
                <div class="stepTitle2nd">
                  {{this.router.url.includes('EN')==true?
                  'Return trip: May 6th(meet at 13:30, scheduled departure at 14:00, 3,300 yen for adult, 1,650 yen for child)'
                  :
                  '復路:5月6日(13:30集合 14:00出発予定 大人3,300円 子供1,650円)'
                }}
                </div>
                <div class="stepTitle2nd">
                  <div>
                    <mat-form-field appearance="outline" class="registrationInputForm">
                      <mat-label>{{this.router.url.includes('EN')==true?
                        'Number of adult':'大人の人数'}}</mat-label>
                      <input matInput formControlName="myGRCDirectBusAdultNumberReturning" type="number" placeholder="Enter number"
                        min="0" (change)="onChangeNumberOfGRCDirectBusAdultNumberReturning($event)">
                      <span matSuffix>名</span>
                    </mat-form-field>&nbsp;&nbsp;
                    <mat-form-field appearance="outline" class="registrationInputForm">
                      <mat-label>{{this.router.url.includes('EN')==true?
                        'Number of child':'子供の人数'}}</mat-label>
                      <input matInput formControlName="myGRCDirectBusChildNumberReturning" type="number" placeholder="Enter number"
                        min="0" (change)="onChangeNumberOfGRCDirectBusChildNumberReturning($event)">
                      <span matSuffix>名</span>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <br><br>  <br><br><br><br>
            <!-- <div class="position-absolute bottom-0 start-50 translate-middle-x"> -->

                <div class="d-flex justify-content-center">
                  <button type="button" class="btn btnNext" (click)="onClickGoBack()">
                    {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                  </button>
                  <button  [disabled]="!myFormGroup.valid"   type="button" class="btn btnNext"
                    (click)="onClickGoForward()">
                    {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                  </button>
                </div>
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn link btnCancel" (click)="cancelProcess()">Cancel</button>
                </div>

            <!-- </div> -->
          </form>
        </mat-card>
      </div>
      <div class="myMainHalfBottom">
        <!-- Main Bottom -->
      </div>
    </div>
    <div class="myMainRight">
      <div class="myMainHalfTop">
        <!-- Right Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Right Bottom -->
      </div>
    </div>
  </div>
  <!-- <footer>footer</footer> -->
</div>
