import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../../../services/auth/auth.service";
import { User } from "../../../models/user";
import { UserService } from "../../../services/user/user.service";
import { PaymentService} from "../../../services/payment/payment.service";
import { MatRadioChange } from '@angular/material/radio';
import { MatStepper } from '@angular/material/stepper';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
//For Dialog
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA , MatDialogConfig} from '@angular/material/dialog';
import { CheckHavingAccountComponent} from "../../dialog/check-having-account/check-having-account.component";
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { TOOLTIP_PANEL_CLASS } from '@angular/material/tooltip';
import {PaymentMaster, LineItem, RecurringPayment} from "../../../models/payment/paymentMaster";

@Component({
  selector: 'app-regular-top',
  templateUrl: './regular-top.component.html',
  styleUrls: ['./regular-top.component.css']
})
export class RegularTopComponent implements OnInit {
  mySelectedLanguage:string;
  firstFormGroup: FormGroup;
  toBeRegularMember:boolean;
  loaded:boolean;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  myMessage: string;

  user: User = {
    PasswordHash: null,
    Id: null,
    UserName: null,
    Email: null,
    FirstName: null,
    LastName: null,
    Password: null,
    Prefix: null,
    Shimei: null,
    Myoji: null,
    Street: null,
    Street2: null,
    City: null,
    State: null,
    Zip: null,
    Country: null,
    Yubin_Bango: null,
    To_Do_Fu_Ken: null,
    Shi_Gun_Ku: null,
    Cho_Son: null,
    Apartment_Etc: null,
    To_Do_Fu_Ken_JP: null,
    Shi_Gun_Ku_JP: null,
    Cho_Son_JP: null,
    Apartment_Etc_JP: null,
    Gender: null,
    DOB: null,
    TelNo: null,
    TimezoneName: null,
    UTCdiff: null,
    Token: null,
    Occupation: null,
    MemberStatus: null,
    Married: null,
    Christian: null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount: null,
    RegisteredDate: null,
    LastAccessedDate: null,

    //From siteground
    LoginId: null,
    MiddleNameEN: null,
    MiddleNameJP: null,
    PrefixJP: null,
    OccupationDetail: null,
    Organization: null,
    LivingCountry: null,
    PhoneEN: null,
    PhoneMobileEN: null,
    PhoneJP: null,
    PhoneMobileJP: null,
    ChurchNameEN: null,
    ChurchNameJP: null,
    MemberType: null,
    MemberDetail: null,
    MemberMemo: null,
    Shokaisha: null,
    Reason: null,
    NewsLetters: null,
    StaffReports: null,
    OtherLetters: null,
    NewsLetterLanguage: null,
    BasicRoles: null,
    //Added new
    MigrateFromSiteGround: null,
    AdminAccess: null,
    JCFNAccess: null,
    SupportingMember: null,
    LastAddressUpdatedDate: null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };

  constructor(
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    public authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog,
    private paymentService:PaymentService
  ) { }

  ngOnInit(): void {
    this.mySelectedLanguage = this.router.url.includes('EN')==true?"EN":"JP";
    this.firstFormGroup = this._formBuilder.group({
      myAcceptRegularMember: ['', Validators.required],
    });

    if (sessionStorage.getItem("loginUserName") != null) {
      var loginUser = sessionStorage.getItem("loginUserName")
      this.loaded = false;
      this.userService.getUserProfile(loginUser).subscribe(result => {
        this.user = result;
        if (this.user != null) {
          if(this.user.MembershipType!=null && this.user.MembershipType.toLowerCase()=='regular'){
            this.toBeRegularMember=true;
          }else{
            this.toBeRegularMember=false;
          }
        }
        this.loaded=true;
      });
    } else {
      this.loaded = true;
    }
    this.user.Id = sessionStorage.getItem("loginUserId");
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

//   onClickProcessLogin(){
//     const dialogConfig = new MatDialogConfig();
//     dialogConfig.disableClose = true;
//     dialogConfig.autoFocus = false;
//     dialogConfig.maxHeight='550px'; //Desktop
//     dialogConfig.maxWidth='500px';//Desktoop
//     dialogConfig.height='100%';//Mobile
//     dialogConfig.width='100%';//Mobile
//     dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
//  //   dialogConfig.data={ Id: this.user.Id };
//     const  dialogRef = this.dialog.open(CheckHavingAccountComponent,dialogConfig);
//     dialogRef.afterClosed().subscribe(result => {
//       var redirectPage = result;
//     //  this.redirectPage(this.codeMaster.CodeType);
//       if(result!=null){
//         console.log("Confirmed Action: "+redirectPage);
//       }
//     })
//   }
onClickBecomeRegularMember(){
  if(this.toBeRegularMember==true){
    this.user.MembershipType="Regular";
  }else{
    this.user.MembershipType=null;
  }
  this.userService.updateMembershipType(this.user).subscribe(
    data => {
      console.log('success in updating user');
      this.myMessage = this.getMyLanguage()=="EN"?"Your membership status updated successfully.":"メンバーシップ情報が更新されました。";
      this.openSnackBar('success', 'close');
      this.loaded = true;
      this.router.navigate(["/members/myProfile/" +this.getMyLanguage()]);
    },
    error => {
      this.myMessage = this.getMyLanguage()=="EN"?"Data save failed.":"問題が生じデータが更新されませんでした。";
      this.openSnackBar('error', 'close');
      this.loaded = true;
    }
  );
}
  onClickGoBack(){
    this.router.navigate(["/members/selectMemberType/" +this.getMyLanguage()]);
  }

  onClickProcessRegularMember(){
    alert('save regular member and go to my account')
  }

  onChangeAgree(value: boolean) {
    this.userService.updateAcceptMembershipTerm(this.user).subscribe(data => {
    });
    console.log('toBeRegularMember: '+this.toBeRegularMember);
}

openSnackBar(message: string, action: string) {
  if (message == "success") {
    this._snackBar.open(this.myMessage, action, {
      duration: 5000,
      panelClass: ["customPanel_Success"],
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  } else if (message = "error") {
    this._snackBar.open(this.myMessage, action, {
      duration: 20000,
      panelClass: ["customPanel_Error"],
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}

}
