import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../../services/auth/auth.service";
import { User } from "../../models/user";
import { UserService } from "../../services/user/user.service";
import { PaymentService} from "../../services/payment/payment.service";
import { MatRadioChange } from '@angular/material/radio';
import { MatStepper } from '@angular/material/stepper';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
//For Dialog
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA , MatDialogConfig} from '@angular/material/dialog';
import { LoginMessageComponent } from '../dialog/login-message/login-message.component';
import { UserEditMyProfileComponent} from '../dialog/user-edit-my-profile/user-edit-my-profile.component';
import { UserEditOthersProfileComponent } from '../dialog/user-edit-others-profile/user-edit-others-profile.component';
import { DeleteConfirmationComponent} from '../dialog/delete-confirmation/delete-confirmation.component';
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { TOOLTIP_PANEL_CLASS } from '@angular/material/tooltip';
import {PaymentMaster, LineItem, RecurringPayment} from "../../models/payment/paymentMaster";

// import * as Timeline from './AcceptUI.js';
// declare var Timeline: any;

interface MyList{
	display:string;
  value:string;
}

@Component({
  selector: 'app-give',
  templateUrl: './give.component.html',
  styleUrls: ['./give.component.css']
})
export class GiveComponent implements OnInit {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  forthFormGroup:FormGroup;

  myPaymentNonce:string="";
  dataDescriptor:string;
  dataValue:string;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  myMessage:string;
  step = 0;
  mySelectedLanguage: string;
  loaded: boolean = true;
  searchingRegistrant: string;
  showCreditCardPayment:boolean=false;
  showBankPayment:boolean=false;
  deleting:boolean=false;
  strCommittee:string;

  loading:boolean=false;
  AttendJapanRegionalNetworkAppliedNameJP:string;
  AttendJapanRegionalNetworkAsignedNameJP:string;
  clickedNextFromFirstFormGroup:boolean=false;

  firstFormIsValid:boolean=false;
  showRecurringGroup:boolean=false;
  clickedPaymentInfo:boolean=false;
  successfulPaymentInput:boolean=false;

  public memberTypes = [
    { value: "", display: "Select" },
    { value: "RegularMember", display: "Regular Member" },
    { value: "SpecialMember", display: "Special Member" }
  ];
  public intervals = [
    { value: "", display: "Select" },
    { value: "monthly", display: "Monthly" },
    { value: "yearly", display: "Yearly" }
  ];

  user: User = {
    PasswordHash:null,
    Id: null,
    UserName: null,
    Email: null,
    FirstName: null,
    LastName: null,
    Password: null,
    Prefix: null,
    Shimei: null,
    Myoji: null,
    Street: null,
    Street2: null,
    City: null,
    State: null,
    Zip: null,
    Country: null,
    Yubin_Bango: null,
    To_Do_Fu_Ken: null,
    Shi_Gun_Ku: null,
    Cho_Son: null,
    Apartment_Etc: null,
    To_Do_Fu_Ken_JP: null,
    Shi_Gun_Ku_JP: null,
    Cho_Son_JP: null,
    Apartment_Etc_JP: null,
    Gender: null,
    DOB: null,
    TelNo: null,
    TimezoneName: null,
    UTCdiff: null,
    Token: null,
    Occupation: null,
    MemberStatus: null,
    Married: null,
    ZoomAccount: null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    RegisteredDate: null,
    LastAccessedDate: null,
        //From siteground
        LoginId:null,
        MiddleNameEN:null,
        MiddleNameJP:null,
        PrefixJP:null,
        OccupationDetail:null,
        Organization:null,
        LivingCountry:null,
        PhoneEN:null,
        PhoneMobileEN:null,
        PhoneJP:null,
        PhoneMobileJP:null,
        ChurchNameEN:null,
        ChurchNameJP:null,
        MemberType:null,
        MemberDetail:null,
        MemberMemo:null,
        Shokaisha:null,
        Reason:null,
        NewsLetters:null,
        StaffReports:null,
        OtherLetters:null,
        NewsLetterLanguage:null,
        BasicRoles:null,
        //Added new
        MigrateFromSiteGround:null,
        AdminAccess:null,
        JCFNAccess:null,
        SupportingMember:null,
        LastAddressUpdatedDate: null,
        AcceptMembershipTerm:null,
        MembershipType:null
  };
  lineItem: LineItem = {
    itemId: 0,
    name: null,
    description: null,
    quantity: 0,
    unitPrice: 0
  }
  lineItems: LineItem[] = [];
  recurringPayment: RecurringPayment = {
    RecurringPaymentId: 0,
    RecurringId: null,
    Interval: null,
    StartDate: null,
    EndDate: null,
    CreatedDate: null
  }
  paymentMaster: PaymentMaster = {
    PaymentMasterId: 0,
    FirstName: null,
    LastName: null,
    Email: null,
    DataDescriptor: null,
    DataValue: null,
    ItemName: null,
    Amount: null,
    Recurring: null,
    RecurringId: null,
    CreatedDate: null,
    ProviderName:null,
    lineItems: this.lineItems,
    recurringPayment: this.recurringPayment
  }

  states: MyList[] =
    [
      {
        "display": "Alabama",
        "value": "AL"
      },
      {
        "display": "Alaska",
        "value": "AK"
      },
      {
        "display": "American Samoa",
        "value": "AS"
      },
      {
        "display": "Arizona",
        "value": "AZ"
      },
      {
        "display": "Arkansas",
        "value": "AR"
      },
      {
        "display": "California",
        "value": "CA"
      },
      {
        "display": "Colorado",
        "value": "CO"
      },
      {
        "display": "Connecticut",
        "value": "CT"
      },
      {
        "display": "Delaware",
        "value": "DE"
      },
      {
        "display": "District Of Columbia",
        "value": "DC"
      },
      {
        "display": "Federated States Of Micronesia",
        "value": "FM"
      },
      {
        "display": "Florida",
        "value": "FL"
      },
      {
        "display": "Georgia",
        "value": "GA"
      },
      {
        "display": "Guam",
        "value": "GU"
      },
      {
        "display": "Hawaii",
        "value": "HI"
      },
      {
        "display": "Idaho",
        "value": "ID"
      },
      {
        "display": "Illinois",
        "value": "IL"
      },
      {
        "display": "Indiana",
        "value": "IN"
      },
      {
        "display": "Iowa",
        "value": "IA"
      },
      {
        "display": "Kansas",
        "value": "KS"
      },
      {
        "display": "Kentucky",
        "value": "KY"
      },
      {
        "display": "Louisiana",
        "value": "LA"
      },
      {
        "display": "Maine",
        "value": "ME"
      },
      {
        "display": "Marshall Islands",
        "value": "MH"
      },
      {
        "display": "Maryland",
        "value": "MD"
      },
      {
        "display": "Massachusetts",
        "value": "MA"
      },
      {
        "display": "Michigan",
        "value": "MI"
      },
      {
        "display": "Minnesota",
        "value": "MN"
      },
      {
        "display": "Mississippi",
        "value": "MS"
      },
      {
        "display": "Missouri",
        "value": "MO"
      },
      {
        "display": "Montana",
        "value": "MT"
      },
      {
        "display": "Nebraska",
        "value": "NE"
      },
      {
        "display": "Nevada",
        "value": "NV"
      },
      {
        "display": "New Hampshire",
        "value": "NH"
      },
      {
        "display": "New Jersey",
        "value": "NJ"
      },
      {
        "display": "New Mexico",
        "value": "NM"
      },
      {
        "display": "New York",
        "value": "NY"
      },
      {
        "display": "North Carolina",
        "value": "NC"
      },
      {
        "display": "North Dakota",
        "value": "ND"
      },
      {
        "display": "Northern Mariana Islands",
        "value": "MP"
      },
      {
        "display": "Ohio",
        "value": "OH"
      },
      {
        "display": "Oklahoma",
        "value": "OK"
      },
      {
        "display": "Oregon",
        "value": "OR"
      },
      {
        "display": "Palau",
        "value": "PW"
      },
      {
        "display": "Pennsylvania",
        "value": "PA"
      },
      {
        "display": "Puerto Rico",
        "value": "PR"
      },
      {
        "display": "Rhode Island",
        "value": "RI"
      },
      {
        "display": "South Carolina",
        "value": "SC"
      },
      {
        "display": "South Dakota",
        "value": "SD"
      },
      {
        "display": "Tennessee",
        "value": "TN"
      },
      {
        "display": "Texas",
        "value": "TX"
      },
      {
        "display": "Utah",
        "value": "UT"
      },
      {
        "display": "Vermont",
        "value": "VT"
      },
      {
        "display": "Virgin Islands",
        "value": "VI"
      },
      {
        "display": "Virginia",
        "value": "VA"
      },
      {
        "display": "Washington",
        "value": "WA"
      },
      {
        "display": "West Virginia",
        "value": "WV"
      },
      {
        "display": "Wisconsin",
        "value": "WI"
      },
      {
        "display": "Wyoming",
        "value": "WY"
      }
  ];

  constructor(
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    public authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog,
    private paymentService:PaymentService
  ) { }

  myStepper:MatStepper;

  ngOnInit(): void {
    this.mySelectedLanguage = this.router.url.includes('EN') == true ? "EN" : "JP";
    this.firstFormGroup = this._formBuilder.group({
      myMemberType:['', Validators.required],
      myAmount: ['', Validators.required],
      myInterval: [''],
      myStartDate: [''],
    });
    this.secondFormGroup = this._formBuilder.group({
      myFirstName: ['', Validators.required],
      myLastName: ['', Validators.required],
      myEmail: ['', Validators.required],
    });
    this.thirdFormGroup = this._formBuilder.group({
    });
    this.forthFormGroup = this._formBuilder.group({
    });


    // this.myPaymentNonce = this.route.snapshot.params["MyPaymentNonce"];
    // var splitString: string[];
    // if (this.myPaymentNonce != null) {
    //   splitString = this.myPaymentNonce.split("&");
    //   this.dataDescriptor = splitString[0];
    //   this.dataValue = splitString[1];
    //   // if(this.dataDescriptor!="" && this.dataValue!=""){
    //   //   alert('OK')
    //   //   this.successfulPaymentInput=true;
    //   //   this.myMessage = this.router.url.includes('EN') == true ? "Payment Infor Input Successful" :
    //   //   "カード情報入力成功";
    //   //  this.openSnackBar('success', 'close');
    //   // }else{
    //   //   this.successfulPaymentInput=false;
    //   // }
    // } else {
    //   this.dataDescriptor = "";
    //   this.dataValue = "";
    // }
    sessionStorage.removeItem("myDataDescriptor");
    sessionStorage.removeItem("myDataValue");
    // this.dataDescriptor = sessionStorage.getItem("myDataDescriptor");
    // this.dataValue = sessionStorage.getItem("myDataValue");


    console.log("dataDescriptor: "+this.dataDescriptor);
    console.log("dataValue: "+this.dataValue);
  }

  getMyLanguage(): string {
    return this.router.url.includes('EN') == true ? 'EN' : 'JP';
  }

  isPaymentInfoValid(): boolean {
    if (this.dataDescriptor!=null &&this.dataValue!=null) {
      return true
    }
    return false;
  }

  goSecondStepper(stepper: MatStepper){
    this.myStepper = stepper;
    stepper.selectedIndex=1;
  }

  onMemberTypeSelected(){
    console.log("Member Type: "+this.paymentMaster.ItemName);
    if(this.paymentMaster.ItemName=="SpecialMember"){
      this.paymentMaster.Amount=40;
    }else{
      this.paymentMaster.Amount=0;
    }
  }
  onIntervalSelected(){
    console.log("Interval: "+ this.recurringPayment.Interval);
  }

  onClickRecurring(){
    this.showRecurringGroup=true;
    this.paymentMaster.Recurring=true;
  }
  onClickCancelRecurring(){
    this.showRecurringGroup=false;
    this.paymentMaster.Recurring=false;
  }
  onClickEnterPaymentInfo(stepper: MatStepper){
    // alert('hey')
    // this.clickedPaymentInfo=true;
    // stepper.selectedIndex=3;
    sessionStorage.setItem("myItemName", this.paymentMaster.ItemName);
    sessionStorage.setItem("myAmount", this.paymentMaster.Amount==null?"":this.paymentMaster.Amount.toString());
    sessionStorage.setItem("myRecurring", this.paymentMaster.Recurring==null?"false":this.paymentMaster.Recurring.toString());
    sessionStorage.setItem("myInterval", this.recurringPayment.Interval);
    sessionStorage.setItem("myStartDate",this.recurringPayment.StartDate==null?null:this.recurringPayment.StartDate.toString());
    sessionStorage.setItem("myFirstName", this.paymentMaster.FirstName);
    sessionStorage.setItem("myLastName", this.paymentMaster.LastName);
    sessionStorage.setItem("myEmail", this.paymentMaster.Email);
  }

  test1(){
    alert('Praise the Lord!!');
  }
  onClickPayNow() {
    /*********************************************
   * POST API
   * ******************************************/
    this.loading = true;
    this.paymentMaster.DataDescriptor = this.dataDescriptor;
    this.paymentMaster.DataValue = this.dataValue;
    this.lineItem.name = this.paymentMaster.ItemName;
    this.lineItem.unitPrice = this.paymentMaster.Amount;

    console.log('first name: '+this.paymentMaster.FirstName);

    //  this.paymentMaster.lineItems.push(this.lineItem);
    this.paymentService.makeRecurringPayment(this.paymentMaster).subscribe(token => {
    },
      error => {
        this.myMessage = this.getMyLanguage()=="EN"?"Payment failed.":"支払いに失敗しました。";
        this.openSnackBar('error', 'close');
        this.loading = false;
      })
  }

  onSubmit(){
    this.clickedNextFromFirstFormGroup=true;
    if(this.firstFormGroup.valid){
      console.log('Form is valid');
      this.firstFormIsValid=true;

    }else{
      console.log('Form is invalid');
      this.firstFormIsValid=false;

    }
  }
  // onConfirm($event: any){
  //   if(this.firstFormGroup.valid){
  //     alert('OK');
  //   }else{
  //     alert('NG');
  //   }
  //   this.ngOnInit()
  //   alert('end')
  // }

  onFromFistToSecound(){
    alert('Yay')
  }

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 5000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 10000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }
}
