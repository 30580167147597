import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from "@angular/router";
import { UserConference } from 'src/app/models/conference/userConference';

export interface DialogData {
  userId: string
}
@Component({
  selector: 'app-my-account-edit-grc',
  templateUrl: './my-account-edit-grc.component.html',
  styleUrls: ['./my-account-edit-grc.component.css']
})
export class MyAccountEditGRCComponent implements OnInit {

  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<MyAccountEditGRCComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    //admin画面からユーザーのmy accountを閲覧する度にセッションを作り直す
    sessionStorage.removeItem("myGRCSearchingUserIdGRC");
    sessionStorage.setItem("myGRCSearchingUserIdGRC", this.data.userId);
  }

  ngOnInit(): void {

  }

  onClickClose() {
    //Adminによる作業中のユーザーのセッションを消しておく
    if (sessionStorage.getItem("myGRCSearchingUserIdGRC") != null) {
      sessionStorage.removeItem("myGRCSearchingUserIdGRC");
    }
    sessionStorage.removeItem("myECSearchingUser");
    this.dialogRef.close();
  }
}
