export class AppConfig {
 //Local
//public readonly apiMyUrl ="http://localhost:12770";

 // Azure
public readonly apiMyUrl = "https://jcfnmembershiphost.azurewebsites.net";


 public readonly apiMailchimp = "https://mailchimp-test1.azurewebsites.net";


 public readonly keyMyAuth = "myAuthKey";

 //public readonly apiDoc = "https://jcfnwebdoc.azurewebsites.net";
  //public readonly apiDoc = "http://localhost:56345";

 //Test
 //public readonly JCFN_Organization_id="org_HYPFilaqd00";
 //public readonly Tithely_Public_key='pub_5e87d58a2e3d5';

 //Production
 public readonly JCFN_Organization_id="org_HYPFilaDQ42";
 public readonly Tithely_Public_key='pub_5e70035b1020d';

 //test1

 //test2 from mac

 //test1-1 from winq

//----------------------------------------------------------------


 //test2-3 from mac


 //I am working on TaskA



 //I am working for TaskB


 //I'm working in TaskA new

}
