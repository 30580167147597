<div class="myBase">
  <div class="myBackgroundLayer">
   <div class="row">
    <div class="myAccountTitle">
     <h2>
     </h2>
    </div>
   </div>
   <div class="d-flex justify-content-center">
    <mat-card class="supportingMembershipAnounce">

     <h3>
      {{this.router.url.includes('EN')==true?'Payment completed successfully.': '支払い処理が正しく行われました。'}}
     </h3>
    <h3>
     {{this.router.url.includes('EN')==true?'Thank you': 'ありがとうございました。'}}
    </h3>
     <br><br><br>
       <!-- <a [routerLink]="this.router.url.includes('EN')==true?'/home/EN': '/home/JP'"
         class="btn btn-link">{{this.router.url.includes('EN')==true?'Home': 'ホーム'}}</a>&nbsp;&nbsp; -->
         <a [routerLink]="this.router.url.includes('EN')==true?'../conference/EC/myAccount/EN': '/conference/EC/myAccount/JP'"
         class="btn btn-link">{{this.router.url.includes('EN')==true?'My Account': 'マイアカウント'}}</a>
    </mat-card>
   </div>
  </div>
 </div>
