<div class="myBody">
    <div class="myHeaderBase d-flex justify-content-center">
      <div class="myHeaderCore">
        <div>
          <img src='../../../../../assets/images/thumbnail_eclogo.png' class="ecLogo">
        </div>
      </div>
    </div>
    <div class="myMain">
      <div clas="myMainLeft">
        <div class="myMainHalfTop">
          <!-- Left Top -->
        </div>
        <div class="myMainHalfBottom">
          <!-- Left Bottom -->
        </div>
      </div>
      <div class="myMainCenter">
        <div class="myMainHalfTop d-flex justify-content-center">
          <!-- Main Top -->
          <mat-card class="mainCard b-radius">
            <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
            <div *ngIf="!loaded">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
              <!-- <form *ngIf="userFromServer | async; else loading"  -->
               <form
              [formGroup]="myFormGroup"
              (ngSubmit)="onSubmit()"
              >
              <div class="float-end stepNumber">12/12</div>
              <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Conference Information':
                'カンファレンス情報'}}
              </h2>

                <mat-card class="RegistrationCardList">
                  <div class="jumbotron p-3 p-md-5 text-white rounded " id="registrationInfoSummaryHeader">
                    <div class="col-md-6 px-0">
                      <h4>{{this.router.url.includes('EN')==true?'Summary': 'サマリー'}}</h4>
                    </div>
                  </div>
                  <br>
                  <div class="registrationInfoSummaryItem">
                    <h4> {{this.router.url.includes('EN')==true?'Program Type:': 'プログラムタイプ：'}}</h4>
                    <div class="row summaryTable">
                      <div class="col-10 col-sm-3 col-md-2">
                        <div class="summaryValue">
                                          {{
                            this.router.url.includes('EN')==true?
                            conference.ProgramType
                            :
                            (conference.ProgramType=='General Program'?'一般 プログラム':
                            (conference.ProgramType=='Mission Program'?'ミッション プログラム':
                            (conference.ProgramType=='Youth Program'?'ユース プログラム':
                            (conference.ProgramType=='Seekers Program'?'Seekers プログラム':'n/a'))))
                                          }}
                        </div>
                      </div>
                    </div>
                    <div class="divEditButton">
                      <button class="btn btn-info btn-lg btnEdit float-end" (click)="goToProgramType()">
                        {{this.router.url.includes('EN')==true?'Edit': '編集'}}
                      </button>
                    </div>
                  </div>
                  <br>
                  <hr>
                  <div class="registrationInfoSummaryItem">
                    <h4> {{this.router.url.includes('EN')==true?'Small Group Language:': 'スモールグループ言語：'}}</h4>
                    <div class="row summaryTable">
                      <div class="col-10 col-sm-3 col-md-4">

                        <div class="summaryValue">
                          {{this.router.url.includes('EN')==true?
                          conference.SmallGroupLanguage
                          :
                          conference.SmallGroupLanguage=='Japanese'?'日本語':
                          (conference.SmallGroupLanguage=='English'?'英語':'バイリンガル')}}

                        </div>
                      </div>
                    </div>
                    <div class="divEditButton">
                      <button class="btn btn-info btn-lg btnEdit float-end" (click)="goToSmallGroupLanguage()">
                        {{this.router.url.includes('EN')==true?'Edit': '編集'}}
                      </button>
                    </div>
                  </div>
                  <br>
                  <hr>
<div  *ngIf="!conference.ProgramType.includes('Youth')">
                  <div class="registrationInfoSummaryItem">
                    <h4> {{this.router.url.includes('EN')==true?'Japan Regional Meeting:': '日本地域別集会：'}}</h4>
                    <div class="row summaryTable">
                      <div class="col-10 col-sm-3 col-md-4">

                        <div class="summaryValue">
                          {{this.router.url.includes('EN')==true?
                          conference.AttendJapanRegionalMeeting==true?'Participate':'Not participate'
                          :
                          conference.AttendJapanRegionalMeeting==true?'参加する':'参加しない'}}<br>
                          {{conference.JapanRegionalMeeting}}
                        </div>
                      </div>
                    </div>
                    <div class="divEditButton">
                      <button class="btn btn-info btn-lg btnEdit float-end" (click)="goToJapanRegionalMeeting()">
                        {{this.router.url.includes('EN')==true?'Edit': '編集'}}
                      </button>
                    </div>
                  </div>
                  <br>
                  <hr>
</div>

                  <div *ngIf="!conference.ProgramType.includes('Youth') && !conference.ProgramType.includes('Seekers')" >
                   <div class="registrationInfoSummaryItem">
                    <h4> {{this.router.url.includes('EN')==true?'Volunteer:' : 'ボランティア：'}}</h4>
                    <div class="row summaryTable">
                      <div class="col-10 col-sm-3 col-md-4">
                        <div class="summaryValue">
                          <div *ngFor = "let volunteer of conference.myVolunteerSelections">
                            {{volunteer.VolunteerName}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="divEditButton">
                      <button class="btn btn-info btn-lg btnEdit float-end" (click)="goToVolunteers()">
                        {{this.router.url.includes('EN')==true?'Edit': '編集'}}
                      </button>
                    </div>
                  </div>
                  <br>
                  <hr>
                  </div>
<div  *ngIf="!conference.ProgramType.includes('Youth')">
                  <div class="registrationInfoSummaryItem">
                    <h4> {{this.router.url.includes('EN')==true?'English Conversation Partner:': 'イングリッシュカンバセーションパートナー：'}}</h4>
                    <div class="row summaryTable">
                      <div class="col-10 col-sm-3 col-md-4">

                        <div class="summaryValue">
                          {{this.router.url.includes('EN')==true?
                          conference.ApplyEnglishPartner==true?'Participate':'Not participate'
                          :
                          conference.ApplyEnglishPartner==true?'参加する':'参加しない'}}
                        </div>
                      </div>
                    </div>
                    <div class="divEditButton">
                      <button class="btn btn-info btn-lg btnEdit float-end" (click)="goToEnglishPartner()">
                        {{this.router.url.includes('EN')==true?'Edit': '編集'}}
                      </button>
                    </div>
                  </div>
                  <br>
                  <hr>
</div>

<div  *ngIf="!conference.ProgramType.includes('Youth')">
  <div class="registrationInfoSummaryItem">
    <h4> {{this.router.url.includes('EN')==true?'Mentoring:': 'メンタリング：'}}</h4>
    <div class="row summaryTable">
      <div class="col-10 col-sm-3 col-md-4">
        <div class="summaryValue">
          {{this.router.url.includes('EN')==true?
          conference.BecomeMentor==true?'I am available to serve as a mentor':'I am not available to serve as a mentor'
          :
          conference.BecomeMentor==true?'メンターとして奉仕することができます':'メンターとして奉仕しない'}}
        </div>
      </div>
    </div>
    <div class="row summaryTable">
      <div class="col-10 col-sm-3 col-md-4">
        <div class="summaryValue">
          {{this.router.url.includes('EN')==true?
          conference.ApplyMentoring==true?'I would like to received a mentoring session':'I do not receive a mentoring session'
          :
          conference.ApplyMentoring==true?'メンターセッションを受けたいです':'メンターセッションを受けない'}}
        </div>
      </div>
    </div>
    <div class="divEditButton">
      <button class="btn btn-info btn-lg btnEdit float-end" (click)="goToCounseling()">
        {{this.router.url.includes('EN')==true?'Edit': '編集'}}
      </button>
    </div>
  </div>
  <br>
  <hr>
</div>

                  <div class="registrationInfoSummaryItem">
                    <h4> {{this.router.url.includes('EN')==true?'Student:': '学生：'}}</h4>
                    <div class="row summaryTable">
                      <div class="col-10 col-sm-3 col-md-4">
                        <div class="summaryValue">
                          {{this.router.url.includes('EN')==true?
                          conference.Student==true?'Yes':'No'
                          :
                          conference.Student==true?'はい':'いいえ'}}
                        </div>
                      </div>
                    </div>
                    <div class="divEditButton">
                      <button class="btn btn-info btn-lg btnEdit float-end" (click)="goToStudent()">
                        {{this.router.url.includes('EN')==true?'Edit': '編集'}}
                      </button>
                    </div>
                  </div>
                  <br>
                  <hr>
                  <div class="registrationInfoSummaryItem">
                    <h4> {{this.router.url.includes('EN')==true?'Couple:': '夫婦：'}}</h4>
                    <div class="row summaryTable">
                      <div class="col-10 col-sm-3 col-md-4">
                        <div class="summaryValue">
                          {{this.router.url.includes('EN')==true?
                          conference.Couple==true?'Yes':'No'
                          :
                          conference.Couple==true?'はい':'いいえ'}}
                        </div>
                      </div>
                    </div>
                    <div class="divEditButton">
                      <button class="btn btn-info btn-lg btnEdit float-end" (click)="goToStudent()">
                        {{this.router.url.includes('EN')==true?'Edit': '編集'}}
                      </button>
                    </div>
                  </div>
                  <br>

                  <!-- <hr>
                  <div class="registrationInfoSummaryItem">
                    <h4> {{this.router.url.includes('EN')==true?'Sleeping Bag:': '寝袋：'}}</h4>
                    <div class="row summaryTable">
                      <div class="col-10 col-sm-3 col-md-6">
                        <div class="summaryValue">
                        {{this.router.url.includes('EN')==true?
                        conference.MySleepingBag=='BringMySleepingBag'?'I will bring my own sleeping bag.':
                        (conference.MySleepingBag=='BorrowMySleepingBag'?'I would like to borrow a sleeping bag.':'')
                        :
                        conference.MySleepingBag=='BringMySleepingBag'?'寝袋持参します。':
                        (conference.MySleepingBag=='BorrowMySleepingBag'?'寝袋を借りたいです。':'')
                      }}
                      <br>
                      {{this.router.url.includes('EN')==true?'Can you rent out extra sleeping bags (or 3 blankets)？': 'エクストラの寝袋（もしくは毛布3枚）を貸し出せますか？'}}
                      {{this.router.url.includes('EN')==true?
                      conference.BringExtraSleepingBag==true?'Yes':'No'
                      :
                      conference.BringExtraSleepingBag==true?'はい':'いいえ'}}
                      <br>
                      {{this.router.url.includes('EN')==true?'How many？': 'いくつ？'}}
                      {{this.router.url.includes('EN')==true?
                      conference.CountExtraSleepingBag
                      :
                      conference.CountExtraSleepingBag}}
                        </div>
                      </div>
                    </div>
                    <div class="divEditButton">
                      <button class="btn btn-info btn-lg btnEdit float-end" (click)="goToSleepingBag()">
                        {{this.router.url.includes('EN')==true?'Edit': '編集'}}
                      </button>
                    </div>
                  </div>
                  <br> -->
                  <hr>
                  <div class="registrationInfoSummaryItem">
                    <h4> {{this.router.url.includes('EN')==true?'Transportation:': 'トランスポーテーション：'}}</h4>
                     <div class="row summaryTable">
                     <div>
                      <b>
                        {{this.router.url.includes('EN')==true?'Coming:': '行き：'}}
                      </b>
                     </div>
                      <div class="col-10 col-sm-4 col-md-4">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          'Do you need Bus transportation to the venue?'
                          :
                          '会場までのバス送迎必要ですか？'}}
                        </div>
                        <div class="summaryValue">

                        {{this.router.url.includes('EN')==true?
                        (conference.NeedCommingBus==true?'Yes':'No')
                        :
                        (conference.NeedCommingBus==true?'はい':'いいえ')
                      　}}

                        </div>
                      </div>

                      <div class="col-8 col-sm-4 col-md-8">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          'Bus Departure time & place to the venue?'
                          :
                          '会場までのバス出発時刻＆場所'}}
                        </div>
                        <div class="summaryValue">
                          {{conference.CommingBusDeparturePlace}}<br>
                        </div>
                      </div>
                      <div class="col-8 col-sm-4 col-md-4">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          'Airline'
                          :
                          '航空会社'}}
                        </div>
                        <div class="summaryValue">
                          {{conference.CommingAirline}}<br>
                        </div>
                      </div>
                      <div class="col-8 col-sm-4 col-md-8">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          'Flight Number'
                          :
                          '便名'}}
                        </div>
                        <div class="summaryValue">
                          {{conference.CommingFlightNo}}<br>
                        </div>
                      </div>
                      <div class="col-8 col-sm-4 col-md-4">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          'Departure City'
                          :
                          '出発航空名'}}
                        </div>
                        <div class="summaryValue">
                          {{conference.DepartureCity}}
                        </div>
                      </div>
                      <div class="col-8 col-sm-4 col-md-8">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          'Arrival Date&Time'
                          :
                          '到着日&時間'}}
                        </div>
                        <div class="summaryValue">
                          {{conference.ArrivalDateTime | date: 'MM/dd/yyyy hh:mm a'}}<br>
                        </div>
                      </div>
                      <div class="col-8 col-sm-4 col-md-3">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          'Domestic or International'
                          :
                          '国際線・国内線'}}
                        </div>
                        <div class="summaryValue">
                          {{conference.CommingInternationalDomestic}}<br>
                        </div>
                      </div>
                    </div>
                    <div class="row summaryTable">
                      <div>
                       <b>
                         {{this.router.url.includes('EN')==true?'Returning:': '帰り：'}}
                       </b>
                      </div>
                       <div class="col-10 col-sm-4 col-md-4">
                         <div class="summaryTitle">
                           {{this.router.url.includes('EN')==true?
                           'Do you need Bus transportation to LAX?'
                           :
                           '空港までのバス送迎必要ですか？'}}
                         </div>
                         <div class="summaryValue">
                          {{this.router.url.includes('EN')==true?
                          (conference.NeedReturningBus==true?'Yes':'No')
                          :
                          (conference.NeedReturningBus==true?'はい':'いいえ')
                        　}}
                         </div>
                       </div>

                       <div class="col-8 col-sm-4 col-md-8">
                         <div class="summaryTitle">
                           {{this.router.url.includes('EN')==true?
                           'Bus Departure time & place to LAX'
                           :
                           '空港までのバス出発時刻＆場所'}}
                         </div>
                         <div class="summaryValue">
                           {{conference.ReturningBusDeparturePlace}}<br>
                         </div>
                       </div>
                       <div class="col-8 col-sm-4 col-md-4">
                         <div class="summaryTitle">
                           {{this.router.url.includes('EN')==true?
                           'Airline'
                           :
                           '航空会社'}}
                         </div>
                         <div class="summaryValue">
                           {{conference.ReturningAirline}}<br>
                         </div>
                       </div>
                       <div class="col-8 col-sm-4 col-md-8">
                         <div class="summaryTitle">
                           {{this.router.url.includes('EN')==true?
                           'Flight Number'
                           :
                           '便名'}}
                         </div>
                         <div class="summaryValue">
                           {{conference.ReturningFlightNo}}<br>
                         </div>
                       </div>
                       <div class="col-8 col-sm-4 col-md-4">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          'Departure City'
                          :
                          '出発航空名'}}
                        </div>
                        <div class="summaryValue">
                          {{conference.ReturningDepartureCity}}
                        </div>
                      </div>
                       <div class="col-8 col-sm-4 col-md-8">
                         <div class="summaryTitle">
                           {{this.router.url.includes('EN')==true?
                           'Destination City'
                           :
                           '目的航空名'}}
                         </div>
                         <div class="summaryValue">
                           {{conference.ReturningCity}}
                         </div>
                       </div>
                       <div class="col-8 col-sm-4 col-md-4">
                         <div class="summaryTitle">
                           {{this.router.url.includes('EN')==true?
                           'Departure Date&Time'
                           :
                           '出発日&時間'}}
                         </div>
                         <div class="summaryValue">
                           {{conference.DepartureDateTime | date: 'MM/dd/yyyy hh:mm a'}}<br>
                         </div>
                       </div>
                       <div class="col-8 col-sm-4 col-md-8">
                         <div class="summaryTitle">
                           {{this.router.url.includes('EN')==true?
                           'Domestic or International'
                           :
                           '国際線・国内線'}}
                         </div>
                         <div class="summaryValue">
                           {{conference.CommingInternationalDomestic}}<br>
                         </div>
                       </div>
                     </div>

                    <div class="divEditButton">
                      <button class="btn btn-info btn-lg btnEdit float-end" (click)="goToTransportation()">
                        {{this.router.url.includes('EN')==true?'Edit': '編集'}}
                      </button>
                    </div>
                  </div>
                  <br>


                  <hr>
                  <div class="registrationInfoSummaryItem">
                    <h4> {{this.router.url.includes('EN')==true?'Room Info:': 'ルーム：'}}</h4>
                    <div class="row summaryTable">
                      <div class="col-10 col-sm-3 col-md-10">
                        <div class="summaryValue">
                        {{this.router.url.includes('EN')==true?
                        conference.RoomInfo=='AnyoneIsFine'?'Anyone is fine.':
                        (conference.RoomInfo=='WithFamily'?'With Family':'Share with others')
                        :
                        conference.RoomInfo=='AnyoneIsFine'?'誰でも良し':
                        (conference.RoomInfo=='WithFamily'?'家族と同室':'その他と同室')
                      }}
　                      <br>
                           <div *ngFor = "let person of conference.myRelatedPersons">
                            {{person.FirstNameRelated}} {{person.LastNameRelated}} ,
                            {{person.MyojiRelated}}  {{person.ShimeiRelated}} -
                            {{person.DOBRelated | date: 'MM/dd/yyyy'}} -
                            {{person.ChildCare==true?(this.router.url.includes('EN')==true?'Need Child Care':'チャイルドケア必要'):'' }} -
                            {{this.router.url.includes('EN')==true?
                              person.GenderRelated
                              :
                              person.GenderRelated=='Woman'?'女性':(person.GenderRelated=='Man'?'男性':'')}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="divEditButton">
                      <button class="btn btn-info btn-lg btnEdit float-end" (click)="goToRoomInfo()">
                        {{this.router.url.includes('EN')==true?'Edit': '編集'}}
                      </button>
                    </div>
                  </div>
                  <br>
                  <hr>
                  <div class="registrationInfoSummaryItem">
                    <h4> {{this.router.url.includes('EN')==true?'Participation Schedule:': '参加日程：'}}</h4>
                    <div >

                          <div class="row summaryTable">
                            <div class="col summaryTitle">
                              {{this.router.url.includes('EN')==true?'Dec 27 Tue': '12月27日 (火)'}}
                            </div>
                             <div class="col summaryValue">
                                <div *ngIf="conference.Dec27=='NoParticipateDec27'">
                                    {{this.router.url.includes('EN')==true?'No Participate': '参加しない。'}}
                                </div>
                                <div *ngIf="conference.Dec27=='NoStayDec27'">
                                    {{this.router.url.includes('EN')==true?'No Stay': '日帰り'}}
                                </div>
                                <div *ngIf="conference.Dec27=='StayDec27'">
                                    {{this.router.url.includes('EN')==true?'Stay': '宿泊'}}
                                </div>
                            </div>
                          </div>
                          <div class="row summaryTable">
                            <div class="col summaryTitle">
                              {{this.router.url.includes('EN')==true?'Dec 28 Wed': '12月28日 (水)'}}
                            </div>
                             <div class="col summaryValue">
                                <div *ngIf="conference.Dec28=='NoParticipateDec28'">
                                    {{this.router.url.includes('EN')==true?'No Participate': '参加しない。'}}
                                </div>
                                <div *ngIf="conference.Dec28=='NoStayDec28'">
                                    {{this.router.url.includes('EN')==true?'No Stay': '日帰り'}}
                                </div>
                                <div *ngIf="conference.Dec28=='StayDec28'">
                                    {{this.router.url.includes('EN')==true?'Stay': '宿泊'}}
                                </div>
                            </div>
                          </div>
                          <div class="row summaryTable">
                            <div class="col summaryTitle">
                              {{this.router.url.includes('EN')==true?'Dec 29 Thu': '12月29日 (木)'}}
                            </div>
                             <div class="col summaryValue">
                                <div *ngIf="conference.Dec29=='NoParticipateDec29'">
                                    {{this.router.url.includes('EN')==true?'No Participate': '参加しない。'}}
                                </div>
                                <div *ngIf="conference.Dec29=='NoStayDec29'">
                                    {{this.router.url.includes('EN')==true?'No Stay': '日帰り'}}
                                </div>
                                <div *ngIf="conference.Dec29=='StayDec29'">
                                    {{this.router.url.includes('EN')==true?'Stay': '宿泊'}}
                                </div>
                            </div>
                          </div>
                          <div class="row summaryTable">
                            <div class="col summaryTitle">
                              {{this.router.url.includes('EN')==true?'Dec 30 Thu': '12月30日 (金)'}}
                            </div>
                             <div class="col summaryValue">
                                <div *ngIf="conference.Dec30=='NoParticipateDec30'">
                                    {{this.router.url.includes('EN')==true?'No Participate': '参加しない。'}}
                                </div>
                                <div *ngIf="conference.Dec30=='NoStayDec30'">
                                    {{this.router.url.includes('EN')==true?'No Stay': '日帰り'}}
                                </div>
                                <div *ngIf="conference.Dec30=='StayDec30'">
                                    {{this.router.url.includes('EN')==true?'Stay': '宿泊'}}
                                </div>
                            </div>
                          </div>
                          <div class="row summaryTable">
                            <div class="col summaryTitle">
                              {{this.router.url.includes('EN')==true?'Dec 31 Thu': '12月31日 (土)'}}
                            </div>
                             <div class="col summaryValue">
                                <div *ngIf="conference.Dec31=='NoParticipateDec31'">
                                    {{this.router.url.includes('EN')==true?'No Participate': '参加しない。'}}
                                </div>
                                <div *ngIf="conference.Dec31=='NoStayDec31'">
                                    {{this.router.url.includes('EN')==true?'No Stay': '日帰り'}}
                                </div>
                                <div *ngIf="conference.Dec31=='StayDec31'">
                                    {{this.router.url.includes('EN')==true?'Stay': '宿泊'}}
                                </div>
                            </div>
                          </div>
                    </div>
                    <div class="divEditButton">
                      <button class="btn btn-info btn-lg btnEdit float-end" (click)="goToParticipationSchedule()">
                        {{this.router.url.includes('EN')==true?'Edit': '編集'}}
                      </button>
                    </div>
                    <br>

                  </div>
                  <!-- <div class="registrationInfoSummaryItem">
                    <h4> {{this.router.url.includes('EN')==true?'Scholarship:': '奨学金：'}}</h4>
                    <div class="row summaryTable">
                      <div class="col-10 col-sm-3 col-md-4">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          'Do you apply for scholarship?'
                          :
                          '奨学金を申し込みますか？'}}
                        </div>
                        <div class="summaryValue">
                          {{this.router.url.includes('EN')==true?
                          conference.ApplyScholarship==true?'Yes':'No'
                          :
                          conference.ApplyScholarship==true?'はい':'いいえ'}}
                        </div>
                      </div>
                      <div class="col-10 col-sm-3 col-md-4">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          'How much?'
                          :
                          'いくら？'}}
                        </div>
                        <div class="summaryValue">
                          {{conference.ApplyingScholarshipAmount | currency}}
                        </div>
                      </div>
                      <div class="col-10 col-sm-10 col-md-10">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          'Reason'
                          :
                          '理由'}}
                        </div>
                        <div class="summaryValue">
                          {{conference.ApplyingReason}}
                        </div>
                      </div>
                    </div>
                    <div class="divEditButton">
                      <button class="btn btn-info btn-lg btnEdit float-end" (click)="goToScholarship()">
                        {{this.router.url.includes('EN')==true?'Edit': '編集'}}
                      </button>
                    </div>
                  </div>
                  <br>
                  <hr> -->

                </mat-card>
                <br>
                <div *ngIf="!loaded">
                  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
                <div *ngIf="!loaded" class="progressMessage">
                  {{this.router.url.includes('EN')==true?'Saving...': '保存中...'}}
                </div>













              <!-- <div class=" position-absolute bottom-0 start-50 translate-middle-x"> -->
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn btnProcess" (click)="onClickGoBack()">
                    {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                  </button>
                  <button  [disabled]="!myFormGroup.valid" type="button" class="btn btnProcess" (click)="onClickGoForward()">
                    {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                  </button>
                </div>
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn link btnCancel" (click)="cancelProcess()">
                    {{this.router.url.includes('EN')==true?'Cancel': 'キャンセル'}}
                  </button>
                </div>
              <!-- </div> -->

            </form>
             <!-- <button type="submit" [disabled]="!myFormGroup.valid">Submit</button> -->
          </mat-card>
        </div>
        <div class="myMainHalfBottom">
          <!-- Main Bottom -->
        </div>
      </div>
      <div class="myMainRight">
        <div class="myMainHalfTop">
          <!-- Right Top -->
        </div>
        <div class="myMainHalfBottom">
          <!-- Right Bottom -->
        </div>
      </div>
    </div>
    <!-- <footer>footer</footer> -->
  </div>
