import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-template-medical',
  templateUrl: './template-medical.component.html',
  styleUrls: ['./template-medical.component.css']
})
export class TemplateMedicalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
