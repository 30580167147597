<div class="myBody">
  <div class="myHeaderBase d-flex justify-content-center">
    <div class="myHeaderCore">
      <div>
        <img src='../../../../../assets/images/thumbnail_eclogo.png' class="ecLogo">
      </div>
    </div>
  </div>
  <div class="myMain">
    <div clas="myMainLeft">
      <div class="myMainHalfTop">
        <!-- Left Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Left Bottom -->
      </div>
    </div>
    <div class="myMainCenter">
      <div class="myMainHalfTop d-flex justify-content-center">
        <!-- Main Top -->

        <mat-card class="mainCard b-radius">
          <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
          <div *ngIf="!loaded">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>

          <!-- <form *ngIf="userFromServer | async; else loading"  -->
          <form [formGroup]="myFormGroup" (ngSubmit)="onSubmit()">
            <div class="float-end stepNumber">10/12</div>
            <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Participation Schedule':
              '参加日程'}}
              <i class="stepTitleSub">
                {{this.router.url.includes('EN')==true?'Required': '必須'}}
              </i>
            </h2>

            <div class="stepTitle2nd">
              {{this.router.url.includes('EN')==true?
              'Buses from the airport to the venue will be available only on December 27, and from the venue to the airport only on January 1. On December 28, 29, 30, and 31, there will be no transportation between the airport and the venue, so you must arrange your own transportation. Please note that partial participation is not available for those who cannot arrange transportation. Priority is given to full-day participants at this time. Partial participants will be accepted once the number of full-day participants reaches 275, so please contact us if you would like to participate. ecregistration@equipper.org'
              :
              '空港から会場へのバスは12月27日のみ、また、会場から空港へのバスは、1月1日のみです。28日、29日、30日、31日には、空港と会場間の送迎がないため、各自で送迎の手配が必要です。送迎の手配ができない方は、部分参加は不可ですのでご了承ください。今回は全日参加者を優先しています。全日参加が定員275名に達した時点で部分参加の方を受け付けますので、ご希望の方はお問い合わせください。ecregistration@equipper.org'}}
            </div>
<br>
             <div class="stepTitle2nd">
              {{this.router.url.includes('EN')==true?'Select the dates you with to participate.':
              '全参加ですか？部分参加ですか？'}}
            </div>
            <br>

            <mat-radio-group formControlName="myLodgingSchedule" (change)="onChangeMyLodgingSchedule($event)"
              class="rbGroup">
              <div *ngIf="loaded" class="d-flex justify-content-center">
                <mat-radio-button value="StayAllDays" class="rbItem" [checked]="StayAllDays==true">
                  {{this.router.url.includes('EN')==true?'Attend all days': '全参加'}}
                </mat-radio-button>

                <div  *ngIf="this.ecRegistrationService.checkLoginUserAccessEC()">
                   <mat-radio-button value="StayPartially" class="rbItem"
                  [checked]="StayPartially==true">
                  {{this.router.url.includes('EN')==true?'Attend Partially': '部分参加'}}
                </mat-radio-button>
                </div>
                <div  *ngIf="!this.ecRegistrationService.checkLoginUserAccessEC()">
                  <mat-radio-button value="StayPartially" class="rbItem" disabled="disabled"
                 [checked]="StayPartially==true">
                 {{this.router.url.includes('EN')==true?'Attend Partially': '部分参加'}}
               </mat-radio-button>
               </div>


              </div>
            </mat-radio-group>
            <br>

            <!-- <div *ngIf="showPreviousDay">
              <div class="stepTitle2nd">
                {{this.router.url.includes('EN')==true?'Stay EC Previous Day?':
                '前日宿泊しますか？'}}
              </div>
              <div class="row summaryTable">
                <div class="col-4 col-sm-4 col-md-4">
                  {{this.router.url.includes('EN')==true?'Dec 27 Tue': '12月27日 (火)'}}
                </div>
                <div class="col-8 col-sm-4 col-md-8">
                  <div *ngIf="loaded">
                    <mat-radio-group formControlName="myDec27" (change)="onChangeDec27($event)" class="rbGroupLodging">
                      <mat-radio-button value="StayDec27" class="rbItemLodging"
                        [checked]="conference.Dec27=='StayDec27'">
                        {{this.router.url.includes('EN')==true?'Yes': 'はい'}}
                      </mat-radio-button>
                      <mat-radio-button value="NoParticipateDec27" class="rbItemLodging"
                        [checked]="conference.Dec27=='NoParticipateDec27'">
                        {{this.router.url.includes('EN')==true?'No': 'いいえ'}}
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
            </div> -->

            <div *ngIf="showLodgingSchedule">
              <div class="stepTitle2nd">
                {{this.router.url.includes('EN')==true?'Please select the date you would like to attend.':
                '参加される日を選んでください。'}}
              </div>

              <div class="row summaryTable">
                <div class="col-2 col-sm-2 col-md-2">
                  {{this.router.url.includes('EN')==true?'Dec 27 Fri': '12月27日 (金)'}}
                </div>
                <div class="col-10 col-sm-4 col-md-10">
                  <div *ngIf="loaded">
                    <mat-radio-group formControlName="myDec27" (change)="onChangeDec27($event)" class="rbGroupLodging">
                      <mat-radio-button value="NoParticipateDec27" class="rbItemLodging"
                        [checked]="conference.Dec27=='NoParticipateDec27'">
                        {{this.router.url.includes('EN')==true?'No Participate': '参加しない。'}}
                      </mat-radio-button>
                      <mat-radio-button value="NoStayDec27" class="rbItemLodging"
                        [checked]="conference.Dec27=='NoStayDec27'">
                        {{this.router.url.includes('EN')==true?'No Stay': '日帰り'}}
                      </mat-radio-button>
                      <mat-radio-button value="StayDec27" class="rbItemLodging"
                        [checked]="conference.Dec27=='StayDec27'">
                        {{this.router.url.includes('EN')==true?'Stay': '宿泊'}}
                      </mat-radio-button>
                      <!-- <mat-checkbox [disabled]="true" value=true formControlName="myDec27Breakfast"
                        [checked]="Dec27Breakfast==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Breakfast': '朝食'}}
                      </mat-checkbox>
                      <mat-checkbox [disabled]="true" value=true formControlName="myDec27Lunch"
                        [checked]="Dec27Lunch==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Lunch': '昼食'}}
                      </mat-checkbox> -->
                      <span class="makeSpace1"></span>
                      <mat-checkbox [disabled]="true" value=true formControlName="myDec27Dinner"
                        [checked]="Dec27Dinner==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Dinner': '夕食'}}
                      </mat-checkbox>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
              <hr class="shceduleDevider">

              <div class="row summaryTable">
                <div class="col-2 col-sm-2 col-md-2">
                  {{this.router.url.includes('EN')==true?'Dec 28 Sat': '12月28日 (土)'}}
                </div>
                <div class="col-10 col-sm-4 col-md-10">
                  <div *ngIf="loaded">
                    <mat-radio-group formControlName="myDec28" (change)="onChangeDec28($event)" class="rbGroupLodging">
                      <mat-radio-button value="NoParticipateDec28" class="rbItemLodging"
                        [checked]="conference.Dec28=='NoParticipateDec28'">
                        {{this.router.url.includes('EN')==true?'No Participate': '参加しない。'}}
                      </mat-radio-button>
                      <mat-radio-button value="NoStayDec28" class="rbItemLodging"
                        [checked]="conference.Dec28=='NoStayDec28'">
                        {{this.router.url.includes('EN')==true?'No Stay': '日帰り'}}
                      </mat-radio-button>
                      <mat-radio-button value="StayDec28" class="rbItemLodging"
                        [checked]="conference.Dec28=='StayDec28'">
                        {{this.router.url.includes('EN')==true?'Stay': '宿泊'}}
                      </mat-radio-button>
                      <mat-checkbox [disabled]="true" value=true formControlName="myDec28Breakfast"
                        [checked]="Dec28Breakfast==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Breakfast': '朝食'}}
                      </mat-checkbox>
                      <mat-checkbox [disabled]="true" value=true formControlName="myDec28Lunch"
                        [checked]="Dec28Lunch==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Lunch': '昼食'}}
                      </mat-checkbox>
                      <mat-checkbox [disabled]="true" value=true formControlName="myDec28Dinner"
                        [checked]="Dec28Dinner==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Dinner': '夕食'}}
                      </mat-checkbox>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
              <hr class="shceduleDevider">

              <div class="row summaryTable">
                <div class="col-2 col-sm-2 col-md-2">
                  {{this.router.url.includes('EN')==true?'Dec 29 Sun': '12月29日 (日)'}}
                </div>
                <div class="col-10 col-sm-4 col-md-10">
                  <div *ngIf="loaded">
                    <mat-radio-group formControlName="myDec29" (change)="onChangeDec29($event)" class="rbGroupLodging">
                      <mat-radio-button value="NoParticipateDec29" class="rbItemLodging"
                        [checked]="conference.Dec29=='NoParticipateDec29'">
                        {{this.router.url.includes('EN')==true?'No Participate': '参加しない。'}}
                      </mat-radio-button>
                      <mat-radio-button value="NoStayDec29" class="rbItemLodging"
                        [checked]="conference.Dec29=='NoStayDec29'">
                        {{this.router.url.includes('EN')==true?'No Stay': '日帰り'}}
                      </mat-radio-button>
                      <mat-radio-button value="StayDec29" class="rbItemLodging"
                        [checked]="conference.Dec29=='StayDec29'">
                        {{this.router.url.includes('EN')==true?'Stay': '宿泊'}}
                      </mat-radio-button>
                      <mat-checkbox [disabled]="true" value=true formControlName="myDec29Breakfast"
                        [checked]="Dec29Breakfast==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Breakfast': '朝食'}}
                      </mat-checkbox>
                      <mat-checkbox [disabled]="true" value=true formControlName="myDec29Lunch"
                        [checked]="Dec29Lunch==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Lunch': '昼食'}}
                      </mat-checkbox>
                      <mat-checkbox [disabled]="true" value=true formControlName="myDec29Dinner"
                        [checked]="Dec29Dinner==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Dinner': '夕食'}}
                      </mat-checkbox>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
              <hr class="shceduleDevider">

              <div class="row summaryTable">
                <div class="col-2 col-sm-2 col-md-2">
                  {{this.router.url.includes('EN')==true?'Dec 30 Mon': '12月30日 (月)'}}
                </div>
                <div class="col-10 col-sm-4 col-md-10">
                  <div *ngIf="loaded">
                    <mat-radio-group formControlName="myDec30" (change)="onChangeDec30($event)" class="rbGroupLodging">
                      <mat-radio-button value="NoParticipateDec30" class="rbItemLodging"
                        [checked]="conference.Dec30=='NoParticipateDec30'">
                        {{this.router.url.includes('EN')==true?'No Participate': '参加しない。'}}
                      </mat-radio-button>
                      <mat-radio-button value="NoStayDec30" class="rbItemLodging"
                        [checked]="conference.Dec30=='NoStayDec30'">
                        {{this.router.url.includes('EN')==true?'No Stay': '日帰り'}}
                      </mat-radio-button>
                      <mat-radio-button value="StayDec30" class="rbItemLodging"
                        [checked]="conference.Dec30=='StayDec30'">
                        {{this.router.url.includes('EN')==true?'Stay': '宿泊'}}
                      </mat-radio-button>
                      <mat-checkbox [disabled]="true" value=true formControlName="myDec30Breakfast"
                        [checked]="Dec30Breakfast==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Breakfast': '朝食'}}
                      </mat-checkbox>
                      <mat-checkbox [disabled]="true" value=true formControlName="myDec30Lunch"
                        [checked]="Dec30Lunch==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Lunch': '昼食'}}
                      </mat-checkbox>
                      <mat-checkbox [disabled]="true" value=true formControlName="myDec30Dinner"
                        [checked]="Dec30Dinner==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Dinner': '夕食'}}
                      </mat-checkbox>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
              <hr class="shceduleDevider">

              <div class="row summaryTable">
                <div class="col-2 col-sm-2 col-md-2">
                  {{this.router.url.includes('EN')==true?'Dec 31 Tue': '12月31日 (火)'}}
                </div>
                <div class="col-10 col-sm-4 col-md-10">
                  <div *ngIf="loaded">
                    <mat-radio-group formControlName="myDec31" (change)="onChangeDec31($event)" class="rbGroupLodging">
                      <mat-radio-button value="NoParticipateDec31" class="rbItemLodging"
                        [checked]="conference.Dec31=='NoParticipateDec31'">
                        {{this.router.url.includes('EN')==true?'No Participate': '参加しない。'}}
                      </mat-radio-button>
                      <mat-radio-button value="NoStayDec31" class="rbItemLodging"
                        [checked]="conference.Dec31=='NoStayDec31'">
                        {{this.router.url.includes('EN')==true?'No Stay': '日帰り'}}
                      </mat-radio-button>
                      <mat-radio-button value="StayDec31" class="rbItemLodging"
                        [checked]="conference.Dec31=='StayDec31'">
                        {{this.router.url.includes('EN')==true?'Stay': '宿泊'}}
                      </mat-radio-button>
                      <mat-checkbox [disabled]="true" value=true formControlName="myDec31Breakfast"
                        [checked]="Dec31Breakfast==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Breakfast': '朝食'}}
                      </mat-checkbox>
                      <mat-checkbox [disabled]="true" value=true formControlName="myDec31Lunch"
                        [checked]="Dec31Lunch==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Lunch': '昼食'}}
                      </mat-checkbox>
                      <mat-checkbox [disabled]="true" value=true formControlName="myDec31Dinner"
                        [checked]="Dec31Dinner==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Dinner': '夕食'}}
                      </mat-checkbox>
                      <div>
                        {{this.router.url.includes('EN')==true?'12/31 stay includes dinner on 12/31, breakfast
                        on 1/1': '12/31の宿泊には、12/31の夕食、1/1の朝食'}}

                      </div>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
            </div>

            <!-- ////////////////////////////////////////////////////////////////////////////////////// -->

            <div *ngIf="showCalculation">
              <div class="d-flex justify-content-center">
                <div class="registrationCostDetail">
                  <div class="row summaryTable2">

                    <div class="row">
                      <div class="col-6 col-sm-6 col-md-3">
                        {{this.router.url.includes('EN')==true?'':''}}
                      </div>
                      <div class="col-6 col-sm-6 col-md-2 manType">
                        {{this.router.url.includes('EN')==true?'You':conference.FirstName}}
                      </div>
                      <div *ngIf="numberOfKidsUnder18>=1" class="col-6 col-sm-6 col-md-2 manType">
                        {{this.router.url.includes('EN')==true?'Child':kidNo1Name}}
                      </div>
                      <div *ngIf="numberOfKidsUnder18>=2" class="col-6 col-sm-6 col-md-2 manType">
                        {{this.router.url.includes('EN')==true?'Child':kidNo2Name}}
                      </div>
                      <div *ngIf="numberOfKidsUnder18>=3" class="col-6 col-sm-6 col-md-2 manType">
                        {{this.router.url.includes('EN')==true?'Child':kidNo3Name}}
                      </div>
                    </div>

                    <div class="row">
                      <div>
                        {{this.router.url.includes('EN')==true?'Lodging:':'宿泊：'}}
                      </div>
                      <div class="col-6 col-sm-6 col-md-3">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          (this.conference.Dec27 == 'StayDec27'? stayCount+'Days': stayCount+'Days')
                          :
                          (this.conference.Dec27 == 'StayDec27'? stayCount+'泊': stayCount+'泊')
                          }}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{this.stayAmountMyself | currency}}
                        </div>
                      </div>
                      <div *ngIf="numberOfKidsUnder18>=1" class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{stayAmountKidNo1 | currency}}
                        </div>
                      </div>
                      <div *ngIf="numberOfKidsUnder18>=2" class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{stayAmountKidNo2 | currency}}
                        </div>
                      </div>
                      <div *ngIf="numberOfKidsUnder18>=3" class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{stayAmountKidNo3 | currency}}
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Day trip:':'日帰り：'}}
                      </div>
                      <div class="col-6 col-sm-6 col-md-3">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          dayTrip+'Days'
                          :
                          dayTrip+'日'
                          }}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{noStayAmountMyself| currency}}
                        </div>
                      </div>
                      <div *ngIf="numberOfKidsUnder18>=1" class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{noStayAmountKidNo1| currency}}
                        </div>
                      </div>
                      <div *ngIf="numberOfKidsUnder18>=2" class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{noStayAmountKidNo2| currency}}
                        </div>
                      </div>
                      <div *ngIf="numberOfKidsUnder18>=3" class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{noStayAmountKidNo3| currency}}
                        </div>
                      </div>
                    </div>

                    <!-- <div *ngIf="conference.Dec27=='StayDec27'" class="row">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Stay EC Previous Day:':'前泊：'}}
                      </div>
                      <div class="col-6 col-sm-6 col-md-3">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          'Dec 27'
                          :
                          '12月27日'
                          }}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{'$80'}}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div *ngIf="numberOfKidsUnder18>=1 && !kidNo1WithNoCharge; else myBlank" class="summaryValue">
                          {{'$80'}}
                        </div>
                        <ng-template #myBlank>
                          {{''}}
                        </ng-template>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div  *ngIf="numberOfKidsUnder18>=2 && !kidNo2WithNoCharge; else myBlank" class="summaryValue">
                          {{'$80'}}
                        </div>
                        <ng-template #myBlank>
                          {{''}}
                        </ng-template>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div  *ngIf="numberOfKidsUnder18>=3  && !kidNo3WithNoCharge; else myBlank" class="summaryValue">
                          {{'$80'}}
                        </div>
                        <ng-template #myBlank>
                          {{''}}
                        </ng-template>
                      </div>
                    </div> -->

                    <!-- <div *ngIf="conference.MySleepingBag=='BorrowMySleepingBag'" class="row">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Sleeping Bag:':'寝袋：'}}
                      </div>
                      <div class="col-6 col-sm-6 col-md-3">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          conference.MySleepingBag=='BorrowMySleepingBag'?'I need':''
                          :
                          conference.MySleepingBag=='BorrowMySleepingBag'?'借りる':''
                          }}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div *ngIf="conference.MySleepingBag=='BorrowMySleepingBag'" class="summaryValue">
                          {{'$15'}}<br>
                        </div>
                      </div>
                    </div> -->

                    <!-- <div *ngIf="conference.BringExtraSleepingBag==true" class="row">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Extra Sleeping Bag:':'寝袋エクストラ：'}}
                      </div>
                      <div class="col-6 col-sm-6 col-md-3">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          conference.BringExtraSleepingBag==true?'I can rent out':''
                          :
                          conference.BringExtraSleepingBag==true?'貸し出せる':''
                          }}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{this.router.url.includes('EN')==true?
                          conference.BringExtraSleepingBag==true?'- $'+10*this.conference.CountExtraSleepingBag:''
                          :
                          conference.BringExtraSleepingBag==true?'- $'+10*this.conference.CountExtraSleepingBag:''
                          }}
                        </div>
                      </div>
                    </div> -->

                    <div *ngIf="conference.NeedCommingBus==true" class="row">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Bus to venue:':'会場へのバス：'}}
                      </div>
                      <div class="col-6 col-sm-6 col-md-3">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          conference.NeedCommingBus==true?'I need.':'I do not need.'
                          :
                          conference.NeedCommingBus==true?'必要':'不要'
                          }}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{'$50'}}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div *ngIf="numberOfKidsUnder18>=1  && !kidNo1WithNoCharge; else myBlank" class="summaryValue">
                          {{'$50'}}
                        </div>
                        <ng-template #myBlank>
                          {{''}}
                        </ng-template>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div *ngIf="numberOfKidsUnder18>=2  && !kidNo2WithNoCharge; else myBlank" class="summaryValue">
                          {{'$50'}}
                        </div>
                        <ng-template #myBlank>
                          {{''}}
                        </ng-template>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div *ngIf="numberOfKidsUnder18>=3  && !kidNo3WithNoCharge; else myBlank" class="summaryValue">
                          {{'$50'}}
                        </div>
                        <ng-template #myBlank>
                          {{''}}
                        </ng-template>
                      </div>
                    </div>

                    <div *ngIf="conference.NeedReturningBus==true" class="row">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Bus to airport:':'空港へのバス：'}}
                      </div>
                      <div class="col-6 col-sm-6 col-md-3">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          conference.NeedReturningBus==true?'I need.':'I do not need.'
                          :
                          conference.NeedReturningBus==true?'必要':'不要'
                          }}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{'$50'}}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div *ngIf="numberOfKidsUnder18>=1  && !kidNo1WithNoCharge; else myBlank" class="summaryValue">
                          {{'$50'}}
                        </div>
                        <ng-template #myBlank>
                          {{''}}
                        </ng-template>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div *ngIf="numberOfKidsUnder18>=2  && !kidNo2WithNoCharge; else myBlank" class="summaryValue">
                          {{'$50'}}
                        </div>
                        <ng-template #myBlank>
                          {{''}}
                        </ng-template>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div *ngIf="numberOfKidsUnder18>=3  && !kidNo3WithNoCharge; else myBlank" class="summaryValue">
                          {{'$50'}}
                        </div>
                        <ng-template #myBlank>
                          {{''}}
                        </ng-template>
                      </div>
                    </div>

                    <div class="row">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Sub Total:':'小計：'}}
                      </div>
                      <div class="col-6 col-sm-6 col-md-3">
                        <div class="summaryTitle">

                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{subTotalMyself| currency}}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div *ngIf="numberOfKidsUnder18>=1" class="summaryValue">
                          {{subTotalKidNo1| currency}}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div *ngIf="numberOfKidsUnder18>=2" class="summaryValue">
                          {{subTotalKidNo2| currency}}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div *ngIf="numberOfKidsUnder18>=3" class="summaryValue">
                          {{subTotalKidNo3| currency}}
                        </div>
                      </div>
                    </div>

                    <div *ngIf="conference.ConferenceDiscountAmount!=null" class="row">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Discount, Honorarium:':'割引、謝礼：'}}
                      </div>
                      <div class="col-9 col-sm-9 col-md-12">
                        <div class="discountValues">
                          - &nbsp;{{conference.ConferenceDiscountAmount | currency}}
                        </div>
                      </div>
                    </div>

                    <div
                      *ngIf="conference.ApplyingScholarshipAmount!=null || conference.ApprovedScholarshipAmount!=null "
                      class="row">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Scholarship :':'奨学金：'}}
                      </div>
                      <div class="col-6 col-sm-6 col-md-3">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          ('Applied: '+ conference.ApplyingScholarshipAmount )
                          :
                          ('申請額: '+ conference.ApplyingScholarshipAmount)
                          }}
                        </div>
                      </div>
                      <div class="col-9 col-sm-9 col-md-12">
                        <div class="discountValues">
                          {{this.router.url.includes('EN')==true?
                          'Approved:':'承認額:'}}
                          - &nbsp;{{conference.ApprovedScholarshipAmount | currency}}
                        </div>
                      </div>
                    </div>

                    <div *ngIf="conference.ApplicationFeePaidDate!=null" class="row">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Application Fee :':'申込金：'}}
                      </div>
                      <div class="col-9 col-sm-9 col-md-12">
                        <div class="discountValues">
                          - &nbsp;{{conference.ApplicationFee | currency}}
                        </div>
                      </div>
                    </div>
                    <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                    <div>
                      {{this.router.url.includes('EN')==true?'Total :':'総計：'}}
                    </div>
                    <div class="col-9 col-sm-9 col-md-12">
                      <div class="summaryValueTotal">
                        {{this.temporalBalanceAmount | currency}}
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
            <br><br>

            <div class="stepTitle2nd">
              {{this.router.url.includes('EN')==true?'Do you have any allergies? ':
              '何かアレルギーはありますか？'}}
            </div>

            <div class="d-flex justify-content-center">
              <mat-radio-group formControlName="myHasAllergy" (change)="onChangeHasAllergy($event)" class="rbGroup">
                <mat-radio-button value=true class="rbItem" [checked]="conference.HasAllergy==true">
                  {{this.router.url.includes('EN')==true?'Yes': 'はい'}}</mat-radio-button>
                <mat-radio-button value=false class="rbItem" [checked]="conference.HasAllergy==false">
                  {{this.router.url.includes('EN')==true?'No': 'いいえ'}}</mat-radio-button>
              </mat-radio-group>
              <mat-error
                *ngIf="myFormGroup.get('myHasAllergy').hasError('required')  && myFormGroup.get('myHasAllergy').touched">
                {{this.router.url.includes('EN')==true?'Required': '未入力'}}
              </mat-error>
            </div>
            <br>
            <br>

            <div class="d-flex justify-content-center">
              <mat-form-field appearance="outline" class="registrationInputFormTextArea">
                <mat-label>
                  {{this.router.url.includes('EN')==true?'Please list any specific foods or substances and provide a
                  description of necessary accommodations.': '食べ物の一覧や、必要な対応の説明を書いてください。'}}
                </mat-label>
                <textarea matInput placeholder="" formControlName="myAllergyDetail"
                  [disabled]="disableSelectingHasAllergy" class="registrationInputBox">
                      </textarea>
                <mat-error>
                  {{this.router.url.includes('EN')==true?'Required': '未入力'}}
                </mat-error>
              </mat-form-field>
            </div>


            <div class="d-flex justify-content-center">
              <div class="processButtonSet">
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn btnProcess" (click)="onClickGoBack()">
                    {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                  </button>
                  <button [disabled]="!myFormGroup.valid" type="button" class="btn btnProcess"
                    (click)="onClickGoForward()">
                    {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                  </button>
                </div>
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn link btnCancel" (click)="cancelProcess()">
                    {{this.router.url.includes('EN')==true?'Cancel': 'キャンセル'}}
                  </button>
                </div>
              </div>
            </div>


            <!-- <button type="submit" [disabled]="!myFormGroup.valid">Submit</button> -->
          </form>
        </mat-card>

      </div>
      <div class="myMainHalfBottom">
        <!-- Main Bottom -->
      </div>
    </div>
    <div class="myMainRight">
      <div class="myMainHalfTop">
        <!-- Right Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Right Bottom -->
      </div>
    </div>
  </div>
  <!-- <footer>footer</footer> -->
</div>
