<div class="myBase">
  <div class="myBackgroundLayer">
    <div class="float-end JCFNMenuTop">
      <div *ngIf="this.authService.isLoggedIn() && this.authService.checkLoginUserAccessJCFN()">
        <div class="committeeText">
          JCFN Staff Only
        </div>
        <form [formGroup]="firstFormGroup">
          <div class="row">

            <div class="col jcfnAuthorization">
              <label>
                {{this.router.url.includes('EN')==true?'Authorized to access JCFN Staff menu':
                'JCFNスタッフメニューへのアクセス権'}}
              </label><br>
              <mat-radio-group formControlName="myJCFNMenuAccess" [(ngModel)]="strAccessJCFN"
                (change)="onChangeJCFNAccess($event)" class="radioBtnMain">
                <mat-radio-button class="radioBtnItem" value='true' class="radioCommitteeItem"
                  [checked]="user.JCFNAccess==true">{{this.router.url.includes('EN')==true?'Yes':
                  '与える'}}
                </mat-radio-button>
                <mat-radio-button class="radioBtnItem" value='false' class="radioCommitteeItem"
                  [checked]="user.JCFNAccess==false || user.JCFNAccess==null">
                  {{this.router.url.includes('EN')==true?'No':
                  '与えない'}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="col deletingBotton">
              <div *ngIf="deleting" class="progressMessage">
                {{this.router.url.includes('EN')==true?'Deleting...': '削除中...'}}
              </div>
              <button mat-button (click)="onConfirmDelete()"
                class="btnDeleteRegistrant">{{this.router.url.includes('EN')==true?'Delete Account':
                'アカウント削除'}}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row ECmyAccountHeader">
      <div class="d-flex justify-content-center ">
        <h2>
          <i class="fas fa-user fa-2x"></i>&nbsp;
          {{this.router.url.includes('EN')==true?'My Account': 'マイアカウント'}}<br>
          {{user.Myoji}}&nbsp;{{user.Shimei}}&nbsp;&nbsp;&nbsp;{{user.FirstName}}&nbsp;{{user.LastName}}
        </h2>
      </div>
    </div>
    <br><br>
    <div *ngIf="!loaded">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>


    <!-- <div class="smallAnnouncement">
        <div >
          <mat-card class="ec20BeyondReunionAnnouncement">
            <div *ngIf="this.authService.isLoggedIn() && (registrant==null || (registrant!= null && registrant.Attend!=true))"
            class="header-supportingMember  b-radius">
              <div class="d-flex justify-content-center">
                <button (click)="onClickRegistration()" class="btnGoSustainingMembership b-radius">
                  {{this.router.url.includes('EN')==true?'EC20 Beyond Reunion':
                  'EC20 Beyond Reunion'}}<br>
                  {{this.router.url.includes('EN')==true?'Registration':
                  'レジストレーション'}}
                </button>
              </div>
              <br>
              <mat-label class="lblComment d-flex justify-content-center">
                {{this.router.url.includes('EN')==true?'You can register here .': 'こちらからレジストができます。'}}<br>
              </mat-label>
            </div>
            <div *ngIf="this.authService.isLoggedIn() && registrant!=null && registrant.Attend==true"
            class="header-supportingMember  b-radius">
              <div
              class="d-flex justify-content-center">
                <button (click)="onClickRegistration()" class="btnGoSustainingMembership b-radius">
                  {{this.router.url.includes('EN')==true?'EC20 Beyond Reunion':
                  'EC20 Beyond Reunion'}}<br>
                  {{this.router.url.includes('EN')==true?'Registration':
                  'レジストレーション'}}
                </button>
              </div><br>
              <div class="d-flex justify-content-center">
                <mat-label class="lblComment d-flex justify-content-center">
                  {{this.router.url.includes('EN')==true?'You already completed registration. ': 'あなたは既にレジスト済です。'}}<br>
                  {{this.router.url.includes('EN')==true?'You can update the registration.': 'こちらから変更ができます。'}}
                </mat-label>
              </div>
              <br>
            </div>
          </mat-card>
        </div>
      </div> -->
    <!--
      <div *ngIf="loaded" class="myAccountRegistrationCard">
        <div class="d-flex justify-content-center">
            <mat-accordion class="example-headers-align myPanel">
                <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle
                    [ngClass]="(user.RegisteredDate !=null && conference.TermsOfParticipation==true) ? 'processCompleted':'processNotCompleted'">
                    <mat-expansion-panel-header class="myExtendPanel">
                        <mat-panel-title>
                            {{this.router.url.includes('EN')==true?'Step 1': 'ステップ１'}}
                        </mat-panel-title>
                        <mat-panel-title>
                            <i class="fas fa-user fa-2x"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {{this.router.url.includes('EN')==true?'Personal Information': '個人情報'}}
                        </mat-panel-title>
                        <mat-panel-description
                            [ngClass]="(user.RegisteredDate !=null && conference.TermsOfParticipation==true)  ? 'txtSuccess':'txtError'">
                            {{this.router.url.includes('EN')==true?
                            ((user.RegisteredDate !=null && conference.TermsOfParticipation==true) ?'completed':'Not completed'):
                            ((user.RegisteredDate !=null && conference.TermsOfParticipation==true) ?'完了':'未完了')
                            }}
                            <button mat-button (click)="onClickEditRegistrationPersonal()"
                                class="btnRegistrantUpdate" cdkFocusInitial>
                                {{this.router.url.includes('EN')==true?'Edit':'編集'}}
                            </button>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="row">
                        <div class="registrationStepDetail">
                            {{this.router.url.includes('EN')==true?'Registererd : ':'登録日 : '}}
                            {{user.RegisteredDate |
                            date:"yyyy/MM/dd"}}
                        </div>
                    </div>
                    <mat-action-row>
                        <button mat-button color="primary" (click)="nextStep()">
                            {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                        </button>
                    </mat-action-row>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle
                    [ngClass]="conference.TotalAmount !=null || conference.Online==true? 'processCompleted':'processNotCompleted'">
                    <mat-expansion-panel-header class="myExtendPanel">
                        <mat-panel-title>
                            {{this.router.url.includes('EN')==true?'Step 2': 'ステップ２'}}
                        </mat-panel-title>
                        <mat-panel-title>
                            <i class="fas fa-users fa-2x"></i>&nbsp;&nbsp;&nbsp;
                            {{this.router.url.includes('EN')==true?'Conference Information':
                            'カンファレンス情報'}}
                        </mat-panel-title>
                        <mat-panel-description
                            [ngClass]="conference.TotalAmount !=null || conference.Online==true ? 'txtSuccess':'txtError'">
                            {{this.router.url.includes('EN')==true?
                            (conference.TotalAmount !=null || conference.Online==true ? 'completed':'Not completed'):
                            (conference.TotalAmount !=null || conference.Online==true ? '完了':'未完了')
                            }}
                            <div *ngIf="user.RegisteredDate!=null && conference.TermsOfParticipation==true">
                            <button mat-button (click)="onClickEditRegistrationConference()"
                                class="btnRegistrantUpdate" cdkFocusInitial
                                >
                                {{this.router.url.includes('EN')==true?
                                (conference.TotalAmount !=null ?'Edit':'Start'):
                                (conference.TotalAmount !=null ?'編集':'スタート')
                                }}
                            </button>
                            </div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="row">
                        <div class="registrationStepDetail">
                            {{this.router.url.includes('EN')==true?'Registererd : ':'登録日 : '}}
                            {{conference.RegisteredDate |
                            date:"yyyy/MM/dd"}}
                        </div>
                    </div>
                    <mat-action-row>
                        <button mat-button color="warn" (click)="prevStep()">
                            {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                        </button>
                        <button mat-button color="primary" (click)="nextStep()">
                            {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                        </button>
                    </mat-action-row>
                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle
                    [ngClass]="(conference.PaymentDate !=null || conference.TotalAmount==0 || conference.Online==true) ? 'processCompleted':'processNotCompleted'">
                    <mat-expansion-panel-header class="myExtendPanel myPaymentPanel">
                        <mat-panel-title>
                            {{this.router.url.includes('EN')==true?'Step 3': 'ステップ３'}}
                        </mat-panel-title>
                        <mat-panel-title>
                            <i class="fas fa-shopping-cart  fa-2x"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {{this.router.url.includes('EN')==true?'Payment': '支払い'}}
                        </mat-panel-title>
                        <mat-panel-description
                            [ngClass]="(conference.PaymentDate !=null || conference.TotalAmount==0 || conference.Online==true) ? 'txtSuccess':'txtError'">
                            {{this.router.url.includes('EN')==true?
                            ((conference.PaymentDate !=null || conference.TotalAmount==0 || conference.Online==true) ?'completed':'Not completed'):
                            ((conference.PaymentDate !=null  || conference.TotalAmount==0 || conference.Online==true) ?'完了':'未完了')
                            }}
                            <div *ngIf="conference.TotalAmount!=null">
                            <button mat-button (click)="onClickEditRegistrationPayment()"
                                class="btnRegistrantUpdate" cdkFocusInitial>
                                {{this.router.url.includes('EN')==true?
                                (conference.RegisteredDate !=null ?'Edit':'Start'):
                                (conference.RegisteredDate !=null ?'編集':'スタート')
                                }}
                            </button>
                            </div>

                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="row">
                        <div class="registrationStepDetail">
                            {{this.router.url.includes('EN')==true?'Paid Date : ':'支払い日 : '}}
                            {{conference.PaymentDate |
                            date:"yyyy/MM/dd"}}
                        </div>
                        <div class="registrationStepDetail">
                            {{this.router.url.includes('EN')==true?'Fee : ':'参加費 : '}}
                            {{conference.TotalAmount
                            | currency:'JPY':'symbol'}}
                        </div>
                    </div>
                    <mat-action-row>
                        <button mat-button color="warn" (click)="prevStep()">
                            {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                        </button>
                        <button mat-button color="primary" (click)="nextStep()">
                            {{this.router.url.includes('EN')==true?'Close': '閉じる'}}
                        </button>
                    </mat-action-row>
                </mat-expansion-panel>

            </mat-accordion>
        </div>
      </div>

 -->

    <!--##################################################################################################
#########################################################################################################-->
    <div class="d-flex justify-content-center">
      <div *ngIf="hideMembershipChangeOption!=true">
        <div *ngIf="!this.authService.isLoggedIn() || this.checkSupportMembershipPayment()==undefined">
          <mat-card class="d-flex justify-content-center supportingMembershipAnounce">
          <div class="col">
           <!-- Non member -->
            <div *ngIf="user.MembershipType==null" class="header-supportingMember  b-radius">
              <div class="d-flex justify-content-center">
                <button (click)="onClickBecomeMember()" class="btnGoSustainingMembership b-radius">
                  {{this.router.url.includes('EN')==true?'Become Member':
                  '会員になる'}}
                </button>
              </div>
              <br>
              <mat-label class="lblComment d-flex justify-content-center">
                {{this.router.url.includes('EN')==true?'You can process from here to become supporting or regular member.': 'こちらから維持会員、もしくは一般会員になる手続きができます。'}}<br>
              </mat-label>
            </div>
            <!-- Regular member -->
            <div *ngIf="user.MembershipType!=null" class="header-supportingMember  b-radius">
              <div *ngIf="user.MembershipType!=null && user.MembershipType.toLowerCase()=='regular'">
                <div class="d-flex justify-content-center">
                  <mat-label class="lblComment d-flex justify-content-center">
                    {{this.router.url.includes('EN')==true?'You are ': 'あなたは'}}
                    {{this.router.url.includes('EN')==true?user.MembershipType + ' member'
                    :
                    (user.MembershipType=='Regular'?'一般会員':(user.MembershipType=='Supporting'?'維持会員':'その他'))}}
                    {{this.router.url.includes('EN')==true?'.': 'です。'}}
                  </mat-label><br><br>
                </div>
                <br>
                <div class="d-flex justify-content-center">
                  <button (click)="onClickChangeMembershipTypeToSupporting()"
                    class="btnGoSustainingMembership b-radius">
                    {{this.router.url.includes('EN')==true?'Become supporting member':
                    '維持会員になる'}}
                  </button>
                </div>
              </div>
            </div>
            <!-- Supporting member with recurring -->
            <div *ngIf="user.MembershipType!=null" class="header-supportingMember  b-radius">
              <div *ngIf="supportingMemberValidity">
                <div class="d-flex justify-content-center">
                  <mat-label class="lblComment d-flex justify-content-center">
                    {{this.router.url.includes('EN')==true?'You are ': 'あなたは'}}
                    {{this.router.url.includes('EN')==true?user.MembershipType + ' member'
                    :
                    (user.MembershipType=='Regular'?'一般会員':(user.MembershipType=='Supporting'?'維持会員':'その他'))}}
                    {{this.router.url.includes('EN')==true?'.': 'です。'}}
                  </mat-label><br><br>
                </div>
                <br>
                 <div *ngIf="recurringSign" class="d-flex justify-content-center">
                  {{this.router.url.includes('EN')==true?'Recurring activated':
                  '自動更新に設定されています。'}}
                </div>
                <div *ngIf="lastValidDate!=null" class="d-flex justify-content-center">
                  {{this.router.url.includes('EN')==true?'Valid until ':'有効期限 '}}
                  {{ lastValidDate | date: 'mediumDate' }}
                </div>
              </div>
            </div>
            <!-- Regular member because Supporting has expired -->
            <div *ngIf="user.MembershipType!=null" class="header-supportingMember  b-radius">
                <div *ngIf="supportingMemberValidity!=true && user.MembershipType!=null && user.MembershipType.toLowerCase()=='supporting'">
                <div class="d-flex justify-content-center">
                  <mat-label class="lblComment d-flex justify-content-center">
                    {{this.router.url.includes('EN')==true?'You are regular member': 'あなたは一般会員です。'}}
                    <br>
                    {{this.router.url.includes('EN')==true?'Supporting Membership expired on ': '維持会員は'}}
                    {{lastValidDate | date: 'mediumDate'}}
                    {{this.router.url.includes('EN')==true?'': 'に期限切れになりました。'}}
                  </mat-label><br><br>
                </div>
                <br>
                <div class="d-flex justify-content-center">
                  <button (click)="onClickChangeMembershipTypeToSupporting()"
                    class="btnGoSustainingMembership b-radius">
                    {{this.router.url.includes('EN')==true?'Become supporting member':
                    '維持会員になる'}}
                  </button>
                </div>
              </div>
            </div>
          </div>
           <!-- <div class="col">
            <div class="header-supportingMember  b-radius">
              <div class="d-flex justify-content-center">
                <button  class="btnGoSustainingMembership b-radius">
                  {{this.router.url.includes('EN')==true?'Attend EC22':
                  'EC22に参加する'}}
                </button>
              </div><br>
              <mat-label class="lblComment d-flex justify-content-center">
                {{this.router.url.includes('EN')==true?'US West Coast: Dec27,2022 〜 Jan1,2023' : '米西海岸：2022年12月28日 〜 2023年1月1日'}}
              </mat-label>
              <br><br>
              <mat-label class="lblComment d-flex justify-content-center">
                {{this.router.url.includes('EN')==true?'You can register from here.': 'こちらから参加手続きができます。'}}<br>
                {{this.router.url.includes('EN')==true?'If you already registered, you can check your registration orupdate your info.'
                : '既に登録済みの方は、ログインすると登録内容の確認や変更ができます。'}}<br>
              </mat-label>
            </div>
           </div> -->
          </mat-card>
        </div>
        <!-- <div *ngIf="this.authService.isLoggedIn() && this.checkSupportMembershipPayment()=='processing'">
          <mat-card class="supportingMembershipAnounce">
            <h3>
              {{this.router.url.includes('EN')==true?'You have already applied Support membership.': '維持会員申し込み済'}}<br>
              {{this.router.url.includes('EN')==true?'We have not confirmed payment receipt.': '現在、入金確認待ちです。'}}
            </h3>
          </mat-card>
        </div>
        <div *ngIf="this.authService.isLoggedIn() && this.checkSupportMembershipPayment()=='done'">
          <mat-card class="supportingMembershipAnounce">
            <h3>
              {{this.router.url.includes('EN')==true?'You are already registered as a Supporting Member.':
              '維持会員申し込み済'}}<br>
              {{this.router.url.includes('EN')==true?'Payment is confirmed.': '入金確認済。'}}
            </h3>
          </mat-card>
        </div> -->
      </div>
    </div>
     <div class="d-flex justify-content-center">
      <div class="row myAccountSection">
        <div class="col myAccountItem">
          <button type="button" class="btn btn-link myModule" (click)="onClickBasicInfo()">
            <div class="itemHeader">
              {{this.router.url.includes('EN')==true?'Basic Information': '基本情報'}}
            </div>
            <div class="itemDetail">
              {{this.router.url.includes('EN')==true?'Name, Address etc.': '名前、住所等々'}}
            </div>
          </button>
        </div>
        <!-- <div class="col myAccountItem">
          <button type="button" class="btn btn-link myModule" (click)="onClickDonationHistory()">
            <div class="itemHeader">
              {{this.router.url.includes('EN')==true?'Donation History': '献金履歴'}}
            </div>
            <div class="itemDetail">
              {{this.router.url.includes('EN')==true?'Donation History through Authorize.net':
              'Authorize.netによる献金履歴'}}
            </div>
          </button>
        </div> -->
        <!-- <div class="col myAccountItem">
          <button type="button" class="btn btn-link myModule" (click)="onClickConferenceHistory()">
            <div class="itemHeader">
              {{this.router.url.includes('EN')==true?'Conference': 'カンファレンス'}}
            </div>
            <div class="itemDetail">
              {{this.router.url.includes('EN')==true?'Conference Attending history hosted by JCFN':
              'JCFN主催カンファレンス参加履歴'}}
            </div>
          </button>
        </div> -->
        <div class="col myAccountItem">
          <button type="button" class="btn btn-link myModule" (click)="onClickMemberSetting()">
            <div class="itemHeader">
              {{this.router.url.includes('EN')==true?'Membership Setting': '会員設定'}}<br>
            </div>
            <div class="itemDetail">
              {{this.router.url.includes('EN')==true?'JCFN Staff Only': 'JCFNスタッフオンリー'}}<br>
              {{this.router.url.includes('EN')==true?'Enter Supporting member info': '維持会員情報の入力'}}
            </div>
          </button>
        </div>
        <div class="col myAccountItem">
          <button type="button" class="btn btn-link myModule" (click)="onClickUploadDocuments()">
            <div class="itemHeader">
              {{this.router.url.includes('EN')==true?'Upload documents': '書類アップロード'}}<br>
            </div>
            <div class="itemDetail">
              {{this.router.url.includes('EN')==true?'Documents Archive': '提出書類のアーカイブ'}}
            </div>
          </button>
        </div>
      </div>
    </div>

  </div>
