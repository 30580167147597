import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl, FormArray} from '@angular/forms';
import { UserService} from "../../../../services/user/user.service";
import { Router, ActivatedRoute,NavigationExtras } from "@angular/router";
import { MatRadioChange } from '@angular/material/radio';
import { ECConference } from 'src/app/models/conference/ECConference';
import { User } from 'src/app/models/user';
import { CancelOrSaveConfirmationComponent } from 'src/app/components/dialog/cancel-or-save-confirmation/cancel-or-save-confirmation.component';
//For Dialog
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA , MatDialogConfig} from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { EcRegistrationService } from 'src/app/services/conference/ec/ec-registration.service';
import { RelatedPerson} from "../../../../models/conference/RelatedPerson";
import { VolunteerSelection} from "../../../../models/conference/volunteerSelection";
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { RegistrationConferenceEmbedComponent } from 'src/app/components/dialog/EC/registration-conference-embed/registration-conference-embed.component';
import { MyAccountEditComponent } from 'src/app/components/dialog/EC/my-account-edit/my-account-edit.component';
@Component({
  selector: 'app-room-info',
  templateUrl: './room-info.component.html',
  styleUrls: ['./room-info.component.css']
})
export class RoomInfoComponent implements OnInit {
  loaded:boolean=true;
  myFormGroup: FormGroup;
  myMessage:string;
  showRelatedPerson?:boolean;
  added?:boolean;
  showInquireChildCare?:boolean=true;
  validRelatedPerson?:boolean;

  public gendersJP = [
    { value: "Man", display: "男性" },
    { value: "Woman", display: "女性" },
  ];
  public gendersEN = [
    { value: "Man", display: "Man" },
    { value: "Woman", display: "Woman" },
  ];

  user: User = {
    PasswordHash:null,
    Id:null,
    UserName:null,
    Email:null,
    FirstName:null,
    LastName:null,
    Password:null,
    Prefix:null,
    Shimei:null,
    Myoji:null,
    Street:null,
    Street2:null,
    City:null,
    State:null,
    Zip:null,
    Country:null,
    Yubin_Bango:null,
    To_Do_Fu_Ken:null,
    Shi_Gun_Ku:null,
    Cho_Son:null,
    Apartment_Etc:null,
    To_Do_Fu_Ken_JP:null,
    Shi_Gun_Ku_JP:null,
    Cho_Son_JP:null,
    Apartment_Etc_JP:null,
    Gender:null,
    DOB:null,
    TelNo:null,
    TimezoneName:null,
    UTCdiff:null,
    Token:null,
    Occupation:null,
    MemberStatus:null,
    Married:null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount:null,
    RegisteredDate:null,
    LastAccessedDate:null,
    //From siteground
    LoginId:null,
    MiddleNameEN:null,
    MiddleNameJP:null,
    PrefixJP:null,
    OccupationDetail:null,
    Organization:null,
    LivingCountry:null,
    PhoneEN:null,
    PhoneMobileEN:null,
    PhoneJP:null,
    PhoneMobileJP:null,
    ChurchNameEN:null,
    ChurchNameJP:null,
    MemberType:null,
    MemberDetail:null,
    MemberMemo:null,
    Shokaisha:null,
    Reason:null,
    NewsLetters:null,
    StaffReports:null,
    OtherLetters:null,
    NewsLetterLanguage:null,
    BasicRoles:null,
    //Added new
    MigrateFromSiteGround:null,
    AdminAccess:null,
    JCFNAccess:null,
    SupportingMember:null,
    LastAddressUpdatedDate:null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };
  relatedPerson:RelatedPerson={
    Id:null,
    RelatedId:null,
    Email:null,
    FirstNameRelated:null,
    LastNameRelated:null,
    MyojiRelated:null,
    ShimeiRelated:null,
    DOBRelated:null,
    GenderRelated:null,
    ChildCare:null,
    Under2YearsOld:null
  };
  relatedPersons:RelatedPerson[]=[];
  volunteerSelections:VolunteerSelection[]=[];
  conference: ECConference= {
    ECConferenceId:0,
    UserId: null,
    Email: null,
    UserName: null,
    FirstName: null,
    LastName: null,
    //Program
    ProgramType: null,
    SmallGroupLanguage: null,
    AttendJapanRegionalMeeting:null,
    JapanRegionalMeeting:null,
    ApplyEnglishPartner:null,
    //Payment
    ConferenceFee: null,
    ConferenceDiscountAmount: null,
    ConferenceFeePaidAmount: null,
    ConferenceFeePaidDate: null,
    CouponApproved: null,
    ApplyScholarship: null,
    ApplyingScholarshipAmount: null,
    ApprovedScholarshipAmount: null,
    ApplyingReason: null,
    //Others\
    Volunteers:null,
    Student:null,
    ApplyCounseling: null,
    MySleepingBag:null,
    BringExtraSleepingBag:null,
    CountExtraSleepingBag:null,
    NeedCommingBus:null,
    CommingBusDeparturePlace:null,
    CommingBusDepartureDateTime:null,
    CommingAirline:null,
    CommingFlightNo:null,
    DepartureCity:null,
    CommingInternationalDomestic:null,
    ArrivalDateTime:null,
    NeedReturningBus:null,
    ReturningBusDepartureDateTime:null,
    ReturningAirline:null,
    ReturningFlightNo:null,
    ReturningCity:null,
    ReturningBusDeparturePlace:null,
    ReturningInternationalDomestic:null,
    DepartureDateTime:null,
    RoomInfo:null,
    Couple:null,
    Youth:null,
    YouthGrade: null,
    GuardianFirstName: null,
    GuardianLastName: null,
    GuardianMyoji: null,
    GuardianShimei: null,
    GuardianEmail: null,
    GuardianTelNo: null,
    TermsOfParticipation: null,
    TermsOfParticipationParent: null,
    TermsOfParticipationDate: null,
    TermsOfParticipationDateParent: null,
    RegisteredDate: null,
    UpdatedDate: null,

    AdminAccess: null,
    ECCommitteeAccess: null,
    Memo: null,
    Dec26:null,
    Dec27:null,
    Dec28:null,
    Dec29:null,
    Dec30:null,
    Dec31:null,
    KidGroup1:null,
    KidGroup2:null,
    KidGroup3:null,
    KidGroup4:null,
    myRelatedPersons: this.relatedPersons,
    myVolunteerSelections:this.volunteerSelections,
    BusComingConfirmation:null,
    BusReturningConfirmation:null,
    TermsOfParticipationVenue:null,
    TermsOfParticipationDateVenue:null,
    TermsOfParticipationYouth:null,
    TermsOfParticipationDateYouth:null,
    TermsOfParticipationYouthParent:null,
    TermsOfParticipationDateYouthParent:null,
    EmergencyContactFirstName:null,
    EmergencyContactLastName:null,
    EmergencyContactEmail:null,
    EmergencyContactTelNo:null,
    ApplicationFee:null,
    ApplicationFeePaidDate:null,
    BalanceAmount:null,
    BalanceAmountPaidDate:null,
    HasAllergy:null,
    AllergyDetail:null,
    BringInsurance:null,
    MinorHasCustodyInsurance:null,
    MinorHasCustodyInsuranceDate:null,
    InsuranceCompany:null,
    InsuranceAddress:null,
    InsuranceTelNo:null,
    InsurancePolicyNo:null,
    InsuranceGroupNo:null,
    VolunteerAgreeConductCode:null,
    VolunteerAgreeConductCodeDate:null,
    PayingAmount:null,
     //New
    BecomeMentor:null,
    ApplyMentoring:null,
    ReturningDepartureCity:null,
    InsuranceStatus:null,
    DressCodeAgreeYouth:null,
    DressCodeDateYouth:null,
    DressCodeAgreeParent:null,
    DressCodeDateParent:null
}

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private registrationService: UserService,
    private ecRegistrationService : EcRegistrationService,
    private dialog: MatDialog,
  ) { }

  mySearch: any = {};
  ngOnInit(): void {
    this.validRelatedPerson=true;
    this.myFormGroup = this._formBuilder.group({
      myRoomShareType: [null, Validators.required],
      mySpouseFirstName: [null, ],
      mySpouseLastName: [null, ],
      mySpouseMyojiName: [null, ],
      mySpouseShimeiName: [null, ],
      myFirstNameRelated:[null,],
      myLastNameRelated:[null,],
      myMyojiRelated:[null,],
      myShimeiRelated:[null,],
      DOBRelated:[null,],
      relativePersonsOz:this._formBuilder.array([
      ])
    });

    if (sessionStorage.getItem("myECSearchingUserId") != null) {
      /*************************************************
       *  A) User info for searching registrant by admin
       *************************************************/
       this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnConferenceAdmin"));
       if (this.mySearch != null) {
         this.conference = this.mySearch.myEditingConference;
         this.patchModelValue(this.conference);
         this.retrievePersonFromDb(this.conference);
         this.loaded = true;
       }else{
        this.loaded = true;
       }
    } else {
    /*####################################################################################
    # Get conference info from previous page for myself
    #####################################################################################*/
    this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnConference"));
      if (this.mySearch != null) {
        this.conference = this.mySearch.myEditingConference;
        this.patchModelValue(this.conference);
        this.retrievePersonFromDb(this.conference);
        this.loaded = true;
      }else{
      }
    }
  }

  relativePersonsOz() :FormArray {
    return this.myFormGroup.get("relativePersonsOz") as FormArray
  }

  retrievePersonFromDb(model:ECConference){
    if(model.myRelatedPersons!=null && model.myRelatedPersons.length>0){
      model.myRelatedPersons.forEach( person=> {
      var personFromDb = this._formBuilder.group({
      RelatedId:[model.UserId],
      Email:[model.Email],
      FirstNameRelated: [person.FirstNameRelated, ],
      LastNameRelated: [person.LastNameRelated, ],
      MyojiRelated: [person.MyojiRelated, ],
      ShimeiRelated: [person.ShimeiRelated, ],
      DOBRelated:[person.DOBRelated,],
      ChildCare:[person.ChildCare],
      GenderRelated:[person.GenderRelated,],
      Under2YearsOld:[person.Under2YearsOld]
    })
    if(this.calculate_age(person.DOBRelated) ){
      this.showInquireChildCare=true;
    }

    this.relativePersonsOz().push(personFromDb);
    });
    }
    this.added = true;
  }

  newPerson(): FormGroup {
    return this._formBuilder.group({
      RelatedId:[this.conference.UserId],
      Email:[this.conference.Email],
      FirstNameRelated: [null, ],
      LastNameRelated: [null, ],
      MyojiRelated: [null, ],
      ShimeiRelated: [null, ],
      DOBRelated:[null,],
      ChildCare:[null,],
      GenderRelated:[null,],
    })
  }

  addPerson() {
    this.relativePersonsOz().push(this.newPerson());
    this.added = true;
  }

  removePerson(i:number) {
   // alert('deleting:'+i)
    this.relativePersonsOz().removeAt(i);
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  patchModelValue(model:ECConference){
    this.myFormGroup.patchValue({
      myRoomShareType:model.RoomInfo
    });

    if(model.RoomInfo =="WithFamily" || model.RoomInfo=="ShareWithOthers"){
      this.showRelatedPerson=true;
      if(model.myRelatedPersons!=null && model.myRelatedPersons.length<1){
       //this.validRelatedPerson=false;
      }
    }else{
      this.showRelatedPerson=false;
      //this.validRelatedPerson=true;
    }
    if(model.RoomInfo =="WithFamily"){
      this.showInquireChildCare=true;
    }else{
      this.showInquireChildCare=false;
    }
  }
  onChangeDOB(event: MatDatepickerInputEvent<Date>,i:number) {
    console.log('clicked line#:'+i)
    console.log(event.value)
    var myAge = this.calculate_age(event.value);
    if(myAge<=2){
    // this.showInquireChildCare=true;
      this.relatedPerson.Under2YearsOld=true;
     this.myFormGroup.get('relativePersonsOz').value[i].Under2YearsOld=true;
     console.log('Value Under2YersOld is : '+ this.myFormGroup.get('relativePersonsOz').value[i].Under2YearsOld);
     console.log('Value FirstNameRelated is : '+ this.myFormGroup.get('relativePersonsOz').value[i].FirstNameRelated);
    }else{
    //  this.showInquireChildCare=false;
      this.relatedPerson.Under2YearsOld=false;
      this.myFormGroup.get('relativePersonsOz').value[i].Under2YearsOld=false;
    }
  }
  onChangeNeedChildCare(value:boolean) {
    console.log(value)
  }

  calculate_age(dob: Date) {
      var diff_ms = Date.now() - new Date(dob).getTime();
      var age_dt = new Date(diff_ms);
      return Math.abs(age_dt.getUTCFullYear() - 1970);
  }

  getFormValue() {
    this.conference.RoomInfo= this.myFormGroup.get('myRoomShareType').value;
    // this.relatedPerson.FirstNameRelated = this.myFormGroup.get('myFirstNameRelated').value;
    // this.relatedPersons.push(this.relatedPerson);
    // this.conference.myRelatedPersons = this.relatedPersons;
    if(this.conference.RoomInfo!='AnyoneIsFine'){
      this.conference.myRelatedPersons =this.myFormGroup.get('relativePersonsOz').value;
    }


 //  this.person = new this.relatedPerson
  //  fhis.myFormGroup.get('myFirstNameRelated').value;
  }

  onChangemyRoomShareType(event: MatRadioChange) {
    this.conference.RoomInfo = event.value;
    if (event.value == "WithFamily" || event.value == "ShareWithOthers") {
      this.showRelatedPerson = true;
     // this.checkNumberOfRelatedPerson();
    } else {
      this.conference.myRelatedPersons = null;
      this.showRelatedPerson = false;
    }
    if (event.value == "WithFamily") {
      this.showInquireChildCare = true;
    } else {
      this.showInquireChildCare = false;
    }
  }

  // checkNumberOfRelatedPerson(){
  //   this.registrationService.checkNumberOfRelatedPerson(this.user.Id).subscribe(result => {
  //     this.user = result;
  //     this.loaded=true;
  //   });
  // }

  onClickSavePerson(){
    // this.relatedPerson.FirstNameRelated = this.strFirstNameRelated;
    // this.relatedPerson.LastNameRelated = this.strLastNameRelated;
    // this.relatedPerson.MyojiRelated = this.strMyojiRelated;
    // this.relatedPerson.ShimeiRelated = this.strShimeiRelated;
    // this.conference.myRelatedPersons.push(this.relatedPerson);
  }

  onClickGoForward(){
    this.getFormValue();
    if(this.router.url.includes('/registration/ec/')){
      sessionStorage.removeItem("myWorkingOnConference");
      sessionStorage.setItem(
        "myWorkingOnConference",
        JSON.stringify({ myEditingConference: this.conference })
      );
      this.router.navigate(["/registration/ec/lodgingSchedule/" +this.getMyLanguage()]);
    }else{
      sessionStorage.removeItem("myWorkingOnConferenceAdmin");
      sessionStorage.setItem(
        "myWorkingOnConferenceAdmin",
        JSON.stringify({ myEditingConference: this.conference })
      );
      this.dialog.closeAll();
      this.openForwardEmbed();
    }
  }
  openForwardEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktoop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ myPage: 'lodgingSchedule' };
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  onClickGoBack(){
    if(this.router.url.includes('/registration/ec/')){
      this.router.navigate(["/registration/ec/transportation/" +this.getMyLanguage()]);
    }else{
      this.dialog.closeAll();
      this.openBackEmbed();
    }
  }
  openBackEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ myPage: 'transportation' };
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }
  onSubmit(){
  }

  cancelProcess(){
    this.getFormValue();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='210px'; //Desktop
    dialogConfig.maxWidth='370px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ conference: this.conference };
    const dialogRef = this.dialog.open(CancelOrSaveConfirmationComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
      //Retreive updated registration Info.
      //this.ngOnInit();
      let navigationExtras: NavigationExtras = {
        queryParams:this.conference
      };
      if(result!='cancel'){
        if(this.router.url.includes('/registration/ec/')){
          this.router.navigate(["/conference/EC/myAccount/" +this.getMyLanguage()], navigationExtras);
        }else{
          this.dialog.closeAll();
          this.openMyAccountEdit();
        }
        }
    })
  }

  openMyAccountEdit(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ userId: this.conference.UserId };
    const dialogRef = this.dialog.open(MyAccountEditComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }
  }
