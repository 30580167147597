<div class="myBase">
 <div class="myBackgroundLayer">
  <div class="row">
   <div class="myAccountTitle">
    <h2>
    </h2>
   </div>
  </div>
  <div class="d-flex justify-content-center">
   <mat-card class="supportingMembershipAnounce">
    <h3>
     {{this.router.url.includes('EN')==true?'Select Membership Type': '会員の種類を選んでください'}}
    </h3>


    <div class="d-flex justify-content-center">
     <button
     type="button"
     class="btn btnProcessPayment"
     (click)="onClickSelectRegularMember()">
     {{this.router.url.includes('EN')==true?'Regular Member': '一般会員'}}
     </button>
    </div>
    <div class="memberInfoDetail">
      <label>
        {{this.router.url.includes('EN')==true?'Anyone can sign up. You will receive newsletters and information about JCFN events and conferences.':
         'どなたでも登録していただけます。JCFNのイベントやカンファレンス情報等のお知らせやニュースレターを受け取ることができます。'}}
      </label>
    </div>
<br>
    <div class="d-flex justify-content-center">
      <button
      type="button"
      class="btn btnProcessPayment"
      (click)="onClickSelectSupportingMember()">
      {{this.router.url.includes('EN')==true?'Supporting Member': '維持会員'}}
      </button>
     </div>
     <div  class="memberInfoDetail">
       <label>
         {{this.router.url.includes('EN')==true?'A member who supports the JCFN with an annual membership donation of 40 (4,000 yen).':
         '年30ドル（３千円）の維持会員献金をもってJCFNを支える会員です。'}}
       </label>
     </div>
<br>
    <!-- <button mat-button (click)="onClickGoBack()" class="btn btn-link">
      {{this.router.url.includes('EN')==true?'Go Back': '戻る'}}
    </button>
     -->

    <a [routerLink]="this.router.url.includes('EN')==true?'/home/EN': '/home/JP'"
    class="btn btn-link">{{this.router.url.includes('EN')==true?'Home': 'ホーム'}}</a>&nbsp;&nbsp;
    <a [routerLink]="this.router.url.includes('EN')==true?'/members/myProfile/EN': '/members/myProfile/JP'"
    class="btn btn-link">{{this.router.url.includes('EN')==true?'My Account': 'マイアカウント'}}</a>


   </mat-card>
  </div>

  <div  class="d-flex justify-content-center">
    <button mat-button (click)="onClickGoBack()" class="btn btn-link">
       {{this.router.url.includes('EN')==true?'Go Back': '戻る'}}
    </button>
   </div>
 </div>
</div>
