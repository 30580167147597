import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';;
import { UserService} from "../../../../services/user/user.service";
import { Router, ActivatedRoute,NavigationExtras } from "@angular/router";
import { MatRadioChange } from '@angular/material/radio';
import { ECConference } from 'src/app/models/conference/ECConference';
import { User } from 'src/app/models/user';
import { CancelOrSaveConfirmationComponent } from 'src/app/components/dialog/cancel-or-save-confirmation/cancel-or-save-confirmation.component';
//For Dialog
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA , MatDialogConfig} from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { EcRegistrationService} from 'src/app/services/conference/ec/ec-registration.service';
import { RelatedPerson} from "../../../../models/conference/RelatedPerson";
import { VolunteerSelection} from "../../../../models/conference/volunteerSelection";
import { RegistrationConferenceEmbedComponent } from 'src/app/components/dialog/EC/registration-conference-embed/registration-conference-embed.component';
import { MyAccountEditComponent } from 'src/app/components/dialog/EC/my-account-edit/my-account-edit.component';
import { Console } from 'console';

@Component({
  selector: 'app-terms-of-participation',
  templateUrl: './terms-of-participation.component.html',
  styleUrls: ['./terms-of-participation.component.css']
})
export class TermsOfParticipationComponent implements OnInit {
  loaded:boolean=true;
  myFormGroup: FormGroup;
  myMessage:string;
  showYouthInfo:boolean=false;
  showInsuranceInfo:boolean=false;
  showGuardianInfo:boolean=false;
  showEmergencyInfo:boolean=false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  user: User = {
    PasswordHash:null,
    Id:null,
    UserName:null,
    Email:null,
    FirstName:null,
    LastName:null,
    Password:null,
    Prefix:null,
    Shimei:null,
    Myoji:null,
    Street:null,
    Street2:null,
    City:null,
    State:null,
    Zip:null,
    Country:null,
    Yubin_Bango:null,
    To_Do_Fu_Ken:null,
    Shi_Gun_Ku:null,
    Cho_Son:null,
    Apartment_Etc:null,
    To_Do_Fu_Ken_JP:null,
    Shi_Gun_Ku_JP:null,
    Cho_Son_JP:null,
    Apartment_Etc_JP:null,
    Gender:null,
    DOB:null,
    TelNo:null,
    TimezoneName:null,
    UTCdiff:null,
    Token:null,
    Occupation:null,
    MemberStatus:null,
    Married:null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount:null,
    RegisteredDate:null,
    LastAccessedDate:null,
    //From siteground
    LoginId:null,
    MiddleNameEN:null,
    MiddleNameJP:null,
    PrefixJP:null,
    OccupationDetail:null,
    Organization:null,
    LivingCountry:null,
    PhoneEN:null,
    PhoneMobileEN:null,
    PhoneJP:null,
    PhoneMobileJP:null,
    ChurchNameEN:null,
    ChurchNameJP:null,
    MemberType:null,
    MemberDetail:null,
    MemberMemo:null,
    Shokaisha:null,
    Reason:null,
    NewsLetters:null,
    StaffReports:null,
    OtherLetters:null,
    NewsLetterLanguage:null,
    BasicRoles:null,
    //Added new
    MigrateFromSiteGround:null,
    AdminAccess:null,
    JCFNAccess:null,
    SupportingMember:null,
    LastAddressUpdatedDate:null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };
  relatedPerson:RelatedPerson={
    Id:null,
    RelatedId:null,
    Email:null,
    FirstNameRelated:null,
    LastNameRelated:null,
    MyojiRelated:null,
    ShimeiRelated:null,
    DOBRelated:null,
    GenderRelated:null
  };
  relatedPersons:RelatedPerson[]=[];
  volunteerSelections:VolunteerSelection[]=[];
  conference: ECConference= {
    ECConferenceId:0,
    UserId: null,
    Email: null,
    UserName: null,
    FirstName: null,
    LastName: null,
    //Program
    ProgramType: null,
    SmallGroupLanguage: null,
    AttendJapanRegionalMeeting:null,
    JapanRegionalMeeting:null,
    ApplyEnglishPartner:null,
    //Payment
    ConferenceFee: null,
    ConferenceDiscountAmount: null,
    ConferenceFeePaidAmount: null,
    ConferenceFeePaidDate: null,
    CouponApproved: null,
    ApplyScholarship: null,
    ApplyingScholarshipAmount: null,
    ApprovedScholarshipAmount: null,
    ApplyingReason: null,
    //Others\
    Volunteers:null,
    Student:null,
    ApplyCounseling: null,
    MySleepingBag:null,
    BringExtraSleepingBag:null,
    CountExtraSleepingBag:null,
    NeedCommingBus:null,
    CommingBusDeparturePlace:null,
    CommingBusDepartureDateTime:null,
    CommingAirline:null,
    CommingFlightNo:null,
    DepartureCity:null,
    CommingInternationalDomestic:null,
    ArrivalDateTime:null,
    NeedReturningBus:null,
    ReturningBusDepartureDateTime:null,
    ReturningAirline:null,
    ReturningFlightNo:null,
    ReturningCity:null,
    ReturningBusDeparturePlace:null,
    ReturningInternationalDomestic:null,
    DepartureDateTime:null,
    RoomInfo:null,
    Couple:null,
    Youth:null,
    YouthGrade: null,
    GuardianFirstName: null,
    GuardianLastName: null,
    GuardianMyoji: null,
    GuardianShimei: null,
    GuardianEmail: null,
    GuardianTelNo: null,
    TermsOfParticipation: null,
    TermsOfParticipationParent: null,
    TermsOfParticipationDate: null,
    TermsOfParticipationDateParent: null,
    RegisteredDate: null,
    UpdatedDate: null,

    AdminAccess: null,
    ECCommitteeAccess: null,
    Memo: null,
    Dec26:null,
    Dec27:null,
    Dec28:null,
    Dec29:null,
    Dec30:null,
    Dec31:null,
    KidGroup1:null,
    KidGroup2:null,
    KidGroup3:null,
    KidGroup4:null,
    myRelatedPersons: this.relatedPersons,
    myVolunteerSelections:this.volunteerSelections,
    BusComingConfirmation:null,
    BusReturningConfirmation:null,
    TermsOfParticipationVenue:null,
    TermsOfParticipationDateVenue:null,
    TermsOfParticipationYouth:null,
    TermsOfParticipationDateYouth:null,
    TermsOfParticipationYouthParent:null,
    TermsOfParticipationDateYouthParent:null,
    EmergencyContactFirstName:null,
    EmergencyContactLastName:null,
    EmergencyContactEmail:null,
    EmergencyContactTelNo:null,
    ApplicationFee:null,
    ApplicationFeePaidDate:null,
    BalanceAmount:null,
    BalanceAmountPaidDate:null,
    HasAllergy:null,
    AllergyDetail:null,
    BringInsurance:null,
    MinorHasCustodyInsurance:null,
    MinorHasCustodyInsuranceDate:null,
    InsuranceCompany:null,
    InsuranceAddress:null,
    InsuranceTelNo:null,
    InsurancePolicyNo:null,
    InsuranceGroupNo:null,
    VolunteerAgreeConductCode:null,
    VolunteerAgreeConductCodeDate:null,
    PayingAmount:null,
     //New
    BecomeMentor:null,
    ApplyMentoring:null,
    ReturningDepartureCity:null,
    InsuranceStatus:null,
    DressCodeAgreeYouth:null,
    DressCodeDateYouth:null,
    DressCodeAgreeParent:null,
    DressCodeDateParent:null
}
  constructor(
    private _snackBar: MatSnackBar,
    public router: Router,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private registrationService: UserService,
    private ecRegistrationService : EcRegistrationService,
    private dialog: MatDialog,
  ) { }
  mySearch: any = {};
  ngOnInit(): void {
    this.myFormGroup = this._formBuilder.group({
      myYouth: [null, Validators.required ],
      myTermsOfParticipation: [null, Validators.required],
      myTermsOfParticipationDate: [null, Validators.required],
      myTermsOfParticipationVenue: [null],
      myTermsOfParticipationDateVenue: [null],
      myTermsOfParticipationYouth: [null],
      myTermsOfParticipationDateYouth: [null],
      myTermsOfParticipationYouthParent: [null],
      myTermsOfParticipationDateYouthParent: [null],
      myGuardianFirstName: [''],
      myGuardianLastName: [''],
      myGuardianEmail: [''],
      myGuardianTelNo:[''],
      myEmergencyContactFirstName: [''],
      myEmergencyContactLastName: [''],
      myEmergencyContactEmail: [''],
      myEmergencyContactTelNo:[''],
      myGuardianTermsOfParticipation: [null],
      myGuardianTermsOfParticipationDate: [null],


      myMinorHasCustodyInsurance:[''],
      myMinorHasCustodyInsuranceDate:[''],
      myInsurance:[null, Validators.required],
      myInsuranceCompany:[null, Validators.required],
      myInsuranceAddress:[null, Validators.required],
      myInsuranceTelNo:[null, Validators.required],
      myInsurancePolicyNo:[null, Validators.required],
      myInsuranceGroupNo:[null, Validators.required],

    });

    if (sessionStorage.getItem("myECSearchingUserId") != null) {
      /*************************************************
       *  A) User info for searching registrant by admin
       *************************************************/
       this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnConferenceAdmin"));
       if (this.mySearch != null) {
         this.conference = this.mySearch.myEditingConference;
         this.patchModelValue(this.conference);
         this.setMyYouth();
         this.loaded = true;
       }else{
        this.loaded = true;
       }
    } else {
    /*####################################################################################
    # Get conference info from previous page for myself
    #####################################################################################*/
    this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnConference"));
      if (this.mySearch != null) {
        this.conference = this.mySearch.myEditingConference;
        this.patchModelValue(this.conference);
        this.setMyYouth();
       //this.loaded = true;
      }else{
      }
    }
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  patchModelValue(model:ECConference){
    this.myFormGroup.patchValue({
      myYouth: model.Youth,
      myTermsOfParticipation: model.TermsOfParticipation,
      myTermsOfParticipationDate: model.TermsOfParticipationDate!=null?model.TermsOfParticipationDate:new Date(),
      myTermsOfParticipationVenue: model.TermsOfParticipationVenue,
      myTermsOfParticipationDateVenue: model.TermsOfParticipationDateVenue!=null?model.TermsOfParticipationDateVenue:new Date(),
      myTermsOfParticipationYouth: model.TermsOfParticipationYouth,
      myTermsOfParticipationDateYouth: model.TermsOfParticipationDateYouth!=null?model.TermsOfParticipationDateYouth:new Date(),
      myTermsOfParticipationYouthParent: model.TermsOfParticipationYouthParent,
      myTermsOfParticipationDateYouthParent: model.TermsOfParticipationDateYouthParent!=null?model.TermsOfParticipationDateYouthParent:new Date(),
      myGuardianFirstName: model.GuardianFirstName,
      myGuardianLastName: model.GuardianLastName,
      myGuardianEmail: model.GuardianEmail,
      myGuardianTelNo:model.GuardianTelNo,
      myEmergencyContactFirstName: model.EmergencyContactFirstName,
      myEmergencyContactLastName: model.EmergencyContactLastName,
      myEmergencyContactEmail: model.EmergencyContactEmail,
      myEmergencyContactTelNo:model.EmergencyContactTelNo,

      myBringInsurance:model.BringInsurance,
      myMinorHasCustodyInsurance:model.MinorHasCustodyInsurance,
      myMinorHasCustodyInsuranceDate:model.MinorHasCustodyInsuranceDate!=null?model.MinorHasCustodyInsuranceDate:new Date(),
      myInsurance:model.InsuranceStatus,
      myInsuranceCompany:model.InsuranceCompany,
      myInsuranceAddress:model.InsuranceAddress,
      myInsuranceTelNo:model.InsuranceTelNo,
      myInsurancePolicyNo:model.InsurancePolicyNo,
      myInsuranceGroupNo:model.InsuranceGroupNo
    });
    if(model.InsuranceStatus=='HasInsurance'){
      this.showInsuranceInfo=true;
    }else{
      this.showInsuranceInfo=false;
    }
    if(model.InsuranceStatus=='NoInsurance' || model.InsuranceStatus=='WillBuyInsurance' ){
      this.myFormGroup.get('myInsuranceCompany').clearValidators();
      this.myFormGroup.get('myInsuranceCompany').updateValueAndValidity();
      this.myFormGroup.get('myInsuranceAddress').clearValidators();
      this.myFormGroup.get('myInsuranceAddress').updateValueAndValidity();
      this.myFormGroup.get('myInsuranceTelNo').clearValidators();
      this.myFormGroup.get('myInsuranceTelNo').updateValueAndValidity();
      this.myFormGroup.get('myInsurancePolicyNo').clearValidators();
      this.myFormGroup.get('myInsurancePolicyNo').updateValueAndValidity();
      this.myFormGroup.get('myInsuranceGroupNo').clearValidators();
      this.myFormGroup.get('myInsuranceGroupNo').updateValueAndValidity();
    }
  }

  onChangeConsent(value:boolean){
    this.conference.TermsOfParticipation=value;
    if(value==false){

    }
  }
  onChangeConsentVenue(value:boolean){
    this.conference.TermsOfParticipationVenue=value;
  }
  onChangeConsentYouth(value:boolean){
    this.conference.TermsOfParticipationYouth=value;
    console.log('test'+value)
  }
  onChangeConsentYouthParent(value:boolean){
    this.conference.TermsOfParticipationYouthParent=value;
  }
  onChangeConsentMinorHasCustodyInsurance(value:boolean){
    this.conference.MinorHasCustodyInsurance=value;
  }

  onChangeYouth($event:MatRadioChange){
    /**********************************************************
     *  I don't know but MatRadioChange returns always string,
     * so it need to be converted to boolean
    ***********************************************************/
    this.conference.Youth= $event.value=='true'? true:false;
    this.setMyYouth();
  }

  setMyYouth(){
    if(this.conference.Youth==true){
      console.log('I am under 18')
      this.showYouthInfo=true;
      this.showGuardianInfo=true;
      this.showEmergencyInfo=false;
      this.myFormGroup.get('myGuardianFirstName').setValidators([Validators.required]);
      this.myFormGroup.get('myGuardianFirstName').updateValueAndValidity();
      this.myFormGroup.get('myGuardianLastName').setValidators([Validators.required]);
      this.myFormGroup.get('myGuardianLastName').updateValueAndValidity();
      this.myFormGroup.get('myGuardianEmail').setValidators([Validators.required]);
      this.myFormGroup.get('myGuardianEmail').updateValueAndValidity();
      this.myFormGroup.get('myGuardianTelNo').setValidators([Validators.required]);
      this.myFormGroup.get('myGuardianTelNo').updateValueAndValidity();
      // this.myFormGroup.get('myTermsOfParticipationVenue').setValidators([Validators.required]);
      // this.myFormGroup.get('myTermsOfParticipationVenue').updateValueAndValidity();
      // this.myFormGroup.get('myTermsOfParticipationDateVenue').setValidators([Validators.required]);
      // this.myFormGroup.get('myTermsOfParticipationDateVenue').updateValueAndValidity();
      this.myFormGroup.get('myTermsOfParticipationYouth').setValidators([Validators.required]);
      this.myFormGroup.get('myTermsOfParticipationYouth').updateValueAndValidity();
      this.myFormGroup.get('myTermsOfParticipationDateYouth').setValidators([Validators.required]);
      this.myFormGroup.get('myTermsOfParticipationDateYouth').updateValueAndValidity();
      this.myFormGroup.get('myTermsOfParticipationYouthParent').setValidators([Validators.required]);
      this.myFormGroup.get('myTermsOfParticipationYouthParent').updateValueAndValidity();
      this.myFormGroup.get('myTermsOfParticipationDateYouthParent').setValidators([Validators.required]);
      this.myFormGroup.get('myTermsOfParticipationDateYouthParent').updateValueAndValidity();

      // this.myFormGroup.get('myMinorHasCustodyInsurance').setValidators([Validators.required]);
      // this.myFormGroup.get('myMinorHasCustodyInsurance').updateValueAndValidity();
      // this.myFormGroup.get('myMinorHasCustodyInsuranceDate').setValidators([Validators.required]);
      // this.myFormGroup.get('myMinorHasCustodyInsuranceDate').updateValueAndValidity();
      // this.myFormGroup.get('myInsuranceCompany').setValidators([Validators.required]);
      // this.myFormGroup.get('myInsuranceCompany').updateValueAndValidity();
      // this.myFormGroup.get('myInsuranceAddress').setValidators([Validators.required]);
      // this.myFormGroup.get('myInsuranceAddress').updateValueAndValidity();
      // this.myFormGroup.get('myInsuranceTelNo').setValidators([Validators.required]);
      // this.myFormGroup.get('myInsuranceTelNo').updateValueAndValidity();
      // this.myFormGroup.get('myInsurancePolicyNo').setValidators([Validators.required]);
      // this.myFormGroup.get('myInsurancePolicyNo').updateValueAndValidity();
      // this.myFormGroup.get('myInsuranceGroupNo').setValidators([Validators.required]);
      // this.myFormGroup.get('myInsuranceGroupNo').updateValueAndValidity();

      this.myFormGroup.get('myEmergencyContactFirstName').clearValidators();
      this.myFormGroup.get('myEmergencyContactFirstName').updateValueAndValidity();
      this.myFormGroup.get('myEmergencyContactLastName').clearValidators();
      this.myFormGroup.get('myEmergencyContactLastName').updateValueAndValidity();
      this.myFormGroup.get('myEmergencyContactEmail').clearValidators();
      this.myFormGroup.get('myEmergencyContactEmail').updateValueAndValidity();
      this.myFormGroup.get('myEmergencyContactTelNo').clearValidators();
      this.myFormGroup.get('myEmergencyContactTelNo').updateValueAndValidity();
    } else if (this.conference.Youth==false) {
      console.log('I am 18 or older');
      this.showYouthInfo=false;
      this.showGuardianInfo=false;
      this.showEmergencyInfo=true;
      this.myFormGroup.get('myGuardianFirstName').clearValidators();
      this.myFormGroup.get('myGuardianFirstName').updateValueAndValidity();
      this.myFormGroup.get('myGuardianLastName').clearValidators();
      this.myFormGroup.get('myGuardianLastName').updateValueAndValidity();
      this.myFormGroup.get('myGuardianEmail').clearValidators();
      this.myFormGroup.get('myGuardianEmail').updateValueAndValidity();
      this.myFormGroup.get('myGuardianTelNo').clearValidators();
      this.myFormGroup.get('myGuardianTelNo').updateValueAndValidity();
      // this.myFormGroup.get('myTermsOfParticipationVenue').clearValidators();
      // this.myFormGroup.get('myTermsOfParticipationVenue').updateValueAndValidity();
      // this.myFormGroup.get('myTermsOfParticipationDateVenue').clearValidators();
      // this.myFormGroup.get('myTermsOfParticipationDateVenue').updateValueAndValidity();
      this.myFormGroup.get('myTermsOfParticipationYouth').clearValidators();
      this.myFormGroup.get('myTermsOfParticipationYouth').updateValueAndValidity();
      this.myFormGroup.get('myTermsOfParticipationDateYouth').clearValidators();
      this.myFormGroup.get('myTermsOfParticipationDateYouth').updateValueAndValidity();
      this.myFormGroup.get('myTermsOfParticipationYouthParent').clearValidators();
      this.myFormGroup.get('myTermsOfParticipationYouthParent').updateValueAndValidity();
      this.myFormGroup.get('myTermsOfParticipationDateYouthParent').clearValidators();
      this.myFormGroup.get('myTermsOfParticipationDateYouthParent').updateValueAndValidity();

      // this.myFormGroup.get('myMinorHasCustodyInsurance').clearValidators();
      // this.myFormGroup.get('myMinorHasCustodyInsurance').updateValueAndValidity();
      // this.myFormGroup.get('myMinorHasCustodyInsuranceDate').clearValidators();
      // this.myFormGroup.get('myMinorHasCustodyInsuranceDate').updateValueAndValidity();
      // this.myFormGroup.get('myInsuranceCompany').clearValidators();
      // this.myFormGroup.get('myInsuranceCompany').updateValueAndValidity();
      // this.myFormGroup.get('myInsuranceAddress').clearValidators();
      // this.myFormGroup.get('myInsuranceAddress').updateValueAndValidity();
      // this.myFormGroup.get('myInsuranceTelNo').clearValidators();
      // this.myFormGroup.get('myInsuranceTelNo').updateValueAndValidity();
      // this.myFormGroup.get('myInsurancePolicyNo').clearValidators();
      // this.myFormGroup.get('myInsurancePolicyNo').updateValueAndValidity();
      // this.myFormGroup.get('myInsuranceGroupNo').clearValidators();
      // this.myFormGroup.get('myInsuranceGroupNo').updateValueAndValidity();

      this.myFormGroup.get('myEmergencyContactFirstName').setValidators([Validators.required]);
      this.myFormGroup.get('myEmergencyContactFirstName').updateValueAndValidity();
      this.myFormGroup.get('myEmergencyContactLastName').setValidators([Validators.required]);
      this.myFormGroup.get('myEmergencyContactLastName').updateValueAndValidity();
      this.myFormGroup.get('myEmergencyContactEmail').setValidators([Validators.required]);
      this.myFormGroup.get('myEmergencyContactEmail').updateValueAndValidity();
      this.myFormGroup.get('myEmergencyContactTelNo').setValidators([Validators.required]);
      this.myFormGroup.get('myEmergencyContactTelNo').updateValueAndValidity();
    }
  }

  onSubmit(){}

  getFormValue() {
    //this.conference.Youth = this.myFormGroup.get('myYouth').value; *** DO NOT Include because this is string not boolean
    this.conference.TermsOfParticipation = this.myFormGroup.get('myTermsOfParticipation').value;
    this.conference.TermsOfParticipationDate =  this.myFormGroup.get('myTermsOfParticipationDate').value;
    this.conference.TermsOfParticipationVenue = this.myFormGroup.get('myTermsOfParticipationVenue').value;
    this.conference.TermsOfParticipationDateVenue = this.myFormGroup.get('myTermsOfParticipationDateVenue').value;
    this.conference.TermsOfParticipationYouth = this.myFormGroup.get('myTermsOfParticipationYouth').value;
    this.conference.TermsOfParticipationDateYouth = this.myFormGroup.get('myTermsOfParticipationDateYouth').value;
    this.conference.TermsOfParticipationYouthParent = this.myFormGroup.get('myTermsOfParticipationYouthParent').value;
    this.conference.TermsOfParticipationDateYouthParent = this.myFormGroup.get('myTermsOfParticipationDateYouthParent').value;
    this.conference.GuardianFirstName  = this.myFormGroup.get('myGuardianFirstName').value;
    this.conference.GuardianLastName  = this.myFormGroup.get('myGuardianLastName').value;
    this.conference.GuardianEmail  = this.myFormGroup.get('myGuardianEmail').value;
    this.conference.GuardianTelNo  = this.myFormGroup.get('myGuardianTelNo').value;
    this.conference.EmergencyContactFirstName  =  this.myFormGroup.get('myEmergencyContactFirstName').value;
    this.conference.EmergencyContactLastName  =  this.myFormGroup.get('myEmergencyContactLastName').value;
    this.conference.EmergencyContactEmail  =  this.myFormGroup.get('myEmergencyContactEmail').value;
    this.conference.EmergencyContactTelNo  =  this.myFormGroup.get('myEmergencyContactTelNo').value;

    this.conference.MinorHasCustodyInsurance = this.myFormGroup.get('myMinorHasCustodyInsurance').value;
    this.conference.MinorHasCustodyInsuranceDate = this.myFormGroup.get('myMinorHasCustodyInsuranceDate').value;
    this.conference.InsuranceCompany = this.myFormGroup.get('myInsuranceCompany').value;
    this.conference.InsuranceAddress=this.myFormGroup.get('myInsuranceAddress').value;
    this.conference.InsuranceTelNo=this.myFormGroup.get('myInsuranceTelNo').value;
    this.conference.InsurancePolicyNo=this.myFormGroup.get('myInsurancePolicyNo').value;
    this.conference.InsuranceGroupNo=this.myFormGroup.get('myInsuranceGroupNo').value;
 }

  saveOrMoveForward(){
    this.getFormValue();
    console.log("My Program is : "+this.conference.ProgramType);
    if(this.conference.ProgramType=="Youth Program"){
      if(this.router.url.includes('/registration/ec/')){
        sessionStorage.removeItem("myWorkingOnConference");
        sessionStorage.setItem(
          "myWorkingOnConference",
          JSON.stringify({ myEditingConference: this.conference })
        );
        this.router.navigate(["/registration/ec/dressCode/" +this.getMyLanguage()]);
      }else{
        sessionStorage.removeItem("myWorkingOnConferenceAdmin");
        sessionStorage.setItem(
          "myWorkingOnConferenceAdmin",
          JSON.stringify({ myEditingConference: this.conference })
        );
        this.dialog.closeAll();
        this.openForwardEmbed();
      }
    }else{
      this.savingConferenceInfo();
    }
  }

  savingConferenceInfo(){
    this.getFormValue();
    console.log("Youth: "+this.conference.Youth);
    console.log("TermsOfParticipation: "+this.conference.TermsOfParticipation);
    console.log("TermsOfParticipationDate: "+this.conference.TermsOfParticipationDate);
    // console.log("TermsOfParticipationVenu: "+this.conference.TermsOfParticipationVenue);
    // console.log("TermsOfParticipationDateVenue: "+this.conference.TermsOfParticipationDateVenue);
    console.log("TermsOfParticipationYouth: "+this.conference.TermsOfParticipationYouth);
    console.log("TermsOfParticipationDateYouth: "+this.conference.TermsOfParticipationDateYouth);
    console.log("TermsOfParticipationYouthParent: "+this.conference.TermsOfParticipationYouthParent);
    console.log("TermsOfParticipationDateYouthParent: "+this.conference.TermsOfParticipationDateYouthParent);
    console.log("GuardianFirstName: "+this.conference.GuardianFirstName);
    console.log("GuardianLastName: "+this.conference.GuardianLastName);
    console.log("GuardianEmail: "+this.conference.GuardianEmail);
    console.log("GuardianTelNo: "+this.conference.GuardianTelNo);
    console.log("EmergencyContactFirstName: "+this.conference.EmergencyContactFirstName);
    console.log("EmergencyContactLastName: "+this.conference.EmergencyContactLastName);
    console.log("EmergencyContactEmail: "+this.conference.EmergencyContactEmail);
    console.log("EmergencyContactTelNo: "+ this.conference.EmergencyContactTelNo);

    this.loaded = false;
    // 1. Save Conference Info
    console.log('registered date: '+this.conference.RegisteredDate);
    this.conference.RegisteredDate=this.conference.RegisteredDate==null? new Date():this.conference.RegisteredDate;
    this.conference.UpdatedDate=new Date();
    console.log('registered date: '+this.conference.RegisteredDate);
    this.ecRegistrationService.upsertConference(this.conference).subscribe(
      data => {
        this.myMessage = this.getMyLanguage()=="EN"?"Conference info saved successfully.":"カンファレンス情報が保存されました!";
        this.openSnackBar('success', 'close');
        this.loaded = true;

        if(this.router.url.includes('/registration/ec/')){
          this.router.navigate(["/conference/EC/myAccount/"+this.getMyLanguage()]);
        }else{
          this.dialog.closeAll();
          this.openForwardEmbed();
        }

      },
      error => {
        this.myMessage = this.getMyLanguage()=="EN"?"failed in saving Conference data.":"問題が生じカンファレンス情報が保存できませんでした。";
        this.openSnackBar('error', 'close');
        this.loaded = true;
      }
    )
    //Updateが終わった自分のカンファレンス情報セッションを消しておく
    sessionStorage.removeItem("myWorkingOnConference");
    // アドミで使った調べ中のユーザーの情報セッションは消しておく
    if (sessionStorage.getItem("myECSearchingUserId") != null) {
      sessionStorage.removeItem("myWorkingOnConferenceAdmin");
    }
  }
  openForwardEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktoop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ userId: this.conference.UserId };
    const dialogRef = this.dialog.open(MyAccountEditComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  onClickGoBack(){
    if(this.router.url.includes('/registration/ec/')){
      this.router.navigate(["/registration/ec/summaryConference/" +this.getMyLanguage()]);
    }else{
      this.dialog.closeAll();
      this.openBackEmbed();
    }
  }
  openBackEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ myPage: 'summaryConference' };
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  setConferenceInfoInSessionStorage(){
    sessionStorage.removeItem("myConferenceInfo");
    sessionStorage.setItem("myConferenceInfo",
    JSON.stringify({ myConferenceSearch: this.conference })
    );
  }

  onChangeGuardianConsent(value:boolean){
    this.conference.TermsOfParticipationParent=value;
  }

  cancelProcess(){
    this.getFormValue();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='210px'; //Desktop
    dialogConfig.maxWidth='370px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ conference: this.conference };
    const dialogRef = this.dialog.open(CancelOrSaveConfirmationComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
      //Retreive updated registration Info.
      //this.ngOnInit();
      let navigationExtras: NavigationExtras = {
        queryParams:this.conference
      };
      if(result!='cancel'){
        if(this.router.url.includes('/registration/ec/')){
          this.router.navigate(["/conference/EC/myAccount/" +this.getMyLanguage()], navigationExtras);
        }else{
          this.dialog.closeAll();
          this.openMyAccountEdit();
        }
        }
    })
  }

  openMyAccountEdit(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ userId: this.conference.UserId };
    const dialogRef = this.dialog.open(MyAccountEditComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 6000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 20000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }

  onChangeInsuranceOption(event: MatRadioChange) {
    this.conference.InsuranceStatus = event.value;
    console.log("My Insurance Status:" + this.conference.InsuranceStatus);
    if (this.conference.InsuranceStatus == 'HasInsurance') {
      this.myFormGroup.get('myInsuranceCompany').setValidators(Validators.required);
      this.myFormGroup.get('myInsuranceCompany').updateValueAndValidity();
      this.myFormGroup.get('myInsuranceAddress').setValidators(Validators.required);
      this.myFormGroup.get('myInsuranceAddress').updateValueAndValidity();
      this.myFormGroup.get('myInsuranceTelNo').setValidators(Validators.required);
      this.myFormGroup.get('myInsuranceTelNo').updateValueAndValidity();
      this.myFormGroup.get('myInsurancePolicyNo').setValidators(Validators.required);
      this.myFormGroup.get('myInsurancePolicyNo').updateValueAndValidity();
      this.myFormGroup.get('myInsuranceGroupNo').setValidators(Validators.required);
      this.myFormGroup.get('myInsuranceGroupNo').updateValueAndValidity();
      this.showInsuranceInfo = true;
    } else if (this.conference.InsuranceStatus == 'NoInsurance') {
      this.myFormGroup.get('myInsuranceCompany').clearValidators();
      this.myFormGroup.get('myInsuranceCompany').updateValueAndValidity();
      this.myFormGroup.get('myInsuranceAddress').clearValidators();
      this.myFormGroup.get('myInsuranceAddress').updateValueAndValidity();
      this.myFormGroup.get('myInsuranceTelNo').clearValidators();
      this.myFormGroup.get('myInsuranceTelNo').updateValueAndValidity();
      this.myFormGroup.get('myInsurancePolicyNo').clearValidators();
      this.myFormGroup.get('myInsurancePolicyNo').updateValueAndValidity();
      this.myFormGroup.get('myInsuranceGroupNo').clearValidators();
      this.myFormGroup.get('myInsuranceGroupNo').updateValueAndValidity();
      this.showInsuranceInfo = false;
    } else  if (this.conference.InsuranceStatus == 'WillBuyInsurance') {
      this.myFormGroup.get('myInsuranceCompany').clearValidators();
      this.myFormGroup.get('myInsuranceCompany').updateValueAndValidity();
      this.myFormGroup.get('myInsuranceAddress').clearValidators();
      this.myFormGroup.get('myInsuranceAddress').updateValueAndValidity();
      this.myFormGroup.get('myInsuranceTelNo').clearValidators();
      this.myFormGroup.get('myInsuranceTelNo').updateValueAndValidity();
      this.myFormGroup.get('myInsurancePolicyNo').clearValidators();
      this.myFormGroup.get('myInsurancePolicyNo').updateValueAndValidity();
      this.myFormGroup.get('myInsuranceGroupNo').clearValidators();;
      this.myFormGroup.get('myInsuranceGroupNo').updateValueAndValidity();
      this.showInsuranceInfo = false;
    }
  }
}

