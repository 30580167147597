<div class="myBase">
  <div class="bg-layer">
    <div class="btnBack">
      <button type="button" class="btn btn-link" (click)="onClickClose()">
        <i class="fas fa-times-circle fa-2x"></i>&nbsp;&nbsp; {{this.router.url.includes('EN')==true?'Close': '閉じる'}}
      </button>
    </div>
    <div *ngIf="this.componentPage=='basicGRC'">
      <app-basic-grc></app-basic-grc>
    </div>
    <div *ngIf="this.componentPage=='basicSubGRC'">
      <app-basic-sub-grc></app-basic-sub-grc>
    </div>
    <div *ngIf="this.componentPage=='addressInfoGRC'">
      <app-address-info-grc></app-address-info-grc>
    </div>
    <div *ngIf="this.componentPage=='faithInfoGRC'">
      <app-faith-info-grc></app-faith-info-grc>
    </div>
    <div *ngIf="this.componentPage=='summaryPersonGRC'">
      <app-summary-person-grc></app-summary-person-grc>
    </div>
    <div *ngIf="this.componentPage=='smallGroupLanguageGRC'">
      <app-small-group-language-grc></app-small-group-language-grc>
    </div>
    <div *ngIf="this.componentPage=='volunteersGRC'">
      <app-volunteers-grc></app-volunteers-grc>
    </div>
    <div *ngIf="this.componentPage=='coupleGRC'">
      <app-couple-grc></app-couple-grc>
    </div>
    <div *ngIf="this.componentPage=='transportationGRC'">
      <app-transportation-grc></app-transportation-grc>
    </div>
    <div *ngIf="this.componentPage=='roomInfoGRC'">
      <app-room-info-grc></app-room-info-grc>
    </div>
    <div *ngIf="this.componentPage=='lodgingScheduleGRC'">
      <app-lodging-schedule-grc></app-lodging-schedule-grc>
    </div>
    <div *ngIf="this.componentPage=='scholarshipGRC'">
      <app-scholarship-grc></app-scholarship-grc>
    </div>
    <div *ngIf="this.componentPage=='summaryConferenceGRC'">
      <app-summary-conference-grc></app-summary-conference-grc>
    </div>
    <div *ngIf="this.componentPage=='termsOfParticipationGRC'">
      <app-terms-of-participation-grc></app-terms-of-participation-grc>
    </div>
    <div *ngIf="this.componentPage=='myAccountGRC'">
      <app-my-account-grc></app-my-account-grc>
    </div>


  </div>
 </div>
 <div class="memoForDevelopper d-flex justify-content-center">
  dialog/GRC/registration-conference-embed-grc
 </div>


