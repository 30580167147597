import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA,MatDialogConfig} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { UserService} from "../../../services/user/user.service";
import { Router, ActivatedRoute } from "@angular/router";
import { LoginEnterPasswordComponent} from "../../dialog/login-enter-password/login-enter-password.component";
import { CheckHavingAccountComponent} from '../../dialog/check-having-account/check-having-account.component';
import { ForwardSiteGroundUserToPasswordRecoveryComponent}
from '../../dialog/forward-site-ground-user-to-password-recovery/forward-site-ground-user-to-password-recovery.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import  { User} from "../../../models/user";

export interface DialogData {
  myLanguage:string
}

@Component({
  selector: 'app-enter-searching-email',
  templateUrl: './enter-searching-email.component.html',
  styleUrls: ['./enter-searching-email.component.css']
})
export class EnterSearchingEmailComponent implements OnInit {
  firstFormGroup: FormGroup;
  loaded:boolean=true;
  userSearched:boolean=true;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  myMessage:string;
  showMessageUserNotFound:boolean=false;
  user: User = {
    PasswordHash:null,
    Id: null,
    UserName: null,
    Email: null,
    FirstName: null,
    LastName: null,
    Password: null,
    Prefix: null,
    Shimei: null,
    Myoji: null,
    Street: null,
    Street2: null,
    City: null,
    State: null,
    Zip: null,
    Country: null,
    Yubin_Bango: null,
    To_Do_Fu_Ken: null,
    Shi_Gun_Ku: null,
    Cho_Son: null,
    Apartment_Etc: null,
    To_Do_Fu_Ken_JP: null,
    Shi_Gun_Ku_JP: null,
    Cho_Son_JP: null,
    Apartment_Etc_JP: null,
    Gender: null,
    DOB: null,
    TelNo: null,
    TimezoneName:null,
    UTCdiff:null,
    Token:null,
    Occupation:null,
    MemberStatus:null,
    Married:null,
    ZoomAccount:null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    RegisteredDate:null,
    LastAccessedDate:null,
    //From siteground
    LoginId:null,
    MiddleNameEN:null,
    MiddleNameJP:null,
    PrefixJP:null,
    OccupationDetail:null,
    Organization:null,
    LivingCountry:null,
    PhoneEN:null,
    PhoneMobileEN:null,
    PhoneJP:null,
    PhoneMobileJP:null,
    ChurchNameEN:null,
    ChurchNameJP:null,
    MemberType:null,
    MemberDetail:null,
    MemberMemo:null,
    Shokaisha:null,
    Reason:null,
    NewsLetters:null,
    StaffReports:null,
    OtherLetters:null,
    NewsLetterLanguage:null,
    BasicRoles:null,
    //Added new
    MigrateFromSiteGround:null,
    AdminAccess:null,
    JCFNAccess:null,
    SupportingMember:null,
    LastAddressUpdatedDate:null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };

  loading = false;
  Email: string;
  Password: string;
  mySelectedLanguage: string;


  constructor(
    public router: Router,
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private userService: UserService,
    public dialogRef: MatDialogRef<EnterSearchingEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog
  ){}

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      myEmail: [null, [Validators.required, Validators.email]],
    });

  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onEmailCheck() {
    console.log('hey');
    this.showMessageUserNotFound = false;
    if (this.firstFormGroup.get('myEmail').status == 'VALID') {
      this.loaded = false;
      //this.userService.emailCheck(this.user.Email).subscribe(
      this.userService.getUserProfile(this.user.Email).subscribe(
        result => {
          if (result != null) {
            this.user = result;
            if (this.user.MigrateFromSiteGround == true && this.user.PasswordHash == null) {
              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = true;
              dialogConfig.autoFocus = false;
              // dialogConfig.maxHeight = '500px'; //Desktop
               dialogConfig.maxWidth = '600px';//Desktoop
              // dialogConfig.height = '100%';//Mobile
               dialogConfig.width = '95%';//Mobile
              dialogConfig.panelClass = 'custom-modalbox'; //Refer global level
              dialogConfig.data = { myEmail: this.user.Email };
              const dialogRef = this.dialog.open(ForwardSiteGroundUserToPasswordRecoveryComponent, dialogConfig);
              dialogRef.afterClosed().subscribe(result => {
                var redirectPage = result;
                //  this.redirectPage(this.codeMaster.CodeType);
                if (result != null) {
                  console.log("Confirmed Action: " + redirectPage);
                }
              })
            } else {
              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = true;
              dialogConfig.autoFocus = false;
             // dialogConfig.maxHeight = '500px'; //Desktop
              dialogConfig.maxWidth = '600px';//Desktoop
             // dialogConfig.height = '100%';//Mobile
              dialogConfig.width = '95%';//Mobile
              dialogConfig.panelClass = 'custom-modalbox'; //Refer global level
              dialogConfig.data = { myEmail: this.user.Email };
              const dialogRef = this.dialog.open(LoginEnterPasswordComponent, dialogConfig);
              dialogRef.afterClosed().subscribe(result => {
                var redirectPage = result;
                //  this.redirectPage(this.codeMaster.CodeType);
                if (result != null) {
                  console.log("Confirmed Action: " + redirectPage);
                }
              })
            }
            this.dialogRef.close();
          } else {
            console.log('User does not exist.');
            this.showMessageUserNotFound = true;
          }
          this.loaded = true;
        },
        error => {
          this.myMessage = "User profile not found."
          this.myMessage = this.getMyLanguage()=="EN"?"User profile not found.":"ユーザーアカウントが見付かりませんでした。";
          this.openSnackBar('error', 'close');
          this.loaded = true;
        }
      )
    }
  }

  onClickBack(){
    // const dialogRef = this.dialog.open(CheckHavingAccountComponent, {
    //   width: '500px',
    //   height:'500px',
    //   data: {
    //     myLanguage:this.mySelectedLanguage
    //   }
    // });

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
   // dialogConfig.maxHeight = '500px'; //Desktop
    dialogConfig.maxWidth = '600px';//Desktoop
  //  dialogConfig.height = '100%';//Mobile
    dialogConfig.width = '95%';//Mobile
    dialogConfig.panelClass = 'custom-modalbox'; //Refer global level
    dialogConfig.data = { myEmail: this.user.Email };
    const dialogRef = this.dialog.open(CheckHavingAccountComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var redirectPage = result;
      //  this.redirectPage(this.codeMaster.CodeType);
      if (result != null) {
        console.log("Confirmed Action: " + redirectPage);
      }
    })




    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 5000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 10000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }
}
