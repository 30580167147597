<div class="myBase">
 <div class="myBackgroundLayer">
  <div class="row">
   <div class="myAccountTitle">
    <h2>
    </h2>
   </div>
  </div>

  <div *ngIf="!loaded">
   <mat-progress-bar mode="indeterminate"></mat-progress-bar>
 </div>

  <div *ngIf="loaded" class="d-flex justify-content-center">
   <mat-card class="supportingMembershipAnounce">
    <form [formGroup]="firstFormGroup">
    <h3>
     {{this.router.url.includes('EN')==true?'Select the language to receive information from JCFN.': 'JCFNからの連絡を受け取るための言語を選んでください'}}
    </h3>
<br><br>
    <div class="registrationInputForm">
     <mat-radio-group formControlName="myNewsLetterLanguage" [(ngModel)]="user.NewsLetterLanguage"
       (change)="onChangeNewsLetterLanguage($event)" class="radioBtnMain">
       <mat-radio-button value="Japanese" [checked]="user.NewsLetterLanguage=='Japanese'" class="radioBtnItem">
         {{this.router.url.includes('EN')==true?'Japanese': '日本語'}}</mat-radio-button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       <mat-radio-button value="English" [checked]="user.NewsLetterLanguage=='English'" class="radioBtnItem">
         {{this.router.url.includes('EN')==true?'English': '英語'}}</mat-radio-button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       <mat-radio-button value="Bilingual" [checked]="user.NewsLetterLanguage=='Bilingual'" class="radioBtnItem">
         {{this.router.url.includes('EN')==true?'Bilingual': 'バイリンガル'}}</mat-radio-button>
     </mat-radio-group>
     <!-- <mat-error *ngIf="user.NewsLetterLanguage==null">
       {{this.router.url.includes('EN')==true?'Gender is required': '未入力'}}
     </mat-error> -->
   </div>

<br>

<div class="d-flex justify-content-center">
 <button 
 type="button" 
 class="btn btnProcessPayment" 
 (click)="onClickNext()"
 [disabled]="user.NewsLetterLanguage==null"
 >
 {{this.router.url.includes('EN')==true?'Next': '次へ'}}
 </button>
</div>
<br><br>
<div>
    <a [routerLink]="this.router.url.includes('EN')==true?'/home/EN': '/home/JP'"
    class="btn btn-link">{{this.router.url.includes('EN')==true?'Home': 'ホーム'}}</a>&nbsp;&nbsp;
    <a [routerLink]="this.router.url.includes('EN')==true?'/members/myProfile/EN': '/members/myProfile/JP'"
    class="btn btn-link">{{this.router.url.includes('EN')==true?'My Account': 'マイアカウント'}}</a>
</div>

</form>
   </mat-card>
  </div>
  <div  class="d-flex justify-content-center">
   <button mat-button (click)="onClickGoBack()" class="btn btn-link">
      {{this.router.url.includes('EN')==true?'Go Back': '戻る'}}
   </button>
  </div>
 </div>
</div>