<div class="myBody">
  <div class="myHeaderBase d-flex justify-content-center">
    <div class="myHeaderCore">
      <div>
        <img src='../../../../../assets/images/thumbnail_eclogo.png' class="ecLogo">
      </div>
    </div>
  </div>
  <div class="myMain">
    <div clas="myMainLeft">
      <div class="myMainHalfTop">
        <!-- Left Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Left Bottom -->
      </div>
    </div>
    <div class="myMainCenter">
      <div class="myMainHalfTop d-flex justify-content-center">
        <!-- Main Top -->

        <mat-card class="mainCard b-radius">
          <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
          <div *ngIf="!loaded">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <form [formGroup]="myFormGroup" (ngSubmit)="onSubmit()">
            <div class="float-end stepNumber">9/12</div>
            <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Room Information':
              'ルーム インフォメーション'}}
              <i class="stepTitleSub">
                {{this.router.url.includes('EN')==true?'Required': '必須'}}
              </i>
            </h2>

            <div>
              <div class="stepTitle2nd">
                {{this.router.url.includes('EN')==true?"Spouses and family members over the age of 13 must submit their own EC registrations."+
                " If you do not have an account, please create an account before applying. To create an account, you must have your own email address. "+
                " If you do not have an email address, please create one and then register. If you are unable to create an email address due to unavoidable circumstances,"+
                " please let us know. Children under 12 years of age do not have to register individually, but by entering their information in the table below, you are making a provisional application."+
                " However, please note that if kids who are 12 years old wish to participate in the Youth Program instead of the Children's Program, they will need to register separately."+
                " (Children's information must only be written on one of the couple's accounts. If it is written on both, the fee will be double charged.)"
                :
                '配偶者と13歳以上の家族は各自のecお申し込みを提出する必要があります。ｱｶｳﾝﾄのない方は、ｱｶｳﾝﾄを作成してからお申し込みください。ｱｶｳﾝﾄを作成する際には、'+
                '独自のEﾒｰﾙｱﾄﾞﾚｽを持つ必要があります。Eﾒｰﾙｱﾄﾞﾚｽをお持ちでない場合は、Eﾒｰﾙｱﾄﾞﾚｽを作成した後、ご登録ください。やむをえない事情で、'+
                'Eﾒｰﾙｱﾄﾞﾚｽの作成が出来ない方はご連絡ください。12歳以下のお子様は個別に申し込みをされなくても、下の表にて情報を入力していただくことによって'+
                '仮申し込みをされた事になります。 (お子様の情報は夫婦どちらかのアカウントにのみご記入ください。両方に書かれると料金がダブルでチャージされてしまいます。）'}}<br>
                {{
                  this.router.url.includes('EN')==true?
                  'However, please note that if you are 12 years old and wish to participate in the Youth Program instead of the Children\'s Program, you will need to apply on your own. If you have any questions, please contact us at equipper@equipper.org.'
                  :
                  'ただし、12歳で子どもプログラムではなく、ユースプログラムに参加される場合は、各自でお申し込みをする必要がありますのでご注意ください。質問のある方は、equipper@equipper.org までお問合せください。'
                }}<br>
                {{
                  this.router.url.includes('EN')==true?
                  'Please note that due to the unavailability of double rooms at this time, we cannot accept requests for couples to share a room. Only couples with children may request to share a room.'
                  :
                  'なお、今回は施設の事情により二人部屋がない為、夫婦お二人での同室希望を承ることはできません。お子様ご同伴の夫婦のみ同室希望を承わりますのでご了承ください。'
                }}<br>
                {{
                  this.router.url.includes('EN')==true?
                  'We apologize in advance that your roommate requests might not be granted due to different reasons.'
                  :
                  '同室希望は諸事情により希望通りにはならない場合がありますのでご了承ください。'
                }}
              </div>
              <br><br>
              <mat-radio-group formControlName="myRoomShareType" (change)="onChangemyRoomShareType($event)"
                class="rbGroup">
                <mat-radio-button value="AnyoneIsFine" class="rbItem" [checked]="conference.RoomInfo=='AnyoneIsFine'">
                  {{this.router.url.includes('EN')==true?'Anyone is fine.': '誰でも良し'}}
                </mat-radio-button>
                <mat-radio-button value="WithFamily" class="rbItem" [checked]="conference.RoomInfo=='WithFamily'">
                  {{this.router.url.includes('EN')==true?'With Family': '家族と同室'}}</mat-radio-button>
                 <mat-radio-button value="ShareWithOthers" class="rbItem"
                  [checked]="conference.RoomInfo=='ShareWithOthers'">
                  {{this.router.url.includes('EN')==true?'Share with others':
                  'その他と同室 '}}<br>
                   {{this.router.url.includes('EN')==true?'(List up to 3 people that you wish to share a room with) ':
                  '（同室希望者名　3名まで）'}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <br>
            <div *ngIf="showRelatedPerson">
              <table class="table " formArrayName="relativePersonsOz">
                <tr *ngFor="let myRelative of relativePersonsOz().controls; let i=index" [formGroupName]="i">
                  <td>
                    <mat-form-field appearance="outline" class="registrationInputFormSm">
                      <mat-label>
                        {{this.router.url.includes('EN')==true?'First Name': '名　ローマ字'}}
                      </mat-label>
                      <input matInput placeholder="" formControlName="FirstNameRelated" class="registrationInputBox">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="registrationInputFormSm">
                      <mat-label>
                        {{this.router.url.includes('EN')==true?'Last Name': '姓　ローマ字'}}
                      </mat-label>
                      <input matInput placeholder="" formControlName="LastNameRelated" class="registrationInputBox">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="registrationInputFormSm">
                      <mat-label>
                        {{this.router.url.includes('EN')==true?'Last Name - Kanji': '姓　漢字'}}
                      </mat-label>
                      <input matInput placeholder="" formControlName="MyojiRelated" class="registrationInputBox">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="registrationInputFormSm">
                      <mat-label>
                        {{this.router.url.includes('EN')==true?'First Name - Kanji': '名 漢字'}}
                      </mat-label>
                      <input matInput placeholder="" formControlName="ShimeiRelated" class="registrationInputBox">
                    </mat-form-field>
                    <mat-form-field *ngIf="conference.RoomInfo=='WithFamily'" appearance="outline"
                      class="registrationInputFormMd">
                      <mat-label>
                        {{this.router.url.includes('EN')==true?'Date of Birth': '生年月日'}}
                      </mat-label>
                      <input matInput placeholder="MM/dd/yyyy" formControlName="DOBRelated"
                        (dateChange)="onChangeDOB($event,i)" [matDatepicker]="DOB" />
                      <mat-datepicker-toggle matSuffix [for]="DOB"></mat-datepicker-toggle>
                      <mat-datepicker #DOB></mat-datepicker>
                      <mat-error *ngIf="myFormGroup.get('DOBRelated').hasError('required')">
                        {{this.router.url.includes('EN')==true?'DOB is required': '未入力'}}
                      </mat-error>
                    </mat-form-field>
                    <span *ngIf="showInquireChildCare" class="quesChildCare">
                      <mat-checkbox [disabled]="!this.myFormGroup.get('relativePersonsOz').value[i].Under2YearsOld" #needChildCare formControlName="ChildCare"
                      (change)="onChangeNeedChildCare(needChildCare.checked)">
                      {{this.router.url.includes('EN')==true?'Need':'チャイルド'}}<br>
                      {{this.router.url.includes('EN')==true?'Child care':'ケア必要'}}<br>
                      {{this.router.url.includes('EN')==true?'(0-2 yrs)':'(0-2歳)'}}<br>
                    </mat-checkbox>
                    </span>
                    <mat-form-field *ngIf="conference.RoomInfo=='WithFamily'" appearance="outline"
                      class="registrationInputFormXSm">
                      <mat-label>{{this.router.url.includes('EN')==true?'Gender': '性別'}}</mat-label>
                      <mat-select placeholder="" formControlName="GenderRelated" class="matInputBox" ngDefaultControl>
                        <div *ngIf="this.router.url.includes('EN')==true">
                        <mat-option *ngFor="let gender of gendersEN" [value]="gender.value" class="matInputBox">
                          {{ gender.display }}
                        </mat-option>
                        </div>
                        <div *ngIf="this.router.url.includes('EN')!=true">
                          <mat-option *ngFor="let gender of gendersJP" [value]="gender.value" class="matInputBox">
                            {{ gender.display }}
                          </mat-option>
                        </div>

                      </mat-select>
                    </mat-form-field>
                    <span class="saveRelative">
                                         <button (click)="removePerson(i)" class="btn btnSave">
                      {{this.router.url.includes('EN')==true?'Remove': '削除'}}
                    </button>
                    </span>

                  </td>
                </tr>
                <tr>
                  <th width="150px"><button type="button" (click)="addPerson()" class="btn btnAdd">
                      {{this.router.url.includes('EN')==true?'+ Add Person': '+ 同伴者追加'}}
                    </button></th>
                </tr>
              </table>
            </div>

            <div class="d-flex justify-content-center">
              <div class="processButtonSet1">
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn btnProcess" (click)="onClickGoBack()">
                    {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                  </button>
                  <button [disabled]="!myFormGroup.valid || !this.validRelatedPerson" type="button" class="btn btnProcess"
                    (click)="onClickGoForward()">
                    {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                  </button>
                </div>
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn link btnCancel" (click)="cancelProcess()">
                    {{this.router.url.includes('EN')==true?'Cancel': 'キャンセル'}}
                  </button>
                </div>
              </div>
            </div>

            <!-- <button type="submit" [disabled]="!myFormGroup.valid">Submit</button> -->
          </form>
        </mat-card>
      </div>
      <div class="myMainHalfBottom">
        <!-- Main Bottom -->
      </div>
    </div>
    <div class="myMainRight">
      <div class="myMainHalfTop">
        <!-- Right Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Right Bottom -->
      </div>
    </div>
  </div>
  <!-- <footer>footer</footer> -->
</div>
