import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
@Component({
  selector: 'app-process-failed-ec',
  templateUrl: './process-failed-ec.component.html',
  styleUrls: ['./process-failed-ec.component.css']
})
export class ProcessFailedECComponent implements OnInit {
  mySelectedLanguage:string;
  constructor(
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.mySelectedLanguage = this.router.url.includes('EN')==true?"EN":"JP";
  }

}
