<div class="myBody">
  <div class="myHeaderBase d-flex justify-content-center">
    <div class="myHeaderCore">
      <div>
        <img src='../../../../../assets/images/thumbnail_eclogo.png' class="ecLogo">
      </div>
    </div>
  </div>
  <div class="myMain">
    <div clas="myMainLeft">
      <div class="myMainHalfTop">
        <!-- Left Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Left Bottom -->
      </div>
    </div>
    <div class="myMainCenter">
      <div class="myMainHalfTop d-flex justify-content-center">
        <!-- Main Top -->

        <mat-card class="mainCard b-radius">
          <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
          <div *ngIf="!loaded">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <form [formGroup]="myFormGroup" (ngSubmit)="onSubmit()">
            <div class="float-end stepNumber">8/12</div>
            <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Sleeping Bag':
              '寝袋'}}
              <div *ngIf="conference.Student==true">
                <i class="stepTitleSub">
                  {{this.router.url.includes('EN')==true?'Required': '必須'}}
                </i>
              </div>
            </h2>

            <div class="sleepingDept">
              <div *ngIf="conference.Student!=null && conference.Student.toString()=='false'
              && conference.RegisteredDate!=null && nonStudentAdjustedEarlyBird">
                <div class="stepTitle2nd">
                  {{this.router.url.includes('EN')==true?'The accommodations have beds and linens in the rooms for
                  working
                  adults, but students do not have linens and will need a sleeping bag. Can you lend a sleeping bag (or
                  3
                  blankets) for the student? →$10 discount per piece (1 set)':
                  '宿泊施設には社会人用の部屋にはベッド、リネンがありますが、学生にはリネンがなく寝袋が必要となります。学生のためにあなたは寝袋（もしくは毛布3枚）を貸し出すことができますか？→1個（１セット）につき10ドル引き'}}
                </div>
                <br>
                <mat-checkbox #checkboxNumberOfExtraSleepingBag formControlName="myBringExtraSleepingBag"
                  [checked]="conference.BringExtraSleepingBag==true" class="radioItemExtraSleepingBag"
                  (change)="onCheckSleepingBag(checkboxNumberOfExtraSleepingBag.checked)">
                  {{this.router.url.includes('EN')==true?'I can rent out extra sleeping bags (or 3 blankets).':
                  'エクストラの寝袋（もしくは毛布3枚）を貸し出せます。'}}<br>
                  {{this.router.url.includes('EN')==true?'→$10 discount per unit (per set)':
                  '→1個（１セット）につき10ドル引き'}}
                </mat-checkbox><br><br>

                <div class="stepTitle2nd">
                  <div *ngIf="showNumberOfExtraSleepingBag">
                    <mat-form-field appearance="outline">
                      <mat-label>How many?</mat-label>
                      <input matInput formControlName="myCountExtraSleepingBag" type="number" placeholder="Enter number"
                        min="0" (change)="onChangeNumberOfExtraSleepingBag($event)">
                      <span matSuffix>sleeping bags</span>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="(conference.Student!=null && conference.Student.toString()=='true') ||
            ((conference.Student!=null && conference.Student.toString()=='false') &&
            (conference.RegisteredDate==null || (conference.RegisteredDate!=null && !nonStudentAdjustedEarlyBird)))
            ">
              <div class="stepTitle2nd">
                {{this.router.url.includes('EN')==true?
                'This accommodation has bunk beds, but no linens, so you will need to bring your own sleeping bag,
                pillow and towels. Only sleeping bags are available for rent.'
                :
                '今回の宿泊施設はバンクベッドはありますがリネンがありません。寝袋や枕、タオルは参加者が持参する必要があります。寝袋に関してのみ借りることが出来ます。'}}
              </div>
              <br>
              <mat-radio-group formControlName="mySleepingBag" (change)="onChangeMySleepingBagSelection($event)"
                class="radioBtnMain">
                <mat-radio-button value="BringMySleepingBag" class="radioBtnItem">
                  {{this.router.url.includes('EN')==true?'I will bring my own sleeping bag.': '寝袋持参します。'}}
                </mat-radio-button>
                <mat-radio-button value="BorrowMySleepingBag" class="radioBtnItem">
                  {{this.router.url.includes('EN')==true?'I would like to rent a sleeping bag.': '寝袋を借りたいです。'}}
                </mat-radio-button>
              </mat-radio-group>
              <br><br><br>

              <div *ngIf="showExtraSleepingBagInquiry">
                              <div class="stepTitle2nd">
                <mat-checkbox #checkboxNumberOfExtraSleepingBag formControlName="myBringExtraSleepingBag"
                  [checked]="conference.BringExtraSleepingBag==true" class="radioItemExtraSleepingBag"
                  (change)="onCheckSleepingBag(checkboxNumberOfExtraSleepingBag.checked)">
                  {{this.router.url.includes('EN')==true?'I can rent out extra sleeping bags (or 3 blankets).)':
                  'エクストラの寝袋（もしくは毛布3枚）を貸し出せます。'}}<br>
                  {{this.router.url.includes('EN')==true?'→ $10 discount per unit (per set)':
                  '→ 1個（１セット）につき10ドル引き'}}
                </mat-checkbox><br><br>
              </div>
              <div class="stepTitle2nd">
                <div *ngIf="showNumberOfExtraSleepingBag">
                  <mat-form-field appearance="outline">
                    <mat-label>How many?</mat-label>
                    <input matInput formControlName="myCountExtraSleepingBag" type="number" placeholder="Enter number"
                      min="0" (change)="onChangeNumberOfExtraSleepingBag($event)">
                    <span matSuffix>sleeping bags</span>
                  </mat-form-field>
                </div>
              </div>
              </div>
            </div>

            <div class=" position-absolute bottom-0 start-50 translate-middle-x">
              <div class="d-flex justify-content-center">
                <button type="button" class="btn btnProcess" (click)="onClickGoBack()">
                  {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                </button>
                <button [disabled]="!myFormGroup.valid" type="button" class="btn btnProcess"
                  (click)="onClickGoForward()">
                  {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                </button>
              </div>
              <div class="d-flex justify-content-center">
                <button type="button" class="btn link btnCancel" (click)="cancelProcess()">Cancel</button>
              </div>
            </div>
          </form>
        </mat-card>
      </div>
      <div class="myMainHalfBottom">
        <!-- Main Bottom -->
      </div>
    </div>
    <div class="myMainRight">
      <div class="myMainHalfTop">
        <!-- Right Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Right Bottom -->
      </div>
    </div>
  </div>
  <!-- <footer>footer</footer> -->
</div>
