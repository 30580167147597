import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { UserService} from "../../../../services/user/user.service";
import { Router, ActivatedRoute,NavigationExtras } from "@angular/router";
import { MatRadioChange } from '@angular/material/radio';
import { GRCConference, GRCRelatedPerson, GRCVolunteerSelection } from 'src/app/models/conference/GRCConference';
import { User } from 'src/app/models/user';
import { MatDialog,  MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import { LoginMessageComponent } from '../../../dialog/login-message/login-message.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { EcRegistrationService } from 'src/app/services/conference/ec/ec-registration.service';
import { UserEditOthersProfileComponent  } from '../../../dialog/user-edit-others-profile/user-edit-others-profile.component';
import { RequestAddressUpdateComponent} from '../../../dialog/request-address-update/request-address-update.component';
import { AuthService } from "../../../../services/auth/auth.service";
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SummaryPersonEmbedComponent } from 'src/app/components/dialog/EC/summary-person-embed/summary-person-embed.component';
//import { FaithInfoEmbedComponent } from 'src/app/components/dialog/EC/faith-info-embed/faith-info-embed.component';
import { MyAccountEditComponent } from 'src/app/components/dialog/EC/my-account-edit/my-account-edit.component';
import { CancelOrSaveConfirmationPersonalGRCComponent } from 'src/app/components/dialog/GRC/cancel-or-save-confirmation-personal-grc/cancel-or-save-confirmation-personal-grc.component';
import { MyAccountEditGRCComponent } from 'src/app/components/dialog/GRC/my-account-edit-grc/my-account-edit-grc.component';
import { RegistrationConferenceEmbedGRCComponent } from 'src/app/components/dialog/GRC/registration-conference-embed-grc/registration-conference-embed-grc.component';

@Component({
  selector: 'app-summary-person-grc',
  templateUrl: './summary-person-grc.component.html',
  styleUrls: ['./summary-person-grc.component.css']
})
export class SummaryPersonGRCComponent implements OnInit {
  loaded: boolean = true;
  myFormGroup: FormGroup;
  myMessage: string;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  mySelectedLanguage: string;
  strBoolean: string;
  editMode: boolean;

  user: User = {
    PasswordHash:null,
    Id:null,
    UserName:null,
    Email:null,
    FirstName:null,
    LastName:null,
    Password:null,
    Prefix:null,
    Shimei:null,
    Myoji:null,
    Street:null,
    Street2:null,
    City:null,
    State:null,
    Zip:null,
    Country:null,
    Yubin_Bango:null,
    To_Do_Fu_Ken:null,
    Shi_Gun_Ku:null,
    Cho_Son:null,
    Apartment_Etc:null,
    To_Do_Fu_Ken_JP:null,
    Shi_Gun_Ku_JP:null,
    Cho_Son_JP:null,
    Apartment_Etc_JP:null,
    Gender:null,
    DOB:null,
    TelNo:null,
    TimezoneName:null,
    UTCdiff:null,
    Token:null,
    Occupation:null,
    MemberStatus:null,
    Married:null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount:null,
    RegisteredDate:null,
    LastAccessedDate:null,
    // //From siteground
    LoginId:null,
    MiddleNameEN:null,
    MiddleNameJP:null,
    PrefixJP:null,
    OccupationDetail:null,
    Organization:null,
    LivingCountry:null,
    PhoneEN:null,
    PhoneMobileEN:null,
    PhoneJP:null,
    PhoneMobileJP:null,
    ChurchNameEN:null,
    ChurchNameJP:null,
    MemberType:null,
    MemberDetail:null,
    MemberMemo:null,
    Shokaisha:null,
    Reason:null,
    NewsLetters:null,
    StaffReports:null,
    OtherLetters:null,
    NewsLetterLanguage:null,
    BasicRoles:null,
    //Added new
    MigrateFromSiteGround:null,
    AdminAccess:null,
    JCFNAccess:null,
    SupportingMember:null,
    LastAddressUpdatedDate:null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };
  relatedPerson:GRCRelatedPerson={
    Id:null,
    RelatedId:null,
    Email:null,
    FirstNameRelated:null,
    LastNameRelated:null,
    MyojiRelated:null,
    ShimeiRelated:null,
    DOBRelated:null,
    GenderRelated:null
  };
  relatedPersons:GRCRelatedPerson[]=[];
  volunteerSelections:GRCVolunteerSelection[]=[];
  conference= new GRCConference();
  constructor(
    private _snackBar: MatSnackBar,
    public router: Router,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private registrationService: UserService,
    private ecRegistrationService : EcRegistrationService,
    private dialog: MatDialog,
    public authService: AuthService,
    private userService: UserService,
    /**************************************************
     * Followings 2 componensts used to call function
     * in those componets from this class
    ***************************************************/
  ) {
    this.route.queryParams.subscribe(params => {
      this.user.FirstName = params["FirstName"];
      this.user.LastName   = params["LastName"];
      console.log("1.First Name: "+ this.conference.FirstName);
      console.log("2.Last Name: "+this.conference.LastName);
  });
   }

  mySearch: any = {};
  ngOnInit(): void {
    this.myFormGroup = this._formBuilder.group({
    });
    this.loaded = false;
    if (sessionStorage.getItem("myGRCSearchingUserIdGRC") != null) {
      /*************************************************
       *  A) User info for searching registrant by admin
       *************************************************/
       this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnUserAdminGRC"));
       if (this.mySearch != null) {
        console.log('admin is editing this user')
        this.user = this.mySearch.myEditingUser;
        this.loaded = true;
       }else{
        this.loaded = true;
       }
    } else {
      /*#####################################################################
      # Get user info from previous page - basic regardless it is searching user or myself
      ######################################################################*/
      this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnUserGRC"));
      if (this.mySearch != null) {
        this.user = this.mySearch.myEditingUser;
        this.loaded = true;
      }else{
      }
  }
}

  onSubmit() {
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  goToBasic(){
    if(this.router.url.includes('/registration/GRC/')){
      this.router.navigate(["/registration/GRC/basicGRC/" +this.getMyLanguage()]);
    }else{
    }
  }

  goToBasicSub(){
    if(this.router.url.includes('/registration/GRC/')){
      this.router.navigate(["/registration/GRC/basicSubGRC/" +this.getMyLanguage()]);
    }else{
    }
  }

  goToAddressInfo(){
    if(this.router.url.includes('/registration/GRC/')){
      this.router.navigate(["/registration/GRC/addressInfoGRC/" +this.getMyLanguage()]);
    }else{
    }
  }

  goToFaithInfo(){
    if(this.router.url.includes('/registration/GRC/')){
      this.router.navigate(["/registration/GRC/faithInfoGRC/" +this.getMyLanguage()]);
    }else{
    }
  }

  savingPersonalInfo() {
    this.loaded = false;
    if (this.user.FaithQuesCompletedDate == null) {
      this.user.FaithQuesCompletedDate = new Date;
    }
    this.userService.upsertUser(this.user).subscribe(
      data => {
        this.myMessage = this.getMyLanguage() == "EN" ? "User profile saved successfully." : "個人情報が保存されました。";
        this.openSnackBar('success', 'close');
        this.loaded = true;
        if (this.authService.isLoggedIn()) {
          this.loaded = true;
          if (this.router.url.includes('/registration/GRC/')) {
            this.router.navigate(["/registration/GRC/myAccountGRC/" + this.getMyLanguage()]);
          } else {
            this.dialog.closeAll();
            this.openForwardEmbed();
          }

        } else {
          this.authService.login(this.user.Email, this.user.Password).subscribe(
            result => {
              //Login Successfully.
              this.userService.getUserProfile(this.user.Email).subscribe(
                result => {
                  this.user = result;
                  if (this.router.url.includes('/registration/GRC/')) {
                    this.router.navigate(["/registration/GRC/myAccountGRC/" + this.getMyLanguage()]);
                  } else {
                    this.dialog.closeAll();
                    this.openForwardEmbed();
                  }
                  //this.myMessage = this.getMyLanguage() == "EN" ? "User profile saved successfully." : "個人情報が保存されました!";
                  //this.openSnackBar('success', 'close');
                  this.loaded = true;
                }
              )
            },
            error => {
              this.myMessage = this.getMyLanguage() == "EN" ? "Login failed." : "ログインに失敗しました。";
              this.openSnackBar('error', 'close');
              this.loaded = true;
            }
          )
        }
      },
      error => {
        this.myMessage = this.getMyLanguage() == "EN" ? "Data save failed." : "問題が生じ個人情報が保存できませんでした。";
        this.openSnackBar('error', 'close');
        this.loaded = true;
      }
    )
    // Updateし終わった自分のPerson情報のセッションを消しておく
    sessionStorage.removeItem("myWorkingOnUserGRC");
    //Adminによる作業中のユーザーのセッションを消しておく
    if (sessionStorage.getItem("myGRCSearchingUserIdGRC") != null) {
      sessionStorage.removeItem("myWorkingOnUserAdminGRC");
    }
  }
  openForwardEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-dialog'; //Refer global level
    dialogConfig.data={ userId: this.user.Id };
    const dialogRef = this.dialog.open(MyAccountEditGRCComponent,dialogConfig);
    // const dialogRef = this.dialog.open(MyAccountEditComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  onClickGoBack(){
    if(this.router.url.includes('/registration/GRC/')){
      this.router.navigate(["/registration/GRC/faithInfoGRC/" +this.getMyLanguage()]);
    }else{
      this.dialog.closeAll();
      this.openBackEmbed();
    }
  }
  openBackEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ myPage: 'faithInfoGRC' };
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedGRCComponent,dialogConfig);
    // dialogConfig.data={ Id: this.user.Id };
    // const dialogRef = this.dialog.open(FaithInfoEmbedComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  cancelProcess(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='210px'; //Desktop
    dialogConfig.maxWidth='370px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ user: this.user };
    const dialogRef = this.dialog.open(CancelOrSaveConfirmationPersonalGRCComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
      //Retreive updated registration Info.
      //this.ngOnInit();
      let navigationExtras: NavigationExtras = {
        queryParams:this.conference
      };
      if(result!='cancel'){
        if(this.router.url.includes('/registration/GRC/')){
          this.router.navigate(["/registration/GRC/myAccountGRC/" +this.getMyLanguage()], navigationExtras);
        }else{
          this.dialog.closeAll();
          this.openMyAccountEdit();
        }
        }
    })
  }

  openMyAccountEdit(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ userId: this.user.Id };
    const dialogRef = this.dialog.open(MyAccountEditComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 6000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 20000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }
}
