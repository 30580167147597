import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { UserService} from "../../../../services/user/user.service";
import { Router, ActivatedRoute,NavigationExtras } from "@angular/router";
import { MatRadioChange } from '@angular/material/radio';
import { ECConference } from 'src/app/models/conference/ECConference';
import { User } from 'src/app/models/user';
import { CancelOrSaveConfirmationComponent } from 'src/app/components/dialog/cancel-or-save-confirmation/cancel-or-save-confirmation.component';
//For Dialog
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA , MatDialogConfig} from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { EcRegistrationService } from 'src/app/services/conference/ec/ec-registration.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { RelatedPerson} from "../../../../models/conference/RelatedPerson";
import { VolunteerSelection} from "../../../../models/conference/volunteerSelection";
import { MatCheckboxChange } from '@angular/material/checkbox';
import { RegistrationConferenceEmbedComponent } from 'src/app/components/dialog/EC/registration-conference-embed/registration-conference-embed.component';
import { MyAccountEditComponent } from 'src/app/components/dialog/EC/my-account-edit/my-account-edit.component';
@Component({
  selector: 'app-transportation',
  templateUrl: './transportation.component.html',
  styleUrls: ['./transportation.component.css']
})
export class TransportationComponent implements OnInit {
  loaded:boolean=true;
  myFormGroup: FormGroup;
  myMessage:string;
  showNeedCommingBus?:boolean;
  showNeedReturningBus?:boolean;

  internationalDomesticEN:string[]=
  ["International",
  "Domestic"]
  internationalDomesticJP:string[]=[
    "International",
    "Domestic"
  ]

  airlines:string[] =
  ["Advanced Air",
  "Aer Lingus",
  "Aeroflot",
  "Aeromexico",
  "Air Canada",
  "Air China",
  "Air France",
  "Air New Zealand",
  "Air Tahiti Nui",
  "Air Transat",
  "Alaska Airlines",
  "All Nippon Airways - ANA",
  "Allegiant Air",
  "American Airlines",
  "American Eagle",
  "Asiana Airlines",
  "Austrian Airlines",
  "Avianca Airlines",
  "Boutique Airlines",
  "British Airways",
  "Cathay Pacific Airlines",
  "China Airlines",
  "China Cargo Airlines",
  "China Eastern",
  "China Southern Airlines",
  "Condor",
  "Copa Airlines",
  "Delta Airlines",
  "El Al Israel Airlines",
  "Emirates",
  "Etihad Airways",
  "EVA Air",
  "Fiji Airways",
  "Finnair",
  "French Bee",
  "Hainan Airlines",
  "Hawaiian Airlines",
  "Horizon Air",
  "Iberia Airlines",
  "InterJet",
  "ITA Airways",
  "Japan Airlines",
  "JetBlue",
  "KLM Royal Dutch Airlines",
  "Korean Airlines",
  "LATAM",
  "Level Airlines",
  "LOT Polish",
  "Lufthansa",
  "Mokulele Airlines",
  "Nippon Cargo Airlines",
  "Norwegian Air Shuttle",
  "Philippine Airlines",
  "Qantas",
  "Qatar Airways",
  "Saudia Airlines",
  "Scandinavian Airlines",
  "Sichuan Airlines",
  "Singapore Airlines",
  "Southwest Airlines",
  "Spirit Airlines",
  "Sun Country",
  "Swiss International Air Lines Ltd",
  "Thomas Cook Airlines",
  "Turkish Airlines",
  "United Airlines",
  "Virgin Atlantic",
  "Virgin Australia",
  "Viva Aerobus",
  "Volaris",
  "West Jet",
  "Xiamen Airlines",
  "XL Airways France",
  "Zipair",
  "Other"];

  user: User = {
    PasswordHash:null,
    Id:null,
    UserName:null,
    Email:null,
    FirstName:null,
    LastName:null,
    Password:null,
    Prefix:null,
    Shimei:null,
    Myoji:null,
    Street:null,
    Street2:null,
    City:null,
    State:null,
    Zip:null,
    Country:null,
    Yubin_Bango:null,
    To_Do_Fu_Ken:null,
    Shi_Gun_Ku:null,
    Cho_Son:null,
    Apartment_Etc:null,
    To_Do_Fu_Ken_JP:null,
    Shi_Gun_Ku_JP:null,
    Cho_Son_JP:null,
    Apartment_Etc_JP:null,
    Gender:null,
    DOB:null,
    TelNo:null,
    TimezoneName:null,
    UTCdiff:null,
    Token:null,
    Occupation:null,
    MemberStatus:null,
    Married:null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount:null,
    RegisteredDate:null,
    LastAccessedDate:null,
    //From siteground
    LoginId:null,
    MiddleNameEN:null,
    MiddleNameJP:null,
    PrefixJP:null,
    OccupationDetail:null,
    Organization:null,
    LivingCountry:null,
    PhoneEN:null,
    PhoneMobileEN:null,
    PhoneJP:null,
    PhoneMobileJP:null,
    ChurchNameEN:null,
    ChurchNameJP:null,
    MemberType:null,
    MemberDetail:null,
    MemberMemo:null,
    Shokaisha:null,
    Reason:null,
    NewsLetters:null,
    StaffReports:null,
    OtherLetters:null,
    NewsLetterLanguage:null,
    BasicRoles:null,
    //Added new
    MigrateFromSiteGround:null,
    AdminAccess:null,
    JCFNAccess:null,
    SupportingMember:null,
    LastAddressUpdatedDate:null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };
  relatedPerson:RelatedPerson={
    Id:null,
    RelatedId:null,
    Email:null,
    FirstNameRelated:null,
    LastNameRelated:null,
    MyojiRelated:null,
    ShimeiRelated:null,
    DOBRelated:null,
    GenderRelated:null
  };
  relatedPersons:RelatedPerson[]=[];
  volunteerSelections:VolunteerSelection[]=[];
  conference: ECConference= {
    ECConferenceId:0,
    UserId: null,
    Email: null,
    UserName: null,
    FirstName: null,
    LastName: null,
    //Program
    ProgramType: null,
    SmallGroupLanguage: null,
    AttendJapanRegionalMeeting:null,
    JapanRegionalMeeting:null,
    ApplyEnglishPartner:null,
    //Payment
    ConferenceFee: null,
    ConferenceDiscountAmount: null,
    ConferenceFeePaidAmount: null,
    ConferenceFeePaidDate: null,
    CouponApproved: null,
    ApplyScholarship: null,
    ApplyingScholarshipAmount: null,
    ApprovedScholarshipAmount: null,
    ApplyingReason: null,
    //Others\
    Volunteers:null,
    Student:null,
    ApplyCounseling: null,
    MySleepingBag:null,
    BringExtraSleepingBag:null,
    CountExtraSleepingBag:null,
    NeedCommingBus:null,
    CommingBusDeparturePlace:null,
    CommingBusDepartureDateTime:null,
    CommingAirline:null,
    CommingFlightNo:null,
    DepartureCity:null,
    CommingInternationalDomestic:null,
    ArrivalDateTime:null,
    NeedReturningBus:null,
    ReturningBusDeparturePlace:null,
    ReturningBusDepartureDateTime:null,
    ReturningAirline:null,
    ReturningFlightNo:null,
    ReturningCity:null,
    ReturningInternationalDomestic:null,
    DepartureDateTime:null,
    RoomInfo:null,
    Couple:null,
    Youth:null,
    YouthGrade: null,
    GuardianFirstName: null,
    GuardianLastName: null,
    GuardianMyoji: null,
    GuardianShimei: null,
    GuardianEmail: null,
    GuardianTelNo: null,
    TermsOfParticipation: null,
    TermsOfParticipationParent: null,
    TermsOfParticipationDate: null,
    TermsOfParticipationDateParent: null,
    RegisteredDate: null,
    UpdatedDate: null,

    AdminAccess: null,
    ECCommitteeAccess: null,
    Memo: null,
    Dec26:null,
    Dec27:null,
    Dec28:null,
    Dec29:null,
    Dec30:null,
    Dec31:null,
    KidGroup1:null,
    KidGroup2:null,
    KidGroup3:null,
    KidGroup4:null,
    myRelatedPersons: this.relatedPersons,
    myVolunteerSelections:this.volunteerSelections,
    BusComingConfirmation:null,
    BusReturningConfirmation:null,
    TermsOfParticipationVenue:null,
    TermsOfParticipationDateVenue:null,
    TermsOfParticipationYouth:null,
    TermsOfParticipationDateYouth:null,
    TermsOfParticipationYouthParent:null,
    TermsOfParticipationDateYouthParent:null,
    EmergencyContactFirstName:null,
    EmergencyContactLastName:null,
    EmergencyContactEmail:null,
    EmergencyContactTelNo:null,
    ApplicationFee:null,
    ApplicationFeePaidDate:null,
    BalanceAmount:null,
    BalanceAmountPaidDate:null,
    HasAllergy:null,
    AllergyDetail:null,
    BringInsurance:null,
    MinorHasCustodyInsurance:null,
    MinorHasCustodyInsuranceDate:null,
    InsuranceCompany:null,
    InsuranceAddress:null,
    InsuranceTelNo:null,
    InsurancePolicyNo:null,
    InsuranceGroupNo:null,
    VolunteerAgreeConductCode:null,
    VolunteerAgreeConductCodeDate:null,
    PayingAmount:null,
     //New
    BecomeMentor:null,
    ApplyMentoring:null,
    ReturningDepartureCity:null,
    InsuranceStatus:null,
    DressCodeAgreeYouth:null,
    DressCodeDateYouth:null,
    DressCodeAgreeParent:null,
    DressCodeDateParent:null
}

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private registrationService: UserService,
    private ecRegistrationService : EcRegistrationService,
    private dialog: MatDialog,
  ) { }
  mySearch: any = {};
  ngOnInit(): void {
    this.myFormGroup = this._formBuilder.group({
      myNeedCommingBus: [null, Validators.required],
      myNeedReturningBus:[null, Validators.required],
      myCommingBusDeparturePlaceDateTime: [null, ],
      myReturningBusDeparturePlaceDateTime:[null,],
      myCommingAirline:[null,],
      myReturningAirline:[null,],
      myCommingFlightNo:[null],
      myReturningFlightNo:[null],
      myDepartureCity:[null],
      myReturningDepartureCity:[null],
      myReturningCity:[null],
      myArrivalDateTime:[null],
      myDepartureDateTime:[null],
      myArrivalTime:[null],
      myDepartureTime:[null],
      myCommingInternationalDomestic:[],
      myReturningInternationalDomestic:[],
      myBusComingConfirmation:[null],
      myBusReturningConfirmation:[null],

    });

    if (sessionStorage.getItem("myECSearchingUserId") != null) {
      /*************************************************
       *  A) User info for searching registrant by admin
       *************************************************/
       this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnConferenceAdmin"));
       if (this.mySearch != null) {
         this.conference = this.mySearch.myEditingConference;
         this.patchModelValue(this.conference);
         this.loaded = true;
       }else{
        this.loaded = true;
       }
    } else {
    /*####################################################################################
    # Get conference info from previous page for myself
    #####################################################################################*/
    this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnConference"));
      if (this.mySearch != null) {
        this.conference = this.mySearch.myEditingConference;
        this.patchModelValue(this.conference);
        this.loaded = true;
      }else{
      }
      this.formatValidationCheck();
    }
  }

  formatValidationCheck(){
    if(this.conference.NeedCommingBus==true){
    //   this.myFormGroup.get('myCommingBusDeparturePlaceDateTime').setValidators(Validators.required);
    //   this.myFormGroup.get('myCommingBusDeparturePlaceDateTime').updateValueAndValidity();
    //   this.myFormGroup.get('myCommingAirline').setValidators(Validators.required);
    //   this.myFormGroup.get('myCommingAirline').updateValueAndValidity();
    //   this.myFormGroup.get('myCommingFlightNo').setValidators(Validators.required);
    //   this.myFormGroup.get('myCommingFlightNo').updateValueAndValidity();
    //   this.myFormGroup.get('myDepartureCity').setValidators(Validators.required);
    //   this.myFormGroup.get('myDepartureCity').updateValueAndValidity();
    //   this.myFormGroup.get('myArrivalDateTime').setValidators(Validators.required);
    //   this.myFormGroup.get('myArrivalDateTime').updateValueAndValidity();
    // this.myFormGroup.get('myCommingInternationalDomestic').setValidators(Validators.required);
    //  this.myFormGroup.get('myCommingInternationalDomestic').updateValueAndValidity();
    //  this.myFormGroup.get('myBusComingConfirmation').setValidators(Validators.required);
    //  this.myFormGroup.get('myBusComingConfirmation').updateValueAndValidity();
    }else{
      this.myFormGroup.get('myCommingBusDeparturePlaceDateTime').clearValidators();
      this.myFormGroup.get('myCommingBusDeparturePlaceDateTime').updateValueAndValidity();
      this.myFormGroup.get('myCommingAirline').clearValidators();
      this.myFormGroup.get('myCommingAirline').updateValueAndValidity();
      this.myFormGroup.get('myCommingFlightNo').clearValidators();
      this.myFormGroup.get('myCommingFlightNo').updateValueAndValidity();
      this.myFormGroup.get('myDepartureCity').clearValidators();
      this.myFormGroup.get('myDepartureCity').updateValueAndValidity();
      this.myFormGroup.get('myArrivalDateTime').clearValidators();
      this.myFormGroup.get('myArrivalDateTime').updateValueAndValidity();
      this.myFormGroup.get('myArrivalTime').clearValidators();
      this.myFormGroup.get('myArrivalTime').updateValueAndValidity();
      this.myFormGroup.get('myCommingInternationalDomestic').clearValidators();
      this.myFormGroup.get('myCommingInternationalDomestic').updateValueAndValidity();
    }
    if(this.conference.NeedReturningBus==true){
    //   this.myFormGroup.get('myReturningBusDeparturePlaceDateTime').setValidators(Validators.required);
    //   this.myFormGroup.get('myReturningBusDeparturePlaceDateTime').updateValueAndValidity();
    //   this.myFormGroup.get('myReturningAirline').setValidators(Validators.required);
    //   this.myFormGroup.get('myReturningAirline').updateValueAndValidity();
    //   this.myFormGroup.get('myReturningFlightNo').setValidators(Validators.required);
    //   this.myFormGroup.get('myReturningFlightNo').updateValueAndValidity();
    //   this.myFormGroup.get('myReturningCity').setValidators(Validators.required);
    //   this.myFormGroup.get('myReturningCity').updateValueAndValidity();
    //   this.myFormGroup.get('myDepartureDateTime').setValidators(Validators.required);
    //   this.myFormGroup.get('myDepartureDateTime').updateValueAndValidity();
    //  this.myFormGroup.get('myReturningInternationalDomestic').setValidators(Validators.required);
    //  this.myFormGroup.get('myReturningInternationalDomestic').updateValueAndValidity();
    //this.myFormGroup.get('myBusReturningConfirmation').setValidators(Validators.required);
    //this.myFormGroup.get('myBusReturningConfirmation').updateValueAndValidity()
    }else{
      this.myFormGroup.get('myReturningBusDeparturePlaceDateTime').clearValidators();
      this.myFormGroup.get('myReturningBusDeparturePlaceDateTime').updateValueAndValidity();
      this.myFormGroup.get('myReturningAirline').clearValidators();
      this.myFormGroup.get('myReturningAirline').updateValueAndValidity();
      this.myFormGroup.get('myReturningFlightNo').clearValidators();
      this.myFormGroup.get('myReturningFlightNo').updateValueAndValidity();
      this.myFormGroup.get('myReturningDepartureCity').clearValidators();
      this.myFormGroup.get('myReturningDepartureCity').updateValueAndValidity();
      this.myFormGroup.get('myReturningCity').clearValidators();
      this.myFormGroup.get('myReturningCity').updateValueAndValidity();
      this.myFormGroup.get('myDepartureDateTime').clearValidators();
      this.myFormGroup.get('myDepartureDateTime').updateValueAndValidity();
      this.myFormGroup.get('myDepartureTime').clearValidators();
      this.myFormGroup.get('myDepartureTime').updateValueAndValidity();
      this.myFormGroup.get('myReturningInternationalDomestic').clearValidators();
      this.myFormGroup.get('myReturningInternationalDomestic').updateValueAndValidity();
    }
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  patchModelValue(model:ECConference){
    this.myFormGroup.patchValue({
      myNeedCommingBus: model.NeedCommingBus,
      myNeedReturningBus:model.NeedReturningBus,
      myCommingBusDeparturePlaceDateTime:   model.CommingBusDeparturePlace,
      myReturningBusDeparturePlaceDateTime: model.ReturningBusDeparturePlace,
      myCommingAirline:model.CommingAirline,
      myReturningAirline:model.ReturningAirline,
      myCommingFlightNo:model.CommingFlightNo,
      myReturningFlightNo:model.ReturningFlightNo,
      myDepartureCity:model.DepartureCity,
      myReturningDepartureCity:model.ReturningDepartureCity,
      myReturningCity:model.ReturningCity,
      myArrivalDateTime:model.ArrivalDateTime,
      myDepartureDateTime:model.DepartureDateTime,
      myArrivalTime:model.ArrivalDateTime,
      myDepartureTime:model.DepartureDateTime,
      myCommingInternationalDomestic:model.CommingInternationalDomestic,
      myReturningInternationalDomestic:model.ReturningInternationalDomestic,
      myBusComingConfirmation:model.BusComingConfirmation,
      myBusReturningConfirmation:model.BusReturningConfirmation,
    });
    this.showNeedReturningBus=model.NeedReturningBus;
    this.showNeedCommingBus=model.NeedCommingBus;
  }

  onCheckBusComingConfirmation($event: MatCheckboxChange) {

  }
  onCheckBusReturningConfirmation($event: MatCheckboxChange) {

  }

  getFormValue() {
   // this.conference.NeedCommingBus = this.myFormGroup.get('myNeedCommingBus').value;
   // this.conference.NeedReturningBus = this.myFormGroup.get('myNeedReturningBus').value;
    this.conference.CommingBusDeparturePlace   = this.myFormGroup.get('myCommingBusDeparturePlaceDateTime').value;
    this.conference.ReturningBusDeparturePlace = this.myFormGroup.get('myReturningBusDeparturePlaceDateTime').value;
    this.conference.BusComingConfirmation =  this.myFormGroup.get('myBusComingConfirmation').value;
    this.conference.BusReturningConfirmation =  this.myFormGroup.get('myBusReturningConfirmation').value;
    this.conference.CommingAirline = this.myFormGroup.get('myCommingAirline').value;
    this.conference.ReturningAirline = this.myFormGroup.get('myReturningAirline').value;
    this.conference.CommingFlightNo = this.myFormGroup.get('myCommingFlightNo').value;
    this.conference.ReturningFlightNo = this.myFormGroup.get('myReturningFlightNo').value;
    this.conference.DepartureCity = this.myFormGroup.get('myDepartureCity').value;
    this.conference.ReturningDepartureCity=this.myFormGroup.get('myReturningDepartureCity').value;
    this.conference.ReturningCity = this.myFormGroup.get('myReturningCity').value;
    this.conference.ArrivalDateTime = this.myFormGroup.get('myArrivalDateTime').value;
    this.conference.DepartureDateTime = this.myFormGroup.get('myDepartureDateTime').value;
    this.conference.CommingInternationalDomestic = this.myFormGroup.get('myCommingInternationalDomestic').value;
    this.conference.ReturningInternationalDomestic = this.myFormGroup.get('myReturningInternationalDomestic').value;
  //  alert(this.conference.ArrivalDateTime);
  }

  onChangeArrivalDateTime(event: any) {
    console.log(event.value)
  }

  onChangeDepartureDateTime(event:any) {
    console.log(event.value)
  }

  onChangeNeedCommingBusSelection($event:any){
    this.conference.NeedCommingBus =$event.value == 'true' ? true : false;
    this.showNeedCommingBus=this.conference.NeedCommingBus;
    if(this.conference.NeedCommingBus!=true){
      this.conference.CommingBusDeparturePlace=null;
      this.conference.CommingAirline=null;
      this.conference.CommingFlightNo=null;
      this.conference.DepartureCity=null;
      this.conference.ArrivalDateTime=null;
     // this.patchModelValue(this.conference);
     this.myFormGroup.patchValue({
      myCommingBusDeparturePlaceDateTime: null,
      myCommingAirline:null,
      myCommingFlightNo:null,
      myDepartureCity:null,
      myArrivalDateTime:null,
      myArrivalTime:null,
    });
    }

    if(this.conference.NeedCommingBus==true){
      this.myFormGroup.get('myBusComingConfirmation').setValidators(Validators.required);
      this.myFormGroup.get('myBusComingConfirmation').updateValueAndValidity();
      }else{
        this.myFormGroup.get('myBusComingConfirmation').clearValidators();
        this.myFormGroup.get('myBusComingConfirmation').updateValueAndValidity();
    }

    this.formatValidationCheck();
  }

  onChangeNeedReturningBusSelection($event:any){
    this.conference.NeedReturningBus =$event.value == 'true' ? true : false;
    this.showNeedReturningBus=this.conference.NeedReturningBus;
    if(this.conference.NeedReturningBus!=true){
      this.conference.ReturningBusDeparturePlace=null;
      this.conference.ReturningAirline=null;
      this.conference.ReturningFlightNo=null;
      this.conference.ReturningCity=null;
      this.conference.DepartureDateTime=null;
     // this.patchModelValue(this.conference);
     this.myFormGroup.patchValue({
      myReturningBusDeparturePlaceDateTime: null,
      myReturningAirline:null,
      myReturningFlightNo:null,
      myReturningDepartureCity:null,
      myReturningCity:null,
      myDepartureDateTime:null,
    });
    }

    if(this.conference.NeedReturningBus==true){
      this.myFormGroup.get('myBusReturningConfirmation').setValidators(Validators.required);
      this.myFormGroup.get('myBusReturningConfirmation').updateValueAndValidity();
      }else{
        this.myFormGroup.get('myBusReturningConfirmation').clearValidators();
        this.myFormGroup.get('myBusReturningConfirmation').updateValueAndValidity();
    }

    this.formatValidationCheck();
  }

  onChangeCommingBusDeparturePlaceDateTime($event:any){
  }

  onChangeReturningBusDeparturePlaceDateTime($event:any){
  }

  onClickGoForward(){
    this.getFormValue();
    if(this.router.url.includes('/registration/ec/')){
      sessionStorage.removeItem("myWorkingOnConference");
      sessionStorage.setItem(
        "myWorkingOnConference",
        JSON.stringify({ myEditingConference: this.conference })
      );
      this.router.navigate(["/registration/ec/roomInfo/" +this.getMyLanguage()]);
    }else{
      sessionStorage.removeItem("myWorkingOnConferenceAdmin");
      sessionStorage.setItem(
        "myWorkingOnConferenceAdmin",
        JSON.stringify({ myEditingConference: this.conference })
      );
      this.dialog.closeAll();
      this.openForwardEmbed();
    }
  }
  openForwardEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktoop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ myPage: 'roomInfo' };
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }


  onClickGoBack(){
    if(this.router.url.includes('/registration/ec/')){
      this.router.navigate(["/registration/ec/student/" +this.getMyLanguage()]);
    }else{
      this.dialog.closeAll();
      this.openBackEmbed();
    }
  }
  openBackEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ myPage: 'student' };
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  onSubmit() {
  }

  cancelProcess(){
    this.getFormValue();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='210px'; //Desktop
    dialogConfig.maxWidth='370px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ conference: this.conference };
    const dialogRef = this.dialog.open(CancelOrSaveConfirmationComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
      //Retreive updated registration Info.
      //this.ngOnInit();
      let navigationExtras: NavigationExtras = {
        queryParams:this.conference
      };
      if(result!='cancel'){
        if(this.router.url.includes('/registration/ec/')){
          this.router.navigate(["/conference/EC/myAccount/" +this.getMyLanguage()], navigationExtras);
        }else{
          this.dialog.closeAll();
          this.openMyAccountEdit();
        }
        }
    })
  }

  openMyAccountEdit(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ userId: this.conference.UserId };
    const dialogRef = this.dialog.open(MyAccountEditComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }
  }
