import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { ParticipantEditMyAccountComponent } from '../../dialog/participant-edit-my-account/participant-edit-my-account.component';
import { Router, ActivatedRoute } from "@angular/router";

export interface DialogData {
  Id: string
}

@Component({
  selector: 'app-user-edit-others-profile',
  templateUrl: './user-edit-others-profile.component.html',
  styleUrls: ['./user-edit-others-profile.component.css']
})
export class UserEditOthersProfileComponent implements OnInit {

  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<UserEditOthersProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog
  ) { }

  myId: string;

  ngOnInit(): void {
    this.myId = this.data.Id;
  }

  onClickClose() {
    sessionStorage.removeItem("searchingRegistrantId");
    this.dialogRef.close();
  }
}
