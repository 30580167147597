import { Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { UserService} from "../../../../services/user/user.service";
import { Router, ActivatedRoute,NavigationExtras } from "@angular/router";
import { MatRadioChange } from '@angular/material/radio';
import { User } from 'src/app/models/user';
import { CancelOrSaveConfirmationComponent } from 'src/app/components/dialog/cancel-or-save-confirmation/cancel-or-save-confirmation.component';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { RegistrationConferenceEmbedComponent } from 'src/app/components/dialog/EC/registration-conference-embed/registration-conference-embed.component';
import { MyAccountEditComponent } from 'src/app/components/dialog/EC/my-account-edit/my-account-edit.component';
import { GRCConference, GRCRelatedPerson, GRCVolunteerSelection } from 'src/app/models/conference/GRCConference';
import { RegistrationConferenceEmbedGRCComponent } from 'src/app/components/dialog/GRC/registration-conference-embed-grc/registration-conference-embed-grc.component';

@Component({
  selector: 'app-lodging-schedule-grc',
  templateUrl: './lodging-schedule-grc.component.html',
  styleUrls: ['./lodging-schedule-grc.component.css']
})
export class LodgingScheduleGRCComponent implements OnInit {
  loaded:boolean=true;
  myFormGroup: FormGroup;
  myMessage:string;
  mySelectedLanguage: string;
  disableSelectingAttendJapanRegionalMeeting:boolean;
  disableJapanRegionalMeetingSelection:boolean;
  showCalculation?:boolean=true;
  stayAmountMyself:number=0;
  stayAmountKidNo1:number=0;
  stayAmountKidNo2:number=0;
  stayAmountKidNo3:number=0;
  stayAmountKidNo4:number=0;
  noStayAmountMyself:number=0;
  noStayAmountKidNo1:number=0;
  noStayAmountKidNo2:number=0;
  noStayAmountKidNo3:number=0;
  noStayAmountKidNo4:number=0;
  subTotalMyself?:number=0;
  subTotalKidNo1?:number=0;
  subTotalKidNo2?:number=0;
  subTotalKidNo3?:number=0;
  subTotalKidNo4?:number=0;
  kidNo1Name:string;
  kidNo2Name:string;
  kidNo3Name:string;
  kidNo4Name:string;
  kidNo1WithNoCharge:boolean=false;
  kidNo2WithNoCharge:boolean=false;
  kidNo3WithNoCharge:boolean=false;
  kidNo4WithNoCharge:boolean=false;
  numberOfKidsUnder18:number = 0;
  myChildCount:number=0;
  stayCount:number=0;
  dayTrip:number=0;
  showPreviousDay?:boolean;
  showMessageForPartial?:boolean;
  showLodgingSchedule?:boolean;
  StayAllDays?:boolean;
  StayPartially?:boolean;
  May3Breakfast?:boolean;
  May3Lunch?:boolean;
  May3Dinner?:boolean;
  May4Breakfast?:boolean;
  May4Lunch?:boolean;
  May4Dinner?:boolean;
  May5Breakfast?:boolean;
  May5Lunch?:boolean;
  May5Dinner?:boolean;
  May6Breakfast?:boolean;
  May6Lunch?:boolean;
  May6Dinner?:boolean;
  kidsAge:number[]=[];

  user: User = {
    PasswordHash:null,
    Id:null,
    UserName:null,
    Email:null,
    FirstName:null,
    LastName:null,
    Password:null,
    Prefix:null,
    Shimei:null,
    Myoji:null,
    Street:null,
    Street2:null,
    City:null,
    State:null,
    Zip:null,
    Country:null,
    Yubin_Bango:null,
    To_Do_Fu_Ken:null,
    Shi_Gun_Ku:null,
    Cho_Son:null,
    Apartment_Etc:null,
    To_Do_Fu_Ken_JP:null,
    Shi_Gun_Ku_JP:null,
    Cho_Son_JP:null,
    Apartment_Etc_JP:null,
    Gender:null,
    DOB:null,
    TelNo:null,
    TimezoneName:null,
    UTCdiff:null,
    Token:null,
    Occupation:null,
    MemberStatus:null,
    Married:null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount:null,
    RegisteredDate:null,
    LastAccessedDate:null,
    //From siteground
    LoginId:null,
    MiddleNameEN:null,
    MiddleNameJP:null,
    PrefixJP:null,
    OccupationDetail:null,
    Organization:null,
    LivingCountry:null,
    PhoneEN:null,
    PhoneMobileEN:null,
    PhoneJP:null,
    PhoneMobileJP:null,
    ChurchNameEN:null,
    ChurchNameJP:null,
    MemberType:null,
    MemberDetail:null,
    MemberMemo:null,
    Shokaisha:null,
    Reason:null,
    NewsLetters:null,
    StaffReports:null,
    OtherLetters:null,
    NewsLetterLanguage:null,
    BasicRoles:null,
    //Added new
    MigrateFromSiteGround:null,
    AdminAccess:null,
    JCFNAccess:null,
    SupportingMember:null,
    LastAddressUpdatedDate:null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };
  relatedPerson:GRCRelatedPerson={
    Id:null,
    RelatedId:null,
    Email:null,
    FirstNameRelated:null,
    LastNameRelated:null,
    MyojiRelated:null,
    ShimeiRelated:null,
    DOBRelated:null,
    GenderRelated:null,
    ChildCare:null,
    ChildProgram:null
  };
  relatedPersons:GRCRelatedPerson[]=[];
  volunteerSelections:GRCVolunteerSelection[]=[];
  conference = new GRCConference();
  constructor(
    public router: Router,
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
  ) { }

  mySearch: any = {};
  ngOnInit(): void {
    this.myFormGroup = this._formBuilder.group({
      myMay3:[null ],
      myMay4:[null],
      myMay5:[null],
      myMay6:[null],
      myLodgingSchedule:[null,Validators.required ],
      myMay3Breakfast:[null],
      myMay3Lunch:[null],
      myMay3Dinner:[null],
      myMay4Breakfast:[null],
      myMay4Lunch:[null],
      myMay4Dinner:[null],
      myMay5Breakfast:[null],
      myMay5Lunch:[null],
      myMay5Dinner:[null],
      myMay6Breakfast:[null],
      myMay6Lunch:[null],
      myMay6Dinner:[null],
    });
    this.loaded = false;
    if (sessionStorage.getItem("myGRCSearchingUserIdGRC") != null) {
      /*************************************************
       *  A) User info for searching registrant by admin
       *************************************************/
       this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnConferenceAdminGRC"));
       if (this.mySearch != null) {
        console.log('admin is editing this user')
         this.conference = this.mySearch.myEditingConference;
         this.patchModelValue(this.conference);
         this.retrievePersonFromDb(this.conference);
         this.updateStayAmount();
         this.processSetPartialAttending();
         this.loaded = true;
       }else{
        this.loaded = true;
       }
    } else {
    /*####################################################################################
    # Get conference info from previous page for myself
    #####################################################################################*/
    this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnConference"));
      if (this.mySearch != null) {
        this.conference = this.mySearch.myEditingConference;
        //Temporary solution because total fee calculation module did not complete in Aug.
        this.patchModelValue(this.conference);
        this.retrievePersonFromDb(this.conference);
        this.updateStayAmount();
        this.processSetPartialAttending();
        this.loaded = true;
      }else{
      }
    }

  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  retrievePersonFromDb(model: GRCConference) {
    if (model.myGRCRelatedPersons != null && model.myGRCRelatedPersons.length > 0) {
      model.myGRCRelatedPersons.forEach(person => {
       //this.kidsAge.push(this.getAge(person.DOBRelated));
       this.kidsAge.push(this.calculateAge(person.DOBRelated));
      });
    }
  }

  getAge(date:Date) {
    var ECFirstDate =  new Date('12/27/2022');
    var birthDate = date; //new Date(dateString);
    var age = ECFirstDate.getFullYear() - birthDate.getFullYear();
    var m = ECFirstDate.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && ECFirstDate.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

calculateAge(date:Date) {
  //var ageDifMs = Date.now() - new Date(date).getTime();
  //var ECFirstDate =  new Date('12/27/2022');
  var ECFirstDate =  new Date('05/03/2023');
  var ageDifMs = ECFirstDate.getTime() - new Date(date).getTime();
  var ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

  patchModelValue(model:GRCConference){
    var allOrPartial='';
    if(
      this.conference.May3==null &&
      this.conference.May4==null &&
      this.conference.May5==null &&
      this.conference.May6==null
    ){
      this.showPreviousDay=false;
      this.showMessageForPartial=false;
      this.showLodgingSchedule=false;
      this.StayAllDays=null;
      this.StayPartially=null;
    }else if(model.May3=='StayMay3' &&
      model.May4=='StayMay4' &&
      model.May5=='StayMay5'
       &&
      model.May6=='NoStayMay6'
    ){
      allOrPartial = "StayAllDays";
      this.StayAllDays=true;
      this.StayPartially=false;
      this.showLodgingSchedule=true;
      this.showMessageForPartial=false;
    }
    else{
      allOrPartial = "StayPartially";
      this.StayAllDays=false;
      this.StayPartially=true;
      this.showLodgingSchedule=true;
      this.showMessageForPartial=true;
    }
    this.myFormGroup.patchValue({
      myLodgingSchedule:allOrPartial,
      myMay3:model.May3,
      myMay4:model.May4,
      myMay5:model.May5,
      myMay6:model.May6
    });
    // if(this.conference.ConferenceFee!=null){
    //   this.showCalculation=true;
    // }else{
    //   this.showCalculation=false;
    // }
  }

  getFormValue() {
   // this.conference.AttendJapanRegionalMeeting =$event.value == 'true' ? true : false;
    //this.conference.AttendJapanRegionalMeeting = this.myFormGroup.get('myAttendingJapanRegionalMeeting').value;
    //this.conference.JapanRegionalMeeting = this.myFormGroup.get('myJapanRegionalMeetingSelection').value;
  }

  onChangeMyLodgingSchedule($event: MatRadioChange){
    //this.showCalculation=true;
    if($event.value=="StayAllDays"){
      this.StayAllDays=true;
      this.StayPartially=false;
      //this.showPreviousDay=true;
      this.showMessageForPartial=false;
      this.showLodgingSchedule=true;
      this.conference.May3='StayMay3';
      this.conference.May4='StayMay4';
      this.conference.May5='StayMay5';
      this.conference.May6='NoStayMay6';
    }else if ($event.value=="StayPartially"){
      this.StayAllDays=false;
      this.StayPartially=true;
      this.showPreviousDay=false;
      this.showLodgingSchedule=true;
      this.showMessageForPartial=true;
      this.conference.May3='NoParticipateMay3';
      this.conference.May4='NoParticipateMay4';
      this.conference.May5='NoParticipateMay5';
      this.conference.May6='NoParticipateMay6';
    }
    this.patchModelValue(this.conference);
    this.updateStayAmount();
    this.updateStayCount();
    this.updateTotalAmount();
  }

  onChangeMay3($event: MatRadioChange) {
    this.conference.May3 = $event.value;
    if($event.value=='StayMay3'){
      this.May3Dinner=true;
      this.May4Breakfast=true;
      this.May4Lunch=true;
    }else if ($event.value=='NoStayMay3'){
      this.May3Lunch=true;
      this.May3Dinner=true;
      this.May4Breakfast=null;
      this.May4Lunch=null;
    }else{
      this.May3Dinner=null;
      this.May4Breakfast=null;
      this.May4Lunch=null;
    }
    this.updateStayAllPartially();
    this.processSetPartialAttending()
  }

  onChangeMay4($event: MatRadioChange) {
    this.conference.May4 = $event.value;
    if($event.value=='StayMay4'){
      this.May4Lunch=true;
      this.May4Dinner=true;
      this.May5Breakfast=true;
    }else if ($event.value=='NoStayMay4'){
      this.May4Lunch=true;
      this.May4Dinner=true;
      this.May5Breakfast=null;
    }else{
      this.May4Lunch=null;
      this.May4Dinner=null;
      this.May5Breakfast=null;
    }
    this.updateStayAllPartially();
    this.processSetPartialAttending()
  }

  onChangeMay5($event: MatRadioChange) {
    this.conference.May5 = $event.value;
    if($event.value=='StayMay5'){
      this.May5Lunch=true;
      this.May5Dinner=true;
      this.May6Breakfast=true;
    }else if ($event.value=='NoStayMay5'){
      this.May5Lunch=true;
      this.May5Dinner=true;
      this.May6Breakfast=null;
    }else{
      this.May5Lunch=null;
      this.May5Dinner=null;
      this.May6Breakfast=null;
    }
    this.updateStayAllPartially();
    this.processSetPartialAttending()
  }

  onChangeMay6($event: MatRadioChange) {
    this.conference.May6 = $event.value;
  if ($event.value=='NoStayMay6'){
      this.May6Lunch=true;
      //this.May6Dinner=true;
    }else{
      this.May6Lunch=null;
      this.May6Dinner=null;
    }
    this.updateStayAllPartially();
    this.processSetPartialAttending()
  }

  updateStayAmount() {
    if (this.StayAllDays) {
      this.stayAmountMyself = this.getStayAllDaysAmountMyself();
      var count = 1;
      if (this.conference.myGRCRelatedPersons != null && this.conference.myGRCRelatedPersons.length > 0) {
        this.conference.myGRCRelatedPersons.sort(x=>x.Id).forEach(person => {
          var age = this.calculateAge(person.DOBRelated);
          var childName = person.FirstNameRelated;
          var childCare = person.ChildCare;
          var childProgram = person.ChildProgram;
          if (age < 18) { //remove spouse
            var stayAmountForKid = this.getStayAllDaysAmountForKid(age, childCare,childProgram);
            if (count == 1) {
              this.stayAmountKidNo1 = stayAmountForKid;
            } else if (count == 2) {
              this.stayAmountKidNo2 = stayAmountForKid;
            } else if (count == 3) {
              this.stayAmountKidNo3 = stayAmountForKid;
            } else if(count ==4){
              this.stayAmountKidNo4 = stayAmountForKid;
            }
            count++;
          }
        });
      }
    } else if (this.StayPartially) {
      this.stayAmountMyself = 0;
      this.stayAmountKidNo1 = 0;
      this.stayAmountKidNo2 = 0;
      this.stayAmountKidNo3 = 0;
      this.stayAmountKidNo4 = 0;
    }
    this.noStayAmountMyself = 0;
    this.noStayAmountKidNo1 = 0;
    this.noStayAmountKidNo2 = 0;
    this.noStayAmountKidNo3 = 0;
    this.noStayAmountKidNo4 = 0;
    var count = 1;
    if (this.conference.myGRCRelatedPersons != null && this.conference.myGRCRelatedPersons.length > 0) {
      this.conference.myGRCRelatedPersons.sort(x=>x.Id).forEach(person => {
        var age = this.calculateAge(person.DOBRelated);
        var childName = person.FirstNameRelated;;
        if (age < 18) { //remove spouse
          if (count == 1) {
            this.kidNo1Name = childName;
          } else if (count == 2) {
            this.kidNo2Name = childName;
          } else if (count == 3) {
            this.kidNo3Name = childName;
          }else if (count == 4) {
            this.kidNo4Name = childName;
          }
          count++;
        }else{
        }
      });
    }
  }

  getStayAllDaysAmountMyself() {
    var stayAllDaysAmount: number = 0;
    var today = new Date();
    var indexDate: Date = null;
    indexDate = this.conference.ApplicationFeePaidDate != null ? this.conference.ApplicationFeePaidDate : today;
///
    var firstEarlyBirdDate = new Date("2023-03-01");
    if (Date.parse(indexDate.toString()) < firstEarlyBirdDate.getTime()) {
      if (this.conference.Couple) {
        stayAllDaysAmount = 39000;
      } else {
        stayAllDaysAmount = 40000;
      }
      //console.log('I am early bird')
    } else {
      if (this.conference.Couple) {
        stayAllDaysAmount = 41000;
      } else {
        stayAllDaysAmount = 42000;
      }
     // console.log('I am not early bird')
    }
    return stayAllDaysAmount;
  }

  getStayAllDaysAmountForKid(age:number, childCare:boolean, childProgram:boolean){
    var stayAllDaysAmount:number=0;
    var today = new Date();
    var indexDate:Date = null;
    indexDate = this.conference.ApplicationFeePaidDate!=null?this.conference.ApplicationFeePaidDate:today;
    var firstEarlyBirdDate = new Date("2023-03-01");
    if (Date.parse(indexDate.toString()) < firstEarlyBirdDate.getTime()) {
      if((age>=0 && age<=2) && !childCare){
        stayAllDaysAmount=0;
      }else if((age>=0 && age<=2) && childCare){
        stayAllDaysAmount=3000;
      }else if((age>=3 && age<=6) && childProgram){
        stayAllDaysAmount=19500;
      }else if((age>=3 && age<=6) && !childProgram){
        stayAllDaysAmount=16500;
      }else if(age>=7 && age<=12){
        stayAllDaysAmount=27300;
      }
    } else {
      if((age>=0 && age<=2) && !childCare){
        stayAllDaysAmount=0;
      }else if((age>=0 && age<=2) && childCare){
        stayAllDaysAmount=3000;
      }else if((age>=3 && age<=6) && childProgram){
        stayAllDaysAmount=19500;
      }else if((age>=3 && age<=6) && !childProgram){
        stayAllDaysAmount=16500;
      }else if(age>=7 && age<=12){
        stayAllDaysAmount=27300;
      }}
    return stayAllDaysAmount;
  }

  updateStayAllPartially(){
    if(this.conference.May3=='StayMay3' &&
    this.conference.May4=='StayMay4' &&
    this.conference.May5=='StayMay5' &&
    this.conference.May6=='NoStayMay6'){
      this.StayAllDays=true;
      this.StayPartially=false;
    }else{
      this.StayAllDays=false;
      this.StayPartially=true;
    }
  }

  async processSetPartialAttending(){
    this.updateStayCount();
    this.updateAttendPartialParticipationFee();
    this.updateAttendPartialParticipationFeeForKid();
    this.updateTotalAmount();
  }

  updateStayCount(){
    this.stayCount=0;
    this.dayTrip=0;
    // As to Dec27, the calculation is different and added at the end.
    if(this.conference.May3=='StayMay3'){
      this.stayCount++;
    }else if(this.conference.May3=='NoStayMay3'){
      this.dayTrip++;
    }
    if(this.conference.May4=='StayMay4'){
      this.stayCount++;
    }else if(this.conference.May4=='NoStayMay4'){
      this.dayTrip++;
    }
    if(this.conference.May5=='StayMay5'){
      this.stayCount++;
    }else if(this.conference.May5=='NoStayMay5'){
      this.dayTrip++;
    }
    if(this.conference.May6=='StayMay6'){
      this.stayCount++;
    }else if(this.conference.May6=='NoStayMay6'){
      this.dayTrip++;
    }
  }

  updateAttendPartialParticipationFee() {
    var today = new Date();
    var indexDate: Date = null;
    indexDate = this.conference.ApplicationFeePaidDate != null ? this.conference.ApplicationFeePaidDate : today;
    var firstEarlyBirdDate = new Date("2023-03-01");
    if (Date.parse(indexDate.toString()) < firstEarlyBirdDate.getTime()) {
      if (this.conference.Couple) {
        switch (this.stayCount) {
          case 0:
            this.stayAmountMyself = 0;
            break;
          case 1:
            this.stayAmountMyself = 17000;
            break;
          case 2:
            this.stayAmountMyself = 28000;
            break;
          case 3:
            this.stayAmountMyself = this.getStayAllDaysAmountMyself();
            break;
        }
        this.noStayAmountMyself = 7510 * this.dayTrip;
      } else {
        switch (this.stayCount) {
          case 0:
            this.stayAmountMyself = 0;
            break;
          case 1:
            this.stayAmountMyself = 18000;
            break;
          case 2:
            this.stayAmountMyself = 29000;
            break;
          case 3:
            this.stayAmountMyself = this.getStayAllDaysAmountMyself();
            break;
        }
        this.noStayAmountMyself = 7510 * this.dayTrip;
      }
    } else {
      if (this.conference.Couple) {
        switch (this.stayCount) {
          case 0:
            this.stayAmountMyself = 0;
            break;
          case 1:
            this.stayAmountMyself = 19000;
            break;
          case 2:
            this.stayAmountMyself = 30000;
            break;
          case 3:
            this.stayAmountMyself = this.getStayAllDaysAmountMyself();
            break;
        }
        this.noStayAmountMyself = 7510 * this.dayTrip;
      } else {
        switch (this.stayCount) {
          case 0:
            this.stayAmountMyself = 0;
            break;
          case 1:
            this.stayAmountMyself = 20000;
            break;
          case 2:
            this.stayAmountMyself = 31000;
            break;
          case 3:
            this.stayAmountMyself = this.getStayAllDaysAmountMyself();
            break;
        }
        this.noStayAmountMyself = 7510 * this.dayTrip;
      }
    }

  }

  updateAttendPartialParticipationFeeForKid(){
    this.myChildCount=0;
    var stayAmountForKid=0;
    var noStayAmountForKid=0;
    if (this.conference.myGRCRelatedPersons != null && this.conference.myGRCRelatedPersons.length > 0) {
          this.conference.myGRCRelatedPersons.sort(x=>x.Id).forEach(person => {
      var age = this.calculateAge(person.DOBRelated);
      var childCare = person.ChildCare;
      var childProgram = person.ChildProgram;
      if(age<18){ // remove adult
        switch(this.stayCount){
          case 1:
            if((age>=0 && age<=2) && !childCare){
              stayAmountForKid=0;
            }else if((age>=0 && age<=2) && childCare){
              stayAmountForKid=1000;
            }else if((age>=3 && age<=6) && childProgram){
              stayAmountForKid=8500;
            }else if((age>=3 && age<=6) && !childProgram){
              stayAmountForKid=7500;
            }else if(age>=7 && age<=12){
              stayAmountForKid=11900;
            }
            break;
          case 2:
            if((age>=0 && age<=2) && !childCare){
              stayAmountForKid=0;
            }else if((age>=0 && age<=2) && childCare){
              stayAmountForKid=2000;
            }else if((age>=3 && age<=6) && childProgram){
              stayAmountForKid=14000;
            }else if((age>=3 && age<=6) && !childProgram){
              stayAmountForKid=12000;
            }else if(age>=7 && age<=12){
              stayAmountForKid=19600;
            }
            break;
          case 3:
            stayAmountForKid = this.getStayAllDaysAmountForKid(age, childCare, childProgram);
            break;
        }
        // if((age==0 || age==1) && !childCare){
        //   noStayAmountForKid=0;
        // }else if((age==0 || age==1) && childCare){
        //   noStayAmountForKid=20*this.dayTrip;
        // }else if(age>=2 && age<=5){
        //   noStayAmountForKid=20*this.dayTrip;
        // }else if(age>=6){
        //   noStayAmountForKid=30*this.dayTrip;
        // };

        if(age<=2 && !childCare){
          noStayAmountForKid=0;
        }else if(age<=6 && !childProgram){
          noStayAmountForKid=0;
        }else{
          noStayAmountForKid=5510*this.dayTrip;
        };
        this.myChildCount++;
        if(this.myChildCount==1){
          this.stayAmountKidNo1 = stayAmountForKid;
          this.noStayAmountKidNo1=noStayAmountForKid;
          //console.log("Watch: "+this.noStayAmountKidNo1)
        }else if(this.myChildCount==2){
          this.stayAmountKidNo2 = stayAmountForKid;
          this.noStayAmountKidNo2=noStayAmountForKid;
          //console.log("Watch: "+this.noStayAmountKidNo2)
        }else if(this.myChildCount==3){
          this.stayAmountKidNo3 = stayAmountForKid;
          this.noStayAmountKidNo3=noStayAmountForKid;
         // console.log("Watch: "+this.noStayAmountKidNo3)
        }else if(this.myChildCount==4){
          this.stayAmountKidNo4 = stayAmountForKid;
          this.noStayAmountKidNo4=noStayAmountForKid;
         // console.log("Watch: "+this.noStayAmountKidNo4)
        }
      }
      });
    }
  }

  updateTotalAmount(){
    this.numberOfKidsUnder18=0;
    if(this.StayAllDays){
      this.conference.ConferenceFee=
      this.stayAmountMyself+
      this.stayAmountKidNo1+
      this.stayAmountKidNo2+
      this.stayAmountKidNo3;
      this.subTotalMyself = this.stayAmountMyself;
      this.subTotalKidNo1 = this.stayAmountKidNo1;
      this.subTotalKidNo2 = this.stayAmountKidNo2;
      this.subTotalKidNo3 = this.stayAmountKidNo3;
    }else if(this.StayPartially){
      this.conference.ConferenceFee=
     this.stayAmountMyself + this.noStayAmountMyself+
     this.stayAmountKidNo1 + this.noStayAmountKidNo1+
     this.stayAmountKidNo2 + this.noStayAmountKidNo2+
     this.stayAmountKidNo3 + this.noStayAmountKidNo3;
     this.subTotalMyself = this.stayAmountMyself + this.noStayAmountMyself;
     this.subTotalKidNo1 = this.stayAmountKidNo1 + this.noStayAmountKidNo1;
     this.subTotalKidNo2 = this.stayAmountKidNo2 + this.noStayAmountKidNo2;
     this.subTotalKidNo3 = this.stayAmountKidNo3 + this.noStayAmountKidNo3;
    }
    if (this.conference.myGRCRelatedPersons != null && this.conference.myGRCRelatedPersons.length > 0) {
      this.conference.myGRCRelatedPersons.sort(x=>x.Id).forEach(person => {
        var childAge=this.calculateAge(person.DOBRelated);
        if (childAge < 18) {
          this.numberOfKidsUnder18 ++
          if(childAge<2){
            if(this.numberOfKidsUnder18==1){
              this.kidNo1WithNoCharge=true;
            }else if(this.numberOfKidsUnder18==2){
              this.kidNo2WithNoCharge=true;
            }else if(this.numberOfKidsUnder18==3){
              this.kidNo3WithNoCharge=true;
            }
          }
        }
      })
    }
    //Total by adding all subTotals
    this.conference.ConferenceFee = this.subTotalMyself+this.subTotalKidNo1+this.subTotalKidNo2+this.subTotalKidNo3;
    // console.log('subTotalMyself: '+this.subTotalMyself)
    // console.log('subTotalKidNo1: '+this.subTotalKidNo1)
    // console.log('subTotalKidNo2: '+this.subTotalKidNo2)
    // console.log('subTotalKidNo3: '+this.subTotalKidNo3)
    // console.log('sum of all subs: '+(this.subTotalMyself+this.subTotalKidNo1+this.subTotalKidNo2+this.subTotalKidNo3))
    // console.log('conference fee before discount: '+this.conference.ConferenceFee)
    if(this.conference.ApplicationFee!=null && this.conference.ApplicationFeePaidDate!=null){
      this.conference.ConferenceFee = this.conference.ConferenceFee-this.conference.ApplicationFee;
    }
    if(this.conference.ConferenceDiscountAmount!=null){
      this.conference.ConferenceFee = this.conference.ConferenceFee-this.conference.ConferenceDiscountAmount;
    }

   // console.log("Total Amount: "+this.conference.ConferenceFee);
  }

  onSubmit() {
  }

  onClickGoForward(){
    this.getFormValue();
    if(this.router.url.includes('/registration/GRC/')){
      sessionStorage.removeItem("myWorkingOnConference");
      sessionStorage.setItem(
        "myWorkingOnConference",
        JSON.stringify({ myEditingConference: this.conference })
      );
      this.router.navigate(["/registration/GRC/scholarshipGRC/" +this.getMyLanguage()]);
    }else{
      sessionStorage.removeItem("myWorkingOnConferenceAdminGRC");
      sessionStorage.setItem(
        "myWorkingOnConferenceAdminGRC",
        JSON.stringify({ myEditingConference: this.conference })
      );
      this.dialog.closeAll();
      this.openForwardEmbed();
    }
  }
  openForwardEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ myPage: 'scholarshipGRC' };
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedGRCComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        //console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  onClickGoBack(){
    if(this.router.url.includes('/registration/GRC/')){
      this.router.navigate(["/registration/GRC/roomInfoGRC/" +this.getMyLanguage()]);
    }else{
      this.dialog.closeAll();
      this.openBackEmbed();
    }
  }
  openBackEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ myPage: 'roomInfoGRC' };
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedGRCComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
       // console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  cancelProcess(){
    this.getFormValue();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='210px'; //Desktop
    dialogConfig.maxWidth='370px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ conference: this.conference };
    const dialogRef = this.dialog.open(CancelOrSaveConfirmationComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        //console.log("Confirmed Action: " + confirmedAction);
      }
      //Retreive updated registration Info.
      //this.ngOnInit();
      let navigationExtras: NavigationExtras = {
        queryParams:this.conference
      };
      if(result!='cancel'){
        if(this.router.url.includes('/registration/GRC/')){
          this.router.navigate(["/registration/GRC/myAccountGRC/" +this.getMyLanguage()], navigationExtras);
        }else{
          this.dialog.closeAll();
          this.openMyAccountEdit();
        }
        }
    })
  }

  openMyAccountEdit(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ userId: this.conference.UserId };
    const dialogRef = this.dialog.open(MyAccountEditComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
       // console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }
  }
