import { Component, OnInit, Inject, ANALYZE_FOR_ENTRY_COMPONENTS } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { PaymentMasterCore} from '../../../models/payment/paymentMasterCore';
import { PaymentService} from "../../../services/payment/payment.service";
import { UserService} from "../../../services/user/user.service";
import { User } from "../../../models/user";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { ColumnGroup } from 'src/app/models/memberListGridTemplate';
import { MemberList } from 'src/app/models/memberList';
import { MemberListGridTemplate } from 'src/app/models/memberListGridTemplate';
import { DeleteConfirmationComponent } from '../delete-confirmation/delete-confirmation.component';
import { MemberListTemplate } from 'src/app/models/MemberListTemplate';
import { DeleteTemplateConfirmationComponent } from '../delete-template-confirmation/delete-template-confirmation.component';
import { MembersListCustomComponent } from '../../admin/members-list-custom/members-list-custom.component';


export interface DialogData {
  UserName: string
}

@Component({
  selector: 'app-upsert-template',
  templateUrl: './upsert-template.component.html',
  styleUrls: ['./upsert-template.component.css']
})
export class UpsertTemplateComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  loaded:boolean=true;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  myMessage:string;
  beSupportingMember:boolean;
  templateNames:String[]=[];
  myUserName: string;
  selectedEditingTemplateName:string;
  selectedDeletingTemplateName:string;
  editing: boolean = false;
  deleting: boolean = false;
  disableCreateButton:boolean=true;
  disableUpdateButton:boolean=true;
  disableDeleteButton:boolean=true;

  editingColumnForm = new FormControl();
  selectedEditingColumns: any[] ;

  columnGroup: ColumnGroup = {
    id: null,
    value: null,
  };
  columnGroups: ColumnGroup[] = [];

  columnSelections:ColumnGroup[] = [
   {id:0, value:"UserName"},
   {id:1, value:"LastName"},
   {id:2, value:"FirstName"},
   {id:3, value:"Myoji"},
   {id:4, value:"Shimei"},
  //  {id:5, value:"Prefix"},
   {id:6, value:"Email"},
   {id:7, value:"Gender"},
   {id:8, value:"DOB"},
   {id:9, value:"TelNo"},
   {id:10, value:"LivingCountry"},
   {id:11, value:"Street"},
   {id:12, value:"Street2"},
   {id:13, value:"City"},
   {id:14, value:"State"},
   {id:15, value:"Zip"},
   {id:16, value:"Country"},
   {id:17, value:"Yubin_Bango"},
   {id:18, value:"To_Do_Fu_Ken"},
   {id:19, value:"Shi_Gun_Ku"},
   {id:20, value:"Cho_Son"},
   {id:21, value:"Apartment_Etc"},
   {id:22, value:"To_Do_Fu_Ken_JP"},
   {id:23, value:"Shi_Gun_Ku_JP"},
   {id:24, value:"Cho_Son_JP"},
   {id:25, value:"Apartment_Etc_JP"},
   {id:26, value:"Occupation"},
   {id:27, value:"Married"},
   {id:28, value:"ZoomAccount"},
   {id:29, value:"Christian"},
   {id:30, value:"NewsLetterLanguage"},
   {id:31, value:"MemberType"},
   {id:32, value:"MembershipType"},
   {id:33, value:"CreatedDate"},
   {id:34, value:"Language"},
   {id:35, value:"Attend"},
   {id:36, value:"NewsLetters"},
   {id:37, value:"ReceivingMethod"},
   {id:38, value:"ActiveStatus"},
   {id:39, value:"SupportingMembershipRecurring"},
   {id:40, value:"SupportingMembershipCreatedDate"},
  ]

  memberListTemplate:MemberListTemplate={
    MemberListGridTemplateId:0,
    TemplateName:null,
    UserName:null,
    Registereddate:null,
    LastName:null,
    FirstName:null,
    Myoji:null,
    Shimei:null,
    Prefix:null,
    Email:null,
    Gender:null,
    DOB:null,
    TelNo:null,
    LivingCountry:null,
    Street:null,
    Street2:null,
    City:null,
    State:null,
    Zip:null,
    Country:null,
    Yubin_Bango:null,
    To_Do_Fu_Ken:null,
    Shi_Gun_Ku:null,
    Cho_Son:null,
    Apartment_Etc:null,
    To_Do_Fu_Ken_JP:null,
    Shi_Gun_Ku_JP:null,
    Cho_Son_JP:null,
    Apartment_Etc_JP:null,
    Occupation:null,
    Married:null,
    ZoomAccount:null,
    Christian:null,
    NewsLetterLanguage:null,
    MemberType:null,
    MembershipType:null,
    CreatedDate:null,
    //EC20 Reunion
    Language:null,
    Attend:null,

    NewsLetters:null,
    ReceivingMethod:null,
    ActiveStatus:null,
    SupportingMembershipRecurring:null,
    SupportingMembershipCreatedDate:null
  }

  user: User = {
    PasswordHash: null,
    Id: null,
    UserName: null,
    Email: null,
    FirstName: null,
    LastName: null,
    Password: null,
    Prefix: null,
    Shimei: null,
    Myoji: null,
    Street: null,
    Street2: null,
    City: null,
    State: null,
    Zip: null,
    Country: null,
    Yubin_Bango: null,
    To_Do_Fu_Ken: null,
    Shi_Gun_Ku: null,
    Cho_Son: null,
    Apartment_Etc: null,
    To_Do_Fu_Ken_JP: null,
    Shi_Gun_Ku_JP: null,
    Cho_Son_JP: null,
    Apartment_Etc_JP: null,
    Gender: null,
    DOB: null,
    TelNo: null,
    TimezoneName: null,
    UTCdiff: null,
    Token: null,
    Occupation: null,
    MemberStatus: null,
    Married: null,
    Christian: null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount: null,
    RegisteredDate: null,
    LastAccessedDate: null,
    //From siteground
    LoginId: null,
    MiddleNameEN: null,
    MiddleNameJP: null,
    PrefixJP: null,
    OccupationDetail: null,
    Organization: null,
    LivingCountry: null,
    PhoneEN: null,
    PhoneMobileEN: null,
    PhoneJP: null,
    PhoneMobileJP: null,
    ChurchNameEN: null,
    ChurchNameJP: null,
    MemberType: null,
    MemberDetail: null,
    MemberMemo: null,
    Shokaisha: null,
    Reason: null,
    NewsLetters: null,
    StaffReports: null,
    OtherLetters: null,
    NewsLetterLanguage: null,
    BasicRoles: null,
    //Added new
    MigrateFromSiteGround: null,
    AdminAccess: null,
    JCFNAccess: null,
    SupportingMember: null,
    LastAddressUpdatedDate: null,
    AcceptMembershipTerm: null,
    MembershipType: null
  };

  memberListGridTemplate: MemberListGridTemplate = {
    MemberListGridTemplateId:null,
    SelectedColumns:this.columnGroups,
    TemplateName:null,
    UserName:null,
  };

  constructor(
    public router: Router,
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private userService:UserService,
    public dialogRef: MatDialogRef<UpsertTemplateComponent>,
    private dialog: MatDialog,
    private membersListCustomComponent :MembersListCustomComponent,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ){}

  ngOnInit() {
    this.myUserName = sessionStorage.getItem("loginUserName");
    this.firstFormGroup = this._formBuilder.group({
      myCreateTemplateName: ['', Validators.required],
      mySelectedCreateColumns: ['', Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      myEditTemplateName: ['', Validators.required],
      mySelectedEditColumns: ['', Validators.required],
    });
    this.thirdFormGroup = this._formBuilder.group({
      myDeleteTemplateName: ['', Validators.required],
    });
    if (this.data.UserName != null) {
     // this.loaded=false;


    }
    this.getTemplateNamesByUser();
  }

  getTemplateNamesByUser() {
    this.templateNames=[];
    if (this.myUserName != null) {
      this.loaded = false;
      this.user.UserName = this.myUserName;
      this.userService.getTemplateNamesByUser(this.user)
        .subscribe(results => {
           this.templateNames.push.apply(this.templateNames,results);
           this.loaded = true;
        },
          error => {
          }
        );
    }
   // this.templateNames.unshift("Default");
  }

  getMyLanguage(): string {
    return this.router.url.includes('EN') == true ? 'EN' : 'JP';
  }

  insertNewTemplate() {
    if (this.firstFormGroup.status == 'VALID') {
      this.loaded = false;
      this.memberListGridTemplate.MemberListGridTemplateId=0;
      this.memberListGridTemplate.UserName = this.data.UserName;
      this.userService.insertNewTemplate(this.memberListGridTemplate).subscribe(
          result => {
            this.myMessage ="Template saved successfully.";
            this.openSnackBar('success', 'close');
            this.loaded = true;
            this.getTemplateNamesByUser();
            this.membersListCustomComponent.ngOnInit();
          },
          error => {
            this.myMessage ="Failed to save.";
            this.openSnackBar('error', 'close');
            this.loaded = true;
          }
        )
    }else{
      console.log('data is bad');
    }
  }

  onSelectCreatingColumn($event:ColumnGroup[]) {
    if($event!=null){
      this.disableCreateButton=false;
    }else{
      this.disableCreateButton=true;
    }
   // console.log("selecte object:" + $event.id + $event.value)
    $event.forEach(result => {
      console.log(result.id+' '+result.value);
    });
  }

  onSelectEditingColumn($event:ColumnGroup[]) {
    // console.log("selecte object:" + $event.id + $event.value)
     $event.forEach(result => {
       console.log(result.id+' '+result.value);
     });
   }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClickCreateCancel(){
    event.preventDefault();
    this.memberListGridTemplate.SelectedColumns=null;
    this.memberListGridTemplate.TemplateName=null;
    this.disableCreateButton=true;
  }

  onClickEditCancel(){
    event.preventDefault();
    this.memberListGridTemplate.SelectedColumns=null;
    this.memberListGridTemplate.TemplateName=null;
    this.disableUpdateButton=true;
  }

  onClickDeleteCancel(){
    event.preventDefault();
    this.memberListGridTemplate.SelectedColumns=null;
    this.memberListGridTemplate.TemplateName=null;
    this.disableDeleteButton=true;
  }

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 5000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 10000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }

  onClickClose() {
    sessionStorage.removeItem("searchingRegistrantId");
    console.log('Im closing');
    this.dialogRef.close();
  }


  onChangeSelectEditingTemplateName(selectedItem: any) {
    this.loaded = false;
    if (selectedItem != null) {
      this.selectedEditingTemplateName = selectedItem;
      this.disableUpdateButton=false;
      this.userService.getMemberListTemplateByTemplateName(this.selectedEditingTemplateName)
      .subscribe(results => {
        this.memberListTemplate = results;

        this.selectedEditingColumns=[];
        if (this.memberListTemplate.LastName == true) {
          this.selectedEditingColumns.push(this.columnSelections[1]);
        }
        if (this.memberListTemplate.FirstName == true) {
          this.selectedEditingColumns.push(this.columnSelections[2]);
        }
        if (this.memberListTemplate.Myoji == true) {
          this.selectedEditingColumns.push(this.columnSelections[3]);
        }
        if (this.memberListTemplate.Shimei == true) {
          this.selectedEditingColumns.push(this.columnSelections[4]);
        }
        if (this.memberListTemplate.Prefix == true) {
          this.selectedEditingColumns.push(this.columnSelections[5]);
        }
        if (this.memberListTemplate.Email == true) {
          this.selectedEditingColumns.push(this.columnSelections[6]);
        }
        if (this.memberListTemplate.Gender == true) {
          this.selectedEditingColumns.push(this.columnSelections[7]);
        }
        if (this.memberListTemplate.DOB == true) {
          this.selectedEditingColumns.push(this.columnSelections[8]);
        }
        if (this.memberListTemplate.TelNo == true) {
          this.selectedEditingColumns.push(this.columnSelections[9]);
        }
        if (this.memberListTemplate.LivingCountry == true) {
          this.selectedEditingColumns.push(this.columnSelections[10]);
        }
        if (this.memberListTemplate.Street == true) {
          this.selectedEditingColumns.push(this.columnSelections[11]);
        }
        if (this.memberListTemplate.Street2 == true) {
          this.selectedEditingColumns.push(this.columnSelections[12]);
        }
        if (this.memberListTemplate.City == true) {
          this.selectedEditingColumns.push(this.columnSelections[13]);
        }
        if (this.memberListTemplate.State == true) {
          this.selectedEditingColumns.push(this.columnSelections[14]);
        }
        if (this.memberListTemplate.Zip == true) {
          this.selectedEditingColumns.push(this.columnSelections[15]);
        }
        if (this.memberListTemplate.Country == true) {
          this.selectedEditingColumns.push(this.columnSelections[16]);
        }
        if (this.memberListTemplate.Yubin_Bango == true) {
          this.selectedEditingColumns.push(this.columnSelections[17]);
        }
        if (this.memberListTemplate.To_Do_Fu_Ken == true) {
          this.selectedEditingColumns.push(this.columnSelections[18]);
        }
        if (this.memberListTemplate.Shi_Gun_Ku == true) {
          this.selectedEditingColumns.push(this.columnSelections[19]);
        }
        if (this.memberListTemplate.Cho_Son == true) {
          this.selectedEditingColumns.push(this.columnSelections[20]);
        }
        if (this.memberListTemplate.Apartment_Etc == true) {
          this.selectedEditingColumns.push(this.columnSelections[21]);
        }
        if (this.memberListTemplate.To_Do_Fu_Ken_JP == true) {
          this.selectedEditingColumns.push(this.columnSelections[22]);
        }
        if (this.memberListTemplate.Shi_Gun_Ku_JP == true) {
          this.selectedEditingColumns.push(this.columnSelections[23]);
        }
        if (this.memberListTemplate.Cho_Son_JP == true) {
          this.selectedEditingColumns.push(this.columnSelections[24]);
        }
        if (this.memberListTemplate.Apartment_Etc == true) {
          this.selectedEditingColumns.push(this.columnSelections[25]);
        }
        if (this.memberListTemplate.Occupation == true) {
          this.selectedEditingColumns.push(this.columnSelections[26]);
        }
        if (this.memberListTemplate.Married == true) {
          this.selectedEditingColumns.push(this.columnSelections[27]);
        }
        if (this.memberListTemplate.ZoomAccount == true) {
          this.selectedEditingColumns.push(this.columnSelections[28]);
        }
        if (this.memberListTemplate.Christian == true) {
          this.selectedEditingColumns.push(this.columnSelections[29]);
        }
        if (this.memberListTemplate.NewsLetterLanguage == true) {
          this.selectedEditingColumns.push(this.columnSelections[30]);
        }
        if (this.memberListTemplate.MemberType == true) {
          this.selectedEditingColumns.push(this.columnSelections[31]);
        }
        if (this.memberListTemplate.MembershipType == true) {
          this.selectedEditingColumns.push(this.columnSelections[32]);
        }
        if (this.memberListTemplate.CreatedDate == true) {
          this.selectedEditingColumns.push(this.columnSelections[33]);
        }
        if (this.memberListTemplate.Language == true) {
          this.selectedEditingColumns.push(this.columnSelections[34]);
        }
        if (this.memberListTemplate.Attend == true) {
          this.selectedEditingColumns.push(this.columnSelections[35]);
        }
        if (this.memberListTemplate.NewsLetters == true) {
          this.selectedEditingColumns.push(this.columnSelections[36]);
        }
        if (this.memberListTemplate.ReceivingMethod == true) {
          this.selectedEditingColumns.push(this.columnSelections[37]);
        }
        if (this.memberListTemplate.ActiveStatus == true) {
          this.selectedEditingColumns.push(this.columnSelections[38]);
        }
        if (this.memberListTemplate.SupportingMembershipRecurring == true) {
          this.selectedEditingColumns.push(this.columnSelections[39]);
        }
        if (this.memberListTemplate.SupportingMembershipCreatedDate == true) {
          this.selectedEditingColumns.push(this.columnSelections[40]);
        }
        this.editingColumnForm.setValue(this.selectedEditingColumns);



    //     if(this.memberListTemplate.LastName==true){
    //       this.memberListGridTemplate.SelectedColumns

    //     }
    //     // this.selectedEditingColumns=[];
    //     // this.selectedEditingColumns.push({ id: 1, value: "LastName" });

    //     this.selectedEditingColumns= [
    //       { id: 1, value: "LastName" },
    //       { id: 2, value: "FirstName" },
    //       { id: 3, value: "Myoji" }
    //   ]



    // //    this.selectedEditingColumns= [
    // //     this.columnSelections[0],
    // //     this.columnSelections[1],
    // //     this.columnSelections[2],
    // //     this.columnSelections[3]
    // // ]

    //  console.log(this.columnSelections[0]);
    // // this.columnSelections.forEach(result=>
    // //   console.log(result)
    // // )




    // this.editingColumnForm.setValue(this.selectedEditingColumns)

      },
        error => {
        }
      );
    }else{
      this.disableUpdateButton=true;
    }
    this.loaded=true;
  }

  onChangeSelectDeletingTemplateName(selectedItem: any) {
    this.loaded = false;
    if (selectedItem != null) {
      this.selectedDeletingTemplateName = selectedItem;
      this.disableDeleteButton=false;
    }else{
      this.disableDeleteButton=true;
    }
    this.loaded=true;
  }

  onConfirmUpdate() {
    this.editing = true;
    this.memberListGridTemplate.UserName = this.data.UserName;
    this.userService.updateTemplate(this.memberListGridTemplate).subscribe(
        result => {
          this.myMessage ="Template updated successfully.";
          this.openSnackBar('success', 'close');
          this.editing = false;
        },
        error => {
          this.myMessage ="Failed to update.";
          this.openSnackBar('error', 'close');
          this.editing = false;
        }
      )
  }

  onConfirmDelete() {
    event.preventDefault(); // This is neccessary to prevent cencel button submit form
    const dialogRef = this.dialog.open(DeleteTemplateConfirmationComponent, {
      width: '250px',
      data: {
        TemplateName: this.selectedDeletingTemplateName
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      console.log("Confirmed Action: " + confirmedAction);
      if (confirmedAction === this.selectedDeletingTemplateName) {
        this.DeleteTemplate();
      } else {
        this.myMessage = this.getMyLanguage() == "EN" ? "Delete data cancelled." : "データ削除がキャンセルされました。";
        this.openSnackBar('error', 'close');
        this.loaded = true;
      }
    });
  }

  DeleteTemplate() {
    this.deleting = true;
    this.memberListTemplate.TemplateName = this.selectedDeletingTemplateName;
    this.memberListTemplate.UserName = this.myUserName;
    this.userService
      .deleteMemberListTemplate(this.memberListTemplate)
      .subscribe(
        result => {
          this.myMessage = this.getMyLanguage() == "EN" ? "Deleted template successfully." : "テンプレートが削除されました。";
          this.openSnackBar('success', 'close');
          this.deleting = false;
          this.getTemplateNamesByUser();
        },
        error => {
          this.myMessage = this.getMyLanguage() == "EN" ? "Delete template failed." : "テンプレート削除に失敗しました。";
          this.openSnackBar('error', 'close');
          this.deleting = false;
        }
      )
    this.deleting = false;

  }

}
