

    <nav class="navbar navbar-expand-lg navbar-light  mask-custom shadow-0">
      <div class="container">
        <a class="navbar-brand" href="#!">JCFN<span style="color: rgb(119, 182, 111);font-size: 18px;">Membership</span></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
          <i class="fas fa-bars"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto">
            <li *ngIf="this.authService.isLoggedIn() && this.authService.checkLoginUserAccessJCFN()" class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#"  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {{this.router.url.includes('EN')==true?'JCFN Staff Only': 'JCFN スタッフオンリー'}}
                </a>
                <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink">
                  <!-- <li><a class="dropdown-item" href="#"
                    [routerLink]="this.router.url.includes('EN')==true?'/admin/members-list/EN':'/admin/members-list/JP'">Search
                    members</a></li> -->
                  <li><a class="dropdown-item" href="#"
                    [routerLink]="this.router.url.includes('EN')==true?'/admin/members-list-custom/EN':'/admin/members-list-custom/JP'">Search members</a></li>
                  <li><a class="dropdown-item" href="#"
                    [routerLink]="this.router.url.includes('EN')==true?'/admin/payment-list/EN':'/admin/payment-list/JP'">Search payment</a></li>
                  <li><a class="dropdown-item" href="#"
                    [routerLink]="this.router.url.includes('EN')==true?'/dashboard/members-Japan/EN':'/dashboard/members-Japan/JP'">members Japan</a></li>
                  <li><a class="dropdown-item " href="#"
                    [routerLink]="this.router.url.includes('EN')==true?'/dashboard/members-USA/EN':'/dashboard/members-USA/JP'">members USA</a></li>
                </ul>
              </li>
          </ul>
          <ul class="navbar-nav me-auto">
            <li *ngIf="this.authService.isLoggedIn() && this.ecRegistrationService.checkLoginUserAccessEC()" class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#"  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{this.router.url.includes('EN')==true?'EC Staff Only': 'EC スタッフオンリー'}}
              </a>
              <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink">
                <li><a class="dropdown-item" href="#"
                  [routerLink]="this.router.url.includes('EN')==true?'/admin/EC/ECparticipant/EN':'/admin/EC/ECparticipant/JP'">Search
                  participants</a></li>
              </ul>
            </li>




           <li *ngIf="this.authService.isLoggedIn() && this.grcRegistrationService.checkLoginUserAccessGRC()">
                <a class="nav-link dropdown-toggle" href="#"  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {{this.router.url.includes('EN')==true?'GRC Staff Only': 'GRC スタッフオンリー'}}
                </a>
                <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink">
                  <li><a class="dropdown-item" href="#"
                    [routerLink]="this.router.url.includes('EN')==true?'/admin/GRC/GRC-participant/EN':'/admin/GRC/GRC-participant/JP'">Search
                    participants</a></li>
                </ul>
              </li>
          </ul>


<div class="buttonGroups">

</div>

              <div class="navbar-nav ml-auto">
            <span class="nav-item">
              <a href="#"  (click)="collapseNavbar()"
              routerLink="{{
                      this.router.url.split('/').length>=6 ?
                      (this.router.url.split('/')[1]+'/'+this.router.url.split('/')[2]+'/'+this.router.url.split('/')[3]+'/'+this.router.url.split('/')[4]):
                      (this.router.url.split('/').length>=5 ?
                      (this.router.url.split('/')[1]+'/'+this.router.url.split('/')[2]+'/'+this.router.url.split('/')[3]):
                      (this.router.url.split('/').length>=4 ?
                      (this.router.url.split('/')[1]+'/'+this.router.url.split('/')[2]):
                      (this.router.url.split('/').length==3 ?
                      (this.router.url.split('/')[1]):
                      (this.router.url.split('/').length==2 ?
                      (this.router.url.split('/')[1]==''?'home':this.router.url.split('/')[1]):(this.router.url.split('/')[1])))))}}/EN"
                class="btn btn-link buttonGroup">
                <img src="../../../assets/images/en1.png" alt="English" class="logoImageFlagEN">
              </a>
            </span>
            <span class="nav-item">
              <a href="#"  (click)="collapseNavbar()"
                routerLink="{{
                        this.router.url.split('/').length>=6 ?
                        (this.router.url.split('/')[1]+'/'+this.router.url.split('/')[2]+'/'+this.router.url.split('/')[3]+'/'+this.router.url.split('/')[4]):
                        (this.router.url.split('/').length>=5 ?
                        (this.router.url.split('/')[1]+'/'+this.router.url.split('/')[2]+'/'+this.router.url.split('/')[3]):
                        (this.router.url.split('/').length>=4 ?
                        (this.router.url.split('/')[1]+'/'+this.router.url.split('/')[2]):
                        (this.router.url.split('/').length==3 ?
                        (this.router.url.split('/')[1]):
                        (this.router.url.split('/').length==2 ?
                        (this.router.url.split('/')[1]==''?'home':this.router.url.split('/')[1]):(this.router.url.split('/')[1])))))}}/JP"
                class="btn btn-link buttonGroup">
                <img src="../../../assets/images/jp1.png" alt="日本語" class="logoImageFlagJP">
              </a>
            </span>
            </div>




          <ul class="navbar-nav d-flex flex-row">
            <!-- <li class="nav-item me-3 me-lg-0">
              <a class="nav-link" href="#!">
                <i class="fab fa-twitter"></i>
              </a>
            </li> -->
            <li class="nav-item me-3 me-lg-0">
              <a class="nav-link" href="https://www.facebook.com/JCFN1/">
                <i class="fab fa-facebook"></i>
              </a>
            </li>
            <li class="nav-item me-3 me-lg-0">
              <a class="nav-link" href="https://www.instagram.com/jcfn1990/">
                <i class="fab fa-instagram"></i>
              </a>
            </li>

            <li *ngIf="!this.authService.isLoggedIn()" class="nav-item active navMenues">
                <button type="button" class="btn btnLogin"  (click)="collapseNavbar()" (click)="onClickRegistration()">
                  {{this.router.url.includes('EN')==true?'Login':'ログイン'}}
                </button>
              </li>


            <li *ngIf="this.authService.isLoggedIn()" class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#"  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {{this.router.url.includes('EN')==true?'Hi ': 'こんにちは '}}
                  {{ this.authService.getLoginUserFirstName() }}&nbsp;&nbsp;&nbsp;&nbsp;
                  {{this.router.url.includes('EN')==true?'': 'さん'}}
                </a>
                <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink">
                  <li><a class="dropdown-item" href="#"  (click)="collapseNavbar()"
                    [routerLink]="this.router.url.includes('JP')==true?'/members/myProfile/JP':'/members/myProfile/EN'">
                    {{this.router.url.includes('EN')==true?'JCFN Membership My Account': 'JCFNメンバーシップ マイアカウント'}}
                  </a></li>
                    <li *ngIf="this.authService.isLoggedIn() && ecRegistrationService.checkLoginUserAccessEC">
                      <a class="dropdown-item" href="#"  (click)="collapseNavbar()"
                    [routerLink]="this.router.url.includes('JP')==true?'/conference/EC/myAccount/JP':'/conference/EC/myAccount/EN'">
                    {{this.router.url.includes('EN')==true?'EC24 My Account': 'EC24 マイアカウント'}}
                  </a></li>
                   <!-- <li><a class="dropdown-item" href="#"
                    [routerLink]="this.router.url.includes('JP')==true?'/registration/GRC/myAccountGRC/JP':'/registration/GRC/myAccountGRC/EN'">
                    {{this.router.url.includes('EN')==true?'My Account': 'GRC23マイアカウント'}}
                  </a></li> -->
                  <li><a class="dropdown-item"  (click)="collapseNavbar()" (click)="onClickLogOut()">
                    {{this.router.url.includes('EN')==true?'Log Out': 'ログアウト'}}
                  </a></li>
                </ul>
              </li>
          </ul>
        </div>
      </div>
    </nav>
