import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router} from "@angular/router";
import { UserConference } from 'src/app/models/conference/userConference';

export interface DialogData {
  user: UserConference
}

@Component({
  selector: 'app-basic-sub-embed',
  templateUrl: './basic-sub-embed.component.html',
  styleUrls: ['./basic-sub-embed.component.css']
})
export class BasicSubEmbedComponent implements OnInit {

  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<BasicSubEmbedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit(): void {
  }

  onClickClose() {
    this.dialogRef.close();
  }
}
