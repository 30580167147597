import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-token-check',
  templateUrl: './token-check.component.html',
  styleUrls: ['./token-check.component.css']
})
export class TokenCheckComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
