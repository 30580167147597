<div class="">
  <div class="d-flex justify-content-center">
    {{ this.router.url.includes('EN')==true?"Are you sure to cancel process?":"プロセスを中止しますか?"}}
  </div>
  <div mat-dialog-actions class="d-flex justify-content-center">
    <button mat-button (click)="onSaveWithoutSavingClick()">
      {{ this.router.url.includes('EN')==true?"Cancel without saving":"保存せずに中止する"}}</button>
    <button mat-button (click)="onSaveAndCancel()">
      {{ this.router.url.includes('EN')==true?"Save and Cancel":"保存してから中止"}}</button>
  </div>

  <div mat-dialog-actions class="d-flex justify-content-center">
    <button mat-button (click)="onClickCancel()">
      {{ this.router.url.includes('EN')==true?"Cancel":"キャンセル"}}</button>
  </div>
</div>
