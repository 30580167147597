import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ec-participant-custom',
  templateUrl: './ec-participant-custom.component.html',
  styleUrls: ['./ec-participant-custom.component.css']
})
export class EcParticipantCustomComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
