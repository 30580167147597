<div class="myBody">
    <div class="myHeaderBase d-flex justify-content-center">
        <div class="myHeaderCore">
            <div>
                <img src='../../../../../assets/images/thumbnail_eclogo.png'  class="ecLogo">
            </div>
        </div>
    </div>
    <div class="myMain">
        <div clas="myMainLeft">
            <div class="myMainHalfTop">
                <!-- Left Top -->
            </div>
            <div class="myMainHalfBottom">
                <!-- Left Bottom -->
            </div>
        </div>
        <div class="myMainCenter">
            <div class="myMainHalfTop d-flex justify-content-center">
                <!-- Main Top -->

                <mat-card class="mainCard b-radius">
                    <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
                    <div *ngIf="!loaded">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </div>
                    <!-- <form *ngIf="userFromServer | async; else loading"  -->
                        <form
                        [formGroup]="myFormGroup"
                        (ngSubmit)="onSubmit()"
                        >
                        <div class="float-end stepNumber">2/12</div>
                        <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Small Group Language':
                            'スモールグループ言語'}}
                            <i class="stepTitleSub">
                                {{this.router.url.includes('EN')==true?'Required': '必須'}}
                            </i>
                        </h2>

                        <div class="stepTitle2nd">
                            {{this.router.url.includes('EN')==true?'Please choose the language in which you feel comfortable to share your deep feelings and thoughts during your small group discussion.':
                            'スモールグループなどで心の深い分かち合いをするのに、自由に話せる言語をご選択ください。'}}
                        </div>
                        <div class="d-flex justify-content-center">
                            <mat-radio-group formControlName="mySmallGroupLanguage" class="radioBtnMain"
                                (change)="onChangeSmallGroupLanguageSelection($event)">
                                <mat-radio-button value="Japanese" class="radioBtnItem">
                                    {{this.router.url.includes('EN')==true?'Japanese': '日本語'}}</mat-radio-button>
                                <mat-radio-button value="English" class="radioBtnItem">
                                    {{this.router.url.includes('EN')==true?'English': '英語'}}</mat-radio-button>
                                <!-- <mat-radio-button value="Bilingual" class="radioBtnItem">
                                    {{this.router.url.includes('EN')==true?'I can comfortably communicate either English or Japanese': '日英どちらの言語でも問題なく会話ができる'}}</mat-radio-button> -->
                            </mat-radio-group>
                            <mat-error *ngIf="myFormGroup.get('mySmallGroupLanguage').hasError('required')  && myFormGroup.get('mySmallGroupLanguage').touched">
                                {{this.router.url.includes('EN')==true?'Program Type is required': '未入力'}}
                              </mat-error>
                        </div>
                        <div class=" position-absolute bottom-0 start-50 translate-middle-x">
                            <div class="d-flex justify-content-center">
                                <button type="button" class="btn btnProcess" (click)="onClickGoBack()">
                                    {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                                </button>
                                <button [disabled]="!myFormGroup.valid" type="button" class="btn btnProcess"
                                    (click)="onClickGoForward()">
                                    {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                                </button>
                            </div>
                            <div class="d-flex justify-content-center">
                                <button type="button" class="btn link" (click)="cancelProcess()">
                                    {{this.router.url.includes('EN')==true?'Cancel': 'キャンセル'}}
                                  </button>
                            </div>
                        </div>
                        <!-- <button type="submit" [disabled]="!myFormGroup.valid">Submit</button> -->
                    </form>
                </mat-card>
            </div>
            <div class="myMainHalfBottom">
                <!-- Main Bottom -->
            </div>
        </div>
        <div class="myMainRight">
            <div class="myMainHalfTop">
                <!-- Right Top -->
            </div>
            <div class="myMainHalfBottom">
                <!-- Right Bottom -->
            </div>
        </div>
    </div>
    <!-- <footer>footer</footer> -->
</div>
