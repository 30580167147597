<div class="myBase">
 <div class="myBackgroundLayer">
  <div class="row">
   <div class="myAccountTitle">
    <h2>
    </h2>
   </div>
  </div>
  <div class="d-flex justify-content-center">
   <mat-card class="supportingMembershipAnounce">
    <h3>
     {{this.router.url.includes('EN')==true?'Supporting Membership Annual Donation': '維持会員献金（年間）'}}
    </h3>
    <br><br>
    <label >
       <div >
         <div class="lblCost">
            $ 40
         </div>
         <div class="lblCostJP">
            <i class="fas fa-yen-sign"></i> 4,000
         </div>
       </div>
    </label>
    <br>
    <div class="d-flex justify-content-center">
     <button
     type="button"
     class="btn btnProcessPayment"
     (click)="onClickProcessSupportingMember()">
     {{this.router.url.includes('EN')==true?'Process Payment': '支払い手続きをする'}}
     </button>
    </div>

    <a [routerLink]="this.router.url.includes('EN')==true?'/home/EN': '/home/JP'"
    class="btn btn-link">{{this.router.url.includes('EN')==true?'Home': 'ホーム'}}</a>&nbsp;&nbsp;
    <a [routerLink]="this.router.url.includes('EN')==true?'/members/myProfile/EN': '/members/myProfile/JP'"
    class="btn btn-link">{{this.router.url.includes('EN')==true?'My Account': 'マイアカウント'}}</a>
   </mat-card>

  </div>
  <div  class="d-flex justify-content-center">
   <button mat-button (click)="onClickGoBack()" class="btn btn-link">
      {{this.router.url.includes('EN')==true?'Go Back': '戻る'}}
   </button>
  </div>
 </div>
</div>
