import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { User } from "../../models/user";
import { AuthService } from "../../services/auth/auth.service";
import { UserService } from "../../services/user/user.service";;


//For Dialog
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA , MatDialogConfig} from '@angular/material/dialog';
import { CheckHavingAccountComponent} from "../dialog/check-having-account/check-having-account.component";
import { CheckHavingAccountECComponent} from "../dialog/check-having-account-ec/check-having-account-ec.component";
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { TOOLTIP_PANEL_CLASS } from '@angular/material/tooltip';
import {PaymentMaster, LineItem, RecurringPayment} from "../../models/payment/paymentMaster";
import {LoginDialogEcComponent} from "../dialog/login-dialog-ec/login-dialog-ec.component";
import {LoginSimpleEcComponent} from "../dialog/login-simple-ec/login-simple-ec.component";
import { LoginGRCComponent } from '../dialog/GRC/login-grc/login-grc.component';

//Following 2 are for File upload
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EcRegistrationService } from 'src/app/services/conference/ec/ec-registration.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  mySelectedLanguage:string;
  firstFormGroup: FormGroup;
  user: User = {
    PasswordHash:null,
    Id:null,
    UserName:null,
    Email:null,
    FirstName:null,
    LastName:null,
    Password:null,
    Prefix:null,
    Shimei:null,
    Myoji:null,
    Street:null,
    Street2:null,
    City:null,
    State:null,
    Zip:null,
    Country:null,
    Yubin_Bango:null,
    To_Do_Fu_Ken:null,
    Shi_Gun_Ku:null,
    Cho_Son:null,
    Apartment_Etc:null,
    To_Do_Fu_Ken_JP:null,
    Shi_Gun_Ku_JP:null,
    Cho_Son_JP:null,
    Apartment_Etc_JP:null,
    Gender:null,
    DOB:null,
    TelNo:null,
    TimezoneName:null,
    UTCdiff:null,
    Token:null,
    Occupation:null,
    MemberStatus:null,
    Married:null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount:null,
    RegisteredDate:null,
    LastAccessedDate:null,
    //From siteground
    LoginId:null,
    MiddleNameEN:null,
    MiddleNameJP:null,
    PrefixJP:null,
    OccupationDetail:null,
    Organization:null,
    LivingCountry:null,
    PhoneEN:null,
    PhoneMobileEN:null,
    PhoneJP:null,
    PhoneMobileJP:null,
    ChurchNameEN:null,
    ChurchNameJP:null,
    MemberType:null,
    MemberDetail:null,
    MemberMemo:null,
    Shokaisha:null,
    Reason:null,
    NewsLetters:null,
    StaffReports:null,
    OtherLetters:null,
    NewsLetterLanguage:null,
    BasicRoles:null,
    //Added new
    MigrateFromSiteGround:null,
    AdminAccess:null,
    JCFNAccess:null,
    SupportingMember:null,
    LastAddressUpdatedDate:null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };
  loading = false;
  Email: string;
  Password: string;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  myMessage:string;

  constructor(
    private _formBuilder: FormBuilder,
    private authService: AuthService,
    public router: Router,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    public userService: UserService,
    private dialog: MatDialog,
    private http: HttpClient,
    public ecRegistrationService : EcRegistrationService,
  ) { }

  ngOnInit() {
    this.mySelectedLanguage = this.router.url.includes('EN') == true ? "EN" : "JP";

    sessionStorage.clear();

    this.firstFormGroup = this._formBuilder.group({
      myEmail: ['', Validators.required],
      myPassword: ['', Validators.required],
    });

    var myEmail = this.route.snapshot.params["MyEmail"];
    if (myEmail != null) {
      this.user.Email = myEmail;
      console.log('Redirected from register page.')
    } else {
      this.user.Email = null;
      console.log('Came to login page directly.')
    }
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  /*****************************
 * (C) Others Original
 *****************************/
  onCheckLogin() {
    this.loading = true;
    //1. Get Token from Host
    this.authService.login(this.user.Email, this.user.Password).subscribe(token => {
      console.log('**** TokenReturning result in Login Page ' + token+ " ****");
      this.router.navigate(["/members/myProfile/" + this.mySelectedLanguage]);
    },
    error => {
      this.myMessage = this.mySelectedLanguage=="EN"?"Login failed.":"ログインに失敗しました。";
      this.openSnackBar('error', 'close');
      this.loading = false;
    })
  }

  onClickECApply(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    // dialogConfig.maxHeight='500px'; //Desktop
     dialogConfig.maxWidth='600px';//Desktoop
    // dialogConfig.height='90%';//Mobile
     dialogConfig.width='95%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    //const  dialogRef = this.dialog.open(CheckHavingAccountECComponent,dialogConfig);
    //const  dialogRef = this.dialog.open(LoginDialogEcComponent,dialogConfig);
    const  dialogRef = this.dialog.open(LoginSimpleEcComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var redirectPage = result;
      if(result!=null){
        console.log("Confirmed Action: "+redirectPage);
      }
    })
  }

  onClickGRC23Apply(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='605px'; //Desktop
    dialogConfig.maxWidth='500px';//Desktop
    dialogConfig.height='90%';//Mobile
    dialogConfig.width='90%';//Mobile
    dialogConfig.panelClass= 'custom-dialog-container'; //Refer global level
    const  dialogRef = this.dialog.open(LoginGRCComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var redirectPage = result;
      if(result!=null){
        console.log("Confirmed Action: "+redirectPage);
      }
    })
  }

  onClickProcessLogin(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    // dialogConfig.maxHeight='550px'; //Desktop
     dialogConfig.maxWidth='600px';//Desktoop
    // dialogConfig.height='100%';//Mobile
     dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    const  dialogRef = this.dialog.open(CheckHavingAccountComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var redirectPage = result;
      if(result!=null){
        console.log("Confirmed Action: "+redirectPage);
      }
    })
   // this.router.navigate(["/registration/basic/personal/" +this.getMyLanguage()]);
  }

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 5000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 12000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }




    @Input()
    requiredFileType:string;
    fileName = '';
    uploadProgress:number;
    uploadSub: Subscription;
    onFileSelected(event:any) {
      const file:File = event.target.files[0];
      if (file) {
          this.fileName = file.name;
          const formData = new FormData();
          formData.append("thumbnail", file);

          const upload$ = this.http.post("/api/thumbnail-upload", formData, {
              reportProgress: true,
              observe: 'events'
          })
          .pipe(
              finalize(() => this.reset())
          );

          this.uploadSub = upload$.subscribe(event => {
            if (event.type == HttpEventType.UploadProgress) {
              this.uploadProgress = Math.round(100 * (event.loaded / event.total));
            }
          })



          // this.ecRegistrationService.postFile(file).subscribe(data => {
          //   // do something, if upload success
          //   }, error => {
          //     console.log(error);
          //   });




      }
  }

  cancelUpload() {
  this.uploadSub.unsubscribe();
  this.reset();
  }

  reset() {
  this.uploadProgress = null;
  this.uploadSub = null;
  }


}
