import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService } from "../../../services/auth/auth.service";

@Component({
  selector: 'app-processfailed',
  templateUrl: './processfailed.component.html',
  styleUrls: ['./processfailed.component.css']
})
export class ProcessfailedComponent implements OnInit {

  mySelectedLanguage:string;
  
  constructor(
    public router: Router,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.mySelectedLanguage = this.router.url.includes('EN')==true?"EN":"JP";
  }

}
