<div class="myBase">
 <div class="myBackgroundLayer">
   <div class="myContainer">

     <div class="truckingTitle">
       <div class="col-md-6">
         <h2><i class="fas fa-file-invoice-dollar fa-2x"></i>&nbsp; Payment</h2>
       </div>
     </div>
f
       <form #mySearchForm="ngForm" (ngSubmit)="onSearchSubmit(mySearchForm)">
         <div class="divSearchBox">
           <div class="row">
             <div class="searchItem">
               <mat-form-field class="containerSearchLg" appearance="outline">
                 <mat-label>First Name</mat-label>
                 <input [(ngModel)]="paymentMaster.FirstName" name="FirstName" matInput placeholder="First Name"
                   class="matInputBox" />
               </mat-form-field>
             </div>
             <div class="searchItem">
               <mat-form-field class="containerSearchLg" appearance="outline">
                 <mat-label>Last Name</mat-label>
                 <input [(ngModel)]="paymentMaster.LastName" name="LastName" matInput placeholder="Last Name"
                   class="matInputBox" />
               </mat-form-field>
             </div>
             <div class="searchItem">
               <mat-form-field class="containerSearch" appearance="outline">
                 <mat-label>Payment Types</mat-label>
                 <mat-select name="SelectedPaymentType" [(ngModel)]="paymentMaster.Recurring" class="matInputBox"
                   (selectionChange)="onSelectPaymentType($event.value)">
                   <mat-option *ngFor="let paymentType of paymentTypes" [value]="paymentType.value" class="matInputBox">
                     {{ paymentType.display }}
                   </mat-option>
                 </mat-select>
               </mat-form-field>
             </div>
             <div class="searchItem">
              <mat-form-field class="containerSearch" appearance="outline">
                <mat-label>Item Name</mat-label>
                <mat-select name="SelectedItemName" [(ngModel)]="paymentMaster.ItemName" class="matInputBox"
                  (selectionChange)="onSelectItemName($event.value)">
                  <mat-option *ngFor="let itemName of itemNames" [value]="itemName.value" class="matInputBox">
                    {{ itemName.display }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

           </div>
           <div class="row divSeachBoxSub">
             <div class="dateColorSub2"></div>
             <div class="row" id="btnGroup">
               <div>onSearchSubmit
                 <button type="button" class="btn btn-link linkToDownload" (click)="(mySearchForm)">Show
                   all
                   payment</button>
               </div>
               <button [disabled]="!mySearchForm.form.valid" class="btn btn-primary btnInSearchBox">
                 <i class="fa fa-search fa-fw"></i> Search</button>
               <button (click)="onCancel($event)" [disabled]="!mySearchForm.form.valid"
                 class="btn btn-secondary  btnInSearchBox">
                 Clear
               </button>
               <!-- <button mat-button (click)="exportToExcel()" class="btn btn-success btnDownload">
                 Download
               </button> -->
               <h4 *ngIf="showNumberOfUser" id="numberOfRegistrant">
                 {{ cntUser }} Registrant(s) Found
               </h4>
             </div>
           </div>
         </div>
       </form>


    <div>
       <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
       <div *ngIf="!loaded">
         <mat-progress-bar mode="indeterminate"></mat-progress-bar>
       </div>
       <br>
       <div class="registrantList">
         <div>

           <div>

             <table class="table table-striped table-bordered table-hover tblRegistrant" mat-table
               [dataSource]="dataSource" matSort>

               <ng-container matColumnDef="LastName">
                 <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                   Last Name</th>
                 <td mat-cell *matCellDef="let row" class="columnMdTd"> {{row.LastName}}<br>{{row.Myoji}} </td>
               </ng-container>

               <ng-container matColumnDef="FirstName">
                 <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                   First Name</th>
                 <td mat-cell *matCellDef="let row" class="columnMdTd"> {{row.FirstName}}<br>{{row.Shimei}} </td>
               </ng-container>

               <ng-container matColumnDef="ItemName">
                 <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                   Item Name</th>
                 <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.ItemName}} </td>
               </ng-container>

               <ng-container matColumnDef="ProviderName">
                 <th mat-header-cell *matHeaderCellDef class="columnSm">
                   Provider Name</th>
                 <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.ProviderName}} </td>
               </ng-container>

               <ng-container matColumnDef="Recurring">
                <th mat-header-cell *matHeaderCellDef class="columnSm">
                  Recurring</th>
                <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Recurring}} </td>
              </ng-container>

              <ng-container matColumnDef="PaymentDate">
                <th mat-header-cell *matHeaderCellDef class="columnSm">
                  Payment Date</th>
                <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.PaymentDate}} </td>
              </ng-container>



               <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
               <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
               <tr class="mat-row" *matNoDataRow>
               </tr>
             </table>


             <mat-paginator [pageSize]="50" [pageSizeOptions]="[5, 10, 25, 100, 200, 300, 400]"></mat-paginator>
           </div>
         </div>
       </div>
     </div>



   </div>
 </div>
</div>
