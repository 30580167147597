import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { UserService} from "../../../../services/user/user.service";
import { Router, ActivatedRoute,NavigationExtras } from "@angular/router";
import { MatRadioChange } from '@angular/material/radio';
import { ECConference } from 'src/app/models/conference/ECConference';
import { User } from 'src/app/models/user';
import { CancelOrSaveConfirmationPersonalComponent } from 'src/app/components/dialog/cancel-or-save-confirmation-personal/cancel-or-save-confirmation-personal.component';
//For Dialog
import { MatDialog,  MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import { LoginMessageComponent } from '../../../dialog/login-message/login-message.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { EcRegistrationService } from 'src/app/services/conference/ec/ec-registration.service';
import { UserEditOthersProfileComponent  } from '../../../dialog/user-edit-others-profile/user-edit-others-profile.component';
import { RequestAddressUpdateComponent} from '../../../dialog/request-address-update/request-address-update.component';
import { AuthService } from "../../../../services/auth/auth.service";
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { RelatedPerson} from "../../../../models/conference/RelatedPerson";
import { VolunteerSelection} from "../../../../models/conference/volunteerSelection";
import { SummaryPersonEmbedComponent } from 'src/app/components/dialog/EC/summary-person-embed/summary-person-embed.component';
import { FaithInfoEmbedComponent } from 'src/app/components/dialog/EC/faith-info-embed/faith-info-embed.component';
import { MyAccountEditComponent } from 'src/app/components/dialog/EC/my-account-edit/my-account-edit.component';
@Component({
  selector: 'app-summary-person',
  templateUrl: './summary-person.component.html',
  styleUrls: ['./summary-person.component.css']
})
export class SummaryPersonComponent implements OnInit {
  loaded: boolean = true;
  myFormGroup: FormGroup;
  myMessage: string;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  mySelectedLanguage: string;
  strBoolean: string;
  editMode: boolean;

  user: User = {
    PasswordHash:null,
    Id:null,
    UserName:null,
    Email:null,
    FirstName:null,
    LastName:null,
    Password:null,
    Prefix:null,
    Shimei:null,
    Myoji:null,
    Street:null,
    Street2:null,
    City:null,
    State:null,
    Zip:null,
    Country:null,
    Yubin_Bango:null,
    To_Do_Fu_Ken:null,
    Shi_Gun_Ku:null,
    Cho_Son:null,
    Apartment_Etc:null,
    To_Do_Fu_Ken_JP:null,
    Shi_Gun_Ku_JP:null,
    Cho_Son_JP:null,
    Apartment_Etc_JP:null,
    Gender:null,
    DOB:null,
    TelNo:null,
    TimezoneName:null,
    UTCdiff:null,
    Token:null,
    Occupation:null,
    MemberStatus:null,
    Married:null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount:null,
    RegisteredDate:null,
    LastAccessedDate:null,
    // //From siteground
    LoginId:null,
    MiddleNameEN:null,
    MiddleNameJP:null,
    PrefixJP:null,
    OccupationDetail:null,
    Organization:null,
    LivingCountry:null,
    PhoneEN:null,
    PhoneMobileEN:null,
    PhoneJP:null,
    PhoneMobileJP:null,
    ChurchNameEN:null,
    ChurchNameJP:null,
    MemberType:null,
    MemberDetail:null,
    MemberMemo:null,
    Shokaisha:null,
    Reason:null,
    NewsLetters:null,
    StaffReports:null,
    OtherLetters:null,
    NewsLetterLanguage:null,
    BasicRoles:null,
    //Added new
    MigrateFromSiteGround:null,
    AdminAccess:null,
    JCFNAccess:null,
    SupportingMember:null,
    LastAddressUpdatedDate:null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };
  relatedPerson:RelatedPerson={
    Id:null,
    RelatedId:null,
    Email:null,
    FirstNameRelated:null,
    LastNameRelated:null,
    MyojiRelated:null,
    ShimeiRelated:null,
    DOBRelated:null,
    GenderRelated:null
  };
  relatedPersons:RelatedPerson[]=[];
  volunteerSelections:VolunteerSelection[]=[];
  conference: ECConference= {
    ECConferenceId:0,
    UserId: null,
    Email: null,
    UserName: null,
    FirstName: null,
    LastName: null,
    //Program
    ProgramType: null,
    SmallGroupLanguage: null,
    AttendJapanRegionalMeeting:null,
    JapanRegionalMeeting:null,
    ApplyEnglishPartner:null,
    //Payment
    ConferenceFee: null,
    ConferenceDiscountAmount: null,
    ConferenceFeePaidAmount: null,
    ConferenceFeePaidDate: null,
    CouponApproved: null,
    ApplyScholarship: null,
    ApplyingScholarshipAmount: null,
    ApprovedScholarshipAmount: null,
    ApplyingReason: null,
    //Others\
    Volunteers:null,
    Student:null,
    ApplyCounseling: null,
    MySleepingBag:null,
    BringExtraSleepingBag:null,
    CountExtraSleepingBag:null,
    NeedCommingBus:null,
    CommingBusDeparturePlace:null,
    CommingBusDepartureDateTime:null,
    CommingAirline:null,
    CommingFlightNo:null,
    DepartureCity:null,
    CommingInternationalDomestic:null,
    ArrivalDateTime:null,
    NeedReturningBus:null,
    ReturningBusDepartureDateTime:null,
    ReturningAirline:null,
    ReturningFlightNo:null,
    ReturningCity:null,
    ReturningBusDeparturePlace:null,
    ReturningInternationalDomestic:null,
    DepartureDateTime:null,
    RoomInfo:null,
    Couple:null,
    Youth:null,
    YouthGrade: null,
    GuardianFirstName: null,
    GuardianLastName: null,
    GuardianMyoji: null,
    GuardianShimei: null,
    GuardianEmail: null,
    GuardianTelNo: null,
    TermsOfParticipation: null,
    TermsOfParticipationParent: null,
    TermsOfParticipationDate: null,
    TermsOfParticipationDateParent: null,
    RegisteredDate: null,
    UpdatedDate: null,

    AdminAccess: null,
    ECCommitteeAccess: null,
    Memo: null,
    Dec26:null,
    Dec27:null,
    Dec28:null,
    Dec29:null,
    Dec30:null,
    Dec31:null,
    KidGroup1:null,
    KidGroup2:null,
    KidGroup3:null,
    KidGroup4:null,
    myRelatedPersons: this.relatedPersons,
    myVolunteerSelections:this.volunteerSelections,
    BusComingConfirmation:null,
    BusReturningConfirmation:null,
    TermsOfParticipationVenue:null,
    TermsOfParticipationDateVenue:null,
    TermsOfParticipationYouth:null,
    TermsOfParticipationDateYouth:null,
    TermsOfParticipationYouthParent:null,
    TermsOfParticipationDateYouthParent:null,
    EmergencyContactFirstName:null,
    EmergencyContactLastName:null,
    EmergencyContactEmail:null,
    EmergencyContactTelNo:null,
    ApplicationFee:null,
    ApplicationFeePaidDate:null,
    BalanceAmount:null,
    BalanceAmountPaidDate:null,
    HasAllergy:null,
    AllergyDetail:null,
    BringInsurance:null,
    MinorHasCustodyInsurance:null,
    MinorHasCustodyInsuranceDate:null,
    InsuranceCompany:null,
    InsuranceAddress:null,
    InsuranceTelNo:null,
    InsurancePolicyNo:null,
    InsuranceGroupNo:null,
    VolunteerAgreeConductCode:null,
    VolunteerAgreeConductCodeDate:null,
    PayingAmount:null,
     //New
    BecomeMentor:null,
    ApplyMentoring:null,
    ReturningDepartureCity:null,
    InsuranceStatus:null,
    DressCodeAgreeYouth:null,
    DressCodeDateYouth:null,
    DressCodeAgreeParent:null,
    DressCodeDateParent:null
}

  constructor(
    private _snackBar: MatSnackBar,
    public router: Router,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private registrationService: UserService,
    private ecRegistrationService : EcRegistrationService,
    private dialog: MatDialog,
    public authService: AuthService,
    private userService: UserService,
    /**************************************************
     * Followings 2 componensts used to call function
     * in those componets from this class
    ***************************************************/
       //  private userEditOthersProfile: UserEditOthersProfileComponent,
      //   private requestAddressUpdate:RequestAddressUpdateComponent
  ) {
    this.route.queryParams.subscribe(params => {
      this.user.FirstName = params["FirstName"];
      this.user.LastName   = params["LastName"];
      console.log("1.First Name: "+ this.conference.FirstName);
      console.log("2.Last Name: "+this.conference.LastName);
  });
   }

  mySearch: any = {};
  ngOnInit(): void {
    this.myFormGroup = this._formBuilder.group({
    });
    this.loaded = false;
    if (sessionStorage.getItem("myECSearchingUserId") != null) {
      /*************************************************
       *  A) User info for searching registrant by admin
       *************************************************/
       this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnUserAdmin"));
       if (this.mySearch != null) {
        this.user = this.mySearch.myEditingUser;
        this.loaded = true;
       }else{
        this.loaded = true;
       }
    } else {
      /*#####################################################################
      # Get user info from previous page - basic regardless it is searching user or myself
      ######################################################################*/
      this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnUser"));
      if (this.mySearch != null) {
        console.log('found it!!')
        this.user = this.mySearch.myEditingUser;
        this.loaded = true;
      }else{

      }
  }
}

  onSubmit() {
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  goToBasic(){
    if(this.router.url.includes('/registration/ec/')){
      this.router.navigate(["/registration/ec/basic/" +this.getMyLanguage()]);
    }else{
    }
  }

  goToBasicSub(){
    if(this.router.url.includes('/registration/ec/')){
      this.router.navigate(["/registration/ec/basicSub/" +this.getMyLanguage()]);
    }else{
    }
  }

  goToAddressInfo(){
    if(this.router.url.includes('/registration/ec/')){
      this.router.navigate(["/registration/ec/addressInfo/" +this.getMyLanguage()]);
    }else{
    }
  }

  goToFaithInfo(){
    if(this.router.url.includes('/registration/ec/')){
      this.router.navigate(["/registration/ec/faithInfo/" +this.getMyLanguage()]);
    }else{
    }
  }

  savingPersonalInfo() {
    this.loaded = false;
    if (this.user.FaithQuesCompletedDate == null) {
      this.user.FaithQuesCompletedDate = new Date;
    }
    this.userService.upsertUser(this.user).subscribe(
      data => {
        this.myMessage = this.getMyLanguage() == "EN" ? "User profile saved successfully." : "個人情報が保存されました";
        this.openSnackBar('success', 'close');
        this.loaded = true;
        if (this.authService.isLoggedIn()) {
          console.log(1);
          this.loaded = true;

          if (this.router.url.includes('/registration/ec/')) {
            console.log(2);
            this.router.navigate(["/conference/EC/myAccount/" + this.getMyLanguage()]);
          } else {
            console.log(3);
            this.dialog.closeAll();
            this.openForwardEmbed();
          }
          console.log(4);
        } else {
          console.log(5);
          this.authService.login(this.user.Email, this.user.Password).subscribe(
            result => {
              //Login Sussessfully.
              this.userService.getUserProfile(this.user.Email).subscribe(
                result => {
                  this.user = result;
                  this.myMessage = this.getMyLanguage() == "EN" ? "User profile saved successfully." : "個人情報が保存されました!";
                  this.openSnackBar('success', 'close');
                  this.loaded = true;
                  console.log(6);
                  if (this.router.url.includes('/registration/ec/')) {
                    console.log('6-a');
                    this.router.navigate(["/conference/EC/myAccount/" + this.getMyLanguage()]);
                  } else {
                    console.log('6-b');
                    this.dialog.closeAll();
                    this.openForwardEmbed();
                  }
                }
              )
              console.log(7);
            },
            error => {
              this.myMessage = this.getMyLanguage() == "EN" ? "Login failed." : "ログインに失敗しました。";
              this.openSnackBar('error', 'close');
              this.loaded = true;
            }
          )
        }
      },
      error => {
        this.myMessage = this.getMyLanguage() == "EN" ? "Data save failed." : "問題が生じ個人情報が保存できませんでした。";
        this.openSnackBar('error', 'close');
        this.loaded = true;
      }
    )
    // Updateし終わった自分のPerson情報のセッションを消しておく
    sessionStorage.removeItem("myWorkingOnUser");
    //Adminによる作業中のユーザーのセッションを消しておく
    if (sessionStorage.getItem("myECSearchingUserId") != null) {
      sessionStorage.removeItem("myWorkingOnUserAdmin");
    }
  }
  openForwardEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ userId: this.user.Id };
    const dialogRef = this.dialog.open(MyAccountEditComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  onClickGoBack(){
    if(this.router.url.includes('/registration/ec/')){
      this.router.navigate(["/registration/ec/faithInfo/" +this.getMyLanguage()]);
    }else{
      this.dialog.closeAll();
      this.openBackEmbed();
    }
  }
  openBackEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ Id: this.user.Id };
    const dialogRef = this.dialog.open(FaithInfoEmbedComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  cancelProcess(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='210px'; //Desktop
    dialogConfig.maxWidth='370px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ user: this.user };
    const dialogRef = this.dialog.open(CancelOrSaveConfirmationPersonalComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
      //Retreive updated registration Info.
      //this.ngOnInit();
      let navigationExtras: NavigationExtras = {
        queryParams:this.conference
      };
      if(result!='cancel'){
        if(this.router.url.includes('/registration/ec/')){
          this.router.navigate(["/conference/EC/myAccount/" +this.getMyLanguage()], navigationExtras);
        }else{
          this.dialog.closeAll();
          this.openMyAccountEdit();
        }
        }
    })
  }

  openMyAccountEdit(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ userId: this.user.Id };
    const dialogRef = this.dialog.open(MyAccountEditComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 6000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 20000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }
}
