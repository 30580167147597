import { Component, OnInit, } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { Router,NavigationExtras } from "@angular/router";
import { User } from 'src/app/models/user';
import { CancelOrSaveConfirmationComponent } from 'src/app/components/dialog/cancel-or-save-confirmation/cancel-or-save-confirmation.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog,  MatDialogConfig} from '@angular/material/dialog';
import { RegistrationConferenceEmbedComponent } from 'src/app/components/dialog/EC/registration-conference-embed/registration-conference-embed.component';
import { MyAccountEditComponent } from 'src/app/components/dialog/EC/my-account-edit/my-account-edit.component';
import { GRCConference, GRCRelatedPerson, GRCVolunteerSelection } from 'src/app/models/conference/GRCConference';
import { RegistrationConferenceEmbedGRCComponent } from 'src/app/components/dialog/GRC/registration-conference-embed-grc/registration-conference-embed-grc.component';
@Component({
  selector: 'app-volunteers-grc',
  templateUrl: './volunteers-grc.component.html',
  styleUrls: ['./volunteers-grc.component.css']
})
export class VolunteersGRCComponent implements OnInit {
  loaded:boolean=true;
  myFormGroup: FormGroup;
  myMessage:string;
  mySelectedLanguage: string;

  prayerChecked?:boolean;
  praiseTeamChecked?:boolean;
  bibleReadingChecked?:boolean;
  skitChecked?:boolean;
  PAChecked?:boolean;
  propresenterChecked?:boolean;
  videoPhotoChecked?:boolean;
  videoEditingChecked?:boolean;
  translatorChecked?:boolean;
  interpreterChecked?:boolean;
  englishAssistantChecked?:boolean;
  usherChecked?:boolean;
  smallGroupLeaderChecked?:boolean;
  workshopHelperChecked?:boolean;
  kidsProgramHelperChecked?:boolean;
  bookstoreChecked?:boolean;
  nursingChecked?:boolean;
  receptionChecked?:boolean;
  testimoneyChecked?:boolean;
  counselorChecked?:boolean;
  japanRegionalMeetingLeaderChecked?:boolean;
  facilitatorOfNetworkingTimeChecked?:boolean;
  childCareHelperChecked?:boolean;
  transportationChecked?:boolean;
  conferenceSiteSetUpChecked?:boolean;
  boothSetUpManagementChecked?:boolean;

  user: User = {
    PasswordHash:null,
    Id:null,
    UserName:null,
    Email:null,
    FirstName:null,
    LastName:null,
    Password:null,
    Prefix:null,
    Shimei:null,
    Myoji:null,
    Street:null,
    Street2:null,
    City:null,
    State:null,
    Zip:null,
    Country:null,
    Yubin_Bango:null,
    To_Do_Fu_Ken:null,
    Shi_Gun_Ku:null,
    Cho_Son:null,
    Apartment_Etc:null,
    To_Do_Fu_Ken_JP:null,
    Shi_Gun_Ku_JP:null,
    Cho_Son_JP:null,
    Apartment_Etc_JP:null,
    Gender:null,
    DOB:null,
    TelNo:null,
    TimezoneName:null,
    UTCdiff:null,
    Token:null,
    Occupation:null,
    MemberStatus:null,
    Married:null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount:null,
    RegisteredDate:null,
    LastAccessedDate:null,
    //From siteground
    LoginId:null,
    MiddleNameEN:null,
    MiddleNameJP:null,
    PrefixJP:null,
    OccupationDetail:null,
    Organization:null,
    LivingCountry:null,
    PhoneEN:null,
    PhoneMobileEN:null,
    PhoneJP:null,
    PhoneMobileJP:null,
    ChurchNameEN:null,
    ChurchNameJP:null,
    MemberType:null,
    MemberDetail:null,
    MemberMemo:null,
    Shokaisha:null,
    Reason:null,
    NewsLetters:null,
    StaffReports:null,
    OtherLetters:null,
    NewsLetterLanguage:null,
    BasicRoles:null,
    //Added new
    MigrateFromSiteGround:null,
    AdminAccess:null,
    JCFNAccess:null,
    SupportingMember:null,
    LastAddressUpdatedDate:null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };
  relatedPerson:GRCRelatedPerson={
    Id:null,
    RelatedId:null,
    Email:null,
    FirstNameRelated:null,
    LastNameRelated:null,
    MyojiRelated:null,
    ShimeiRelated:null,
    DOBRelated:null,
    GenderRelated:null
  };
  relatedPersons:GRCRelatedPerson[]=[];
  volunteerSelection: GRCVolunteerSelection={
    Id:null,
    UserId:null,
    Email:null,
    VolunteerName:null,
    VolunteerAccepted:null,
    AcceptedDate:null,
  }
  volunteerSelections:GRCVolunteerSelection[]=[];
  conference= new GRCConference();

  constructor(
    public router: Router,
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
  ) { }

  mySearch: any = {};
  ngOnInit(): void {
    this.myFormGroup = this._formBuilder.group({
      myPrayer: [null],
    });

    if (sessionStorage.getItem("myGRCSearchingUserIdGRC") != null) {
      /*************************************************
       *  A) User info for searching registrant by admin
       *************************************************/
       this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnConferenceAdminGRC"));
       if (this.mySearch != null) {
        console.log('admin is editing this user')
         this.conference = this.mySearch.myEditingConference;
         this.patchModelValue(this.conference);
         this.loaded = true;
       }else{
        this.loaded = true;
       }
    } else {
    /*####################################################################################
    #  B) Get conference info from previous page for myself
    #####################################################################################*/
    this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnConference"));
      if (this.mySearch != null) {
        console.log('login user is editing his account')
        this.conference = this.mySearch.myEditingConference;
        this.patchModelValue(this.conference);
      }else{
      }
    }
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  patchModelValue(model:GRCConference){
    //console.log('lenght of myGRCVolunteerSelections: '+ model.myGRCVolunteerSelections.length);
    if(model.myGRCVolunteerSelections!=null &&  model.myGRCVolunteerSelections.length>0){
        model.myGRCVolunteerSelections.forEach(element => {
          if(element.VolunteerName=='Prayer'){
            this.prayerChecked=true;
          }
          if(element.VolunteerName=='Praise Team'){
            this.praiseTeamChecked=true;
          }
          if(element.VolunteerName=='Bible Reading'){
            this.bibleReadingChecked=true;
          }
          if(element.VolunteerName=='Skit'){
            this.skitChecked=true;
          }
          if(element.VolunteerName=='PA'){
            this.PAChecked=true;
          }
          if(element.VolunteerName=='Propresenter'){
            this.propresenterChecked=true;
          }
          if(element.VolunteerName=='Video/Photo'){
            this.videoPhotoChecked=true;
          }
          if(element.VolunteerName=='Translator'){
            this.translatorChecked=true;
          }
          if(element.VolunteerName=='Interpreter'){
            this.interpreterChecked=true;
          }
          if(element.VolunteerName=='Usher'){
            this.usherChecked=true;
          }
          if(element.VolunteerName=='Small Group Leader'){
            this.smallGroupLeaderChecked=true;
          }
          if(element.VolunteerName=='Workshop Helper'){
            this.workshopHelperChecked=true;
          }
          if(element.VolunteerName=='Kids Program Helper'){
            this.kidsProgramHelperChecked=true;
          }
          if(element.VolunteerName=='Book Store'){
            this.bookstoreChecked=true;
          }
          if(element.VolunteerName=='Nursing'){
            this.nursingChecked=true;
          }
          if(element.VolunteerName=='Reception'){
            this.receptionChecked=true;
          }
          if(element.VolunteerName=='Counselor'){
            this.counselorChecked=true;
          }
          if(element.VolunteerName=='Sharing Testimony'){
            this.testimoneyChecked=true;
          }

          if(element.VolunteerName=='Video Editing'){
            this.videoEditingChecked=true;
          }
          if(element.VolunteerName=='English Assistant'){
            this.englishAssistantChecked=true;
          }
          if(element.VolunteerName=='Facilitator of networking time'){
            this.facilitatorOfNetworkingTimeChecked=true;
          }
          if(element.VolunteerName=='Child care helper'){
            this.childCareHelperChecked=true;
          }
          if(element.VolunteerName=='Transportation'){
            this.transportationChecked=true;
          }
          if(element.VolunteerName=='Conference site set-up'){
            this.conferenceSiteSetUpChecked=true;
          }
          if(element.VolunteerName=='Booth set-up/management'){
            this.boothSetUpManagementChecked=true;
          }
        });
        this.volunteerSelections = model.myGRCVolunteerSelections;
    }
  }

  onSubmit() {
  }

  getFormValue() {
  this.conference.myGRCVolunteerSelections = this.volunteerSelections;
  }

  onCheckVolunteer($event: MatCheckboxChange) {
    if($event.checked){
      const person = {
        Id:0,
        UserId:this.conference.UserId,
        Email:this.conference.Email,
        VolunteerName:$event.source.value,
        VolunteerAccepted:false,
        AcceptedDate:new Date(),

      }
      this.volunteerSelections.push(person);
    }else{
      console.log('unchecking');
      this.volunteerSelections.forEach(element => {
        if(element.VolunteerName == $event.source.value){
          console.log('found it!');
          const index = this.volunteerSelections.findIndex(x=>x.VolunteerName==$event.source.value);
          if (index > -1) {
            console.log('removing');
            this.volunteerSelections.splice(index, 1);
          }
        }
      });
    }
  }

  onClickGoForward(){
    this.getFormValue();
    if(this.router.url.includes('/registration/GRC/')){
      sessionStorage.removeItem("myWorkingOnConference");
      sessionStorage.setItem(
        "myWorkingOnConference",
        JSON.stringify({ myEditingConference: this.conference })
      );
      this.router.navigate(["/registration/GRC/coupleGRC/" +this.getMyLanguage()]);
    }else{
      sessionStorage.removeItem("myWorkingOnConferenceAdminGRC");
      sessionStorage.setItem(
        "myWorkingOnConferenceAdminGRC",
        JSON.stringify({ myEditingConference: this.conference })
      );
      this.dialog.closeAll();
      this.openForwardEmbed();
    }
  }
  openForwardEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktoop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ myPage: 'coupleGRC' };
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedGRCComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  onClickGoBack(){
    if(this.router.url.includes('/registration/GRC/')){
      this.router.navigate(["/registration/GRC/smallGroupLanguageGRC/" +this.getMyLanguage()]);
    }else{
      this.dialog.closeAll();
      this.openBackEmbed();
    }
  }
  openBackEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ myPage: 'smallGroupLanguageGRC' };
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedGRCComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  onChangeVolunteersSelection($event:any){
  //  this.conference.Volunteers = $event.value;
  }

  cancelProcess(){
    this.getFormValue();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='210px'; //Desktop
    dialogConfig.maxWidth='370px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ conference: this.conference };
    const dialogRef = this.dialog.open(CancelOrSaveConfirmationComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
      //Retreive updated registration Info.
      //this.ngOnInit();
      let navigationExtras: NavigationExtras = {
        queryParams:this.conference
      };
      if(result!='cancel'){
        if(this.router.url.includes('/registration/GRC/')){
          this.router.navigate(["/registration/GRC/myAccountGRC/" +this.getMyLanguage()], navigationExtras);
        }else{
          this.dialog.closeAll();
          this.openMyAccountEdit();
        }
        }
    })
  }

  openMyAccountEdit(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ userId: this.conference.UserId };
    const dialogRef = this.dialog.open(MyAccountEditComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }
  }
