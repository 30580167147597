<div class="myBody">
  <div class="myHeaderBase d-flex justify-content-center">
    <div class="myHeaderCore">
      <div>
        <img src='../../../../../assets/images/thumbnail_eclogo.png'  class="ecLogo">
    </div>
    </div>
  </div>
  <div class="myMain">
    <div clas="myMainLeft">
      <div class="myMainHalfTop">
        <!-- Left Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Left Bottom -->
      </div>
    </div>
    <div class="myMainCenter">
      <div class="myMainHalfTop d-flex justify-content-center">
        <!-- Main Top -->

        <mat-card class="mainCard b-radius">
          <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
          <div *ngIf="!loaded">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
            <!-- <form *ngIf="userFromServer | async; else loading"  -->
            <form
              [formGroup]="myFormGroup"
              (ngSubmit)="onSubmit()"
              >
            <div class="float-end stepNumber">1/12</div>
            <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Program Type': 'プログラムタイプ'}}
              <i class="stepTitleSub">
                {{this.router.url.includes('EN')==true?'Required': '必須'}}
              </i>
            </h2>
            <!-- <div class="stepTitle2nd">
              {{this.router.url.includes('EN')==true?'New applications are no longer accepted. You will be placed on a waiting list. If you are interested in joining EC, please contact us at ecregistration@equipper.org.':
              '新規申し込みは終了しました。今後ははキャンセル待ちにつきWaiting Listにてお待ちいただくことにになります。 EC参加ご希望の方は ecregistration@equipper.org までお問い合わせください。'}}
           </div> -->
            <br>
            <div *ngIf="this.ecRegistrationService.checkLoginUserAccessEC()"  class="d-flex justify-content-center">
              <mat-radio-group formControlName="myProgramType"
                (change)="onChangeProgramSelection($event)">
                <mat-accordion>
                  <mat-expansion-panel class="myPanel" (opened)="panelOpenAdult = true"
                    (closed)="panelOpenAdult = false">
                    <mat-expansion-panel-header class="myHeaderPanel">
                      <mat-panel-title>
                        <mat-radio-button  value="General Program"  [disabled]=false></mat-radio-button>
                        <!-- <mat-radio-button value="JapaneseAdult"></mat-radio-button> -->
                        {{this.router.url.includes('EN')==true?'General Program (Japanese and English)': '一般プログラム（日・英）'}}
                      </mat-panel-title>
                      <p class="detailSign">
                        {{this.router.url.includes('EN')==true?
                        (panelOpenAdult? 'Close' : 'Detail'):
                        (panelOpenAdult? '閉じる' : '詳細')}}
                      </p>
                    </mat-expansion-panel-header>
                    <p>
                      {{this.router.url.includes('EN')==true?
                      'The General Program is for college students and adult Christians (18 years old or older). It will be conducted in Japanese with simultaneous interpretation in English.':
                      '大学生以上(18歳以上)のクリスチャン向けです。日本語で行われますが、英語の同時通訳があります。'}}
                    </p>
                    <!-- <p class="attention">
                      {{this.router.url.includes('EN')==true?
                      'Capacity reached':
                      '定員に達しました'}}
                  </p> -->
                  </mat-expansion-panel>
                  <mat-expansion-panel class="myPanel" (opened)="panelOpenRJC = true" (closed)="panelOpenRJC = false">
                    <mat-expansion-panel-header class="myHeaderPanel">
                      <mat-panel-title>
                        <mat-radio-button  value="Mission Program" [disabled]=false></mat-radio-button>
                        <!-- <mat-radio-button value="Mission Program"></mat-radio-button> -->
                        {{this.router.url.includes('EN')==true?'Mission Program': 'ミッション・プログラム'}}
                      </mat-panel-title>
                      <p class="detailSign">
                        {{this.router.url.includes('EN')==true?
                        (panelOpenRJC? 'Close' : 'Detail'):
                        (panelOpenRJC? '閉じる' : '詳細')}}
                      </p>
                    </mat-expansion-panel-header>
                    <p>
                      {{this.router.url.includes('EN')==true?
                      'The Mission Program is for those who have a burden and interest in Japan missions and Japanese missions overseas. (Japanese can also participate, but it will be conducted primarily in English.':
                      '日本宣教、海外日本人宣教に重荷・関心のある人向けです。日本人でも参加できますが、主に英語で行われます'}}
                    </p>
                    <!-- <p class="attention">
                      {{this.router.url.includes('EN')==true?
                      'Capacity reached':
                      '定員に達しました'}}
                  </p> -->
                  </mat-expansion-panel>
                  <mat-expansion-panel class="myPanel" (opened)="panelOpenSeekers = true"
                    (closed)="panelOpenSeekers = false">
                    <mat-expansion-panel-header class="myHeaderPanel">
                      <mat-panel-title>
                        <mat-radio-button  value="Seekers Program" [disabled]=false></mat-radio-button>
                        <!-- <mat-radio-button value="Seekers Program"></mat-radio-button> -->
                        {{this.router.url.includes('EN')==true?'Seekers Program': 'Seekersプログラム'}}
                      </mat-panel-title>
                      <p class="detailSign">
                        {{this.router.url.includes('EN')==true?
                        (panelOpenSeekers? 'Close' : 'Detail'):
                        (panelOpenSeekers? '閉じる' : '詳細')}}
                      </p>
                    </mat-expansion-panel-header>

                    {{this.router.url.includes('EN')==true?
                    'The Seekers Program is for people who are interested in Christianity and are seeking God. Program
                    is only in Japanese.':
                    'キリスト教に興味をもっており、探求している方のためのものです。イエス様と共に生きる楽しさを知っていただけたら嬉しいです！'}}
                  <p class="attention">
                    {{this.router.url.includes('EN')==true?
                    'New Christians are also welcomed.':
                    'クリスチャンになったばかりの方も、ウェルカムです。プログラムは日本語です。'}}
                  </p>
                    {{this.router.url.includes('EN')==true?
                    'Seekers program staff should also choose this option.':
                    'Seekersプログラムのスタッフもこれを選択して下さい。'}}
                    <!-- <p class="attention">
                      {{this.router.url.includes('EN')==true?
                      'Capacity reached':
                      '定員に達しました'}}
                  </p> -->

                  </mat-expansion-panel>
                  <mat-expansion-panel class="myPanel" (opened)="panelOpenYouth = true"
                    (closed)="panelOpenYouth = false">
                    <mat-expansion-panel-header class="myHeaderPanel">
                      <mat-panel-title>
                        <mat-radio-button  value="Youth Program" [disabled]=false></mat-radio-button>
                        <!-- <mat-radio-button value="Youth Program"></mat-radio-button> -->
                        {{this.router.url.includes('EN')==true?'Youth Program ': 'ユース(中高生)プログラム '}}

                      </mat-panel-title>
                      <p class="detailSign">
                        {{this.router.url.includes('EN')==true?
                        (panelOpenYouth? 'Close' : 'Detail'):
                        (panelOpenYouth? '閉じる' : '詳細')}}
                      </p>
                    </mat-expansion-panel-header>
                    <div class="attentionDescription">
                      <!-- {{this.router.url.includes('EN')==true?'Max 50 People': '定員50人'}} -->
                    </div>

                      {{this.router.url.includes('EN')==true?
                      'The Youth Program is for middle and high school students (12 years old or older) and is in
                      bilingual.　Youth program staff should also choose this option.':
                      '中高生のためのもので、バイリンガルのプログラムです。Youthプログラムのスタッフもこれを選択して下さい。'}}<br>
                  <!--  <p class="attention">
                        {{this.router.url.includes('EN')==true?
                        'Capacity reached':
                        '定員に達しました'}}
                    </p>-->
                  </mat-expansion-panel>
                </mat-accordion>
              </mat-radio-group>
            </div>
            <div *ngIf="!this.ecRegistrationService.checkLoginUserAccessEC()"  class="d-flex justify-content-center">
              <mat-radio-group formControlName="myProgramType"
                (change)="onChangeProgramSelection($event)">
                <mat-accordion>
                  <mat-expansion-panel class="myPanel" (opened)="panelOpenAdult = true"
                    (closed)="panelOpenAdult = false">
                    <mat-expansion-panel-header class="myHeaderPanel">
                      <mat-panel-title>
                        <mat-radio-button  value="General Program"  [disabled]=true></mat-radio-button>
                        <!-- <mat-radio-button value="JapaneseAdult"></mat-radio-button> -->
                        {{this.router.url.includes('EN')==true?'General Program (Japanese and English)': '一般プログラム（日・英）'}}
                        &nbsp;&nbsp;<p class="attention">
                        {{this.router.url.includes('EN')==true?
                        'Capacity reached':
                        '定員に達しました'}}
                      </mat-panel-title>
                      <p class="detailSign">
                        {{this.router.url.includes('EN')==true?
                        (panelOpenAdult? 'Close' : 'Detail'):
                        (panelOpenAdult? '閉じる' : '詳細')}}
                      </p>
                    </mat-expansion-panel-header>
                    <p>
                      {{this.router.url.includes('EN')==true?
                      'The General Program is for college students and adult Christians (18 years old or older). It will be conducted in Japanese with simultaneous interpretation in English.':
                      '大学生以上(18歳以上)のクリスチャン向けです。日本語で行われますが、英語の同時通訳があります。'}}
                    </p>
                    <!-- <p class="attention">
                      {{this.router.url.includes('EN')==true?
                      'Capacity reached':
                      '定員に達しました'}}
                  </p> -->
                  </mat-expansion-panel>
                  <mat-expansion-panel class="myPanel" (opened)="panelOpenRJC = true" (closed)="panelOpenRJC = false">
                    <mat-expansion-panel-header class="myHeaderPanel">
                      <mat-panel-title>
                        <mat-radio-button  value="Mission Program" [disabled]=true></mat-radio-button>
                        <!-- <mat-radio-button value="Mission Program"></mat-radio-button> -->
                        {{this.router.url.includes('EN')==true?'Mission Program': 'ミッション・プログラム'}}
                        &nbsp;&nbsp;<p class="attention">
                        {{this.router.url.includes('EN')==true?
                        'Capacity reached':
                        '定員に達しました'}}
                      </mat-panel-title>
                      <p class="detailSign">
                        {{this.router.url.includes('EN')==true?
                        (panelOpenRJC? 'Close' : 'Detail'):
                        (panelOpenRJC? '閉じる' : '詳細')}}
                      </p>
                    </mat-expansion-panel-header>
                    <p>
                      {{this.router.url.includes('EN')==true?
                      'The Mission Program is for those who have a burden and interest in Japan missions and Japanese missions overseas. (Japanese can also participate, but it will be conducted primarily in English.':
                      '日本宣教、海外日本人宣教に重荷・関心のある人向けです。日本人でも参加できますが、主に英語で行われます'}}
                    </p>
                    <!-- <p class="attention">
                      {{this.router.url.includes('EN')==true?
                      'Capacity reached':
                      '定員に達しました'}}
                  </p> -->
                  </mat-expansion-panel>
                  <mat-expansion-panel class="myPanel" (opened)="panelOpenSeekers = true"
                    (closed)="panelOpenSeekers = false">
                    <mat-expansion-panel-header class="myHeaderPanel">
                      <mat-panel-title>
                        <mat-radio-button  value="Seekers Program" [disabled]=true></mat-radio-button>
                        <!-- <mat-radio-button value="Seekers Program"></mat-radio-button> -->
                        {{this.router.url.includes('EN')==true?'Seekers Program': 'Seekersプログラム'}}
                        &nbsp;&nbsp;<p class="attention">
                        {{this.router.url.includes('EN')==true?
                        'Capacity reached':
                        '定員に達しました'}}
                      </mat-panel-title>
                      <p class="detailSign">
                        {{this.router.url.includes('EN')==true?
                        (panelOpenSeekers? 'Close' : 'Detail'):
                        (panelOpenSeekers? '閉じる' : '詳細')}}
                      </p>
                    </mat-expansion-panel-header>

                      {{this.router.url.includes('EN')==true?
                      'The Seekers Program is for people who are interested in Christianity and are seeking God. Program
                      is only in Japanese.':
                      'キリスト教に興味をもっており、探求している方のためのものです。イエス様と共に生きる楽しさを知っていただけたら嬉しいです！'}}
                    <p class="attention">
                      {{this.router.url.includes('EN')==true?
                      'New Christians are also welcomed.':
                      'クリスチャンになったばかりの方も、ウェルカムです。プログラムは日本語です。'}}
                    </p>
                      {{this.router.url.includes('EN')==true?
                      'Seekers program staff should also choose this option.':
                      'Seekersプログラムのスタッフもこれを選択して下さい。'}}
                      <!-- <p class="attention">
                        {{this.router.url.includes('EN')==true?
                        'Capacity reached':
                        '定員に達しました'}}
                    </p> -->

                  </mat-expansion-panel>
                  <mat-expansion-panel class="myPanel" (opened)="panelOpenYouth = true"
                    (closed)="panelOpenYouth = false">
                    <mat-expansion-panel-header class="myHeaderPanel">
                      <mat-panel-title>
                        <mat-radio-button  value="Youth Program" [disabled]=true></mat-radio-button>
                        <!-- <mat-radio-button value="Youth Program"></mat-radio-button> -->
                        {{this.router.url.includes('EN')==true?'Youth Program:': 'ユース(中高生)プログラム'}}
                        &nbsp;&nbsp;<p class="attention">
                        {{this.router.url.includes('EN')==true?
                        'Capacity reached':
                        '定員に達しました'}}
                      </mat-panel-title>
                      <p class="detailSign">
                        {{this.router.url.includes('EN')==true?
                        (panelOpenYouth? 'Close' : 'Detail'):
                        (panelOpenYouth? '閉じる' : '詳細')}}
                      </p>
                    </mat-expansion-panel-header>
                    <div class="attentionDescription">
                      <!-- {{this.router.url.includes('EN')==true?'Max 50 People': '定員50人'}} -->
                    </div>

                      {{this.router.url.includes('EN')==true?
                      'The Youth Program is for middle and high school students (12 years old or older) and is bilingual. Youth program staff should also choose this option.':
                      '中高生のためのもので、バイリンガルのプログラムです。Youthプログラムのスタッフもこれを選択して下さい。'}}<br>
                      <!-- <p class="attention">
                        {{this.router.url.includes('EN')==true?
                        'Capacity reached':
                        '定員に達しました'}}
                    </p> -->
                  </mat-expansion-panel>
                </mat-accordion>
              </mat-radio-group>
            </div>




            <div>
              <mat-error *ngIf="myFormGroup.get('myProgramType').hasError('required')  && myFormGroup.get('myProgramType').touched">
              {{this.router.url.includes('EN')==true?'Program Type is required': '未入力'}}
            </mat-error>
            </div>
            <div class=" position-absolute bottom-0 start-50 translate-middle-x">
              <div class="d-flex justify-content-center">
                <button type="button" class="btn btnProcess" (click)="onClickGoBack()">
                  {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                </button>
                <button type="button"  [disabled]="!myFormGroup.valid"  class="btn btnProcess" (click)="onClickGoForward()">
                  {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                </button>
              </div>
              <div class="d-flex justify-content-center">
                <button type="button" class="btn link" (click)="cancelProcess()">
                  {{this.router.url.includes('EN')==true?'Cancel': 'キャンセル'}}
                </button>
              </div>
            </div>
             <!-- <button type="submit" [disabled]="!myFormGroup.valid">Submit</button> -->
          </form>
        </mat-card>
      </div>
      <div class="myMainHalfBottom">
        <!-- Main Bottom -->
      </div>
    </div>
    <div class="myMainRight">
      <div class="myMainHalfTop">
        <!-- Right Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Right Bottom -->
      </div>
    </div>
  </div>
  <!-- <footer>footer</footer> -->
</div>
