import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../../../services/auth/auth.service";
import { User } from "../../../models/user";
import { UserService } from "../../../services/user/user.service";
import { MatRadioChange } from '@angular/material/radio';
import { MatStepper } from '@angular/material/stepper';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
//For Dialog
import { MatDialog,  MatDialogConfig} from '@angular/material/dialog';
import { LoginMessageComponent } from '../../dialog/login-message/login-message.component';
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { UserEditOthersProfileComponent  } from '../../dialog/user-edit-others-profile/user-edit-others-profile.component';
import { RequestAddressUpdateComponent} from '../../dialog/request-address-update/request-address-update.component';
interface To_Do_Fu_KenEN {
  display: string;
  value: string;
}

interface To_Do_Fu_KenJP {
  display: string;
  value: string;
}

interface USState {
  display: string;
  value: string;
}

interface MyList {
  display: string;
  value: string;
}

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.css']
})

export class PersonalComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  strMarried: string;
  myZoomSameEmail: boolean = false;
  panelOpenState = false;

  clickedNextFromFirstFormGroup: boolean;
  clickedNextFromSecondFormGroup: boolean = false;
  clickedNextFromFourthFormGroup: boolean = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  myMessage: string;

  loaded: boolean = true;
  mySelectedLanguage: string;
  showUSAddress: boolean = true;
  showJapanAddress: boolean = true;
  showOtherAddress: boolean = true;
  showGuardianInfo: boolean = false;
  showScholarshipDetail: boolean = false;
  hideMyPassword: boolean = true;
  value = 'Clear me';
  disableNextFromPersonal: boolean;
  btnClickedOnFifth: boolean;
  showThanksDonation: boolean;

  strFutureVolunteer: string;
  strMailingList: string;
  strAddToMailingList: string;
  strMyDonation: string;
  strYouth: string;
  strScholarship: string;
  editMode: boolean;
  showCreditCardPayment: boolean = false;
  showBankPayment: boolean = false;

  states: USState[] =
    [
      {
        "display": "Alabama",
        "value": "AL"
      },
      {
        "display": "Alaska",
        "value": "AK"
      },
      {
        "display": "American Samoa",
        "value": "AS"
      },
      {
        "display": "Arizona",
        "value": "AZ"
      },
      {
        "display": "Arkansas",
        "value": "AR"
      },
      {
        "display": "California",
        "value": "CA"
      },
      {
        "display": "Colorado",
        "value": "CO"
      },
      {
        "display": "Connecticut",
        "value": "CT"
      },
      {
        "display": "Delaware",
        "value": "DE"
      },
      {
        "display": "District Of Columbia",
        "value": "DC"
      },
      {
        "display": "Federated States Of Micronesia",
        "value": "FM"
      },
      {
        "display": "Florida",
        "value": "FL"
      },
      {
        "display": "Georgia",
        "value": "GA"
      },
      {
        "display": "Guam",
        "value": "GU"
      },
      {
        "display": "Hawaii",
        "value": "HI"
      },
      {
        "display": "Idaho",
        "value": "ID"
      },
      {
        "display": "Illinois",
        "value": "IL"
      },
      {
        "display": "Indiana",
        "value": "IN"
      },
      {
        "display": "Iowa",
        "value": "IA"
      },
      {
        "display": "Kansas",
        "value": "KS"
      },
      {
        "display": "Kentucky",
        "value": "KY"
      },
      {
        "display": "Louisiana",
        "value": "LA"
      },
      {
        "display": "Maine",
        "value": "ME"
      },
      {
        "display": "Marshall Islands",
        "value": "MH"
      },
      {
        "display": "Maryland",
        "value": "MD"
      },
      {
        "display": "Massachusetts",
        "value": "MA"
      },
      {
        "display": "Michigan",
        "value": "MI"
      },
      {
        "display": "Minnesota",
        "value": "MN"
      },
      {
        "display": "Mississippi",
        "value": "MS"
      },
      {
        "display": "Missouri",
        "value": "MO"
      },
      {
        "display": "Montana",
        "value": "MT"
      },
      {
        "display": "Nebraska",
        "value": "NE"
      },
      {
        "display": "Nevada",
        "value": "NV"
      },
      {
        "display": "New Hampshire",
        "value": "NH"
      },
      {
        "display": "New Jersey",
        "value": "NJ"
      },
      {
        "display": "New Mexico",
        "value": "NM"
      },
      {
        "display": "New York",
        "value": "NY"
      },
      {
        "display": "North Carolina",
        "value": "NC"
      },
      {
        "display": "North Dakota",
        "value": "ND"
      },
      {
        "display": "Northern Mariana Islands",
        "value": "MP"
      },
      {
        "display": "Ohio",
        "value": "OH"
      },
      {
        "display": "Oklahoma",
        "value": "OK"
      },
      {
        "display": "Oregon",
        "value": "OR"
      },
      {
        "display": "Palau",
        "value": "PW"
      },
      {
        "display": "Pennsylvania",
        "value": "PA"
      },
      {
        "display": "Puerto Rico",
        "value": "PR"
      },
      {
        "display": "Rhode Island",
        "value": "RI"
      },
      {
        "display": "South Carolina",
        "value": "SC"
      },
      {
        "display": "South Dakota",
        "value": "SD"
      },
      {
        "display": "Tennessee",
        "value": "TN"
      },
      {
        "display": "Texas",
        "value": "TX"
      },
      {
        "display": "Utah",
        "value": "UT"
      },
      {
        "display": "Vermont",
        "value": "VT"
      },
      {
        "display": "Virgin Islands",
        "value": "VI"
      },
      {
        "display": "Virginia",
        "value": "VA"
      },
      {
        "display": "Washington",
        "value": "WA"
      },
      {
        "display": "West Virginia",
        "value": "WV"
      },
      {
        "display": "Wisconsin",
        "value": "WI"
      },
      {
        "display": "Wyoming",
        "value": "WY"
      }
    ]
  to_do_fu_kensJP: To_Do_Fu_KenJP[] =
    [
      {
        "display": "北海道",
        "value": "Hokkaido:北海道"
      },
      {
        "display": "青森県",
        "value": "Aomori:青森県"
      },
      {
        "display": "岩手県",
        "value": "Iwate:岩手県"
      },
      {
        "display": "宮城県",
        "value": "Miyagi:宮城県"
      },
      {
        "display": "秋田県",
        "value": "Akita:秋田県"
      },
      {
        "display": "山形県",
        "value": "Yamagata:山形県"
      },
      {
        "display": "福島県",
        "value": "Fukushima:福島県"
      },
      {
        "display": "茨城県",
        "value": "Ibaraki:茨城県"
      },
      {
        "display": "栃木県",
        "value": "Tochigi:栃木県"
      },
      {
        "display": "群馬県",
        "value": "Gunma:群馬県"
      },
      {
        "display": "埼玉県",
        "value": "Saitama:埼玉県"
      },
      {
        "display": "千葉県",
        "value": "Chiba:千葉県"
      },
      {
        "display": "東京都",
        "value": "Tokyo:東京都"
      },
      {
        "display": "神奈川県",
        "value": "Kanagawa:神奈川県"
      },
      {
        "display": "新潟県",
        "value": "Niigata:新潟県"
      },
      {
        "display": "富山県",
        "value": "Toyama:富山県"
      },
      {
        "display": "石川県",
        "value": "Ishikawa:石川県"
      },
      {
        "display": "福井県",
        "value": "Fukui:福井県"
      },
      {
        "display": "山梨県",
        "value": "Yamanashi:山梨県"
      },
      {
        "display": "長野県",
        "value": "Nagano:長野県"
      },
      {
        "display": "岐阜県",
        "value": "Gifu:岐阜県"
      },
      {
        "display": "静岡県",
        "value": "Shizuoka:静岡県"
      },
      {
        "display": "愛知県",
        "value": "Aichi:愛知県"
      },
      {
        "display": "三重県",
        "value": "Mie:三重県"
      },
      {
        "display": "滋賀県",
        "value": "Shiga:滋賀県"
      },
      {
        "display": "京都府",
        "value": "Kyoto:京都府"
      },
      {
        "display": "大阪府",
        "value": "Osaka:大阪府"
      },
      {
        "display": "兵庫県",
        "value": "Hyogo:兵庫県"
      },
      {
        "display": "奈良県",
        "value": "Nara:奈良県"
      },
      {
        "display": "和歌山県",
        "value": "Wakayama:和歌山県"
      },
      {
        "display": "鳥取県",
        "value": "Tottori:鳥取県"
      },
      {
        "display": "島根県",
        "value": "Shimane:島根県"
      },
      {
        "display": "岡山県",
        "value": "Okayama:岡山県"
      },
      {
        "display": "広島県",
        "value": "Hiroshima:広島県"
      },
      {
        "display": "山口県",
        "value": "Yamaguchi:山口県"
      },
      {
        "display": "徳島県",
        "value": "Tokushima:徳島県"
      },
      {
        "display": "香川県",
        "value": "Kagawa:香川県"
      },
      {
        "display": "愛媛県",
        "value": "Ehime:愛媛県"
      },
      {
        "display": "高知県",
        "value": "Kochi:高知県"
      },
      {
        "display": "福岡県",
        "value": "Fukuoka:福岡県"
      },
      {
        "display": "佐賀県",
        "value": "Saga:佐賀県"
      },
      {
        "display": "長崎県",
        "value": "Nagasaki:長崎県"
      },
      {
        "display": "熊本県",
        "value": "Kumamoto:熊本県"
      },
      {
        "display": "大分県",
        "value": "Oita:大分県"
      },
      {
        "display": "宮崎県",
        "value": "Miyazaki:宮崎県"
      },
      {
        "display": "鹿児島県",
        "value": "Kagoshima:鹿児島県"
      },
      {
        "display": "沖縄県",
        "value": "Okinawa:沖縄県"
      }
    ]
  to_do_fu_kensEN: To_Do_Fu_KenEN[] =
    [
      {
        "display": "Hokkaido",
        "value": "Hokkaido:北海道"
      },
      {
        "display": "Aomori",
        "value": "Aomori:青森県"
      },
      {
        "display": "Iwate",
        "value": "Iwate:岩手県"
      },
      {
        "display": "Miyagi",
        "value": "Miyagi:宮城県"
      },
      {
        "display": "Akita",
        "value": "Akita:秋田県"
      },
      {
        "display": "Yamagata",
        "value": "Yamagata:山形県"
      },
      {
        "display": "Fukushima",
        "value": "Fukushima:福島県"
      },
      {
        "display": "Ibaraki",
        "value": "Ibaraki:茨城県"
      },
      {
        "display": "Tochigi",
        "value": "Tochigi:栃木県"
      },
      {
        "display": "Gunma",
        "value": "Gunma:群馬県"
      },
      {
        "display": "Saitama",
        "value": "Saitama:埼玉県"
      },
      {
        "display": "Chiba",
        "value": "Chiba:千葉県"
      },
      {
        "display": "Tokyo",
        "value": "Tokyo:東京都"
      },
      {
        "display": "Kanagawa",
        "value": "Kanagawa:神奈川県"
      },
      {
        "display": "Niigata",
        "value": "Niigata:新潟県"
      },
      {
        "display": "Toyama",
        "value": "Toyama:富山県"
      },
      {
        "display": "Ishikawa",
        "value": "Ishikawa:石川県"
      },
      {
        "display": "Fukui",
        "value": "Fukui:福井県"
      },
      {
        "display": "Yamanashi",
        "value": "Yamanashi:山梨県"
      },
      {
        "display": "Nagano",
        "value": "Nagano:長野県"
      },
      {
        "display": "Gifu",
        "value": "Gifu:岐阜県"
      },
      {
        "display": "Shizuoka",
        "value": "Shizuoka:静岡県"
      },
      {
        "display": "Aichi",
        "value": "Aichi:愛知県"
      },
      {
        "display": "Mie",
        "value": "Mie:三重県"
      },
      {
        "display": "Shiga",
        "value": "Shiga:滋賀県"
      },
      {
        "display": "Kyoto",
        "value": "Kyoto:京都府"
      },
      {
        "display": "Osaka",
        "value": "Osaka:大阪府"
      },
      {
        "display": "Hyogo",
        "value": "Hyogo:兵庫県"
      },
      {
        "display": "Nara",
        "value": "Nara:奈良県"
      },
      {
        "display": "Wakayama",
        "value": "Wakayama:和歌山県"
      },
      {
        "display": "Tottori",
        "value": "Tottori:鳥取県"
      },
      {
        "display": "Shimane",
        "value": "Shimane:島根県"
      },
      {
        "display": "Okayama",
        "value": "Okayama:岡山県"
      },
      {
        "display": "Hiroshima",
        "value": "Hiroshima:広島県"
      },
      {
        "display": "Yamaguchi",
        "value": "Yamaguchi:山口県"
      },
      {
        "display": "Tokushima",
        "value": "Tokushima:徳島県"
      },
      {
        "display": "Kagawa",
        "value": "Kagawa:香川県"
      },
      {
        "display": "Ehime",
        "value": "Ehime:愛媛県"
      },
      {
        "display": "Kochi",
        "value": "Kochi:高知県"
      },
      {
        "display": "Fukuoka",
        "value": "Fukuoka:福岡県"
      },
      {
        "display": "Saga",
        "value": "Saga:佐賀県"
      },
      {
        "display": "Nagasaki",
        "value": "Nagasaki:長崎県"
      },
      {
        "display": "Kumamoto",
        "value": "Kumamoto:熊本県"
      },
      {
        "display": "Oita",
        "value": "Oita:大分県"
      },
      {
        "display": "Miyazaki",
        "value": "Miyazaki:宮崎県"
      },
      {
        "display": "Kagoshima",
        "value": "Kagoshima:鹿児島県"
      },
      {
        "display": "Okinawa",
        "value": "Okinawa:沖縄県"
      }
    ]
  prefixes: MyList[] =
    [
      {
        "display": "Mr.",
        "value": "Mr."
      },
      {
        "display": "Mrs.",
        "value": "Mrs."
      },
      {
        "display": "Ms.",
        "value": "Ms."
      },
      {
        "display": "Miss",
        "value": "Miss"
      },
      {
        "display": "Dr.",
        "value": "Dr."
      },
      {
        "display": "Prof.",
        "value": "Prof."
      },
      {
        "display": "Rev.",
        "value": "Rev."
      },
    ]
  occupationsEN: MyList[] =
    [
      {
        "display": "Working professional",
        "value": "workingProfessional"
      },
      {
        "display": "Pastor, Missionary",
        "value": "Minister"
      },
      {
        "display": "Student",
        "value": "Student"
      },
      {
        "display": "Other",
        "value": "Other"
      },
    ]
  occupationsJP: MyList[] =
    [
      {
        "display": "社会人",
        "value": "workingProfessional"
      },
      {
        "display": "牧師　宣教師",
        "value": "Minister"
      },
      {
        "display": "学生",
        "value": "Student"
      },
      {
        "display": "その他",
        "value": "Other"
      },
    ]

  user: User = {
    PasswordHash: null,
    Id: null,
    UserName: null,
    Email: null,
    FirstName: null,
    LastName: null,
    Password: null,
    Prefix: null,
    Shimei: null,
    Myoji: null,
    Street: null,
    Street2: null,
    City: null,
    State: null,
    Zip: null,
    Country: null,
    Yubin_Bango: null,
    To_Do_Fu_Ken: null,
    Shi_Gun_Ku: null,
    Cho_Son: null,
    Apartment_Etc: null,
    To_Do_Fu_Ken_JP: null,
    Shi_Gun_Ku_JP: null,
    Cho_Son_JP: null,
    Apartment_Etc_JP: null,
    Gender: null,
    DOB: null,
    TelNo: null,
    TimezoneName: null,
    UTCdiff: null,
    Token: null,
    Occupation: null,
    MemberStatus: null,
    Married: null,
    Christian: null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount: null,
    RegisteredDate: null,
    LastAccessedDate: null,
    FaithQuesCompletedDate:null,
    //From siteground
    LoginId: null,
    MiddleNameEN: null,
    MiddleNameJP: null,
    PrefixJP: null,
    OccupationDetail: null,
    Organization: null,
    LivingCountry: null,
    PhoneEN: null,
    PhoneMobileEN: null,
    PhoneJP: null,
    PhoneMobileJP: null,
    ChurchNameEN: null,
    ChurchNameJP: null,
    MemberType: null,
    MemberDetail: null,
    MemberMemo: null,
    Shokaisha: null,
    Reason: null,
    NewsLetters: null,
    StaffReports: null,
    OtherLetters: null,
    NewsLetterLanguage: null,
    BasicRoles: null,
    //Added new
    MigrateFromSiteGround: null,
    AdminAccess: null,
    JCFNAccess: null,
    SupportingMember: null,
    LastAddressUpdatedDate: null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };

  constructor(
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    public authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog,
    /**************************************************
     * Followings 2 componensts used to call function
     * in those componets from this class
    ***************************************************/
    private userEditOthersProfile: UserEditOthersProfileComponent,
    private requestAddressUpdate:RequestAddressUpdateComponent
  ) { }

  loginUserProfile: any = {};

  ngOnInit(): void {
    this.mySelectedLanguage = this.router.url.includes('EN') == true ? "EN" : "JP";
    this.firstFormGroup = this._formBuilder.group({
      myFirstName: ['', Validators.required],
      myLastName: ['', Validators.required],
      myShimei: [''],
      myMyoji: [''],
      myEmail: [null, [Validators.required, Validators.email]],
      myPassword: [null, [Validators.required, Validators.minLength(6)]],
      myTelNo: [''],
      myGender: ['', Validators.required],
      myDOB: ['', Validators.required],
      myStreet: ['', Validators.required],
      myStreet2: [''],
      myCity: ['', Validators.required],
      myState: ['', Validators.required],
      myZip: ['', Validators.required],
      myYubin_Bango: ['', Validators.required],
      myTo_Do_Fu_Ken: ['', Validators.required],
      myShi_Gun_Ku: ['', Validators.required],
      myCho_Son: ['', Validators.required],
      myApartment_Etc: [''],
      myCountry: ['', Validators.required],
    });
    this.thirdFormGroup = this._formBuilder.group({});
    this.fifthFormGroup = this._formBuilder.group({});
    /*#####################################################################
    # Admin Work seach for registrant
    ######################################################################*/
    if (sessionStorage.getItem("searchingMemberId") != null) {
      console.log("Take thier Id");
      this.editMode = true;
      var searchingRegistrantId = sessionStorage.getItem("searchingMemberId");
      this.loaded = false;
      /*****************************************
       *  A) User info for searching registrant
       ****************************************/
      this.userService.getUserProfileById(searchingRegistrantId).subscribe(result => {
        this.user = result;

        this.setMyCountry();
        this.firstFormGroup.get('myPassword').clearValidators();
        this.firstFormGroup.get('myPassword').updateValueAndValidity();
        if (this.user.Country != 'Japan') {
          this.firstFormGroup.get('myYubin_Bango').clearValidators();
          this.firstFormGroup.get('myYubin_Bango').updateValueAndValidity();
          this.firstFormGroup.get('myTo_Do_Fu_Ken').clearValidators();
          this.firstFormGroup.get('myTo_Do_Fu_Ken').updateValueAndValidity();
          this.firstFormGroup.get('myShi_Gun_Ku').clearValidators();
          this.firstFormGroup.get('myShi_Gun_Ku').updateValueAndValidity();
          this.firstFormGroup.get('myCho_Son').clearValidators();
          this.firstFormGroup.get('myCho_Son').updateValueAndValidity();
        } else if (this.user.Country == 'Japan') {
          this.firstFormGroup.get('myStreet').clearValidators();
          this.firstFormGroup.get('myStreet').updateValueAndValidity();
          this.firstFormGroup.get('myCity').clearValidators();
          this.firstFormGroup.get('myCity').updateValueAndValidity();
          this.firstFormGroup.get('myState').clearValidators();
          this.firstFormGroup.get('myState').updateValueAndValidity();
          this.firstFormGroup.get('myZip').clearValidators();
          this.firstFormGroup.get('myZip').updateValueAndValidity();
        }
      });
      this.loaded = true;
    } else {
      console.log("Take my username");
      var loginUserName = sessionStorage.getItem("loginUserName");
      if (loginUserName != null) {
        console.log('I am edit mode');
        this.editMode = true;
        this.loaded = false;
        /******************************
         *  A) User Info for login user
         ******************************/
        this.userService.getUserProfile(loginUserName).subscribe(result => {
          this.user = result;
console.log('hey2');
console.log(this.user.FaithQuesCompletedDate);

          sessionStorage.setItem("loginUserFirstName", this.user.FirstName);
          if (this.user.Country != 'Japan') {
            this.firstFormGroup.get('myYubin_Bango').clearValidators();
            this.firstFormGroup.get('myYubin_Bango').updateValueAndValidity();
            this.firstFormGroup.get('myTo_Do_Fu_Ken').clearValidators();
            this.firstFormGroup.get('myTo_Do_Fu_Ken').updateValueAndValidity();
            this.firstFormGroup.get('myShi_Gun_Ku').clearValidators();
            this.firstFormGroup.get('myShi_Gun_Ku').updateValueAndValidity();
            this.firstFormGroup.get('myCho_Son').clearValidators();
            this.firstFormGroup.get('myCho_Son').updateValueAndValidity();
          } else if (this.user.Country == 'Japan') {
            this.firstFormGroup.get('myStreet').clearValidators();
            this.firstFormGroup.get('myStreet').updateValueAndValidity();
            this.firstFormGroup.get('myCity').clearValidators();
            this.firstFormGroup.get('myCity').updateValueAndValidity();
            this.firstFormGroup.get('myState').clearValidators();
            this.firstFormGroup.get('myState').updateValueAndValidity();
            this.firstFormGroup.get('myZip').clearValidators();
            this.firstFormGroup.get('myZip').updateValueAndValidity();
          }
          this.setMyCountry();
          this.loaded = true;
        });
      } else {
        console.log('I am not edit mode');
        this.editMode = false;
        this.setMyCountry();
      }

      if (this.authService.isLoggedIn()) {
        this.firstFormGroup.get('myPassword').clearValidators();
        this.firstFormGroup.get('myPassword').updateValueAndValidity();
      }
    }
  }

  goSpecificStepForEdit(index: number, stepper: MatStepper) {
    stepper.selectedIndex = index - 1;
  }

  getMyLanguage(): string {
    return this.router.url.includes('EN') == true ? 'EN' : 'JP';
  }

  goFromFirstFormGroup() {
    this.clickedNextFromFirstFormGroup = true;
  }

  flagCountryOther:string;
  setMyCountry() {
    console.log('My Country: ' + this.user.Country)
    if (this.user.Country == null) {
      this.showUSAddress = false;
      this.showJapanAddress = false;
      this.showOtherAddress = false;
    } else if (this.user.Country == "United States") {
      this.showUSAddress = true;
      this.showJapanAddress = false;
      this.showOtherAddress = false;
    } else if (this.user.Country == "Japan") {
      this.showUSAddress = false;
      this.showJapanAddress = true;
      this.showOtherAddress = false;
    } else {
     // this.flagCountryOther = this.user.Country;
      this.showUSAddress = false;
      this.showJapanAddress = false;
      this.showOtherAddress = true;
    }
  }

  onEmailChangeEvent($event: Event) {
    this.userService.emailCheck(this.user.Email).subscribe(user => {
      var user = user;
      if (user != null) {
        const dialogRef = this.dialog.open(LoginMessageComponent, {
          width: '500px',
          height: '450px',
          data: {
            myLanguage: this.mySelectedLanguage
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          var redirectPage = result;
          if (result != null) {
            console.log("Confirmed Action: " + redirectPage);
          }
          // this.router.navigate(["/login/" +this.user.Email+"/"+ this.mySelectedLanguage]);
        })

      } else {
        console.log("This email is not taken yet.")
      }

    })
  }
  onUpdateOtherCountry(country:string){
    this.user.LivingCountry=country;
  }

  onChangeCountrySelection(event: MatRadioChange) {
    this.user.Country = event.value;
    this.user.LivingCountry = this.user.Country;
    console.log("My Country！:" + this.user.Country);
    if (this.user.Country == 'United States') {
      this.firstFormGroup.get('myYubin_Bango').clearValidators();
      this.firstFormGroup.get('myYubin_Bango').updateValueAndValidity();
      this.firstFormGroup.get('myTo_Do_Fu_Ken').clearValidators();
      this.firstFormGroup.get('myTo_Do_Fu_Ken').updateValueAndValidity();
      this.firstFormGroup.get('myShi_Gun_Ku').clearValidators();
      this.firstFormGroup.get('myShi_Gun_Ku').updateValueAndValidity();
      this.firstFormGroup.get('myCho_Son').clearValidators();
      this.firstFormGroup.get('myCho_Son').updateValueAndValidity();
      this.firstFormGroup.get('myStreet').setValidators(Validators.required);
      this.firstFormGroup.get('myStreet').updateValueAndValidity();
      this.firstFormGroup.get('myCity').setValidators(Validators.required);
      this.firstFormGroup.get('myCity').updateValueAndValidity();
      this.firstFormGroup.get('myState').setValidators(Validators.required);
      this.firstFormGroup.get('myState').updateValueAndValidity();
      this.firstFormGroup.get('myZip').setValidators(Validators.required);
      this.firstFormGroup.get('myZip').updateValueAndValidity();
      this.showUSAddress = true;
      this.showJapanAddress = false;
      this.showOtherAddress = false;
      // this.user.Street = null;
      // this.user.Street2 = null;
      // this.user.City = null;
      // this.user.State = null;
      // this.user.Zip = null;
    } else if (this.user.Country == 'Japan') {
      this.firstFormGroup.get('myYubin_Bango').setValidators(Validators.required);
      this.firstFormGroup.get('myYubin_Bango').updateValueAndValidity();
      this.firstFormGroup.get('myTo_Do_Fu_Ken').setValidators(Validators.required);
      this.firstFormGroup.get('myTo_Do_Fu_Ken').updateValueAndValidity();
      this.firstFormGroup.get('myShi_Gun_Ku').setValidators(Validators.required);
      this.firstFormGroup.get('myShi_Gun_Ku').updateValueAndValidity();
      this.firstFormGroup.get('myCho_Son').setValidators(Validators.required);
      this.firstFormGroup.get('myCho_Son').updateValueAndValidity();
      this.firstFormGroup.get('myStreet').clearValidators();
      this.firstFormGroup.get('myStreet').updateValueAndValidity();
      this.firstFormGroup.get('myCity').clearValidators();
      this.firstFormGroup.get('myCity').updateValueAndValidity();
      this.firstFormGroup.get('myState').clearValidators();
      this.firstFormGroup.get('myState').updateValueAndValidity();
      this.firstFormGroup.get('myZip').clearValidators();
      this.firstFormGroup.get('myZip').updateValueAndValidity();
      this.showUSAddress = false;
      this.showJapanAddress = true;
      this.showOtherAddress = false;
      // this.user.Street = null;
      // this.user.Street2 = null;
      // this.user.City = null;
      // this.user.State = null;
      // this.user.Zip = null;
    } else {
      this.firstFormGroup.get('myYubin_Bango').clearValidators();
      this.firstFormGroup.get('myYubin_Bango').updateValueAndValidity();
      this.firstFormGroup.get('myTo_Do_Fu_Ken').clearValidators();
      this.firstFormGroup.get('myTo_Do_Fu_Ken').updateValueAndValidity();
      this.firstFormGroup.get('myShi_Gun_Ku').clearValidators();
      this.firstFormGroup.get('myShi_Gun_Ku').updateValueAndValidity();
      this.firstFormGroup.get('myCho_Son').clearValidators();
      this.firstFormGroup.get('myCho_Son').updateValueAndValidity();
      this.firstFormGroup.get('myStreet').setValidators(Validators.required);
      this.firstFormGroup.get('myStreet').updateValueAndValidity();
      this.firstFormGroup.get('myCity').setValidators(Validators.required);
      this.firstFormGroup.get('myCity').updateValueAndValidity();
      this.firstFormGroup.get('myState').setValidators(Validators.required);
      this.firstFormGroup.get('myState').updateValueAndValidity();
      this.firstFormGroup.get('myZip').setValidators(Validators.required);
      this.firstFormGroup.get('myZip').updateValueAndValidity();
      this.showUSAddress = false;
      this.showJapanAddress = false;
      this.showOtherAddress = true;
      // this.user.Street = null;
      // this.user.Street2 = null;
      // this.user.City = null;
      // this.user.State = null;
      // this.user.Zip = null;
    }
  }

  onEnterMyCountry() {
    console.log('My Country: ' + this.user.Country)
  }

  savingPersonalInfo() {
    if (this.editMode != true) {
      console.log('This is not edit mode, creating user');
      this.createUser()
      /************************************************
       * New user login for next conference module
       * customized for  them  based on thier timezone
       * **********************************************/

console.log('hey');
console.log(this.user.FaithQuesCompletedDate);

      this.authService.setLoginUserName(this.user.Email);
      this.userEditOthersProfile.onClickClose();
      //1. Get Token from Host
      console.log("email: " + this.user.Email);
      console.log("password: " + this.user.Password);
      setTimeout(() => {
        this.authService.login(this.user.Email, this.user.Password).subscribe(token => {
          console.log('**** TokenReturning result in Login Page ' + token + " ****");
          this.router.navigate(["/members/myProfile/" + this.mySelectedLanguage]);
        });
      },
        1000);
    } else {
      console.log('This is edit mode, updating user');
      this.updateUser();
      /*********************************************************
       * Close popup window when user finished updating address
       *********************************************************/
       setTimeout(() => {
        this.userEditOthersProfile.onClickClose();
        this.requestAddressUpdate.getUpdatedUserById(this.user.Email);
      },
        1000);
    }
  }

  createUser() {

//alert("Id: "+ this.user.Id);

    this.loaded = false;
    // 1. Save User Info
    console.log('Going to create user profile')
    this.userService.createUser(this.user).subscribe(
      data => {
        //Success saving personal info.
        this.myMessage = this.getMyLanguage()=="EN"?"Account Created successfully":"アカウントが作られました。";
        this.openSnackBar('success', 'close');
        this.loaded = true;
      //  this.login();
      },
      error => {
        this.myMessage = this.getMyLanguage()=="EN"?"Data save failed.":"問題が生じアカウントが作られませんでした。";
        this.openSnackBar('error', 'close');
        this.loaded = true;
      }
    )
  }

  updateUser() {
    this.loaded = false;
    // 1. Save User Info
    this.userService.updateUser(this.user).subscribe(
      data => {
        console.log('success in updating user');
        this.myMessage = this.getMyLanguage()=="EN"?"User updated successfully.":"アカウント情報が更新されました。";
        this.openSnackBar('success', 'close');
        this.loaded = true;
      },
      error => {
        this.myMessage = this.getMyLanguage()=="EN"?"Data save failed.":"問題が生じアカウントが更新されませんでした。";
        this.openSnackBar('error', 'close');
        this.loaded = true;

      }
    )
  }

  login() {
    this.loaded = false;
    //1. Get Token from Host
    this.authService.login(this.user.Email, this.user.Password).subscribe(
      token => {
        console.log('**** TokenReturning result in Login Page ' + token + " ****");
        //this.router.navigate(["/members/myProfile/" + this.getMyLanguage()]);
        this.router.navigate(["/members/acceptTerms/" + this.getMyLanguage()]);
      },
      error => {
        this.myMessage = this.getMyLanguage()=="EN"?"Login failed.":"ログインに失敗しました。";
        this.openSnackBar('error', 'close');
      })
  }

  sendConfirmationEmail() {
    this.btnClickedOnFifth = true;
    this.userService
      .sendConfirmationEmail(this.user)
      .subscribe(
        data => {

        },
        error => {
          this.myMessage = this.getMyLanguage()=="EN"?"Data save failed.":"問題が生じ、データが保存されませんでした。";
          this.openSnackBar('error', 'close');
        }
      )
  }

  onClickGoingDonationSite() {
    this.showThanksDonation = true;
  }

  onChangeGenderSelection($event: MatRadioChange) {
    console.log("My Gender:" + $event.value);
    this.user.Gender = $event.value;
  }

  onChangeDOB(event: MatDatepickerInputEvent<Date>) {
    //  console.log( ${event.value}`);
    console.log(event.value)
    this.user.DOB = event.value;
  }

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 5000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 20000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }
}
