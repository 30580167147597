<div class="myBody">
  <div class="myHeaderBase d-flex justify-content-center">
    <div class="myHeaderCore">
      <div>
        <img src='../../../../../assets/images/thumbnail_eclogo.png' class="ecLogo">
      </div>
    </div>
  </div>
  <div class="myMain">
    <div clas="myMainLeft">
      <div class="myMainHalfTop">
        <!-- Left Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Left Bottom -->
      </div>
    </div>
    <div class="myMainCenter">
      <div class="myMainHalfTop d-flex justify-content-center">
        <!-- Main Top -->

        <mat-card class="mainCard b-radius">
          <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
          <div *ngIf="!loaded">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>


          <form
            [formGroup]="myFormGroup"
            (ngSubmit)="onSubmit()"
            >

            <div class="float-end stepNumber">1/4</div>
            <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Personal Information':
              '個人情報'}}
              <i class="stepTitleSub">
                {{this.router.url.includes('EN')==true?'* ... Required': '* ... 必須項目'}}
              </i>
            </h2>

            <div class="stepTitle2nd">
              {{this.router.url.includes('EN')==true?'E-Mail':
              'Eメール'}}
            </div>
            <mat-form-field appearance="outline" class="registrationInputForm">
              <mat-label>
                {{this.router.url.includes('EN')==true?'Email': 'Eメール *'}}
              </mat-label>
              <input matInput placeholder="pat@example.com" formControlName="myEmail" [readonly]='disableTextbox'
                (change)="onEmailChangeEventEC($event)" class="registrationInputBox" >
                <mat-error *ngIf="myFormGroup.get('myEmail').hasError('required')">
                {{this.router.url.includes('EN')==true?'Email is required': '未入力'}}
              </mat-error>
              <mat-error *ngIf="myFormGroup.get('myEmail').hasError('email')">
                {{this.router.url.includes('EN')==true?'Invalid Email format': 'Eメールフォーマットが正しくありません'}}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="!this.authService.isLoggedIn()"
            appearance="outline" hintLabel="Min 8 characters" class="registrationInputForm">
              <mat-label>
                {{this.router.url.includes('EN')==true?'Password': 'パスワード'}}
              </mat-label>



              <input matInput placeholder="" formControlName="myPassword"
                [type]="hideMyPassword ? 'password' : 'text'"  #myInputPassword
                class="registrationInputBox">
              <mat-hint align="end">{{myInputPassword.value?.length || 0}} characters</mat-hint>
              <button mat-icon-button matSuffix (click)="hideMyPassword = !hideMyPassword"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideMyPassword">
                <mat-icon>{{hideMyPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error *ngIf="myFormGroup.get('myPassword').hasError('required')">
                {{this.router.url.includes('EN')==true?'Password is required': '未入力'}}
              </mat-error>
              <mat-error *ngIf="myFormGroup.get('myPassword').hasError('minlength')">
                {{this.router.url.includes('EN')==true?'Need 8 characters or more': '8文字以上必要です'}}
              </mat-error>
              <mat-error *ngIf="myFormGroup.get('myPassword').hasError('pattern')">
                {{this.router.url.includes('EN')==true?'Must contain uppercase, lowercase, digit ': ' 大文字、小文字、数字が必要です'}}
              </mat-error>
            </mat-form-field>




            <br>
            <div class="stepTitle2nd">
              {{this.router.url.includes('EN')==true?'Name':
              '氏名'}}
            </div>
            <mat-form-field appearance="outline" class="registrationInputForm">
              <mat-label>
                {{this.router.url.includes('EN')==true?'First Name *': '名　ローマ字 *'}}
              </mat-label>
              <input matInput placeholder="" formControlName="myFirstName"
                class="registrationInputBox">
              <mat-error>
                {{this.router.url.includes('EN')==true?'First Name is required': '未入力'}}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="registrationInputForm">
              <mat-label>
                {{this.router.url.includes('EN')==true?'Last Name *': '姓　ローマ字 *'}}
              </mat-label>
              <input matInput placeholder="" formControlName="myLastName"
                class="registrationInputBox">
              <mat-error>
                {{this.router.url.includes('EN')==true?'Last Name is required': '未入力'}}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="registrationInputForm">
              <mat-label>
                {{this.router.url.includes('EN')==true?'Kanji- Last Name': '姓　漢字'}}
              </mat-label>
              <input matInput placeholder="" formControlName="myMyoji"
                class="registrationInputBox">
            </mat-form-field>


            <mat-form-field appearance="outline" class="registrationInputForm">
              <mat-label>
                {{this.router.url.includes('EN')==true?'Kanji- First Name': '名　漢字'}}
              </mat-label>
              <input matInput placeholder="" formControlName="myShimei"
                class="registrationInputBox">
            </mat-form-field>


          </form>
          <ng-template #loading>
            Loading User...
          </ng-template>



            <div class=" position-absolute bottom-0 start-50 translate-middle-x">
              <div class="d-flex justify-content-center">
                <button type="button"  class="btn btnProcess" (click)="onClickGoBack()">
                  {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                </button>
                <button  type="button"  [disabled]="!myFormGroup.valid" class="btn btnProcess" (click)="onClickGoForward()">
                  {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                </button>
              </div>
              <div class="d-flex justify-content-center">
                <button type="button" class="btn link btnCancel" (click)="cancelProcess()">
                  {{this.router.url.includes('EN')==true?'Cancel': 'キャンセル'}}
                </button>
              </div>
            </div>
        </mat-card>
      </div>
      <div class="myMainHalfBottom">
        <!-- Main Bottom -->
      </div>
    </div>
    <div class="myMainRight">
      <div class="myMainHalfTop">
        <!-- Right Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Right Bottom -->
      </div>
    </div>
  </div>
  <!-- <footer>footer</footer> -->
</div>
