import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';;
import { Router, NavigationExtras } from "@angular/router";
import { MatRadioChange } from '@angular/material/radio';
import { User } from 'src/app/models/user';
import { CancelOrSaveConfirmationComponent } from 'src/app/components/dialog/cancel-or-save-confirmation/cancel-or-save-confirmation.component';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { GRCConference, GRCRelatedPerson, GRCVolunteerSelection } from 'src/app/models/conference/GRCConference';
import { GrcRegistrationService } from 'src/app/services/conference/grc/grc-registration.service';
import { MyAccountEditComponent } from 'src/app/components/dialog/EC/my-account-edit/my-account-edit.component';
import { RegistrationConferenceEmbedComponent } from 'src/app/components/dialog/EC/registration-conference-embed/registration-conference-embed.component';
import { MyAccountEditGRCComponent } from 'src/app/components/dialog/GRC/my-account-edit-grc/my-account-edit-grc.component';


@Component({
  selector: 'app-terms-of-participation-grc',
  templateUrl: './terms-of-participation-grc.component.html',
  styleUrls: ['./terms-of-participation-grc.component.css']
})
export class TermsOfParticipationGRCComponent implements OnInit {
  loaded:boolean=true;
  myFormGroup: FormGroup;
  myMessage:string;
  showYouthInfo:boolean=false;
  showGuardianInfo:boolean=false;
  showEmergencyInfo:boolean=false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  user: User = {
    PasswordHash:null,
    Id:null,
    UserName:null,
    Email:null,
    FirstName:null,
    LastName:null,
    Password:null,
    Prefix:null,
    Shimei:null,
    Myoji:null,
    Street:null,
    Street2:null,
    City:null,
    State:null,
    Zip:null,
    Country:null,
    Yubin_Bango:null,
    To_Do_Fu_Ken:null,
    Shi_Gun_Ku:null,
    Cho_Son:null,
    Apartment_Etc:null,
    To_Do_Fu_Ken_JP:null,
    Shi_Gun_Ku_JP:null,
    Cho_Son_JP:null,
    Apartment_Etc_JP:null,
    Gender:null,
    DOB:null,
    TelNo:null,
    TimezoneName:null,
    UTCdiff:null,
    Token:null,
    Occupation:null,
    MemberStatus:null,
    Married:null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount:null,
    RegisteredDate:null,
    LastAccessedDate:null,
    //From siteground
    LoginId:null,
    MiddleNameEN:null,
    MiddleNameJP:null,
    PrefixJP:null,
    OccupationDetail:null,
    Organization:null,
    LivingCountry:null,
    PhoneEN:null,
    PhoneMobileEN:null,
    PhoneJP:null,
    PhoneMobileJP:null,
    ChurchNameEN:null,
    ChurchNameJP:null,
    MemberType:null,
    MemberDetail:null,
    MemberMemo:null,
    Shokaisha:null,
    Reason:null,
    NewsLetters:null,
    StaffReports:null,
    OtherLetters:null,
    NewsLetterLanguage:null,
    BasicRoles:null,
    //Added new
    MigrateFromSiteGround:null,
    AdminAccess:null,
    JCFNAccess:null,
    SupportingMember:null,
    LastAddressUpdatedDate:null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };
  relatedPerson:GRCRelatedPerson={
    Id:null,
    RelatedId:null,
    Email:null,
    FirstNameRelated:null,
    LastNameRelated:null,
    MyojiRelated:null,
    ShimeiRelated:null,
    DOBRelated:null,
    GenderRelated:null
  };
  relatedPersons:GRCRelatedPerson[]=[];
  volunteerSelections:GRCVolunteerSelection[]=[];
  conference= new GRCConference();
  // conference: EC22Conference= {
  //   EC22ConferenceId:0,
  //   UserId: null,
  //   Email: null,
  //   UserName: null,
  //   FirstName: null,
  //   LastName: null,
  //   //Program
  //   ProgramType: null,
  //   SmallGroupLanguage: null,
  //   AttendJapanRegionalMeeting:null,
  //   JapanRegionalMeeting:null,
  //   ApplyEnglishPartner:null,
  //   //Payment
  //   ConferenceFee: null,
  //   ConferenceDiscountAmount: null,
  //   ConferenceFeePaidAmount: null,
  //   ConferenceFeePaidDate: null,
  //   CouponApproved: null,
  //   ApplyScholarship: null,
  //   ApplyingScholarshipAmount: null,
  //   ApprovedScholarshipAmount: null,
  //   ApplyingReason: null,
  //   //Others\
  //   Volunteers:null,
  //   Student:null,
  //   ApplyCounseling: null,
  //   MySleepingBag:null,
  //   BringExtraSleepingBag:null,
  //   CountExtraSleepingBag:null,
  //   NeedCommingBus:null,
  //   CommingBusDeparturePlace:null,
  //   CommingBusDepartureDateTime:null,
  //   CommingAirline:null,
  //   CommingFlightNo:null,
  //   DepartureCity:null,
  //   CommingInternationalDomestic:null,
  //   ArrivalDateTime:null,
  //   NeedReturningBus:null,
  //   ReturningBusDepartureDateTime:null,
  //   ReturningAirline:null,
  //   ReturningFlightNo:null,
  //   ReturningCity:null,
  //   ReturningBusDeparturePlace:null,
  //   ReturningInternationalDomestic:null,
  //   DepartureDateTime:null,
  //   RoomInfo:null,
  //   Couple:null,
  //   Youth:null,
  //   YouthGrade: null,
  //   GuardianFirstName: null,
  //   GuardianLastName: null,
  //   GuardianMyoji: null,
  //   GuardianShimei: null,
  //   GuardianEmail: null,
  //   GuardianTelNo: null,
  //   TermsOfParticipation: null,
  //   TermsOfParticipationParent: null,
  //   TermsOfParticipationDate: null,
  //   TermsOfParticipationDateParent: null,
  //   RegisteredDate: null,
  //   UpdatedDate: null,

  //   AdminAccess: null,
  //   ECCommitteeAccess: null,
  //   Memo: null,
  //   Dec26:null,
  //   Dec27:null,
  //   Dec28:null,
  //   Dec29:null,
  //   Dec30:null,
  //   Dec31:null,
  //   KidGroup1:null,
  //   KidGroup2:null,
  //   KidGroup3:null,
  //   KidGroup4:null,
  //   myRelatedPersons: this.relatedPersons,
  //   myVolunteerSelections:this.volunteerSelections,
  //   BusComingConfirmation:null,
  //   BusReturningConfirmation:null,
  //   TermsOfParticipationVenue:null,
  //   TermsOfParticipationDateVenue:null,
  //   TermsOfParticipationYouth:null,
  //   TermsOfParticipationDateYouth:null,
  //   TermsOfParticipationYouthParent:null,
  //   TermsOfParticipationDateYouthParent:null,
  //   EmergencyContactFirstName:null,
  //   EmergencyContactLastName:null,
  //   EmergencyContactEmail:null,
  //   EmergencyContactTelNo:null,
  //   ApplicationFee:null,
  //   ApplicationFeePaidDate:null,
  //   BalanceAmount:null,
  //   BalanceAmountPaidDate:null
  // }
  constructor(
    private _snackBar: MatSnackBar,
    public router: Router,
    private _formBuilder: FormBuilder,
    private grcRegistrationService : GrcRegistrationService,
    private dialog: MatDialog,
  ) { }
  mySearch: any = {};
  ngOnInit(): void {
    this.myFormGroup = this._formBuilder.group({
      myTermsOfParticipation: [null, Validators.required],
      myTermsOfParticipationDate: [null, Validators.required],
      // myTermsOfParticipationVenue: [null, Validators.required],
      // myTermsOfParticipationDateVenue: [null, Validators.required],
      // myTermsOfParticipationYouth: [null],
      // myTermsOfParticipationDateYouth: [null],
      // myTermsOfParticipationYouthParent: [null],
      // myTermsOfParticipationDateYouthParent: [null],
      // myGuardianFirstName: [''],
      // myGuardianLastName: [''],
      // myGuardianEmail: [''],
      // myGuardianTelNo:[''],
      // myEmergencyContactFirstName: [''],
      // myEmergencyContactLastName: [''],
      // myEmergencyContactEmail: [''],
      // myEmergencyContactTelNo:[''],
      // myGuardianTermsOfParticipation: [null],
      // myGuardianTermsOfParticipationDate: [null],
    });

    if (sessionStorage.getItem("myGRCSearchingUserIdGRC") != null) {
      /*************************************************
       *  A) User info for searching registrant by admin
       *************************************************/
       this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnConferenceAdminGRC"));
       if (this.mySearch != null) {
         this.conference = this.mySearch.myEditingConference;
         this.patchModelValue(this.conference);
         this.setMyYouth();
         this.loaded = true;
       }else{
        this.loaded = true;
       }
    } else {
    /*####################################################################################
    # Get conference info from previous page for myself
    #####################################################################################*/
    this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnConference"));
      if (this.mySearch != null) {
        this.conference = this.mySearch.myEditingConference;
        this.patchModelValue(this.conference);
        this.setMyYouth();
       //this.loaded = true;
      }else{
      }
    }
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  patchModelValue(model:GRCConference){
    this.myFormGroup.patchValue({
      myTermsOfParticipation: model.TermsOfParticipation,
      myTermsOfParticipationDate: model.TermsOfParticipationDate!=null?model.TermsOfParticipationDate:new Date(),
      myEmergencyContactFirstName: model.EmergencyContactFirstName,
      myEmergencyContactLastName: model.EmergencyContactLastName,
      myEmergencyContactEmail: model.EmergencyContactEmail,
      myEmergencyContactTelNo:model.EmergencyContactTelNo
    });
  }

  onChangeConsent(value:boolean){
    this.conference.TermsOfParticipation=value;
  }
  onChangeConsentVenue(value:boolean){

  }
  onChangeConsentYouth(value:boolean){

  }

  onChangeYouth($event:MatRadioChange){
    /**********************************************************
     *  I don't know but MatRadioChange returns always string,
     * so it need to be converted to boolean
    ***********************************************************/
  }

  setMyYouth(){

  }

  onSubmit(){}

  getFormValue() {
    //this.conference.Youth = this.myFormGroup.get('myYouth').value; *** DO NOT Include because this is string not boolean
    this.conference.TermsOfParticipation = this.myFormGroup.get('myTermsOfParticipation').value;
    this.conference.TermsOfParticipationDate =  this.myFormGroup.get('myTermsOfParticipationDate').value;
    // this.conference.EmergencyContactFirstName  =  this.myFormGroup.get('myEmergencyContactFirstName').value;
    // this.conference.EmergencyContactLastName  =  this.myFormGroup.get('myEmergencyContactLastName').value;
    // this.conference.EmergencyContactEmail  =  this.myFormGroup.get('myEmergencyContactEmail').value;
    // this.conference.EmergencyContactTelNo  =  this.myFormGroup.get('myEmergencyContactTelNo').value;
 }

  savingConferenceInfo(){
    this.getFormValue();
    console.log("TermsOfParticipation: "+this.conference.TermsOfParticipation);
    console.log("TermsOfParticipationDate: "+this.conference.TermsOfParticipationDate);
    // console.log("EmergencyContactFirstName: "+this.conference.EmergencyContactFirstName);
    // console.log("EmergencyContactLastName: "+this.conference.EmergencyContactLastName);
    // console.log("EmergencyContactEmail: "+this.conference.EmergencyContactEmail);
    // console.log("EmergencyContactTelNo: "+ this.conference.EmergencyContactTelNo);

    this.loaded = false;
    // 1. Save Conference Info
    this.grcRegistrationService.upsertConference(this.conference).subscribe(
      data => {
        this.myMessage = this.getMyLanguage()=="EN"?"Conference info saved successfully.":"カンファレンス情報が保存されました。";
        this.openSnackBar('success', 'close');
        this.loaded = true;

        if(this.router.url.includes('/registration/GRC/')){
          this.router.navigate(["/registration/GRC/myAccountGRC/"+this.getMyLanguage()]);
        }else{
          this.dialog.closeAll();
          this.openForwardEmbed();
        }

      },
      error => {
        this.myMessage = this.getMyLanguage()=="EN"?"failed in saving Conference data.":"問題が生じカンファレンス情報が保存できませんでした。";
        this.openSnackBar('error', 'close');
        this.loaded = true;
      }
    )
    //Updateが終わった自分のカンファレンス情報セッションを消しておく
    sessionStorage.removeItem("myWorkingOnConference");
    // アドミで使った調べ中のユーザーの情報セッションは消しておく
    if (sessionStorage.getItem("myECSearchingUserId") != null) {
      sessionStorage.removeItem("myWorkingOnConferenceAdmin");
    }
  }
  openForwardEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktoop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ userId: this.conference.UserId };
    const dialogRef = this.dialog.open(MyAccountEditGRCComponent,dialogConfig);


    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  onClickGoBack(){
    if(this.router.url.includes('/registration/GRC/')){
      this.router.navigate(["/registration/GRC/summaryConferenceGRC/" +this.getMyLanguage()]);
    }else{
      this.dialog.closeAll();
      this.openBackEmbed();
    }
  }
  openBackEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ myPage: 'summaryConference' };
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  setConferenceInfoInSessionStorage(){
    sessionStorage.removeItem("myConferenceInfo");
    sessionStorage.setItem("myConferenceInfo",
    JSON.stringify({ myConferenceSearch: this.conference })
    );
  }

  onChangeGuardianConsent(value:boolean){

  }

  cancelProcess(){
    this.getFormValue();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='210px'; //Desktop
    dialogConfig.maxWidth='370px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ conference: this.conference };
    const dialogRef = this.dialog.open(CancelOrSaveConfirmationComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
      //Retreive updated registration Info.
      //this.ngOnInit();
      let navigationExtras: NavigationExtras = {
        queryParams:this.conference
      };
      if(result!='cancel'){
        if(this.router.url.includes('/registration/GRC/')){
          this.router.navigate(["/registration/GRC/myAccountGRC/" +this.getMyLanguage()], navigationExtras);
        }else{
          this.dialog.closeAll();
          this.openMyAccountEdit();
        }
        }
    })
  }

  openMyAccountEdit(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ userId: this.conference.UserId };
    const dialogRef = this.dialog.open(MyAccountEditComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 6000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 20000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }
}

