<div class="WelcomeBase">
 <form [formGroup]="firstFormGroup">
   <div class="btnBack">
     <button type="button" class="btn btn-link" (click)="onClickBack()">
       <i class="fas fa-arrow-circle-left fa-2x"></i>
     </button>
   </div>
   <mat-card class="WelcomeWindow">
     <div *ngIf="showMessageUserFound" class="divMessageUserFound d-flex justify-content-center">
       {{this.router.url.includes('EN')==true?'Found your profile. Please enter your 
       password.':'該当データが見つかりました。パスワードを入力してください'}}
     </div>
     <div class="d-flex justify-content-center">
       <div>
         <i class="fa fa-lock fa-2x"></i>&nbsp;
         <mat-form-field appearance="fill" class="registrationInputForm">
           <mat-label>
             {{this.router.url.includes('EN')==true?'Password': 'パスワード'}}
           </mat-label>
           <input matInput placeholder="" formControlName="myPassword" [(ngModel)]="user.Password" type="password" autofocus 
             #myInputPassword class="registrationInputBox">
         </mat-form-field>
       </div>
     </div>
     <div *ngIf="!loaded">
       <mat-progress-bar mode="indeterminate"></mat-progress-bar>
     </div>
     <div *ngIf="!loaded" class="progressMessage">
       {{this.router.url.includes('EN')==true?'Loading ...':'プロセス中 ...'}}
     </div>
     <div class="divUpdateButton  d-flex justify-content-center">
       <button mat-button (click)="onClickLogin()" class="btnSearchEmail" cdkFocusInitial>
         {{this.router.url.includes('EN')==true?'Login':'ログイン'}}
       </button>
     </div>
     <button mat-button (click)="onClickForgotPassword()" class="btn btn-link">
       {{this.router.url.includes('EN')==true?'Forgot Password?': 'パスワードを忘れた場合'}}
     </button>
   </mat-card>
 </form>
</div>
<div class="memoForDevelopper">
 login-enter-password
 </div>