<div class="myBase">
  <div class="myBackgroundLayer">
    <div class="myContainer">

      <div class="truckingTitle">
        <div class="col-md-6">
          <h2><i class="fas fa-users fa-2x"></i>&nbsp; Members</h2>
        </div>
      </div>

      <form #mySearchForm="ngForm" (ngSubmit)="onSearchSubmit(mySearchForm)">
        <div class="divSearchBox d-flex">
          <div class="row me-auto">
            <div class="col searchItem">
              <mat-form-field class="containerSearchMd" appearance="outline">
                <mat-label>First Name</mat-label>
                <input [(ngModel)]="memberList.FirstName" name="FirstName" matInput placeholder="First Name"
                  class="matInputBox" />
              </mat-form-field>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearchMd" appearance="outline">
                <mat-label>Last Name</mat-label>
                <input [(ngModel)]="memberList.LastName" name="LastName" matInput placeholder="Last Name"
                  class="matInputBox" />
              </mat-form-field>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearchLg" appearance="outline">
                <mat-label>Email</mat-label>
                <input [(ngModel)]="memberList.Email" name="Email" matInput placeholder="Email" class="matInputBox" />
              </mat-form-field>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearch" appearance="outline">
                <mat-label>Genders</mat-label>
                <mat-select name="SelectedGender" [(ngModel)]="memberList.Gender" class="matInputBox"
                  (selectionChange)="onSelectGender($event.value)">
                  <mat-option *ngFor="let gender of genders" [value]="gender.value" class="matInputBox">
                    {{ gender.display }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearchSm" appearance="outline">
                <mat-label>Min Age</mat-label>
                <input matInput type="number" name='MinAge' [(ngModel)]="memberList.MinAge" class="matInputBox">
              </mat-form-field>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearchSm" appearance="outline">
                <mat-label>Max Age</mat-label>
                <input matInput type="number" name='MaxAge' [(ngModel)]="memberList.MaxAge" class="matInputBox">
              </mat-form-field>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearch" appearance="outline">
                <mat-label>Language</mat-label>
                <mat-select name="SelectedNewsLetterLanguages" [(ngModel)]="memberList.NewsLetterLanguage"
                  class="matInputBox" (selectionChange)="onSelectNewsLetterLanguage($event.value)">
                  <mat-option *ngFor="let newsLetterLanguage of newsLetterLanguages" [value]="newsLetterLanguage.value"
                    class="matInputBox">
                    {{ newsLetterLanguage.display }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearch" appearance="outline">
                <mat-label>Country</mat-label>
                <mat-select name="SelectedCountry" [(ngModel)]="memberList.Country" class="matInputBox"
                  (selectionChange)="onSelectCountry($event.value)">
                  <mat-option *ngFor="let country of countries" [value]="country.value" class="matInputBox">
                    {{ country.display }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearch" appearance="outline">
                <mat-label>都道府県</mat-label>
                <mat-select name="SelectedToDoFuKen" [(ngModel)]="memberList.To_Do_Fu_Ken" class="matInputBox"
                  (selectionChange)="onSelectToDoFuKen($event.value)">
                  <mat-option *ngFor="let to_do_fu_kenJP of to_do_fu_kensJP" [value]="to_do_fu_kenJP.value"
                    class="matInputBox">
                    {{ to_do_fu_kenJP.display }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearchMd" appearance="outline">
                <mat-label>市郡区</mat-label>
                <input [(ngModel)]="memberList.Shi_Gun_Ku" name="Shi_Gun_Ku" matInput placeholder="市郡区"
                  class="matInputBox" />
              </mat-form-field>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearchMd" appearance="outline">
                <mat-label>US State</mat-label>
                <mat-select name="SelectedState" [(ngModel)]="memberList.State" class="matInputBox"
                  (selectionChange)="onSelectState($event.value)">
                  <mat-option *ngFor="let state of states" [value]="state.value" class="matInputBox">
                    {{ state.display }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearchMd" appearance="outline">
                <mat-label>City</mat-label>
                <input [(ngModel)]="memberList.City" name="City" matInput placeholder="City" class="matInputBox" />
              </mat-form-field>
            </div>
            <!-- <div class="col searchItem">
                            <mat-form-field class="containerSearchLg" appearance="outline">
                                <mat-label>Membership:Old DB</mat-label>
                                <mat-select name="SelectedMemberType" [(ngModel)]="memberList.MemberType"
                                    class="matInputBox" (selectionChange)="onSelectMemberType($event.value)">
                                    <mat-option *ngFor="let memberType of memberTypes" [value]="memberType.value"
                                        class="matInputBox">
                                        {{ memberType.display }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> -->
            <div class="col searchItem">
              <mat-form-field class="containerSearchLg" appearance="outline">
                <mat-label>Membership:New DB</mat-label>
                <mat-select name="SelectedMembershipType" [(ngModel)]="memberList.MyMembershipTypes" multiple
                  class="matInputBox" (selectionChange)="onSelectMembershipType($event)">
                  <mat-option *ngFor="let listMembershipType of listMembershipTypes" [value]="listMembershipType"
                    class="matInputBox">
                    {{ listMembershipType.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col searchItem">
              <mat-form-field class="containerSearchLg" appearance="outline">
                <mat-label>News Letters</mat-label>
                <mat-select name="SelectedNewsLetters" [(ngModel)]="memberList.MyNewsLetters" multiple
                  class="matInputBox" (selectionChange)="onSelectNewsLetters($event)">
                  <mat-option *ngFor="let listNewsLetter of listNewsLetters" [value]="listNewsLetter"
                    class="matInputBox">
                    {{ listNewsLetter.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col searchItem">
              <mat-form-field class="containerSearchMd" appearance="outline">
                <mat-label>Receiving Method</mat-label>
                <mat-select name="SelectedReceivingMethod" [(ngModel)]="memberList.ReceivingMethod" class="matInputBox"
                  (selectionChange)="onSelectReceivingMethod($event.value)">
                  <mat-option *ngFor="let receivingMethod of receivingMethods" [value]="receivingMethod.value"
                    class="matInputBox">
                    {{ receivingMethod.display }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col searchItem">
              <mat-form-field class="containerSearch" appearance="outline">
                <mat-label>Active Status</mat-label>
                <mat-select name="SelectedActiveStatus" [(ngModel)]="memberList.ActiveStatus" class="matInputBox"
                  (selectionChange)="onSelectActiveStatus($event.value)">
                  <mat-option *ngFor="let activeStatus of activeStatuses" [value]="activeStatus.value"
                    class="matInputBox">
                    {{ activeStatus.display }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>


            <!-- <div class="col searchItem">
                            <mat-form-field class="containerSearchMd EC20BeyondReunionOuter" appearance="outline">
                                <mat-label>EC20 Reunion Participate</mat-label>
                                <mat-select name="SelectedEC20BeyondReunionAttend" [(ngModel)]="memberList.Attend"
                                    class="matInputBox  EC20BeyondReunionInner"
                                    (selectionChange)="onSelectEC20BeyondReunionAttend($event.value)">
                                    <mat-option *ngFor="let ec20ReunionAttend of ec20ReunionAttends"
                                        [value]="ec20ReunionAttend.value" class="matInputBox">
                                        {{ ec20ReunionAttend.display }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col searchItem">
                            <mat-form-field class="containerSearchMd  EC20BeyondReunionOuter" appearance="outline">
                                <mat-label>EC20 Reunion Language</mat-label>
                                <mat-select name="SelectedEC20BeyondReunionLanguage" [(ngModel)]="memberList.Language"
                                    class="matInputBox  EC20BeyondReunionInner"
                                    (selectionChange)="onSelectEC20BeyondReunionLanguage($event.value)">
                                    <mat-option *ngFor="let ec20ReunionLanguage of ec20ReunionLanguages"
                                        [value]="ec20ReunionLanguage.value" class="matInputBox">
                                        {{ ec20ReunionLanguage.display }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> -->



          </div>
        </div>

        <div class="divSearchBox d-flex">
          <div class="row  me-auto" id="btnGroup">
            <div class="col" id="linkShowAllUsers">
              <button type="button" class="btn btn-link linkToDownload" (click)="showAllUsers()">
                Show all users</button>
            </div>

            <div class="col" id="linkShowAllUsers">
              <button mat-button (click)="onClickGoToUpsertTemplate()" class="btn btn-link editLink">Create My Template
              </button>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearchLg" appearance="outline">
                <mat-label>Template</mat-label>
                <mat-select name="SelectedMemberType" class="matInputBox"
                  (selectionChange)="onChangeSelectTemplateName($event.value)">
                  <mat-option *ngFor="let templateName of templateNames" [value]="templateName" class="matInputBox">
                    {{ templateName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col">
              <button [disabled]="!mySearchForm.form.valid" class="btn btn-primary btnInSearchBox">
                <i class="fa fa-search fa-fw"></i> Search</button>
            </div>
            <div class="col">
              <button (click)="onCancel($event)" [disabled]="!mySearchForm.form.valid"
                class="btn btn-secondary  btnInSearchBox">
                Clear
              </button>
            </div>
            <div class="col">
              <button mat-button (click)="exportToExcel()" class="btn btn-success btnDownload">
                Download
              </button>
            </div>
            <div class="col">
              <h4 *ngIf="showNumberOfUser" id="numberOfUsers">
                {{ cntUser }} Registrant(s) Found
              </h4>
            </div>
          </div>
        </div>
      </form>
      <div>
        <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
        <div *ngIf="!loaded">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <br>
        <div class="registrantList">
          <div>
            <div>
              <table class="table table-striped table-bordered table-hover tblRegistrant" mat-table
                [dataSource]="dataSource" matSort>

                <div *ngIf="displayedColumns.includes('LastName')">
                  <ng-container matColumnDef="LastName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      Last Name</th>
                    <td mat-cell *matCellDef="let row" class="columnMdTd"> {{row.LastName}}</td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('FirstName')">
                  <ng-container matColumnDef="FirstName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      First Name</th>
                    <td mat-cell *matCellDef="let row" class="columnMdTd"> {{row.FirstName}}</td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('Myoji')">
                  <ng-container matColumnDef="Myoji">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      苗字</th>
                    <td mat-cell *matCellDef="let row" class="columnMdTd"> {{row.Myoji}}</td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('Shimei')">
                  <ng-container matColumnDef="Shimei">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      氏名</th>
                    <td mat-cell *matCellDef="let row" class="columnMdTd"> {{row.Shimei}}</td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('Prefix')">
                  <ng-container matColumnDef="Prefix">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      Prefix</th>
                    <td mat-cell *matCellDef="let row" class="columnMdTd"> {{row.Prefix}}</td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('Email')">
                  <ng-container matColumnDef="Email" class="columnSm">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      Email</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd">
                      <!-- {{row.Email}} -->
                      <a href="mailto:  {{row.Email}}">Email</a>
                    </td>
                  </ng-container>
                </div>
                <div *ngIf="displayedColumns.includes('Gender')">
                  <ng-container matColumnDef="Gender">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      Gender</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Gender}} </td>
                  </ng-container>
                </div>
                <div *ngIf="displayedColumns.includes('Age')">
                  <ng-container matColumnDef="Age">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      Age</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd">
                      {{calculate_age(row.DOB)}} </td>
                  </ng-container>
                </div>
                <div *ngIf="displayedColumns.includes('TelNo')">
                  <ng-container matColumnDef="TelNo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      Tel#</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd">
                      {{row.TelNo}} </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('LivingCountry')">
                  <ng-container matColumnDef="LivingCountry">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      Current Living Country</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.LivingCountry}}
                    </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('Street')">
                  <ng-container matColumnDef="Street">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      Street</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Street}} </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('Street2')">
                  <ng-container matColumnDef="Street2">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      Street2</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Street2}} </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('City')">
                  <ng-container matColumnDef="City">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      City</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd">
                      {{row.City}}
                    </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('State')">
                  <ng-container matColumnDef="State">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      State</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd">
                      {{row.State}}
                    </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('Zip')">
                  <ng-container matColumnDef="Zip">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      Zip</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd">
                      {{row.Zip}}
                    </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('Country')">
                  <ng-container matColumnDef="Country">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      Country</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Country}} </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('Yubin_Bango')">
                  <ng-container matColumnDef="Yubin_Bango">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      郵便番号</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Yubin_Bango}} </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('To_Do_Fu_Ken')">
                  <ng-container matColumnDef="To_Do_Fu_Ken">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      都道府県</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd">
                      {{row.To_Do_Fu_Ken!=null?row.To_Do_Fu_Ken.split(':')[1]:''}} </td>
                  </ng-container>
                </div>


                <div *ngIf="displayedColumns.includes('Shi_Gun_Ku')">
                  <ng-container matColumnDef="Shi_Gun_Ku">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      市郡区</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Shi_Gun_Ku}} </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('Cho_Son')">
                  <ng-container matColumnDef="Cho_Son">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      町村</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Cho_Son}} </td>
                  </ng-container>
                </div>


                <div *ngIf="displayedColumns.includes('Apartment_Etc')">
                  <ng-container matColumnDef="Apartment_Etc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      アパートメント等</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Apartment_Etc}}
                    </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('To_Do_Fu_Ken_JP')">
                  <ng-container matColumnDef="To_Do_Fu_Ken_JP">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      都道府県</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.To_Do_Fu_Ken_JP}}
                    </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('Shi_Gun_Ku_JP')">
                  <ng-container matColumnDef="Shi_Gun_Ku_JP">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      市郡区</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Shi_Gun_Ku_JP}}
                    </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('Cho_Son_JP')">
                  <ng-container matColumnDef="Cho_Son_JP">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      町村</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Cho_Son_JP}} </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('Apartment_Etc_JP')">
                  <ng-container matColumnDef="Apartment_Etc_JP">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      アパートメント等</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Apartment_Etc_JP}}
                    </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('Occupation')">
                  <ng-container matColumnDef="Occupation">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      職業</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Occupation}} </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('Married')">
                  <ng-container matColumnDef="Married">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      Married</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Married}} </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('ZoomAccount')">
                  <ng-container matColumnDef="ZoomAccount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      Zoom Account</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.ZoomAccount}} </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('Christian')">
                  <ng-container matColumnDef="Christian">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      Christian</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Christian}} </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('NewsLetterLanguage')">
                  <ng-container matColumnDef="NewsLetterLanguage">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      News Letter Language</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd">
                      {{row.NewsLetterLanguage}} </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('MemberType')">
                  <ng-container matColumnDef="MemberType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      Member Type<br>Old Database</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd">
                      {{convertMemberType(row.MemberType)}} </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('MembershipType')">
                  <ng-container matColumnDef="MembershipType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      Member Type<br>New Database</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd">
                      {{row.MembershipType}}
                    </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('SupportingMembershipRecurring')">
                  <ng-container matColumnDef="SupportingMembershipRecurring">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      Membership <br>Recurring</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd">
                      {{row.SupportingMembershipRecurring==true?'Yes':'No'}}
                    </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('SupportingMembershipCreatedDate')">
                  <ng-container matColumnDef="SupportingMembershipCreatedDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      Membership <br>Paid</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd">
                      {{row.SupportingMembershipCreatedDate | date: 'shortDate'}}
                    </td>
                  </ng-container>
                </div>

                <!-- <div *ngIf="displayedColumns.includes('EC20BeyondReunionAttend')">
                                    <ng-container matColumnDef="EC20BeyondReunionAttend">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            class="columnSm columnEC20BeyondReunion">
                                            EC Reunion<br>Participate</th>
                                        <td mat-cell *matCellDef="let row" class="columnSmTd">
                                            {{row.Attend==true?"Yes":"No"}} </td>
                                    </ng-container>
                                </div>

                                <div *ngIf="displayedColumns.includes('EC20BeyondReunionLanguage')">
                                    <ng-container matColumnDef="EC20BeyondReunionLanguage">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                                            EC Reunion<br>Language</th>
                                        <td mat-cell *matCellDef="let row" class="columnSmTd">
                                            {{row.Language}} </td>
                                    </ng-container>
                                </div> -->

                <div *ngIf="displayedColumns.includes('NewsLetters')">
                  <ng-container matColumnDef="NewsLetters">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      News Letters</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd">
                      {{row.NewsLetters}} </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('ReceivingMethod')">
                  <ng-container matColumnDef="ReceivingMethod">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      Receiving Method</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd">
                      {{row.ReceivingMethod=='E'?'Email':'Paper'}} </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('ActiveStatus')">
                  <ng-container matColumnDef="ActiveStatus">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      Active Status</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd">
                      {{row.ActiveStatus==true?"Yes":"No"}} </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('CreatedDate')">
                  <ng-container matColumnDef="CreatedDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm columnEC20BeyondReunion">
                      Data Registered</th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.CreatedDate | date:
                      'shortDate'}} </td>
                  </ng-container>
                </div>

                <div *ngIf="displayedColumns.includes('LastModifiedDate')">
                  <ng-container matColumnDef="LastModifiedDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      Last Modified </th>
                    <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.LastModifiedDate |
                      date: 'shortDate'}} </td>
                  </ng-container>
                </div>


                <ng-container matColumnDef="myEditProfile">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">My Account
                  </th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd">
                    <button mat-button (click)="onClickGoToMyAccount(row)" class="btn btn-link editLink">My Account
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                </tr>
              </table>

              <mat-paginator [pageSize]="50" [pageSizeOptions]="[5, 10, 25, 100, 200, 300, 400]">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
