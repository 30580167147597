import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { User } from "../../models/user";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfig } from "../../app.config";
import { Observable } from "rxjs";
import { isPlatformBrowser } from "@angular/common";
import { TokenResponse } from "../../models/tokenResponse";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
//import { UserService } from "../user/user.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authKey: string = this.config.keyMyAuth;
  clientId: string = "TestMakerFree1";
  tokenResponse: TokenResponse;

  user: User = {
    PasswordHash: null,
    Id: null,
    UserName: null,
    Email: null,
    FirstName: null,
    LastName: null,
    Password: null,
    Prefix: null,
    Shimei: null,
    Myoji: null,
    Street: null,
    Street2: null,
    City: null,
    State: null,
    Zip: null,
    Country: null,
    Yubin_Bango: null,
    To_Do_Fu_Ken: null,
    Shi_Gun_Ku: null,
    Cho_Son: null,
    Apartment_Etc: null,
    To_Do_Fu_Ken_JP: null,
    Shi_Gun_Ku_JP: null,
    Cho_Son_JP: null,
    Apartment_Etc_JP: null,
    Gender: null,
    DOB: null,
    TelNo: null,
    TimezoneName: null,
    UTCdiff: null,
    Token: null,
    Occupation: null,
    MemberStatus: null,
    Married: null,
    ZoomAccount: null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    RegisteredDate: null,
    LastAccessedDate: null,
    //From siteground
    LoginId: null,
    MiddleNameEN: null,
    MiddleNameJP: null,
    PrefixJP: null,
    OccupationDetail: null,
    Organization: null,
    LivingCountry: null,
    PhoneEN: null,
    PhoneMobileEN: null,
    PhoneJP: null,
    PhoneMobileJP: null,
    ChurchNameEN: null,
    ChurchNameJP: null,
    MemberType: null,
    MemberDetail: null,
    MemberMemo: null,
    Shokaisha: null,
    Reason: null,
    NewsLetters: null,
    StaffReports: null,
    OtherLetters: null,
    NewsLetterLanguage: null,
    BasicRoles: null,
    //Added new
    MigrateFromSiteGround: null,
    AdminAccess: null,
    JCFNAccess: null,
    SupportingMember: null,
    LastAddressUpdatedDate: null,
    AcceptMembershipTerm: null,
    MembershipType: null
  };

  loginUserName: string = "";
  loginUserFirstName: string;
  companyName: string;

  constructor(
    private http: HttpClient,
    private config: AppConfig,
    //private userService: UserService,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  isLoggedIn(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return sessionStorage.getItem(this.authKey) != null;
    }
    return false;
  }

  logout(): boolean {
    this.setAuth(null);
    return true;
  }

  /*****************************************
   *  Set Group: Auth, User name etc
   ****************************************/

   checkLoginUserAccessCommittee(){

   }

  login(email: string, password: string): Observable<TokenResponse> {
    var url = "/api/token/auth";
    var data = {
      Grant_type: "password",
      Client_id: this.clientId,
      Client_secret: "",
      Username: email,
      Password: password,
      Refresh_token: ""
    };
    this.loginUserName = data.Username;
    return this.getAuthFromServerAngular(url, data);
  }
  getAuthFromServerAngular(url: string, data: any): Observable<TokenResponse> {
    return this.http
      .post<TokenResponse>(this.config.apiMyUrl + url, data)
      .map(res => {
        //.pipe(map(res => {
        let token = res && res.token;
        // if the token is there, login has been successful
        console.log(
          "**** Received token from api host **** "
          //+res.token
        );
        if (token) {
          // 1) Store jwt token
          this.setAuth(res);
          this.setLoginUserName(this.loginUserName);
          this.masterMyProfile(this.loginUserName)
          // successful login
          return true;
        }
        // failed login
        return Observable.throw("Unauthorized");
      })
      .catch(error => {
        return new Observable<any>(error);
      });
  }
  setAuth(auth: TokenResponse | null): boolean {
    if (isPlatformBrowser(this.platformId)) {
      if (auth) {
        sessionStorage.setItem(this.authKey, JSON.stringify(auth));
        console.log("**** Completed setAuth(): " + this.authKey + " to use for JWT going after. ****");
      } else {
        sessionStorage.removeItem(this.authKey);
        sessionStorage.clear();
      }
    }
    return true;
  }
  setLoginUserName(userName: string) {
    sessionStorage.setItem("loginUserName", userName);
    console.log("***** Set user name completed for: " + userName + " to use later *******");
  }

  async masterMyProfile(loginUserName: string) {
    await this.getUserProfileByUserName(loginUserName).subscribe(result => {
      this.user = result;
      if (this.user != null) {
        sessionStorage.setItem("loginUserId", this.user.Id);
        sessionStorage.setItem("loginUserFirstName", this.user.FirstName);
      }
    });
  }

  getUserProfileByUserName(userName: string): Observable<User> {
    return this.http.get<User>(
      this.config.apiMyUrl + "/api/user/getUserProfile/" + userName
    );
  }



  /*****************************************
   *  Get Group: Auth, User name etc
   ****************************************/
  getAuth(): TokenResponse | null {
    if (isPlatformBrowser(this.platformId)) {
      var i = sessionStorage.getItem(this.authKey);
      if (i) {
        return JSON.parse(i);
      }
    }
    return null;
  }
  getUserName(): string {
    if (this.isLoggedIn()) {
      console.log('isLogin');
      console.log('session ' + sessionStorage.getItem("loginUserName"));
      this.loginUserName = sessionStorage.getItem("loginUserName");
    }
    return this.loginUserName;
  }
  getLoginUserFirstName(): string {
    if (this.isLoggedIn()) {
      this.loginUserFirstName = sessionStorage.getItem("loginUserFirstName");
    }
    return this.loginUserFirstName;
  }
  getJWT() {
    this.tokenResponse = JSON.parse(sessionStorage.getItem(this.authKey));
    if (this.tokenResponse != null) {
      return new HttpHeaders()
        .append("Content-Type", "application/json")
        .append(
          "Authorization",
          "Bearer " + this.tokenResponse.token
        );
    } else {
      return null;
    }
  }



  /*****************************************
   *  Check Group: Auth, User name etc
   ****************************************/
  checkLoginUserAccessJCFN(): boolean {
    return sessionStorage.getItem("loginUserAccessJCFN") === 'true';
  }
  checkLoginUserAccessAdmin(): boolean {
    return sessionStorage.getItem("loginUserAccessAdmin") === 'true';
  }


  getTokenResponseFromServer(email: string, password: string) {
    var url = "/api/token/auth";
    var data = {
      Grant_type: "password",
      Client_id: this.clientId,
      Client_secret: "",
      Username: email,
      Password: password,
      Refresh_token: ""
    };
    this.loginUserName = data.Username;
    return this.http.post<TokenResponse>(this.config.apiMyUrl + url, data)
  }
  getTokenResponseFromServerNew(email: string, password: string): Observable<any> {
    var url = "/api/token/auth";
    var data = {
      Grant_type: "password",
      Client_id: this.clientId,
      Client_secret: "",
      Username: email,
      Password: password,
      Refresh_token: ""
    };
    this.loginUserName = data.Username;
    return this.http.post<TokenResponse>(this.config.apiMyUrl + url, data);
  }
  getTokenResponseFromServerNew2(email: string, password: string): Observable<any> {
    var url = "/api/token/auth";
    var data = {
      Grant_type: "password",
      Client_id: this.clientId,
      Client_secret: "",
      Username: email,
      Password: password,
      Refresh_token: ""
    };
    this.loginUserName = data.Username;
    return this.http.post<TokenResponse>(this.config.apiMyUrl + url, data).map(res => {
      let token = res && res.token;
      console.log("##### In auth.service - response from api host: " + res.token);
      if (token) {
        this.setAuth(res);
        return true;
      }
      // failed login
      return Observable.throw("Unauthorized");
    });;
  }

  getCORSjob() {
    return new HttpHeaders()
      .append("Content-Type", "application/json")
      .append("Access-Control-Allow-Origin", "*")
  }
}
