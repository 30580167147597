import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router} from "@angular/router";
import { UserConference } from 'src/app/models/conference/userConference';
export interface DialogData {
  user: UserConference
}
@Component({
  selector: 'app-address-info-embed',
  templateUrl: './address-info-embed.component.html',
  styleUrls: ['./address-info-embed.component.css']
})
export class AddressInfoEmbedComponent implements OnInit {

  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<AddressInfoEmbedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit(): void {

  }

  onClickClose() {
    this.dialogRef.close();
  }
}
