import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router, ActivatedRoute } from "@angular/router";
import { User } from 'src/app/models/user';
import { UserService} from "../../../services/user/user.service";
//import { ECConference } from '../../../models/conference/ECConference';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { RelatedPerson} from "../../../models/conference/RelatedPerson";
import { VolunteerSelection} from "../../../models/conference/volunteerSelection";

export interface DialogData {
  user: User;
}

@Component({
  selector: 'app-cancel-or-save-confirmation-personal',
  templateUrl: './cancel-or-save-confirmation-personal.component.html',
  styleUrls: ['./cancel-or-save-confirmation-personal.component.css']
})
export class CancelOrSaveConfirmationPersonalComponent implements OnInit {
  myMessage:string;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    private _snackBar: MatSnackBar,
    public router: Router,
    public dialogRef: MatDialogRef<CancelOrSaveConfirmationPersonalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private userService : UserService) {}

  relatedPerson:RelatedPerson={
    Id:null,
    RelatedId:null,
    Email:null,
    FirstNameRelated:null,
    LastNameRelated:null,
    MyojiRelated:null,
    ShimeiRelated:null,
    DOBRelated:null,
    GenderRelated:null
  };
  relatedPersons:RelatedPerson[]=[];
  volunteerSelections:VolunteerSelection[]=[];
  user: User = {
    PasswordHash:null,
    Id:null,
    UserName:null,
    Email:null,
    FirstName:null,
    LastName:null,
    Password:null,
    Prefix:null,
    Shimei:null,
    Myoji:null,
    Street:null,
    Street2:null,
    City:null,
    State:null,
    Zip:null,
    Country:null,
    Yubin_Bango:null,
    To_Do_Fu_Ken:null,
    Shi_Gun_Ku:null,
    Cho_Son:null,
    Apartment_Etc:null,
    To_Do_Fu_Ken_JP:null,
    Shi_Gun_Ku_JP:null,
    Cho_Son_JP:null,
    Apartment_Etc_JP:null,
    Gender:null,
    DOB:null,
    TelNo:null,
    TimezoneName:null,
    UTCdiff:null,
    Token:null,
    Occupation:null,
    MemberStatus:null,
    Married:null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount:null,
    RegisteredDate:null,
    LastAccessedDate:null,
    // //From siteground
    LoginId:null,
    MiddleNameEN:null,
    MiddleNameJP:null,
    PrefixJP:null,
    OccupationDetail:null,
    Organization:null,
    LivingCountry:null,
    PhoneEN:null,
    PhoneMobileEN:null,
    PhoneJP:null,
    PhoneMobileJP:null,
    ChurchNameEN:null,
    ChurchNameJP:null,
    MemberType:null,
    MemberDetail:null,
    MemberMemo:null,
    Shokaisha:null,
    Reason:null,
    NewsLetters:null,
    StaffReports:null,
    OtherLetters:null,
    NewsLetterLanguage:null,
    BasicRoles:null,
    //Added new
    MigrateFromSiteGround:null,
    AdminAccess:null,
    JCFNAccess:null,
    SupportingMember:null,
    LastAddressUpdatedDate:null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };
  ngOnInit() {
    if(this.data.user!=null){
      this.user = this.data.user;
   }
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  onSaveWithoutSavingClick(): void {
    sessionStorage.removeItem("myWorkingOnUser"); // This is session being used during personal registration
       // Adminによる調べ中のユーザーのMy Accountに戻る前に作業中のセッションは消しておく
       if (sessionStorage.getItem("myECSearchingUserId") != null) {
        sessionStorage.removeItem("myWorkingOnUserAdmin");
      }
    this.dialogRef.close();
  }

  onSaveAndCancel(): void {
    sessionStorage.removeItem("myWorkingOnUser"); // This is session being used during personal registration
      // Adminによる調べ中のユーザーのMy Accountに戻る前に作業中のセッションは消しておく
      if (sessionStorage.getItem("myECSearchingUserId") != null) {
      sessionStorage.removeItem("myWorkingOnUserAdmin");
    }
    this.userService.upsertUser(this.user)
      .subscribe(
        () => {
          //success
          this.myMessage = this.getMyLanguage() == "EN" ? "Personal registration information saved successfully " : "個人情報が保存されました。";
          this.openSnackBar('success', 'close');
        },
        () => {
          //Error
          this.myMessage = this.getMyLanguage() == "EN" ? "Personal registration update failed." : "問題が生じ個人情報がアップデイトされませんでした。";
          this.openSnackBar('error', 'close');
        }
      )
      this.dialogRef.close();
  }

  onClickCancel(){
    this.dialogRef.close("cancel");
  }

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 6000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 20000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }

}
