import { Component, OnInit, Inject, ANALYZE_FOR_ENTRY_COMPONENTS } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { PaymentMasterCore} from '../../../models/payment/paymentMasterCore';
import { PaymentService} from "../../../services/payment/payment.service";
import { UserService} from "../../../services/user/user.service";
import { User } from "../../../models/user";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

export interface DialogData {
  Id: string
}

@Component({
  selector: 'app-membership-setting',
  templateUrl: './membership-setting.component.html',
  styleUrls: ['./membership-setting.component.css']
})
export class MembershipSettingComponent implements OnInit {
  firstFormGroup: FormGroup;
  loaded:boolean=true;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  myMessage:string;
  beSupportingMember:boolean;
  disableUpdateButton:boolean;

  public providers = [
    { value: "Authorize.net", display: "Authorize.net" },
    { value: "Tithely", display: "Tithely" },
    { value: "日本郵便振替 Japan Postal Transfer", display: "日本郵便振替 Japan Postal Transfer" },
    { value: "日本銀行振込 Japan Bank Transfer", display: "日本銀行振込 Japan Bank Transfer" },
    { value: "日本クレジットカード Japan Credit Card", display: "日本クレジットカード Japan Credit Card" },
    { value: "現金 Cash", display: "現金 Cash" },
    { value: "チェック Check", display: "チェック Check" },
  ];

  public membershipTypes = [
   // { value: "", display: "" },
    { value: "Supporting Membership", display: "Supporting Membership" },
   // { value: "Regular Membership", display: "Regular Membership" },
  ];

  public currencies=[
    { value: "USD", display: "USD" },
    { value: "JPY", display: "JPY" },
  ]

  paymentMasterCore: PaymentMasterCore = {
    PaymentMasterId: 0,
    FirstName: null,
    LastName: null,
    Email: null,
    DataDescriptor: null,
    DataValue: null,
    ItemName: null,
    Amount: null,
    Recurring: null,
    RecurringId: null,
    CreatedDate: null,
    ProviderName:null,
    Currency:null,
    MembershipType:null
  }
  paymentMasterCores:PaymentMasterCore[]=[];

  user: User = {
    PasswordHash: null,
    Id: null,
    UserName: null,
    Email: null,
    FirstName: null,
    LastName: null,
    Password: null,
    Prefix: null,
    Shimei: null,
    Myoji: null,
    Street: null,
    Street2: null,
    City: null,
    State: null,
    Zip: null,
    Country: null,
    Yubin_Bango: null,
    To_Do_Fu_Ken: null,
    Shi_Gun_Ku: null,
    Cho_Son: null,
    Apartment_Etc: null,
    To_Do_Fu_Ken_JP: null,
    Shi_Gun_Ku_JP: null,
    Cho_Son_JP: null,
    Apartment_Etc_JP: null,
    Gender: null,
    DOB: null,
    TelNo: null,
    TimezoneName: null,
    UTCdiff: null,
    Token: null,
    Occupation: null,
    MemberStatus: null,
    Married: null,
    ZoomAccount: null,
    Christian: null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    RegisteredDate: null,
    LastAccessedDate: null,
    //From siteground
    LoginId: null,
    MiddleNameEN: null,
    MiddleNameJP: null,
    PrefixJP: null,
    OccupationDetail: null,
    Organization: null,
    LivingCountry: null,
    PhoneEN: null,
    PhoneMobileEN: null,
    PhoneJP: null,
    PhoneMobileJP: null,
    ChurchNameEN: null,
    ChurchNameJP: null,
    MemberType: null,
    MemberDetail: null,
    MemberMemo: null,
    Shokaisha: null,
    Reason: null,
    NewsLetters: null,
    StaffReports: null,
    OtherLetters: null,
    NewsLetterLanguage: null,
    BasicRoles: null,
    //Added new
    MigrateFromSiteGround: null,
    AdminAccess: null,
    JCFNAccess: null,
    SupportingMember: null,
    LastAddressUpdatedDate: null,
    AcceptMembershipTerm: null,
    MembershipType: null
  };

  constructor(
    public router: Router,
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private userService:UserService,
    public dialogRef: MatDialogRef<MembershipSettingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ){}

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      myFirstName: ['', Validators.required],
      myLastName: ['', Validators.required],
      myEmail:['',],
      myItemName:['',Validators.required],
      myCurrency:['',Validators.required],
      myAmount:['',Validators.required],
      myProviderName: ['',Validators.required ],
      myCreatedDate: ['', Validators.required],
    });
    if (this.data.Id != null) {
      this.loaded=false;
      // this.userService.getUserProfileById(this.data.Id).subscribe(
      //   result => {
      //     this.user = result;
      //    // this.paymentMasterCore.PaymentMasterId=1;
      //     this.paymentMasterCore.FirstName = result.FirstName;
      //     this.paymentMasterCore.LastName = result.LastName;
      //     this.paymentMasterCore.Email =result.Email;
      //     this.paymentMasterCore.ItemName = "Supporting Membership";
      //     this.paymentMasterCore.Amount=30;
      //     this.paymentMasterCore.Recurring=false;
      //     this.beSupportingMember=result.MembershipType==null?false:(result.MembershipType.toLowerCase()=="supporting"?true:false);
      //     //this.disableUpdateButton=this.beSupportingMember==true?true:false;
      //     console.log(this.disableUpdateButton);
      //     this.loaded = true;
      //   }
      // )
      this.userService.getPaymentMasterCoreByUserId(this.data.Id).subscribe(
        result=>{
            this.paymentMasterCore = result;
            if(this.paymentMasterCore.PaymentMasterId==0){
              this.paymentMasterCore.Recurring=false;
              this.paymentMasterCore.ItemName="Supporting Membership";
              this.paymentMasterCore.Currency="USD";
              this.paymentMasterCore.Amount=40;
            }
            this.loaded=true;
        }
      )
    }
  }

  getMyLanguage(): string {
    return this.router.url.includes('EN') == true ? 'EN' : 'JP';
  }

  InsertSupportingMembershipPaymentInfo() {
    if (this.firstFormGroup.status == 'VALID') {
      this.loaded = false;
      this.paymentMasterCore.Amount =Number(this.paymentMasterCore.Amount);
      console.log("Id: "+this.paymentMasterCore.PaymentMasterId);
      console.log("currency: "+this.paymentMasterCore.Currency);
      console.log("amount: "+this.paymentMasterCore.Amount);


      this.paymentService.InsertSupportingMembershipPaymentInfo(this.paymentMasterCore).subscribe(
          data => {
            //Success saving personal info.
            this.user = data;
            this.myMessage = this.getMyLanguage()=="EN"?"Membership info saved successfully.":"会員情報が更新されました。";
            this.openSnackBar('success', 'close');
            this.userService.getPaymentMasterCoreByUserId(this.data.Id).subscribe(
              result=>{
                  this.paymentMasterCore = result;
              }
            )
          this.loaded=true;
          },
          error => {
            this.myMessage = this.getMyLanguage()=="EN"?"Membership info failed to be saved.":"問題が生じ会員情報が更新されませんでした。";
            this.openSnackBar('error', 'close');
            this.loaded = true;
          }
        )
    }else{
      console.log('data is bad');
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 5000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 10000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }

  onClickClose() {
    sessionStorage.removeItem("searchingRegistrantId");
    console.log('Im closing');
    this.dialogRef.close();
  }

  onCurrencyChanged(choosedValue:any){
    this.paymentMasterCore.Currency=choosedValue;
    if(this.paymentMasterCore.Currency=="USD"){
      this.paymentMasterCore.Amount=40;
    }else if(this.paymentMasterCore.Currency="JPY"){
      this.paymentMasterCore.Amount=4000;
    }
  }

}
