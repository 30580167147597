<div class="WelcomeBase">
 <form [formGroup]="firstFormGroup">
  <div class="btnBack">
   <button type="button" class="btn btn-link" (click)="onClickBack()">
    <i class="fas fa-arrow-circle-left fa-2x"></i>
   </button>
  </div>
  <mat-card class="WelcomeWindow">
   <!-- <mat-card-header>
    <mat-card-title>
     {{this.router.url.includes('EN')==true?'Welcome to Global Returnees Conference 2021!':'Global Returnees Conference 2021へようこそ !'}}
    </mat-card-title>
   </mat-card-header> -->
   <h3>
    <i class="fas fa-question-circle fa-2x"></i>
    {{this.router.url.includes('EN')==true?'Do you have a JCFN Account?':'JCFNアカウントをお持ちですか？'}}
   </h3>
   <!-- <label class="WelcomeQues">
    {{this.router.url.includes('EN')==true?
    'If you are a JCFN member, Supporting Member, or have participated in EC20 or GRC21, you have a JCFN account!'
    :
    'JCFN会員の方、維持会員の方、EC20かGRC21に参加された方はJCFNアカウントをお持ちになっています'}}
   </label> -->
   <br><br><br>
   <div>
    <div class="d-flex justify-content-center">
     <button mat-button (click)="onClickEnterSearchingEmail()" class="btnRegistrantUpdate" cdkFocusInitial>
      {{this.router.url.includes('EN')==true?'Yes':'はい'}}
     </button>
    </div>
    <label class="WelcomeComment d-flex justify-content-center">
     {{this.router.url.includes('EN')==true?'Search my account.':'あなたのアカウントを検索します'}}
    </label>
    <hr><br>
    <div class="d-flex justify-content-center">
     <button mat-button (click)="onClickCreateUser()" class="btnRegistrantUpdate">
      {{this.router.url.includes('EN')==true?'No':'いいえ'}}
     </button>
    </div>
    <label class="WelcomeComment d-flex justify-content-center">
     {{this.router.url.includes('EN')==true?'Create my account.':'あなたのアカウントを作ります'}}
    </label>
   </div>
  </mat-card>

 </form>
</div>
<div class="memoForDevelopper">
 check-having-account.component
</div>
