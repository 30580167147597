<div class="myBody">
    <div class="myHeaderBase d-flex justify-content-center">
        <div class="myHeaderCore">
            <div>
                <img src='../../../../../assets/images/thumbnail_eclogo.png'  class="ecLogo">
            </div>
        </div>
    </div>
    <div class="myMain">
        <div clas="myMainLeft">
            <div class="myMainHalfTop">
                <!-- Left Top -->
            </div>
            <div class="myMainHalfBottom">
                <!-- Left Bottom -->
            </div>
        </div>
        <div class="myMainCenter">
            <div class="myMainHalfTop d-flex justify-content-center">
                <!-- Main Top -->

                <mat-card class="mainCard b-radius">
                    <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
                    <div *ngIf="!loaded">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </div>
                    <!-- <form *ngIf="userFromServer | async; else loading"  -->
                        <form
                        [formGroup]="myFormGroup"
                        (ngSubmit)="onSubmit()"
                        >
                        <div class="float-end stepNumber">3/12</div>
                        <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Japan Regional Meeting':
                            '日本地域別集会'}}
                            <i class="stepTitleSub">
                                {{this.router.url.includes('EN')==true?'Required': '必須'}}
                            </i>
                        </h2>
                        <div class="stepTitle2nd">
                          {{this.router.url.includes('EN')==true?'During EC, we will have a "Japan Regional Meeting" time for networking purposes.':
                          'EC中、出身地や帰国後住む予定の地域の人たちとのネットワークのため、日本地域別集会がもたれます。'}}
                      </div>
                      <div *ngIf="conference.ProgramType=='Youth'" class="stepTitle2nd">
                        <u>{{this.router.url.includes('EN')==true?'Youth are not eligible.':
                          'ユースは対象外'}}
                        </u>
                      </div>
                        <div class="d-flex justify-content-center">
                            <mat-radio-group formControlName="myAttendingJapanRegionalMeeting"
                               (change)="onChangeAttendSelection($event)"
                               [disabled]="disableSelectingAttendJapanRegionalMeeting"
                               class="rbGroup">
                               <mat-radio-button value=true class="rbItem"
                               [checked]="conference.AttendJapanRegionalMeeting==true"
                               >
                                  {{this.router.url.includes('EN')==true?'Attend': '参加する'}}</mat-radio-button>
                               <mat-radio-button value=false class="rbItem"
                               [checked]="conference.AttendJapanRegionalMeeting==false"
                               >
                                  {{this.router.url.includes('EN')==true?'Not attend': '参加しない'}}</mat-radio-button>
                            </mat-radio-group>
                            <mat-error *ngIf="myFormGroup.get('myAttendingJapanRegionalMeeting').hasError('required')  && myFormGroup.get('myAttendingJapanRegionalMeeting').touched">
                                {{this.router.url.includes('EN')==true?'Japan Regional Meeting is required': '未入力'}}
                              </mat-error>
                         </div>
                         <br>
                            <br>
                            <div  class="d-flex justify-content-center">
                                <mat-form-field appearance="outline" class="registrationInputForm">
                                    <mat-label>
                                      {{this.router.url.includes('EN')==true?'Japan Regional Meeting': '日本地域別集会'}}

                                    </mat-label>
                                    <mat-select [disabled]="disableJapanRegionalMeetingSelection"
                                    placeholder="" formControlName="myJapanRegionalMeetingSelection"
                                       class="matInputBox" ngDefaultControl>

                                       <div *ngIf="this.router.url.includes('EN')==true">
                                        <mat-option *ngFor="let japanRegional of to_do_fu_kensEN"
                                        [value]="japanRegional.value" class="matInputBox">
                                            {{ japanRegional.display }}
                                        </mat-option>
                                       </div>
                                       <div *ngIf="this.router.url.includes('EN')==false">
                                        <mat-option *ngFor="let japanRegional of to_do_fu_kensJP"
                                        [value]="japanRegional.value" class="matInputBox">
                                            {{ japanRegional.display }}
                                        </mat-option>
                                       </div>
                                    </mat-select>
                                </mat-form-field><br>
                                <mat-error *ngIf="myFormGroup.get('myJapanRegionalMeetingSelection').hasError('required')  && myFormGroup.get('myJapanRegionalMeetingSelection').touched">
                                    {{this.router.url.includes('EN')==true?'Required': '未選択'}}
                                  </mat-error>
                            </div>



                        <div class=" position-absolute bottom-0 start-50 translate-middle-x">
                            <div class="d-flex justify-content-center">
                                <button type="button" class="btn btnProcess" (click)="onClickGoBack()">
                                    {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                                </button>
                                <button [disabled]="!myFormGroup.valid" type="button" class="btn btnProcess"
                                    (click)="onClickGoForward()">
                                    {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                                </button>
                            </div>
                            <div class="d-flex justify-content-center">
                                <button type="button" class="btn link" (click)="cancelProcess()">
                                    {{this.router.url.includes('EN')==true?'Cancel': 'キャンセル'}}
                                  </button>
                            </div>
                        </div>
                         <!-- <button type="submit" [disabled]="!myFormGroup.valid">Submit</button> -->
                    </form>
                </mat-card>
            </div>
            <div class="myMainHalfBottom">
                <!-- Main Bottom -->
            </div>
        </div>
        <div class="myMainRight">
            <div class="myMainHalfTop">
                <!-- Right Top -->
            </div>
            <div class="myMainHalfBottom">
                <!-- Right Bottom -->
            </div>
        </div>
    </div>
    <!-- <footer>footer</footer> -->
</div>
