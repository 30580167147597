<div class="WelcomeBase">
 <form [formGroup]="firstFormGroup">
  <div class="btnBack">

   <button type="button" class="btn btn-link" (click)="onClickBack()">
    <i class="fas fa-arrow-circle-left fa-2x"></i>
   </button>
  </div>

  <mat-card class="WelcomeWindow">
   <label class="EmailCheckComment">
    {{this.router.url.includes('EN')==true?'Your password has expired. Please update your password below':
    'パスワードの有効期限が切れました。新しいパスワードを設定してください'}}
   </label><br>
 
   <div class="d-flex justify-content-center">
    <i class="fas fa-user fa-2x"></i>&nbsp;
   <mat-form-field appearance="outline" class="registrationInputForm">
    <mat-label>
     Email
    </mat-label>
    <input matInput placeholder="" formControlName="myEmail" [(ngModel)]="user.Email"  readonly
     class="registrationInputBox" >
   </mat-form-field>
   </div>
   <div *ngIf="!loaded">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
   </div>
   <div *ngIf="!loaded" class="progressMessage">
    {{this.router.url.includes('EN')==true?'Sending ...':'送信中 ...'}}
   </div>
   <div  class="divUpdateButton  d-flex justify-content-center">
    <button mat-button (click)="onPasswordRecoveryTokenRequest()" class="btnSearchEmail" cdkFocusInitial>
     {{this.router.url.includes('EN')==true?'Change my password':'パスワードを変更する'}}
    </button>
   </div>
<div *ngIf="showMessageUserNotFound" class="divMessageUserNotFound d-flex justify-content-center">
  {{this.router.url.includes('EN')==true?'No data found. Please make sure the Email address is correct.':'該当データが見つかりませんでした。Eメールアドレスが正しいか確認してください'}}
</div>
  </mat-card>
 </form>

</div>
<div class="memoForDevelopper">
forward-site-ground-user-to-password-recovery
</div>