import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { UserService} from "../../../services/user/user.service";
import { Router, ActivatedRoute } from "@angular/router";
import { LoginEnterPasswordComponent} from "../../dialog/login-enter-password/login-enter-password.component";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import  { User} from "../../../models/user";

export interface DialogData {
  myLanguage:string
}

@Component({
  selector: 'app-password-recovery-email-sent',
  templateUrl: './password-recovery-email-sent.component.html',
  styleUrls: ['./password-recovery-email-sent.component.css']
})
export class PasswordRecoveryEmailSentComponent implements OnInit {
  firstFormGroup: FormGroup;
  loaded:boolean=true;
  userSearched:boolean=true;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  myMessage:string;
  showMessageUserNotFound:boolean=false;

  loading = false;
  Email: string;
  Password: string;
  mySelectedLanguage: string;


  constructor(
    public router: Router,
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private userService: UserService,
    public dialogRef: MatDialogRef<PasswordRecoveryEmailSentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog
  ){}

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}



