<div class="myBase">
  <div class="myBackgroundLayer">
    <div class="myContainer">
      <div class="row truckingTitle">
        <div class="col-md-6">
          <h2><i class="fas man fa-2x"></i>&nbsp; GRC23 Registrant</h2>
        </div>
      </div>
      <!--  ####### Search Box Area ######## -->
      <div>
        <form [formGroup]="myFormGroup">
          <div class=" divSearchBox">
            <div class="row divSeachBoxSub">

              <div class="col searchItem">
                <mat-form-field class="containerSearchLg" appearance="outline">
                  <mat-label>Last Name</mat-label>
                  <input formControlName="myLastName" name="LastName" matInput placeholder="Last Name"
                    class="matInputBox" />
                </mat-form-field>
              </div>

              <div class="col searchItem">
                <mat-form-field class="containerSearchLg" appearance="outline">
                  <mat-label>First Name</mat-label>
                  <input formControlName="myFirstName" name="FirstName" matInput placeholder="First Name"
                    class="matInputBox" />
                </mat-form-field>
              </div>

              <div class="col searchItem">
                <mat-form-field class="containerSearchLg" appearance="outline">
                  <mat-label>Email</mat-label>
                  <input formControlName="myEmail" name="Email" matInput placeholder="Email"
                    class="matInputBox" />
                </mat-form-field>
              </div>

            </div>

            <div class=" divSeachBoxButtons">
              <div class="dateColorSub2"></div>
              <div class="row" id="btnGroup">
                <div class="col">
                  <button type="button" class="btn btn-link linkToDownload" (click)="onSearchSubmit(myFormGroup)">
                    参加者全員を出す</button>
                </div>
                <div class="col">
                  <button [disabled]="!myFormGroup.valid" (click)="onSearchSubmit(myFormGroup)"
                    class="btn btn-primary btnInSearchBox">
                    <i class="fa fa-search fa-fw"></i> Search</button>
                </div>

                <div class="col">
                  <button (click)="onCancel($event)" class="btn btn-secondary  btnInSearchBox">
                    Clear
                  </button>
                </div>
                <div class="col">
                  <button mat-button (click)="exportToExcel()" class="btn btn-success btnDownload">
                    Download
                  </button>
                </div>
                <div class="col">
                  <h4 *ngIf="showNumberOfUser" id="numberOfRegistrant">
                    {{ cntUser }} Registrant(s) Found
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div>
        <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
        <div *ngIf="!loaded">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <br>
        <div class="registrantList">
          <div>
            <div>
              <table class="table table-striped table-bordered table-hover tblRegistrant" mat-table
                [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="LastName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                    Last Name</th>
                  <td mat-cell *matCellDef="let row" class="columnMdTd"> {{row.LastName}}<br>{{row.Myoji}} </td>
                </ng-container>
                <ng-container matColumnDef="FirstName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                    First Name</th>
                  <td mat-cell *matCellDef="let row" class="columnMdTd"> {{row.FirstName}}<br>{{row.Shimei}} </td>
                </ng-container>

                <ng-container matColumnDef="Gender">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                    性別</th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Gender=='Male'?'男性':'女性'}} </td>
                </ng-container>

                <ng-container matColumnDef="Age">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="age" class="columnSm">
                    年齢</th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd"> {{calculate_age(row.DOB)}} </td>
                </ng-container>

                <ng-container matColumnDef="Student">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="Student" class="columnSm">
                    学生</th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Student==true?'はい':'いいえ'}} </td>
                </ng-container>

                <ng-container matColumnDef="Christian">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="Christian" class="columnSm">
                    クリスチャン</th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Christian==true?'はい':'いいえ'}} </td>
                </ng-container>

                <ng-container matColumnDef="SmallGroupLanguage">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="SmallGroupLanguage" class="columnSm">
                    言語</th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd">
                    {{row.SmallGroupLanguage=='Japanese'?'日本語':row.SmallGroupLanguage}} </td>
                </ng-container>

                <ng-container matColumnDef="myVolunteerSelections">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="myVolunteerSelections" class="columnSm">
                    希望奉仕</th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd">
                    <div *ngFor="let myVolunteerSelection of row.myVolunteerSelections">
                      {{ myVolunteerSelection.VolunteerName}}
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="myVolunteerSelectionsApproved">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="myVolunteerSelectionsApproved"
                    class="columnSm">
                    承認奉仕</th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd">
                    <div *ngFor="let myVolunteerSelection of row.myVolunteerSelections">
                      <div *ngIf="myVolunteerSelection.VolunteerAccepted">
                        {{ myVolunteerSelection.VolunteerName}}
                      </div>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="myRelatedPersons">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="myRelatedPersons" class="columnSm">
                    同伴者</th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd">
                    <div *ngFor="let myRelatedPerson of row.RelatedPersonRelatedPersons">
                      {{ myRelatedPerson.FirstNameRelated}}&nbsp;{{ myRelatedPerson.LastNameRelated}}
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="RegisteredDate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                    登録日</th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.RegisteredDate | date: 'shortDate'}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="myEditProfile">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">My Account
                  </th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd">
                       <button mat-button (click)="onClickGoToMyAccount(row)" class="btn btn-link editLink">My Account
                    </button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                </tr>
              </table>
              <mat-paginator [pageSize]="50" [pageSizeOptions]="[5, 10, 25, 100, 200, 300, 400]"></mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
