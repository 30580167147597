import { Injectable } from '@angular/core';
import { User } from "../../models/user";
import { MemberList} from "../../models/memberList";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../../app.config";
import { Observable } from "rxjs";
import { AuthService } from '../auth/auth.service';
import { MemberListGridTemplate } from 'src/app/models/memberListGridTemplate';
import { MemberListTemplate } from 'src/app/models/MemberListTemplate';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private authService:AuthService,
    private config: AppConfig) { }

    getMemberListsBySearchKey(user:MemberList):Observable<MemberList[]>{
      return this.http.post<MemberList[]>(
        this.config.apiMyUrl + "/api/user/getMemberListsBySearchKey",
        user,
          { headers: this.authService.getJWT()  }
      );
    }

    emailCheck(email:string): Observable<User> {
      return this.http.get<User>(
        this.config.apiMyUrl + "/api/user/emailCheck/"+email
      );
    }

    sendEmail(email: string, passcode: string): Observable<any> {
      return this.http.get(
        this.config.apiMyUrl + "/api/user/sendmail/" + email + "/" + passcode
      );
    }

    createUser(user: User) {
      return this.http.post(
        this.config.apiMyUrl + "/api/user/createUser",user
      );
    }

    updateUser(user: User) {
      return this.http.post(
        this.config.apiMyUrl + "/api/user/updateUser",
        user
      );
    }

    upsertUser(user: User) {
      return this.http.post(
        this.config.apiMyUrl + "/api/user/upsertUser",
        user
      );
    }

    updateAcceptMembershipTerm(user: User) {
      return this.http.post(
        this.config.apiMyUrl + "/api/user/updateAcceptMembershipTerm",
        user,   { headers: this.authService.getJWT()  }
      );
    }

    updateMembershipType(user: User) {
      return this.http.post(
        this.config.apiMyUrl + "/api/user/updateMembershipType",
        user,  { headers: this.authService.getJWT()  }
      );
    }

    updateNewsLetterLanguage(user: User) {
      return this.http.post(
        this.config.apiMyUrl + "/api/user/updateNewsLetterLanguage",
        user,  { headers: this.authService.getJWT()  }
      );
    }

    confirmAddress(user: User) {
      return this.http.post(
        this.config.apiMyUrl + "/api/user/confirmAddress",
        user
      );
    }

    PasswordRecoveryTokenRequest(email: string): Observable<any> {
      return this.http.get(
        this.config.apiMyUrl +
        "/api/user/passwordRecoveryTokenRequest/" +
        email
      );
    }

    getUserProfile(userName: string): Observable<User> {
      return this.http.get<User>(
        this.config.apiMyUrl + "/api/user/getUserProfile/" + userName
      );
    }

    getUserProfileById(id: string): Observable<User> {
      return this.http.get<User>(
        this.config.apiMyUrl + "/api/user/getUserProfileById/" + id
      );
    }

    getPaymentMasterCoreByUserId(id: string): Observable<any> {
      return this.http.get(
        this.config.apiMyUrl + "/api/payment/getPaymentMasterCoreByUserId/" + id
      );
    }

    checkEmailForPasswordRecovery(email: string): Observable<any> {
      return this.http.get(
        this.config.apiMyUrl +
        "/api/user/checkUserGetTokenForPasswordRecoveryByEmail/" +
        email
      );
    }
    passwordRecovery(email: string, user: User) {
      return this.http.put(
        this.config.apiMyUrl + "/api/user/passwordRecovery/",
        user
      );
    }
    passwordRecoveryWithoutToken(email: string, user: User) {

      return this.http.put(
        this.config.apiMyUrl + "/api/user/passwordRecoveryWithoutToken/",
        user
      );
    }


    deleteUserById(Id: String) {
      return this.http.get(
        this.config.apiMyUrl + "/api/user/deleteUserById/" + Id
      );
    }

    sendConfirmationEmail(user: User) {
      return this.http.post(
        this.config.apiMyUrl + "/api/user/sendConfirmationEmail",
        user);
    }

    insertNewTemplate(memberListGridTemplate: MemberListGridTemplate) {
      return this.http.post(
        this.config.apiMyUrl + "/api/user/insertNewTemplate",
        memberListGridTemplate,{ headers: this.authService.getJWT()  });
    }

    updateTemplate(memberListGridTemplate: MemberListGridTemplate) {
      return this.http.post(
        this.config.apiMyUrl + "/api/user/updateTemplate",
        memberListGridTemplate,{ headers: this.authService.getJWT()  });
    }

    getMemberListTemplateByUser(model: User) {
      return this.http.post(
        this.config.apiMyUrl + "/api/user/getMemberListTemplateByUser",
        model,{ headers: this.authService.getJWT()});
    }

    getTemplateNamesByUser(model: User): Observable<string[]>  {
      return this.http.post<string[]>(
        this.config.apiMyUrl + "/api/user/getTemplateNamesByUser",
        model,{ headers: this.authService.getJWT()});
    }

    getMemberListTemplateByTemplateName(templateName: string): Observable<MemberListTemplate>  {
      return this.http.get<MemberListTemplate>(
        this.config.apiMyUrl + "/api/user/getMemberListTemplateByTemplateName/"+templateName,
        { headers: this.authService.getJWT()});
    }

    deleteMemberListTemplate(model: MemberListTemplate) {
      return this.http.put(
        this.config.apiMyUrl + "/api/user/deleteMemberListTemplate",model, { headers: this.authService.getJWT()}
      );
    }

    uploadFile(fileToUpload: File, user:User): Observable<any> {
      const formData: FormData = new FormData();
      formData.append(fileToUpload.name, fileToUpload);
      return this.http.post<number>(
        this.config.apiMyUrl + "/api/uploadFile/uploadFile/"+user.Id,
         formData
      );
    }

  // emailCheck(email:string): Observable<User> {
  //   return this.http.get<User>(
  //     this.config.apiMyUrl + "/api/user/emailCheck/"+email
  //   );
  // }

  // sendEmail(email: string, passcode: string): Observable<any> {
  //   return this.http.get(
  //     this.config.apiMyUrl + "/api/user/sendmail/" + email + "/" + passcode
  //   );
  // }

  // createUser(user: User) {
  //   return this.http.post(
  //     this.config.apiMyUrl + "/api/user/createUser",user
  //   );
  // }

  // updateUser(user: User) {
  //   return this.http.post(
  //     this.config.apiMyUrl + "/api/user/updateUser",
  //     user
  //   );
  // }

  // PasswordRecoveryTokenRequest(email: string): Observable<any> {
  //   return this.http.get(
  //     this.config.apiMyUrl +
  //     "/api/user/passwordRecoveryTokenRequest/" +
  //     email
  //   );
  // }

  // getUserProfile(userName: string): Observable<any> {
  //   return this.http.get(
  //     this.config.apiMyUrl + "/api/user/getUserProfile/" + userName
  //   );
  // }

  // getUserProfileById(id: string): Observable<any> {
  //   return this.http.get(
  //     this.config.apiMyUrl + "/api/user/getUserProfileById/" + id
  //   );
  // }

  // checkEmailForPasswordRecovery(email: string): Observable<any> {
  //   return this.http.get(
  //     this.config.apiMyUrl +
  //     "/api/user/checkUserGetTokenForPasswordRecoveryByEmail/" +
  //     email
  //   );
  // }

  // passwordRecovery(email: string, user: User) {
  //   return this.http.put(
  //     this.config.apiMyUrl + "/api/user/passwordRecovery/",
  //     user
  //   );
  // }

  // passwordRecoveryWithoutToken(email: string, user: User) {
  //   return this.http.put(
  //     this.config.apiMyUrl + "/api/user/passwordRecoveryWithoutToken/",
  //     user
  //   );
  // }

  // deleteUserById(Id: String) {
  //   return this.http.get(
  //     this.config.apiMyUrl + "/api/user/deleteUserById/" + Id
  //   );
  // }

  // sendConfirmationEmail(user: User) {
  //   return this.http.post(
  //     this.config.apiMyUrl + "/api/user/sendConfirmationEmail",
  //     user);
  // }


}

