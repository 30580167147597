import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../../../services/auth/auth.service";
import { User } from "../../../models/user";
import { UserService } from "../../../services/user/user.service";
import { PaymentService } from "../../../services/payment/payment.service";
import { MatRadioChange } from '@angular/material/radio';
import { MatStepper } from '@angular/material/stepper';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
//For Dialog
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { CheckHavingAccountComponent } from "../../dialog/check-having-account/check-having-account.component";
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { TOOLTIP_PANEL_CLASS } from '@angular/material/tooltip';
import { PaymentMaster, LineItem, RecurringPayment } from "../../../models/payment/paymentMaster";

@Component({
  selector: 'app-supporting-membership',
  templateUrl: './supporting-membership.component.html',
  styleUrls: ['./supporting-membership.component.css']
})
export class SupportingMembershipComponent implements OnInit {
  loaded: boolean = true;
  mySelectedLanguage: string;
  firstFormGroup: FormGroup;
  strRecurring: string;
  enableEnterPaymentInfoButton: boolean = false;
  clickedEnterPaymentInfoButton: boolean = false;
  supportMembershipActive:string;
  paymentProcessing:boolean=false;

  constructor(
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    public authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog,
    private paymentService: PaymentService
  ) { }

  user: User = {
    PasswordHash: null,
    Id: null,
    UserName: null,
    Email: null,
    FirstName: null,
    LastName: null,
    Password: null,
    Prefix: null,
    Shimei: null,
    Myoji: null,
    Street: null,
    Street2: null,
    City: null,
    State: null,
    Zip: null,
    Country: null,
    Yubin_Bango: null,
    To_Do_Fu_Ken: null,
    Shi_Gun_Ku: null,
    Cho_Son: null,
    Apartment_Etc: null,
    To_Do_Fu_Ken_JP: null,
    Shi_Gun_Ku_JP: null,
    Cho_Son_JP: null,
    Apartment_Etc_JP: null,
    Gender: null,
    DOB: null,
    TelNo: null,
    TimezoneName: null,
    UTCdiff: null,
    Token: null,
    Occupation: null,
    MemberStatus: null,
    Married: null,
    ZoomAccount: null,
    Christian: null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    RegisteredDate: null,
    LastAccessedDate: null,
    //From siteground
    LoginId: null,
    MiddleNameEN: null,
    MiddleNameJP: null,
    PrefixJP: null,
    OccupationDetail: null,
    Organization: null,
    LivingCountry: null,
    PhoneEN: null,
    PhoneMobileEN: null,
    PhoneJP: null,
    PhoneMobileJP: null,
    ChurchNameEN: null,
    ChurchNameJP: null,
    MemberType: null,
    MemberDetail: null,
    MemberMemo: null,
    Shokaisha: null,
    Reason: null,
    NewsLetters: null,
    StaffReports: null,
    OtherLetters: null,
    NewsLetterLanguage: null,
    BasicRoles: null,
    //Added new
    MigrateFromSiteGround: null,
    AdminAccess: null,
    JCFNAccess: null,
    SupportingMember: null,
    LastAddressUpdatedDate:null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };
  lineItem: LineItem = {
    itemId: 0,
    name: null,
    description: null,
    quantity: 0,
    unitPrice: 0
  }
  lineItems: LineItem[] = [];
  recurringPayment: RecurringPayment = {
    RecurringPaymentId: 0,
    RecurringId: null,
    Interval: null,
    StartDate: null,
    EndDate: null,
    CreatedDate: null
  }
  paymentMaster: PaymentMaster = {
    PaymentMasterId: 0,
    FirstName: null,
    LastName: null,
    Email: null,
    DataDescriptor: null,
    DataValue: null,
    ItemName: null,
    Amount: null,
    Recurring: null,
    RecurringId: null,
    CreatedDate: null,
    ProviderName:null,
    lineItems: this.lineItems,
    recurringPayment: this.recurringPayment
  }

  ngOnInit(): void {
    /************************************************************
     * All people who come to this page is already logged in.
     ***********************************************************/
    this.mySelectedLanguage = this.router.url.includes('EN') == true ? "EN" : "JP";
    this.firstFormGroup = this._formBuilder.group({
      myAcceptRecurring: ['', Validators.required],
    });

    if (sessionStorage.getItem("loginUserName") != null) {
      var loginUser = sessionStorage.getItem("loginUserName")
      this.loaded = false;
      /******************************
       *  A) User Info for login user
       ******************************/
      this.userService.getUserProfile(loginUser).subscribe(result => {
        this.user = result;
        if (this.user != null) {
          sessionStorage.setItem("loginUserFirstName", this.user.FirstName);
          sessionStorage.setItem("myFirstName", this.user.FirstName);
          sessionStorage.setItem("myLastName", this.user.LastName);
          sessionStorage.setItem("myEmail", this.user.Email);
         this.checkSupportingMembershipStatus(loginUser);
        }
        this.loaded = true;
      });
    } else {
      this.loaded = true;
    }
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  checkSupportingMembershipStatus(loginUser: string) {
    this.loaded = false;
    this.paymentService.getLatestMembershipPaymentInfoByUserName(loginUser).subscribe(result => {
      if (result != null) {
        this.paymentMaster = result;
        var today = new Date();
        var myday = today.getDate();
        var myMonth = today.getMonth();
        var myYear = today.getFullYear() - 1;
        var newDate = new Date(myYear, myMonth, myday);
        if (this.paymentMaster != null) {
          if (this.user.SupportingMember == true && this.paymentMaster.Recurring == true) {
            this.supportMembershipActive = 'done';
          } else if (this.user.SupportingMember == true && this.paymentMaster.Recurring == false &&
            new Date(this.paymentMaster.CreatedDate).getTime() >= newDate.getTime()) {
            this.supportMembershipActive = 'done';
          } else if (this.user.SupportingMember == true && this.paymentMaster.Recurring == false &&
            new Date(this.paymentMaster.CreatedDate).getTime() < newDate.getTime()) {
            this.supportMembershipActive = 'processing';
          } else {
          }
        } else {
          if (this.user.SupportingMember == true) {
            this.supportMembershipActive = 'processing';
          } else {
          }
        }
        this.loaded = true;
        console.log('supportMembershipActive: ' + this.supportMembershipActive);
      }
    });
  }

  checkSupportMembershipPayment(){
    return this.supportMembershipActive;
  }

  onChangeAcceptRecurring($event: MatRadioChange) {
    /**********************************************************
     *  I don't know but MatRadioChange returns always string,
     * so it need to be converted to boolean
    ***********************************************************/
    this.paymentMaster.Recurring = $event.value == 'true' ? true : false;
    this.setMyRecurring();
    //SessionStorage must be all string
    sessionStorage.setItem("supportingMembershipRecurring", this.paymentMaster.Recurring.toString());
    sessionStorage.setItem("supportingMembershipRecurringInterval", 'yearly');
    sessionStorage.setItem("supportingMembershipItemName", 'Supporting Membership');
    sessionStorage.setItem("supportingMembershipAmount", '40');
    //sessionStorage.setItem("supportingMembershipAmount", '1');
    sessionStorage.setItem("supportingMembershipProviderName", 'Authorize.net');
    // var tempStartDate = new Date();
    // tempStartDate.setDate(tempStartDate.getDate() + 1);
    //sessionStorage.setItem("supportingMembershipRecurringStartDate", tempStartDate.toString());
    //Following is important to check and prevent paying multiple times
    sessionStorage.setItem("supportingMembershipPreventCallingFunctionMultipleTimes", "mySession");
    /*************************************************************************
    According to Authorize.net, there is the following chainedInstruction.
    "IMPORTANT: When using the payment information form, be sure that your page
    loads the library after the button is defined."
    https://developer.authorize.net/api/reference/features/acceptjs.html
    To follow above instruction, I found it is important to import Authorize.net
    javascript program after this page is all loaded. Below line is to import
    javascript library into Angular component. First, I inserted this line in the
    ngOnInit, but is was not enough. The safest way is to add event to import the
    javascript library.
    Below line enable to open popup window to enter card information created by
    Authorize.net.
 * **************************************************************************** */
    //this.loadScript('https://jstest.authorize.net/v3/AcceptUI.js');
    this.loadScript('https://js.authorize.net/v3/AcceptUI.js');
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  setMyRecurring() {
    if (this.paymentMaster.Recurring == true) {
      this.strRecurring = 'true'
    } else if (this.paymentMaster.Recurring == false) {
      this.strRecurring = 'false';
    }
    this.enableEnterPaymentInfoButton = true;
  }

  onClickEnterPaymentInfo() {
   // this.clickedEnterPaymentInfoButton = true;
   // this.loaded = false;

    this.paymentProcessing=true;
  }

  onClickGoBack(){
    this.router.navigate(["/members/supportingTop/" +this.getMyLanguage()]);
  }
}
