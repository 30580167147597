<div class="myBase">
    <div class="myBackgroundLayer">
     <div class="row">
      <div class="myAccountTitle">
       <h2>
       </h2>
      </div>
     </div>
     <div class="d-flex justify-content-center">
      <mat-card class="supportingMembershipAnounce">
   <br><br><br>
       <h4>
        {{this.router.url.includes('EN')==true?'Registration completed successfully.': 'レジストレーションが完了しました。'}}
        {{this.router.url.includes('EN')==true?'Zoom Link will be sent to you around Jan10,2022.': '2022年1月10日頃、当日のZOOMのリンクが送られてきます。'}}
       </h4>
       <br><br><br>
   
       <div *ngIf="this.authService.isLoggedIn()">
        <a [routerLink]="this.router.url.includes('EN')==true?'/members/myProfile/EN': '/members/myProfile/JP'"
          class="btn btn-link">{{this.router.url.includes('EN')==true?'My Account': 'マイアカウント'}}</a>
      </div>
      <div *ngIf="this.authService.isLoggedIn()==false">
        <a [routerLink]="this.router.url.includes('EN')==true?'/home/EN': '/home/JP'"
          class="btn btn-link">{{this.router.url.includes('EN')==true?'Home': 'ホーム'}}</a>&nbsp;&nbsp;
      </div>
      </mat-card>
     </div>
    </div>
   </div>