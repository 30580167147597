import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA,MatDialogConfig} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { UserService} from "../../../services/user/user.service";
import { Router, ActivatedRoute } from "@angular/router";
import { EnterSearchingEmailComponent} from '../../dialog/enter-searching-email/enter-searching-email.component';
import { PasswordRecoveryEnterEmailComponent} from '../../dialog/password-recovery-enter-email/password-recovery-enter-email.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import  { User} from "../../../models/user";
import { AuthService } from "../../../services/auth/auth.service";
import { UserEditOthersProfileComponent  } from '../../dialog/user-edit-others-profile/user-edit-others-profile.component';

export interface DialogData {
  model:User
}

@Component({
  selector: 'app-request-address-update',
  templateUrl: './request-address-update.component.html',
  styleUrls: ['./request-address-update.component.css']
})
export class RequestAddressUpdateComponent implements OnInit {
  firstFormGroup: FormGroup;
  loaded:boolean=true;
  userSearched:boolean=true;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  myMessage:string;
  showMessageUserFound:boolean=false;
  showUSAddress:boolean=true;
  showJapanAddress:boolean=true;

  user: User = {
    PasswordHash:null,
    Id: null,
    UserName: null,
    Email: null,
    FirstName: null,
    LastName: null,
    Password: null,
    Prefix: null,
    Shimei: null,
    Myoji: null,
    Street: null,
    Street2: null,
    City: null,
    State: null,
    Zip: null,
    Country: null,
    Yubin_Bango: null,
    To_Do_Fu_Ken: null,
    Shi_Gun_Ku: null,
    Cho_Son: null,
    Apartment_Etc: null,
    To_Do_Fu_Ken_JP: null,
    Shi_Gun_Ku_JP: null,
    Cho_Son_JP: null,
    Apartment_Etc_JP: null,
    Gender: null,
    DOB: null,
    TelNo: null,
    TimezoneName:null,
    UTCdiff:null,
    Token:null,
    Occupation:null,
    MemberStatus:null,
    Married:null,
    ZoomAccount:null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    RegisteredDate:null,
    LastAccessedDate:null,
    //From siteground
    LoginId:null,
    MiddleNameEN:null,
    MiddleNameJP:null,
    PrefixJP:null,
    OccupationDetail:null,
    Organization:null,
    LivingCountry:null,
    PhoneEN:null,
    PhoneMobileEN:null,
    PhoneJP:null,
    PhoneMobileJP:null,
    ChurchNameEN:null,
    ChurchNameJP:null,
    MemberType:null,
    MemberDetail:null,
    MemberMemo:null,
    Shokaisha:null,
    Reason:null,
    NewsLetters:null,
    StaffReports:null,
    OtherLetters:null,
    NewsLetterLanguage:null,
    BasicRoles:null,
    //Added new
    MigrateFromSiteGround:null,
    AdminAccess:null,
    JCFNAccess:null,
    SupportingMember:null,
    LastAddressUpdatedDate:null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };

  loading = false;
  Email: string;
  Password: string;
  mySelectedLanguage: string;


  constructor(
    private authService: AuthService,
    private userService:UserService,
    public router: Router,
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private registrationService: UserService,
    public dialogRef: MatDialogRef<EnterSearchingEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog
  ){}

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      myPassword: [null, [Validators.required]]
    });
    if(this.data.model !=null){
      this.loaded=false;
      this.user.Id = this.data.model.Id;
      this.user.FirstName = this.data.model.FirstName;
      this.user.LastName = this.data.model.LastName;
      this.user.Street = this.data.model.Street;
      this.user.Street2 = this.data.model.Street2;
      this.user.City = this.data.model.City;
      this.user.State = this.data.model.State;
      this.user.Zip = this.data.model.Zip;
      this.user.Country = this.data.model.Country;
      this.user.Yubin_Bango = this.data.model.Yubin_Bango;
      this.user.To_Do_Fu_Ken = this.data.model.To_Do_Fu_Ken;
      this.user.Shi_Gun_Ku = this.data.model.Shi_Gun_Ku;
      this.user.Cho_Son = this.data.model.Cho_Son;
      this.user.Apartment_Etc = this.data.model.Apartment_Etc;
      this.user.To_Do_Fu_Ken_JP = this.data.model.To_Do_Fu_Ken_JP;
      this.user.Shi_Gun_Ku_JP = this.data.model.Shi_Gun_Ku_JP;
      this.user.Cho_Son_JP = this.data.model.Cho_Son_JP;
      this.user.Apartment_Etc_JP = this.data.model.Apartment_Etc_JP;
      this.user.LivingCountry = this.data.model.LivingCountry;
      this.loaded=true;
    }
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  requestAddressUpdate(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight = '500px'; //Desktop
    dialogConfig.maxWidth = '500px';//Desktoop
    dialogConfig.height = '100%';//Mobile
    dialogConfig.width = '100%';//Mobile
    dialogConfig.panelClass = 'custom-modalbox'; //Refer global level
    dialogConfig.data = { model: this.user };
    const dialogRef = this.dialog.open(RequestAddressUpdateComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var redirectPage = result;
      //  this.redirectPage(this.codeMaster.CodeType);
      if (result != null) {
        console.log("Confirmed Action: " + redirectPage);
      }
    })
  }

  onConfirmAddress() {
    //1. Update  database
    this.loaded = false;
    this.user.LastAddressUpdatedDate = new Date();
    this.userService.confirmAddress(this.user).subscribe(
      data => {
        console.log('address confirmed successfully');
        this.myMessage = this.getMyLanguage()=="EN"?"User updated successfully.":"ユーザー情報がアップデイトされました。";
        this.loaded = true;
      },
      error => {
        this.myMessage = this.getMyLanguage()=="EN"?"Data save failed.":"問題が生じ、ユーザー情報がアップデイトされませんでした。";
        this.openSnackBar('error', 'close');
        this.loaded = true;
      }
    )

    //2. Redirect to supporting membership page
    this.loaded = true;
    //this.router.navigate(["/members/acceptTerms/" + this.getMyLanguage()]);
    //this.dialogRef.close();
    // *** as of 08/02/23 *****
    this.router.navigate(["/members/myProfile/"+this.getMyLanguage()]);
    this.dialogRef.close();
  }

  onEditAddress(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1000px';//Desktoop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ Id: this.user.Id };
    const dialogRef = this.dialog.open(UserEditOthersProfileComponent,dialogConfig);
    //  {
    //   width: '1000px',
    //   height: '800px',
    //   data: {
    //     Id: row.Id
    //   }
    // });
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
        console.log("returned AS400Code: " + confirmedAction.AS400Code);
      }
     this.getUpdatedUserById(this.user.Id);
    })
  }

  getUpdatedUserById(Id:string){
    this.loaded=false;
    if(this.user!=null){
      this.userService.getUserProfileById(this.user.Id).subscribe(result => {
        this.user = result;
        this.loaded=true;
      });
    }else{
      this.loaded=true;
    }

  }

  onClickBack(){
    const dialogRef = this.dialog.open(EnterSearchingEmailComponent, {
      width: '500px',
      height:'500px',
      data: {
        myLanguage:this.mySelectedLanguage
      }
    });
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 5000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 12000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }
}
