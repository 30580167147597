<div class="myBody  myBodySub">
  <div class="myHeaderBase d-flex justify-content-center">
    <div class="myHeaderCore">
      <div>
        <!-- <img src='../../../../../assets/images/thumbnail_ec22logo.jpg' class="ecLogo"> -->
      </div>
    </div>
  </div>
  <div class="myMain">
    <div clas="myMainLeft">
      <div class="myMainHalfTop">
        <!-- Left Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Left Bottom -->
      </div>
    </div>
    <div class="myMainCenter">
      <div class="myMainHalfTop d-flex justify-content-center">
        <!-- Main Top -->

        <mat-card class="mainCard b-radius">
          <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
          <div *ngIf="!loaded">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>

          <!-- <form *ngIf="userFromServer | async; else loading"  -->
          <form [formGroup]="myFormGroup" (ngSubmit)="onSubmit()">
            <div class="float-end stepNumber">6/8</div>
            <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Participation Schedule':
              '参加日程'}}
              <i class="stepTitleSub">
                {{this.router.url.includes('EN')==true?'Required': '必須'}}
              </i>
            </h2>
            <!-- <div class="stepTitle2nd">
              {{this.router.url.includes('EN')==true?
              'Buses from the airport to the venue will be available only on December 27 and 28, and from the venue to
              the airport only on January 1. On December 29, 30, and 31, there will be no transportation between airport
              and venue, so you must arrange your own transportation. Please note that partial participation is not
              available for those who cannot arrange transportation.'
              :
              '空港から会場へのバスは12月27日と28日のみ、また、会場から空港へのバスは、1月1日のみです。29日、30日、31日には、空港と会場間の送迎がないため、各自で送迎の手配が必要です。送迎の手配ができない方は、部分参加は不可ですのでご了承ください。'}}
            </div> -->
            <div class="stepTitle2nd">
              {{this.router.url.includes('EN')==true?'Select the date you participate.':
              '全参加ですか？部分参加ですか？'}}
            </div>
            <mat-radio-group formControlName="myLodgingSchedule" (change)="onChangeMyLodgingSchedule($event)"
              class="rbGroup">
              <div *ngIf="loaded" class="d-flex justify-content-center">
                <mat-radio-button value="StayAllDays" class="rbItem" [checked]="StayAllDays==true">
                  {{this.router.url.includes('EN')==true?'Attend all days': '全参加'}}
                </mat-radio-button>
                <mat-radio-button value="StayPartially" class="rbItem" [checked]="StayPartially==true">
                  {{this.router.url.includes('EN')==true?'Attend Partially': '部分参加'}}</mat-radio-button>
              </div>
            </mat-radio-group>
            <br>

            <div *ngIf="showMessageForPartial">
              <div class="stepTitle2nd">
                {{this.router.url.includes('EN')==true?
                'The fee for partial participation with overnight stay includes three meals per night. Additional meals may also be available upon request. Day participants may request additional meals. For partial participants, the office will confirm the meal plan later.'
                :
                '宿泊を伴う部分参加の料金には、1泊につき3食の食事が含まれます。また、追加の食事をお申し込みいただくことも可能です。日帰り参加の方は追加で食事をお申し込みいただけます。部分参加の方には、後日、事務局からお食事の予定について確認させていただきます。よろしくお願いします。'}}
              </div>
            </div>
<br><br>


            <div *ngIf="showLodgingSchedule">
              <div class="stepTitle2nd">
                {{this.router.url.includes('EN')==true?'Please select the date you would like to attend':
                '参加される日を選んでください。'}}
              </div>

              <div class="row summaryTable">
                <div class="col-2 col-sm-2 col-md-2">
                  {{this.router.url.includes('EN')==true?'May 3 Wed': '5月3日 (水)'}}
                </div>
                <div class="col-10 col-sm-4 col-md-10">
                  <div *ngIf="loaded">
                    <mat-radio-group formControlName="myMay3" (change)="onChangeMay3($event)" class="rbGroupLodging">
                      <mat-radio-button value="NoParticipateMay3" class="rbItemLodging"
                        [checked]="conference.May3=='NoParticipateMay3'">
                        {{this.router.url.includes('EN')==true?'No Participate': '参加しない。'}}
                      </mat-radio-button>
                      <mat-radio-button value="NoStayMay3" class="rbItemLodging"
                        [checked]="conference.May3=='NoStayMay3'">
                        {{this.router.url.includes('EN')==true?'No Stay': '日帰り'}}
                      </mat-radio-button>
                      <mat-radio-button value="StayMay3" class="rbItemLodging" [checked]="conference.May3=='StayMay3'">
                        {{this.router.url.includes('EN')==true?'Stay': '宿泊'}}
                      </mat-radio-button>
                      <!-- <mat-checkbox [disabled]="true" value=true formControlName="myMay3Breakfast"
                        [checked]="May3Breakfast==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Breakfast': '朝食'}}
                      </mat-checkbox>
                      <mat-checkbox [disabled]="true" value=true formControlName="myMay3Lunch"
                        [checked]="May3Lunch==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Lunch': '昼食'}}
                      </mat-checkbox>
                      <mat-checkbox [disabled]="true" value=true formControlName="myMay3Dinner"
                        [checked]="May3Dinner==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Dinner': '夕食'}}
                      </mat-checkbox> -->
                    </mat-radio-group>
                  </div>
                </div>
              </div>
              <hr class="shceduleDevider">

              <div class="row summaryTable">
                <div class="col-2 col-sm-2 col-md-2">
                  {{this.router.url.includes('EN')==true?'May 4 Thu': '5月4日 (木)'}}
                </div>
                <div class="col-10 col-sm-4 col-md-10">
                  <div *ngIf="loaded">
                    <mat-radio-group formControlName="myMay4" (change)="onChangeMay4($event)" class="rbGroupLodging">
                      <mat-radio-button value="NoParticipateMay4" class="rbItemLodging"
                        [checked]="conference.May4=='NoParticipateMay4'">
                        {{this.router.url.includes('EN')==true?'No Participate': '参加しない。'}}
                      </mat-radio-button>
                      <mat-radio-button value="NoStayMay4" class="rbItemLodging"
                        [checked]="conference.May4=='NoStayMay4'">
                        {{this.router.url.includes('EN')==true?'No Stay': '日帰り'}}
                      </mat-radio-button>
                      <mat-radio-button value="StayMay4" class="rbItemLodging" [checked]="conference.May4=='StayMay4'">
                        {{this.router.url.includes('EN')==true?'Stay': '宿泊'}}
                      </mat-radio-button>
                      <!-- <mat-checkbox [disabled]="true" value=true formControlName="myMay4Breakfast"
                        [checked]="May4Breakfast==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Breakfast': '朝食'}}
                      </mat-checkbox>
                      <mat-checkbox [disabled]="true" value=true formControlName="myMay4Lunch"
                        [checked]="May4Lunch==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Lunch': '昼食'}}
                      </mat-checkbox>
                      <mat-checkbox [disabled]="true" value=true formControlName="myMay4Dinner"
                        [checked]="May4Dinner==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Dinner': '夕食'}}
                      </mat-checkbox> -->
                    </mat-radio-group>
                  </div>
                </div>
              </div>
              <hr class="shceduleDevider">

              <div class="row summaryTable">
                <div class="col-2 col-sm-2 col-md-2">
                  {{this.router.url.includes('EN')==true?'May 5 Fri': '5月5日 (金)'}}
                </div>
                <div class="col-10 col-sm-4 col-md-10">
                  <div *ngIf="loaded">
                    <mat-radio-group formControlName="myMay5" (change)="onChangeMay5($event)" class="rbGroupLodging">
                      <mat-radio-button value="NoParticipateMay5" class="rbItemLodging"
                        [checked]="conference.May5=='NoParticipateMay5'">
                        {{this.router.url.includes('EN')==true?'No Participate': '参加しない。'}}
                      </mat-radio-button>
                      <mat-radio-button value="NoStayMay5" class="rbItemLodging"
                        [checked]="conference.May5=='NoStayMay5'">
                        {{this.router.url.includes('EN')==true?'No Stay': '日帰り'}}
                      </mat-radio-button>
                      <mat-radio-button value="StayMay5" class="rbItemLodging" [checked]="conference.May5=='StayMay5'">
                        {{this.router.url.includes('EN')==true?'Stay': '宿泊'}}
                      </mat-radio-button>
                      <!-- <mat-checkbox [disabled]="true" value=true formControlName="myMay5Breakfast"
                        [checked]="May5Breakfast==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Breakfast': '朝食'}}
                      </mat-checkbox>
                      <mat-checkbox [disabled]="true" value=true formControlName="myMay5Lunch"
                        [checked]="May5Lunch==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Lunch': '昼食'}}
                      </mat-checkbox>
                      <mat-checkbox [disabled]="true" value=true formControlName="myMay5Dinner"
                        [checked]="May5Dinner==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Dinner': '夕食'}}
                      </mat-checkbox> -->
                    </mat-radio-group>
                  </div>
                </div>
              </div>
              <hr class="shceduleDevider">

              <div class="row summaryTable">
                <div class="col-2 col-sm-2 col-md-2">
                  {{this.router.url.includes('EN')==true?'May 6 Sat': '5月6日 (土)'}}
                </div>
                <div class="col-10 col-sm-4 col-md-10">
                  <div *ngIf="loaded">
                    <mat-radio-group formControlName="myMay6" (change)="onChangeMay6($event)" class="rbGroupLodging">
                      <mat-radio-button value="NoParticipateMay6" class="rbItemLodging"
                        [checked]="conference.May6=='NoParticipateMay6'">
                        {{this.router.url.includes('EN')==true?'No Participate': '参加しない。'}}
                      </mat-radio-button>
                      <mat-radio-button value="NoStayMay6" class="rbItemLodging"
                        [checked]="conference.May6=='NoStayMay6'">
                        {{this.router.url.includes('EN')==true?'No Stay': '日帰り'}}
                      </mat-radio-button>
                      <!-- <mat-radio-button value="StayMay6" class="rbItemLodging" [checked]="conference.May6=='StayMay6'">
                        {{this.router.url.includes('EN')==true?'Stay': '宿泊'}}
                      </mat-radio-button> -->
                      <!-- <mat-checkbox [disabled]="true" value=true formControlName="myMay6Breakfast"
                        [checked]="May6Breakfast==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Breakfast': '朝食'}}
                      </mat-checkbox>
                      <mat-checkbox [disabled]="true" value=true formControlName="myMay6Lunch"
                        [checked]="May6Lunch==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Lunch': '昼食'}}
                      </mat-checkbox>
                      <mat-checkbox [disabled]="true" value=true formControlName="myMay6Dinner"
                        [checked]="May6Dinner==true" class="cbItemMeal">
                        {{this.router.url.includes('EN')==true?'Dinner': '夕食'}}
                      </mat-checkbox> -->
                      <!-- <div>
                        {{this.router.url.includes('EN')==true?'12/31 stay includes dinner on 12/31, breakfast and lunch
                        on 1/1': '12/31の宿泊には、12/31の夕食、1/1の朝食、昼食付'}}

                      </div> -->
                    </mat-radio-group>
                  </div>
                </div>
              </div>
            </div>
            <!-- ////////////////////////////////////////////////////////////////////////////////////// -->
            <!-- <div *ngIf="showCalculation">
              <div class="d-flex justify-content-center">
                <div class="registrationCostDetail">
                  <div class="row summaryTable2">

                    <div class="row">
                      <div class="col-6 col-sm-6 col-md-3">
                        {{this.router.url.includes('EN')==true?'':''}}
                      </div>
                      <div class="col-6 col-sm-6 col-md-2 manType">
                        {{this.router.url.includes('EN')==true?'You':'あなた'}}
                      </div>
                      <div *ngIf="numberOfKidsUnder18>=1" class="col-6 col-sm-6 col-md-2 manType">
                        {{this.router.url.includes('EN')==true?'Child':kidNo1Name}}
                      </div>
                      <div *ngIf="numberOfKidsUnder18>=2" class="col-6 col-sm-6 col-md-2 manType">
                        {{this.router.url.includes('EN')==true?'Child':kidNo2Name}}
                      </div>
                      <div *ngIf="numberOfKidsUnder18>=3" class="col-6 col-sm-6 col-md-2 manType">
                        {{this.router.url.includes('EN')==true?'Child':kidNo3Name}}
                      </div>
                      <div *ngIf="numberOfKidsUnder18>=4" class="col-6 col-sm-6 col-md-2 manType">
                        {{this.router.url.includes('EN')==true?'Child':kidNo4Name}}
                      </div>
                    </div>

                    <div class="row">
                      <div>
                        {{this.router.url.includes('EN')==true?'Lodging:':'宿泊：'}}
                      </div>
                      <div class="col-6 col-sm-6 col-md-3">
                        {{this.router.url.includes('EN')==true?'':''}}
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{this.stayAmountMyself | currency:"JPY":"symbol"}}
                        </div>
                      </div>
                      <div *ngIf="numberOfKidsUnder18>=1" class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{stayAmountKidNo1 | currency:"JPY":"symbol"}}
                        </div>
                      </div>
                      <div *ngIf="numberOfKidsUnder18>=2" class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{stayAmountKidNo2 | currency:"JPY":"symbol"}}
                        </div>
                      </div>
                      <div *ngIf="numberOfKidsUnder18>=3" class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{stayAmountKidNo3 | currency:"JPY":"symbol"}}
                        </div>
                      </div>
                      <div *ngIf="numberOfKidsUnder18>=4" class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{stayAmountKidNo4 | currency:"JPY":"symbol"}}
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Day trip:':'日帰り：'}}
                      </div>
                      <div class="col-6 col-sm-6 col-md-3">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          dayTrip+'Days'
                          :
                          dayTrip+'日'
                          }}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{noStayAmountMyself| currency:"JPY":"symbol"}}
                        </div>
                      </div>
                      <div *ngIf="numberOfKidsUnder18>=1" class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{noStayAmountKidNo1| currency:"JPY":"symbol"}}
                        </div>
                      </div>
                      <div *ngIf="numberOfKidsUnder18>=2" class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{noStayAmountKidNo2| currency:"JPY":"symbol"}}
                        </div>
                      </div>
                      <div *ngIf="numberOfKidsUnder18>=3" class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{noStayAmountKidNo3| currency:"JPY":"symbol"}}
                        </div>
                      </div>
                      <div *ngIf="numberOfKidsUnder18>=4" class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{noStayAmountKidNo4| currency:"JPY":"symbol"}}
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Sub Total:':'小計：'}}
                      </div>
                      <div class="col-6 col-sm-6 col-md-3">
                        <div class="summaryTitle">

                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{subTotalMyself| currency:"JPY":"symbol"}}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div *ngIf="numberOfKidsUnder18>=1" class="summaryValue">
                          {{subTotalKidNo1| currency:"JPY":"symbol"}}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div *ngIf="numberOfKidsUnder18>=2" class="summaryValue">
                          {{subTotalKidNo2| currency:"JPY":"symbol"}}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div *ngIf="numberOfKidsUnder18>=3" class="summaryValue">
                          {{subTotalKidNo3| currency:"JPY":"symbol"}}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div *ngIf="numberOfKidsUnder18>=4" class="summaryValue">
                          {{subTotalKidNo4| currency:"JPY":"symbol"}}
                        </div>
                      </div>
                    </div>

                    <div *ngIf="conference.ConferenceDiscountAmount!=null" class="row">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Discount :':'割引：'}}
                      </div>
                      <div class="col-9 col-sm-9 col-md-12">
                        <div class="discountValues">
                          - &nbsp;{{conference.ConferenceDiscountAmount | currency:"JPY":"symbol"}}
                        </div>
                      </div>
                    </div>

                    <div *ngIf="conference.ApplicationFeePaidDate!=null" class="row">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Application Fee :':'申込金：'}}
                      </div>
                      <div class="col-9 col-sm-9 col-md-12">
                        <div class="discountValues">
                          - &nbsp;{{conference.ApplicationFee | currency:"JPY":"symbol"}}
                        </div>
                      </div>
                    </div>
                    <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                    <div>
                      {{this.router.url.includes('EN')==true?'Total :':'総計：'}}
                    </div>
                    <div class="col-9 col-sm-9 col-md-12">
                      <div class="summaryValueTotal">
                        {{conference.ConferenceFee | currency:"JPY":"symbol"}}
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div> -->
            <div class="position-absolute bottom-0 start-50 translate-middle-x">
            <div class="d-flex justify-content-center">
              <div class="processButtonSet">
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn btnNext" (click)="onClickGoBack()">
                    {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                  </button>
                  <button [disabled]="!myFormGroup.valid" type="button" class="btn btnNext"
                    (click)="onClickGoForward()">
                    {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                  </button>
                </div>
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn link btnCancel" (click)="cancelProcess()">Cancel</button>
                </div>
              </div>
            </div>
            </div>

            <!-- <button type="submit" [disabled]="!myFormGroup.valid">Submit</button> -->
          </form>
        </mat-card>

      </div>
      <div class="myMainHalfBottom">
        <!-- Main Bottom -->
      </div>
    </div>
    <div class="myMainRight">
      <div class="myMainHalfTop">
        <!-- Right Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Right Bottom -->
      </div>
    </div>
  </div>
  <!-- <footer>footer</footer> -->
</div>
