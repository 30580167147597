<!-- <iframe 
width="1230"
height="900"
src="https://app.powerbi.com/reportEmbed?reportId=65355805-e5c6-41de-94ad-4cc3736bc48d&autoAuth=true&ctid=0a7803c2-5498-4a38-8cac-ae7420dc2f58&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLXdlc3QyLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9" frameborder="0" allowFullScreen="true"></iframe>
 -->



<!-- <iframe 
width="1230"
height="900"


src="https://app.powerbi.com/reportEmbed?reportId=65355805-e5c6-41de-94ad-4cc3736bc48d&autoAuth=true&ctid=0a7803c2-5498-4a38-8cac-ae7420dc2f58&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLXdlc3QyLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9" frameborder="0" allowFullScreen="true"></iframe> -->
<!-- 

<iframe
width="1840" height="850" 

src="https://app.powerbi.com/reportEmbed?reportId=65355805-e5c6-41de-94ad-4cc3736bc48d&autoAuth=true&ctid=0a7803c2-5498-4a38-8cac-ae7420dc2f58&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLXdlc3QyLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9" frameborder="0" allowFullScreen="true"></iframe> -->


<iframe
width="1840" height="850" 
 src="https://app.powerbi.com/view?r=eyJrIjoiYTg1YTcwZDctNjlmZS00ZmM0LWI5OWEtNGI4YzYzODg0NTJlIiwidCI6IjBhNzgwM2MyLTU0OTgtNGEzOC04Y2FjLWFlNzQyMGRjMmY1OCJ9&pageName=ReportSection" frameborder="0" allowFullScreen="true"></iframe>