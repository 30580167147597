import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { ParticipantEditMyAccountComponent } from '../../dialog/participant-edit-my-account/participant-edit-my-account.component';
import { Router, ActivatedRoute } from "@angular/router";
import { MembersListComponent } from '../../../admin/members-list/members-list.component';
import { User } from "../../../../models/user";
import { UserConference } from 'src/app/models/conference/userConference';

export interface DialogData {
  user: UserConference
}

@Component({
  selector: 'app-registration-payment-embed',
  templateUrl: './registration-payment-embed.component.html',
  styleUrls: ['./registration-payment-embed.component.css']
})
export class RegistrationPaymentEmbedComponent implements OnInit {

  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<RegistrationPaymentEmbedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog,
    /**************************************************
     * Followings componenst used to call function
     * in those componets from this class
    ***************************************************/
    private membersList: MembersListComponent,
  ) { }

 // myId: string;

  ngOnInit(): void {
  //  this.myId = this.data.user.Id;
    sessionStorage.setItem('myECSearchingUser',JSON.stringify({ myECSearchUser: this.data.user }));
  }

  onClickClose() {
    sessionStorage.removeItem("searchingMemberId"); //remove seaching ID when closing
    sessionStorage.removeItem('myECSearchingUser');
    console.log('Im closing!');
    this.dialogRef.close();
    /**********************************
     * Retrieve updated list of users.
     *********************************/
    this.membersList.ngOnInit();
  }
}
