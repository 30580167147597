<div class="myBody">
  <div class="myHeaderBase d-flex justify-content-center">
    <div class="myHeaderCore">
      <div>
        <img src='../../../../../assets/images/thumbnail_eclogo.png' class="ecLogo">
      </div>
    </div>
  </div>
  <div class="myMain">
    <div clas="myMainLeft">
      <div class="myMainHalfTop">
        <!-- Left Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Left Bottom -->
      </div>
    </div>
    <div class="myMainCenter">
      <div class="myMainHalfTop d-flex justify-content-center">
        <!-- Main Top -->

        <mat-card class="mainCard b-radius">
          <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
          <div *ngIf="!loaded">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <!-- <form *ngIf="userFromServer | async; else loading"  -->
          <form [formGroup]="myFormGroup" (ngSubmit)="onSubmit()">
            <div class="float-end stepNumber">4/12</div>
            <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Volunteers':
              '奉仕'}}
            </h2>
            <div class="stepTitle2nd">
              {{this.router.url.includes('EN')==true?"Please check the boxes that you're interested in volunteering for. (You can choose as many as you like) A representative will contact you later.":
              '希望される奉仕にチェックを入れてください。（いくつ入れても可）　後ほど担当者から連絡させていただきます。'}}
            </div>
            <div *ngIf="conference.ProgramType=='Youth'" class="stepTitle2nd">
              <u>{{this.router.url.includes('EN')==true?'Youth are not eligible.':
                'ユースは対象外'}}
              </u>
            </div>
            <div *ngIf="conference.ProgramType=='Seekers'" class="stepTitle2nd">
              <u>{{this.router.url.includes('EN')==true?'Seekers are not eligible.':
                'シーカーズは対象外'}}
              </u>
            </div>
            <!-- <div class="stepTitle2nd">
                        {{this.router.url.includes('EN')==true?'For more information about the service, click ':
                        '奉仕についての詳細は'}}<a href="http://equipper.org/ec17/index.php?option=com_content&view=article&id=32:ecchart&catid=8&lang=ja&Itemid=168" target="_blank">
                          {{this.router.url.includes('EN')==true?'here': 'こちら'}}
                        </a>
                        {{this.router.url.includes('EN')==true?'(link will be updated to EC).':
                        'を参照　（リンクはECにUpdateされます。）'}}
                    </div> -->
            <div class="registrationInputFormLg">
              <div>
                <mat-checkbox value='Prayer' [checked]=prayerChecked class="radioItemVolunteer"
                  (change)="onCheckVolunteer($event)" [disabled]="conference.ProgramType=='Seekers'"
                  >
                  {{this.router.url.includes('EN')==true?'Prayer': '祈り'}}
                </mat-checkbox>
                <mat-checkbox value='Praise Team' [checked]=praiseTeamChecked class="radioItemVolunteer"
                  (change)="onCheckVolunteer($event)" [disabled]="conference.ProgramType=='Seekers'">
                  {{this.router.url.includes('EN')==true?'Praise Team': '賛美チーム'}}
                </mat-checkbox>
                <mat-checkbox value='Bible Reading' [checked]=bibleReadingChecked class="radioItemVolunteer"
                  (change)="onCheckVolunteer($event)" [disabled]="conference.ProgramType=='Seekers'">
                  {{this.router.url.includes('EN')==true?'Bible Reading': '聖書朗読'}}
                </mat-checkbox>
                <!-- <mat-checkbox value='Skit' [checked]=skitChecked class="radioItemVolunteer" (change)="onCheckVolunteer($event)">
                                {{this.router.url.includes('EN')==true?'Skit': 'スキット'}}
                          </mat-checkbox> -->
                <mat-checkbox value='PA' [checked]=PAChecked class="radioItemVolunteer"
                  (change)="onCheckVolunteer($event)" [disabled]="conference.ProgramType=='Seekers'">
                  {{this.router.url.includes('EN')==true?'PA': '音響'}}
                </mat-checkbox>
                <mat-checkbox value='Propresenter' [checked]=propresenterChecked class="radioItemVolunteer"
                  (change)="onCheckVolunteer($event)" [disabled]="conference.ProgramType=='Seekers'">
                  {{this.router.url.includes('EN')==true?'Propresenter, PPT': '投影（Propresenter, PPT）'}}
                </mat-checkbox>
                <mat-checkbox value='Video/Photo' [checked]=videoPhotoChecked class="radioItemVolunteer"
                  (change)="onCheckVolunteer($event)" [disabled]="conference.ProgramType=='Seekers'">
                  {{this.router.url.includes('EN')==true?'Video Photo': 'ビデオ　写真'}}
                </mat-checkbox>
                <!-- <mat-checkbox value='Interpreter' [checked]=interpreterChecked class="radioItemVolunteer"
                  (change)="onCheckVolunteer($event)" [disabled]="conference.ProgramType=='Seekers'">
                  {{this.router.url.includes('EN')==true?'Interpreter': '通訳'}}
                </mat-checkbox>
                <mat-checkbox value='Translator' [checked]=translatorChecked class="radioItemVolunteer"
                  (change)="onCheckVolunteer($event)" [disabled]="conference.ProgramType=='Seekers'">
                  {{this.router.url.includes('EN')==true?'Translator': '翻訳'}}
                </mat-checkbox> -->
                <mat-checkbox value='Interpreter/Translator' [checked]=interpreterTranslatorChecked class="radioItemVolunteer"
                  (change)="onCheckVolunteer($event)" [disabled]="conference.ProgramType=='Seekers'">
                  {{this.router.url.includes('EN')==true?'Interpreter/Translator': '通訳/翻訳'}}
                </mat-checkbox>



                <mat-checkbox value='Usher' [checked]=usherChecked class="radioItemVolunteer"
                  (change)="onCheckVolunteer($event)" [disabled]="conference.ProgramType=='Seekers'">
                  {{this.router.url.includes('EN')==true?'Usher': 'アッシャー'}}
                </mat-checkbox>
                <mat-checkbox value='Small Group Leader' [checked]=smallGroupLeaderChecked class="radioItemVolunteer"
                  (change)="onCheckVolunteer($event)" [disabled]="conference.ProgramType=='Seekers'">
                  {{this.router.url.includes('EN')==true?'Small Group Leader': 'スモールグループリーダー '}}
                </mat-checkbox>
                <mat-checkbox value='Workshop Helper' [checked]=workshopHelperChecked class="radioItemVolunteer"
                  (change)="onCheckVolunteer($event)" [disabled]="conference.ProgramType=='Seekers'">
                  {{this.router.url.includes('EN')==true?'Workshop Helper': 'ワークショップヘルパー'}}
                </mat-checkbox>
                <mat-checkbox value='Kids Program Helper' [checked]=kidsProgramHelperChecked class="radioItemVolunteer"
                  (change)="onCheckVolunteer($event)" [disabled]="conference.ProgramType=='Seekers'">
                  {{this.router.url.includes('EN')==true?'Kids program Helper': 'キッズプログラムヘルパー'}}
                </mat-checkbox>
                <mat-checkbox value='Book Store' [checked]=bookstoreChecked class="radioItemVolunteer"
                  (change)="onCheckVolunteer($event)" [disabled]="conference.ProgramType=='Seekers'">
                  {{this.router.url.includes('EN')==true?'Book Store': '本屋'}}
                </mat-checkbox>
                <mat-checkbox value='Nursing' [checked]=nursingChecked class="radioItemVolunteer"
                  (change)="onCheckVolunteer($event)" [disabled]="conference.ProgramType=='Seekers'">
                  {{this.router.url.includes('EN')==true?'Nursing': '看護'}}
                </mat-checkbox>
                <mat-checkbox value='Reception' [checked]=receptionChecked class="radioItemVolunteer"
                  (change)="onCheckVolunteer($event)" [disabled]="conference.ProgramType=='Seekers'">
                  {{this.router.url.includes('EN')==true?'Reception': '受付'}}
                </mat-checkbox>
                <!-- <mat-checkbox value='Counselor' [checked]=counselorChecked class="radioItemVolunteer"
                  (change)="onCheckVolunteer($event)" [disabled]="conference.ProgramType=='Seekers'">
                  {{this.router.url.includes('EN')==true?'Counselor': 'カウンセラー'}}
                </mat-checkbox> -->
                <mat-checkbox value='Mentor' [checked]=mentorChecked class="radioItemVolunteer"
                (change)="onCheckVolunteer($event)" [disabled]="conference.ProgramType=='Seekers'">
                <div class="text-wrap">
                  {{this.router.url.includes('EN')==true?'Mentor (Those who are willing to mentor participants one-time during EC.)': 'メンター（EC中ワンタイムで参加者のメンタリングをしてくださる方）'}}
                </div>
              </mat-checkbox>

                <mat-checkbox value='Sharing Testimony' [checked]=testimoneyChecked class="radioItemVolunteer"
                  (change)="onCheckVolunteer($event)" [disabled]="conference.ProgramType=='Seekers'">
                  {{this.router.url.includes('EN')==true?'Sharing Testimony': '証'}}
                </mat-checkbox>

                <mat-checkbox value='Hospitality' [checked]=hospitalityChecked class="radioItemVolunteer"
                  (change)="onCheckVolunteer($event)" [disabled]="conference.ProgramType=='Seekers'">
                  <div class="text-wrap">
                  {{this.router.url.includes('EN')==true?"Hospitality (prepare coffee before and after the meeting, New Year's Eve soba noodles, etc.)" : 'ホスピタリティー(集会前後のコーヒーの用意、年越しそばの用意等)'}}
                  </div>

                </mat-checkbox>

                <mat-checkbox value='airportGreeterTransfer' [checked]=airportGreeterTransferChecked class="radioItemVolunteer"
                  (change)="onCheckVolunteer($event)" [disabled]="conference.ProgramType=='Seekers'">
                  {{this.router.url.includes('EN')==true?'Airport Greeter and Airport Transfer Team': '空港グリーター・空港送迎チーム'}}
                </mat-checkbox>

                <!-- <mat-checkbox value='Japan Regional Meeting regional leader'
                              [checked]=japanRegionalMeetingLeaderChecked
                                class="radioItemVolunteer"  (change)="onCheckVolunteer($event)" >
                                {{this.router.url.includes('EN')==true?'Japan Regional Meeting regional leader': '日本地域別ネットワーク地域リーダー '}}
                              </mat-checkbox> -->

                <!-- <mat-checkbox value="I would like to know any other volunteer opportunities"
                                [checked]="conference.Volunteers!=null && conference.Volunteers.includes('I would like to know any other volunteer opportunities')"
                                class="radioItemVolunteer"  (change)="onCheckVolunteer($event)" >
                                {{this.router.url.includes('EN')==true?'I would like to know any other volunteer opportunities':
                                '他の奉仕の機会があれば情報を知りたい'}}
                              </mat-checkbox>               -->
              </div>
            </div>

<!--
            <br><br>
            <div class="stepTitle">
              <label><b>
                  {{this.router.url.includes('EN')==true?"JCFN Volunteer Code of Conduct":
                  'JCFN ボランティア行動規範'}}
                </b></label><br>
              <div>
                <div class="make-scrollable scrollBackGround" flex>
                  <div *ngIf="this.router.url.includes('EN')==true">
                    1. Commit to Christian values: Embrace love, kindness, honesty, and compassion. Show kindness,
                    fairness and respect to all people regardless of background.<br>
                    2. Commit to Serve and Be healthy: Be faithful to your volunteer responsibilities and act
                    responsibly. Keep self-care in mind for this purpose.<br>
                    3. Maintain confidentiality: Keep sensitive information confidential and respect the privacy of
                    individuals.<br>
                    4. Uphold ethical behavior: Act with honesty, integrity, and avoid conflicts of interest.<br>
                    5. Demonstrate professionalism: Behave professionally, follow organizational policies, and work
                    collaboratively.<br>
                    6. Uphold personal conduct: Live according to Christian values, being positive role models.<br>
                    7. Ensure safety and well-being: Prioritize safety for all and follow provided guidelines.<br>
                    8. Embrace continuous learning: Seek growth, be open to feedback, and learn about community
                    needs.<br>
                  </div>
                  <div *ngIf="this.router.url.includes('EN')!=true">
                    1. クリスチャンの価値を守る：愛、親切、誠実、思いやりを大切にする。背景に関わらず、すべての人々に親切、公正、尊重を示す。<br>
                    2. 健康的・献身的な奉仕：ボランティアの責任を忠実に果たし、責任を持って行動する。そのためにセルフケアも心がける。<br>
                    3. 機密保持：機密情報を慎重に管理し、個人のプライバシーを尊重する。<br>
                    4. 倫理的な行動の守り：誠実さと正直さをもって行動し、利益相反を避ける。<br>
                    5. プロフェッショナリズムの示し：プロとしての態度を持ち、組織の方針に従い、協力的に働く。<br>
                    6. プライベートの行動：クリスチャンの価値に基づいた生活を実践し、良い模範となる。<br>
                    7. 安全と福祉の確保：すべての人の安全を優先し、指示やガイドラインに従う。<br>
                    8. 継続的な学びと成長の追求：成長を追求し、フィードバックを受け入れ、コミュニティーのニーズを理解するために学び続ける。<br>
                  </div>
                </div>
              </div>
              <br>
              <label class="stepTitle2nd">
                {{this.router.url.includes('EN')==true?
                'By following this Code of Conduct, we demonstrate our commitment to serving with love, integrity, and
                excellence, reflecting the teachings of Jesus Christ in our volunteer work.':
                'この行動規範に従うことで、私たちは愛、誠実さ、卓越性をもって奉仕し、ボランティアの活動においてイエス・キリストの教えを反映することを示します。'}}
              </label>
              <div>
                <div class="participantConsent">
                  <mat-checkbox #checkboxConsent formControlName="myVolunteerAgreeConductCode"
                    (change)='onChangeConsent(checkboxConsent.checked)' class="ckb_SG"
                    [checked]="conference.VolunteerAgreeConductCode==true"> {{this.router.url.includes('EN')==true?'* I
                    Agree':'*
                    同意します'}}
                  </mat-checkbox>
                  <mat-form-field class="participantConsent">
                    <input matInput placeholder='Date' formControlName="myVolunteerAgreeConductCodeDate"
                      [matDatepicker]="VolunteerAgreeConductCodeDate" class="matInputBox" />
                    <mat-datepicker-toggle matSuffix [for]="VolunteerAgreeConductCodeDate"></mat-datepicker-toggle>
                    <mat-datepicker #VolunteerAgreeConductCodeDate></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </div> -->

            <div class="d-flex justify-content-center">
              <div class="processButtonSet">
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn btnProcess" (click)="onClickGoBack()">
                    {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                  </button>
                  <button [disabled]="!myFormGroup.valid" type="button" class="btn btnProcess"
                    (click)="onClickGoForward()">
                    {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                  </button>
                </div>
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn link" (click)="cancelProcess()">
                    {{this.router.url.includes('EN')==true?'Cancel': 'キャンセル'}}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </mat-card>
      </div>
      <div class="myMainHalfBottom">
        <!-- Main Bottom -->
      </div>
    </div>
    <div class="myMainRight">
      <div class="myMainHalfTop">
        <!-- Right Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Right Bottom -->
      </div>
    </div>
  </div>
  <!-- <footer>footer</footer> -->
</div>
