import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//Added
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppConfig} from "./app.config";
import { myRoutes} from "./app.myRoutes";
import { FormsModule, ReactiveFormsModule} from "@angular/forms";
import { HttpClientModule} from "@angular/common/http";
import { DatePipe} from "@angular/common";

// ###### Angular Material Master File #######################
import { MyMaterialModule} from './app.material-module';
//############################################################

//Timezone
//import { MomentTimezonePickerModule } from 'moment-timezone-picker';

// Service Imports
import { UserService } from "./services/user/user.service";
import { AuthService } from "./services/auth/auth.service";
import { PaymentService} from "./services/payment/payment.service";
import { Ec20BeyondReunionService } from './services/conference/ec20BeyondReunion/ec20-beyond-reunion.service';

//####### Component ##########################################
import { HomeComponent } from './components/home/home.component';
import { NavbarComponent } from './components/navbar/navbar.component';
//General
import { EmailSentMessageComponent } from './components/general/email-sent-message/email-sent-message.component';
import { EnterNewPasswordComponent } from './components/general/enter-new-password/enter-new-password.component';
import { NotFoundComponent } from './components/general/not-found/not-found.component';
import { ResetPasswordComponent } from './components/general/reset-password/reset-password.component';
import { TokenCheckComponent } from './components/general/token-check/token-check.component';
//dialog
import { DeleteConfirmationComponent } from './components/dialog/delete-confirmation/delete-confirmation.component';
import { LoginCheckEmailComponent } from './components/dialog/login-check-email/login-check-email.component';
import { LoginEnterPasswordComponent } from './components/dialog/login-enter-password/login-enter-password.component';
import { LoginMessageComponent } from './components/dialog/login-message/login-message.component';
import { PasswordRecoveryEmailSentComponent } from './components/dialog/password-recovery-email-sent/password-recovery-email-sent.component';
import { PasswordRecoveryEnterEmailComponent } from './components/dialog/password-recovery-enter-email/password-recovery-enter-email.component';
import { TopComponent } from './components/top/top.component';

//admin
import { MembersListComponent } from './components/admin/members-list/members-list.component';

import { AuthGuard } from './guards/auth.guard';
import { UserEditMyProfileComponent } from './components/dialog/user-edit-my-profile/user-edit-my-profile.component';
import { UserEditOthersProfileComponent } from './components/dialog/user-edit-others-profile/user-edit-others-profile.component';
import { MyProfileComponent } from './components/members/my-profile/my-profile.component';
import { GiveComponent } from './components/give/give.component';
import { PersonalComponent } from './components/registration/personal/personal.component';
import { LoginDialogComponent } from './components/dialog/login-dialog/login-dialog.component';
import { SupportingTopComponent } from './components/members/supporting-top/supporting-top.component';
import { CheckHavingAccountComponent } from './components/dialog/check-having-account/check-having-account.component';
import { EnterSearchingEmailComponent } from './components/dialog/enter-searching-email/enter-searching-email.component';
import { SupportingMembershipComponent } from './components/payment/supporting-membership/supporting-membership.component';
import { SuccessfullyProcessedComponent } from './components/payment/successfully-processed/successfully-processed.component';
import { ProcessFailedComponent } from './components/payment/process-failed/process-failed.component';
import { ForwardSiteGroundUserToPasswordRecoveryComponent } from './components/dialog/forward-site-ground-user-to-password-recovery/forward-site-ground-user-to-password-recovery.component';
import { PaymentListComponent } from './components/admin/payment-list/payment-list.component';
import { RequestAddressUpdateComponent } from './components/dialog/request-address-update/request-address-update.component';
import { MemberJapanComponent } from './components/dashboard/member-japan/member-japan.component';
import { MemberUSAComponent } from './components/dashboard/member-usa/member-usa.component';
import { SelectMemberTypeComponent } from './components/members/select-member-type/select-member-type.component';
import { RegularTopComponent } from './components/members/regular-top/regular-top.component';
import { AcceptTermsComponent } from './components/members/accept-terms/accept-terms.component';
import { SelectNewsLetterLanguageComponent } from './components/members/select-news-letter-language/select-news-letter-language.component';
import { MyAccountImportDialogComponent } from './components/dialog/my-account-import-dialog/my-account-import-dialog.component';
import { DonationLogComponent } from './components/dialog/donation-log/donation-log.component';
import { ConferenceHistoryComponent } from './components/dialog/conference-history/conference-history.component';
import { MembershipSettingComponent } from './components/dialog/membership-setting/membership-setting.component';
import { JCFNAccessConfirmationComponent } from './components/dialog/jcfnaccess-confirmation/jcfnaccess-confirmation.component';
import { NoUserFoundComponent } from './components/dialog/no-user-found/no-user-found.component';
import { ProcessedSuccessfullyComponent } from './components/registration/processed-successfully/processed-successfully.component';
import { ProcessfailedComponent } from './components/registration/processfailed/processfailed.component';
import { MembersListCustomComponent } from './components/admin/members-list-custom/members-list-custom.component';
import { UpsertTemplateComponent } from './components/dialog/upsert-template/upsert-template.component';
import { DeleteTemplateConfirmationComponent } from './components/dialog/delete-template-confirmation/delete-template-confirmation.component';
import { TemplateComponent } from './components/general/template/template.component';
import { TemplateGridComponent } from './components/general/template-grid/template-grid.component';
import { TemplateMedicalComponent } from './components/general/template-medical/template-medical.component';
import { TemplateMedical2Component } from './components/general/template-medical2/template-medical2.component';
import { CheckHavingAccountECComponent } from './components/dialog/check-having-account-ec/check-having-account-ec.component';
import { EnterSearchingEmailECComponent } from './components/dialog/enter-searching-email-ec/enter-searching-email-ec.component';
import { LoginEnterPasswordECComponent } from './components/dialog/login-enter-password-ec/login-enter-password-ec.component';
import { PasswordRecoveryEnterEmailECComponent } from './components/dialog/password-recovery-enter-email-ec/password-recovery-enter-email-ec.component';
import { PasswordRecoveryEmailSentECComponent } from './components/dialog/password-recovery-email-sent-ec/password-recovery-email-sent-ec.component';
import { MyAccountComponent } from './components/conference/EC/my-account/my-account.component';
import { ProgramTypeComponent } from './components/registration/ec/program-type/program-type.component';


import { UploadDocumentsComponent } from './components/dialog/upload-documents/upload-documents.component';
import { ProgramTypesComponent } from './components/registration/ec/program-types/program-types.component';
import { CancelProcessConfirmationComponentComponent } from './components/dialog/cancel-process-confirmation-component/cancel-process-confirmation-component.component';
import { CancelOrSaveConfirmationComponent } from './components/dialog/cancel-or-save-confirmation/cancel-or-save-confirmation.component';
import { SmallGroupLanguageComponent } from './components/registration/ec/small-group-language/small-group-language.component';
import { JapanRegionalMeetingComponent } from './components/registration/ec/japan-regional-meeting/japan-regional-meeting.component';
import { OverseasRegionalMeetingComponent } from './components/registration/ec/overseas-regional-meeting/overseas-regional-meeting.component';
import { EnglishPartnersComponent } from './components/registration/ec/english-partners/english-partners.component';
import { CounselingComponent } from './components/registration/ec/counseling/counseling.component';
import { StudentComponent } from './components/registration/ec/student/student.component';
import { SleepingBagComponent } from './components/registration/ec/sleeping-bag/sleeping-bag.component';
import { FlightInfoComponent } from './components/registration/ec/flight-info/flight-info.component';
import { RoomInfoComponent } from './components/registration/ec/room-info/room-info.component';
import { VolunteersComponent } from './components/registration/ec/volunteers/volunteers.component';
import { TransportationComponent } from './components/registration/ec/transportation/transportation.component';
import { RegistrationConfirmationComponent } from './components/registration/ec/registration-confirmation/registration-confirmation.component';
import { ChristianInfoComponent } from './components/registration/basic/christian-info/christian-info.component';
import { AddressComponent } from './components/registration/basic/address/address.component';
import { TermsOfParticipationComponent } from './components/registration/ec/terms-of-participation/terms-of-participation.component';
import { BasicComponent } from './components/registration/ec/basic/basic.component';
import { AddressInfoComponent } from './components/registration/ec/address-info/address-info.component';
import { FaithInfoComponent } from './components/registration/ec/faith-info/faith-info.component';
import { BasicSubComponent } from './components/registration/ec/basic-sub/basic-sub.component';
import { LoginMessageEcComponent } from './components/dialog/login-message-ec/login-message-ec.component';
import { LoginDialogEcComponent } from './components/dialog/login-dialog-ec/login-dialog-ec.component';
import { SummaryPersonComponent } from './components/registration/ec/summary-person/summary-person.component';
import { SummaryConferenceComponent } from './components/registration/ec/summary-conference/summary-conference.component';
import { LoginSimpleEcComponent } from './components/dialog/login-simple-ec/login-simple-ec.component';
import { NavbarThreeComponent } from './components/navbar-three/navbar-three.component';
import { CancelOrSaveConfirmationPersonalComponent } from './components/dialog/cancel-or-save-confirmation-personal/cancel-or-save-confirmation-personal.component';
import { ScholarshipComponent } from './components/registration/ec/scholarship/scholarship.component';
import { LodgingScheduleComponent } from './components/registration/ec/lodging-schedule/lodging-schedule.component';
import { EnterNewPasswordChildComponent } from './components/dialog/enter-new-password-child/enter-new-password-child.component';
import { SuccessfullyProcessedECComponent } from './components/payment/successfully-processed-ec/successfully-processed-ec.component';
import { ProcessFailedECComponent } from './components/payment/process-failed-ec/process-failed-ec.component';
import { PayByPaypalComponent } from './components/dialog/pay-by-paypal/pay-by-paypal.component';
import { EcParticipantComponent } from './components/admin/EC/ec-participant/ec-participant.component';
import { EcParticipantCustomComponent} from './components/admin/EC/ec-participant-custom/ec-participant-custom.component';
import { ECparticipantTemplateComponent } from './components/dialog/EC/admin/ecparticipant-template/ecparticipant-template.component';
import { ECEditEachRegistrationComponent } from './components/dialog/EC/ec-edit-each-registration/ec-edit-each-registration.component';
import { ECParticipantsTemplateComponent } from './components/dialog/EC/admin/ec-participants-template/ec-participants-template.component';
import { ConferenceFeeDetailComponent } from './components/registration/ec/conference-fee-detail/conference-fee-detail.component';
import { MyAccountEditComponent } from './components/dialog/EC/my-account-edit/my-account-edit.component';
import { RegistrationPersonalEmbedComponent } from './components/dialog/EC/registration-personal-embed/registration-personal-embed.component';
import { RegistrationConferenceEmbedComponent } from './components/dialog/EC/registration-conference-embed/registration-conference-embed.component';
import { RegistrationPaymentEmbedComponent } from './components/dialog/EC/registration-payment-embed/registration-payment-embed.component';

import { BasicSubEmbedComponent } from './components/dialog/EC/basic-sub-embed/basic-sub-embed.component';
import { AddressInfoEmbedComponent } from './components/dialog/EC/address-info-embed/address-info-embed.component';
import { FaithInfoEmbedComponent } from './components/dialog/EC/faith-info-embed/faith-info-embed.component';
import { SummaryPersonEmbedComponent } from './components/dialog/EC/summary-person-embed/summary-person-embed.component';

//GRC
import { LoginGRCComponent } from './components/dialog/GRC/login-grc/login-grc.component';
import { BasicGRCComponent } from './components/registration/GRC/basic-grc/basic-grc.component';
import { BasicSubGRCComponent } from './components/registration/GRC/basic-sub-grc/basic-sub-grc.component';
import { AddressInfoGRCComponent } from './components/registration/GRC/address-info-grc/address-info-grc.component';
import { FaithInfoGRCComponent } from './components/registration/GRC/faith-info-grc/faith-info-grc.component';
import { SummaryPersonGRCComponent } from './components/registration/GRC/summary-person-grc/summary-person-grc.component';
//import { BasicGRC2Component } from './components/registration/GRC/basic-grc2/basic-grc2.component';
import { MyAccountGRCComponent } from './components/registration/GRC/my-account-grc/my-account-grc.component';
import { PasswordRecoveryEnterEmailGRCComponent } from './components/dialog/GRC/password-recovery-enter-email-grc/password-recovery-enter-email-grc.component';
import { PasswordRecoveryEmailSentGRCComponent } from './components/dialog/GRC/password-recovery-email-sent-grc/password-recovery-email-sent-grc.component';
import { CancelOrSaveConfirmationPersonalGRCComponent } from './components/dialog/GRC/cancel-or-save-confirmation-personal-grc/cancel-or-save-confirmation-personal-grc.component';
import { LoginMessageGRCComponent } from './components/dialog/GRC/login-message-grc/login-message-grc.component';
import { SmallGroupLanguageGRCComponent } from './components/registration/GRC/small-group-language-grc/small-group-language-grc.component';
import { VolunteersGRCComponent } from './components/registration/GRC/volunteers-grc/volunteers-grc.component';
import { CoupleGRCComponent } from './components/registration/GRC/couple-grc/couple-grc.component';
import { TransportationGRCComponent } from './components/registration/GRC/transportation-grc/transportation-grc.component';
import { RoomInfoGRCComponent } from './components/registration/GRC/room-info-grc/room-info-grc.component';
import { LodgingScheduleGRCComponent } from './components/registration/GRC/lodging-schedule-grc/lodging-schedule-grc.component';
import { SummaryConferenceGRCComponent } from './components/registration/GRC/summary-conference-grc/summary-conference-grc.component';
import { TermsOfParticipationGRCComponent } from './components/registration/GRC/terms-of-participation-grc/terms-of-participation-grc.component';
import { ScholarshipGRCComponent } from './components/registration/GRC/scholarship-grc/scholarship-grc.component';
import { GrcParticipantComponent } from './components/admin/GRC/grc-participant/grc-participant.component';
import { MyAccountEditGRCComponent } from './components/dialog/GRC/my-account-edit-grc/my-account-edit-grc.component';
import { RegistrationConferenceEmbedGRCComponent } from './components/dialog/GRC/registration-conference-embed-grc/registration-conference-embed-grc.component';
import { DressCodeComponent } from './components/registration/ec/dress-code/dress-code.component';
//import { EcParticipantComponent } from './components/admin/EC/ec-participant/ec-participant.component';
//import { EcParticipantCustomComponent } from './components/admin/EC/ec-participant-custom/ec-participant-custom.component';

@NgModule({
  declarations: [
    AppComponent,
    //general
    EmailSentMessageComponent,
    EnterNewPasswordComponent,
    NotFoundComponent,
    ResetPasswordComponent,
    TokenCheckComponent,
    //dialog
    DeleteConfirmationComponent,
    LoginMessageComponent,
    HomeComponent,
    NavbarComponent,
    LoginCheckEmailComponent,
    LoginEnterPasswordComponent,
    PasswordRecoveryEnterEmailComponent,
    PasswordRecoveryEmailSentComponent,
    TopComponent,
    MembersListComponent,
    UserEditMyProfileComponent,
    UserEditOthersProfileComponent,
    MyProfileComponent,
    GiveComponent,
    PersonalComponent,
    LoginDialogComponent,
    SupportingTopComponent,
    CheckHavingAccountComponent,
    EnterSearchingEmailComponent,
    SupportingMembershipComponent,
    SuccessfullyProcessedComponent,
    ProcessFailedComponent,
    ForwardSiteGroundUserToPasswordRecoveryComponent,
    PaymentListComponent,
    RequestAddressUpdateComponent,
    MemberJapanComponent,
    MemberUSAComponent,
    SelectMemberTypeComponent,
    RegularTopComponent,
    AcceptTermsComponent,
    SelectNewsLetterLanguageComponent,
    MyAccountImportDialogComponent,
    DonationLogComponent,
    ConferenceHistoryComponent,
    MembershipSettingComponent,
    JCFNAccessConfirmationComponent,

    NoUserFoundComponent,
    ProcessedSuccessfullyComponent,
    ProcessfailedComponent,
    MembersListCustomComponent,
    UpsertTemplateComponent,
    DeleteTemplateConfirmationComponent,
    TemplateComponent,
    TemplateGridComponent,
    TemplateMedicalComponent,
    TemplateMedical2Component,
    CheckHavingAccountECComponent,
    EnterSearchingEmailECComponent,
    LoginEnterPasswordECComponent,
    PasswordRecoveryEnterEmailECComponent,
    PasswordRecoveryEmailSentECComponent,
    MyAccountComponent,
   // ProgramTypeComponent,
    UploadDocumentsComponent,
    ProgramTypesComponent,
    CancelProcessConfirmationComponentComponent,
    ProgramTypeComponent,
    CancelOrSaveConfirmationComponent,
    SmallGroupLanguageComponent,
    JapanRegionalMeetingComponent,
    OverseasRegionalMeetingComponent,
    EnglishPartnersComponent,
    CounselingComponent,
    StudentComponent,
    SleepingBagComponent,
    FlightInfoComponent,
    RoomInfoComponent,
    VolunteersComponent,
    TransportationComponent,
    RegistrationConfirmationComponent,
    ChristianInfoComponent,
    AddressComponent,
    TermsOfParticipationComponent,
    BasicComponent,
    AddressInfoComponent,
    FaithInfoComponent,
    BasicSubComponent,
    LoginMessageEcComponent,
    LoginDialogEcComponent,
    SummaryPersonComponent,
    SummaryConferenceComponent,
    LoginSimpleEcComponent,
    NavbarThreeComponent,
    CancelOrSaveConfirmationPersonalComponent,
    ScholarshipComponent,
    LodgingScheduleComponent,
    EnterNewPasswordChildComponent,
    SuccessfullyProcessedECComponent,
    ProcessFailedECComponent,
    PayByPaypalComponent,
    EcParticipantComponent,
    EcParticipantCustomComponent,
    //ECparticipantTemplateComponent,
    //ECEditEachRegistrationComponent,
    ECParticipantsTemplateComponent,
    ConferenceFeeDetailComponent,
    MyAccountEditComponent,
    RegistrationPersonalEmbedComponent,
    RegistrationConferenceEmbedComponent,
    RegistrationPaymentEmbedComponent,
    BasicSubEmbedComponent,
    AddressInfoEmbedComponent,
    FaithInfoEmbedComponent,
    SummaryPersonEmbedComponent,
    LoginGRCComponent,
    BasicGRCComponent,
    BasicSubGRCComponent,
    AddressInfoGRCComponent,
    FaithInfoGRCComponent,
    SummaryPersonGRCComponent,
    //BasicGRC2Component,
    MyAccountGRCComponent,
    PasswordRecoveryEnterEmailGRCComponent,
    PasswordRecoveryEmailSentGRCComponent,
    CancelOrSaveConfirmationPersonalGRCComponent,
    LoginMessageGRCComponent,
    SmallGroupLanguageGRCComponent,
    VolunteersGRCComponent,
    CoupleGRCComponent,
    TransportationGRCComponent,
    RoomInfoGRCComponent,
    LodgingScheduleGRCComponent,
    SummaryConferenceGRCComponent,
    TermsOfParticipationGRCComponent,
    ScholarshipGRCComponent,
    GrcParticipantComponent,
    MyAccountEditGRCComponent,
    RegistrationConferenceEmbedGRCComponent,
    EcParticipantComponent,
    EcParticipantCustomComponent,
    DressCodeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    //Added
    myRoutes,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MyMaterialModule, //Angular Material Master
    //Timezone
    //MomentTimezonePickerModule,

  ],
  providers: [ {provide: LocationStrategy, useClass: HashLocationStrategy},
    AppConfig,  DatePipe, AuthService, UserService,PaymentService, Ec20BeyondReunionService,
    //Following 2 components are used in constructor in personal.components.ts
    UserEditOthersProfileComponent,
    RequestAddressUpdateComponent,
  MembersListComponent,
  //Follwing component is used in constructor in upsert-template.component.ts to call function of other class.
  MembersListCustomComponent,

  EcParticipantComponent,
  EcParticipantCustomComponent,

],
  bootstrap: [AppComponent],

})
export class AppModule { }
