import { Component, OnInit, Inject, ANALYZE_FOR_ENTRY_COMPONENTS } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { PaymentMasterCore} from '../../../models/payment/paymentMasterCore';
import { PaymentService} from "../../../services/payment/payment.service";
import { UserService} from "../../../services/user/user.service";
import { User } from "../../../models/user";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';


export interface DialogData {
  Id: string
}

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.css']
})
export class UploadDocumentsComponent implements OnInit {
    firstFormGroup: FormGroup;
    loaded:boolean=true;
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    myMessage:string;
    beSupportingMember:boolean;
    disableUpdateButton:boolean;
    myFileToUpload:File=null;

    public providers = [
      { value: "Authorize.net", display: "Authorize.net" },
      { value: "Tithely", display: "Tithely" },
      { value: "日本郵便振替 Japan Postal Transfer", display: "日本郵便振替 Japan Postal Transfer" },
      { value: "日本銀行振込 Japan Bank Transfer", display: "日本銀行振込 Japan Bank Transfer" },
      { value: "日本クレジットカード Japan Credit Card", display: "日本クレジットカード Japan Credit Card" },
      { value: "現金 Cash", display: "現金 Cash" },
      { value: "チェック Check", display: "チェック Check" },
    ];

    public currencies=[
      { value: "USD", display: "USD" },
      { value: "JPY", display: "JPY" },
    ]

    paymentMasterCore: PaymentMasterCore = {
      PaymentMasterId: 0,
      FirstName: null,
      LastName: null,
      Email: null,
      DataDescriptor: null,
      DataValue: null,
      ItemName: null,
      Amount: null,
      Recurring: null,
      RecurringId: null,
      CreatedDate: null,
      ProviderName:null,
      Currency:null,
      MembershipType:null
    }
    paymentMasterCores:PaymentMasterCore[]=[];

    user: User = {
      PasswordHash: null,
      Id: null,
      UserName: null,
      Email: null,
      FirstName: null,
      LastName: null,
      Password: null,
      Prefix: null,
      Shimei: null,
      Myoji: null,
      Street: null,
      Street2: null,
      City: null,
      State: null,
      Zip: null,
      Country: null,
      Yubin_Bango: null,
      To_Do_Fu_Ken: null,
      Shi_Gun_Ku: null,
      Cho_Son: null,
      Apartment_Etc: null,
      To_Do_Fu_Ken_JP: null,
      Shi_Gun_Ku_JP: null,
      Cho_Son_JP: null,
      Apartment_Etc_JP: null,
      Gender: null,
      DOB: null,
      TelNo: null,
      TimezoneName: null,
      UTCdiff: null,
      Token: null,
      Occupation: null,
      MemberStatus: null,
      Married: null,
      ZoomAccount: null,
      Christian: null,
      Baptized:null,
      ReturneeInfo:null,
      HasReturningChurch:null,
      RegisteredDate: null,
      LastAccessedDate: null,
      //From siteground
      LoginId: null,
      MiddleNameEN: null,
      MiddleNameJP: null,
      PrefixJP: null,
      OccupationDetail: null,
      Organization: null,
      LivingCountry: null,
      PhoneEN: null,
      PhoneMobileEN: null,
      PhoneJP: null,
      PhoneMobileJP: null,
      ChurchNameEN: null,
      ChurchNameJP: null,
      MemberType: null,
      MemberDetail: null,
      MemberMemo: null,
      Shokaisha: null,
      Reason: null,
      NewsLetters: null,
      StaffReports: null,
      OtherLetters: null,
      NewsLetterLanguage: null,
      BasicRoles: null,
      //Added new
      MigrateFromSiteGround: null,
      AdminAccess: null,
      JCFNAccess: null,
      SupportingMember: null,
      LastAddressUpdatedDate: null,
      AcceptMembershipTerm: null,
      MembershipType: null
    };

    constructor(
      public router: Router,
      private _snackBar: MatSnackBar,
      private _formBuilder: FormBuilder,
      private paymentService: PaymentService,
      private userService:UserService,
      public dialogRef: MatDialogRef<UploadDocumentsComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData
    ){}

    ngOnInit() {
      this.firstFormGroup = this._formBuilder.group({

      });
      if (this.data.Id != null) {
        this.loaded=false;
        this.userService.getUserProfileById(this.data.Id).subscribe(
          (          result: User) => {
            this.user = result;
            this.loaded = true;
          }
        )
      }
    }

    // handleFileInputContainerAppointment(event: any) {
    //   let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    //   let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    //   let files: FileList = target.files;
    //   this.myFileToUpload = files.item(0);
    // }

    uploadCodeMasterFile() {
      if (this.myFileToUpload != null) {
        this.uploadFileSample();
      } else {
        this.myMessage = this.getMyLanguage()=="EN"?"No file selected.":"ファイルが選ばれていません。";
        this.openSnackBar('error', 'close');
        this.loaded = true;
      }
    }

    uploadFileSample() {
      this.loaded = false;
      this.userService.uploadFile(this.myFileToUpload,this.user).subscribe(
          () => {
            this.loaded = true;
            this.myMessage =this.getMyLanguage()=="EN"?"File has been uploaded successfully.":"ファイルがアップロードされました";
            this.openSnackBar('success', 'close');
            this.loaded = true;
          },
          () => {
            this.myMessage = this.getMyLanguage()=="EN"?"File upload failed.":"ファイルのアップロードに失敗しました。";
            this.openSnackBar('error', 'close');
            this.loaded = true;
          }
        );
    }

    getMyLanguage(): string {
      return this.router.url.includes('EN') == true ? 'EN' : 'JP';
    }

    InsertSupportingMembershipPaymentInfo() {
      if (this.firstFormGroup.status == 'VALID') {
        this.loaded = false;
        this.paymentService.InsertSupportingMembershipPaymentInfo(this.paymentMasterCore).subscribe(
            data => {
              //Success saving personal info.
              this.user = data;
              this.myMessage = this.getMyLanguage()=="EN"?"Membership info saved successfully.":"会員情報が更新されました。";
              this.openSnackBar('success', 'close');
              this.loaded = true;
            //  this.login();
            },
            error => {
              this.myMessage = this.getMyLanguage()=="EN"?"Membership info failed to be saved.":"問題が生じ会員情報が更新されませんでした。";
              this.openSnackBar('error', 'close');
              this.loaded = true;
            }
          )
      }else{
        console.log('data is bad');
      }
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

    openSnackBar(message: string, action: string) {
      if (message == "success") {
        this._snackBar.open(this.myMessage, action, {
          duration: 5000,
          panelClass: ["customPanel_Success"],
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      } else if (message = "error") {
        this._snackBar.open(this.myMessage, action, {
          duration: 10000,
          panelClass: ["customPanel_Error"],
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    }

    onClickClose() {
      sessionStorage.removeItem("searchingRegistrantId");
      console.log('Im closing');
      this.dialogRef.close();
    }

    onCurrencyChanged(choosedValue:any){
      this.paymentMasterCore.Currency=choosedValue;
      if(this.paymentMasterCore.Currency=="USD"){
        this.paymentMasterCore.Amount=30;
      }else if(this.paymentMasterCore.Currency="JPY"){
        this.paymentMasterCore.Amount=3000;
      }
    }

  }
