import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router, NavigationExtras } from "@angular/router";
import { User } from 'src/app/models/user';
import { CancelOrSaveConfirmationComponent } from 'src/app/components/dialog/cancel-or-save-confirmation/cancel-or-save-confirmation.component';
import { MatDialog,  MatDialogConfig} from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { RegistrationConferenceEmbedComponent } from 'src/app/components/dialog/EC/registration-conference-embed/registration-conference-embed.component';
import { MyAccountEditComponent } from 'src/app/components/dialog/EC/my-account-edit/my-account-edit.component';
import { GRCConference, GRCRelatedPerson, GRCVolunteerSelection } from 'src/app/models/conference/GRCConference';
import { MatRadioChange } from '@angular/material/radio';
import { RegistrationConferenceEmbedGRCComponent } from 'src/app/components/dialog/GRC/registration-conference-embed-grc/registration-conference-embed-grc.component';
@Component({
  selector: 'app-transportation-grc',
  templateUrl: './transportation-grc.component.html',
  styleUrls: ['./transportation-grc.component.css']
})
export class TransportationGRCComponent implements OnInit {
  loaded:boolean=true;
  myFormGroup: FormGroup;
  myMessage:string;
  showNeedCommingBus?:boolean;
  showNeedReturningBus?:boolean;

  user: User = {
    PasswordHash:null,
    Id:null,
    UserName:null,
    Email:null,
    FirstName:null,
    LastName:null,
    Password:null,
    Prefix:null,
    Shimei:null,
    Myoji:null,
    Street:null,
    Street2:null,
    City:null,
    State:null,
    Zip:null,
    Country:null,
    Yubin_Bango:null,
    To_Do_Fu_Ken:null,
    Shi_Gun_Ku:null,
    Cho_Son:null,
    Apartment_Etc:null,
    To_Do_Fu_Ken_JP:null,
    Shi_Gun_Ku_JP:null,
    Cho_Son_JP:null,
    Apartment_Etc_JP:null,
    Gender:null,
    DOB:null,
    TelNo:null,
    TimezoneName:null,
    UTCdiff:null,
    Token:null,
    Occupation:null,
    MemberStatus:null,
    Married:null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount:null,
    RegisteredDate:null,
    LastAccessedDate:null,
    //From siteground
    LoginId:null,
    MiddleNameEN:null,
    MiddleNameJP:null,
    PrefixJP:null,
    OccupationDetail:null,
    Organization:null,
    LivingCountry:null,
    PhoneEN:null,
    PhoneMobileEN:null,
    PhoneJP:null,
    PhoneMobileJP:null,
    ChurchNameEN:null,
    ChurchNameJP:null,
    MemberType:null,
    MemberDetail:null,
    MemberMemo:null,
    Shokaisha:null,
    Reason:null,
    NewsLetters:null,
    StaffReports:null,
    OtherLetters:null,
    NewsLetterLanguage:null,
    BasicRoles:null,
    //Added new
    MigrateFromSiteGround:null,
    AdminAccess:null,
    JCFNAccess:null,
    SupportingMember:null,
    LastAddressUpdatedDate:null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };
  relatedPerson:GRCRelatedPerson={
    Id:null,
    RelatedId:null,
    Email:null,
    FirstNameRelated:null,
    LastNameRelated:null,
    MyojiRelated:null,
    ShimeiRelated:null,
    DOBRelated:null,
    GenderRelated:null
  };
  relatedPersons:GRCRelatedPerson[]=[];
  volunteerSelections:GRCVolunteerSelection[]=[];
  conference= new GRCConference();

  constructor(
    public router: Router,
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
  ) { }
  mySearch: any = {};
  ngOnInit(): void {
    this.myFormGroup = this._formBuilder.group({
      myTransportationMethodComing: [null, Validators.required],
      myTransportationMethodReturning:[null, Validators.required],
      myGRCDirectBusAdultNumberComing:[null, ],
      myGRCDirectBusChildNumberComing:[null, ],
      myGRCDirectBusAdultNumberReturning:[null, ],
      myGRCDirectBusChildNumberReturning:[null, ],
    });

    if (sessionStorage.getItem("myGRCSearchingUserIdGRC") != null) {
      /*************************************************
       *  A) User info for searching registrant by admin
       *************************************************/
       this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnConferenceAdminGRC"));
       if (this.mySearch != null) {
        console.log('admin is editing this user')
         this.conference = this.mySearch.myEditingConference;
         this.patchModelValue(this.conference);
         this.loaded = true;
       }else{
        this.loaded = true;
       }
    } else {
    /*####################################################################################
    # Get conference info from previous page for myself
    #####################################################################################*/
    this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnConference"));
      if (this.mySearch != null) {
        console.log('login user is editing his account')
        this.conference = this.mySearch.myEditingConference;
        this.patchModelValue(this.conference);
        this.loaded = true;
      }else{
      }

    }
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  patchModelValue(model:GRCConference){
    this.myFormGroup.patchValue({
      myTransportationMethodComing:model.TransportationMethodComing,
      myTransportationMethodReturning:model.TransportationMethodReturning,
      myGRCDirectBusAdultNumberComing:model.GRCDirectBusAdultNumberComing,
      myGRCDirectBusChildNumberComing:model.GRCDirectBusChildNumberComing,
      myGRCDirectBusAdultNumberReturning:model.GRCDirectBusAdultNumberReturning,
      myGRCDirectBusChildNumberReturning:model.GRCDirectBusChildNumberReturning
    });
  }

  onChangeNumberOfGRCDirectBusAdultNumberComing($event:any){

  }

  onChangeNumberOfGRCDirectBusChildNumberComing($event:any){

  }

  onChangeNumberOfGRCDirectBusAdultNumberReturning($event:any){

  }

  onChangeNumberOfGRCDirectBusChildNumberReturning($event:any){

  }

  getFormValue() {
    this.conference.GRCDirectBusAdultNumberComing = this.myFormGroup.get('myGRCDirectBusAdultNumberComing').value;
    this.conference.GRCDirectBusChildNumberComing = this.myFormGroup.get('myGRCDirectBusChildNumberComing').value;
    this.conference.GRCDirectBusAdultNumberReturning = this.myFormGroup.get('myGRCDirectBusAdultNumberReturning').value;
    this.conference.GRCDirectBusChildNumberReturning = this.myFormGroup.get('myGRCDirectBusChildNumberReturning').value;
  }

  onChangeArrivalDateTime(event: any) {
    console.log(event.value)
  }

  onChangeDepartureDateTime(event:any) {
    console.log(event.value)
  }

  onChangeNeedCommingBusSelection($event:any){
  }

  onChangeNeedReturningBusSelection($event:any){

  }

  onChangeCommingBusDeparturePlaceDateTime($event:any){
  }

  onChangeReturningBusDeparturePlaceDateTime($event:any){
  }

  onClickGoForward(){
    this.getFormValue();
    if(this.router.url.includes('/registration/GRC/')){
      sessionStorage.removeItem("myWorkingOnConference");
      sessionStorage.setItem(
        "myWorkingOnConference",
        JSON.stringify({ myEditingConference: this.conference })
      );
      this.router.navigate(["/registration/GRC/roomInfoGRC/" +this.getMyLanguage()]);
    }else{
      sessionStorage.removeItem("myWorkingOnConferenceAdminGRC");
      sessionStorage.setItem(
        "myWorkingOnConferenceAdminGRC",
        JSON.stringify({ myEditingConference: this.conference })
      );
      this.dialog.closeAll();
      this.openForwardEmbed();
    }
  }
  openForwardEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktoop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ myPage: 'roomInfoGRC' };
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedGRCComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  onClickGoBack(){
    if(this.router.url.includes('/registration/GRC/')){
      this.router.navigate(["/registration/GRC/coupleGRC/" +this.getMyLanguage()]);
    }else{
      this.dialog.closeAll();
      this.openBackEmbed();
    }
  }
  openBackEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ myPage: 'coupleGRC' };
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedGRCComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  onSubmit() {
  }

  cancelProcess(){
    this.getFormValue();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='210px'; //Desktop
    dialogConfig.maxWidth='370px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ conference: this.conference };
    const dialogRef = this.dialog.open(CancelOrSaveConfirmationComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
      //Retreive updated registration Info.
      //this.ngOnInit();
      let navigationExtras: NavigationExtras = {
        queryParams:this.conference
      };
      if(result!='cancel'){
        if(this.router.url.includes('/registration/GRC/')){
          this.router.navigate(["/registration/GRC/myAccountGRC/" +this.getMyLanguage()], navigationExtras);
        }else{
          this.dialog.closeAll();
          this.openMyAccountEdit();
        }
        }
    })
  }

  onChangeTransportationComingSelection(event: MatRadioChange) {
    this.conference.TransportationMethodComing = event.value;
    console.log("My Comming Method！:" + this.conference.TransportationMethodComing);

  }
  onChangeTransportationReturningSelection(event: MatRadioChange) {
    this.conference.TransportationMethodReturning = event.value;
    console.log("My Comming Method！:" + this.conference.TransportationMethodComing);

  }

  openMyAccountEdit(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ userId: this.conference.UserId };
    const dialogRef = this.dialog.open(MyAccountEditComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }
  }
