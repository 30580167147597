<div class="WelcomeBase">
 <div class="d-flex justify-content-center">
   <form [formGroup]="firstFormGroup">
  <mat-card class="WelcomeWindow">
   <label class="EmailCheckComment">
    {{this.router.url.includes('EN')==true?
    'Thanks for submitting your email. We`ve sent you an email with the information needed to reset your password. The
    email might take a couple of minutes to reach your account. Please check your junk mail to ensure you receive it.':
    'Emailありがとうございます。パスワード再設定のメールが送られました。メールの受信には数分かかる場合がございます。またスパムメール扱いになっていないか念のためご確認ください。'
    }}
   </label>
   <div  class="d-flex justify-content-center btnCancel">
    <a [routerLink]="['/login']" class="btn btn-link ">
     {{this.router.url.includes('EN')==true?'Back to login':'ログイン画面に戻る'}}
    </a>
   </div>
  </mat-card>
 </form>
 </div>

</div>