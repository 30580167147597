<!-- <div id="intro" class="bg-image vh-100" style="
background-image: url('./images/jcfn4.jpg');
">
<div class="mask" style="background-color: rgba(250, 182, 162, 0.15);"></div>
</div> -->

<div class="loginBase">
  <div class="bg-layer">

    <div class="row d-flex justify-content-center">

       <div class="col">
          <div class="header-EC b-radius">
            <div class="d-flex justify-content-center">
              <button (click)="onClickECApply()" class="btnGotoEC themeColor1 btnGradient gradient-1 b-radius">
                {{this.router.url.includes('EN')==true?'EC24':
                'EC24'}}
              </button>
            </div><br>
            <mat-label class="lblComment d-flex justify-content-center">
              {{this.router.url.includes('EN')==true?
              'US West Coast: Dec27,2025 〜 Jan1,2025' :
              '米西海岸：2024年12月27日 〜 2025年1月1日'}}
            </mat-label>
            <!-- <mat-label class="lblComment2 d-flex justify-content-center">
              {{this.router.url.includes('EN')==true?
              'Due to venue constraints, priority for this conference will be given to full-day attendees. We will accept partial participants when the number of full-day participants reaches 275, so please be patient if you plan to attend a part of the conference. We will notify you when we start accepting partial participants.' :
              '今回のカンファレンスは、会場の都合により全日参加者を優先しています。全日参加者が定員275名に達した時点で部分参加の方を受け付けますので、部分参加予定の方は、しばらくお待ちください。部分参加受付が開始しましたらお知らせいたします。'}}
            </mat-label> -->
            <mat-label class="lblComment2 d-flex justify-content-center">
              {{this.router.url.includes('EN')==true?
              'EC registration is closed as we have reached capacity. For inquiries, please contact: ecregistration@equipper.org' :
              'ECレジストレーションは定員につき締め切りました。お問い合わせは：ecregistration@equipper.org　までお願いします。'}}
            </mat-label>
            <!-- <mat-label class="lblComment2 d-flex justify-content-center">
              {{this.router.url.includes('EN')==true?
              'For Admin only' :
              'アドミ用: 新規登録、編集可'}}
            </mat-label> -->
            <!-- <mat-label class="lblComment2">
              {{this.router.url.includes('EN')==true?
              'Contact: ecregistration@equipper.org' :
              'お問い合わせ：ecregistration@equipper.org'}}
            </mat-label><br> -->


                <!--       <mat-label class="lblComment2 d-flex justify-content-center">
                  {{this.router.url.includes('EN')==true?'Partial participation application, open!' : '部分参加の申し込み、オープンしました！'}}<br>
                      {{this.router.url.includes('EN')==true?'If the number of registrations exceeds the limit of 320, registration will be placed on a Waiting List. Please note that we will contact you in that case.' : '定員320名を超えた場合、登録はWaiting Listとなります。その場合はこちらからご連絡させていただきますのでご了承ください。'}}

                      {{this.router.url.includes('EN')==true?
                      'New applications are no longer accepted. You will be placed on a waiting list. If you are interested in joining EC, please contact us at'
                      :'新規申し込みは終了しました。今後ははキャンセル待ちにつきWaiting Listにてお待ちいただくことにになります。EC参加ご希望の方は以下にお問い合わせください。'}}<br>
                      {{'ecregistration@equipper.org'}}


                    </mat-label>-->
            <br>
            <mat-label class="lblComment d-flex justify-content-center">
              <!-- {{this.router.url.includes('EN')==true?'You can register from here.': 'こちらから参加手続きができます。'}} -->
              {{this.router.url.includes('EN')==true?'If you already registered, you can check your registration orupdate your info.'
              : '既に登録済みの方は、ログインすると登録内容の確認や変更ができます。'}}<br>
            </mat-label>
          </div>
        </div>


        <!-- <input type="file" class="file-input" multiple [accept]="requiredFileType" (change)="onFileSelected($event)"
          #fileUpload>
        <div class="file-upload">
          {{fileName ||this.router.url.includes('EN')==true?'No file uploaded yet' : 'まだファイルがアップロードされていません'}}
          <button mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()">
            <mat-icon>attach_file</mat-icon>
          </button>
        </div>
        <div class="progress">
          <mat-progress-bar class="progress-bar" mode="determinate" [value]="uploadProgress" *ngIf="uploadProgress">
          </mat-progress-bar>
          <mat-icon class="cancel-upload" (click)="cancelUpload()" *ngIf="uploadProgress">delete_forever</mat-icon>
        </div> -->

     <!--       <div class="col">
          <div class="header-lbl  b-radius">
            <div class="d-flex justify-content-center">
              <button (click)="onClickGRC23Apply()" class="btnGoGRC themeColor1 btnGradient gradient-1 b-radius">
                {{this.router.url.includes('EN')==true?'Attend GRC23':
                'GRC23に参加する'}}
              </button>
            </div><br>
            <mat-label class="lblComment d-flex justify-content-center">
              {{this.router.url.includes('EN')==true?'Japan Time: May03,2023 〜 May06,2023' : '日本時間：2023年05月03日 〜 2023年05月06日'}}
            </mat-label>
            <br><br>
            <mat-label class="lblComment d-flex justify-content-center">
              {{this.router.url.includes('EN')==true?'You can register from here.': 'こちらから参加手続きができます。'}}<br>
              {{this.router.url.includes('EN')==true?'If you already registered, you can check your registration orupdate your info.'
              : '既に登録済みの方は、ログインすると登録内容の確認や変更ができます。'}}<br>
            </mat-label>
          </div>
        </div>-->
        <div class="col">
          <div class="header-supportingMember  b-radius">
            <div class="d-flex justify-content-center">
              <button (click)="onClickProcessLogin()" class="btnGoSustainingMembership b-radius">
                {{this.router.url.includes('EN')==true?'Become a JCFN member':
                'JCFN会員になる'}}
              </button>
            </div>
            <br><br><br>
            <mat-label class="lblComment d-flex justify-content-center">
              {{this.router.url.includes('EN')==true?'Click here to become a regular member': 'こちらから一般会員、もしくは維持会員'}}<br>
              {{this.router.url.includes('EN')==true?' or a supporting member': 'になる手続きができます。'}}
            </mat-label>
          </div>
        </div>

<!--
        <div class="col col-md-6 col-10">
          <div class="header-main  b-radius">
            <form [formGroup]="firstFormGroup" class="loginForm">
              <i class="fas fa-user fa-2x"></i>&nbsp;
              <mat-form-field appearance="outline" class="registrationInputForm">
                <mat-label>
                  {{this.router.url.includes('EN')==true?'Email': 'Eメール'}}
                </mat-label>
                <input matInput placeholder="" formControlName="myEmail" [(ngModel)]="user.Email" required
                  class="registrationInputBox">
                <mat-error *ngIf="firstFormGroup.get('myEmail').hasError('required')">
                  {{this.router.url.includes('EN')==true?'Email is required': '未入力'}}
                </mat-error>
                <mat-error *ngIf="firstFormGroup.get('myEmail').hasError('email')">
                  {{this.router.url.includes('EN')==true?'Invalid Email format': 'Eメールフォーマットが正しくありません'}}
                </mat-error>
              </mat-form-field><br><br>
              <i class="fa fa-lock fa-2x"></i>&nbsp;
              <mat-form-field appearance="fill" class="registrationInputForm">
                <mat-label>
                  {{this.router.url.includes('EN')==true?'Password': 'パスワード'}}
                </mat-label>
                <input matInput placeholder="" formControlName="myPassword" [(ngModel)]="user.Password" type="password"
                  required #myInputPassword class="registrationInputBox">
                <mat-error *ngIf="firstFormGroup.get('myPassword').hasError('required')">
                  {{this.router.url.includes('EN')==true?'Password is required': '未入力'}}
                </mat-error>
              </mat-form-field>
              <br />
              <br />
              <button [disabled]="loading" (click)="onCheckLogin()" class="btnLogin b-radius" cdkFocusInitial>
                {{this.router.url.includes('EN')==true?'Log In':
                'ログイン'}}</button>
              <img *ngIf="loading"
                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              <img *ngIf="loading" />
              <br><br><br>
              <a [routerLink]="this.router.url.includes('EN')==true?'/reset-password/EN': '/reset-password/JP'"
                class="btn btn-link">{{this.router.url.includes('EN')==true?'Forgot Password?': 'パスワードを忘れた場合'}}</a>
            </form>
          </div>
        </div> -->

    </div>


<!--
   <div class="row ">
     <div class="col col-lg-3  ecReunion">
       <div class="header-supportingMember  b-radius">
        <div class="d-flex justify-content-center">
            <button
            (click)="onClickEC20BeyondReunionApply()" class="btnGoSustainingMembership b-radius">
              {{this.router.url.includes('EN')==true?'Attend EC20 Beyond Reunion':
              'EC20 Beyondリユニオンに参加する'}}
            </button>
           </div><br>
           <mat-label class="lblComment d-flex justify-content-center">
             {{this.router.url.includes('EN')==true?'Japan: Jan22,2022 Sat 11am ~ 2pm': '日本：2022年1月22日（土）11AM 〜 2PM'}}<br>
             {{this.router.url.includes('EN')==true?'US West Coast: Jan21,2022 Fri 6pm ~ 9pm': '米西海岸：2022年1月21日（金）6 〜 9 PM'}}
           </mat-label>
            <br><br>
            <mat-label class="lblComment d-flex justify-content-center">
              {{this.router.url.includes('EN')==true?'You can register from here.': 'こちらから参加手続きができます。'}}<br>
              {{this.router.url.includes('EN')==true?'If you already registered, you can check your registration or update your info.': '既に登録済みの方は、ログインすると登録内容の確認や変更ができます'}}<br>
            </mat-label>
         </div>
     </div>
     <div class="col col-lg-3   membershipAnounce">
       <div class="header-supportingMember  b-radius">
         <div class="d-flex justify-content-center">
             <button
             (click)="onClickProcessLogin()" class="btnGoSustainingMembership b-radius">
               {{this.router.url.includes('EN')==true?'Become a member':
               '会員になる'}}
             </button>
            </div>
             <br><br><br>
             <mat-label class="lblComment d-flex justify-content-center">
               {{this.router.url.includes('EN')==true?'Click here to become a regular member': 'こちらから一般会員、もしくは維持会員'}}<br>
               {{this.router.url.includes('EN')==true?' or a supporting member': 'になる手続きができます'}}
             </mat-label>
          </div>
     </div>
     <div class="col col-lg-4 ">
       <div class="header-main  b-radius">
           <form [formGroup]="firstFormGroup" class="loginForm">
             <i class="fas fa-user fa-2x"></i>&nbsp;
             <mat-form-field appearance="outline" class="registrationInputForm">
               <mat-label>
                 {{this.router.url.includes('EN')==true?'Email': 'Eメール'}}
               </mat-label>
               <input matInput placeholder="" formControlName="myEmail" [(ngModel)]="user.Email" required
                 class="registrationInputBox">
               <mat-error *ngIf="firstFormGroup.get('myEmail').hasError('required')">
                 {{this.router.url.includes('EN')==true?'Email is required': '未入力'}}
               </mat-error>
               <mat-error *ngIf="firstFormGroup.get('myEmail').hasError('email')">
                 {{this.router.url.includes('EN')==true?'Invalid Email format': 'Eメールフォーマットが正しくありません'}}
               </mat-error>
             </mat-form-field><br><br>
             <i class="fa fa-lock fa-2x"></i>&nbsp;
             <mat-form-field appearance="fill" class="registrationInputForm">
               <mat-label>
                 {{this.router.url.includes('EN')==true?'Password': 'パスワード'}}
               </mat-label>
               <input matInput placeholder="" formControlName="myPassword" [(ngModel)]="user.Password" type="password"
                 required #myInputPassword class="registrationInputBox">
               <mat-error *ngIf="firstFormGroup.get('myPassword').hasError('required')">
                 {{this.router.url.includes('EN')==true?'Password is required': '未入力'}}
               </mat-error>
             </mat-form-field>
             <br />
             <br />
             <button [disabled]="loading" (click)="onCheckLogin()" class="btnLogin b-radius" cdkFocusInitial>
               {{this.router.url.includes('EN')==true?'Log In':
               'ログイン'}}</button>
             <img *ngIf="loading"
               src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
             <img *ngIf="loading" />
             <br><br><br>
             <a [routerLink]="this.router.url.includes('EN')==true?'/reset-password/EN': '/reset-password/JP'"
               class="btn btn-link">{{this.router.url.includes('EN')==true?'Forgot Password?': 'パスワードを忘れた場合'}}</a>
           </form>
       </div>
     </div>
   </div>
 -->


 </div>
</div>
