import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../../../services/auth/auth.service";
import { User } from "../../../models/user";
import { UserService } from "../../../services/user/user.service";
import { PaymentService} from "../../../services/payment/payment.service";
import { MatRadioChange } from '@angular/material/radio';
import { MatStepper } from '@angular/material/stepper';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
//For Dialog
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA , MatDialogConfig} from '@angular/material/dialog';
import { CheckHavingAccountComponent} from "../../dialog/check-having-account/check-having-account.component";
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { TOOLTIP_PANEL_CLASS } from '@angular/material/tooltip';
import {PaymentMaster, LineItem, RecurringPayment} from "../../../models/payment/paymentMaster";

@Component({
  selector: 'app-select-member-type',
  templateUrl: './select-member-type.component.html',
  styleUrls: ['./select-member-type.component.css']
})
export class SelectMemberTypeComponent implements OnInit {
  mySelectedLanguage:string;
  firstFormGroup: FormGroup;


  constructor(
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    public authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog,
    private paymentService:PaymentService
  ) { }


  ngOnInit(): void {
    this.mySelectedLanguage = this.router.url.includes('EN')==true?"EN":"JP";
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

//   onClickProcessLogin(){
//     const dialogConfig = new MatDialogConfig();
//     dialogConfig.disableClose = true;
//     dialogConfig.autoFocus = false;
//     dialogConfig.maxHeight='550px'; //Desktop
//     dialogConfig.maxWidth='500px';//Desktoop
//     dialogConfig.height='100%';//Mobile
//     dialogConfig.width='100%';//Mobile
//     dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
//  //   dialogConfig.data={ Id: this.user.Id };
//     const  dialogRef = this.dialog.open(CheckHavingAccountComponent,dialogConfig);
//     dialogRef.afterClosed().subscribe(result => {
//       var redirectPage = result;
//     //  this.redirectPage(this.codeMaster.CodeType);
//       if(result!=null){
//         console.log("Confirmed Action: "+redirectPage);
//       }
//     })
//   }

onClickGoBack(){
  this.router.navigate(["/members/selectNewsLetterLanguage/" +this.getMyLanguage()]);
}

  onClickSelectSupportingMember(){
    this.router.navigate(["/members/supportingTop/"+this.getMyLanguage()]);
  }


  onClickSelectRegularMember(){
    this.router.navigate(["/members/regularTop/"+this.getMyLanguage()]);
  }
}
