import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-process-failed',
  templateUrl: './process-failed.component.html',
  styleUrls: ['./process-failed.component.css']
})
export class ProcessFailedComponent implements OnInit {
  mySelectedLanguage:string;
  
  constructor(
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.mySelectedLanguage = this.router.url.includes('EN')==true?"EN":"JP";
  }

}
