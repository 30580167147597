<div class="myBase">
  <div class="myBackgroundLayer">
    <div class="row">
      <div class="myAccountTitle">
        <h2>
        </h2>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <mat-card class="supportingMembershipAnounce">
        <br><br>
        <h4>
          {{this.router.url.includes('EN')==true?'Registration unsuccessful': 'レジストレーション中に問題が発生しました。'}}
        </h4>
        <h4>
          {{this.router.url.includes('EN')==true?'Please contact JCFN office': 'JCFN事務局までご連絡ください'}}<br>
          Email: info@jcfn.org
        </h4>
        <br><br>
        <div *ngIf="this.authService.isLoggedIn()">
          <a [routerLink]="this.router.url.includes('EN')==true?'/members/myProfile/EN': '/members/myProfile/JP'"
            class="btn btn-link">{{this.router.url.includes('EN')==true?'My Account': 'マイアカウント'}}</a>
        </div>
        <div *ngIf="this.authService.isLoggedIn()==false">
          <a [routerLink]="this.router.url.includes('EN')==true?'/home/EN': '/home/JP'"
            class="btn btn-link">{{this.router.url.includes('EN')==true?'Home': 'ホーム'}}</a>&nbsp;&nbsp;
        </div>
      </mat-card>
    </div>
  </div>
</div>