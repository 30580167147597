import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../../../services/auth/auth.service";
import { User } from "../../../models/user";
import { MyAddress, NewUser, PersonalInfo} from "../../../models/mailchimp/newUser";
import { UserService } from "../../../services/user/user.service";
import { PaymentService} from "../../../services/payment/payment.service";
import { MatRadioChange } from '@angular/material/radio';
import { MatStepper } from '@angular/material/stepper';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
//For Dialog
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA , MatDialogConfig} from '@angular/material/dialog';
import { CheckHavingAccountComponent} from "../../dialog/check-having-account/check-having-account.component";
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { TOOLTIP_PANEL_CLASS } from '@angular/material/tooltip';
import {PaymentMaster, LineItem, RecurringPayment} from "../../../models/payment/paymentMaster";
import { EcRegistrationService } from 'src/app/services/conference/ec/ec-registration.service';


@Component({
  selector: 'app-accept-terms',
  templateUrl: './accept-terms.component.html',
  styleUrls: ['./accept-terms.component.css']
})
export class AcceptTermsComponent implements OnInit {
  doctrinalStatementJP=
  "JCFNは正統的福音主義キリスト教信仰をその立場とし、会員間の一致の為に次の信 仰基準を設けています。"+
  "JCFNは正統的福音主義キリスト教信仰をその立場とし、会員間の一致の為に次の信仰基準を設けています。"+
  "1. 旧新約聖書六十六巻は神の霊感によって記された神の言葉であって、原典において誤りを含まず、信仰と生活の唯一の規範である。"+
  "2. 唯一真の神は、父、御子、聖霊の三位にして一体であり、全能の主権者、創造主である。"+
  "3. 聖霊によって証されているイエスキリストは真の神であり、真の人である。"+
  "イエス キリストは処女より生まれ、罪人の救いのために身代わりとして贖罪の死を遂げ、死人 より復活し、"+
  "父なる神の右に座し、再び栄光のうちに再臨し、全ての人を裁き、神の国を完成させる。"+
  "4. 人間は生まれながらの罪人であり、主イエスキリストの贖罪の御業によってのみ罪から救われる。"+
  "5. 救いは信仰によってのみ受ける神の恵みである。罪人は聖霊によって新生させられ 清くされ、救いを完成される。"+
  "6. 全て救われた者はキリストのからだに属し、一体である。"
  doctrinalStatementEN=
"The doctrinal statement of JCFN is in agreement with the basic tenets of the historical evangelical "+
"Christian faith and it provides for a unity of belief among all members."+
"1. We believe that all sixty-six books of the Old and New Testaments are the Word of God, "+
"which in their original form are perfect. They are our sole text for life and faith."+
"2. We believe that there is only one true God, revealed in three persons: the Father, "+
"the Son, and the Holy Spirit. We believe that He is the Almighty, Sovereign Creator of everything."+
"3. We believe that Jesus Christ, testified to in the Scriptures, is true God and true man. "+
"We believe that He was born of a virgin, died as an atoning sacrifice for our sins, was resurrected from the dead, "+
"and is seated on the right hand of God the Father. He will come again, filled with glory, "+
"to judge all people and consummate the Kingdom of God."+
"4. We believe that all human beings are sinful by nature and can be saved from sin only by the redemption of Jesus Christ."+
"5. We believe that salvation is only by God's grace through faith. We believe that sinners can be renewed and purified, "+
"and that their salvation is made complete through the Holy Spirit."+
"6. We believe that all who are saved are a part of Christ's body, the church, and are one in Christ."

  mySelectedLanguage:string;
  firstFormGroup: FormGroup;
  loaded:boolean;

  user: User = {
    PasswordHash: null,
    Id: null,
    UserName: null,
    Email: null,
    FirstName: null,
    LastName: null,
    Password: null,
    Prefix: null,
    Shimei: null,
    Myoji: null,
    Street: null,
    Street2: null,
    City: null,
    State: null,
    Zip: null,
    Country: null,
    Yubin_Bango: null,
    To_Do_Fu_Ken: null,
    Shi_Gun_Ku: null,
    Cho_Son: null,
    Apartment_Etc: null,
    To_Do_Fu_Ken_JP: null,
    Shi_Gun_Ku_JP: null,
    Cho_Son_JP: null,
    Apartment_Etc_JP: null,
    Gender: null,
    DOB: null,
    TelNo: null,
    TimezoneName: null,
    UTCdiff: null,
    Token: null,
    Occupation: null,
    MemberStatus: null,
    Married: null,
    Christian: null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount: null,
    RegisteredDate: null,
    LastAccessedDate: null,

    //From siteground
    LoginId: null,
    MiddleNameEN: null,
    MiddleNameJP: null,
    PrefixJP: null,
    OccupationDetail: null,
    Organization: null,
    LivingCountry: null,
    PhoneEN: null,
    PhoneMobileEN: null,
    PhoneJP: null,
    PhoneMobileJP: null,
    ChurchNameEN: null,
    ChurchNameJP: null,
    MemberType: null,
    MemberDetail: null,
    MemberMemo: null,
    Shokaisha: null,
    Reason: null,
    NewsLetters: null,
    StaffReports: null,
    OtherLetters: null,
    NewsLetterLanguage: null,
    BasicRoles: null,
    //Added new
    MigrateFromSiteGround: null,
    AdminAccess: null,
    JCFNAccess: null,
    SupportingMember: null,
    LastAddressUpdatedDate: null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };

  newUser:NewUser={
    api_key:null,
    server:null,
    list_id:null,
    email_address:null,
    status:null,
    merge_fields:null
  }
  personalInfo: PersonalInfo={
    FNAME:null,
    LNAME:null,
    ADDRESS:null
  }
  myAddress:MyAddress={
    addr1:null,
    city:null,
    state:null,
    zip:null
  }

  constructor(
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    public authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private registrationService: EcRegistrationService,
    private dialog: MatDialog,
    private paymentService:PaymentService
  ) { }

  ngOnInit(): void {
    this.mySelectedLanguage = this.router.url.includes('EN')==true?"EN":"JP";
    this.firstFormGroup = this._formBuilder.group({
      myAcceptTerms: ['', Validators.required],
    });

    if (sessionStorage.getItem("loginUserName") != null) {
      var loginUser = sessionStorage.getItem("loginUserName")
      this.loaded = false;
      this.userService.getUserProfile(loginUser).subscribe(result => {
        this.user = result;
        if (this.user != null) {
          // sessionStorage.setItem("loginUserFirstName", this.user.FirstName);
          // sessionStorage.setItem("loginUserAccessJCFN", this.user.JCFNAccess!=null?this.user.JCFNAccess.toString():'false');
          // sessionStorage.setItem("loginUserAccessAdmin", this.user.AdminAccess!=null?this.user.AdminAccess.toString():'false');
        }
        this.loaded=true;
      });
    } else {
      this.loaded = true;
    }
    this.user.Id = sessionStorage.getItem("loginUserId");
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  onClickGoBack(){
    this.router.navigate(["/home/" +this.getMyLanguage()]);
  }

  onClickSelectSupportingMember(){
    this.router.navigate(["/members/supportingTop/"+this.getMyLanguage()]);
  }

  onClickSelectRegularMember(){
    this.router.navigate(["/members/selectMemberType/"+this.getMyLanguage()]);
  }

  onChangeAgree(value: boolean) {
      this.userService.updateAcceptMembershipTerm(this.user).subscribe(data => {
      });
      this.addUserToMailchimp();
  }

  addUserToMailchimp(){
    var apiKey = "03d7b7b8697425e15be37701e1e1c19f-us5";
    var server = "us5";
    var audienceId = "7035d07ae0";
    this.myAddress.addr1 = this.user.LivingCountry=="Japan"?this.user.Cho_Son:this.user.Street;
    this.myAddress.city = this.user.LivingCountry=="Japan"?this.user.Shi_Gun_Ku:this.user.City;
    this.myAddress.state = this.user.LivingCountry=="Japan"?this.user.To_Do_Fu_Ken:this.user.State;
    this.myAddress.zip = this.user.LivingCountry=="Japan"?this.user.Yubin_Bango:this.user.Zip;
    this.personalInfo.FNAME=this.user.FirstName;
    this.personalInfo.LNAME=this.user.LastName;
    this.personalInfo.ADDRESS=this.myAddress;
    this.newUser.merge_fields=this.personalInfo;
    this.newUser.api_key=apiKey;
    this.newUser.server=server;
    this.newUser.list_id = audienceId;
    this.newUser.email_address = this.user.Email;
    this.newUser.status = "subscribed";
    this.registrationService.addUserToMailchimp(this.newUser).subscribe(data =>{

    });
  }

  onClickNext(){
    this.router.navigate(["/members/selectNewsLetterLanguage/"+this.getMyLanguage()]);
  }



}
