<div class="myBase">
 <div class="myBackgroundLayer">
   <div class="myContainer">
     <div class="d-flex justify-content-center">
       <img src="../../../assets/images/giveHeart.jpg" class="logoImageGive">
     </div>
     <br>
     <div class="container" >
       <mat-tab-group animationDuration="0ms">
         <mat-tab label="  {{this.router.url.includes('EN')==true?'Supporting Member': '維持会員'}}">
           <mat-vertical-stepper [linear]=false #stepper>
             <mat-step [stepControl]="firstFormGroup">
               <form [formGroup]="firstFormGroup">
                 <ng-template matStepLabel>Select Item</ng-template>
                 <mat-form-field class="registrationInputForm" appearance="outline">
                   <mat-label>Item </mat-label>
                   <mat-select formControlName="myMemberType" [(ngModel)]="paymentMaster.ItemName"
                     (selectionChange)='onMemberTypeSelected()' class="matInputBox">
                     <mat-option *ngFor="let memberType of memberTypes" [value]="memberType.value" class="matInputBox">
                       {{ memberType.display }}
                     </mat-option>
                   </mat-select>
                 </mat-form-field>
             
                 <mat-form-field appearance="outline" class="registrationInputForm">
                   <mat-label>
                     {{this.router.url.includes('EN')==true?'Amount': '献金額'}}
                   </mat-label>
                   <input type='number' matInput placeholder="" formControlName="myAmount" readonly
                     [(ngModel)]="paymentMaster.Amount" required class="registrationInputBox">
                   <mat-error>
                     {{this.router.url.includes('EN')==true?'First Name is required': '未入力'}}
                   </mat-error>
                 </mat-form-field>
                   <button  *ngIf="showRecurringGroup==false" type="button" class="btn btnInStepperRecurring" (click)="onClickRecurring()">Make this a Recurring</button>
                 <div *ngIf="showRecurringGroup==true">
                   <button type="button" class="btn btnInStepper" (click)="onClickCancelRecurring()">Cancel this schedule</button>
                 </div>
                   <div *ngIf="showRecurringGroup==true">
                   
                   <ng-template matStepLabel>Select Interval</ng-template>
                     <mat-form-field class="registrationInputForm" appearance="outline">
                       <mat-label>Given</mat-label>
                       <mat-select formControlName="myInterval" [(ngModel)]="recurringPayment.Interval"
                         (selectionChange)='onIntervalSelected()' class="matInputBox">
                         <mat-option *ngFor="let interval of intervals" [value]="interval.value" class="matInputBox">
                           {{ interval.display }}
                         </mat-option>
                       </mat-select>
                     </mat-form-field>
                     
                   
                       <mat-form-field appearance="outline" class="registrationInputForm">
                         <mat-label>
                           Starting From
                         </mat-label>
                         <input matInput placeholder="" formControlName="myStartDate" [(ngModel)]="recurringPayment.StartDate" 
                           class="registrationInputBox"  [matDatepicker]="StartingDate">
                           <mat-datepicker-toggle matSuffix [for]="StartingDate"></mat-datepicker-toggle>
                           <mat-datepicker #StartingDate></mat-datepicker>
                       </mat-form-field>
                   

                   </div>      
                
                 
              <div class="d-flex justify-content-center">
               <button mat-button matStepperNext class="btnMatStep" 
               >Next</button>
              </div>
                   
                 
               </form>
             </mat-step>
             <mat-step [stepControl]="secondFormGroup">
               <form [formGroup]="secondFormGroup">
                 <ng-template matStepLabel>Fill out your name</ng-template>
                 <div>
                   <mat-form-field appearance="outline" class="registrationInputForm">
                     <mat-label>
                       {{this.router.url.includes('EN')==true?'First Name': '名　ローマ字'}}
                     </mat-label>
                     <input matInput placeholder="" formControlName="myFirstName" [(ngModel)]="paymentMaster.FirstName"
                       required class="registrationInputBox">
                     <mat-error>
                       {{this.router.url.includes('EN')==true?'First Name is required': '未入力'}}
                     </mat-error>
                   </mat-form-field>
   
                   <mat-form-field appearance="outline" class="registrationInputForm">
                     <mat-label>
                       {{this.router.url.includes('EN')==true?'Last Name': '姓　ローマ字'}}
                     </mat-label>
                     <input matInput placeholder="" formControlName="myLastName" [(ngModel)]="paymentMaster.LastName"
                       required class="registrationInputBox">
                     <mat-error>
                       {{this.router.url.includes('EN')==true?'Last Name is required': '未入力'}}
                     </mat-error>
                   </mat-form-field>
                 </div>
                 <div>
                   <mat-form-field class="registrationInputForm" appearance="outline">
                     <mat-label>
                       {{this.router.url.includes('EN')==true?'E-mail': 'Eメール'}}
                     </mat-label>
                     <input formControlName="myEmail" [(ngModel)]="paymentMaster.Email" name="Email" matInput
                       placeholder="" required class="registrationInputBox" />
                     <mat-error>
                       {{this.router.url.includes('EN')==true?'Email is required': '未入力'}}
                     </mat-error>
                   </mat-form-field>
                 </div>
                 <div class="d-flex justify-content-center">
                   <button mat-button matStepperPrevious class="btnMatStep">Back</button>
                   <button mat-button matStepperNext class="btnMatStep">Next</button>
                 </div>
               </form>
             </mat-step>
             <mat-step>
               <ng-template matStepLabel>Payment Info</ng-template>
         

               <form [formGroup]="thirdFormGroup"  
               id="paymentForm"  method="POST" action="http://localhost:12770/api/payment/makeRecurringPayment">
                 
               <input type="hidden" name="dataValue" id="dataValue" />
               <input type="hidden" name="dataDescriptor" id="dataDescriptor" />   
               <!-- <input type="hidden" name="firstName" id="firstName" [(ngModel)]="paymentMaster.FirstName" />   
               <input type="hidden" name="lastName" id="lastName" [(ngModel)]="paymentMaster.LastName" />   
               <input type="hidden" name="email" id="email" [(ngModel)]="paymentMaster.Email" />    -->
               <!-- <button 
                 
                   class="AcceptUI btnInStepper" data-billingAddressOptions='{"show":false, "required":false}'
                   data-apiLoginID="6H8vc9Km4"
                   data-clientKey="4Pxk7P8A4bzYZLm8dmHjg8K39mPB7J7u24fr35cJvUj4s3bVmA64ZvGANT3fhyjP"
                   data-acceptUIFormBtnTxt="Submit Test" data-acceptUIFormHeaderTxt="Payment Information"
                   data-paymentOptions='{"showCreditCard": true, "showBankAccount": true}'
                   data-responseHandler="responseHandler"
                   (click)="onClickEnterPaymentInfo(stepper)"
                   >Enter Payment Info
                 </button> -->

                 <div *ngIf="clickedPaymentInfo==true && successfulPaymentInput==false"
                   class="invalidComment">
                   {{this.router.url.includes('EN')==true?'Invalid Input': 'カード認証不成功'}}
                 </div>
                 <div *ngIf="clickedPaymentInfo==true && successfulPaymentInput==true"
                 class="validComment">
                 {{this.router.url.includes('EN')==true?'Card Validation Success': 'カード認証成功'}}
               </div>
               </form>
               <div class="d-flex justify-content-center">
                 <button mat-button matStepperPrevious class="btnMatStep">Back</button>
                 <button 
                 *ngIf="isPaymentInfoValid()==true"
                 mat-button matStepperNext class="btnMatStep">Next</button>
               </div>
             </mat-step>
             <mat-step>
               <ng-template matStepLabel>Payment Info</ng-template>
               <form  [formGroup]="forthFormGroup" method="GET">
                 <div>
                   <button type="button" class="btn btnInStepper" (click)="onClickPayNow()">Pay Now</button>
                 </div>
               </form>
               <div class="d-flex justify-content-center">
                 <button mat-button matStepperPrevious class="btnMatStep">Back</button>
               </div>
             </mat-step>
           </mat-vertical-stepper>







            <!-- <form [formGroup]="firstFormGroup" (ngSubmit)="onSubmit()" id="paymentForm">
             <div class="searchItem">
               <mat-form-field class="registrationInputForm" appearance="outline">
                 <mat-label>Item </mat-label>
                 <mat-select formControlName="myMemberType" [(ngModel)]="paymentMaster.ItemName"
                   (selectionChange)='onMemberTypeSelected()' class="matInputBox">
                   <mat-option *ngFor="let memberType of memberTypes" [value]="memberType.value" class="matInputBox">
                     {{ memberType.display }}
                   </mat-option>
                 </mat-select>
               </mat-form-field>
             </div>
             <div>
               <mat-form-field appearance="outline" class="registrationInputForm">
                 <mat-label>
                   {{this.router.url.includes('EN')==true?'Amount': '献金額'}}
                 </mat-label>
                 <input type='number' matInput placeholder="" formControlName="myAmount"
                   [(ngModel)]="paymentMaster.Amount" required class="registrationInputBox">
                 <mat-error>
                   {{this.router.url.includes('EN')==true?'First Name is required': '未入力'}}
                 </mat-error>
               </mat-form-field>
             </div>
             <div>
               <mat-form-field appearance="outline" class="registrationInputForm">
                 <mat-label>
                   {{this.router.url.includes('EN')==true?'First Name': '名　ローマ字'}}
                 </mat-label>
                 <input matInput placeholder="" formControlName="myFirstName" [(ngModel)]="paymentMaster.FirstName"
                   required class="registrationInputBox">
                 <mat-error>
                   {{this.router.url.includes('EN')==true?'First Name is required': '未入力'}}
                 </mat-error>
               </mat-form-field>

               <mat-form-field appearance="outline" class="registrationInputForm">
                 <mat-label>
                   {{this.router.url.includes('EN')==true?'Last Name': '姓　ローマ字'}}
                 </mat-label>
                 <input matInput placeholder="" formControlName="myLastName" [(ngModel)]="paymentMaster.LastName"
                   required class="registrationInputBox">
                 <mat-error>
                   {{this.router.url.includes('EN')==true?'Last Name is required': '未入力'}}
                 </mat-error>
               </mat-form-field>
             </div>
             <div>
               <mat-form-field class="registrationInputForm" appearance="outline">
                 <mat-label>
                   {{this.router.url.includes('EN')==true?'E-mail': 'Eメール'}}
                 </mat-label>
                 <input formControlName="myEmail" [(ngModel)]="paymentMaster.Email" name="Email" matInput
                   placeholder="" required class="registrationInputBox" />
                 <mat-error>
                   {{this.router.url.includes('EN')==true?'Email is required': '未入力'}}
                 </mat-error>
               </mat-form-field>
             </div>
             <button class="btnMyPayment">
               Confirm Payment
             </button>

             <input type="hidden" name="dataValue" id="dataValue" />
             <input type="hidden" name="dataDescriptor" id="dataDescriptor" />
           </form>
           <div >
             <form method="GET" action="http://localhost:4206/#/give/JP">
               <button 

                 class="AcceptUI btnMyPayment" data-billingAddressOptions='{"show":false, "required":false}'
                 data-apiLoginID="6H8vc9Km4"
                 data-clientKey="4Pxk7P8A4bzYZLm8dmHjg8K39mPB7J7u24fr35cJvUj4s3bVmA64ZvGANT3fhyjP"
                 data-acceptUIFormBtnTxt="Submit Test" data-acceptUIFormHeaderTxt="Payment Information"
                 data-paymentOptions='{"showCreditCard": true, "showBankAccount": true}'
                 data-responseHandler="responseHandler">Pay Now test (One Time Only)
               </button>
               <div *ngIf="clickedNextFromFirstFormGroup==true && firstFormGroup.status=='INVALID'"
                 class="invalidComment">
                 {{this.router.url.includes('EN')==true?'There is something incomplete': '未入力があります'}}
               </div>
             </form>
           </div>  -->

         </mat-tab>
         <mat-tab label="MANAGE MY GIVING">MANAGE MY GIVING</mat-tab>
       </mat-tab-group>
     </div>
   </div>
 </div>
</div>