import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router, NavigationExtras } from "@angular/router";
import { MatRadioChange } from '@angular/material/radio';
import { User } from 'src/app/models/user';
import { CancelOrSaveConfirmationComponent } from 'src/app/components/dialog/cancel-or-save-confirmation/cancel-or-save-confirmation.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { RegistrationConferenceEmbedComponent } from 'src/app/components/dialog/EC/registration-conference-embed/registration-conference-embed.component';
import { MyAccountEditComponent } from 'src/app/components/dialog/EC/my-account-edit/my-account-edit.component';
import { GRCConference, GRCRelatedPerson, GRCVolunteerSelection } from 'src/app/models/conference/GRCConference';
import { RegistrationConferenceEmbedGRCComponent } from 'src/app/components/dialog/GRC/registration-conference-embed-grc/registration-conference-embed-grc.component';
@Component({
  selector: 'app-room-info-grc',
  templateUrl: './room-info-grc.component.html',
  styleUrls: ['./room-info-grc.component.css']
})
export class RoomInfoGRCComponent implements OnInit {
  loaded: boolean = true;
  myFormGroup: FormGroup;
  myMessage: string;
  showRelatedPerson?: boolean;
  added?: boolean;
  showInquireChildCare?: boolean = true;
  showInquireChildProgram?: boolean = true;

  public gendersJP = [
    { value: "Man", display: "男性" },
    { value: "Woman", display: "女性" },
  ];
  public gendersEN = [
    { value: "Man", display: "Man" },
    { value: "Woman", display: "Woman" },
  ];

  user: User = {
    PasswordHash: null,
    Id: null,
    UserName: null,
    Email: null,
    FirstName: null,
    LastName: null,
    Password: null,
    Prefix: null,
    Shimei: null,
    Myoji: null,
    Street: null,
    Street2: null,
    City: null,
    State: null,
    Zip: null,
    Country: null,
    Yubin_Bango: null,
    To_Do_Fu_Ken: null,
    Shi_Gun_Ku: null,
    Cho_Son: null,
    Apartment_Etc: null,
    To_Do_Fu_Ken_JP: null,
    Shi_Gun_Ku_JP: null,
    Cho_Son_JP: null,
    Apartment_Etc_JP: null,
    Gender: null,
    DOB: null,
    TelNo: null,
    TimezoneName: null,
    UTCdiff: null,
    Token: null,
    Occupation: null,
    MemberStatus: null,
    Married: null,
    Christian: null,
    Baptized: null,
    ReturneeInfo: null,
    HasReturningChurch: null,
    ZoomAccount: null,
    RegisteredDate: null,
    LastAccessedDate: null,
    //From siteground
    LoginId: null,
    MiddleNameEN: null,
    MiddleNameJP: null,
    PrefixJP: null,
    OccupationDetail: null,
    Organization: null,
    LivingCountry: null,
    PhoneEN: null,
    PhoneMobileEN: null,
    PhoneJP: null,
    PhoneMobileJP: null,
    ChurchNameEN: null,
    ChurchNameJP: null,
    MemberType: null,
    MemberDetail: null,
    MemberMemo: null,
    Shokaisha: null,
    Reason: null,
    NewsLetters: null,
    StaffReports: null,
    OtherLetters: null,
    NewsLetterLanguage: null,
    BasicRoles: null,
    //Added new
    MigrateFromSiteGround: null,
    AdminAccess: null,
    JCFNAccess: null,
    SupportingMember: null,
    LastAddressUpdatedDate: null,
    AcceptMembershipTerm: null,
    MembershipType: null
  };
  relatedPerson: GRCRelatedPerson = {
    Id: null,
    RelatedId: null,
    Email: null,
    FirstNameRelated: null,
    LastNameRelated: null,
    MyojiRelated: null,
    ShimeiRelated: null,
    DOBRelated: null,
    GenderRelated: null,
    ChildCare: null,
    ChildProgram: null
  };
  relatedPersons: GRCRelatedPerson[] = [];
  volunteerSelections: GRCVolunteerSelection[] = [];
  conference = new GRCConference();

  constructor(
    public router: Router,
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
  ) { }
  mySearch: any = {};
  ngOnInit(): void {
    this.myFormGroup = this._formBuilder.group({
      myRoomShareType: [null, Validators.required],
      mySpouseFirstName: [null,],
      mySpouseLastName: [null,],
      mySpouseMyojiName: [null,],
      mySpouseShimeiName: [null,],
      myFirstNameRelated: [null,],
      myLastNameRelated: [null,],
      myMyojiRelated: [null,],
      myShimeiRelated: [null,],
      DOBRelated: [null,],
      relativePersonsOz: this._formBuilder.array([
      ])
    });

    if (sessionStorage.getItem("myGRCSearchingUserIdGRC") != null) {
      /*************************************************
       *  A) User info for searching registrant by admin
       *************************************************/
      this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnConferenceAdminGRC"));
      if (this.mySearch != null) {
        console.log('admin is editing this user')
        this.conference = this.mySearch.myEditingConference;
        this.patchModelValue(this.conference);
        this.retrievePersonFromDb(this.conference);
        this.loaded = true;
      } else {
        this.loaded = true;
      }
    } else {
      /*####################################################################################
      # Get conference info from previous page for myself
      #####################################################################################*/
      this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnConference"));
      if (this.mySearch != null) {
        this.conference = this.mySearch.myEditingConference;
        this.patchModelValue(this.conference);
        this.retrievePersonFromDb(this.conference);
        this.loaded = true;
      } else {
      }
    }
  }

  relativePersonsOz(): FormArray {
    return this.myFormGroup.get("relativePersonsOz") as FormArray
  }

  retrievePersonFromDb(model: GRCConference) {
    if (model.myGRCRelatedPersons != null && model.myGRCRelatedPersons.length > 0) {
      model.myGRCRelatedPersons.forEach(person => {
        var personFromDb = this._formBuilder.group({
          RelatedId: [model.UserId],
          Email: [model.Email],
          FirstNameRelated: [person.FirstNameRelated,],
          LastNameRelated: [person.LastNameRelated,],
          MyojiRelated: [person.MyojiRelated,],
          ShimeiRelated: [person.ShimeiRelated,],
          DOBRelated: [person.DOBRelated,],
          ChildCare: [person.ChildCare],
          GenderRelated: [person.GenderRelated,],
          ChildProgram: [person.ChildProgram]
        })
        if (this.calculate_age(person.DOBRelated)) {
          this.showInquireChildCare = true;
        }

        this.relativePersonsOz().push(personFromDb);
      });
    }
    this.added = true;
  }

  newPerson(): FormGroup {
    return this._formBuilder.group({
      RelatedId: [this.conference.UserId],
      Email: [this.conference.Email],
      FirstNameRelated: [null,],
      LastNameRelated: [null,],
      MyojiRelated: [null,],
      ShimeiRelated: [null,],
      DOBRelated: [null,],
      ChildCare: [null,],
      ChildProgram: [null,],
      GenderRelated: [null,],
    })
  }

  addPerson() {
    this.relativePersonsOz().push(this.newPerson());
    this.added = true;
  }

  removePerson(i: number) {
    // alert('deleting:'+i)
    this.relativePersonsOz().removeAt(i);
  }

  getMyLanguage(): string {
    return this.router.url.includes('EN') == true ? 'EN' : 'JP';
  }

  patchModelValue(model: GRCConference) {
    this.myFormGroup.patchValue({
      myRoomShareType: model.RoomInfo
    });

    if (model.RoomInfo == "WithFamily" || model.RoomInfo == "ShareWithOthers") {
      this.showRelatedPerson = true;
    } else {
      this.showRelatedPerson = false;
    }
  }
  onChangeDOB(event: MatDatepickerInputEvent<Date>, i: number) {
    console.log('clicked line#:' + i)
    console.log(event.value)
    var myAge = this.calculate_age(event.value);
    if (myAge <= 1) {
      // this.showInquireChildCare=true;
     // this.relatedPerson.Under2YearsOld = true;
      this.myFormGroup.get('relativePersonsOz').value[i].Under2YearsOld = true;
      console.log('Value Under2YersOld is : ' + this.myFormGroup.get('relativePersonsOz').value[i].Under2YearsOld);
      console.log('Value FirstNameRelated is : ' + this.myFormGroup.get('relativePersonsOz').value[i].FirstNameRelated);
    } else {
      //  this.showInquireChildCare=false;
    // this.relatedPerson.Under2YearsOld = false;
      this.myFormGroup.get('relativePersonsOz').value[i].Under2YearsOld = false;
    }
  }
  onChangeNeedChildCare(value: boolean) {
    console.log(value)
  }
  onChangeNeedChildProgram(value: boolean) {
    console.log(value)
  }

  calculate_age(dob: Date) {
    var diff_ms = Date.now() - new Date(dob).getTime();
    var age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }

  getFormValue() {
    this.conference.RoomInfo = this.myFormGroup.get('myRoomShareType').value;
    if (this.conference.RoomInfo != 'AnyoneIsFine') {
      this.conference.myGRCRelatedPersons = this.myFormGroup.get('relativePersonsOz').value;
    }
  }

  onChangemyRoomShareType(event: MatRadioChange) {
    this.conference.RoomInfo = event.value;
    if (event.value == "WithFamily" || event.value == "ShareWithOthers") {
      this.showRelatedPerson = true;
    } else {
      this.conference.myGRCRelatedPersons = null;
      this.showRelatedPerson = false;
    }
  }

  onClickSavePerson() {
  }

  onClickGoForward() {
    this.getFormValue();
    if (this.router.url.includes('/registration/GRC/')) {
      sessionStorage.removeItem("myWorkingOnConference");
      sessionStorage.setItem(
        "myWorkingOnConference",
        JSON.stringify({ myEditingConference: this.conference })
      );
      this.router.navigate(["/registration/GRC/lodgingScheduleGRC/" + this.getMyLanguage()]);
    } else {
      sessionStorage.removeItem("myWorkingOnConferenceAdminGRC");
      sessionStorage.setItem(
        "myWorkingOnConferenceAdminGRC",
        JSON.stringify({ myEditingConference: this.conference })
      );
      this.dialog.closeAll();
      this.openForwardEmbed();
    }
  }
  openForwardEmbed() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight = '800px'; //Desktop
    dialogConfig.maxWidth = '1200px';//Desktop
    dialogConfig.height = '100%';//Mobile
    dialogConfig.width = '100%';//Mobile
    dialogConfig.panelClass = 'custom-modalbox'; //Refer global level
    dialogConfig.data = { myPage: 'lodgingScheduleGRC' };
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedGRCComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  onClickGoBack() {
    if (this.router.url.includes('/registration/GRC/')) {
      this.router.navigate(["/registration/GRC/transportationGRC/" + this.getMyLanguage()]);
    } else {
      this.dialog.closeAll();
      this.openBackEmbed();
    }
  }
  openBackEmbed() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight = '800px'; //Desktop
    dialogConfig.maxWidth = '1200px';//Desktop
    dialogConfig.height = '100%';//Mobile
    dialogConfig.width = '100%';//Mobile
    dialogConfig.panelClass = 'custom-modalbox'; //Refer global level
    dialogConfig.data = { myPage: 'transportationGRC' };
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedGRCComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }
  onSubmit() {
  }

  cancelProcess() {
    this.getFormValue();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight = '210px'; //Desktop
    dialogConfig.maxWidth = '370px';//Desktop
    dialogConfig.height = '100%';//Mobile
    dialogConfig.width = '100%';//Mobile
    dialogConfig.panelClass = 'custom-modalbox'; //Refer global level
    dialogConfig.data = { conference: this.conference };
    const dialogRef = this.dialog.open(CancelOrSaveConfirmationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
      //Retreive updated registration Info.
      //this.ngOnInit();
      let navigationExtras: NavigationExtras = {
        queryParams: this.conference
      };
      if (result != 'cancel') {
        if (this.router.url.includes('/registration/GRC/')) {
          this.router.navigate(["/registration/GRC/myAccountGRC/" + this.getMyLanguage()], navigationExtras);
        } else {
          this.dialog.closeAll();
          this.openMyAccountEdit();
        }
      }
    })
  }

  openMyAccountEdit() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight = '800px'; //Desktop
    dialogConfig.maxWidth = '1200px';//Desktop
    dialogConfig.height = '100%';//Mobile
    dialogConfig.width = '100%';//Mobile
    dialogConfig.panelClass = 'custom-modalbox'; //Refer global level
    dialogConfig.data = { userId: this.conference.UserId };
    const dialogRef = this.dialog.open(MyAccountEditComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }
}
