<div class="myBody">
  <div class="myHeaderBase d-flex justify-content-center">
      <div class="myHeaderCore">
          <div>
              <img src='../../../../../assets/images/thumbnail_eclogo.png'  class="ecLogo">
          </div>
      </div>
  </div>
  <div class="myMain">
      <div clas="myMainLeft">
          <div class="myMainHalfTop">
              <!-- Left Top -->
          </div>
          <div class="myMainHalfBottom">
              <!-- Left Bottom -->
          </div>
      </div>
      <div class="myMainCenter">
          <div class="myMainHalfTop d-flex justify-content-center">
              <!-- Main Top -->

              <mat-card class="mainCard b-radius">
                  <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
                  <div *ngIf="!loaded">
                      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                  </div>
                  <!-- <form *ngIf="userFromServer | async; else loading"  -->
                      <form
                      [formGroup]="myFormGroup"
                      (ngSubmit)="onSubmit()"
                      >
                      <div class="float-end stepNumber">11/12</div>
                      <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Scholarship':
                          '奨学金'}}
                      </h2>
                      <div class="stepTitle2nd">
                        {{this.router.url.includes('EN')==true?
                        'The first scholarship application deadline is 10/7 and the final application deadline is 11/11. Scholarship amounts will be announced on this page as soon as they are determined, so please apply as soon as possible. Please note that we may not be able to fulfill all requests. If you are interested in fundraising, please contact us at equipper@equipper.org so that we can send you a fundraising manual.':
                        '奨学金の第一次申し込み締切日は10/7、最終申し込み締切日は11/11です。なお奨学金決定金額は随時決まり次第以下の申し込みページにて発表いたしますので、お早めにこちらのリンクから申請してください。ec奨学金は教会や団体、個人の献金、ファンドレイズによって成り立っています。ご希望にお応え出来ない場合がございますのでご了承ください。また、ファンドレイズに興味がある方はファンドレイズマニュアルをお送りしますので、equipper@equipper.org までお問合せください。'}}
                    </div>
                    <br><br>
                    <div *ngIf="this.router.url.includes('EN')==true"  class="stepTitle2nd">
                      　<a href='https://docs.google.com/forms/d/1GOwEd1z3a1Az6Y29eI_yYrGvVZAAwMcTh6zPQieo8gk/closedform' target='_blank'>Application Link</a>
                       </div>
                       <div *ngIf="this.router.url.includes('EN')!=true"  class="stepTitle2nd">
                       <a href='https://docs.google.com/forms/d/1GOwEd1z3a1Az6Y29eI_yYrGvVZAAwMcTh6zPQieo8gk/closedform' target='_blank'>申請書リンク</a>
                       </div>
                      <!-- <div class="d-flex justify-content-center">
                          <mat-radio-group formControlName="myApplyScholarship"
                             (change)="onChangeApplyScholarship($event)"
                             class="rbGroup">
                             <mat-radio-button value=true class="rbItem"
                             [checked]="conference.ApplyScholarship==true"
                             >
                                {{this.router.url.includes('EN')==true?'Apply': '申し込む'}}</mat-radio-button>
                             <mat-radio-button value=false class="rbItem"
                             [checked]="conference.ApplyScholarship==false"
                             >
                                {{this.router.url.includes('EN')==true?'Not Apply': '申し込まない'}}</mat-radio-button>
                          </mat-radio-group>
                          <mat-error *ngIf="myFormGroup.get('myApplyScholarship').hasError('required')  && myFormGroup.get('myApplyScholarship').touched">
                              {{this.router.url.includes('EN')==true?'Japan Regional Meeting is required': '未入力'}}
                            </mat-error>
                       </div> -->

                          <!-- <div class="d-flex justify-content-center">
                                <mat-form-field appearance="outline" class="registrationInputForm">
                                  <mat-label>
                                    {{this.router.url.includes('EN')==true?'Amount desired': '希望額'}}
                                  </mat-label>
                                  <input type="number"  matInput placeholder="" formControlName="myApplyingScholarshipAmount"
                                  [disabled]="disableSelectingApplyScholarship"
                                    class="registrationInputBox">
                                    <span matPrefix>$&nbsp;</span>
                                  <mat-error>
                                    {{this.router.url.includes('EN')==true?'Amount is required': '未入力'}}
                                  </mat-error>
                                </mat-form-field>
                          </div> -->
                          <!-- <div class="d-flex justify-content-center">
                                <mat-form-field appearance="outline" class="registrationInputFormTextArea">
                                  <mat-label>
                                    {{this.router.url.includes('EN')==true?'Reason': '理由'}}
                                  </mat-label>
                                  <textarea matInput placeholder="" formControlName="myApplyingReason"
                                  [disabled]="disableSelectingApplyScholarship"
                                    class="registrationInputBox">
                                  </textarea>
                                  <mat-error>
                                    {{this.router.url.includes('EN')==true?'Applying reason is required': '未入力'}}
                                  </mat-error>
                                </mat-form-field>
                            </div> -->
                      <div class=" position-absolute bottom-0 start-50 translate-middle-x">
                          <div class="d-flex justify-content-center">
                              <button type="button" class="btn btnProcess" (click)="onClickGoBack()">
                                  {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                              </button>
                              <button [disabled]="!myFormGroup.valid" type="button" class="btn btnProcess"
                                  (click)="onClickGoForward()">
                                  {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                              </button>
                          </div>
                          <div class="d-flex justify-content-center">
                              <button type="button" class="btn link" (click)="cancelProcess()">
                                  {{this.router.url.includes('EN')==true?'Cancel': 'キャンセル'}}
                                </button>
                          </div>
                      </div>
                       <!-- <button type="submit" [disabled]="!myFormGroup.valid">Submit</button> -->
                  </form>
              </mat-card>
          </div>
          <div class="myMainHalfBottom">
              <!-- Main Bottom -->
          </div>
      </div>
      <div class="myMainRight">
          <div class="myMainHalfTop">
              <!-- Right Top -->
          </div>
          <div class="myMainHalfBottom">
              <!-- Right Bottom -->
          </div>
      </div>
  </div>
  <!-- <footer>footer</footer> -->
</div>
