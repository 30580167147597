import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { UserService} from "../../../../services/user/user.service";
import { Router, ActivatedRoute,NavigationExtras } from "@angular/router";
import { MatRadioChange } from '@angular/material/radio';
import { ECConference } from 'src/app/models/conference/ECConference';
import { User } from 'src/app/models/user';
import { CancelOrSaveConfirmationComponent } from 'src/app/components/dialog/cancel-or-save-confirmation/cancel-or-save-confirmation.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
//For Dialog
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA , MatDialogConfig} from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { EcRegistrationService } from 'src/app/services/conference/ec/ec-registration.service';
import { RelatedPerson} from "../../../../models/conference/RelatedPerson";
import { VolunteerSelection} from "../../../../models/conference/volunteerSelection";
import { RegistrationConferenceEmbedComponent } from 'src/app/components/dialog/EC/registration-conference-embed/registration-conference-embed.component';
import { MyAccountEditComponent } from 'src/app/components/dialog/EC/my-account-edit/my-account-edit.component';
@Component({
  selector: 'app-volunteers',
  templateUrl: './volunteers.component.html',
  styleUrls: ['./volunteers.component.css']
})
export class VolunteersComponent implements OnInit {
  loaded:boolean=true;
  myFormGroup: FormGroup;
  myMessage:string;
  mySelectedLanguage: string;

  prayerChecked?:boolean;
  praiseTeamChecked?:boolean;
  bibleReadingChecked?:boolean;
  skitChecked?:boolean;
  PAChecked?:boolean;
  propresenterChecked?:boolean;
  videoPhotoChecked?:boolean;
  translatorChecked?:boolean;
  interpreterChecked?:boolean;
  usherChecked?:boolean;
  smallGroupLeaderChecked?:boolean;
  workshopHelperChecked?:boolean;
  kidsProgramHelperChecked?:boolean;
  bookstoreChecked?:boolean;
  nursingChecked?:boolean;
  receptionChecked?:boolean;
  testimoneyChecked?:boolean;
  counselorChecked?:boolean;
  japanRegionalMeetingLeaderChecked?:boolean;
  hospitalityChecked?:boolean;
  airportGreeterTransferChecked?:boolean;
  mentorChecked?:boolean;
  interpreterTranslatorChecked?:boolean;

  user: User = {
    PasswordHash:null,
    Id:null,
    UserName:null,
    Email:null,
    FirstName:null,
    LastName:null,
    Password:null,
    Prefix:null,
    Shimei:null,
    Myoji:null,
    Street:null,
    Street2:null,
    City:null,
    State:null,
    Zip:null,
    Country:null,
    Yubin_Bango:null,
    To_Do_Fu_Ken:null,
    Shi_Gun_Ku:null,
    Cho_Son:null,
    Apartment_Etc:null,
    To_Do_Fu_Ken_JP:null,
    Shi_Gun_Ku_JP:null,
    Cho_Son_JP:null,
    Apartment_Etc_JP:null,
    Gender:null,
    DOB:null,
    TelNo:null,
    TimezoneName:null,
    UTCdiff:null,
    Token:null,
    Occupation:null,
    MemberStatus:null,
    Married:null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount:null,
    RegisteredDate:null,
    LastAccessedDate:null,
    //From siteground
    LoginId:null,
    MiddleNameEN:null,
    MiddleNameJP:null,
    PrefixJP:null,
    OccupationDetail:null,
    Organization:null,
    LivingCountry:null,
    PhoneEN:null,
    PhoneMobileEN:null,
    PhoneJP:null,
    PhoneMobileJP:null,
    ChurchNameEN:null,
    ChurchNameJP:null,
    MemberType:null,
    MemberDetail:null,
    MemberMemo:null,
    Shokaisha:null,
    Reason:null,
    NewsLetters:null,
    StaffReports:null,
    OtherLetters:null,
    NewsLetterLanguage:null,
    BasicRoles:null,
    //Added new
    MigrateFromSiteGround:null,
    AdminAccess:null,
    JCFNAccess:null,
    SupportingMember:null,
    LastAddressUpdatedDate:null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };
  relatedPerson:RelatedPerson={
    Id:null,
    RelatedId:null,
    Email:null,
    FirstNameRelated:null,
    LastNameRelated:null,
    MyojiRelated:null,
    ShimeiRelated:null,
    DOBRelated:null,
    GenderRelated:null
  };
  relatedPersons:RelatedPerson[]=[];
  volunteerSelection: VolunteerSelection={
    Id:null,
    UserId:null,
    Email:null,
    VolunteerName:null,
    VolunteerAccepted:null,
    AcceptedDate:null,
  }
  volunteerSelections:VolunteerSelection[]=[];

  conference: ECConference= {
    ECConferenceId:0,
    UserId: null,
    Email: null,
    UserName: null,
    FirstName: null,
    LastName: null,
    //Program
    ProgramType: null,
    SmallGroupLanguage: null,
    AttendJapanRegionalMeeting:null,
    JapanRegionalMeeting:null,
    ApplyEnglishPartner:null,
    //Payment
    ConferenceFee: null,
    ConferenceDiscountAmount: null,
    ConferenceFeePaidAmount: null,
    ConferenceFeePaidDate: null,
    CouponApproved: null,
    ApplyScholarship: null,
    ApplyingScholarshipAmount: null,
    ApprovedScholarshipAmount: null,
    ApplyingReason: null,
    //Other
    Volunteers:null,
    Student:null,
    MySleepingBag:null,
    BringExtraSleepingBag:null,
    CountExtraSleepingBag:null,
    NeedCommingBus:null,
    CommingBusDeparturePlace:null,
    CommingBusDepartureDateTime:null,
    CommingAirline:null,
    CommingFlightNo:null,
    DepartureCity:null,
    CommingInternationalDomestic:null,
    ArrivalDateTime:null,
    NeedReturningBus:null,
    ReturningBusDepartureDateTime:null,
    ReturningAirline:null,
    ReturningFlightNo:null,
    ReturningCity:null,
    ReturningBusDeparturePlace:null,
    ReturningInternationalDomestic:null,
    DepartureDateTime:null,
    ApplyCounseling: null,
    RoomInfo:null,
    Couple:null,
    Youth:null,
    YouthGrade: null,
    GuardianFirstName: null,
    GuardianLastName: null,
    GuardianMyoji: null,
    GuardianShimei: null,
    GuardianEmail: null,
    GuardianTelNo: null,
    TermsOfParticipation: null,
    TermsOfParticipationParent: null,
    TermsOfParticipationDate: null,
    TermsOfParticipationDateParent: null,
    RegisteredDate: null,
    UpdatedDate: null,

    AdminAccess: null,
    ECCommitteeAccess: null,
    Memo: null,
    Dec26:null,
    Dec27:null,
    Dec28:null,
    Dec29:null,
    Dec30:null,
    Dec31:null,
    KidGroup1:null,
    KidGroup2:null,
    KidGroup3:null,
    KidGroup4:null,
    myRelatedPersons: this.relatedPersons,
    myVolunteerSelections:this.volunteerSelections,
    BusComingConfirmation:null,
    BusReturningConfirmation:null,
    TermsOfParticipationVenue:null,
    TermsOfParticipationDateVenue:null,
    TermsOfParticipationYouth:null,
    TermsOfParticipationDateYouth:null,
    TermsOfParticipationYouthParent:null,
    TermsOfParticipationDateYouthParent:null,
    EmergencyContactFirstName:null,
    EmergencyContactLastName:null,
    EmergencyContactEmail:null,
    EmergencyContactTelNo:null,
    ApplicationFee:null,
    ApplicationFeePaidDate:null,
    BalanceAmount:null,
    BalanceAmountPaidDate:null,
    HasAllergy:null,
    AllergyDetail:null,
    BringInsurance:null,
    MinorHasCustodyInsurance:null,
    MinorHasCustodyInsuranceDate:null,
    InsuranceCompany:null,
    InsuranceAddress:null,
    InsuranceTelNo:null,
    InsurancePolicyNo:null,
    InsuranceGroupNo:null,
    VolunteerAgreeConductCode:null,
    VolunteerAgreeConductCodeDate:null,
    PayingAmount:null,
     //New
    BecomeMentor:null,
    ApplyMentoring:null,
    ReturningDepartureCity:null,
    InsuranceStatus:null,
    DressCodeAgreeYouth:null,
    DressCodeDateYouth:null,
    DressCodeAgreeParent:null,
    DressCodeDateParent:null
}

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private registrationService: UserService,
    private ecRegistrationService : EcRegistrationService,
    private dialog: MatDialog,
  ) { }

  mySearch: any = {};
  ngOnInit(): void {
    this.myFormGroup = this._formBuilder.group({
      myPrayer: [null],
      myVolunteerAgreeConductCode: [null],
      myVolunteerAgreeConductCodeDate: [null],
    });

    if (sessionStorage.getItem("myECSearchingUserId") != null) {
      /*************************************************
       *  A) User info for searching registrant by admin
       *************************************************/
       this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnConferenceAdmin"));
       if (this.mySearch != null) {
         this.conference = this.mySearch.myEditingConference;
         this.patchModelValue(this.conference);
         this.loaded = true;
       }else{
        this.loaded = true;
       }
    } else {
    /*####################################################################################
    #  B) Get conference info from previous page for myself
    #####################################################################################*/
    this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnConference"));
      if (this.mySearch != null) {
        this.conference = this.mySearch.myEditingConference;
        this.patchModelValue(this.conference);
      }else{
      }
    }
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  patchModelValue(model:ECConference){
    //console.log('lenght of myVolunteerSelections: '+ model.myVolunteerSelections.length);
    if(model.myVolunteerSelections!=null &&  model.myVolunteerSelections.length>0){
        model.myVolunteerSelections.forEach(element => {
          if(element.VolunteerName=='Prayer'){
            this.prayerChecked=true;
          }
          if(element.VolunteerName=='Praise Team'){
            this.praiseTeamChecked=true;
          }
          if(element.VolunteerName=='Bible Reading'){
            this.bibleReadingChecked=true;
          }
          if(element.VolunteerName=='Skit'){
            this.skitChecked=true;
          }
          if(element.VolunteerName=='PA'){
            this.PAChecked=true;
          }
          if(element.VolunteerName=='Propresenter'){
            this.propresenterChecked=true;
          }
          if(element.VolunteerName=='Video/Photo'){
            this.videoPhotoChecked=true;
          }
          // if(element.VolunteerName=='Translator'){
          //   this.translatorChecked=true;
          // }
          // if(element.VolunteerName=='Interpreter'){
          //   this.interpreterChecked=true;
          // }
          if(element.VolunteerName=='Interpreter/Translator'){
            this.interpreterTranslatorChecked=true;
          }
          if(element.VolunteerName=='Usher'){
            this.usherChecked=true;
          }
          if(element.VolunteerName=='Small Group Leader'){
            this.smallGroupLeaderChecked=true;
          }
          if(element.VolunteerName=='Workshop Helper'){
            this.workshopHelperChecked=true;
          }
          if(element.VolunteerName=='Kids Program Helper'){
            this.kidsProgramHelperChecked=true;
          }
          if(element.VolunteerName=='Book Store'){
            this.bookstoreChecked=true;
          }
          if(element.VolunteerName=='Nursing'){
            this.nursingChecked=true;
          }
          if(element.VolunteerName=='Reception'){
            this.receptionChecked=true;
          }
          if(element.VolunteerName=='Counselor'){
            this.counselorChecked=true;
          }
          if(element.VolunteerName=='Sharing Testimony'){
            this.testimoneyChecked=true;
          }
          if(element.VolunteerName=='Mentor'){
            this.mentorChecked=true;
          }
          if(element.VolunteerName=='Hospitality'){
            this.hospitalityChecked=true;
          }
          if(element.VolunteerName=='airportGreeterTransfer'){
            this.airportGreeterTransferChecked=true;
          }
        }
        );
        this.volunteerSelections = model.myVolunteerSelections;
    }
    this.myFormGroup.patchValue({
      myVolunteerAgreeConductCode: model.VolunteerAgreeConductCode,
      myVolunteerAgreeConductCodeDate: model.VolunteerAgreeConductCodeDate
    })
  }

  onSubmit() {
  }

  getFormValue() {
  this.conference.myVolunteerSelections = this.volunteerSelections;
  this.conference.VolunteerAgreeConductCodeDate = this.myFormGroup.get('myVolunteerAgreeConductCodeDate').value;
  }

  onChangeConsent(value:boolean){
    this.conference.VolunteerAgreeConductCode=value;
  }

  onCheckVolunteer($event: MatCheckboxChange) {
    if($event.checked){
      const person = {
        Id:0,
        UserId:this.conference.UserId,
        Email:this.conference.Email,
        VolunteerName:$event.source.value,
        VolunteerAccepted:false,
        AcceptedDate:new Date()
      }
      this.volunteerSelections.push(person);
    }else{
      console.log('unchecking');
      this.volunteerSelections.forEach(element => {
        if(element.VolunteerName == $event.source.value){
          console.log('found it!');
          const index = this.volunteerSelections.findIndex(x=>x.VolunteerName==$event.source.value);
          if (index > -1) {
            console.log('removing');
            this.volunteerSelections.splice(index, 1);
          }
        }
      });
    }
  }

  onClickGoForward(){
    this.getFormValue();
    if(this.router.url.includes('/registration/ec/')){
      sessionStorage.removeItem("myWorkingOnConference");
      sessionStorage.setItem(
        "myWorkingOnConference",
        JSON.stringify({ myEditingConference: this.conference })
      );
      this.router.navigate(["/registration/ec/englishPartners/" +this.getMyLanguage()]);
    }else{
      sessionStorage.removeItem("myWorkingOnConferenceAdmin");
      sessionStorage.setItem(
        "myWorkingOnConferenceAdmin",
        JSON.stringify({ myEditingConference: this.conference })
      );
      this.dialog.closeAll();
      this.openForwardEmbed();
    }
  }
  openForwardEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktoop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ myPage: 'englishPartners' };
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  onClickGoBack(){
    if(this.router.url.includes('/registration/ec/')){
      this.router.navigate(["/registration/ec/japanRegionalMeeting/" +this.getMyLanguage()]);
    }else{
      this.dialog.closeAll();
      this.openBackEmbed();
    }
  }
  openBackEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ myPage: 'japanRegionalMeeting' };
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  onChangeVolunteersSelection($event:any){
   this.conference.Volunteers = $event.value;
  }

  cancelProcess(){
    this.getFormValue();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='210px'; //Desktop
    dialogConfig.maxWidth='370px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ conference: this.conference };
    const dialogRef = this.dialog.open(CancelOrSaveConfirmationComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
      //Retreive updated registration Info.
      //this.ngOnInit();
      let navigationExtras: NavigationExtras = {
        queryParams:this.conference
      };
      if(result!='cancel'){
        if(this.router.url.includes('/registration/ec/')){
          this.router.navigate(["/conference/EC/myAccount/" +this.getMyLanguage()], navigationExtras);
        }else{
          this.dialog.closeAll();
          this.openMyAccountEdit();
        }
        }
    })
  }

  openMyAccountEdit(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ userId: this.conference.UserId };
    const dialogRef = this.dialog.open(MyAccountEditComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }
  }
