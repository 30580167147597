<div class="myBody">
  <div class="myHeaderBase d-flex justify-content-center">
    <div class="myHeaderCore">
      <div>
        <img src='../../../../../assets/images/thumbnail_eclogo.png' class="ecLogo">
      </div>
    </div>
  </div>
  <div class="myMain">
    <div clas="myMainLeft">
      <div class="myMainHalfTop">
        <!-- Left Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Left Bottom -->
      </div>
    </div>
    <div class="myMainCenter">
      <div class="myMainHalfTop d-flex justify-content-center">
        <!-- Main Top -->
        <mat-card class="mainCard b-radius">
          <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
          <div *ngIf="!loaded">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <!-- <form *ngIf="userFromServer | async; else loading"  -->
          <form [formGroup]="myFormGroup" (ngSubmit)="onSubmit()">
            <div class="float-end stepNumber"></div>
            <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Terms of Participation':
              '参加規約'}}
              <i class="stepTitleSub">
                {{this.router.url.includes('EN')==true?'Required': '必須'}}
              </i>
            </h2>
            <div class="stepTitle">
              <label><b>
                  {{this.router.url.includes('EN')==true?
                  "Japanese Christian Fellowship Network General Release and Waiver of Rights":
                  'Equipper Conference参加同意、免責事項と権利放棄について'}}
                </b></label><br>
              <div>
                <div class="make-scrollable scrollBackGround" flex>
                  <div *ngIf="this.router.url.includes('EN')==true">
                    <b>GENERAL RELEASE AND WAIVER</b><br>
                    The undersigned has asked Japanese Christian Fellowship Network (hereinafter “JCFN”) which is the
                    sponsoring organization of Equipper Conference (hereinafter “EC”), to allow the individual named
                    here, sometimes referred to herein as the “participant,” to participate in the activities offered at
                    EC. Activities may include, but are not limited to, what Pine Valley Bible Conference Center
                    (hereinafter “PVBC”) offers. If the participant named here is a minor, the undersigned hereby
                    authorizes JCFN to allow said minor to participate in all activities offered by PVBC to participants
                    of the group's event. The undersigned (a) acknowledges that the activity involves physical exertion
                    and other risks; (b) is aware of the possibility of risk of injury to individuals participating in
                    or observing the activities; (c) is aware that such risk of injury includes, but is not limited to,
                    risk of permanent disability, blindness, loss of hearing, or death; (d) recognizes the need for each
                    participant to follow all rules and directions given by any JCFN Staff Member, EC Staff, leaders,
                    volunteers, and PBVC staff; (e) understands and acknowledges that other risks to which the
                    participant may be exposed include, but are not limited to, snake bite, insect bites and stings,
                    exposure to poisonous plants, as well as risks associated with traveling along mountain roads and
                    inclement weather.
                    The undersigned, individually and on behalf of any minor participant named above, hereby waives and
                    releases JCFN and its officers, directors, employees, volunteers, agents, and insurers, and each of
                    them, from any and all claims, demands, actions, causes of action and rights, (contingent, accrued,
                    inchoate, or otherwise), which may arise out of, or in connection with, the participant’s attendance
                    at the above-referenced event and/or any activity engaged in by the participant while attending the
                    event, whether such claim is based upon JCFN’s active or passive negligence or otherwise.
                    In addition, the undersigned agrees to defend and hold JCFN harmless from and against any and all
                    claims, liabilities, expenses, damages, losses, causes of action, and suits (including, without
                    limitation, attorneys’ fees and costs) arising out of, or any way related to, the participant’s
                    attendance at the above-referenced event and/or any activity engaged in by the participant while
                    attending the event, whether such claim is based upon JCFN’s active or passive negligence or
                    otherwise.
                    <br><br>
                    <b>CONFIDENTIALITY</b><br>
                    What is discussed in small group and counseling sessions is confidential information between the
                    parties. However, any child abuse, elder abuse, suicidal thoughts, homicidal thoughts, or criminal
                    acts mentioned during the session will be reported to a professional organization (professional
                    organization, guardian in the case of a minor, etc.) as deemed necessary, according to the judgment
                    of the JCFN staff, EC directors and counselors.
                    <br><br>
                    <b>IMAGE RELEASE AND WAIVER</b><br>
                    The undersigned hereby gives permission to JCFN to use any photographs, videos, and/or audio
                    recordings of the participant for promotional materials, including the JCFN web site postings,
                    without expectation of compensation, including, but not limited to, any royalties, proceeds, and/or
                    other benefits derived from such photographs, videos, or audio recordings.
                    <br><br>
                    <b>MEDICAL RELEASE AND WAIVER</b><br>
                    The undersigned hereby acknowledges that JCFN does not provide medical personnel and agrees that the
                    undersigned shall be responsible for (a) providing any emergency transportation required by the
                    participant named above, and (b) paying any medical and hospitalization costs incurred while
                    attending the event. In the unlikely event of a medical emergency, the JCFN director or a volunteer
                    will be delegated the medical measures to be taken.
                    <br><br>
                    <b>CONSENT TO COVID-19 Guidelines</b><br>
                    I (the participant or, if the participant is a minor, his/her parent or legal guardian) understand
                    and agree that JCFN will hold EC in accordance with the guidelines of the Centers for Disease
                    Control and Prevention (CDC) , the State of California, County of San Diego, and the venue at the
                    time of the EC. Furthermore, during the EC period, if I exhibit symptoms such as cold, flu, or
                    COVID-19, I agree to promptly notify the JCFN staff or the EC health personnel to prevent infection
                    to other participants. I also agree to comply promptly with any requests from the organizers, such
                    as undergoing PCR testing (including home antigen tests) and moving to an isolation room.
                    <br><br>
                    <b>REPRESENTATION AND WARRANTY OF AUTHORITY</b><br>
                    By signing this General Release and Waiver of Rights, the undersigned represents and warrants to
                    JCFN that he/she is of legal age and competence to give a valid release on his/her own behalf and,
                    if signing on behalf of a minor participant, is the parent or legal guardian of the minor
                    participant and has full authority to give a valid release on behalf of such minor. ALSO, THE
                    UNDERSIGNED HEREBY REPRESENTS AND WARRANTS TO JCFN THAT THE PARTICIPANT’S PARTICIPATION IN THIS
                    EVENT IS ENTIRELY VOLUNTARY AND IS NOT REQUIRED BY THE SCHOOL OR FOR ANY CLASS OR COURSE IN WHICH
                    THE ATTENDEE MAY BE INVOLVED, OR BY ANY OTHER GROUP SPONSORING THE CONFERENCE OR PROGRAM.
                  </div>
                  <div *ngIf="this.router.url.includes('EN')!=true">
                    本書は英文を正文とします。各項に併記する邦訳文は、本書の理解を助けるための参考であることをご理解ください。<br>
                    <b>一般的な同意書と権利放棄</b><br>
                    本書に署名している私（未成年の場合、親、または後見人）は、Equipper Conference（以下 EC）主催団体のジャパニーズ・クリスチャン・フェローシップ・ネットワーク（以下
                    JCFN）に対し、この参加申込者が EC で提供される活動に参加することを許可するよう要請します。ECの活動には、パインバレー・バイブル・コンファレンス・センター（以下
                    PVBC）が提供する自由参加のアクティビティーが含まれますが、これらに限定されるものではありません。上記の申込者が未成年の場合、親、または後見人は、JCFNがグループのイベントの参加者に提供するすべての活動に、当該未成年者を参加させることをここに許可します。)活動に参加または見学する個人が怪我をする可能性があることを認識しています。
                    そのような怪我のリスクには、永久障害、失明、聴覚喪失、死亡のリスクが含まれますが、これに限定されないことを認識しています。各参加者は、主事、実行委員、リーダー、ボランティア、PVBCスタッフが与える全ての規則と指示に従う必要性を認識します。
                    参加者がさらされる可能性のあるその他のリスクには、蛇にかまれる、虫に刺される、毒草にさらされる、山道や悪天候での移動に伴うリスクが含まれるが、これらに限定されないことを理解し承認します。
                    私は、個人として、また上記の未成年参加者の代理として、JCFNおよびその役員、取締役、従業員、ボランティア、代理人、保険会社、およびそれらの各々に対して、参加者のECの出席および／またはイベント出席中に参加者が行った活動に起因する、あらゆる請求、要求、訴訟、訴因、権利（偶発的、未収、不定、その他）を、その請求がJCFNの積極的または消極的過失に基づくかどうかにかかわらず放棄し解放するものとします。
                    さらに、私（未成年の場合、親、または後見人）は、参加者の上記イベントへの出席および/またはイベント出席中に参加者が行った活動に起因する、あるいは何らかの形で関連する、あらゆる請求、責任、経費、損害、損失、訴因および訴訟（弁護士費用および経費を含むがこれに限らない）から、かかる請求がJCFNの積極的または消極的過失その他に基づくかどうかに関わらず、JCFNを防御し、JCFNに損害を与えないことに同意するものとします。

                    <br><br>
                    <b>守秘義務</b><br>
                    スモールグループ、カウンセリング等で話した内容は、当事者同士の秘密の情報となります。ただしセッション中に言及される幼児虐待、老人虐待、自殺願望、殺人願望、犯罪行為については、JCFNスタッフ、カウンセラー等従事者の判断に従い、必要と思われる専門機関(専門機関、未成年の場合保護者等)に報告することに同意します。
                    <br><br>
                    <b>イメージリリースと権利放棄</b><br>
                    参加者が撮影した写真、ビデオ、音声をJCFNのウェブサイトを含む宣伝用資料として使用することを、ロイヤリティ、収益、その他の利益を含む（ただし必ずしもこれらに限定されない）報酬を期待することなくJCFNに許可します。
                    <br><br>
                    <b>メディカルリリースと権利放棄</b><br>
                    私（未成年の場合、親、または後見人）は、JCFNが医療従事者を派遣しないことを認め、（a）参加者が必要とする緊急輸送を提供し、（b）イベント参加中に発生した医療費および入院費を負担する責任を負うことに同意するものとします。また、万一の医療的な緊急事態には、JCFN主事、またはボランティアに取るべき医療措置を委ねます。
                    <br><br>
                    <b>コロナ対策への同意</b><br>
                    私（未成年の場合、親、または後見人）は、JCFNが、開催時における、疾病対策予防センター（Centers for Disease Control and Prevention:
                    CDC）のガイドライン、会場のあるカリフォルニア州、サンディエゴ郡、また会場のガイドラインに従ってECが開催されることを理解し、同意します。さらに、EC期間中に風邪やインフルエンザ、コロナなどの症状がみられる時に、他の参加者への感染を防ぐため、JCFN主事、またはEC保健係りに速やかに申し出ること、PCRテスト（家庭用抗原テストを含む）を受けること、隔離部屋に移動するなど、主催者側のリクエストに従い、対応に速やかに応じることに同意します。
                    <br><br>
                    <b>権限の表明と保証</b><br>
                    この一般的な権利放棄に署名することにより、私（未成年の場合、親、または法定後見人）は、自分自身を代表して有効な権利放棄を行う法定年齢および能力を有しており、未成年の参加者を代表して署名する場合は、その親または法定後見人であり、有効な権利放棄を行う完全な権限を有していることをJCFNに対して表明および保証します。
                  </div>
                </div>
              </div>
              <br>
              <label class="stepTitle2nd">
                {{this.router.url.includes('EN')==true?
                'I (the participant or, if the participant is a minor, the parent or legal guardian) am at least 18
                years of age and
                have read and understand the above, I have read and understand the "Equipper Conference Participation
                Agreement,
                Disclaimer and Waiver of Rights" above. I also agree to its contents. By checking the box below, I
                express my
                consent.':
                'ここをチェックしてください。 私（参加者または参加者が未成年の場合、親、または法定後見人）は、18歳以上であり、下記の「Equipper
                Conference参加同意、免責事項と権利放棄について」を読み理解しました。また、その内容に同意します。左のチェックボックスをチェックすることによって私の同意を表明します。'}}
              </label>
              <label class="stepTitle2nd" style="color:red;">
                {{this.router.url.includes('EN')==true?
                'IMPORTANT!!! Please read the "Equipper Conference Participation Agreement, Disclaimer and Waiver" and
                agree to it. No application will be accepted without consent. If the participant is under 18 years of
                age, the consent of a parent or legal guardian is required.'
                :
                '重要！「Equipper
                Conference参加同意、免責事項と権利放棄について」を一読の後、同意してください。同意なしでは申し込みを受け付けられません。参加者が18歳未満の場合は親・法定後見人の同意が必要となります。'}}
              </label>
              <div>
                <div class="participantConsent">
                  <mat-checkbox #checkboxConsent formControlName="myTermsOfParticipation"
                    (change)='onChangeConsent(checkboxConsent.checked)' class="ckb_SG"
                    [checked]="conference.TermsOfParticipation==true"> {{this.router.url.includes('EN')==true?'* I
                    Agree':'*
                    同意します'}}
                  </mat-checkbox>
                  <mat-form-field class="participantConsent">
                    <input matInput placeholder='Date' formControlName="myTermsOfParticipationDate"
                      [matDatepicker]="TermsOfParticipationDate" class="matInputBox" />
                    <mat-datepicker-toggle matSuffix [for]="TermsOfParticipationDate"></mat-datepicker-toggle>
                    <mat-datepicker #TermsOfParticipationDate></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <hr>
            <div>
              <div>
                <div class="stepTitle2nd">
                  <label class="boolAdult">
                    {{this.router.url.includes('EN')==true?
                    '* I am 18 or older':
                    '* 私は18歳以上です'}}<br>
                    {{this.router.url.includes('EN')==true?'Participant who is the age of 18 or older on
                    12/27/2024':
                    '参加者が2024/12/27時点で18歳以上の場合'}}
                  </label><br>
                  <div class="registrationInputFormLg">
                    <mat-radio-group formControlName="myYouth" (change)="onChangeYouth($event)" class="radioBtnMain">
                      <mat-radio-button class="radioBtnItem" value=false [checked]="conference.Youth==false">
                        {{this.router.url.includes('EN')==true?'Yes':
                        'はい'}}</mat-radio-button>
                      <mat-radio-button class="radioBtnItem" value=true [checked]="conference.Youth==true">
                        {{this.router.url.includes('EN')==true?'No':
                        'いいえ'}}</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
                <br><br>
                <div>
                  <div *ngIf="showYouthInfo==true" class="stepTitle">
                    <!-- <label><b>
                        {{this.router.url.includes('EN')==true?
                        'B) Pine Valley Bible Conference Center (PVBC) Participant Consent, Disclaimer, and Waiver':
                        'B) パインバレー・バイブル・コンファレンス・センター（PVBC)参加同意、免責事項と権利放棄について'}}
                      </b>
                    </label><br>
                    <label class="stepTitle2nd"><b>
                        {{this.router.url.includes('EN')==true?
                        'If you are signing the waiver for PVBC at the time of registration:':
                        'PVBCの権利放棄を申込時に手続きをする：'}}
                      </b>
                    </label>
                    <br>
                    <label class="stepTitle2nd">
                      {{this.router.url.includes('EN')==true?
                      'Please download and print the below Consent and Waiver form (English only), sign it, take a
                      photo
                      or scan,
                      and then send it to ecregistration@equipper.org.':
                      '下の免責事項・権利放棄のための書類（英語のみ）を(ダウンロード、印刷し)、署名をし後、写メ・またはスキャンをし、ecregistration@equipper.orgに送付してください。'}}
                    </label>
                    <br><br> -->
                    <!-- <div>
                      <label class="stepTitle2nd"><b>
                          {{this.router.url.includes('EN')==true?
                          'Downloadable Forms:':
                          'ダウンロードをする書類'}}
                        </b>
                      </label>
                      <br>
                      <a href="../../../../../assets/images/JCFN_Liab_Release-Minor-C-19.pdf" class="btn btnSchedule"
                        role="button" aria-pressed="true" target="_blank">
                        <i
                          class="fas fa-file-download  fa-1x"></i>&nbsp;&nbsp;{{this.router.url.includes('JP')==true?'未成年':'Minor'}}
                      </a>

                      <label class="stepTitle2nd">
                        {{this.router.url.includes('EN')==true?
                        "For parents or legal guardians of participants under 18 years old":
                        '18歳未満の参加者の親・法定後見人用'}}
                      </label><br><br>
                      <label class="stepTitle2nd" style="color:red;">
                        {{this.router.url.includes('EN')==true?
                        'IMPORTANT!!! Please read the "Equipper Conference Participation Agreement, Disclaimer and
                        Waiver"
                        and
                        agree to it. No application will be accepted without consent. If the participant is under 18
                        years
                        of
                        age, the consent of a parent or legal guardian is required.'
                        :
                        '重要！PVBC参加同意、免責事項と権利放棄の書類を一読の後、同意してください。同意なしでは申し込みを受け付けられません'}}
                      </label>
                      <div class="participantConsent">
                        <mat-checkbox #checkboxConsent formControlName="myTermsOfParticipationVenue"
                          (change)='onChangeConsent(checkboxConsent.checked)' class="ckb_SG"
                          [checked]="conference.TermsOfParticipationVenue==true">
                        </mat-checkbox>
                        <div class="ckb_SG">
                          {{this.router.url.includes('EN')==true?
                          '* I (or my parent/guardian, if I am a minor) will send the signed form to
                          ecregistration@equipper.org in advance.':
                          '*私（未成年の場合、親、または後見人）は、事前に署名した書類をecregistration@equipper.orgに送付します。'}}
                        </div>
                        <mat-form-field class="participantConsent">
                          <input matInput placeholder='Date' formControlName="myTermsOfParticipationDateVenue"
                            [matDatepicker]="TermsOfParticipationDateVenue" class="matInputBox" />
                          <mat-datepicker-toggle matSuffix [for]="TermsOfParticipationDateVenue"></mat-datepicker-toggle>
                          <mat-datepicker #TermsOfParticipationDateVenue></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div> -->
                    <!-- <div>
                      <label><b>
                          {{this.router.url.includes('EN')==true?
                          'About Medical Insurance:':
                          '医療保険について'}}
                        </b>
                      </label><br>
                      <label class="stepTitle2nd"><b>
                          {{this.router.url.includes('EN')==true?
                          "For participants under 18 years old, according to the venue's regulations and PVBC
                          requirements, we need to collect medical insurance information. Please fill in the following
                          details.":
                          '未成年参加者におきましては、会場、PVBCの規定により、医療保険の情報を収集する必要がありますので、以下にご記入下さい。'}}
                        </b>
                      </label>
                      <br>
                      <label class="stepTitle2nd">
                        {{this.router.url.includes('EN')==true?
                        'Please download and print the appropriate Consent and Waiver form (English only), sign it, take
                        a photo
                        or scan,
                        and then send it to ecregistration@equipper.org.':
                        '自分にあてはまる免責事項・権利放棄のための書類（英語のみ）を(ダウンロード、印刷し)、署名をし後、写メ・またはスキャンをし、ecregistration@equipper.orgに送付してください。'}}
                      </label>
                      <br>
                      <div class="participantConsent">
                        <mat-checkbox #checkboxConsent formControlName="myMinorHasCustodyInsurance"
                          (change)='onChangeConsentMinorHasCustodyInsurance(checkboxConsent.checked)' class="ckb_SG"
                          [checked]="conference.TermsOfParticipation==true">
                        </mat-checkbox>
                        <div class="ckb_SG">
                          {{this.router.url.includes('EN')==true?
                        '* The participant is under 18 years old, so I will provide medical insurance information
                        below.':
                        '*参加者が未成年なので、以下に医療保険の情報を記入します。'}}
                        </div>
                        <mat-form-field class="participantConsent">
                          <input matInput placeholder='Date' formControlName="myMinorHasCustodyInsuranceDate"
                            [matDatepicker]="MinorHasCustodyInsuranceDate" class="matInputBox" />
                          <mat-datepicker-toggle matSuffix [for]="MinorHasCustodyInsuranceDate"></mat-datepicker-toggle>
                          <mat-datepicker #MinorHasCustodyInsuranceDate></mat-datepicker>
                        </mat-form-field>
                      </div>

                      <div  class="stepTitle2nd">
                        <h5 class="stepTitle">{{this.router.url.includes('EN')==true?'Medical Insurance Information':
                          '医療保険の情報'}}
                          <i class="stepstrYouthTitleSub">
                            {{this.router.url.includes('EN')==true?'': ''}}
                          </i>
                        </h5>
                        <mat-form-field appearance="outline" class="guardianInputForm">
                          <mat-label>
                            {{this.router.url.includes('EN')==true?'Insurance Company': '保険会社名'}}
                          </mat-label>
                          <input matInput placeholder="" formControlName="myInsuranceCompany" class="registrationInputBox">
                          <mat-error>
                            {{this.router.url.includes('EN')==true?'Insurance Company is required': '未入力'}}
                          </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="guardianInputForm">
                          <mat-label>
                            {{this.router.url.includes('EN')==true?'Insurance Company Address': '保険会社 住所'}}
                          </mat-label>
                          <input matInput placeholder="" formControlName="myInsuranceAddress" class="registrationInputBox">
                          <mat-error>
                            {{this.router.url.includes('EN')==true?'Insurance Company Address is required': '未入力'}}
                          </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="guardianInputForm">
                          <mat-label>
                            {{this.router.url.includes('EN')==true?'Insurance Company TelNo': '保険会社 電話番号'}}
                          </mat-label>
                          <input matInput placeholder="" formControlName="myInsuranceTelNo"
                            class="registrationInputBox">
                          <mat-error>
                            {{this.router.url.includes('EN')==true?'Tel# is required': '未入力'}}
                          </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="guardianInputForm">
                          <mat-label>
                            {{this.router.url.includes('EN')==true?'Insurance Policy#': '保険契約番号'}}
                          </mat-label>
                          <input matInput placeholder="" formControlName="myInsurancePolicyNo" class="registrationInputBox">
                          <mat-error>
                            {{this.router.url.includes('EN')==true?'Insurance Policy# is required': '未入力'}}
                          </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="guardianInputForm">
                          <mat-label>
                            {{this.router.url.includes('EN')==true?'Insurance Group#': '保険グループ番号'}}
                          </mat-label>
                          <input matInput placeholder="" formControlName="myInsuranceGroupNo" class="registrationInputBox">
                          <mat-error>
                            {{this.router.url.includes('EN')==true?'Insurance Group# is required': '未入力'}}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div> -->
                    <label><b>
                        {{this.router.url.includes('EN')==true?"Participation Agreement for EC Youth Program":
                        'ECユースプログラムのための参加同意書'}}
                      </b></label><br>
                    <div>
                      <div class="make-scrollable scrollBackGround" flex>
                        <div *ngIf="this.router.url.includes('EN')==true">
                          1. Cell phones will be kept by staff at the registration desk and returned when campers return
                          home
                          at the end of the camp. If parents need to contact their child, please call the campsite at
                          760-742-3400 or the JCFN Director (Shimizu). (562-682-6880) Email: ushq@jcfn.orgです.
                          <br>
                          2. No games (PS Vista, 3DS, Switch, etc.) are allowed. If you do bring them, our staff will
                          take
                          care of them during the camp.
                          <br>
                          3. The following items are not allowed: Drugs, alcoholic beverages, cigarettes, e-cigarettes,
                          and
                          other items prohibited for underage possession or purchase. If you bring them, staff will keep
                          them,
                          but may not return them.
                          <br>
                          4. Be punctual. If you do not or cannot participate in a program, please promptly inform,
                          discuss,
                          and report the reason to your small group leader or staff.
                          <br>
                          5. Listen to your Pastor, JCFN Director, Youth Leaders, Small Group Leaders, and PCCC staff,
                          follow
                          the rules, and have a safe and enjoyable time.
                        </div>
                        <div *ngIf="this.router.url.includes('EN')!=true">
                          1.
                          携帯電話は、受付でスタッフが預かり、キャンプが終わる帰宅時に返却します。親御さんがお子さんに連絡する必要がある場合は、キャンプ場（760-742-3400）、あるいはJCFN主事（清水）までお電話ください。(後日お伝えします。）
                          Eメールは、ushq@jcfn.orgです。
                          <br>
                          2. ゲーム（PS Vista, 3DS, Switchなど）は持ち込み禁止です。もし持ってきた場合は、キャンプ中、スタッフの方で預からせていただきます。
                          <br>
                          3.
                          次のものは持ち込み禁止です。ドラッグ、酒類、タバコ、電子タバコ、その他未成年の所持や購入が禁止されているもの。もし、持ってきた場合はスタッフが預かりますが、返却しかねる可能性がありますのでご了承ください。
                          <br>
                          4. 時間を守りましょう。もし、プログラムに参加しない場合、参加できない場合など、スモールグループリーダーやスタッフに速やかに理由を伝え、相談、報告してください。
                          <br>
                          5. 牧師、JCFN主事、ユースリーダーやスモールグループリーダー、また会場スタッフの言うことを良く聞き、ルールを守り、安全に楽しく過ごしましょう。
                        </div>
                      </div>
                    </div>
                    <br>
                    <label class="stepTitle2nd">
                      {{this.router.url.includes('EN')==true?
                      'I, (Youth Program Participant), have read the following above. I have read and understand the
                      "Participation Agreement for EC Youth Program". I also agree to its contents. By checking the box
                      below, I express my consent.'
                      :
                      '私（ユースプログラム参加者）は、上記の「ECユースプログラムのための参加同意書」を読み理解しました。また、その内容に同意します。下のチェックボックスをチェックすることによって私の同意を表明します。'}}
                    </label>
                    <label class="stepTitle2nd" style="color:darkblue;">
                      {{this.router.url.includes('EN')==true?
                      'IMPORTANT! Please read the "Participation Agreement for EC Youth Program" carefully before
                      agreeing
                      to it. Without consent, your application will not be accepted. If the participant is under 18
                      years
                      of
                      age, the consent of a parent and his/her legal guardian is required.'
                      :
                      '重要！「ECユースプログラムのための参加同意書」を一読の後、同意してください。同意なしでは申し込みを受け付けられません。参加者が18歳未満の場合は本人と、親・法定後見人の同意が必要となります。'}}
                    </label>
                    <div>
                      <div class="participantConsent">
                        <mat-checkbox #checkboxConsentYouth formControlName="myTermsOfParticipationYouth"
                          (change)='onChangeConsentYouth(checkboxConsentYouth.checked)' class="ckb_SG"
                          [checked]="conference.TermsOfParticipationYouth==true">
                          {{this.router.url.includes('EN')==true?'* I Agree':'* 同意します.'}}
                        </mat-checkbox>
                        <mat-form-field class="participantConsent">
                          <input matInput placeholder='Date' formControlName="myTermsOfParticipationDateYouth"
                            [matDatepicker]="TermsOfParticipationDateYouth" class="matInputBox" />
                          <mat-datepicker-toggle matSuffix
                            [for]="TermsOfParticipationDateYouth"></mat-datepicker-toggle>
                          <mat-datepicker #TermsOfParticipationDateYouth></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                    <br>
                    <label class="stepTitle2nd">
                      {{this.router.url.includes('EN')==true?
                      'I, (the parent or legal guardian of the above named participant), have read and understand the
                      above I
                      have read and understand the "Participation Agreement for EC Youth Program". I also agree to its
                      contents. I express my consent by checking the box below.'
                      :
                      '私（上記参加者の親、または法定後見人）は、上記の「ECユースプログラムのための参加同意書」を読み理解しました。また、その内容に同意します。下のチェックボックスをチェックすることによって私の同意を表明します。'}}
                    </label>
                    <label class="stepTitle2nd" style="color:darkblue;">
                      {{this.router.url.includes('EN')==true?
                      'IMPORTANT! Please read the "Participation Agreement for EC Youth Program" carefully before
                      agreeing
                      to it. Without consent, your application will not be accepted. If the participant is under 18
                      years
                      of
                      age, the consent of a parent and his/her legal guardian is required.'
                      :
                      '重要！「ECユースプログラムのための参加同意書」を一読の後、同意してください。同意なしでは申し込みを受け付けられません。参加者が18歳未満の場合は本人と、親・法定後見人の同意が必要となります。'}}
                    </label>
                    <div>
                      <div class="participantConsent">
                        <mat-checkbox #checkboxConsentYouthParent formControlName="myTermsOfParticipationYouthParent"
                          (change)='onChangeConsentYouthParent(checkboxConsentYouthParent.checked)' class="ckb_SG"
                          [checked]="conference.TermsOfParticipationYouthParent==true">
                          {{this.router.url.includes('EN')==true?'* I Agree':'*
                          同意します'}}
                        </mat-checkbox>
                        <mat-form-field class="participantConsent">
                          <input matInput placeholder='Date' formControlName="myTermsOfParticipationDateYouthParent"
                            [matDatepicker]="TermsOfParticipationDateYouthParent" class="matInputBox" />
                          <mat-datepicker-toggle matSuffix [for]="TermsOfParticipationDateYouthParent">
                          </mat-datepicker-toggle>
                          <mat-datepicker #TermsOfParticipationDateYouthParent></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <br>
                  <div *ngIf="showGuardianInfo" class="guardianInfo">
                    <label><b>{{this.router.url.includes('EN')==true?'Guardian Contact':
                      '保護者連絡先'}}
                      <i class="stepstrYouthTitleSub">
                        {{this.router.url.includes('EN')==true?'Required': '必須'}}
                      </i></b>
                    </label><br>
                    <div  class="stepTitle2nd">
                    <mat-form-field appearance="outline" class="guardianInputForm">
                      <mat-label>
                        {{this.router.url.includes('EN')==true?'* Guardian First Name': '*名　ローマ字'}}
                      </mat-label>
                      <input matInput placeholder="" formControlName="myGuardianFirstName" class="registrationInputBox">
                      <mat-error>
                        {{this.router.url.includes('EN')==true?'Guardian First Name is required': '未入力'}}
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="guardianInputForm">
                      <mat-label>
                        {{this.router.url.includes('EN')==true?'* Guardian Last Name': '*姓　ローマ字'}}
                      </mat-label>
                      <input matInput placeholder="" formControlName="myGuardianLastName" class="registrationInputBox">
                      <mat-error>
                        {{this.router.url.includes('EN')==true?'Guardian Last Name is required': '未入力'}}
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="guardianInputForm">
                      <mat-label>
                        {{this.router.url.includes('EN')==true?'* Guardian Email': '* Eメール'}}
                      </mat-label>
                      <input matInput placeholder="pat@example.com" formControlName="myGuardianEmail"
                        class="registrationInputBox">
                      <mat-error>
                        {{this.router.url.includes('EN')==true?'Guardian Email is required': '未入力'}}
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="guardianInputForm">
                      <mat-label>
                        {{this.router.url.includes('EN')==true?'*Guardian Tel#*': '* 電話番号'}}
                      </mat-label>
                      <input matInput placeholder="" formControlName="myGuardianTelNo" class="registrationInputBox">
                      <mat-error>
                        {{this.router.url.includes('EN')==true?'Guardian Tel# is required': '未入力'}}
                      </mat-error>
                    </mat-form-field>
                    </div>

                  </div>
                </div>


                  <!-- <div>
                    <label><b>
                        {{this.router.url.includes('EN')==true?
                        'B) Pine Valley Bible Conference Center (PVBC) Participant Consent, Disclaimer, and Waiver':
                        'B) パインバレー・バイブル・コンファレンス・センター（PVBC)参加同意、免責事項と権利放棄について'}}
                      </b>
                    </label><br><br>
                    <label class="stepTitle2nd"><b>
                        {{this.router.url.includes('EN')==true?
                        'Important! On the day of the event, you must agree to the PVBC Participation Agreement,
                        Disclaimer and Waiver at the registration desk at the event site. You cannot participate without
                        consent. ':
                        '重要！参加当日、会場の受付でPVBC参加同意、免責事項と権利放棄の書類をに同意してください。同意なしでは参加できません。'}}
                      </b>
                    </label><br>
                    <label class="stepTitle2nd"><b>
                        {{this.router.url.includes('EN')==true?
                        'For your reference, the PVBC Participation Agreement, Disclaimer and Waiver forms can be
                        downloaded from the links below.':
                        '参考までにPVBC参加同意、免責事項と権利放棄の書類は下記リンクからダウンロードすることが出来ます。'}}
                      </b>
                    </label>
                    <br><br>
                    <a href="../../../../../assets/images/JCFN_Liab_Release-Adult-C-19.pdf" class="btn btnSchedule"
                      role="button" aria-pressed="true" target="_blank">
                      <i
                        class="fas fa-file-download  fa-1x"></i>&nbsp;&nbsp;{{this.router.url.includes('JP')==true?'大人':'Adult'}}
                    </a>
                    <label class="stepTitle2nd">
                      {{this.router.url.includes('EN')==true?
                      "For participants 18 years old and above":
                      '18歳以上の参加者用'}}
                    </label><br><br>
                    <a href="../../../../../assets/images/JCFN_Liab_Release-Family-C-19.pdf" class="btn btnSchedule"
                      role="button" aria-pressed="true" target="_blank">
                      <i
                        class="fas fa-file-download  fa-1x"></i>&nbsp;&nbsp;{{this.router.url.includes('JP')==true?'家族':'Family'}}
                    </a>
                    <label class="stepTitle2nd">
                      {{this.router.url.includes('EN')==true?
                      "For families (accompanied by underage children other than a couple's children; separate forms
                      required for each spouse's signature)":
                      '家族連れ用（夫婦以外の未成年の子ども連れ。夫婦の場合は別々の書類の署名が必要です）'}}
                    </label>
                    <br>
                   <label class="stepTitle2nd"><b>
                        {{this.router.url.includes('EN')==true?
                        'About Medical Insurance:':
                        '医療保険について'}}
                      </b>
                    </label><br>
                    <label class="stepTitle2nd"><b>
                        {{this.router.url.includes('EN')==true?
                        'Please bring your medical insurance certificate. Especially for participants coming from
                        overseas, please make sure to have travel insurance that covers your stay abroad':
                        '可能な方は、医療保険証書をご持参ください。特に、海外からの参加者は、海外旅行者保険に加入してきてください。'}}
                      </b>
                    </label>
                    <br>
                  </div> -->

<!-- Insurance Information -->
  <div>
    <label><b>
      {{this.router.url.includes('EN')==true?'Medical Insurance Information':
      '医療保険の情報'}}
      <i class="stepTitleSub">
        {{this.router.url.includes('EN')==true?'Required': '必須'}}
      </i>
      </b>
    </label>
    <br>
    <div class="stepTitle2nd">
      <div class="registrationInputFormLg">
        <mat-radio-group formControlName="myInsurance" (change)="onChangeInsuranceOption($event)" class="radioBtnMain">
          <mat-radio-button class="radioBtnItem" value="HasInsurance">
            {{this.router.url.includes('EN')==true?'I have health insurance.':
            '健康保険を保有しています'}}</mat-radio-button><br>
            <mat-radio-button class="radioBtnItem" value="NoInsurance">
              {{this.router.url.includes('EN')==true?'I do not have health insurance.':
              '健康保険を保有していません'}}</mat-radio-button>

            <mat-radio-button class="radioBtnItem"  value="WillBuyInsurance">
              <div class="text-wrap">
  {{this.router.url.includes('EN')==true?'I will attend EC from Japan, purchase travel insurance, and bring my insurance card with me to EC.':
              '日本からの参加なので、海外旅行保険を購入し、保険証書を持参します。'}}
              </div>

          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div  *ngIf="showInsuranceInfo" class="stepTitle2nd">
      <mat-form-field appearance="outline" class="guardianInputForm">
        <mat-label>
          {{this.router.url.includes('EN')==true?'Insurance Company': '保険会社名'}}
        </mat-label>
        <input matInput placeholder="" formControlName="myInsuranceCompany" class="registrationInputBox">
        <mat-error>
          {{this.router.url.includes('EN')==true?'Insurance Company is required': '未入力'}}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="guardianInputForm">
        <mat-label>
          {{this.router.url.includes('EN')==true?'Insurance Company Address': '保険会社 住所'}}
        </mat-label>
        <input matInput placeholder="" formControlName="myInsuranceAddress" class="registrationInputBox">
        <mat-error>
          {{this.router.url.includes('EN')==true?'Insurance Company Address is required': '未入力'}}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="guardianInputForm">
        <mat-label>
          {{this.router.url.includes('EN')==true?'Insurance Company TelNo': '保険会社 電話番号'}}
        </mat-label>
        <input matInput placeholder="" formControlName="myInsuranceTelNo"
          class="registrationInputBox">
        <mat-error>
          {{this.router.url.includes('EN')==true?'Tel# is required': '未入力'}}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="guardianInputForm">
        <mat-label>
          {{this.router.url.includes('EN')==true?'Insurance Policy#': '保険契約番号'}}
        </mat-label>
        <input matInput placeholder="" formControlName="myInsurancePolicyNo" class="registrationInputBox">
        <mat-error>
          {{this.router.url.includes('EN')==true?'Insurance Policy# is required': '未入力'}}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="guardianInputForm">
        <mat-label>
          {{this.router.url.includes('EN')==true?'Insurance Group#': '保険グループ番号'}}
        </mat-label>
        <input matInput placeholder="" formControlName="myInsuranceGroupNo" class="registrationInputBox">
        <mat-error>
          {{this.router.url.includes('EN')==true?'Insurance Group# is required': '未入力'}}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <br><br>


  <div *ngIf="showEmergencyInfo" class="emergencyInfo">
    <label><b>{{this.router.url.includes('EN')==true?'Emergency Contact':
        '緊急連絡先'}}
        <i class="stepTitleSub">
          {{this.router.url.includes('EN')==true?'Required': '必須'}}
        </i>
        </b>
      </label>
      <br>
      <div  class="stepTitle2nd" >
        <mat-form-field appearance="outline" class="emergencyContactInputForm">
          <mat-label>
            {{this.router.url.includes('EN')==true?'*First Name': '* 名　ローマ字'}}
          </mat-label>
          <input matInput placeholder="" formControlName="myEmergencyContactFirstName"
            class="registrationInputBox">
          <mat-error>
            {{this.router.url.includes('EN')==true?'First Name is required': '未入力'}}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="emergencyContactInputForm">
          <mat-label>
            {{this.router.url.includes('EN')==true?'*Last Name': '* 姓　ローマ字'}}
          </mat-label>
          <input matInput placeholder="" formControlName="myEmergencyContactLastName"
            class="registrationInputBox">
          <mat-error>
            {{this.router.url.includes('EN')==true?'Last Name is required': '未入力'}}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="emergencyContactInputForm">
          <mat-label>
            {{this.router.url.includes('EN')==true?'*Email': '* Eメール'}}
          </mat-label>
          <input matInput placeholder="pat@example.com" formControlName="myEmergencyContactEmail"
            class="registrationInputBox">
          <mat-error>
            {{this.router.url.includes('EN')==true?'Email is required': '未入力'}}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="emergencyContactInputForm">
          <mat-label>
            {{this.router.url.includes('EN')==true?'*Tel#*': '電話番号*'}}
          </mat-label>
          <input matInput placeholder="" formControlName="myEmergencyContactTelNo"
            class="registrationInputBox">
          <mat-error>
            {{this.router.url.includes('EN')==true?'Tel# is required': '未入力'}}
          </mat-error>
        </mat-form-field>
      </div>

  </div>





                <br>
                <div *ngIf="!loaded">
                  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
              </div>
              <br>
            </div>
            <div class="d-flex justify-content-center">
              <div class="processButtonSet">
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn btnProcess" (click)="onClickGoBack()">
                    {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                  </button>
                  <button [disabled]="!myFormGroup.valid" type="button" class="btn btnProcess"
                    (click)="saveOrMoveForward()">
                    {{this.router.url.includes('EN')==true?'Submit': '送信'}}
                  </button>
                </div>
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn link btnCancel" (click)="cancelProcess()">
                    {{this.router.url.includes('EN')==true?'Cancel': 'キャンセル'}}
                  </button>
                </div>
              </div>
            </div>
            <!-- <div class=" position-absolute bottom-0 start-50 translate-middle-x">
              <div class="d-flex justify-content-center">
                <button type="button" class="btn btnProcess" (click)="onClickGoBack()">
                  {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                </button>
                <button  [disabled]="!myFormGroup.valid" type="button" class="btn btnProcess"
                (click)="saveOrMoveForward()">
                {{this.router.url.includes('EN')==true?'Submit': '送信'}}
                </button>
              </div>
              <div class="d-flex justify-content-center">
                <button type="button" class="btn link btnCancel" (click)="cancelProcess()">
                  {{this.router.url.includes('EN')==true?'Cancel': 'キャンセル'}}
                </button>
              </div>
            </div> -->
          </form>
        </mat-card>
      </div>
      <div class="myMainHalfBottom">
        <!-- Main Bottom -->
      </div>
    </div>
    <div class="myMainRight">
      <div class="myMainHalfTop">
        <!-- Right Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Right Bottom -->
      </div>
    </div>
  </div>
  <!-- <footer>footer</footer> -->
</div>
