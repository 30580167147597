<div class="myBase">
  <div class="bg-layer">
    <div class="btnBack">
      <button type="button" class="btn btn-link" (click)="onClickClose()">
        <i class="fas fa-times-circle fa-2x"></i>&nbsp;&nbsp; {{this.router.url.includes('EN')==true?'Close': '閉じる'}}
      </button>
    </div>
    <app-basic></app-basic>
  </div>
 </div>
 <div class="memoForDevelopper  d-flex justify-content-center">
  registration/ec22/basic in /dialog/EC/registration-personal-embed
 </div>
