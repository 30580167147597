import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { AppConfig } from "../../../app.config";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { User } from "../../../models/user";
import { GRCConference } from "../../../models/conference/GRCConference";
import { UserConference } from "../../../models/conference/userConference";
import { AuthService } from '../../auth/auth.service';
import { NewUser } from 'src/app/models/mailchimp/newUser';
import { UserConferenceTemplate } from 'src/app/models/conference/userConferenceTemplate';
import { UserConferenceGridTemplate } from 'src/app/models/conference/userConferenceGridTemplate';
import { SubProfile } from 'src/app/models/conference/subProfile';
import { UserConferenceSubProfileGRC } from 'src/app/models/conference/userConferenceSubProfileGRC';

@Injectable({
  providedIn: 'root'
})
export class GrcRegistrationService {

  constructor(
    private http: HttpClient,
    private config: AppConfig,
    private authService: AuthService) { }

    getConferenceByUserId(userId: String): Observable<GRCConference> {
      return this.http.get<GRCConference>(
        this.config.apiMyUrl + "/api/Conference/GRC/GRCConference/getConferenceByUserId/" + userId,
        { headers: this.authService.getJWT() }
      );
    }

    getSubProfileByUserId(userId: String): Observable<SubProfile> {
      return this.http.get<SubProfile>(
        this.config.apiMyUrl + "/api/Conference/GRC/GRCConference/getSubProfileByUserId/" + userId,
        { headers: this.authService.getJWT() }
      );
    }

    upsertSubProfile(model: SubProfile) {
      return this.http.post(
        this.config.apiMyUrl + "/api/Conference/GRC/GRCConference/upsertSubProfile",
        model,
        { headers: this.authService.getJWT() }
      );
    }

    upsertConference(conference: GRCConference) {
      return this.http.post(
        this.config.apiMyUrl + "/api/Conference/GRC/GRCConference/upsertConference",
        conference,
        { headers: this.authService.getJWT() }
      );
    }

    approveVolunteers(conference: GRCConference) {
      return this.http.post(
        this.config.apiMyUrl + "/api/Conference/GRC/GRCConference/approveVolunteers",
        conference,
        { headers: this.authService.getJWT() }
      );
    }

    getTest(conference: GRCConference) {
      console.log('In service test');
      return this.http.get(
        this.config.apiMyUrl + "/api/Conference/GRC/GRCConference/getTest",
        { headers: this.authService.getJWT() }
      );
    }

    checkLoginUserAccessGRC(): boolean {
       return sessionStorage.getItem("loginUserAccessGRC") === 'true';
     }

     GetUserConferenceBySearchKey(conference: GRCConference): Observable<UserConferenceSubProfileGRC[]> {
      return this.http.post<UserConferenceSubProfileGRC[]>(
        this.config.apiMyUrl + "/api/Conference/GRC/GRCConference/GetUserConferenceBySearchKey",
        conference,
        { headers: this.authService.getJWT() }
      );
    }

}
