import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { UserService} from "../../../../services/user/user.service";
import { Router, ActivatedRoute,NavigationExtras } from "@angular/router";
import { ECConference } from 'src/app/models/conference/ECConference';
import { User } from 'src/app/models/user';
import { CancelOrSaveConfirmationComponent } from 'src/app/components/dialog/cancel-or-save-confirmation/cancel-or-save-confirmation.component';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { EcRegistrationService } from 'src/app/services/conference/ec/ec-registration.service';
import { RelatedPerson} from "../../../../models/conference/RelatedPerson";
import { VolunteerSelection} from "../../../../models/conference/volunteerSelection";
import { RegistrationConferenceEmbedComponent } from 'src/app/components/dialog/EC/registration-conference-embed/registration-conference-embed.component';
import { MyAccountEditComponent } from 'src/app/components/dialog/EC/my-account-edit/my-account-edit.component';

@Component({
  selector: 'app-program-type',
  templateUrl: './program-type.component.html',
  styleUrls: ['./program-type.component.css']
})
export class ProgramTypeComponent implements OnInit {
  loaded:boolean=true;
  myFormGroup:FormGroup;
  myMessage:string;
  strOnline:string;
  editMode: boolean;
  panelOpenState: boolean = false;
  panelOpenAdult :boolean= false;
  panelOpenRJC:boolean=false;
  panelOpenSeekers:boolean=false;
  panelOpenYouth:boolean=false;

  user: User = {
    PasswordHash:null,
    Id:null,
    UserName:null,
    Email:null,
    FirstName:null,
    LastName:null,
    Password:null,
    Prefix:null,
    Shimei:null,
    Myoji:null,
    Street:null,
    Street2:null,
    City:null,
    State:null,
    Zip:null,
    Country:null,
    Yubin_Bango:null,
    To_Do_Fu_Ken:null,
    Shi_Gun_Ku:null,
    Cho_Son:null,
    Apartment_Etc:null,
    To_Do_Fu_Ken_JP:null,
    Shi_Gun_Ku_JP:null,
    Cho_Son_JP:null,
    Apartment_Etc_JP:null,
    Gender:null,
    DOB:null,
    TelNo:null,
    TimezoneName:null,
    UTCdiff:null,
    Token:null,
    Occupation:null,
    MemberStatus:null,
    Married:null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount:null,
    RegisteredDate:null,
    LastAccessedDate:null,
    //From siteground
    LoginId:null,
    MiddleNameEN:null,
    MiddleNameJP:null,
    PrefixJP:null,
    OccupationDetail:null,
    Organization:null,
    LivingCountry:null,
    PhoneEN:null,
    PhoneMobileEN:null,
    PhoneJP:null,
    PhoneMobileJP:null,
    ChurchNameEN:null,
    ChurchNameJP:null,
    MemberType:null,
    MemberDetail:null,
    MemberMemo:null,
    Shokaisha:null,
    Reason:null,
    NewsLetters:null,
    StaffReports:null,
    OtherLetters:null,
    NewsLetterLanguage:null,
    BasicRoles:null,

    //Added new
    MigrateFromSiteGround:null,
    AdminAccess:null,
    JCFNAccess:null,
    SupportingMember:null,
    LastAddressUpdatedDate:null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };
  relatedPerson:RelatedPerson={
    Id:null,
    RelatedId:null,
    Email:null,
    FirstNameRelated:null,
    LastNameRelated:null,
    MyojiRelated:null,
    ShimeiRelated:null,
    DOBRelated:null,
    GenderRelated:null
  };
  relatedPersons:RelatedPerson[]=[];
  volunteerSelections:VolunteerSelection[]=[];
  conference: ECConference= {
    ECConferenceId:0,
    UserId: null,
    Email: null,
    UserName: null,
    FirstName: null,
    LastName: null,
    //Program
    ProgramType: null,
    SmallGroupLanguage: null,
    AttendJapanRegionalMeeting:null,
    JapanRegionalMeeting:null,
    ApplyEnglishPartner:null,
    //Payment
    ConferenceFee: null,
    ConferenceDiscountAmount: null,
    ConferenceFeePaidAmount: null,
    ConferenceFeePaidDate: null,
    CouponApproved: null,
    ApplyScholarship: null,
    ApplyingScholarshipAmount: null,
    ApprovedScholarshipAmount: null,
    ApplyingReason: null,
    //Others
    Volunteers:null,
    Student:null,
    ApplyCounseling: null,
    MySleepingBag:null,
    BringExtraSleepingBag:null,
    CountExtraSleepingBag:null,
    NeedCommingBus:null,
    CommingBusDeparturePlace:null,
    CommingBusDepartureDateTime:null,
    CommingAirline:null,
    CommingFlightNo:null,
    DepartureCity:null,
    CommingInternationalDomestic:null,
    ArrivalDateTime:null,
    NeedReturningBus:null,
    ReturningBusDeparturePlace:null,
    ReturningBusDepartureDateTime:null,
    ReturningAirline:null,
    ReturningFlightNo:null,
    ReturningCity:null,
    ReturningInternationalDomestic:null,
    DepartureDateTime:null,
    RoomInfo:null,
    Couple:null,
    Youth:null,
    YouthGrade: null,
    GuardianFirstName: null,
    GuardianLastName: null,
    GuardianMyoji: null,
    GuardianShimei: null,
    GuardianEmail: null,
    GuardianTelNo: null,
    TermsOfParticipation: null,
    TermsOfParticipationParent: null,
    TermsOfParticipationDate: null,
    TermsOfParticipationDateParent: null,
    RegisteredDate: null,
    UpdatedDate: null,

    AdminAccess: null,
    ECCommitteeAccess: null,
    Memo: null,
    Dec26:null,
    Dec27:null,
    Dec28:null,
    Dec29:null,
    Dec30:null,
    Dec31:null,
    KidGroup1:null,
    KidGroup2:null,
    KidGroup3:null,
    KidGroup4:null,
    myRelatedPersons: this.relatedPersons,
    myVolunteerSelections:this.volunteerSelections,
    BusComingConfirmation:null,
    BusReturningConfirmation:null,
    TermsOfParticipationVenue:null,
    TermsOfParticipationDateVenue:null,
    TermsOfParticipationYouth:null,
    TermsOfParticipationDateYouth:null,
    TermsOfParticipationYouthParent:null,
    TermsOfParticipationDateYouthParent:null,
    EmergencyContactFirstName:null,
    EmergencyContactLastName:null,
    EmergencyContactEmail:null,
    EmergencyContactTelNo:null,
    ApplicationFee:null,
    ApplicationFeePaidDate:null,
    BalanceAmount:null,
    BalanceAmountPaidDate:null,
    HasAllergy:null,
    AllergyDetail:null,
    BringInsurance:null,
    MinorHasCustodyInsurance:null,
    MinorHasCustodyInsuranceDate:null,
    InsuranceCompany:null,
    InsuranceAddress:null,
    InsuranceTelNo:null,
    InsurancePolicyNo:null,
    InsuranceGroupNo:null,
    VolunteerAgreeConductCode:null,
    VolunteerAgreeConductCodeDate:null,
    PayingAmount:null,
     //New
    BecomeMentor:null,
    ApplyMentoring:null,
    ReturningDepartureCity:null,
    InsuranceStatus:null,
    DressCodeAgreeYouth:null,
    DressCodeDateYouth:null,
    DressCodeAgreeParent:null,
    DressCodeDateParent:null
}

  constructor(
    public router: Router,
    private _formBuilder: FormBuilder,
    public ecRegistrationService : EcRegistrationService,
    private dialog: MatDialog,
  ) {
  }
  admin:boolean = true;
  mySearch: any = {};
  ngOnInit() {
    this.loaded=false;
    this.myFormGroup = this._formBuilder.group({
      myProgramType: [null, Validators.required],
    });

    /*#####################################################################
    # Admin Work search for registrant
    ######################################################################*/
    if (sessionStorage.getItem("myECSearchingUserId") != null) {
      console.log("Take their Id");

      var searchingRegistrantId = sessionStorage.getItem("myECSearchingUserId") ;
      /*****************************************
       *  A) User info for searching registrant
       ****************************************/
      this.ecRegistrationService.getConferenceByUserId(searchingRegistrantId).subscribe(result => {
      this.conference = result;


      this.patchModelValue(this.conference);
      this.loaded = true;
      });
      this.loaded = true;
    } else {
      /******************************
       *  B) User Info for login user
       ******************************/
      console.log("Take my username");
      var loginUserName = sessionStorage.getItem("loginUserName");
      console.log('login username: '+ loginUserName)
      if (loginUserName != null) {
        this.conference.Email = loginUserName;
        console.log('I am edit mode');
        this.editMode = true;
      }

      this.mySearch = JSON.parse(sessionStorage.getItem("myConferenceInfo"));
      if (this.mySearch != null) {
        this.conference = this.mySearch.myConferenceSearch;
        this.patchModelValue(this.conference);
        this.loaded = true;
      }else{
        this.loaded = true;
      }
    }
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  patchModelValue(model:ECConference){
    if(model!=null){
      this.myFormGroup.patchValue({
        myProgramType: model.ProgramType,
      });
    }
  }

  getFormValue() {
    this.conference.ProgramType = this.myFormGroup.get('myProgramType').value;
  }

  onChangeProgramSelection($event:any){
  }

  onSubmit() {
  }

  onClickGoForward(){
    this.getFormValue();
    if(this.router.url.includes('/registration/ec/')){
      sessionStorage.removeItem("myWorkingOnConference");
      sessionStorage.setItem(
        "myWorkingOnConference",
        JSON.stringify({ myEditingConference: this.conference })
      );
      this.router.navigate(["/registration/ec/smallGroupLanguage/" +this.getMyLanguage()]);
    }else{
      sessionStorage.removeItem("myWorkingOnConferenceAdmin");
      sessionStorage.setItem(
        "myWorkingOnConferenceAdmin",
        JSON.stringify({ myEditingConference: this.conference })
      );

      this.dialog.closeAll();
      this.openForwardEmbed();
    }
  }
  openForwardEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktoop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ myPage: 'smallGroupLanguage' };
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  onClickGoBack(){
    if(this.router.url.includes('/registration/ec/')){
      this.router.navigate(["/conference/EC/myAccount/" +this.getMyLanguage()]);
    }else{
      // Adminによる調べ中のユーザーのMy Accountに戻る前に作業中のセッションは消しておく
      sessionStorage.removeItem("myWorkingOnConferenceAdmin");
      this.dialog.closeAll();
      this.openBackEmbed();
    }
  }

  openBackEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ userId: this.conference.UserId };
    const dialogRef = this.dialog.open(MyAccountEditComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  cancelProcess(){
    this.getFormValue();
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = false;
  dialogConfig.maxHeight='210px'; //Desktop
  dialogConfig.maxWidth='370px';//Desktop
  dialogConfig.height='100%';//Mobile
  dialogConfig.width='100%';//Mobile
  dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
  dialogConfig.data={ conference: this.conference };
  const dialogRef = this.dialog.open(CancelOrSaveConfirmationComponent,dialogConfig);
  dialogRef.afterClosed().subscribe(result => {
    var confirmedAction = result;
    if (result != null) {
      console.log("Confirmed Action: " + confirmedAction);
    }
    //Retreive updated registration Info.
    //this.ngOnInit();
    let navigationExtras: NavigationExtras = {
      queryParams:this.conference
    };
    if(result!='cancel'){
      if(this.router.url.includes('/registration/ec/')){
        this.router.navigate(["/conference/EC/myAccount/" +this.getMyLanguage()], navigationExtras);
      }else{
        this.dialog.closeAll();
        this.openMyAccountEdit();
      }
      }
  })
}

openMyAccountEdit(){
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = false;
  dialogConfig.maxHeight='800px'; //Desktop
  dialogConfig.maxWidth='1200px';//Desktop
  dialogConfig.height='100%';//Mobile
  dialogConfig.width='100%';//Mobile
  dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
  dialogConfig.data={ userId: this.conference.UserId };
  const dialogRef = this.dialog.open(MyAccountEditComponent,dialogConfig);
  dialogRef.afterClosed().subscribe(result => {
    var confirmedAction = result;
    if (result != null) {
      console.log("Confirmed Action: " + confirmedAction);
    }
  })
}
}
