import { Routes, RouterModule } from "@angular/router";

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AuthGuard } from "../app/guards/auth.guard";

//copy from app.module.ts
import { HomeComponent } from './components/home/home.component';
import { TopComponent } from './components/top/top.component';
//General
import { EmailSentMessageComponent } from './components/general/email-sent-message/email-sent-message.component';
import { EnterNewPasswordComponent } from      './components/general/enter-new-password/enter-new-password.component';
import { EnterNewPasswordChildComponent } from './components/dialog/enter-new-password-child/enter-new-password-child.component';
import { NotFoundComponent } from './components/general/not-found/not-found.component';
import { ResetPasswordComponent } from './components/general/reset-password/reset-password.component';
import { TokenCheckComponent } from './components/general/token-check/token-check.component';
//Dialog
import { DeleteConfirmationComponent } from './components/dialog/delete-confirmation/delete-confirmation.component';
import { LoginMessageComponent } from './components/dialog/login-message/login-message.component';

//admin
import { MembersListComponent } from './components/admin/members-list/members-list.component';
import { MembersListCustomComponent } from './components/admin/members-list-custom/members-list-custom.component';

//give
import { GiveComponent } from './components/give/give.component';

//registration
import { PersonalComponent } from './components/registration/personal/personal.component';
import { ProcessedSuccessfullyComponent } from './components/registration/processed-successfully/processed-successfully.component';
import { ProcessfailedComponent } from './components/registration/processfailed/processfailed.component';

//members
import { MyProfileComponent } from './components/members/my-profile/my-profile.component';
import { SupportingTopComponent } from './components/members/supporting-top/supporting-top.component';
import { SelectMemberTypeComponent } from './components/members/select-member-type/select-member-type.component'
import { RegularTopComponent } from './components/members/regular-top/regular-top.component';
import { AcceptTermsComponent } from './components/members/accept-terms/accept-terms.component';
import { SelectNewsLetterLanguageComponent } from './components/members/select-news-letter-language/select-news-letter-language.component';

//payment
import { SupportingMembershipComponent } from './components/payment/supporting-membership/supporting-membership.component';
import { SuccessfullyProcessedComponent } from './components/payment/successfully-processed/successfully-processed.component';
import { ProcessFailedComponent } from './components/payment/process-failed/process-failed.component';
import { PaymentListComponent } from './components/admin/payment-list/payment-list.component';

//dashboard
import { MemberJapanComponent } from './components/dashboard/member-japan/member-japan.component';
import { MemberUSAComponent } from './components/dashboard/member-usa/member-usa.component';

//EC
import { MyAccountComponent } from './components/conference/EC/my-account/my-account.component';
import { ProgramTypeComponent } from './components/registration/ec/program-type/program-type.component';
import { SmallGroupLanguageComponent } from './components/registration/ec/small-group-language/small-group-language.component';
import { JapanRegionalMeetingComponent } from './components/registration/ec/japan-regional-meeting/japan-regional-meeting.component';
import { OverseasRegionalMeetingComponent } from './components/registration/ec/overseas-regional-meeting/overseas-regional-meeting.component';
import { EnglishPartnersComponent } from './components/registration/ec/english-partners/english-partners.component';
import { CounselingComponent } from './components/registration/ec/counseling/counseling.component';
import { StudentComponent } from './components/registration/ec/student/student.component';
import { SleepingBagComponent } from './components/registration/ec/sleeping-bag/sleeping-bag.component';
import { FlightInfoComponent } from './components/registration/ec/flight-info/flight-info.component';
import { RoomInfoComponent } from './components/registration/ec/room-info/room-info.component';
import { VolunteersComponent } from './components/registration/ec/volunteers/volunteers.component';
import { TransportationComponent } from './components/registration/ec/transportation/transportation.component';
import { RegistrationConfirmationComponent } from './components/registration/ec/registration-confirmation/registration-confirmation.component';
import { ChristianInfoComponent } from './components/registration/basic/christian-info/christian-info.component';
import { AddressComponent } from './components/registration/basic/address/address.component';
import { BasicInfoComponent } from './components/registration/basic/basic-info/basic-info.component';
import { TermsOfParticipationComponent } from './components/registration/ec/terms-of-participation/terms-of-participation.component';
import { BasicComponent } from './components/registration/ec/basic/basic.component';
import { AddressInfoComponent } from './components/registration/ec/address-info/address-info.component';
import { BasicSubComponent } from './components/registration/ec/basic-sub/basic-sub.component';
import { FaithInfoComponent } from './components/registration/ec/faith-info/faith-info.component';
import { SummaryPersonComponent } from './components/registration/ec/summary-person/summary-person.component';
import { SummaryConferenceComponent } from './components/registration/ec/summary-conference/summary-conference.component';
import { LoginDialogEcComponent } from './components/dialog/login-dialog-ec/login-dialog-ec.component';
import { LoginSimpleEcComponent } from './components/dialog/login-simple-ec/login-simple-ec.component';
import { ScholarshipComponent } from './components/registration/ec/scholarship/scholarship.component';
import { LodgingScheduleComponent } from './components/registration/ec/lodging-schedule/lodging-schedule.component';
import { SuccessfullyProcessedECComponent } from './components/payment/successfully-processed-ec/successfully-processed-ec.component';
import { ProcessFailedECComponent } from './components/payment/process-failed-ec/process-failed-ec.component';
import { EcParticipantComponent } from './components/admin/EC/ec-participant/ec-participant.component';
import { EcParticipantCustomComponent} from './components/admin/EC/ec-participant-custom/ec-participant-custom.component';
import { ConferenceFeeDetailComponent } from './components/registration/ec/conference-fee-detail/conference-fee-detail.component';
import { DressCodeComponent } from './components/registration/ec/dress-code/dress-code.component';

//Template
import { TemplateComponent } from './components/general/template/template.component';
import { TemplateGridComponent } from './components/general/template-grid/template-grid.component';
import { TemplateMedicalComponent } from './components/general/template-medical/template-medical.component';
import { TemplateMedical2Component } from './components/general/template-medical2/template-medical2.component';

//GRC
import { LoginGRCComponent } from './components/dialog/GRC/login-grc/login-grc.component';
import { BasicGRCComponent } from './components/registration/GRC/basic-grc/basic-grc.component';
import { BasicSubGRCComponent } from './components/registration/GRC/basic-sub-grc/basic-sub-grc.component';
import { AddressInfoGRCComponent } from './components/registration/GRC/address-info-grc/address-info-grc.component';
import { FaithInfoGRCComponent } from './components/registration/GRC/faith-info-grc/faith-info-grc.component';
import { SummaryPersonGRCComponent } from './components/registration/GRC/summary-person-grc/summary-person-grc.component';
//import { BasicGRC2Component } from './components/registration/GRC/basic-grc2/basic-grc2.component';
import { MyAccountGRCComponent } from './components/registration/GRC/my-account-grc/my-account-grc.component';
import { SmallGroupLanguageGRCComponent } from './components/registration/GRC/small-group-language-grc/small-group-language-grc.component';
import { VolunteersGRCComponent } from './components/registration/GRC/volunteers-grc/volunteers-grc.component';
import { CoupleGRCComponent } from './components/registration/GRC/couple-grc/couple-grc.component';
import { TransportationGRCComponent } from './components/registration/GRC/transportation-grc/transportation-grc.component';
import { RoomInfoGRCComponent } from './components/registration/GRC/room-info-grc/room-info-grc.component';
import { LodgingScheduleGRCComponent } from './components/registration/GRC/lodging-schedule-grc/lodging-schedule-grc.component';
import { SummaryConferenceGRCComponent } from './components/registration/GRC/summary-conference-grc/summary-conference-grc.component';
import { TermsOfParticipationGRCComponent } from './components/registration/GRC/terms-of-participation-grc/terms-of-participation-grc.component';
import { ScholarshipGRCComponent } from './components/registration/GRC/scholarship-grc/scholarship-grc.component';
import { GrcParticipantComponent } from './components/admin/GRC/grc-participant/grc-participant.component';

const routes: Routes = [
  //General
  { path: "", component: HomeComponent },
  { path: ":MyLanguage", component: HomeComponent },
  { path: "!", component: HomeComponent   },
  { path: "!/:MyLanguage", component: HomeComponent },
  { path: "home", component: HomeComponent   },
  { path: "home/:MyLanguage", component: HomeComponent },
  { path: "top", component: TopComponent   },
  { path: "top/:MyLanguage", component: TopComponent },
  { path: "email-sent-message", component: EmailSentMessageComponent },
  { path: "email-sent-message/:MyLanguage", component: EmailSentMessageComponent },
  { path: "enter-new-password", component: EnterNewPasswordComponent },
  { path: "enter-new-password/:token", component: EnterNewPasswordComponent },
  { path: "enter-new-password-child", component: EnterNewPasswordChildComponent },
  { path: "enter-new-password-child/:token", component: EnterNewPasswordChildComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  { path: "reset-password/:MyLanguage", component: ResetPasswordComponent },
  { path: "tokenCheck", component: TokenCheckComponent },
  { path: "tokenCheck/:MyLanguage", component: TokenCheckComponent },
  //admin
  { path: "admin/members-list", component: MembersListComponent,canActivate: [AuthGuard]},
  { path: "admin/members-list/:MyLanguage", component: MembersListComponent ,canActivate: [AuthGuard]},
  { path: "admin/members-list-custom", component: MembersListCustomComponent,canActivate: [AuthGuard]},
  { path: "admin/members-list-custom/:MyLanguage", component: MembersListCustomComponent,canActivate: [AuthGuard]},
  { path: "admin/payment-list", component: PaymentListComponent,canActivate: [AuthGuard]},
  { path: "admin/payment-list/:MyLanguage", component: PaymentListComponent,canActivate: [AuthGuard] },
  // { path: "admin/EC/participant", component: ParticipantComponent},
  // { path: "admin/EC/participant/:MyLanguage", component: ParticipantComponent },
  { path: "admin/EC/ECparticipant", component: EcParticipantComponent,canActivate: [AuthGuard]},
  { path: "admin/EC/ECparticipant/:MyLanguage", component: EcParticipantComponent ,canActivate: [AuthGuard]},
  { path: "admin/EC/ECparticipantCustom", component: EcParticipantCustomComponent,canActivate: [AuthGuard]},
  { path: "admin/EC/ECparticipantCustom/:MyLanguage", component: EcParticipantCustomComponent,canActivate: [AuthGuard] },
  //give
  { path: "give", component: GiveComponent},
  { path: "give/:MyLanguage", component: GiveComponent },
  { path: "give/:MyPaymentNonce/:MyLanguage", component: GiveComponent },
   //registration
   { path: "registration/personal", component: PersonalComponent},
   { path: "registration/personal/:MyLanguage", component: PersonalComponent },
   { path: "registration/processedSuccessfullyComponenty", component: ProcessedSuccessfullyComponent},
   { path: "registration/processedSuccessfullyComponenty/:MyLanguage", component: ProcessedSuccessfullyComponent},
   { path: "registration/processfailedComponent", component: ProcessfailedComponent},
   { path: "registration/processfailedComponent/:MyLanguage", component: ProcessfailedComponent},

  //members
  { path: "members/myProfile", component: MyProfileComponent, canActivate: [AuthGuard] },
  { path: "members/myProfile/:MyLanguage", component: MyProfileComponent, canActivate: [AuthGuard]  },
  { path: "members/supportingTop", component: SupportingTopComponent,canActivate: [AuthGuard]},
  { path: "members/supportingTop/:MyLanguage", component: SupportingTopComponent ,canActivate: [AuthGuard]},
  { path: "members/selectMemberType", component: SelectMemberTypeComponent,canActivate: [AuthGuard]},
  { path: "members/selectMemberType/:MyLanguage", component: SelectMemberTypeComponent,canActivate: [AuthGuard]},
  { path: "members/regularTop", component: RegularTopComponent,canActivate: [AuthGuard]},
  { path: "members/regularTop/:MyLanguage", component: RegularTopComponent,canActivate: [AuthGuard] },
  { path: "members/acceptTerms", component: AcceptTermsComponent,canActivate: [AuthGuard]},
  { path: "members/acceptTerms/:MyLanguage", component: AcceptTermsComponent ,canActivate: [AuthGuard]},
  { path: "members/selectNewsLetterLanguage", component: SelectNewsLetterLanguageComponent,canActivate: [AuthGuard]},
  { path: "members/selectNewsLetterLanguage/:MyLanguage", component: SelectNewsLetterLanguageComponent,canActivate: [AuthGuard] },

   //payment
   { path: "payment/supportingMembership", component: SupportingMembershipComponent,canActivate: [AuthGuard]},
   { path: "payment/supportingMembership/:MyLanguage", component: SupportingMembershipComponent,canActivate: [AuthGuard]},
   { path: "payment/successfullyProcessedComponent", component: SuccessfullyProcessedComponent ,canActivate: [AuthGuard]},
   { path: "payment/successfullyProcessedComponent/:MyLanguage", component: SuccessfullyProcessedComponent ,canActivate: [AuthGuard]},
   { path: "payment/processFailedComponent", component: ProcessFailedComponent,canActivate: [AuthGuard]},
   { path: "payment/processFailedComponent/:MyLanguage", component: ProcessFailedComponent,canActivate: [AuthGuard]},

    //dashboard
    { path: "dashboard/members-Japan", component: MemberJapanComponent},
    { path: "dashboard/members-Japan/:MyLanguage", component: MemberJapanComponent },
    { path: "dashboard/members-USA", component: MemberUSAComponent},
    { path: "dashboard/members-USA/:MyLanguage", component: MemberUSAComponent },

    //EC
    { path: "conference/EC/myAccount", component: MyAccountComponent ,canActivate: [AuthGuard]},
    { path: "conference/EC/myAccount/:MyLanguage", component: MyAccountComponent ,canActivate: [AuthGuard]},
    // { path: "conference/registration/ec/programType", component: ProgramTypeComponent},
    // { path: "conference/registration/ec/programType/:MyLanguage", component: ProgramTypeComponent },
    { path: "registration/ec/programType", component: ProgramTypeComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/programType/:MyLanguage", component: ProgramTypeComponent ,canActivate: [AuthGuard]},
    { path: "registration/ec/smallGroupLanguage", component: SmallGroupLanguageComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/smallGroupLanguage/:MyLanguage", component: SmallGroupLanguageComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/japanRegionalMeeting", component: JapanRegionalMeetingComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/japanRegionalMeeting/:MyLanguage", component: JapanRegionalMeetingComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/overseasRegionalMeeting", component: OverseasRegionalMeetingComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/overseasRegionalMeeting/:MyLanguage", component: OverseasRegionalMeetingComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/englishPartners", component: EnglishPartnersComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/englishPartners/:MyLanguage", component: EnglishPartnersComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/counseling", component: CounselingComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/counseling/:MyLanguage", component: CounselingComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/student", component:StudentComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/student/:MyLanguage", component: StudentComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/sleepingBag", component: SleepingBagComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/sleepingBag/:MyLanguage", component: SleepingBagComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/flightInfo", component: FlightInfoComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/flightInfo/:MyLanguage", component: FlightInfoComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/roomInfo", component: RoomInfoComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/roomInfo/:MyLanguage", component: RoomInfoComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/volunteers", component: VolunteersComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/volunteers/:MyLanguage", component: VolunteersComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/transportation", component: TransportationComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/transportation/:MyLanguage", component: TransportationComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/registrationConfirmation", component: RegistrationConfirmationComponent},
    { path: "registration/ec/registrationConfirmation/:MyLanguage", component: RegistrationConfirmationComponent},
    { path: "registration/basic/basicInfo", component: BasicInfoComponent},
    { path: "registration/basic/basicInfo/:MyLanguage", component: BasicInfoComponent,canActivate: [AuthGuard]},
    { path: "registration/basic/christianInfo", component: ChristianInfoComponent,canActivate: [AuthGuard]},
    { path: "registration/basic/christianInfo/:MyLanguage", component: ChristianInfoComponent,canActivate: [AuthGuard]},
    { path: "registration/basic/address", component: AddressComponent,canActivate: [AuthGuard]},
    { path: "registration/basic/address/:MyLanguage", component: AddressComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/termsOfParticipation", component: TermsOfParticipationComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/termsOfParticipation/:MyLanguage", component: TermsOfParticipationComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/basic", component: BasicComponent},
    { path: "registration/ec/basic/:MyLanguage", component: BasicComponent},
    { path: "registration/ec/basicSub", component: BasicSubComponent},
    { path: "registration/ec/basicSub/:MyLanguage", component: BasicSubComponent},
    { path: "registration/ec/addressInfo", component: AddressInfoComponent},
    { path: "registration/ec/addressInfo/:MyLanguage", component: AddressInfoComponent},
    { path: "registration/ec/faithInfo", component: FaithInfoComponent},
    { path: "registration/ec/faithInfo/:MyLanguage", component: FaithInfoComponent},
    { path: "registration/ec/summaryPerson", component: SummaryPersonComponent},
    { path: "registration/ec/summaryPerson/:MyLanguage", component: SummaryPersonComponent},
    { path: "registration/ec/summaryConference", component: SummaryConferenceComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/summaryConference/:MyLanguage", component: SummaryConferenceComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/loginDialogEC", component: LoginDialogEcComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/loginDialogEC/:MyLanguage", component: LoginDialogEcComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/loginSimpleEC", component: LoginSimpleEcComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/loginSimpleEC/:MyLanguage", component: LoginSimpleEcComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/scholarship", component: ScholarshipComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/scholarship/:MyLanguage", component: ScholarshipComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/lodgingSchedule", component: LodgingScheduleComponent,canActivate: [AuthGuard] },
    { path: "registration/ec/lodgingSchedule/:MyLanguage", component: LodgingScheduleComponent,canActivate: [AuthGuard]},
    { path: "payment/successfullyProcessedEC", component: SuccessfullyProcessedECComponent,canActivate: [AuthGuard]},
    { path: "payment/successfullyProcessedEC/:MyLanguage", component: SuccessfullyProcessedECComponent,canActivate: [AuthGuard]},
    { path: "payment/processFailedEC", component: ProcessFailedECComponent,canActivate: [AuthGuard]},
    { path: "payment/processFailedEC/:MyLanguage", component: ProcessFailedECComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/conferenceFeeDetail", component: ConferenceFeeDetailComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/conferenceFeeDetail/:MyLanguage", component: ConferenceFeeDetailComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/dressCode", component: DressCodeComponent,canActivate: [AuthGuard]},
    { path: "registration/ec/dressCode/:MyLanguage", component: DressCodeComponent,canActivate: [AuthGuard]},

    //Template
    {path:"template", component: TemplateComponent},
    {path:"template/:MyLanguage", component: TemplateComponent},
    {path:"template-grid", component: TemplateGridComponent},
    {path:"template-grid/:MyLanguage", component: TemplateGridComponent},
    {path:"template-medical", component: TemplateMedicalComponent},
    {path:"template-medical/:MyLanguage", component: TemplateMedicalComponent},
    {path:"template-medical2", component: TemplateMedical2Component},
    {path:"template-medical2/:MyLanguage", component: TemplateMedical2Component},


  //GRC
  { path: "registration/GRC/loginSimpleEC", component: LoginSimpleEcComponent},
  { path: "registration/GRC/loginSimpleEC/:MyLanguage", component: LoginSimpleEcComponent},
  { path: "registration/GRC/basicGRC", component: BasicGRCComponent},
  { path: "registration/GRC/basicGRC/:MyLanguage", component: BasicGRCComponent},
  { path: "registration/GRC/basicSubGRC", component: BasicSubGRCComponent},
  { path: "registration/GRC/basicSubGRC/:MyLanguage", component: BasicSubGRCComponent},
  { path: "registration/GRC/addressInfoGRC", component: AddressInfoGRCComponent},
  { path: "registration/GRC/addressInfoGRC/:MyLanguage", component: AddressInfoGRCComponent},
  { path: "registration/GRC/faithInfoGRC", component: FaithInfoGRCComponent},
  { path: "registration/GRC/faithInfoGRC/:MyLanguage", component: FaithInfoGRCComponent},
  { path: "registration/GRC/summaryPersonGRC", component: SummaryPersonGRCComponent},
  { path: "registration/GRC/summaryPersonGRC/:MyLanguage", component: SummaryPersonGRCComponent},
  { path: "registration/GRC/smallGroupLanguageGRC", component: SmallGroupLanguageGRCComponent,canActivate: [AuthGuard]},
  { path: "registration/GRC/smallGroupLanguageGRC/:MyLanguage", component: SmallGroupLanguageGRCComponent,canActivate: [AuthGuard]},
  { path: "registration/GRC/volunteersGRC", component: VolunteersGRCComponent,canActivate: [AuthGuard]},
  { path: "registration/GRC/volunteersGRC/:MyLanguage", component: VolunteersGRCComponent,canActivate: [AuthGuard]},
  { path: "registration/GRC/coupleGRC", component: CoupleGRCComponent,canActivate: [AuthGuard]},
  { path: "registration/GRC/coupleGRC/:MyLanguage", component: CoupleGRCComponent,canActivate: [AuthGuard]},
  { path: "registration/GRC/transportationGRC", component: TransportationGRCComponent,canActivate: [AuthGuard]},
  { path: "registration/GRC/transportationGRC/:MyLanguage", component: TransportationGRCComponent,canActivate: [AuthGuard]},
  { path: "registration/GRC/roomInfoGRC", component: RoomInfoGRCComponent,canActivate: [AuthGuard]},
  { path: "registration/GRC/roomInfoGRC/:MyLanguage", component: RoomInfoGRCComponent,canActivate: [AuthGuard]},
  { path: "registration/GRC/lodgingScheduleGRC", component: LodgingScheduleGRCComponent,canActivate: [AuthGuard]},
  { path: "registration/GRC/lodgingScheduleGRC/:MyLanguage", component: LodgingScheduleGRCComponent},
  { path: "registration/GRC/summaryConferenceGRC", component: SummaryConferenceGRCComponent,canActivate: [AuthGuard]},
  { path: "registration/GRC/summaryConferenceGRC/:MyLanguage", component: SummaryConferenceGRCComponent,canActivate: [AuthGuard]},
  { path: "registration/GRC/termsOfParticipationGRC", component: TermsOfParticipationGRCComponent,canActivate: [AuthGuard]},
  { path: "registration/GRC/termsOfParticipationGRC/:MyLanguage", component: TermsOfParticipationGRCComponent,canActivate: [AuthGuard]},
  { path: "registration/GRC/scholarshipGRC", component: ScholarshipGRCComponent,canActivate: [AuthGuard]},
  { path: "registration/GRC/scholarshipGRC/:MyLanguage", component: ScholarshipGRCComponent,canActivate: [AuthGuard]},
  { path: "registration/GRC/myAccountGRC", component: MyAccountGRCComponent,canActivate: [AuthGuard] },
  { path: "registration/GRC/myAccountGRC/:MyLanguage", component: MyAccountGRCComponent,canActivate: [AuthGuard] },
  { path: "admin/GRC/GRC-participant", component: GrcParticipantComponent ,canActivate: [AuthGuard]},
  { path: "admin/GRC/GRC-participant/:MyLanguage", component: GrcParticipantComponent,canActivate: [AuthGuard]},

  { path: "**", component: NotFoundComponent },
  ];

@NgModule({
  exports: [RouterModule],
  imports: [
    // RouterModule.forRoot(routes)
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})
  ],
  providers: [AuthGuard]
})
export class myRoutes {}
