import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { UserService} from "../../../../services/user/user.service";
import { Router, ActivatedRoute,NavigationExtras } from "@angular/router";
import { MatRadioChange } from '@angular/material/radio';
import { EC22Conference } from 'src/app/models/conference/EC22Conference';
import { User } from 'src/app/models/user';
import { CancelOrSaveConfirmationPersonalComponent } from 'src/app/components/dialog/cancel-or-save-confirmation-personal/cancel-or-save-confirmation-personal.component';
//For Dialog
import { MatDialog,  MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import { LoginMessageComponent } from '../../../dialog/login-message/login-message.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { EcRegistrationService } from 'src/app/services/conference/ec/ec-registration.service';
import { UserEditOthersProfileComponent  } from '../../../dialog/user-edit-others-profile/user-edit-others-profile.component';
import { RequestAddressUpdateComponent} from '../../../dialog/request-address-update/request-address-update.component';
import { AuthService } from "../../../../services/auth/auth.service";
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { RelatedPerson} from "../../../../models/conference/RelatedPerson";
import { VolunteerSelection} from "../../../../models/conference/volunteerSelection";
import { AddressInfoEmbedComponent } from 'src/app/components/dialog/EC/address-info-embed/address-info-embed.component';
//import { FaithInfoEmbedComponent } from 'src/app/components/dialog/EC/faith-info-embed/faith-info-embed.component';
//import { BasicSubEmbedComponent } from 'src/app/components/dialog/EC/basic-sub-embed/basic-sub-embed.component';
import { MyAccountEditComponent } from 'src/app/components/dialog/EC/my-account-edit/my-account-edit.component';
import { RegistrationConferenceEmbedGRCComponent } from 'src/app/components/dialog/GRC/registration-conference-embed-grc/registration-conference-embed-grc.component';
import { ECConference } from 'src/app/models/conference/ECConference';
interface To_Do_Fu_KenEN {
  display: string;
  value: string;
}

interface To_Do_Fu_KenJP {
  display: string;
  value: string;
}

interface USState {
  display: string;
  value: string;
}

interface MyList {
  display: string;
  value: string;
}
@Component({
  selector: 'app-address-info-grc',
  templateUrl: './address-info-grc.component.html',
  styleUrls: ['./address-info-grc.component.css']
})
export class AddressInfoGRCComponent implements OnInit {
  loaded: boolean = true;
  myFormGroup: FormGroup;
  myMessage: string;
  mySelectedLanguage: string;
  strBoolean: string;
  editMode: boolean;
  showUSAddress: boolean = true;
  showJapanAddress: boolean = true;
  showOtherAddress: boolean = true;

  states: USState[] =
    [
      {
        "display": "Alabama",
        "value": "AL"
      },
      {
        "display": "Alaska",
        "value": "AK"
      },
      {
        "display": "American Samoa",
        "value": "AS"
      },
      {
        "display": "Arizona",
        "value": "AZ"
      },
      {
        "display": "Arkansas",
        "value": "AR"
      },
      {
        "display": "California",
        "value": "CA"
      },
      {
        "display": "Colorado",
        "value": "CO"
      },
      {
        "display": "Connecticut",
        "value": "CT"
      },
      {
        "display": "Delaware",
        "value": "DE"
      },
      {
        "display": "District Of Columbia",
        "value": "DC"
      },
      {
        "display": "Federated States Of Micronesia",
        "value": "FM"
      },
      {
        "display": "Florida",
        "value": "FL"
      },
      {
        "display": "Georgia",
        "value": "GA"
      },
      {
        "display": "Guam",
        "value": "GU"
      },
      {
        "display": "Hawaii",
        "value": "HI"
      },
      {
        "display": "Idaho",
        "value": "ID"
      },
      {
        "display": "Illinois",
        "value": "IL"
      },
      {
        "display": "Indiana",
        "value": "IN"
      },
      {
        "display": "Iowa",
        "value": "IA"
      },
      {
        "display": "Kansas",
        "value": "KS"
      },
      {
        "display": "Kentucky",
        "value": "KY"
      },
      {
        "display": "Louisiana",
        "value": "LA"
      },
      {
        "display": "Maine",
        "value": "ME"
      },
      {
        "display": "Marshall Islands",
        "value": "MH"
      },
      {
        "display": "Maryland",
        "value": "MD"
      },
      {
        "display": "Massachusetts",
        "value": "MA"
      },
      {
        "display": "Michigan",
        "value": "MI"
      },
      {
        "display": "Minnesota",
        "value": "MN"
      },
      {
        "display": "Mississippi",
        "value": "MS"
      },
      {
        "display": "Missouri",
        "value": "MO"
      },
      {
        "display": "Montana",
        "value": "MT"
      },
      {
        "display": "Nebraska",
        "value": "NE"
      },
      {
        "display": "Nevada",
        "value": "NV"
      },
      {
        "display": "New Hampshire",
        "value": "NH"
      },
      {
        "display": "New Jersey",
        "value": "NJ"
      },
      {
        "display": "New Mexico",
        "value": "NM"
      },
      {
        "display": "New York",
        "value": "NY"
      },
      {
        "display": "North Carolina",
        "value": "NC"
      },
      {
        "display": "North Dakota",
        "value": "ND"
      },
      {
        "display": "Northern Mariana Islands",
        "value": "MP"
      },
      {
        "display": "Ohio",
        "value": "OH"
      },
      {
        "display": "Oklahoma",
        "value": "OK"
      },
      {
        "display": "Oregon",
        "value": "OR"
      },
      {
        "display": "Palau",
        "value": "PW"
      },
      {
        "display": "Pennsylvania",
        "value": "PA"
      },
      {
        "display": "Puerto Rico",
        "value": "PR"
      },
      {
        "display": "Rhode Island",
        "value": "RI"
      },
      {
        "display": "South Carolina",
        "value": "SC"
      },
      {
        "display": "South Dakota",
        "value": "SD"
      },
      {
        "display": "Tennessee",
        "value": "TN"
      },
      {
        "display": "Texas",
        "value": "TX"
      },
      {
        "display": "Utah",
        "value": "UT"
      },
      {
        "display": "Vermont",
        "value": "VT"
      },
      {
        "display": "Virgin Islands",
        "value": "VI"
      },
      {
        "display": "Virginia",
        "value": "VA"
      },
      {
        "display": "Washington",
        "value": "WA"
      },
      {
        "display": "West Virginia",
        "value": "WV"
      },
      {
        "display": "Wisconsin",
        "value": "WI"
      },
      {
        "display": "Wyoming",
        "value": "WY"
      }
    ]
  to_do_fu_kensJP: To_Do_Fu_KenJP[] =
    [
      {
        "display": "北海道",
        "value": "Hokkaido:北海道"
      },
      {
        "display": "青森県",
        "value": "Aomori:青森県"
      },
      {
        "display": "岩手県",
        "value": "Iwate:岩手県"
      },
      {
        "display": "宮城県",
        "value": "Miyagi:宮城県"
      },
      {
        "display": "秋田県",
        "value": "Akita:秋田県"
      },
      {
        "display": "山形県",
        "value": "Yamagata:山形県"
      },
      {
        "display": "福島県",
        "value": "Fukushima:福島県"
      },
      {
        "display": "茨城県",
        "value": "Ibaraki:茨城県"
      },
      {
        "display": "栃木県",
        "value": "Tochigi:栃木県"
      },
      {
        "display": "群馬県",
        "value": "Gunma:群馬県"
      },
      {
        "display": "埼玉県",
        "value": "Saitama:埼玉県"
      },
      {
        "display": "千葉県",
        "value": "Chiba:千葉県"
      },
      {
        "display": "東京都",
        "value": "Tokyo:東京都"
      },
      {
        "display": "神奈川県",
        "value": "Kanagawa:神奈川県"
      },
      {
        "display": "新潟県",
        "value": "Niigata:新潟県"
      },
      {
        "display": "富山県",
        "value": "Toyama:富山県"
      },
      {
        "display": "石川県",
        "value": "Ishikawa:石川県"
      },
      {
        "display": "福井県",
        "value": "Fukui:福井県"
      },
      {
        "display": "山梨県",
        "value": "Yamanashi:山梨県"
      },
      {
        "display": "長野県",
        "value": "Nagano:長野県"
      },
      {
        "display": "岐阜県",
        "value": "Gifu:岐阜県"
      },
      {
        "display": "静岡県",
        "value": "Shizuoka:静岡県"
      },
      {
        "display": "愛知県",
        "value": "Aichi:愛知県"
      },
      {
        "display": "三重県",
        "value": "Mie:三重県"
      },
      {
        "display": "滋賀県",
        "value": "Shiga:滋賀県"
      },
      {
        "display": "京都府",
        "value": "Kyoto:京都府"
      },
      {
        "display": "大阪府",
        "value": "Osaka:大阪府"
      },
      {
        "display": "兵庫県",
        "value": "Hyogo:兵庫県"
      },
      {
        "display": "奈良県",
        "value": "Nara:奈良県"
      },
      {
        "display": "和歌山県",
        "value": "Wakayama:和歌山県"
      },
      {
        "display": "鳥取県",
        "value": "Tottori:鳥取県"
      },
      {
        "display": "島根県",
        "value": "Shimane:島根県"
      },
      {
        "display": "岡山県",
        "value": "Okayama:岡山県"
      },
      {
        "display": "広島県",
        "value": "Hiroshima:広島県"
      },
      {
        "display": "山口県",
        "value": "Yamaguchi:山口県"
      },
      {
        "display": "徳島県",
        "value": "Tokushima:徳島県"
      },
      {
        "display": "香川県",
        "value": "Kagawa:香川県"
      },
      {
        "display": "愛媛県",
        "value": "Ehime:愛媛県"
      },
      {
        "display": "高知県",
        "value": "Kochi:高知県"
      },
      {
        "display": "福岡県",
        "value": "Fukuoka:福岡県"
      },
      {
        "display": "佐賀県",
        "value": "Saga:佐賀県"
      },
      {
        "display": "長崎県",
        "value": "Nagasaki:長崎県"
      },
      {
        "display": "熊本県",
        "value": "Kumamoto:熊本県"
      },
      {
        "display": "大分県",
        "value": "Oita:大分県"
      },
      {
        "display": "宮崎県",
        "value": "Miyazaki:宮崎県"
      },
      {
        "display": "鹿児島県",
        "value": "Kagoshima:鹿児島県"
      },
      {
        "display": "沖縄県",
        "value": "Okinawa:沖縄県"
      }
    ]
  to_do_fu_kensEN: To_Do_Fu_KenEN[] =
    [
      {
        "display": "Hokkaido",
        "value": "Hokkaido:北海道"
      },
      {
        "display": "Aomori",
        "value": "Aomori:青森県"
      },
      {
        "display": "Iwate",
        "value": "Iwate:岩手県"
      },
      {
        "display": "Miyagi",
        "value": "Miyagi:宮城県"
      },
      {
        "display": "Akita",
        "value": "Akita:秋田県"
      },
      {
        "display": "Yamagata",
        "value": "Yamagata:山形県"
      },
      {
        "display": "Fukushima",
        "value": "Fukushima:福島県"
      },
      {
        "display": "Ibaraki",
        "value": "Ibaraki:茨城県"
      },
      {
        "display": "Tochigi",
        "value": "Tochigi:栃木県"
      },
      {
        "display": "Gunma",
        "value": "Gunma:群馬県"
      },
      {
        "display": "Saitama",
        "value": "Saitama:埼玉県"
      },
      {
        "display": "Chiba",
        "value": "Chiba:千葉県"
      },
      {
        "display": "Tokyo",
        "value": "Tokyo:東京都"
      },
      {
        "display": "Kanagawa",
        "value": "Kanagawa:神奈川県"
      },
      {
        "display": "Niigata",
        "value": "Niigata:新潟県"
      },
      {
        "display": "Toyama",
        "value": "Toyama:富山県"
      },
      {
        "display": "Ishikawa",
        "value": "Ishikawa:石川県"
      },
      {
        "display": "Fukui",
        "value": "Fukui:福井県"
      },
      {
        "display": "Yamanashi",
        "value": "Yamanashi:山梨県"
      },
      {
        "display": "Nagano",
        "value": "Nagano:長野県"
      },
      {
        "display": "Gifu",
        "value": "Gifu:岐阜県"
      },
      {
        "display": "Shizuoka",
        "value": "Shizuoka:静岡県"
      },
      {
        "display": "Aichi",
        "value": "Aichi:愛知県"
      },
      {
        "display": "Mie",
        "value": "Mie:三重県"
      },
      {
        "display": "Shiga",
        "value": "Shiga:滋賀県"
      },
      {
        "display": "Kyoto",
        "value": "Kyoto:京都府"
      },
      {
        "display": "Osaka",
        "value": "Osaka:大阪府"
      },
      {
        "display": "Hyogo",
        "value": "Hyogo:兵庫県"
      },
      {
        "display": "Nara",
        "value": "Nara:奈良県"
      },
      {
        "display": "Wakayama",
        "value": "Wakayama:和歌山県"
      },
      {
        "display": "Tottori",
        "value": "Tottori:鳥取県"
      },
      {
        "display": "Shimane",
        "value": "Shimane:島根県"
      },
      {
        "display": "Okayama",
        "value": "Okayama:岡山県"
      },
      {
        "display": "Hiroshima",
        "value": "Hiroshima:広島県"
      },
      {
        "display": "Yamaguchi",
        "value": "Yamaguchi:山口県"
      },
      {
        "display": "Tokushima",
        "value": "Tokushima:徳島県"
      },
      {
        "display": "Kagawa",
        "value": "Kagawa:香川県"
      },
      {
        "display": "Ehime",
        "value": "Ehime:愛媛県"
      },
      {
        "display": "Kochi",
        "value": "Kochi:高知県"
      },
      {
        "display": "Fukuoka",
        "value": "Fukuoka:福岡県"
      },
      {
        "display": "Saga",
        "value": "Saga:佐賀県"
      },
      {
        "display": "Nagasaki",
        "value": "Nagasaki:長崎県"
      },
      {
        "display": "Kumamoto",
        "value": "Kumamoto:熊本県"
      },
      {
        "display": "Oita",
        "value": "Oita:大分県"
      },
      {
        "display": "Miyazaki",
        "value": "Miyazaki:宮崎県"
      },
      {
        "display": "Kagoshima",
        "value": "Kagoshima:鹿児島県"
      },
      {
        "display": "Okinawa",
        "value": "Okinawa:沖縄県"
      }
    ]
  prefixes: MyList[] =
    [
      {
        "display": "Mr.",
        "value": "Mr."
      },
      {
        "display": "Mrs.",
        "value": "Mrs."
      },
      {
        "display": "Ms.",
        "value": "Ms."
      },
      {
        "display": "Miss",
        "value": "Miss"
      },
      {
        "display": "Dr.",
        "value": "Dr."
      },
      {
        "display": "Prof.",
        "value": "Prof."
      },
      {
        "display": "Rev.",
        "value": "Rev."
      },
    ]

  user: User = {
    PasswordHash:null,
    Id:null,
    UserName:null,
    Email:null,
    FirstName:null,
    LastName:null,
    Password:null,
    Prefix:null,
    Shimei:null,
    Myoji:null,
    Street:null,
    Street2:null,
    City:null,
    State:null,
    Zip:null,
    Country:null,
    Yubin_Bango:null,
    To_Do_Fu_Ken:null,
    Shi_Gun_Ku:null,
    Cho_Son:null,
    Apartment_Etc:null,
    To_Do_Fu_Ken_JP:null,
    Shi_Gun_Ku_JP:null,
    Cho_Son_JP:null,
    Apartment_Etc_JP:null,
    Gender:null,
    DOB:null,
    TelNo:null,
    TimezoneName:null,
    UTCdiff:null,
    Token:null,
    Occupation:null,
    MemberStatus:null,
    Married:null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount:null,
    RegisteredDate:null,
    LastAccessedDate:null,
    //From siteground
    LoginId:null,
    MiddleNameEN:null,
    MiddleNameJP:null,
    PrefixJP:null,
    OccupationDetail:null,
    Organization:null,
    LivingCountry:null,
    PhoneEN:null,
    PhoneMobileEN:null,
    PhoneJP:null,
    PhoneMobileJP:null,
    ChurchNameEN:null,
    ChurchNameJP:null,
    MemberType:null,
    MemberDetail:null,
    MemberMemo:null,
    Shokaisha:null,
    Reason:null,
    NewsLetters:null,
    StaffReports:null,
    OtherLetters:null,
    NewsLetterLanguage:null,
    BasicRoles:null,
    //Added new
    MigrateFromSiteGround:null,
    AdminAccess:null,
    JCFNAccess:null,
    SupportingMember:null,
    LastAddressUpdatedDate:null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };
  relatedPerson:RelatedPerson={
    Id:null,
    RelatedId:null,
    Email:null,
    FirstNameRelated:null,
    LastNameRelated:null,
    MyojiRelated:null,
    ShimeiRelated:null,
    DOBRelated:null,
    GenderRelated:null
  };
  relatedPersons:RelatedPerson[]=[];
  volunteerSelections:VolunteerSelection[]=[];

  conference: ECConference= {
    ECConferenceId:0,
    UserId: null,
    Email: null,
    UserName: null,
    FirstName: null,
    LastName: null,
    //Program
    ProgramType: null,
    SmallGroupLanguage: null,
    AttendJapanRegionalMeeting:null,
    JapanRegionalMeeting:null,
    ApplyEnglishPartner:null,
    //Payment
    ConferenceFee: null,
    ConferenceDiscountAmount: null,
    ConferenceFeePaidAmount: null,
    ConferenceFeePaidDate: null,
    CouponApproved: null,
    ApplyScholarship: null,
    ApplyingScholarshipAmount: null,
    ApprovedScholarshipAmount: null,
    ApplyingReason: null,
    //Others\
    Volunteers:null,
    Student:null,
    ApplyCounseling: null,
    MySleepingBag:null,
    BringExtraSleepingBag:null,
    CountExtraSleepingBag:null,
    NeedCommingBus:null,
    CommingBusDeparturePlace:null,
    CommingBusDepartureDateTime:null,
    CommingAirline:null,
    CommingFlightNo:null,
    DepartureCity:null,
    CommingInternationalDomestic:null,
    ArrivalDateTime:null,
    NeedReturningBus:null,
    ReturningBusDepartureDateTime:null,
    ReturningAirline:null,
    ReturningFlightNo:null,
    ReturningCity:null,
    ReturningBusDeparturePlace:null,
    ReturningInternationalDomestic:null,
    DepartureDateTime:null,
    RoomInfo:null,
    Couple:null,
    Youth:null,
    YouthGrade: null,
    GuardianFirstName: null,
    GuardianLastName: null,
    GuardianMyoji: null,
    GuardianShimei: null,
    GuardianEmail: null,
    GuardianTelNo: null,
    TermsOfParticipation: null,
    TermsOfParticipationParent: null,
    TermsOfParticipationDate: null,
    TermsOfParticipationDateParent: null,
    RegisteredDate: null,
    UpdatedDate: null,
    AdminAccess: null,
    ECCommitteeAccess: null,
    Memo: null,
    Dec26:null,
    Dec27:null,
    Dec28:null,
    Dec29:null,
    Dec30:null,
    Dec31:null,
    KidGroup1:null,
    KidGroup2:null,
    KidGroup3:null,
    KidGroup4:null,
    myRelatedPersons: this.relatedPersons,
    myVolunteerSelections:this.volunteerSelections,
    BusComingConfirmation:null,
    BusReturningConfirmation:null,
    TermsOfParticipationVenue:null,
    TermsOfParticipationDateVenue:null,
    TermsOfParticipationYouth:null,
    TermsOfParticipationDateYouth:null,
    TermsOfParticipationYouthParent:null,
    TermsOfParticipationDateYouthParent:null,
    EmergencyContactFirstName:null,
    EmergencyContactLastName:null,
    EmergencyContactEmail:null,
    EmergencyContactTelNo:null,
    HasAllergy:null,
    AllergyDetail:null,
    BringInsurance:null,
    MinorHasCustodyInsurance:null,
    MinorHasCustodyInsuranceDate:null,
    InsuranceCompany:null,
    InsuranceAddress:null,
    InsuranceTelNo:null,
    InsurancePolicyNo:null,
    InsuranceGroupNo:null,
    VolunteerAgreeConductCode:null,
    VolunteerAgreeConductCodeDate:null,
    PayingAmount:null,
     //New
    BecomeMentor:null,
    ApplyMentoring:null,
    ReturningDepartureCity:null,
    InsuranceStatus:null,
    DressCodeAgreeYouth:null,
    DressCodeDateYouth:null,
    DressCodeAgreeParent:null,
    DressCodeDateParent:null
  }

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private registrationService: UserService,
    private ecRegistrationService : EcRegistrationService,
    private dialog: MatDialog,
    public authService: AuthService,
    private userService: UserService,
    /**************************************************
     * Followings 2 componensts used to call function
     * in those componets from this class
    ***************************************************/
  ) {
    this.route.queryParams.subscribe(params => {
      this.user.FirstName = params["FirstName"];
      this.user.LastName   = params["LastName"];
      console.log("1.First Name: "+ this.conference.FirstName);
      console.log("2.Last Name: "+this.conference.LastName);
  });
   }

  mySearch: any = {};
  ngOnInit(): void {
    this.myFormGroup = this._formBuilder.group({
      myStreet: ['', Validators.required],
      myStreet2: [''],
      myCity: ['', Validators.required],
      myState: ['', Validators.required],
      myZip: ['', Validators.required],
      myYubin_Bango: ['', Validators.required],
      myTo_Do_Fu_Ken: ['', Validators.required],
      myShi_Gun_Ku: ['', Validators.required],
      myCho_Son: ['', Validators.required],
      myApartment_Etc: [''],
      myCountry: ['', Validators.required],
    });

    this.loaded = false;
    if (sessionStorage.getItem("myGRCSearchingUserIdGRC") != null) {
      /*************************************************
       *  A) User info for searching registrant by admin
       *************************************************/
      this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnUserAdminGRC"));
      if (this.mySearch != null) {
        console.log('admin is editing this user')
        this.user = this.mySearch.myEditingUser;
        this.patchModelValue(this.user);
        this.setMyCountry();
        if (this.user.Country != 'Japan') {
          this.myFormGroup.get('myYubin_Bango').clearValidators();
          this.myFormGroup.get('myYubin_Bango').updateValueAndValidity();
          this.myFormGroup.get('myTo_Do_Fu_Ken').clearValidators();
          this.myFormGroup.get('myTo_Do_Fu_Ken').updateValueAndValidity();
          this.myFormGroup.get('myShi_Gun_Ku').clearValidators();
          this.myFormGroup.get('myShi_Gun_Ku').updateValueAndValidity();
          this.myFormGroup.get('myCho_Son').clearValidators();
          this.myFormGroup.get('myCho_Son').updateValueAndValidity();
        } else if (this.user.Country == 'Japan') {
          this.myFormGroup.get('myStreet').clearValidators();
          this.myFormGroup.get('myStreet').updateValueAndValidity();
          this.myFormGroup.get('myCity').clearValidators();
          this.myFormGroup.get('myCity').updateValueAndValidity();
          this.myFormGroup.get('myState').clearValidators();
          this.myFormGroup.get('myState').updateValueAndValidity();
          this.myFormGroup.get('myZip').clearValidators();
          this.myFormGroup.get('myZip').updateValueAndValidity();
        }
        this.loaded = true;
      } else {
        this.loaded = true;
      }
    } else {
      /*#####################################################################
      # Get user info from previous page - basic regardless it is searching user or myself
      ######################################################################*/
      this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnUserGRC"));
      if (this.mySearch != null) {
        this.user = this.mySearch.myEditingUser;
        this.patchModelValue(this.user);
        this.setMyCountry();
        if (this.user.Country != 'Japan') {
          this.myFormGroup.get('myYubin_Bango').clearValidators();
          this.myFormGroup.get('myYubin_Bango').updateValueAndValidity();
          this.myFormGroup.get('myTo_Do_Fu_Ken').clearValidators();
          this.myFormGroup.get('myTo_Do_Fu_Ken').updateValueAndValidity();
          this.myFormGroup.get('myShi_Gun_Ku').clearValidators();
          this.myFormGroup.get('myShi_Gun_Ku').updateValueAndValidity();
          this.myFormGroup.get('myCho_Son').clearValidators();
          this.myFormGroup.get('myCho_Son').updateValueAndValidity();
        } else if (this.user.Country == 'Japan') {
          this.myFormGroup.get('myStreet').clearValidators();
          this.myFormGroup.get('myStreet').updateValueAndValidity();
          this.myFormGroup.get('myCity').clearValidators();
          this.myFormGroup.get('myCity').updateValueAndValidity();
          this.myFormGroup.get('myState').clearValidators();
          this.myFormGroup.get('myState').updateValueAndValidity();
          this.myFormGroup.get('myZip').clearValidators();
          this.myFormGroup.get('myZip').updateValueAndValidity();
        }
        this.loaded = true;
      } else {
      }
    }
  }

  patchModelValue(model:User){
    this.myFormGroup.patchValue({
      myStreet:model.Street,
      myStreet2:model.Street2,
      myCity: model.City,
      myState: model.State,
      myZip: model.Zip,
      myYubin_Bango:model.Yubin_Bango,
      myTo_Do_Fu_Ken: model.To_Do_Fu_Ken,
      myShi_Gun_Ku:model.Shi_Gun_Ku,
      myCho_Son: model.Cho_Son,
      myApartment_Etc: model.Apartment_Etc,
      myCountry: model.Country,
    });
  }
  getFormValue() {
    this.user.Street = this.myFormGroup.get('myStreet').value;
    this.user.Street2 = this.myFormGroup.get('myStreet2').value;
    this.user.City = this.myFormGroup.get('myCity').value;
    this.user.State = this.myFormGroup.get('myState').value;
    this.user.Zip = this.myFormGroup.get('myZip').value;
    this.user.Yubin_Bango = this.myFormGroup.get('myYubin_Bango').value;
    this.user.To_Do_Fu_Ken = this.myFormGroup.get('myTo_Do_Fu_Ken').value;
    this.user.Shi_Gun_Ku = this.myFormGroup.get('myShi_Gun_Ku').value;
    this.user.Cho_Son = this.myFormGroup.get('myCho_Son').value;
    this.user.Apartment_Etc = this.myFormGroup.get('myApartment_Etc').value;
    this.user.Country = this.myFormGroup.get('myCountry').value;
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  onSubmit() {
  }

  flagCountryOther:string;
  setMyCountry() {
    console.log('My Country: ' + this.user.Country)
    if (this.user.Country == null) {
      this.showUSAddress = false;
      this.showJapanAddress = false;
      this.showOtherAddress = false;
    } else if (this.user.Country == "United States") {
      this.showUSAddress = true;
      this.showJapanAddress = false;
      this.showOtherAddress = false;
    } else if (this.user.Country == "Japan") {
      this.showUSAddress = false;
      this.showJapanAddress = true;
      this.showOtherAddress = false;
    } else {
     // this.flagCountryOther = this.user.Country;
      this.showUSAddress = false;
      this.showJapanAddress = false;
      this.showOtherAddress = true;
    }
  }

  onUpdateOtherCountry(country:string){
    this.user.LivingCountry=country;
  }

  onChangeCountrySelection(event: MatRadioChange) {
    this.user.Country = event.value;
    this.user.LivingCountry = this.user.Country;
    console.log("My Country！:" + this.user.Country);
    if (this.user.Country == 'United States') {
      this.myFormGroup.get('myYubin_Bango').clearValidators();
      this.myFormGroup.get('myYubin_Bango').updateValueAndValidity();
      this.myFormGroup.get('myTo_Do_Fu_Ken').clearValidators();
      this.myFormGroup.get('myTo_Do_Fu_Ken').updateValueAndValidity();
      this.myFormGroup.get('myShi_Gun_Ku').clearValidators();
      this.myFormGroup.get('myShi_Gun_Ku').updateValueAndValidity();
      this.myFormGroup.get('myCho_Son').clearValidators();
      this.myFormGroup.get('myCho_Son').updateValueAndValidity();
      this.myFormGroup.get('myStreet').setValidators(Validators.required);
      this.myFormGroup.get('myStreet').updateValueAndValidity();
      this.myFormGroup.get('myCity').setValidators(Validators.required);
      this.myFormGroup.get('myCity').updateValueAndValidity();
      this.myFormGroup.get('myState').setValidators(Validators.required);
      this.myFormGroup.get('myState').updateValueAndValidity();
      this.myFormGroup.get('myZip').setValidators(Validators.required);
      this.myFormGroup.get('myZip').updateValueAndValidity();
      this.showUSAddress = true;
      this.showJapanAddress = false;
      this.showOtherAddress = false;
      // this.user.Street = null;
      // this.user.Street2 = null;
      // this.user.City = null;
      // this.user.State = null;
      // this.user.Zip = null;
    } else if (this.user.Country == 'Japan') {
      this.myFormGroup.get('myYubin_Bango').setValidators(Validators.required);
      this.myFormGroup.get('myYubin_Bango').updateValueAndValidity();
      this.myFormGroup.get('myTo_Do_Fu_Ken').setValidators(Validators.required);
      this.myFormGroup.get('myTo_Do_Fu_Ken').updateValueAndValidity();
      this.myFormGroup.get('myShi_Gun_Ku').setValidators(Validators.required);
      this.myFormGroup.get('myShi_Gun_Ku').updateValueAndValidity();
      this.myFormGroup.get('myCho_Son').setValidators(Validators.required);
      this.myFormGroup.get('myCho_Son').updateValueAndValidity();
      this.myFormGroup.get('myStreet').clearValidators();
      this.myFormGroup.get('myStreet').updateValueAndValidity();
      this.myFormGroup.get('myCity').clearValidators();
      this.myFormGroup.get('myCity').updateValueAndValidity();
      this.myFormGroup.get('myState').clearValidators();
      this.myFormGroup.get('myState').updateValueAndValidity();
      this.myFormGroup.get('myZip').clearValidators();
      this.myFormGroup.get('myZip').updateValueAndValidity();
      this.showUSAddress = false;
      this.showJapanAddress = true;
      this.showOtherAddress = false;
      // this.user.Street = null;
      // this.user.Street2 = null;
      // this.user.City = null;
      // this.user.State = null;
      // this.user.Zip = null;
    } else {
      this.myFormGroup.get('myYubin_Bango').clearValidators();
      this.myFormGroup.get('myYubin_Bango').updateValueAndValidity();
      this.myFormGroup.get('myTo_Do_Fu_Ken').clearValidators();
      this.myFormGroup.get('myTo_Do_Fu_Ken').updateValueAndValidity();
      this.myFormGroup.get('myShi_Gun_Ku').clearValidators();
      this.myFormGroup.get('myShi_Gun_Ku').updateValueAndValidity();
      this.myFormGroup.get('myCho_Son').clearValidators();
      this.myFormGroup.get('myCho_Son').updateValueAndValidity();
      this.myFormGroup.get('myStreet').setValidators(Validators.required);
      this.myFormGroup.get('myStreet').updateValueAndValidity();
      this.myFormGroup.get('myCity').setValidators(Validators.required);
      this.myFormGroup.get('myCity').updateValueAndValidity();
      this.myFormGroup.get('myState').setValidators(Validators.required);
      this.myFormGroup.get('myState').updateValueAndValidity();
      this.myFormGroup.get('myZip').setValidators(Validators.required);
      this.myFormGroup.get('myZip').updateValueAndValidity();
      this.showUSAddress = false;
      this.showJapanAddress = false;
      this.showOtherAddress = true;
      // this.user.Street = null;
      // this.user.Street2 = null;
      // this.user.City = null;
      // this.user.State = null;
      // this.user.Zip = null;
    }
  }

  onEnterMyCountry() {
    console.log('My Country: ' + this.user.Country)
  }

  onClickGoForward(){
    this.getFormValue();
    if(this.router.url.includes('/registration/GRC/')){
      sessionStorage.removeItem("myWorkingOnUserGRC");
      sessionStorage.setItem(
        "myWorkingOnUserGRC",
        JSON.stringify({ myEditingUser: this.user })
      );
      this.router.navigate(["/registration/GRC/faithInfoGRC/" +this.getMyLanguage()]);
    }else{
      sessionStorage.removeItem("myWorkingOnUserAdminGRC");
      sessionStorage.setItem(
        "myWorkingOnUserAdminGRC",
        JSON.stringify({ myEditingUser: this.user })
      );
      this.dialog.closeAll();
      this.openForwardEmbed();
    }
  }
  openForwardEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ myPage: 'faithInfoGRC' };
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedGRCComponent,dialogConfig);
    // dialogConfig.data={ Id: this.user.Id };
    // const dialogRef = this.dialog.open(FaithInfoEmbedComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }
  onClickGoBack(){
    if(this.router.url.includes('/registration/GRC/')){
      this.router.navigate(["/registration/GRC/basicSubGRC/" +this.getMyLanguage()]);
    }else{
      this.dialog.closeAll();
      this.openBackEmbed();
    }
  }

  openBackEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ myPage: 'basicSubGRC' };
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedGRCComponent,dialogConfig);
    // dialogConfig.data={ Id: this.user.Id };
    // const dialogRef = this.dialog.open(BasicSubEmbedComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  cancelProcess(){
    this.getFormValue();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='210px'; //Desktop
    dialogConfig.maxWidth='370px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ user: this.user };
    const dialogRef = this.dialog.open(CancelOrSaveConfirmationPersonalComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
      //Retreive updated registration Info.
      //this.ngOnInit();
      let navigationExtras: NavigationExtras = {
        queryParams:this.conference
      };
      if(result!='cancel'){
        if(this.router.url.includes('/registration/GRC/')){
          this.router.navigate(["/registration/GRC/myAccountGRC/" +this.getMyLanguage()], navigationExtras);
        }else{
          this.dialog.closeAll();
          this.openMyAccountEdit();
        }
        }
    })
  }

  openMyAccountEdit(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ userId: this.user.Id };
    const dialogRef = this.dialog.open(MyAccountEditComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }
}
