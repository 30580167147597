<div class="myBase">
 <div class="bg-layer">
   <div class="btnBack">
     <button type="button" class="btn btn-link" (click)="onClickClose()">
       <i class="fas fa-times-circle fa-2x"></i>&nbsp;&nbsp; {{this.router.url.includes('EN')==true?'Close': '閉じる'}}
     </button>
   </div>
   <app-personal></app-personal>
   <div class="btnBack">
     <button type="button" class="btn btn-link" (click)="onClickClose()">
       <i class="fas fa-times-circle fa-2x"></i>&nbsp;&nbsp; {{this.router.url.includes('EN')==true?'Close': '閉じる'}}
     </button>
   </div>
 </div>
</div>