import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-successfully-processed',
  templateUrl: './successfully-processed.component.html',
  styleUrls: ['./successfully-processed.component.css']
})
export class SuccessfullyProcessedComponent implements OnInit {
  mySelectedLanguage:string;

  constructor(
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.mySelectedLanguage = this.router.url.includes('EN') == true ? "EN" : "JP";
  }

}
