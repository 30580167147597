import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { ParticipantEditMyAccountComponent } from '../../dialog/participant-edit-my-account/participant-edit-my-account.component';
import { Router, ActivatedRoute } from "@angular/router";
import { MembersListComponent } from '../../admin/members-list/members-list.component';

export interface DialogData {
  Id: string
}

@Component({
  selector: 'app-my-account-import-dialog',
  templateUrl: './my-account-import-dialog.component.html',
  styleUrls: ['./my-account-import-dialog.component.css']
})
export class MyAccountImportDialogComponent implements OnInit {

  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<MyAccountImportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog,
    /**************************************************
     * Followings componenst used to call function 
     * in those componets from this class
    ***************************************************/
    private membersList: MembersListComponent,
  ) { }

  myId: string;

  ngOnInit(): void {
    this.myId = this.data.Id;
  }

  onClickClose() {
    sessionStorage.removeItem("searchingMemberId"); //remove seaching ID when closing
    console.log('Im closing!');
    this.dialogRef.close();
    /**********************************
     * Retrieve updated list of users.
     *********************************/
    this.membersList.ngOnInit();
  }
}