import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA,MatDialogConfig} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { UserService} from "../../../services/user/user.service";
import { Router, ActivatedRoute } from "@angular/router";
import { User } from "../../../models/user";
import { AuthService } from "../../../services/auth/auth.service";
import { LoginCheckEmailComponent} from "../../dialog/login-check-email/login-check-email.component";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';


export interface DialogData {
  myLanguage:string
}



@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.css']
})
export class LoginDialogComponent implements OnInit {
  loaded:boolean=true;
  firstFormGroup: FormGroup;
  myMessage:string;
  loading = false;
  mySelectedLanguage:string;
  Email: string;
  Password: string;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  user: User = {
    PasswordHash:null,
    Id:null,
    UserName:null,
    Email:null,
    FirstName:null,
    LastName:null,
    Password:null,
    Prefix:null,
    Shimei:null,
    Myoji:null,
    Street:null,
    Street2:null,
    City:null,
    State:null,
    Zip:null,
    Country:null,
    Yubin_Bango:null,
    To_Do_Fu_Ken:null,
    Shi_Gun_Ku:null,
    Cho_Son:null,
    Apartment_Etc:null,
    To_Do_Fu_Ken_JP:null,
    Shi_Gun_Ku_JP:null,
    Cho_Son_JP:null,
    Apartment_Etc_JP:null,
    Gender:null,
    DOB:null,
    TelNo:null,
    TimezoneName:null,
    UTCdiff:null,
    Token:null,
    Occupation:null,
    MemberStatus:null,
    Married:null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount:null,
    RegisteredDate:null,
    LastAccessedDate:null,
    //From siteground
    LoginId:null,
    MiddleNameEN:null,
    MiddleNameJP:null,
    PrefixJP:null,
    OccupationDetail:null,
    Organization:null,
    LivingCountry:null,
    PhoneEN:null,
    PhoneMobileEN:null,
    PhoneJP:null,
    PhoneMobileJP:null,
    ChurchNameEN:null,
    ChurchNameJP:null,
    MemberType:null,
    MemberDetail:null,
    MemberMemo:null,
    Shokaisha:null,
    Reason:null,
    NewsLetters:null,
    StaffReports:null,
    OtherLetters:null,
    NewsLetterLanguage:null,
    BasicRoles:null,
      //Added new
      MigrateFromSiteGround:null,
      AdminAccess:null,
      JCFNAccess:null,
      SupportingMember:null,
      LastAddressUpdatedDate:null,
      AcceptMembershipTerm:null,
      MembershipType:null
  };


  constructor(
    private authService: AuthService,
    public router: Router,
    private _formBuilder: FormBuilder,
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog
  ){}

  ngOnInit() {
    sessionStorage.clear();

    this.firstFormGroup = this._formBuilder.group({
      myEmail: ['', Validators.required],
      myPassword: ['', Validators.required],
    });
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClickAttendedEC20(){
    const dialogRef = this.dialog.open(LoginCheckEmailComponent, {
      width: '500px',
      height:'500px',
      data: {
        myLanguage:this.getMyLanguage()
      }
    });
    this.dialogRef.close();
  }

  onClickNoAttendedEC20(){
  this.router.navigate(["/registration/personal/" +this.getMyLanguage()]);
  this.dialogRef.close();
  }

  onClickBack(){
    this.dialogRef.close();
  }

  onClickForgotPassword(){
    this.router.navigate(["/reset-password/" +this.getMyLanguage()]);
    this.dialogRef.close();
  }

  onCheckLogin() {
    this.loaded = false;
    //1. Get Token from Host
    this.authService.login(this.user.Email, this.user.Password).subscribe(
      token => {
      console.log('**** TokenReturning result in Login Page ' + token+ " ****");
      this.router.navigate(["/members/myProfile/" + this.getMyLanguage()]);
      this.dialogRef.close();
    },
    error => {
      this.myMessage = this.getMyLanguage()=="EN"?"Login failed.":"ログインに失敗しました。";
      this.openSnackBar('error', 'close');
      this.loaded = true;
    })
  }

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 5000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 12000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }

}
