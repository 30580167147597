<div class="myBase">
 <div class="bg-layer">
  <div class="btnBack">
   <button type="button" class="btn btn-link" (click)="onClickClose()">
    <i class="fas fa-times-circle fa-2x"></i>&nbsp;&nbsp;
    {{this.router.url.includes('EN')==true?'Close': '閉じる'}}
   </button>
  </div>

  <form [formGroup]="firstFormGroup">
   <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
   <div *ngIf="!loaded">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
   </div>

   <div *ngIf="loaded">
    <mat-card class="RegistrationCardList">
     <mat-card-header>
      <mat-card-title>
       Supporting Membership Maintenance
      </mat-card-title>
      <mat-card-subtitle>
       * ... Required
      </mat-card-subtitle>
     </mat-card-header>
     <div *ngIf="this.beSupportingMember" class="warningMessage">
      {{this.router.url.includes('EN')==true?'Already Supporting member': '既に維持会員です'}}
     </div>
     <mat-form-field appearance="outline" class="registrationInputForm">
      <mat-label>
       First Name
      </mat-label>
      <input matInput placeholder="" formControlName="myFirstName" [(ngModel)]="paymentMasterCore.FirstName" required
       class="registrationInputBox" cdkFocusInitial>
      <mat-error>
       First Name is required
      </mat-error>
     </mat-form-field>

     <mat-form-field appearance="outline" class="registrationInputForm">
      <mat-label>
       Last Name
      </mat-label>
      <input matInput placeholder="" formControlName="myLastName" [(ngModel)]="paymentMasterCore.LastName" required
       class="registrationInputBox">
      <mat-error>
       Last Name is required
      </mat-error>
     </mat-form-field>

     <mat-form-field appearance="outline" class="registrationInputForm">
      <mat-label>
       Email
      </mat-label>
      <input matInput placeholder="" formControlName="myEmail" [(ngModel)]="paymentMasterCore.Email" required
       class="registrationInputBox">
     </mat-form-field>

     <mat-form-field appearance="outline" class="registrationInputForm">
      <mat-label>Item Name</mat-label>
       <mat-select placeholder="" formControlName="myItemName" [(ngModel)]="paymentMasterCore.ItemName" class="matInputBox"
       required>
       <mat-option *ngFor="let membershipType of membershipTypes" [value]="membershipType.value" class="matInputBox">
        {{ membershipType.display }}
       </mat-option>
      </mat-select>
      <mat-error>
       Item Name is required
      </mat-error>
     </mat-form-field>

     <mat-form-field appearance="outline" class="registrationInputForm">
      <mat-label>Currency</mat-label>
      <mat-select placeholder="" formControlName="myCurrency" [(ngModel)]="paymentMasterCore.Currency" class="matInputBox"
       ngDefaultControl #choosedValue (selectionChange)="onCurrencyChanged(choosedValue.value)">
       <mat-option *ngFor="let currency of currencies" [value]="currency.value" class="matInputBox">
        {{ currency.display }}
       </mat-option>
      </mat-select>
      <mat-error>
       Currency is required
      </mat-error>
     </mat-form-field>

     <mat-form-field appearance="outline" class="registrationInputForm">
      <mat-label>
       Amount
      </mat-label>
      <input matInput placeholder="" formControlName="myAmount" [(ngModel)]="paymentMasterCore.Amount" required
       class="registrationInputBox">
      <mat-error>
       Amount is required
      </mat-error>
     </mat-form-field>

     <mat-form-field appearance="outline" class="registrationInputForm">
      <mat-label>Vendor</mat-label>
      <mat-select placeholder="" formControlName="myProviderName" [(ngModel)]="paymentMasterCore.ProviderName" class="matInputBox"
       ngDefaultControl>
       <mat-option *ngFor="let provider of providers" [value]="provider.value" class="matInputBox">
        {{ provider.display }}
       </mat-option>
      </mat-select>
      <mat-error>
       Vendor is required
      </mat-error>
     </mat-form-field>

     <mat-form-field appearance="outline" class="registrationInputForm">
      <mat-label>
       Payment Date
      </mat-label>
      <input matInput placeholder="" formControlName="myCreatedDate" [(ngModel)]="paymentMasterCore.CreatedDate"
       class="registrationInputBox" [matDatepicker]="dateCreatedDate"  required>
      <mat-datepicker-toggle matSuffix [for]="dateCreatedDate"></mat-datepicker-toggle>
      <mat-datepicker #dateCreatedDate></mat-datepicker>
      <mat-error>
       Payment date is required
      </mat-error>
     </mat-form-field>


     <div *ngIf="!loaded">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
     </div>
     <div *ngIf="!loaded" class="progressMessage">
      Saving...
     </div>
     <div class="divUpdateButton  d-flex justify-content-center">
      <button mat-button (click)="onNoClick()" class="btnRegistrantUpdate">Cancel</button>
      <button [disabled]="this.disableUpdateButton"

      mat-button (click)="InsertSupportingMembershipPaymentInfo()" class="btnRegistrantUpdate" cdkFocusInitial>Update</button>
     </div>

    </mat-card>
   </div>
  </form>

 </div>
</div>
<div class="memoForDevelopper">
  membership-setting
  </div>
