import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from "@angular/router";
import { UserConference } from 'src/app/models/conference/userConference';
export interface DialogData {
  myPage: string
}
@Component({
  selector: 'app-registration-conference-embed',
  templateUrl: './registration-conference-embed.component.html',
  styleUrls: ['./registration-conference-embed.component.css']
})
export class RegistrationConferenceEmbedComponent implements OnInit {
  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<RegistrationConferenceEmbedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  componentPage:string;

  ngOnInit(): void {
   this.componentPage = this.data.myPage;
  }

  onClickClose() {
    // Adminによる調べ中のユーザーのMy Accountに戻る前に作業中のセッションは消しておく
    sessionStorage.removeItem("myWorkingOnConferenceAdmin");
    this.dialogRef.close();
  }
}
