<div class="myBase">
    <div class="bg-layer">
        <div class="btnBack">
            <button type="button" class="btn btn-link" (click)="onClickClose()">
                <i class="fas fa-times-circle fa-2x"></i>&nbsp;&nbsp;
                {{this.router.url.includes('EN')==true?'Close': '閉じる'}}
            </button>
        </div>
        <form>
            <mat-card class="RegistrationCardList">
                <mat-card-header>
                    <mat-card-title>
                        Upload Files
                    </mat-card-title>
                </mat-card-header>
                <div class="form-group uploadingFile">
                    <input type="file" />
                    <br><br>
                    <button type="submit" class="btn btn-primary" (click)="uploadCodeMasterFile()">
                        Submit
                    </button>
                    <div *ngIf="!loaded">
                        <h4 class="table loading-message">Uploading ...</h4>
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </div>
                </div>
            </mat-card>
        </form>
    </div>
</div>
