import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { UserService} from "../../../../services/user/user.service";
import { Router, ActivatedRoute,NavigationExtras } from "@angular/router";
import { MatRadioChange } from '@angular/material/radio';
import { ECConference } from 'src/app/models/conference/ECConference';
import { User } from 'src/app/models/user';
import { CancelOrSaveConfirmationComponent } from 'src/app/components/dialog/cancel-or-save-confirmation/cancel-or-save-confirmation.component';
//For Dialog
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA , MatDialogConfig} from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { EcRegistrationService } from 'src/app/services/conference/ec/ec-registration.service';
import { RelatedPerson} from "../../../../models/conference/RelatedPerson";
import { VolunteerSelection} from "../../../../models/conference/volunteerSelection";
import { RegistrationConferenceEmbedComponent } from 'src/app/components/dialog/EC/registration-conference-embed/registration-conference-embed.component';
import { MyAccountEditComponent } from 'src/app/components/dialog/EC/my-account-edit/my-account-edit.component';
interface To_Do_Fu_KenEN {
  display: string;
  value: string;
}
interface To_Do_Fu_KenJP {
  display: string;
  value: string;
}
@Component({
  selector: 'app-japan-regional-meeting',
  templateUrl: './japan-regional-meeting.component.html',
  styleUrls: ['./japan-regional-meeting.component.css']
})
export class JapanRegionalMeetingComponent implements OnInit {
  loaded:boolean=true;
  myFormGroup: FormGroup;
  myMessage:string;
  mySelectedLanguage: string;
  disableSelectingAttendJapanRegionalMeeting:boolean;
  disableJapanRegionalMeetingSelection:boolean;

  to_do_fu_kensJP: To_Do_Fu_KenJP[] =
  [
    {
      "display": "北海道",
      "value": "Hokkaido:北海道"
    },
    {
      "display": "青森県",
      "value": "Aomori:青森県"
    },
    {
      "display": "岩手県",
      "value": "Iwate:岩手県"
    },
    {
      "display": "宮城県",
      "value": "Miyagi:宮城県"
    },
    {
      "display": "秋田県",
      "value": "Akita:秋田県"
    },
    {
      "display": "山形県",
      "value": "Yamagata:山形県"
    },
    {
      "display": "福島県",
      "value": "Fukushima:福島県"
    },
    {
      "display": "茨城県",
      "value": "Ibaraki:茨城県"
    },
    {
      "display": "栃木県",
      "value": "Tochigi:栃木県"
    },
    {
      "display": "群馬県",
      "value": "Gunma:群馬県"
    },
    {
      "display": "埼玉県",
      "value": "Saitama:埼玉県"
    },
    {
      "display": "千葉県",
      "value": "Chiba:千葉県"
    },
    {
      "display": "東京都",
      "value": "Tokyo:東京都"
    },
    {
      "display": "神奈川県",
      "value": "Kanagawa:神奈川県"
    },
    {
      "display": "新潟県",
      "value": "Niigata:新潟県"
    },
    {
      "display": "富山県",
      "value": "Toyama:富山県"
    },
    {
      "display": "石川県",
      "value": "Ishikawa:石川県"
    },
    {
      "display": "福井県",
      "value": "Fukui:福井県"
    },
    {
      "display": "山梨県",
      "value": "Yamanashi:山梨県"
    },
    {
      "display": "長野県",
      "value": "Nagano:長野県"
    },
    {
      "display": "岐阜県",
      "value": "Gifu:岐阜県"
    },
    {
      "display": "静岡県",
      "value": "Shizuoka:静岡県"
    },
    {
      "display": "愛知県",
      "value": "Aichi:愛知県"
    },
    {
      "display": "三重県",
      "value": "Mie:三重県"
    },
    {
      "display": "滋賀県",
      "value": "Shiga:滋賀県"
    },
    {
      "display": "京都府",
      "value": "Kyoto:京都府"
    },
    {
      "display": "大阪府",
      "value": "Osaka:大阪府"
    },
    {
      "display": "兵庫県",
      "value": "Hyogo:兵庫県"
    },
    {
      "display": "奈良県",
      "value": "Nara:奈良県"
    },
    {
      "display": "和歌山県",
      "value": "Wakayama:和歌山県"
    },
    {
      "display": "鳥取県",
      "value": "Tottori:鳥取県"
    },
    {
      "display": "島根県",
      "value": "Shimane:島根県"
    },
    {
      "display": "岡山県",
      "value": "Okayama:岡山県"
    },
    {
      "display": "広島県",
      "value": "Hiroshima:広島県"
    },
    {
      "display": "山口県",
      "value": "Yamaguchi:山口県"
    },
    {
      "display": "徳島県",
      "value": "Tokushima:徳島県"
    },
    {
      "display": "香川県",
      "value": "Kagawa:香川県"
    },
    {
      "display": "愛媛県",
      "value": "Ehime:愛媛県"
    },
    {
      "display": "高知県",
      "value": "Kochi:高知県"
    },
    {
      "display": "福岡県",
      "value": "Fukuoka:福岡県"
    },
    {
      "display": "佐賀県",
      "value": "Saga:佐賀県"
    },
    {
      "display": "長崎県",
      "value": "Nagasaki:長崎県"
    },
    {
      "display": "熊本県",
      "value": "Kumamoto:熊本県"
    },
    {
      "display": "大分県",
      "value": "Oita:大分県"
    },
    {
      "display": "宮崎県",
      "value": "Miyazaki:宮崎県"
    },
    {
      "display": "鹿児島県",
      "value": "Kagoshima:鹿児島県"
    },
    {
      "display": "沖縄県",
      "value": "Okinawa:沖縄県"
    }
  ]
to_do_fu_kensEN: To_Do_Fu_KenEN[] =
  [
    {
      "display": "Hokkaido",
      "value": "Hokkaido:北海道"
    },
    {
      "display": "Aomori",
      "value": "Aomori:青森県"
    },
    {
      "display": "Iwate",
      "value": "Iwate:岩手県"
    },
    {
      "display": "Miyagi",
      "value": "Miyagi:宮城県"
    },
    {
      "display": "Akita",
      "value": "Akita:秋田県"
    },
    {
      "display": "Yamagata",
      "value": "Yamagata:山形県"
    },
    {
      "display": "Fukushima",
      "value": "Fukushima:福島県"
    },
    {
      "display": "Ibaraki",
      "value": "Ibaraki:茨城県"
    },
    {
      "display": "Tochigi",
      "value": "Tochigi:栃木県"
    },
    {
      "display": "Gunma",
      "value": "Gunma:群馬県"
    },
    {
      "display": "Saitama",
      "value": "Saitama:埼玉県"
    },
    {
      "display": "Chiba",
      "value": "Chiba:千葉県"
    },
    {
      "display": "Tokyo",
      "value": "Tokyo:東京都"
    },
    {
      "display": "Kanagawa",
      "value": "Kanagawa:神奈川県"
    },
    {
      "display": "Niigata",
      "value": "Niigata:新潟県"
    },
    {
      "display": "Toyama",
      "value": "Toyama:富山県"
    },
    {
      "display": "Ishikawa",
      "value": "Ishikawa:石川県"
    },
    {
      "display": "Fukui",
      "value": "Fukui:福井県"
    },
    {
      "display": "Yamanashi",
      "value": "Yamanashi:山梨県"
    },
    {
      "display": "Nagano",
      "value": "Nagano:長野県"
    },
    {
      "display": "Gifu",
      "value": "Gifu:岐阜県"
    },
    {
      "display": "Shizuoka",
      "value": "Shizuoka:静岡県"
    },
    {
      "display": "Aichi",
      "value": "Aichi:愛知県"
    },
    {
      "display": "Mie",
      "value": "Mie:三重県"
    },
    {
      "display": "Shiga",
      "value": "Shiga:滋賀県"
    },
    {
      "display": "Kyoto",
      "value": "Kyoto:京都府"
    },
    {
      "display": "Osaka",
      "value": "Osaka:大阪府"
    },
    {
      "display": "Hyogo",
      "value": "Hyogo:兵庫県"
    },
    {
      "display": "Nara",
      "value": "Nara:奈良県"
    },
    {
      "display": "Wakayama",
      "value": "Wakayama:和歌山県"
    },
    {
      "display": "Tottori",
      "value": "Tottori:鳥取県"
    },
    {
      "display": "Shimane",
      "value": "Shimane:島根県"
    },
    {
      "display": "Okayama",
      "value": "Okayama:岡山県"
    },
    {
      "display": "Hiroshima",
      "value": "Hiroshima:広島県"
    },
    {
      "display": "Yamaguchi",
      "value": "Yamaguchi:山口県"
    },
    {
      "display": "Tokushima",
      "value": "Tokushima:徳島県"
    },
    {
      "display": "Kagawa",
      "value": "Kagawa:香川県"
    },
    {
      "display": "Ehime",
      "value": "Ehime:愛媛県"
    },
    {
      "display": "Kochi",
      "value": "Kochi:高知県"
    },
    {
      "display": "Fukuoka",
      "value": "Fukuoka:福岡県"
    },
    {
      "display": "Saga",
      "value": "Saga:佐賀県"
    },
    {
      "display": "Nagasaki",
      "value": "Nagasaki:長崎県"
    },
    {
      "display": "Kumamoto",
      "value": "Kumamoto:熊本県"
    },
    {
      "display": "Oita",
      "value": "Oita:大分県"
    },
    {
      "display": "Miyazaki",
      "value": "Miyazaki:宮崎県"
    },
    {
      "display": "Kagoshima",
      "value": "Kagoshima:鹿児島県"
    },
    {
      "display": "Okinawa",
      "value": "Okinawa:沖縄県"
    }
  ]

  user: User = {
    PasswordHash:null,
    Id:null,
    UserName:null,
    Email:null,
    FirstName:null,
    LastName:null,
    Password:null,
    Prefix:null,
    Shimei:null,
    Myoji:null,
    Street:null,
    Street2:null,
    City:null,
    State:null,
    Zip:null,
    Country:null,
    Yubin_Bango:null,
    To_Do_Fu_Ken:null,
    Shi_Gun_Ku:null,
    Cho_Son:null,
    Apartment_Etc:null,
    To_Do_Fu_Ken_JP:null,
    Shi_Gun_Ku_JP:null,
    Cho_Son_JP:null,
    Apartment_Etc_JP:null,
    Gender:null,
    DOB:null,
    TelNo:null,
    TimezoneName:null,
    UTCdiff:null,
    Token:null,
    Occupation:null,
    MemberStatus:null,
    Married:null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount:null,
    RegisteredDate:null,
    LastAccessedDate:null,
    //From siteground
    LoginId:null,
    MiddleNameEN:null,
    MiddleNameJP:null,
    PrefixJP:null,
    OccupationDetail:null,
    Organization:null,
    LivingCountry:null,
    PhoneEN:null,
    PhoneMobileEN:null,
    PhoneJP:null,
    PhoneMobileJP:null,
    ChurchNameEN:null,
    ChurchNameJP:null,
    MemberType:null,
    MemberDetail:null,
    MemberMemo:null,
    Shokaisha:null,
    Reason:null,
    NewsLetters:null,
    StaffReports:null,
    OtherLetters:null,
    NewsLetterLanguage:null,
    BasicRoles:null,
    //Added new
    MigrateFromSiteGround:null,
    AdminAccess:null,
    JCFNAccess:null,
    SupportingMember:null,
    LastAddressUpdatedDate:null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };
  relatedPerson:RelatedPerson={
    Id:null,
    RelatedId:null,
    Email:null,
    FirstNameRelated:null,
    LastNameRelated:null,
    MyojiRelated:null,
    ShimeiRelated:null,
    DOBRelated:null,
    GenderRelated:null
  };
  relatedPersons:RelatedPerson[]=[];
  volunteerSelections:VolunteerSelection[]=[];
  conference: ECConference= {
    ECConferenceId:0,
    UserId: null,
    Email: null,
    UserName: null,
    FirstName: null,
    LastName: null,
    //Program
    ProgramType: null,
    SmallGroupLanguage: null,
    AttendJapanRegionalMeeting:null,
    JapanRegionalMeeting:null,
    ApplyEnglishPartner:null,
    //Payment
    ConferenceFee: null,
    ConferenceDiscountAmount: null,
    ConferenceFeePaidAmount: null,
    ConferenceFeePaidDate: null,
    CouponApproved: null,
    ApplyScholarship: null,
    ApplyingScholarshipAmount: null,
    ApprovedScholarshipAmount: null,
    ApplyingReason: null,
    //Other
    Volunteers:null,
    Student:null,
    ApplyCounseling: null,
    MySleepingBag:null,
    BringExtraSleepingBag:null,
    CountExtraSleepingBag:null,
    NeedCommingBus:null,
    CommingBusDeparturePlace:null,
    CommingBusDepartureDateTime:null,
    CommingAirline:null,
    CommingFlightNo:null,
    DepartureCity:null,
    CommingInternationalDomestic:null,
    ArrivalDateTime:null,
    NeedReturningBus:null,
    ReturningBusDepartureDateTime:null,
    ReturningAirline:null,
    ReturningFlightNo:null,
    ReturningCity:null,
    ReturningBusDeparturePlace:null,
    ReturningInternationalDomestic:null,
    DepartureDateTime:null,
    RoomInfo:null,
    Youth:null,
    YouthGrade: null,
    GuardianFirstName: null,
    GuardianLastName: null,
    GuardianMyoji: null,
    GuardianShimei: null,
    GuardianEmail: null,
    GuardianTelNo: null,
    TermsOfParticipation: null,
    TermsOfParticipationParent: null,
    TermsOfParticipationDate: null,
    TermsOfParticipationDateParent: null,
    RegisteredDate: null,
    UpdatedDate: null,

    AdminAccess: null,
    ECCommitteeAccess: null,
    Memo: null,
    Dec26:null,
    Dec27:null,
    Dec28:null,
    Dec29:null,
    Dec30:null,
    Dec31:null,
    KidGroup1:null,
    KidGroup2:null,
    KidGroup3:null,
    KidGroup4:null,
    myRelatedPersons: this.relatedPersons,
    myVolunteerSelections:this.volunteerSelections,
    BusComingConfirmation:null,
    BusReturningConfirmation:null,
    TermsOfParticipationVenue:null,
    TermsOfParticipationDateVenue:null,
    TermsOfParticipationYouth:null,
    TermsOfParticipationDateYouth:null,
    TermsOfParticipationYouthParent:null,
    TermsOfParticipationDateYouthParent:null,
    EmergencyContactFirstName:null,
    EmergencyContactLastName:null,
    EmergencyContactEmail:null,
    EmergencyContactTelNo:null,
    ApplicationFee:null,
    ApplicationFeePaidDate:null,
    BalanceAmount:null,
    BalanceAmountPaidDate:null,
    HasAllergy:null,
    AllergyDetail:null,
    BringInsurance:null,
    MinorHasCustodyInsurance:null,
    MinorHasCustodyInsuranceDate:null,
    InsuranceCompany:null,
    InsuranceAddress:null,
    InsuranceTelNo:null,
    InsurancePolicyNo:null,
    InsuranceGroupNo:null,
    VolunteerAgreeConductCode:null,
    VolunteerAgreeConductCodeDate:null,
    PayingAmount:null,
    //New
    BecomeMentor:null,
    ApplyMentoring:null,
    ReturningDepartureCity:null,
    InsuranceStatus:null,
    DressCodeAgreeYouth:null,
    DressCodeDateYouth:null,
    DressCodeAgreeParent:null,
    DressCodeDateParent:null
  }

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private registrationService: UserService,
    private ecRegistrationService : EcRegistrationService,
    private dialog: MatDialog,
  ) { }

  mySearch: any = {};
  ngOnInit(): void {
    this.myFormGroup = this._formBuilder.group({
      myAttendingJapanRegionalMeeting: [null,Validators.required],
      myJapanRegionalMeetingSelection: [null,Validators.required],
    });

    if (sessionStorage.getItem("myECSearchingUserId") != null) {
      /*************************************************
       *  A) User info for searching registrant by admin
       *************************************************/
       this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnConferenceAdmin"));
       if (this.mySearch != null) {
         this.conference = this.mySearch.myEditingConference;
         if(this.conference.ProgramType=="Youth"){
          this.myFormGroup.get('myJapanRegionalMeetingSelection').clearValidators();
          this.myFormGroup.get('myJapanRegionalMeetingSelection').updateValueAndValidity();
          this.disableSelectingAttendJapanRegionalMeeting=true;
          this.disableJapanRegionalMeetingSelection=true;
          this.conference.JapanRegionalMeeting=null;
          this.conference.AttendJapanRegionalMeeting=false;
        }else{
          this.disableSelectingAttendJapanRegionalMeeting=false;
          this.disableJapanRegionalMeetingSelection=false;
        }
         this.patchModelValue(this.conference);
         this.loaded = true;
       }else{
        this.loaded = true;
       }
    } else {
    /*####################################################################################
    #  B) Get conference info from previous page for myself
    #####################################################################################*/
    this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnConference"));
      if (this.mySearch != null) {
        this.conference = this.mySearch.myEditingConference;
        if(this.conference.ProgramType=="Youth"){
          this.myFormGroup.get('myJapanRegionalMeetingSelection').clearValidators();
          this.myFormGroup.get('myJapanRegionalMeetingSelection').updateValueAndValidity();
          this.disableSelectingAttendJapanRegionalMeeting=true;
          this.disableJapanRegionalMeetingSelection=true;
          this.conference.JapanRegionalMeeting=null;
          this.conference.AttendJapanRegionalMeeting=false;
        }else{
          this.disableSelectingAttendJapanRegionalMeeting=false;
          this.disableJapanRegionalMeetingSelection=false;
        }
        this.patchModelValue(this.conference);
        this.loaded = true;
      }else{
      }
    }
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  patchModelValue(model:ECConference){
    this.myFormGroup.patchValue({
      myAttendingJapanRegionalMeeting:model.AttendJapanRegionalMeeting,
      myJapanRegionalMeetingSelection:model.JapanRegionalMeeting
    });
    if(model.AttendJapanRegionalMeeting==false){
      this.myFormGroup.get('myJapanRegionalMeetingSelection').clearValidators();
      this.myFormGroup.get('myJapanRegionalMeetingSelection').updateValueAndValidity();
    }
  }

  getFormValue() {
    //this.conference.AttendJapanRegionalMeeting =$event.value == 'true' ? true : false;
    //this.conference.AttendJapanRegionalMeeting = this.myFormGroup.get('myAttendingJapanRegionalMeeting').value;
    this.conference.JapanRegionalMeeting = this.myFormGroup.get('myJapanRegionalMeetingSelection').value;
  }

  onChangeAttendSelection($event:MatRadioChange){
    this.conference.AttendJapanRegionalMeeting =$event.value == 'true' ? true : false;
    if(this.conference.AttendJapanRegionalMeeting){
      this.myFormGroup.get('myJapanRegionalMeetingSelection').setValidators(Validators.required);
      this.myFormGroup.get('myJapanRegionalMeetingSelection').updateValueAndValidity();
      this.disableJapanRegionalMeetingSelection=false;
    } else{
      this.myFormGroup.get('myJapanRegionalMeetingSelection').clearValidators();
      this.myFormGroup.get('myJapanRegionalMeetingSelection').updateValueAndValidity();
      this.disableJapanRegionalMeetingSelection=true;
      this.conference.JapanRegionalMeeting=null;
      this.patchModelValue(this.conference);
    }
   }

  onChangeJapanRegionalMeetingSelection($event:any){
  }

  onSubmit() {
  }

  onClickGoForward(){
    this.getFormValue();
    if(this.router.url.includes('/registration/ec/')){
      sessionStorage.removeItem("myWorkingOnConference");
      sessionStorage.setItem(
        "myWorkingOnConference",
        JSON.stringify({ myEditingConference: this.conference })
      );
      if(this.conference.ProgramType.includes("Seekers" )){
        this.router.navigate(["/registration/ec/englishPartners/" +this.getMyLanguage()]);
      }else{
        this.router.navigate(["/registration/ec/volunteers/" +this.getMyLanguage()]);
      }
    }else{
      sessionStorage.removeItem("myWorkingOnConferenceAdmin");
      sessionStorage.setItem(
        "myWorkingOnConferenceAdmin",
        JSON.stringify({ myEditingConference: this.conference })
      );
      this.dialog.closeAll();
      this.openForwardEmbed();
    }
  }
  openForwardEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktoop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    if(this.conference.ProgramType.includes("Seekers" )){
      dialogConfig.data={ myPage: 'englishPartners' };
      console.log('english');
    }else{
      dialogConfig.data={ myPage: 'volunteers' };
      console.log('volunteer');
    }
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  onClickGoBack(){
    if(this.router.url.includes('/registration/ec/')){
      this.router.navigate(["/registration/ec/smallGroupLanguage/" +this.getMyLanguage()]);
    }else{
      this.dialog.closeAll();
      this.openBackEmbed();
    }
  }
  openBackEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ myPage: 'smallGroupLanguage' };
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  cancelProcess(){
    this.getFormValue();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='210px'; //Desktop
    dialogConfig.maxWidth='370px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ conference: this.conference };
    const dialogRef = this.dialog.open(CancelOrSaveConfirmationComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
      //Retreive updated registration Info.
      //this.ngOnInit();
      let navigationExtras: NavigationExtras = {
        queryParams:this.conference
      };
      if(result!='cancel'){
        if(this.router.url.includes('/registration/ec/')){
          this.router.navigate(["/conference/EC/myAccount/" +this.getMyLanguage()], navigationExtras);
        }else{
          this.dialog.closeAll();
          this.openMyAccountEdit();
        }
        }
    })
  }

  openMyAccountEdit(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ userId: this.conference.UserId };
    const dialogRef = this.dialog.open(MyAccountEditComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }
  }
