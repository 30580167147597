<div class="myBase">
 <div class="myBackgroundLayer">
  <div class="row">
   <div class="myAccountTitle">
    <h2>
    </h2>
   </div>
  </div>

  <div *ngIf="!loaded">
   <mat-progress-bar mode="indeterminate"></mat-progress-bar>
 </div>

  <div *ngIf="loaded" class="d-flex justify-content-center">
   <mat-card class="supportingMembershipAnounce">
    <form [formGroup]="firstFormGroup">
    <h3>
     {{this.router.url.includes('EN')==true?'Mission Statement & Doctrinal Statement': 'ミッションステートメント＆信仰基準'}}
    </h3>

    
    <div class="stepTitle">
    <label class="statementFocus">
      {{this.router.url.includes('EN')==true?
      "Mission Statement":
      'ミッションステートメント'}}
    </label>
    <label>
      {{this.router.url.includes('EN')==true?
      "To glorify God, we equip multi-cultural Japanese Christians for the expansion of God's work.":
      'JCFNは、帰国者クリスチャンを主の働きの拡大のために整える活動を通し、主の栄光を表わすため存在しています。'}}
    </label>
    <label  class="statementFocus">
      {{this.router.url.includes('EN')==true?
      "Doctrinal Statement":
      '信仰基準'}}
    </label>
      <div class="make-scrollable scrollBackGround" flex>
        {{this.router.url.includes('EN')==true?doctrinalStatementEN: doctrinalStatementJP}}
      </div>
    </div>
    <!-- <br><br>
    <label>
      {{this.router.url.includes('EN')==true?
      'I have carefully read and understood the “Terms of Participation” above. I agree to the stipulations
      therein and express my consent by checking below checkbox.':
      '私は、上記の「参加規約」を読み理解しました。また、その内容に同意します。下のチェックボックスをチェックすることによって私の同意を表明します。'}}
    </label> -->
   <br><br>
   
   <div class="ckbZoomAccount">
    <mat-checkbox 
    formControlName="myAcceptTerms" 
    #checkboxAgree
     (change)='onChangeAgree(checkboxAgree.checked)'
     [(ngModel)]="user.AcceptMembershipTerm"
      class="ckb_SG"
      required
      >
      {{this.router.url.includes('EN')==true?
      'Agree':
      '同意する'}}
    </mat-checkbox>
  </div>
  <div>
    {{this.router.url.includes('EN')==true?
    "Only those who consent will be able to become JCFN member":
    '同意した方のみJCFNの会員登録をすることができます'}}
  </div>
<br>
<div>

</div>

<div class="d-flex justify-content-center">
 <button 
 type="button" 
 class="btn btnProcessPayment" 
 (click)="onClickNext()"
 [disabled]="user.AcceptMembershipTerm!=true"
 >
 {{this.router.url.includes('EN')==true?'Next': '次へ'}}
 </button>
</div>

    <a [routerLink]="this.router.url.includes('EN')==true?'/home/EN': '/home/JP'"
    class="btn btn-link">{{this.router.url.includes('EN')==true?'Home': 'ホーム'}}</a>&nbsp;&nbsp;
    <a [routerLink]="this.router.url.includes('EN')==true?'/members/myProfile/EN': '/members/myProfile/JP'"
    class="btn btn-link">{{this.router.url.includes('EN')==true?'My Account': 'マイアカウント'}}</a>

</form>
   </mat-card>
  </div>
 </div>
</div>