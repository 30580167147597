import { DateFilterFn } from "@angular/material/datepicker";


export class GRCConference {
  GRCConferenceId: number; //key
  UserId: string; //"8510a05f-91ac-42ed-9d23-4f7728f69a7f",
  ConferenceYear?:number;
  Email: string;
  UserName: string;
  FirstName: string;
  LastName: string;
  SmallGroupLanguage: string; //Japanese, English

  ConferenceFee?: number;
  ConferenceDiscountAmount?: number;
  ConferenceFeePaidAmount?: number;
  ConferenceFeePaidDate?: Date;
  CouponApproved?: boolean;
  ApplicationFee?: number;
  ApplicationFeePaidDate?: Date;
  BalanceAmount?: number;
  BalanceAmountPaidDate?: Date;

  TransportationMethodComing: string;
  TransportationMethodReturning: string;
  GRCDirectBusAdultNumberComing?:number;
  GRCDirectBusChildNumberComing?:number;
  GRCDirectBusAdultNumberReturning?:number;
  GRCDirectBusChildNumberReturning?:number;

  RoomInfo: string;
  Couple?: boolean;
  May3: string;
  May4: string;
  May5: string;
  May6: string;
  KidGroup1?: number;
  KidGroup2?: number;
  KidGroup3?: number;
  KidGroup4?: number;
  KidGroup5?: number;
  KidGroup6?: number;
  myGRCRelatedPersons: GRCRelatedPerson[];
  myGRCVolunteerSelections: GRCVolunteerSelection[];
  Memo: string;

  TermsOfParticipation?: boolean;
  TermsOfParticipationDate?: Date;
  EmergencyContactFirstName: string;
  EmergencyContactLastName: string;
  EmergencyContactEmail: string;
  EmergencyContactTelNo: string;
  RegisteredDate?: Date;
  UpdatedDate?: Date;
  AdminAccess?: boolean;
  CommitteeAccess?: boolean;

  ApplyScholarship?:boolean;
  ApplyingScholarshipAmount?:number;
  ApprovedScholarshipAmount?:number;
  ApplyingReason:string;
  PaymentOption:string;

  constructor() {
    UserId: null;
    Email: null;
    UserName: null;
    FirstName: null;
    LastName: null;
    SmallGroupLanguage: null;

    ConferenceFee: null;
    ConferenceDiscountAmount: null;
    ConferenceFeePaidAmount: null;
    ConferenceFeePaidDate: null;
    CouponApproved: null;
    ApplicationFee: null;
    ApplicationFeePaidDate: null;
    BalanceAmount: null;
    BalanceAmountPaidDate: null;

    TransportationMethodComing: null;
    TransportationMethodReturning: null;

    RoomInfo: null;
    Couple: null;
    May3: null;
    May4: null;
    May5: null;
    May6: null;
    KidGroup1: null;
    KidGroup2: null;
    KidGroup3: null;
    KidGroup4: null;
    KidGroup5: null;
    KidGroup6: null;
    //myRelatedPersons: RelatedPerson[];
    //myVolunteerSelections: VolunteerSelection[];
    Memo: null;

    TermsOfParticipation: null;
    TermsOfParticipationDate: null;
    EmergencyContactFirstName: null;
    EmergencyContactLastName: null;
    EmergencyContactEmail: null;
    EmergencyContactTelNo: null;
    RegisteredDate: null;
    UpdatedDate: null;
    AdminAccess: null;
    CommitteeAccess: null;

    ApplyScholarship: null;
    ApplyingScholarshipAmount: null;
    ApprovedScholarshipAmount: null;
    ApplyingReason:null;
    PaymentOption:null;
  }
}

export interface GRCRelatedPerson{
  Id:number;
  RelatedId:string;
  Email:string;
  FirstNameRelated:string;
  LastNameRelated:string;
  MyojiRelated:string;
  ShimeiRelated:string;
  DOBRelated?:Date;
  GenderRelated:string;
  ChildCare?:boolean;
  ChildProgram?:boolean;
}

export interface GRCVolunteerSelection{
  Id:number;
  UserId:string;
  Email:string;
  VolunteerName:string;
  VolunteerAccepted?:boolean;
  AcceptedDate?:Date;
}
