<div class="WelcomeBase">
  <div class="btnBack">
    <button type="button" class="btn btn-link" (click)="onClickBack()">
        <i class="fas fa-times-circle fa-2x"></i>
    </button>
</div>
  <mat-card class="WelcomeWindow">
      <form [formGroup]="myFormGroup" (ngSubmit)="onSubmit()">
          <mat-card-header>
              <mat-card-title >
                {{this.router.url.includes('EN')==true?'Reset password':'パスワード再設定'}}
              </mat-card-title>
          </mat-card-header>

          <div class="d-flex justify-content-center">
              <i class="fa fa-lock fa-2x"></i>&nbsp;



              <mat-form-field appearance="fill"  hintLabel="Min 8 characters" class="registrationInputForm">
                  <mat-label>
                      {{this.router.url.includes('EN')==true?'Password': 'パスワード'}}
                  </mat-label>




                  <input matInput placeholder="" formControlName="myPassword"
                   [type]="hideMyPassword ? 'password' : 'text'" #myInputPassword
                      class="registrationInputBox">
                      <!-- <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                      </button> -->
                      <mat-hint align="end">{{myInputPassword.value?.length || 0}} characters</mat-hint>
                      <button mat-icon-button matSuffix (click)="hideMyPassword = !hideMyPassword"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideMyPassword">
                        <mat-icon>{{hideMyPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                      </button>
                      <mat-error *ngIf="myFormGroup.get('myPassword').hasError('required')">
                        {{this.router.url.includes('EN')==true?'Password is required': '未入力'}}
                      </mat-error>
                      <mat-error *ngIf="myFormGroup.get('myPassword').hasError('minlength')">
                        {{this.router.url.includes('EN')==true?'Need 8 characters or more': '8文字以上必要です'}}
                      </mat-error>
                      <mat-error *ngIf="myFormGroup.get('myPassword').hasError('pattern')">
                        {{this.router.url.includes('EN')==true?'Must contain uppercase, lowercase, digit ': ' 大文字、小文字、数字が必要です'}}
                      </mat-error>
              </mat-form-field>



          </div>
          <br>
          <div class="d-flex justify-content-center">
            <i class="fa fa-lock fa-2x"></i>&nbsp;
            <mat-form-field appearance="fill" class="registrationInputForm">
                <mat-label>
                    {{this.router.url.includes('EN')==true?'Re-Enter Password': 'パスワード再入力'}}
                </mat-label>
                <input matInput placeholder="" formControlName="myRePassword"  [type]="hideMyPassword ? 'password' : 'text'"
                class="registrationInputBox">
                <!-- <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button> -->
                <mat-error *ngIf="myFormGroup.get('myRePassword').hasError('required')">
                    {{this.router.url.includes('EN')==true?'Password is required': '未入力'}}
                </mat-error>
            </mat-form-field>
        </div>

          <div *ngIf="!loaded">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <div *ngIf="!loaded" class="progressMessage">
              {{this.router.url.includes('EN')==true?'Processing...':'処理中 ...'}}
          </div>
          <br>
          <div class="divUpdateButton  d-flex justify-content-center">
            <button mat-button  class="btnSearchEmail" cdkFocusInitial>
              {{this.router.url.includes('EN')==true?'Submit':'送信'}}
             </button>
          </div>
          <div  class="d-flex justify-content-center btnCancel">
           <button mat-button  (click)="onClickCancel()">
            {{ this.router.url.includes('EN')==true?"Cancel":"キャンセル"}}</button>
           </div>

      </form>
  </mat-card>
</div>
<div class="memoForDevelopper">
  enter-new-password-child
</div>
