<div class="myBody">
  <div class="myHeaderBase d-flex justify-content-center">
    <div class="myHeaderCore">
      <div>
        <img src='../../../../../assets/images/thumbnail_eclogo.png' class="ecLogo">
      </div>
    </div>
  </div>
  <div class="myMain">
    <div clas="myMainLeft">
      <div class="myMainHalfTop">
        <!-- Left Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Left Bottom -->
      </div>
    </div>
    <div class="myMainCenter">
      <div class="myMainHalfTop d-flex justify-content-center">
        <!-- Main Top -->
        <mat-card class="mainCard b-radius">
          <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
          <div *ngIf="!loaded">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <form [formGroup]="myFormGroup" (ngSubmit)="onSubmit()">
            <div class="float-end stepNumber">8/12</div>
            <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Transportation':
              'トランスポーテーション'}}
              <i class="stepTitleSub">
                {{this.router.url.includes('EN')==true?'Required': '必須'}}
              </i>
            </h2>

            <mat-card *ngIf="loaded" class="mainCardTop b-radius">
              <div class="transportGroup">
                <div class="stepTitle2nd">
                  {{this.router.url.includes('EN')==true?
                  'Buses will be available on December 27 at both LAX and San Diego airports; two buses will be at LAX and one at San Diego airport. If you cannot make it to the bus on time, you will need to come to the venue on your own; on the 28th, 29th, 30th, and 31st, there will be no airport-to-venue or venue-to-airport transfers, so you will need to arrange your own transportation. It takes 3 to 4 hours one way from LAX to the venue, and 1.5 to 2 hours from the San Diego airport. We recommend that you arrive well in advance. Depending on your arrival time, you may be asked to wait at the airport for a long time.'
                  :
                  'バスはロサンゼルス空港とサンディエゴ空港の二つの空港で12月27日に用意しています。２台はLAX空港、１台はSan Diego空港です。バスの時間に間に合わない場合は自力で会場まで来ていただく必要があります。28日、29日、30日、31日には、空港から会場、会場から空港の送迎がないため、各自で送迎の手配が必要です。ロサンゼルス 空港から会場まで、片道3時間から4時間、サンディエゴ空港からは1時間半から2時間かかります。十分な余裕を持って到着されることをお勧めします。なお、到着時間によっては、長時間空港で待っていただく可能性があります。ご了承ください。
                  '}}
                  <!-- <div>
                    {{this.router.url.includes('EN')==true?
                    "For more information, please click "
                    :
                    "詳しくは"
                    }}
                    <p *ngIf="this.router.url.includes('EN')==true">
                      　<a
                        href='https://equipper.org/ec23/index.php?option=com_content&view=article&id=29&Itemid=225&lang=en'
                        target='_blank'>here</a>
                    </p>
                    <p *ngIf="this.router.url.includes('EN')!=true">
                      <a href='https://equipper.org/ec23/index.php?option=com_content&view=article&id=29&Itemid=225&lang=ja'
                        target='_blank'>こちら</a>
                    </p>
                    {{this.router.url.includes('EN')==true?
                    ". "
                    :
                    "をご覧ください。"
                    }}
                  </div> -->

                </div>

                <div class="stepTitle2nd">
                  {{this.router.url.includes('EN')==true?'1. Bus transportation from airport
                  to
                  venue (Fee: $50 one way)' :
                  '1. 空港から会場までのバスの送迎（有料 片道＄50) '}}
                  <i class="stepTitleSub">
                    {{this.router.url.includes('EN')==true?'Required': '必須'}}
                  </i>
                </div>
                <div>
                  <mat-radio-group formControlName="myNeedCommingBus" (change)="onChangeNeedCommingBusSelection($event)"
                    class="rbGroup">
                    <mat-radio-button value=false class="rbItem" [checked]="conference.NeedCommingBus==false">
                      {{this.router.url.includes('EN')==true?'No need (I will provide my own)':
                      '不要（自分で用意します）'}}</mat-radio-button>
                    <mat-radio-button value=true class="rbItem" [checked]="conference.NeedCommingBus==true">
                      {{this.router.url.includes('EN')==true?'Need': '必要'}}</mat-radio-button>
                  </mat-radio-group>
                </div>
                <br>
                <div *ngIf="showNeedCommingBus">
                  <div class="stepTitle2nd">
                    {{this.router.url.includes('EN')==true?'2.Bus Reservations(Be sure to enter by the final deadline 11/25.)'
                    :
                    '2. バスの予約（最終締切日11/25までに必ず入力してください。）'}}
                  </div>
                  <span class="wrap-mat-radio-label busType">
                    {{this.router.url.includes('EN')==true?'Bus from LAX Airport':
                    'LAX空港発バス'}}
                  </span><br>
                  <mat-radio-group formControlName="myCommingBusDeparturePlaceDateTime"
                    (change)="onChangeCommingBusDeparturePlaceDateTime($event)" class="rbGroup">
                    <mat-radio-button value="27LAX01" class="rbItem"
                      [checked]="conference.CommingBusDeparturePlace=='27LAX01'">
                      <span class="wrap-mat-radio-label">
                        {{this.router.url.includes('EN')==true?'Dec27 (27LAX01): Meet 12:00pm, Departure LAX 12:30pm':
                        '12月27日 (27LAX01号): 集合 12:00pm, LAX発12:30pm'}}
                      </span>
                    </mat-radio-button><br>
                    <mat-radio-button value="27LAX02" class="rbItem"
                      [checked]="conference.CommingBusDeparturePlace=='27LAX02'">
                      <span class="wrap-mat-radio-label">
                        {{this.router.url.includes('EN')==true?"Dec27 (27LAX02): Meet 5:00pm, Departure LAX 5:30pm (Please prepare your own dinner because you'll arrive after dinner time)":
                        '12月27日 (27LAX02号): 集合 5:00pm, LAX発5:30pm (夕食の時間には間に合いませんので各自でご用意ください。）'}}
                      </span>
                    </mat-radio-button><br>
                    <span class="wrap-mat-radio-label busType">
                      {{this.router.url.includes('EN')==true?'Bus from San Diego Airport':
                      'San Diego空港発バス'}}
                    </span><br>
                    <mat-radio-button value="27SAN01" class="rbItem"
                      [checked]="conference.CommingBusDeparturePlace=='27SAN01'">
                      <span class="wrap-mat-radio-label">
                        {{this.router.url.includes('EN')==true?"Dec27 (27SAN01): Meet 2:30pm, Departure SAN 3:00pm":
                        '12月27日 (27SAN01号): 集合 2:30pm, SAN発3:00pm'}}
                      </span>
                    </mat-radio-button><br>
                    <!-- <div class="rbItem">
                   {{this.router.url.includes('EN')==true?
                    'The bus leaving at 6:00 on the 28th will not make it to the evening session on the first day.t':
                    '28日6時発のバスは初日の夜の集会に間に合いません。'}}
                  </div> -->
                  </mat-radio-group>

                  <br><br>
                  <div class="stepTitle2nd">
                    {{this.router.url.includes('EN')==true?
                    '3 Confirmation'
                    :
                    '３　確認事項'}}
                    <i class="stepTitleSub">
                      {{this.router.url.includes('EN')==true?'Required': '必須'}}
                    </i><br>
                    {{this.router.url.includes('EN')==true?
                    'I will arrange my flight with enough time in mind to make it in time for the bus. If I cannot make
                    the bus, I will arrange my own transportation to and from my accommodation or the venue. （On the
                    29th,
                    30th, and 31st (even if due to flight delays), I will arrange my own transportation, as there will
                    be
                    no airport-to-venue or venue-to-airport transfers.'
                    :
                    '私は、バスに間に合うように十分な時間を考慮して飛行機の手配をします。また、バスに間に合わない場合は、自分で宿泊先、もしくは会場までの送迎の手配をします。（飛行機の遅延が理由であっても）29日、30日、31日には、空港から会場、会場から空港の送迎がないため、自分で送迎の手配をします。'}}
                    <br>


                    <mat-checkbox value='conference.BusComingConfirmation' formControlName="myBusComingConfirmation"
                      [checked]="conference.BusComingConfirmation==true" class="radioItemExtraSleepingBag"
                      (change)="onCheckBusComingConfirmation($event)">


                    </mat-checkbox>
                    <div class="checkComment">
                      {{this.router.url.includes('EN')==true?'By checking the box above, I express my consent.':
                      '上のチェックボックスをチェックすることによって私の同意を表明します。'}}
                    </div>



                  </div>
                  <br>
                  <div class="flightInfo">
                    <div class="stepTitle2nd">
                      {{this.router.url.includes('EN')==true?'4. Information on arrival flights' :
                      '4.到着便の情報'}}
                      <br>
                      {{this.router.url.includes('EN')==true?
                      'If you have not yet arranged your flight, please fill out the form by 11/25. Please note that seats on the bus are limited. We recommend that you fill out your flight
                      information as soon as possible, as seats will be allocated to those who fill out their flight
                      information early.'
                      :
                      '飛行機の手配がまだの方は、申込締切日の11月25日までに記入ください。なお、バスに席に限りがあります。早めに飛行機の情報を記入された方から席を確保いたしますので、なるべく早く情報記入することをお勧めします。'
                      }}
                    </div>
                    <mat-form-field appearance="outline" class="registrationInputForm">
                      <mat-label>
                        {{this.router.url.includes('EN')==true?'Airline company' :
                        '航空会社'}}
                      </mat-label>
                      <mat-select placeholder="" formControlName="myCommingAirline" class="registrationInputBoxDDL">
                        <mat-option *ngFor="let airline of airlines" [value]="airline" class="matInputBox">
                          {{ airline }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="registrationInputForm">
                      <mat-label>
                        {{this.router.url.includes('EN')==true?'Flight Number': '便名'}}
                      </mat-label>
                      <input matInput placeholder="" formControlName="myCommingFlightNo" class="registrationInputBox">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="registrationInputForm">
                      <mat-label>
                        {{this.router.url.includes('EN')==true?'Departure Airport Name': '出発空港名 '}}
                      </mat-label>
                      <input matInput placeholder="" formControlName="myDepartureCity" class="registrationInputBox">
                    </mat-form-field>
                    <div>
                      <mat-form-field appearance="outline" class="registrationInputForm">
                        <mat-label>
                          {{this.router.url.includes('EN')==true?'Arrival Date & Time:': '到着日 & 時刻'}}
                        </mat-label>
                        <input matInput type="datetime-local" placeholder="start date"
                          formControlName="myArrivalDateTime" (dateChange)="onChangeArrivalDateTime($event)">
                        <mat-error>
                          {{this.router.url.includes('EN')==true?'Date＆Time is required': '未入力'}}
                        </mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="registrationInputForm">
                        <mat-label>
                          {{this.router.url.includes('EN')==true?'International/Domestic' :
                          '国際線/国内線'}}
                        </mat-label>
                        <mat-select placeholder="" formControlName="myCommingInternationalDomestic"
                          class="registrationInputBoxDDL">
                          <div *ngIf="this.router.url.includes('EN')==true">
                            <mat-option *ngFor="let flightType of internationalDomesticEN" [value]="flightType"
                              class="matInputBox">
                              {{ flightType }}
                            </mat-option>
                          </div>
                          <div *ngIf="this.router.url.includes('EN')!=true">
                            <mat-option *ngFor="let flightType of internationalDomesticJP" [value]="flightType"
                              class="matInputBox">
                              {{ flightType }}
                            </mat-option>
                          </div>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
            <br>
            <mat-card *ngIf="loaded" class="mainCardBottom b-radius">
              <div class="stepTitle2nd">
                {{this.router.url.includes('EN')==true?'1. Bus transportation from the venue to Airport (Fee: $50 one way)'
                :
                '1.会場から空港までのバスの送迎（有料 片道＄50) '}}
                <i class="stepTitleSub">
                  {{this.router.url.includes('EN')==true?'Required': '必須'}}
                </i>
              </div>
              <div>
                <mat-radio-group formControlName="myNeedReturningBus"
                  (change)="onChangeNeedReturningBusSelection($event)" class="rbGroup">
                  <mat-radio-button value=false class="rbItem" [checked]="conference.NeedReturningBus==false">
                    {{this.router.url.includes('EN')==true?'No need (I will provide my own)':
                    '不要（自分で用意します）'}}</mat-radio-button>
                  <mat-radio-button value=true class="rbItem" [checked]="conference.NeedReturningBus==true">
                    {{this.router.url.includes('EN')==true?'Need': '必要'}}</mat-radio-button>
                </mat-radio-group>
              </div>
              <br>
              <div *ngIf="showNeedReturningBus">
                <div class="stepTitle2nd">
                  {{this.router.url.includes('EN')==true?
                  '2. Bus reservations (must be entered by final deadline 11/25)'
                  :
                  '2. バスの予約（最終締切日11/25までに必ず入力してください。））'}}
                </div>
                <span class="wrap-mat-radio-label busType">
                  {{this.router.url.includes('EN')==true?'The return bus will be via San Diego Airport to LAX. Please make an appointment well in advance.':
                  '帰りのバスはSan Diego空港経由、LAX行きとなります。時間に余裕をもって予約をしてください。'}}
                </span><br>
                <mat-radio-group formControlName="myReturningBusDeparturePlaceDateTime"
                  (change)="onChangeReturningBusDeparturePlaceDateTime($event)" class="rbGroup">
                  <mat-radio-button value="0101" class="rbItem" [checked]="conference.CommingBusDeparturePlace=='0101'">
                    <span class="wrap-mat-radio-label">
                      {{this.router.url.includes('EN')==true?
                      'Jan1 (0101): Meet 3:30am, Departure 4:00am (Estimated Time of Arrival: San Diego Airport 5:00am, LAX 08:00am)'
                      :
                      '1月1日 (0101号):集合 3:30am, 出発4:00am（到着推定時刻：San Diego空港 5:00am, LAX 08:00am）'}}
                    </span>
                  </mat-radio-button><br>
                  <mat-radio-button value="0102" class="rbItem" [checked]="conference.CommingBusDeparturePlace=='0102'">
                    <span class="wrap-mat-radio-label">
                      {{this.router.url.includes('EN')==true?
                      'Jan1 (0102): Meet 11:00am, Departure 11:30am (Estimated Time of Arrival: San Diego Airport 12:30pm, LAX 3:30pm)'
                      :
                      '1月1日 (0102号):集合11:00am, 出発11:30am（到着推定時刻：San Diego空港 12:30pm, LAX 3:30pm）'}}
                    </span>
                  </mat-radio-button><br>
                </mat-radio-group>
                <br>
                <div class="stepTitle2nd">
                  {{this.router.url.includes('EN')==true?
                  '3 Confirmation'
                  :
                  '３ 確認事項'}}
                  <i class="stepTitleSub">
                    {{this.router.url.includes('EN')==true?'Required': '必須'}}
                  </i><br>
                  {{this.router.url.includes('EN')==true?
                  'I will arrange my flight with sufficient time in mind. I also understand that I may need to wait at the airport for an extended period of time. In the case of flights that cannot be made in time by these buses, I will arrange my own transportation and accommodations.'
                  :
                  '私は、十分な時間を考慮して飛行機の手配をします。また、長時間空港で待つ必要がある可能性も理解します。これらのバスで間に合わない飛行機の場合、送迎と宿泊は自分で手配します。'}}
                  <br>
                  <mat-checkbox value='conference.BusReturningConfirmation' formControlName="myBusReturningConfirmation"
                    [checked]="conference.BusReturningConfirmation==true" class="radioItemExtraSleepingBag"
                    (change)="onCheckBusReturningConfirmation($event)">
                  </mat-checkbox>
                  <div class="checkComment">
                    {{this.router.url.includes('EN')==true?'By checking the box above, I express my consent.':
                    '上のチェックボックスをチェックすることによって私の同意を表明します。'}}
                  </div>
                </div>
                <br>

                <div class="flightInfo">
                  <div class="stepTitle2nd">
                    {{this.router.url.includes('EN')==true?'4. Departure Information'
                    :
                    '4. 出発の情報'}}
                    {{this.router.url.includes('EN')==true?
                    'If you have not yet arranged your flight, please fill out the form by the application deadline,
                    December 9. Please note that seats on the bus are limited. We recommend that you fill out your
                    flight
                    information as soon as possible, as seats will be allocated to those who fill out their flight
                    information early.'
                    :
                    '飛行機の手配がまだの方は、申込締切日の11月25日までに記入ください。なお、バスに席に限りがあります。早めに飛行機の情報を記入された方から席を確保いたしますので、なるべく早く情報記入することをお勧めします。'
                    }}

                  </div>
                  <mat-form-field appearance="outline" class="registrationInputForm">
                    <mat-label>
                      {{this.router.url.includes('EN')==true?'Airline company' :
                      '航空会社'}}
                    </mat-label>
                    <mat-select placeholder="" formControlName="myReturningAirline" class="registrationInputBoxDDL">
                      <mat-option *ngFor="let airline of airlines" [value]="airline" class="matInputBox">
                        {{ airline }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="registrationInputForm">
                    <mat-label>
                      {{this.router.url.includes('EN')==true?'Flight Number': '便名'}}
                    </mat-label>
                    <input matInput placeholder="" formControlName="myReturningFlightNo" class="registrationInputBox">
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="registrationInputForm">
                    <mat-label>
                      {{this.router.url.includes('EN')==true?'Departure Airport Name': '出発空港名'}}
                    </mat-label>
                    <input matInput placeholder="" formControlName="myReturningDepartureCity" class="registrationInputBox">
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="registrationInputForm">
                    <mat-label>
                      {{this.router.url.includes('EN')==true?'Destination Airport Name': '目的空港名'}}
                    </mat-label>
                    <input matInput placeholder="" formControlName="myReturningCity" class="registrationInputBox">
                  </mat-form-field>
                  <div>
                    <mat-form-field appearance="outline" class="registrationInputForm">
                      <mat-label>
                        {{this.router.url.includes('EN')==true?'Departure Date & Time:': '出発日 & 時刻'}}
                      </mat-label>
                      <input matInput type="datetime-local" placeholder="start date"
                        formControlName="myDepartureDateTime" (dateChange)="onChangeDepartureDateTime($event)">
                      <mat-error>
                        {{this.router.url.includes('EN')==true?'Date＆Time is required': '未入力'}}
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="registrationInputForm">
                      <mat-label>
                        {{this.router.url.includes('EN')==true?'International/Domestic' :
                        '国際線/国内線'}}
                      </mat-label>
                      <mat-select placeholder="" formControlName="myReturningInternationalDomestic"
                        class="registrationInputBoxDDL">
                        <div *ngIf="this.router.url.includes('EN')==true">
                          <mat-option *ngFor="let flightType of internationalDomesticEN" [value]="flightType"
                            class="matInputBox">
                            {{ flightType }}
                          </mat-option>
                        </div>
                        <div *ngIf="this.router.url.includes('EN')!=true">
                          <mat-option *ngFor="let flightType of internationalDomesticJP" [value]="flightType"
                            class="matInputBox">
                            {{ flightType }}
                          </mat-option>
                        </div>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </mat-card>


            <div class="d-flex justify-content-center">
              <div class="processButtonSet">
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn btnProcess" (click)="onClickGoBack()">
                    {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                  </button>
                  <button [disabled]="!myFormGroup.valid" type="button" class="btn btnProcess"
                    (click)="onClickGoForward()">
                    {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                  </button>
                </div>
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn link btnCancel" (click)="cancelProcess()">
                    {{this.router.url.includes('EN')==true?'Cancel': 'キャンセル'}}
                  </button>
                </div>
              </div>
            </div>

          </form>
        </mat-card>
      </div>
      <div class="myMainHalfBottom">
        <!-- Main Bottom -->
      </div>
    </div>
    <div class="myMainRight">
      <div class="myMainHalfTop">
        <!-- Right Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Right Bottom -->
      </div>
    </div>
  </div>
  <!-- <footer>footer</footer> -->
</div>
