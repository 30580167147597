<div class="RegistrationBase">
 <div class="bg-layer">
   <div class="RegistTitle">
     <h2>
       <i class="fas fa-clipboard fa-2x"></i>&nbsp;
       {{this.router.url.includes('EN')==true?'User Information': 'ユーザー情報'}}<br>
     </h2>
     <div *ngIf="user!=null && user.RegisteredDate!=null">
       <h2>
         {{user.FirstName}}&nbsp;{{user.LastName}}
       </h2>
     </div>
   </div>
   <div class="d-flex justify-content-center" id="stepperPersonal">
     <mat-horizontal-stepper labelPosition="bottom" #stepper linear class="stepperBase">

       <mat-step [stepControl]="firstFormGroup">
         <div *ngIf="!loaded">
           <mat-progress-bar mode="indeterminate"></mat-progress-bar>
         </div>
         <div *ngIf="loaded">
           <form [formGroup]="firstFormGroup">
             <ng-template matStepLabel>
               {{this.router.url.includes('EN')==true?'My Info:': '個人情報：'}}</ng-template>
             <mat-card class="RegistrationCardList">
               <mat-card-header>
                 <mat-card-title>
                   {{this.router.url.includes('EN')==true?'Personal Information:': '個人情報：'}}
                 </mat-card-title>
                 <mat-card-subtitle>
                   {{this.router.url.includes('EN')==true?'* ... Required': '* ... 必須項目'}}
                 </mat-card-subtitle>
               </mat-card-header>

               <mat-form-field appearance="outline" class="registrationInputForm">
                 <mat-label>
                   {{this.router.url.includes('EN')==true?'Last Name': '姓　ローマ字'}}
                 </mat-label>
                 <input matInput placeholder="" formControlName="myLastName" [(ngModel)]="user.LastName" required
                   class="registrationInputBox">
                 <mat-error>
                   {{this.router.url.includes('EN')==true?'Last Name is required': '未入力'}}
                 </mat-error>
               </mat-form-field>

               <mat-form-field appearance="outline" class="registrationInputForm">
                 <mat-label>
                   {{this.router.url.includes('EN')==true?'First Name': '名　ローマ字'}}
                 </mat-label>
                 <input matInput placeholder="" formControlName="myFirstName" [(ngModel)]="user.FirstName" required
                   class="registrationInputBox">
                 <mat-error>
                   {{this.router.url.includes('EN')==true?'First Name is required': '未入力'}}
                 </mat-error>
               </mat-form-field>

               <mat-form-field appearance="outline" class="registrationInputForm">
                 <mat-label>
                   {{this.router.url.includes('EN')==true?'Kanji- Last Name': '姓　漢字'}}
                 </mat-label>
                 <input matInput placeholder="" formControlName="myMyoji" [(ngModel)]="user.Myoji"
                   class="registrationInputBox">
               </mat-form-field>

               <mat-form-field appearance="outline" class="registrationInputForm">
                 <mat-label>
                   {{this.router.url.includes('EN')==true?'Kanji- First Name': '名　漢字'}}
                 </mat-label>
                 <input matInput placeholder="" formControlName="myShimei" [(ngModel)]="user.Shimei"
                   class="registrationInputBox">
               </mat-form-field>

               <mat-form-field appearance="outline" class="registrationInputForm">
                 <mat-label>
                   {{this.router.url.includes('EN')==true?'Email': 'Eメール'}}
                 </mat-label>
                 <input matInput placeholder="pat@example.com" formControlName="myEmail" [(ngModel)]="user.Email"
                   required (change)="onEmailChangeEvent($event)" class="registrationInputBox"
                   [readonly]="this.authService.isLoggedIn()">
                 <mat-error *ngIf="firstFormGroup.get('myEmail').hasError('required')">
                   {{this.router.url.includes('EN')==true?'Email is required': '未入力'}}
                 </mat-error>
                 <mat-error *ngIf="firstFormGroup.get('myEmail').hasError('email')">
                   {{this.router.url.includes('EN')==true?'Invalid Email format': 'Eメールフォーマットが正しくありません'}}
                 </mat-error>
               </mat-form-field>

               <div *ngIf="!this.authService.isLoggedIn()">
                 <mat-form-field appearance="fill" hintLabel="Min 6 characters" class="registrationInputForm">
                   <mat-label>
                     {{this.router.url.includes('EN')==true?'Password': 'パスワード'}}
                   </mat-label>
                   <input matInput placeholder="" formControlName="myPassword" [(ngModel)]="user.Password"
                     [type]="hideMyPassword ? 'password' : 'text'" required minlength="6" #myInputPassword
                     class="registrationInputBox">
                   <mat-hint align="end">{{myInputPassword.value?.length || 0}} characters</mat-hint>
                   <button mat-icon-button matSuffix (click)="hideMyPassword = !hideMyPassword"
                     [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideMyPassword">
                     <mat-icon>{{hideMyPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                   </button>
                   <mat-error *ngIf="firstFormGroup.get('myPassword').hasError('required')">
                     {{this.router.url.includes('EN')==true?'Password is required': '未入力'}}
                   </mat-error>
                   <mat-error *ngIf="firstFormGroup.get('myPassword').hasError('minlength')">
                     {{this.router.url.includes('EN')==true?'Need 6 characters or more': '6文字以上必要です'}}
                   </mat-error>
                 </mat-form-field>
               </div>

               <mat-form-field appearance="outline" class="registrationInputForm">
                 <mat-label>
                   {{this.router.url.includes('EN')==true?'Tel#': '電話番号'}}
                 </mat-label>
                 <input matInput placeholder="" formControlName="myTelNo" [(ngModel)]="user.TelNo"
                   class="registrationInputBox">
                 <!-- <button mat-button *ngIf="user.TelNo" matSuffix mat-icon-button aria-label="Clear" (click)="user.TelNo=''">
                                 <mat-icon>close</mat-icon>
                               </button> -->
               </mat-form-field>

               <div>
                 <div class="stepTitle">
                   <label>
                     {{this.router.url.includes('EN')==true?'Gender *:': '性別 *：'}}
                   </label>
                 </div>
                 <div class="registrationInputFormLg">
                   <mat-radio-group formControlName="myGender" [(ngModel)]="user.Gender"
                     (change)="onChangeGenderSelection($event)" class="radioBtnMain">
                     <mat-radio-button value="Male" [checked]="user.Gender=='Male'" class="radioBtnItem">
                       {{this.router.url.includes('EN')==true?'Male': '男性'}}</mat-radio-button>
                     <mat-radio-button value="Female" [checked]="user.Gender=='Female'" class="radioBtnItem">
                       {{this.router.url.includes('EN')==true?'Female': '女性'}}</mat-radio-button>
                   </mat-radio-group>
                   <mat-error *ngIf="user.Gender==null && clickedNextFromFirstFormGroup==true ">
                     {{this.router.url.includes('EN')==true?'Gender is required': '未入力'}}
                   </mat-error>
                 </div>
               </div>

               <div>
                 <div class="stepTitle">
                   <label>
                     {{this.router.url.includes('EN')==true?'Date of birth *:': '生年月日 *：'}}
                   </label><br>
                 </div>
                 <mat-form-field appearance="outline" class="registrationInputForm">
                   <mat-label>
                     {{this.router.url.includes('EN')==true?'Date of Birth': '生年月日'}}
                   </mat-label>
                   <input matInput placeholder="MM/dd/yyyy" formControlName="myDOB" [(ngModel)]="user.DOB" required
                     (dateChange)="onChangeDOB($event)" [matDatepicker]="DOB" />
                   <mat-datepicker-toggle matSuffix [for]="DOB"></mat-datepicker-toggle>
                   <mat-datepicker #DOB></mat-datepicker>
                   <mat-error>
                     {{this.router.url.includes('EN')==true?'DOB is required': '未入力'}}
                   </mat-error>
                 </mat-form-field>
               </div>
             </mat-card>

             <mat-card class="RegistrationCardList">
               <div class="groupAddressHeader">
                 <mat-card-header>
                   <mat-card-title>
                     {{this.router.url.includes('EN')==true?'Address:': '住所：'}}
                   </mat-card-title>
                   <mat-card-subtitle>
                     {{this.router.url.includes('EN')==true?'* ... Required': '* ... 必須項目'}}
                   </mat-card-subtitle>
                 </mat-card-header>
               </div>

               <div class="registrationInputFormLg">
                 <mat-radio-group formControlName="myCountry" [(ngModel)]="user.Country"
                   (change)="onChangeCountrySelection($event)" class="radioBtnMain" required>
                   <mat-radio-button class="radioBtnItem" value="Japan">
                     {{this.router.url.includes('EN')==true?'Japan': '日本'}}</mat-radio-button>
                   <mat-radio-button class="radioBtnItem" value="United States">
                     {{this.router.url.includes('EN')==true?'United
                     States': 'アメリカ'}}</mat-radio-button>
                   <!-- <mat-radio-button class="radioBtnItem" [value]="user.Country"  [(ngModel)]="flagCountryOther">
                     {{this.router.url.includes('EN')==true?'Other': '他'}}
                   </mat-radio-button> -->
                   <mat-radio-button class="radioBtnItem" [value]=""  >
                    {{this.router.url.includes('EN')==true?'Other': '他'}}
                  </mat-radio-button>
                 </mat-radio-group>
                 <mat-error *ngIf="user.Country==null && clickedNextFromFirstFormGroup==true ">
                   {{this.router.url.includes('EN')==true?'Country is required': '未入力'}}
                 </mat-error>
               </div>

               <div *ngIf="showUSAddress">
                 <mat-form-field appearance="outline" class="registrationInputForm">
                   <mat-label>
                     {{this.router.url.includes('EN')==true?'Street': 'ストリート'}}
                   </mat-label>
                   <input matInput placeholder="" formControlName="myStreet" [(ngModel)]="user.Street" required
                     class="registrationInputBox">
                   <mat-error>
                     {{this.router.url.includes('EN')==true?'Street is required': '未入力'}}
                   </mat-error>
                 </mat-form-field>

                 <mat-form-field appearance="outline" class="registrationInputForm">
                   <mat-label>
                     {{this.router.url.includes('EN')==true?'City': '市'}}
                   </mat-label>
                   <input matInput placeholder="" formControlName="myCity" [(ngModel)]="user.City" required
                     class="registrationInputBox">
                   <mat-error>
                     {{this.router.url.includes('EN')==true?'City is required': '未入力'}}
                   </mat-error>
                 </mat-form-field>

                 <mat-form-field appearance="outline" class="registrationInputForm">
                   <mat-label>
                     {{this.router.url.includes('EN')==true?'State': '州'}}
                   </mat-label>
                   <mat-select placeholder="" formControlName="myState" [(ngModel)]="user.State" required
                     class="registrationInputBoxDDL">
                     <mat-option *ngFor="let state of states" [value]="state.value" class="matInputBox">
                       {{ state.display }}
                     </mat-option>
                   </mat-select>
                   <mat-error>
                     {{this.router.url.includes('EN')==true?'State is required': '未入力'}}
                   </mat-error>
                 </mat-form-field>

                 <mat-form-field appearance="outline" class="registrationInputForm">
                   <mat-label>
                     {{this.router.url.includes('EN')==true?'Zip': 'Zip'}}
                   </mat-label>
                   <input matInput placeholder="" formControlName="myZip" [(ngModel)]="user.Zip"
                     class="registrationInputBox">
                   <mat-error>
                     {{this.router.url.includes('EN')==true?'Zip is required': '未入力'}}
                   </mat-error>
                 </mat-form-field>

                 <!-- <mat-form-field appearance="outline" class="registrationInputForm">
               <mat-label>
                 {{this.router.url.includes('EN')==true?'Country': '国'}}
               </mat-label>
               <input matInput placeholder="" formControlName="myCountry" [(ngModel)]="user.Country"
                 class="registrationInputBox" readonly>
               <mat-error>
                 {{this.router.url.includes('EN')==true?'Country is required': '未入力'}}
               </mat-error>
             </mat-form-field> -->
               </div>

               <div *ngIf="showJapanAddress">
                 <mat-form-field appearance="outline" class="registrationInputForm">
                   <mat-label>
                     {{this.router.url.includes('EN')==true?'Yubin Bango (=Zip)': '郵便番号'}}
                   </mat-label>
                   <input matInput placeholder="" formControlName="myYubin_Bango" [(ngModel)]="user.Yubin_Bango"
                     class="registrationInputBox">
                   <mat-error>
                     {{this.router.url.includes('EN')==true?'Yubin Bango is required': '未入力'}}
                   </mat-error>
                 </mat-form-field>

                 <mat-form-field appearance="outline" class="registrationInputForm">
                   <mat-label>
                     {{this.router.url.includes('EN')==true?'ToDoFuKen (=State)': '都道府県'}}
                   </mat-label>
                   <div *ngIf="this.router.url.includes('EN')==true">
                     <mat-select placeholder="" formControlName="myTo_Do_Fu_Ken" [(ngModel)]="user.To_Do_Fu_Ken"
                       class="registrationInputBox">
                       <mat-option *ngFor="let to_do_fu_ken of to_do_fu_kensEN" [value]="to_do_fu_ken.value"
                         class="matInputBox">
                         {{ to_do_fu_ken.display }}
                       </mat-option>
                     </mat-select>
                   </div>
                   <div *ngIf="this.router.url.includes('EN')==false">
                     <mat-select placeholder="" formControlName="myTo_Do_Fu_Ken" [(ngModel)]="user.To_Do_Fu_Ken"
                       class="registrationInputBox">
                       <mat-option *ngFor="let to_do_fu_ken of to_do_fu_kensJP" [value]="to_do_fu_ken.value"
                         class="matInputBox">
                         {{ to_do_fu_ken.display }}
                       </mat-option>
                     </mat-select>
                   </div>
                   <mat-error>
                     {{this.router.url.includes('EN')==true?'To_Do_Fu_Ken is required': '未入力'}}
                   </mat-error>
                 </mat-form-field>

                 <mat-form-field appearance="outline" class="registrationInputForm">
                   <mat-label>
                     {{this.router.url.includes('EN')==true?'Shi_Gun_Ku (=City)': '市郡区'}}
                   </mat-label>
                   <input matInput placeholder="" formControlName="myShi_Gun_Ku" [(ngModel)]="user.Shi_Gun_Ku"
                     class="registrationInputBox">
                   <mat-error>
                     {{this.router.url.includes('EN')==true?'Shi_Gun_Ku is required': '未入力'}}
                   </mat-error>
                 </mat-form-field>

                 <mat-form-field appearance="outline" class="registrationInputForm">
                   <mat-label>
                     {{this.router.url.includes('EN')==true?'Cho_Son (=Street)': '町村　丁目番地'}}
                   </mat-label>
                   <input matInput placeholder="" formControlName="myCho_Son" [(ngModel)]="user.Cho_Son"
                     class="registrationInputBox">
                   <mat-error>
                     {{this.router.url.includes('EN')==true?'Cho_Son is required': '未入力'}}
                   </mat-error>
                 </mat-form-field>

                 <mat-form-field appearance="outline" class="registrationInputForm">
                   <mat-label>
                     {{this.router.url.includes('EN')==true?'Apartment_Etc': 'アパート名等'}}
                   </mat-label>
                   <input matInput placeholder="" formControlName="myApartment_Etc" [(ngModel)]="user.Apartment_Etc"
                     class="registrationInputBox">
                 </mat-form-field>
               </div>

               <div *ngIf="showOtherAddress">
                 <mat-form-field appearance="outline" class="registrationInputForm">
                   <mat-label>
                     {{this.router.url.includes('EN')==true?'Street': 'ストリート'}}
                   </mat-label>
                   <input matInput placeholder="" formControlName="myStreet" [(ngModel)]="user.Street" required
                     class="registrationInputBox">
                   <mat-error>
                     {{this.router.url.includes('EN')==true?'Street is required': '未入力'}}
                   </mat-error>
                 </mat-form-field>

                 <mat-form-field appearance="outline" class="registrationInputForm">
                   <mat-label>
                     {{this.router.url.includes('EN')==true?'City': '市'}}
                   </mat-label>
                   <input matInput placeholder="" formControlName="myCity" [(ngModel)]="user.City" required
                     class="registrationInputBox">
                   <mat-error>
                     {{this.router.url.includes('EN')==true?'City is required': '未入力'}}
                   </mat-error>
                 </mat-form-field>

                 <mat-form-field appearance="outline" class="registrationInputForm">
                   <mat-label>
                     {{this.router.url.includes('EN')==true?'State': '州'}}
                   </mat-label>
                   <!-- <mat-select placeholder="" formControlName="myState" [(ngModel)]="user.State" required
                 class="registrationInputBoxDDL">
                 <mat-option *ngFor="let state of states" [value]="state.value" class="matInputBox">
                   {{ state.display }}
                 </mat-option>
               </mat-select> -->
                   <input matInput placeholder="" formControlName="myState" [(ngModel)]="user.State" required
                     class="registrationInputBox">
                   <mat-error>
                     {{this.router.url.includes('EN')==true?'State is required': '未入力'}}
                   </mat-error>
                 </mat-form-field>

                 <mat-form-field appearance="outline" class="registrationInputForm">
                   <mat-label>
                     {{this.router.url.includes('EN')==true?'Zip': 'Zip'}}
                   </mat-label>
                   <input matInput placeholder="" formControlName="myZip" [(ngModel)]="user.Zip"
                     class="registrationInputBox">
                   <mat-error>
                     {{this.router.url.includes('EN')==true?'Zip is required': '未入力'}}
                   </mat-error>
                 </mat-form-field>

                 <mat-form-field appearance="outline" class="registrationInputForm">
                   <mat-label>
                     {{this.router.url.includes('EN')==true?'Country': '国'}}
                   </mat-label>
                   <input matInput placeholder="" formControlName="myCountry" [(ngModel)]="user.Country"
                     class="registrationInputBox"  (change)="onUpdateOtherCountry(user.Country)">
                   <mat-error>
                     {{this.router.url.includes('EN')==true?'Country is required': '未入力'}}
                   </mat-error>
                 </mat-form-field>
               </div>
             </mat-card>

             <!-- <mat-card class="RegistrationCardList">
              <mat-card-header>
                <mat-card-title>
                  {{this.router.url.includes('EN')==true?'Personal Information:': 'メンバーシップタイプ：'}}
                </mat-card-title>
              </mat-card-header>

              <mat-accordion>
                <mat-expansion-panel hideToggle>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{this.router.url.includes('EN')==true?'Japanese Adult Program': '一般プログラム'}}
                    </mat-panel-title>
                    <mat-panel-description>
                      This is a summary of the content
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <p>This is the primary content of the panel.</p>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Self aware panel
                    </mat-panel-title>
                    <mat-panel-description>
                      Currently I am {{panelOpenState ? 'open' : 'closed'}}
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <p>I'm visible because I am open</p>
                </mat-expansion-panel>
              </mat-accordion>

            </mat-card>         -->

             <div class="row">
               <div class="stepperButtonSection">
                 <button mat-button matStepperNext (click)="goFromFirstFormGroup()" class="btnMatStep"
                   cdkFocusInitial>{{this.router.url.includes('EN')==true?'Next':
                   '次へ'}}</button>
               </div>
               <div *ngIf="clickedNextFromFirstFormGroup==true && firstFormGroup.status=='INVALID'"
                 class="invalidComment">
                 {{this.router.url.includes('EN')==true?'There is something incomplete': '未入力があります'}}
               </div>
             </div>
           </form>
         </div>
       </mat-step>

       <mat-step [stepControl]="thirdFormGroup">
         <form [formGroup]="thirdFormGroup">
           <mat-card class="RegistrationCardList">
             <ng-template matStepLabel>
               {{this.router.url.includes('EN')==true?'Summary': 'サマリー'}}
             </ng-template>
             <div class="jumbotron p-3 p-md-5 text-white rounded bg-dark" id="registrationInfoSummaryHeader">
               <div class="col-md-6 px-0">
                 <h4>{{this.router.url.includes('EN')==true?'Summary': 'サマリー'}}</h4>
               </div>
             </div>

             <div class="registrationInfoSummaryItem">
               <h4> {{this.router.url.includes('EN')==true?'Personal Information:': '個人情報：'}}</h4>
               <div class="row summaryTable">
                 <div class="col-10 col-sm-3 col-md-2">
                   <div class="summaryTitle">
                     {{this.router.url.includes('EN')==true?'Last Name': '姓（ローマ字）'}}
                   </div>
                   <div class="summaryValue">
                     {{user.LastName}}
                   </div>
                 </div>
                 <div class="col-10 col-sm-3 col-md-2">
                   <div class="summaryTitle">
                     {{this.router.url.includes('EN')==true?'First Name': '名 (ローマ字)'}}
                   </div>
                   <div class="summaryValue">
                     {{user.FirstName}}
                   </div>
                 </div>
               </div>
               <div class="row summaryTable">
                 <div class="col-10 col-sm-3 col-md-2">
                   <div class="summaryTitle">
                     {{this.router.url.includes('EN')==true?'Kanji - Last Name': '姓（漢字）'}}
                   </div>
                   <div class="summaryValue">
                     {{user.Myoji}}
                   </div>
                 </div>
                 <div class="col-10 col-sm-3 col-md-2">
                   <div class="summaryTitle">
                     {{this.router.url.includes('EN')==true?'Kanji - First Name': '名 (漢字)'}}
                   </div>
                   <div class="summaryValue">
                     {{user.Shimei}}
                   </div>
                 </div>
               </div>
               <div class="row summaryTable">
                 <div class="col-10 col-sm-3 col-md-4">
                   <div class="summaryTitle">
                     {{this.router.url.includes('EN')==true?'Email': 'Eメール'}}
                   </div>
                   <div class="summaryValue">
                     {{user.Email}}
                   </div>
                 </div>
                 <div class="col-10 col-sm-3 col-md-3">
                   <div class="summaryTitle">
                     {{this.router.url.includes('EN')==true?'Tel#': '電話番号'}}
                   </div>
                   <div class="summaryValue">
                     {{user.TelNo }}
                   </div>
                 </div>
               </div>


               <div class="row summaryTable">
                 <div class="col-10 col-sm-3 col-md-4">
                   <div class="summaryTitle">
                     {{this.router.url.includes('EN')==true?'Gender': '性別'}}
                   </div>
                   <div class="summaryValue">
                     {{user.Gender}}
                   </div>
                 </div>
                 <div class="col-10 col-sm-3 col-md-3">
                   <div class="summaryTitle">
                     {{this.router.url.includes('EN')==true?'DOB': '生年月日'}}
                   </div>
                   <div class="summaryValue">
                     {{user.DOB | date: 'MM/dd/yy' }}
                   </div>
                 </div>
               </div>

               <div class="divEditButton">
                 <button class="btn btn-info btn-lg btnEdit float-right" (click)="goSpecificStepForEdit(1,stepper)">
                   {{this.router.url.includes('EN')==true?'Edit': '編集'}}
                 </button>
                 <div *ngIf=" !user.Gender ||  !user.DOB " class="float-right missingRequiredInfo">
                   {{this.router.url.includes('EN')==true?'Required Entry Missing': '必要項目が入力されていません'}}
                 </div>
               </div>
             </div>


             <div class="registrationInfoSummaryItem">
               <h4> {{this.router.url.includes('EN')==true?'Address:': '住所：'}}</h4>

               <div *ngIf="user.Country!='Japan'">
                 <div class="row summaryTable">
                   <div class="col-6 col-sm-3 col-md-2">
                     <div class="summaryTitle">
                       {{this.router.url.includes('EN')==true?'Country': '国'}}:
                     </div>
                     <div class="summaryValue">
                       {{user.Country}}
                     </div>
                   </div>
                   <div class="col-6 col-sm-3 col-md-2">
                     <div class="summaryTitle">
                       {{this.router.url.includes('EN')==true?'Street': 'ストリート'}}
                     </div>
                     <div class="summaryValue">
                       {{user.Street}}
                     </div>
                   </div>
                   <div class="col-6 col-sm-3 col-md-2">
                     <div class="summaryTitle">
                       {{this.router.url.includes('EN')==true?'City': '市'}}
                     </div>
                     <div class="summaryValue">
                       {{user.City}}
                     </div>
                   </div>
                   <div class="col-6 col-sm-3 col-md-1">
                     <div class="summaryTitle">
                       {{this.router.url.includes('EN')==true?'State': '州'}}
                     </div>
                     <div class="summaryValue">
                       {{user.State}}
                     </div>
                   </div>
                   <div class="col-6 col-sm-3 col-md-1">
                     <div class="summaryTitle">
                       {{this.router.url.includes('EN')==true?'Zip': 'Zip'}}
                     </div>
                     <div class="summaryValue">
                       {{user.Zip}}
                     </div>
                   </div>
                 </div>
               </div>
               <div *ngIf="user.Country=='Japan'">
                 <div class="row summaryTable">
                   <div class="col-10 col-sm-3 col-md-3">
                     <div class="summaryTitle">
                       {{this.router.url.includes('EN')==true?'Country': '国'}}:
                     </div>
                     <div class="summaryValue">
                       日本
                     </div>
                   </div>
                   <div class="col-10 col-sm-3 col-md-3">
                     <div class="summaryTitle">
                       {{this.router.url.includes('EN')==true?'Yubin Bango': '郵便番号'}}
                     </div>
                     <div class="summaryValue">
                       {{user.Yubin_Bango}}
                     </div>
                   </div>
                   <div class="col-10 col-sm-3 col-md-4">
                     <div class="summaryTitle">
                       {{this.router.url.includes('EN')==true?'To_Do_Fu_Ken': '都道府県'}}
                     </div>
                     <div class="summaryValue">
                       {{user.To_Do_Fu_Ken}}
                     </div>
                   </div>
                 </div>
                 <div class="row summaryTable">
                   <div class="col-10 col-sm-3 col-md-3">
                     <div class="summaryTitle">
                       {{this.router.url.includes('EN')==true?'Shi_Gun_Ku': '市郡区'}}
                     </div>
                     <div class="summaryValue">
                       {{user.Shi_Gun_Ku}}
                     </div>
                   </div>
                   <div class="col-10 col-sm-3 col-md-4">
                     <div class="summaryTitle">
                       {{this.router.url.includes('EN')==true?'Cho_Son': '町村'}}
                     </div>
                     <div class="summaryValue">
                       {{user.Cho_Son}}
                     </div>
                   </div>
                   <div class="col-10 col-sm-3 col-md-4">
                     <div class="summaryTitle">
                       {{this.router.url.includes('EN')==true?'Apartment etc': 'アパート名等'}}
                     </div>
                     <div class="summaryValue">
                       {{user.Apartment_Etc}}
                     </div>
                   </div>
                 </div>
               </div>

               <div class="divEditButton">
                 <button class="btn btn-info btn-lg btnEdit float-right" (click)="goSpecificStepForEdit(1,stepper)">
                   {{this.router.url.includes('EN')==true?'Edit': '編集'}}
                 </button>
                 <div *ngIf="user.Country!='Japan' && (!user.Street || !user.City || !user.State || !user.Zip)"
                   class="float-right missingRequiredInfo">
                   {{this.router.url.includes('EN')==true?'Required Entry Missing': '必要項目が入力されていません'}}
                 </div>
                 <div
                   *ngIf="(user.Country=='Japan') && (!user.Yubin_Bango || !user.To_Do_Fu_Ken || !user.Shi_Gun_Ku || !user.Cho_Son )"
                   class="float-right missingRequiredInfo">
                   {{this.router.url.includes('EN')==true?'Required Entry Missing': '必要項目が入力されていません'}}
                 </div>
               </div>
             </div>
             <hr>

           </mat-card>
           <br>
           <div *ngIf="!loaded">
             <mat-progress-bar mode="indeterminate"></mat-progress-bar>
           </div>
           <div *ngIf="!loaded" class="progressMessage">
             {{this.router.url.includes('EN')==true?'Saving...': '保存中...'}}
           </div>
           <div class="row">

             <div class="stepperButtonSection">
               <button mat-button matStepperPrevious class="btnMatStep">{{this.router.url.includes('EN')==true?'Back':
                 '戻る'}}</button>
               <button mat-button (click)="savingPersonalInfo()"
                 class="btnMatStep">{{this.router.url.includes('EN')==true?'Submit': '送信'}}</button>
             </div>
           </div>
         </form>
       </mat-step>

       <mat-step [stepControl]="fifthFormGroup">
         <form [formGroup]="fifthFormGroup">
           <ng-template matStepLabel>Done</ng-template>
           <div class="d-flex justify-content-center">
             <mat-card class="RegistrationComplete">
               <div class="d-flex justify-content-center">
                 <label class="CompleteComment">
                   <h4>{{this.router.url.includes('EN')==true?'Personal Info updated
                     successfully.':'個人情報がアップデイトされました。'}}</h4>

                   <br>
                 </label>
               </div>
               <!-- <div class="d-flex justify-content-center btnCancel">
               <a [routerLink]="['/login']" class="btn btn-link ">
                 {{this.router.url.includes('EN')==true?'Back to home':'スタートに戻る'}}
               </a>
             </div> -->
             </mat-card>
           </div>
         </form>
       </mat-step>

     </mat-horizontal-stepper>
   </div>
 </div>
</div>
