import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { AuthService } from "../../../../services/auth/auth.service";
import { User } from "../../../../models/user";
import { UserService } from "../../../../services/user/user.service";
import { MatRadioChange } from '@angular/material/radio';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PayByPaypalComponent } from 'src/app/components/dialog/pay-by-paypal/pay-by-paypal.component';
import { RegistrationPersonalEmbedComponent } from 'src/app/components/dialog/EC/registration-personal-embed/registration-personal-embed.component';
import { RegistrationConferenceEmbedComponent } from 'src/app/components/dialog/EC/registration-conference-embed/registration-conference-embed.component';
import { RegistrationPaymentEmbedComponent } from 'src/app/components/dialog/EC/registration-payment-embed/registration-payment-embed.component';
import { SubProfile } from 'src/app/models/conference/subProfile';
import { CheckGroup } from 'src/app/models/checkGroup';
import { ColumnGroup, MemberListGridTemplate } from 'src/app/models/memberListGridTemplate';
import { GRCConference, GRCRelatedPerson, GRCVolunteerSelection } from 'src/app/models/conference/GRCConference';
import { GrcRegistrationService } from 'src/app/services/conference/grc/grc-registration.service';
import { RegistrationConferenceEmbedGRCComponent } from 'src/app/components/dialog/GRC/registration-conference-embed-grc/registration-conference-embed-grc.component';
export interface DialogData {
  Id: string
}
interface MyList{
	display:string;
  value:string;
}

@Component({
  selector: 'app-my-account-grc',
  templateUrl: './my-account-grc.component.html',
  styleUrls: ['./my-account-grc.component.css']
})
export class MyAccountGRCComponent implements OnInit {
  myFormGroup:FormGroup;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  myMessage:string;
  step = 0;
  mySelectedLanguage: string;
  loaded: boolean = true;
  paymentProcessing:boolean=false;
  searchingRegistrant: string;
  showCreditCardPayment:boolean=false;
  showBankPayment:boolean=false;
  deleting:boolean=false;
  strCommittee:string;
  firstFormGroup: FormGroup;
  isMinor:boolean=false;
  myOriginallySelectedVolunteers:GRCVolunteerSelection[]=[];
  myApprovedVolunteers:GRCVolunteerSelection[]=[];

  user: User = {
    PasswordHash: null,
    Id: null,
    UserName: null,
    Email: null,
    FirstName: null,
    LastName: null,
    Password: null,
    Prefix: null,
    Shimei: null,
    Myoji: null,
    Street: null,
    Street2: null,
    City: null,
    State: null,
    Zip: null,
    Country: null,
    Yubin_Bango: null,
    To_Do_Fu_Ken: null,
    Shi_Gun_Ku: null,
    Cho_Son: null,
    Apartment_Etc: null,
    To_Do_Fu_Ken_JP: null,
    Shi_Gun_Ku_JP: null,
    Cho_Son_JP: null,
    Apartment_Etc_JP: null,
    Gender: null,
    DOB: null,
    TelNo: null,
    TimezoneName: null,
    UTCdiff: null,
    Token: null,
    Occupation: null,
    MemberStatus: null,
    Married: null,
    ZoomAccount: null,
    Christian: null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    RegisteredDate: null,
    LastAccessedDate: null,
    FaithQuesCompletedDate:null,
    LoginId: null,
    MiddleNameEN: null,
    MiddleNameJP: null,
    PrefixJP: null,
    OccupationDetail: null,
    Organization: null,
    LivingCountry: null,
    PhoneEN: null,
    PhoneMobileEN: null,
    PhoneJP: null,
    PhoneMobileJP: null,
    ChurchNameEN: null,
    ChurchNameJP: null,
    MemberType: null,
    MemberDetail: null,
    MemberMemo: null,
    Shokaisha: null,
    Reason: null,
    NewsLetters: null,
    StaffReports: null,
    OtherLetters: null,
    NewsLetterLanguage: null,
    BasicRoles: null,
    //Added new
    MigrateFromSiteGround: null,
    AdminAccess: null,
    JCFNAccess: null,
    SupportingMember: null,
    LastAddressUpdatedDate: null,
    AcceptMembershipTerm: null,
    MembershipType: null
  };
  relatedPerson:GRCRelatedPerson={
    Id:null,
    RelatedId:null,
    Email:null,
    FirstNameRelated:null,
    LastNameRelated:null,
    MyojiRelated:null,
    ShimeiRelated:null,
    DOBRelated:null,
    GenderRelated:null
  };
  relatedPersons:GRCRelatedPerson[]=[];
  volunteerSelections:GRCVolunteerSelection[]=[];

  conference= new GRCConference();
  columnGroup: ColumnGroup = {
    id: null,
    value: null,
  };
  columnGroups: ColumnGroup[] = [];

  memberListGridTemplate: MemberListGridTemplate = {
    MemberListGridTemplateId:null,
    SelectedColumns:this.columnGroups,
    TemplateName:null,
    UserName:null,
  };

  listVolunteers: CheckGroup[] =
  [
    { id: 0, value: 'Prayer' },
    { id: 1, value: 'Praise Team' },
    { id: 2, value: 'Bible Reading' },
    { id: 3, value: 'Skit' },
    { id: 4, value: 'PA' },
    { id: 5, value: 'Propresenter' },
    { id: 6, value: 'Video/Photo' },
    { id: 7, value: 'Translator' },
    { id: 8, value: 'Interpreter' },
    { id: 9, value: 'Usher' },
    { id: 10, value: 'Small Group Leader' },
    { id: 11, value: 'Workshop Helper' },
    { id: 12, value: 'Kids Program Helper' },
    { id: 13, value: 'Book Store' },
    { id: 14, value: 'Nursing' },
    { id: 15, value: 'Reception' },
    { id: 16, value: 'Counselor' },
    { id: 17, value: 'Sharing Testimony' }

  ]
  subProfile:SubProfile={
    UserId:null,
    Email:null,
    FirstName:null,
    LastName:null,
    SmallGroupNo:null,
    RoomNo:null,
    RegistrationFee:null,
    ChildRegistrationFee:null,
    BusFee:null,
    ApplicationPaidAmount:null,
    ApplicationPaidDate:null,
    RegistrationFeePaidAmount:null,
    RegistrationFeePaidDate:null,
    ApprovedScholarshipAmount:null,
    DiscountAmount:null,
    BalanceAmount:null
  }

  constructor(
    private _snackBar: MatSnackBar,
    private _formBuilder:FormBuilder,
    public authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    public grcRegistrationService : GrcRegistrationService,
    private dialog: MatDialog,
  ) { }

  myApprovedOptions: string[] = [];
  mySearch: any = {};
  ngOnInit(): void {
    this.mySelectedLanguage = this.router.url.includes('EN') == true ? "EN" : "JP";
    this.myFormGroup = this._formBuilder.group({
      // mySmallGroupNo: [''],
      // myRoomNo: [''],
      myRegistrationFee: [''],
      myChildRegistrationFee: [''],
      myBusFee: [''],
      myApplicationPaidAmount: [''],
      myApplicationPaidDate: [''],
      myRegistrationFeePaidAmount: [''],
      myRegistrationFeePaidDate: [''],
      myApprovedScholarshipAmount: [''],
      myDiscountAmount: [''],
      myBalanceAmount: [''],


      // myApprovedScholarshipAmount: [''],
      // mySelectedServices:[''],
      // myApprovedServices: [''],
      // myConferenceFee: [''],
      // myConferenceFeePaidAmount: [''],
      // myConferenceFeePaidDate: [''],
      // myApplicationFee: [''],
      // myApplicationFeePaidDate: [''],
      // myMemo:[''],
    });

    if (this.router.url.includes('/registration/GRC/myAccountGRC') == true) {
      /************************************************************************
       * This means user came to  his/her own my account page.
       * So session 'myECSearchingUser' used for admin purpose must be deleted
       * **********************************************************************/
      sessionStorage.removeItem("myGRCSearchingUserIdGRC");
    }
    /*#####################################################################
      # Admin Work search for registrant
      ######################################################################*/
    if (sessionStorage.getItem("myGRCSearchingUserIdGRC") != undefined) {
      var searchingRegistrantId = sessionStorage.getItem("myGRCSearchingUserIdGRC")
      console.log("Use myGRCSearchingUserIdGRC for admin purpose: " + searchingRegistrantId)
      this.loaded = false;
      /*****************************************
       *  A) User info for searching registrant
       ****************************************/
      this.userService.getUserProfileById(searchingRegistrantId).subscribe((result: User) => {
        this.user = result;
        console.log('use received for admin purpose')
        /***********************************************
         *  B) Conference info for searching registrant
         ***********************************************/
        this.grcRegistrationService.getConferenceByUserId(searchingRegistrantId).subscribe(result => {
          if (result != null) {
            this.loaded = false;
            this.conference = result;
     //       sessionStorage.setItem("loginUserAccessGRC", this.conference.CommitteeAccess == true ? "true" : "false");
            this.loaded = true;
            console.log('conference received for admin purpose')
            this.setMyCommittee();
          } else {
            this.loaded = true;
          }
          /***********************************************
           *  C) SubProfile info for searching registrant
           ***********************************************/
          this.grcRegistrationService.getSubProfileByUserId(searchingRegistrantId).subscribe(result => {
            if (result != null) {
              console.log('sub profile received for admin purpose')
              this.loaded = false;
              this.subProfile = result;
              this.patchModelValue(this.subProfile, this.conference);
              this.loaded = true;
            } else {
              console.log('no sub profile exist yet')
              this.patchModelValue(this.subProfile, this.conference);
              this.loaded = true;
            }
          });
        });
      });
      this.loaded = true;
    } else {
      if (sessionStorage.getItem("loginUserName") != null) {
        console.log("Use login UserName for my as user")
        var loginUser = sessionStorage.getItem("loginUserName")
        this.loaded = false;
        /******************************
         *  1) User Info for login user
         ******************************/
        this.userService.getUserProfile(loginUser).subscribe(result => {
          this.user = result;
          if (this.user != null) {
            sessionStorage.setItem("loginUserFirstName", this.user.FirstName);

            /************************************
            *  2) Conference Info for login user
            ************************************/
            this.grcRegistrationService.getConferenceByUserId(this.user.Id).subscribe(result => {
              if (result != null) {
                this.loaded = false;
                this.conference = result;
                sessionStorage.setItem("loginUserAccessGRC", this.conference.CommitteeAccess == true ? "true" : "false");
                this.setMyCommittee();
                this.loaded = true;
              } else {
                this.loaded = true;
              }
              this.setConferenceInfoInSessionStorage();

              /***********************************************
              *  3) SubProfile info for searching registrant
              ***********************************************/
              this.grcRegistrationService.getSubProfileByUserId(this.user.Id).subscribe(result => {
                if (result != null) {
                  this.loaded = false;
                  this.subProfile = result;
                  this.patchModelValue(this.subProfile, this.conference);
                  this.loaded = true;
                } else {
                  console.log('no sub profile exist yet')
                  this.patchModelValue(this.subProfile, this.conference);
                  this.loaded = true;
                }
              });
            },
              error => {
                console.log('Oops, API error', error);
                this.loaded = true;
              }
            );
          }
          this.loaded = true;
        },
          error => {
            console.log('Oops, API error', error);
            this.loaded = true;
          }
        );
      } else {
        this.loaded = true;
      }
    }
    this.loaded = true;
  }

  onSubmit() {
  }

  patchModelValue(subProfile:SubProfile, conference:GRCConference){
    this.myApprovedVolunteers =           conference.myGRCVolunteerSelections!=null?conference.myGRCVolunteerSelections.filter(x=>x.VolunteerAccepted==true):[];
    this.myApprovedVolunteers.forEach(element => {
      this.myApprovedOptions.push(element.VolunteerName);
    });

    this.myFormGroup = this._formBuilder.group({
      // mySmallGroupNo: [''],
      // myRoomNo: [''],

      myRegistrationFee: [''],
      myChildRegistrationFee: [''],
      myBusFee: [''],
      myApplicationPaidAmount: [''],
      myApplicationPaidDate: [''],
      myRegistrationFeePaidAmount: [''],
      myRegistrationFeePaidDate: [''],
      myApprovedScholarshipAmount: [''],
      myDiscountAmount: [''],
      myBalanceAmount: [''],

      // mySelectedServices:[''],
      // myApprovedServices: [this.myApprovedOptions,],
      // myConferenceFee: [''],
      // myConferenceFeePaidAmount: [''],
      // myConferenceFeePaidDate: [''],
      // myApplicationFee: [''],
      // myApplicationFeePaidDate: [''],
      // myApprovedScholarshipAmount: [''],
      // myMemo:[''],
    })

    this.myFormGroup.patchValue({
      // mySmallGroupNo: subProfile.SmallGroupNo,
      // myRoomNo:subProfile.RoomNo,

      myRegistrationFee:subProfile.RegistrationFee,
      myChildRegistrationFee: subProfile.ChildRegistrationFee,
      myBusFee: subProfile.BusFee,
      myApplicationPaidAmount: subProfile.ApplicationPaidAmount,
      myApplicationPaidDate: subProfile.ApplicationPaidDate,
      myRegistrationFeePaidAmount: subProfile.RegistrationFeePaidAmount,
      myRegistrationFeePaidDate: subProfile.RegistrationFeePaidDate,
      myApprovedScholarshipAmount: subProfile.ApprovedScholarshipAmount,
      myDiscountAmount: subProfile.DiscountAmount,
      myBalanceAmount: subProfile.BalanceAmount

      // myConferenceFee:conference.ConferenceFee,
      // myConferenceFeePaidAmount:conference.ConferenceFeePaidAmount,
      // myConferenceFeePaidDate:conference.ConferenceFeePaidDate,
      // myApplicationFee:conference.ApplicationFee,
      // myApplicationFeePaidDate:conference.ApplicationFeePaidDate,
      // myApprovedScholarshipAmount:conference.ApprovedScholarshipAmount,
      // myMemo:conference.Memo,
    });
  }

  setConferenceInfoInSessionStorage(){
    sessionStorage.removeItem("myConferenceInfo");
    this.conference.UserId=this.user.Id;
    this.conference.FirstName=this.user.FirstName;
    this.conference.LastName=this.user.LastName;
    this.conference.Email=this.user.Email;
    this.conference.ConferenceYear=2024;
    sessionStorage.setItem("myConferenceInfo",
    JSON.stringify({ myConferenceSearch: this.conference })
    );
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  onClickEditRegistrationPayment() {

  sessionStorage.setItem("EC22FeePreventCallingFunctionMultipleTimes", "mySession");
  /*************************************************************************
  According to Authorize.net, there is the following chainedInstruction.
  "IMPORTANT: When using the payment information form, be sure that your page
  loads the library after the button is defined."
  https://developer.authorize.net/api/reference/features/acceptjs.html
  To follow above instruction, I found it is important to import Authorize.net
  javascript program after this page is all loaded. Below line is to import
  javascript library into Angular component. First, I inserted this line in the
  ngOnInit, but is was not enough. The safest way is to add event to import the
  javascript library.
  Below line enable to open popup window to enter card information created by
  Authorize.net.
* **************************************************************************** */
  //this.loadScript('https://jstest.authorize.net/v3/AcceptUI.js');
  //this.loadScript('https://js.authorize.net/v3/AcceptUI.js');

  sessionStorage.setItem("myConferenceInfo",
  JSON.stringify({ myConferenceSearch: this.conference })
  );
  this.paymentProcessing =true;
  }

  getMyLanguage(): string {
    return this.router.url.includes('EN') == true ? 'EN' : 'JP';
  }
  setMyCommittee(){
  }

  setStep(index: number) {
    this.step = index;
  }
  nextStep() {
    this.step++;
  }
  prevStep() {
    this.step--;
  }

  onClickEditRegistrationPersonal() {
    sessionStorage.removeItem("myWorkingOnPersonal"); // This is session being used during personal registration
    let navigationExtras: NavigationExtras = {
      queryParams:this.user
    };
    if(this.router.url.includes('registration/GRC/')){
      this.router.navigate(["/registration/GRC/basicGRC/" +this.getMyLanguage()]);
    }else{
      this.openRegistrationPersonalEmbed();
    }
  }
  openRegistrationPersonalEmbed(){
    console.log('modifying user id: '+this.user.Id);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ myPage: 'basicGRC' };
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedGRCComponent,dialogConfig);
    // const dialogRef = this.dialog.open(RegistrationPersonalEmbedComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
     this.getUpdatedUserById(this.user.Id);
    })
  }

  getUpdatedUserById(Id:string){
    this.loaded=false;
    if(this.user!=null){
      this.userService.getUserProfileById(this.user.Id).subscribe(result => {
        this.user = result;
        this.loaded=true;
      });
    }else{
      this.loaded=true;
    }
  }

  onClickEditRegistrationConference() {
    sessionStorage.removeItem("myWorkingOnConference"); // This is session being used during personal registration
    let navigationExtras: NavigationExtras = {
      queryParams:this.user
    };
    if(this.router.url.includes('registration/GRC/')){
      this.router.navigate(["/registration/GRC/smallGroupLanguageGRC/" +this.getMyLanguage()]);
    }else{
      this.openRegistrationConferenceEmbed();
    }
  }
  openRegistrationConferenceEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ myPage: 'smallGroupLanguageGRC' };
    const dialogRef = this.dialog.open(RegistrationConferenceEmbedGRCComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
     this.getUpdatedUserById(this.user.Id);
    })
  }

  onClickProcessPayment(){
    let navigationExtras: NavigationExtras = {
      queryParams:this.user
    };
    if(this.router.url.includes('conference/EC22/myAccount')){
      this.router.navigate(["/registration/ec22/conferenceFeeDetail/" +this.getMyLanguage()]);
    }else{
      this.openRegistrationPaymentEmbed();
    }
  }
  openRegistrationPaymentEmbed(){
    console.log('modifying user id: '+this.user.Id);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ Id: this.user.Id };
    const dialogRef = this.dialog.open(RegistrationPaymentEmbedComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
     this.getUpdatedUserById(this.user.Id);
    })
  }

  onConfirmDelete() {

  }

  DeleteRegistrantById() {
  }

   onChangeCommittee($event:MatRadioChange){
  }

  getFormValue() {
    this.subProfile.RegistrationFee= this.myFormGroup.get('myRegistrationFee')!=null?parseInt(this.myFormGroup.get('myRegistrationFee').value):null;
    this.subProfile.ChildRegistrationFee= this.myFormGroup.get('myChildRegistrationFee')!=null?parseInt(this.myFormGroup.get('myChildRegistrationFee').value):null;
    this.subProfile.BusFee= this.myFormGroup.get('myBusFee')!=null?parseInt(this.myFormGroup.get('myBusFee').value):null;
    this.subProfile.ApplicationPaidAmount= this.myFormGroup.get('myApplicationPaidAmount')!=null?parseInt(this.myFormGroup.get('myApplicationPaidAmount').value):null;
    this.subProfile.ApplicationPaidDate= this.myFormGroup.get('myApplicationPaidDate').value;
    this.subProfile.RegistrationFeePaidAmount= this.myFormGroup.get('myRegistrationFeePaidAmount')!=null?parseInt(this.myFormGroup.get('myRegistrationFeePaidAmount').value):null;
    this.subProfile.RegistrationFeePaidDate= this.myFormGroup.get('myRegistrationFeePaidDate').value;
    this.subProfile.ApprovedScholarshipAmount=this.myFormGroup.get('myApprovedScholarshipAmount')!=null?parseInt(this.myFormGroup.get('myApprovedScholarshipAmount').value):null;
    this.subProfile.DiscountAmount=this.myFormGroup.get('myDiscountAmount')!=null? parseInt(this.myFormGroup.get('myDiscountAmount').value):null;
    this.subProfile.BalanceAmount= this.myFormGroup.get('myBalanceAmount')!=null? parseInt(this.myFormGroup.get('myBalanceAmount').value):null;

    this.subProfile.UserId = this.conference.UserId;
    this.subProfile.FirstName = this.conference.FirstName;
    this.subProfile.LastName = this.conference.LastName;
    this.subProfile.Email = this.conference.Email;
    this.conference.ConferenceFee = this.myFormGroup.get('myConferenceFee')!=null?parseInt(this.myFormGroup.get('myConferenceFee').value):null;
    this.conference.ConferenceFeePaidAmount = this.myFormGroup.get('myConferenceFeePaidAmount')!=null?parseInt(this.myFormGroup.get('myConferenceFeePaidAmount').value):null;
    this.conference.ConferenceFeePaidDate = this.myFormGroup.get('myConferenceFeePaidDate')!=null?this.myFormGroup.get('myConferenceFeePaidDate').value:null;
    this.conference.ApplicationFee = this.myFormGroup.get('myApplicationFee')!=null?parseInt(this.myFormGroup.get('myApplicationFee').value):null;
    this.conference.ApplicationFeePaidDate = this.myFormGroup.get('myApplicationFeePaidDate')!=null?this.myFormGroup.get('myApplicationFeePaidDate').value:null;
    this.conference.ApprovedScholarshipAmount = this.myFormGroup.get('myApprovedScholarshipAmount')!=null?parseInt(this.myFormGroup.get('myApprovedScholarshipAmount').value):null;

    // Should not use below
    //this.conference.myGRCVolunteerSelections = this.myFormGroup.get('myApprovedServices').value;
 }
 calcBalance(){
  this.subProfile.BalanceAmount =
  (
  (this.subProfile.RegistrationFee?this.subProfile.RegistrationFee:0)+
  (this.subProfile.ChildRegistrationFee?this.subProfile.ChildRegistrationFee:0)+
  (this.subProfile.BusFee?this.subProfile.BusFee:0)
  )-
  (
  (this.subProfile.RegistrationFeePaidAmount?this.subProfile.RegistrationFeePaidAmount:0)+
   (this.subProfile.ApplicationPaidAmount?this.subProfile.ApplicationPaidAmount:0)+
   (this.subProfile.ApprovedScholarshipAmount?this.subProfile.ApprovedScholarshipAmount:0)+
   (this.subProfile.DiscountAmount?this.subProfile.DiscountAmount:0)
  );
   console.log('ApplicationPaidAmount:'+this.subProfile.ApplicationPaidAmount);
   console.log('Balance Amount:'+this.subProfile.BalanceAmount);
 }

 onClickSaveSubProfile(){
  this.getFormValue();
  console.log('RegistrationFee: '+this.subProfile.RegistrationFee);
  console.log('ChildRegistrationFee: '+this.subProfile.ChildRegistrationFee);
  console.log('BusFee: '+this.subProfile.BusFee);
  console.log('ApplicationPaidAmount: '+this.subProfile.ApplicationPaidAmount);
  console.log('ApplicationPaidDate :'+this.subProfile.ApplicationPaidDate);
  console.log('RegistrationFeePaidAmount :'+this.subProfile.RegistrationFeePaidAmount);
  console.log('RegistrationFeePaidDate :'+this.subProfile.RegistrationFeePaidDate);
  console.log('ApprovedScholarshipAmount :'+this.subProfile.ApprovedScholarshipAmount);
  console.log('DiscountAmount :'+this.subProfile.DiscountAmount);
  console.log('BalanceAmount :'+this.subProfile.BalanceAmount);
  this.calcBalance();


  this.loaded = false;
  // 1. Save Sub Profile Info
  this.grcRegistrationService.upsertSubProfile(this.subProfile).subscribe(
    data => {
      // 2. Save Conference Info
      this.grcRegistrationService.upsertConference(this.conference).subscribe(
        data => {
          this.myMessage = this.getMyLanguage()=="EN"?"Sub Profile info saved successfully.":"情報が保存されました。";
          this.openSnackBar('success', 'close');
          this.ngOnInit();
          // 3. Save Volunteer Info
          this.createdApprovedVolunteerInstance();
          this.grcRegistrationService.approveVolunteers(this.conference).subscribe(
            data => {
              this.myMessage = this.getMyLanguage()=="EN"?"Sub Profile info saved successfully.":"情報が保存されました。";
              this.openSnackBar('success', 'close');
              this.loaded = true;
              this.ngOnInit();
              },
              error=>{this.loaded = true;}
              )
        },
        error=>{this.loaded = true;}
        )
    },
    error => {
      this.myMessage = this.getMyLanguage()=="EN"?"failed in saving Sub profile data.":"問題が生じ保存できませんでした。";
      this.openSnackBar('error', 'close');
      this.loaded = true;
    },
  )
}

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 5000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 10000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }

  onClickPaymentByPaypal() {
    console.log('paying user id: '+this.user.Id);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='450px';//Desktoop
   dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ conference: this.conference };
    const dialogRef = this.dialog.open(PayByPaypalComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
        console.log("returned AS400Code: " + confirmedAction.AS400Code);
      }
      //Retreive updated registration Info.
      this.ngOnInit();
    })
  }

  onClickApprovedService(data: any) {
    if(data!=null && data.selected==true){
      this.myApprovedOptions.push(data.value);
    }else if(data!=null && data.selected==false){
      this.myApprovedOptions = this.myApprovedOptions.filter(function(ele){
        return ele != data.value;
      });
    }
  }

  createdApprovedVolunteerInstance(){
    var myFinalApprovals:GRCVolunteerSelection[]=[];
      console.log('The number of volunteer to be saved: '+this.myApprovedOptions.length);
      this.myApprovedOptions.forEach(element=>{
        var myFinalApprove:GRCVolunteerSelection={
          Id:null,
          UserId:null,
          Email:null,
          VolunteerName:null,
          VolunteerAccepted:null,
          AcceptedDate:null
        }
        myFinalApprove.Id=1;
        myFinalApprove.UserId = this.conference.UserId;
        myFinalApprove.Email = this.conference.Email;
        myFinalApprove.VolunteerName =element;
        myFinalApprove.VolunteerAccepted=true;
        myFinalApprove.AcceptedDate= new Date;
        myFinalApprovals.push(myFinalApprove);
      })
      this.conference.myGRCVolunteerSelections = myFinalApprovals;
      console.log('Saved in model like this:')
      this.conference.myGRCVolunteerSelections.forEach(element=>{
        console.log(element.VolunteerName);
      })
    //Need to initialize before going next
    this.myApprovedOptions = [];
  }

  onChangePaymentOption($event: MatRadioChange) {
    this.conference.PaymentOption = $event.value;
     if(this.conference.PaymentOption=='CreditCard'){
       this.showCreditCardPayment=true;
       this.showBankPayment=false;
     }else if(this.conference.PaymentOption=='Bank'){
       this.showCreditCardPayment=false;
       this.showBankPayment=true;
     }
   }
}
