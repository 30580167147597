import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from "@angular/router";
import { MembersListComponent } from '../../../admin/members-list/members-list.component';
import { User } from "../../../../models/user";
import { UserConference } from 'src/app/models/conference/userConference';
export interface DialogData {
  user: UserConference
}
@Component({
  selector: 'app-registration-personal-embed',
  templateUrl: './registration-personal-embed.component.html',
  styleUrls: ['./registration-personal-embed.component.css']
})
export class RegistrationPersonalEmbedComponent implements OnInit {

  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<RegistrationPersonalEmbedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) { }

    ngOnInit(): void {
    }

    onClickClose() {
      this.dialogRef.close();
    }
}
