<div class="WelcomeBase">
 <form [formGroup]="firstFormGroup">
  <div class="btnBack">
   <button type="button" class="btn btn-link" (click)="onClickBack()">
    <i class="fas fa-arrow-circle-left fa-2x"></i>
   </button>
  </div>
  <div *ngIf="!loaded">
   <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div *ngIf="loaded">
     <mat-card class="WelcomeWindow">
   <div class="divMessage">
    <i class="fas fa-map-pin fa-2x"></i>&nbsp;&nbsp; {{this.router.url.includes('EN')==true?'Please verify your address. You can update if needed. ':'あなたの住所を確認してください'}}
   </div>
   <div class="row">
    <div class="col myAddress">
     {{user.Street}}<br>
     {{user.City}}<br>
     {{user.State}}<br>
     {{user.Zip}}<br>
    </div>
    <div  class="col myAddress">
     {{user.Yubin_Bango}}<br>
     {{user.To_Do_Fu_Ken}}<br>
     {{user.Shi_Gun_Ku}}<br>
     {{user.Cho_Son}}<br>
     {{user.Apartment_Etc}}<br>
    </div>
    <!-- <div *ngIf="this.router.url.includes('EN')!=true" class="myAddress">
     {{user.Yubin_Bango}}<br>
     {{user.To_Do_Fu_Ken_JP}}<br>
     {{user.Shi_Gun_Ku_JP}}<br>
     {{user.Cho_Son_JP}}<br>
     {{user.Apartment_Etc_JP}}<br>
    </div> -->
   </div>

   <div class="divMessage">
    <i class="fas fa-globe-asia fa-2x"></i>&nbsp;&nbsp;{{this.router.url.includes('EN')==true?'Please verify your country of residence.  You can update if needed.':'あなたの現在の居住国を確認してください'}}
   </div>
   <div class="myAddress">
    {{user.LivingCountry=='Japan' && this.router.url.includes('EN')!=true?'日本':user.LivingCountry}}
   </div>

   <div class="divUpdateButton  d-flex justify-content-center">
    <button mat-button (click)="onConfirmAddress()" class="btnActionAddress" cdkFocusInitial>
     {{this.router.url.includes('EN')==true?'Confirm':'確認する'}}
    </button>
    <button mat-button (click)="onEditAddress()" class="btnActionAddress" cdkFocusInitial>
     {{this.router.url.includes('EN')==true?'Edit':'編集する'}}
    </button>
   </div>


  </mat-card>
  </div>

 </form>
</div>
<div class="memoForDevelopper">
 request-address-update
</div>