import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../../../services/auth/auth.service";
import { User } from "../../../models/user";
import { UserService } from "../../../services/user/user.service";
import { Ec20BeyondReunionService} from "../../../services/conference/ec20BeyondReunion/ec20-beyond-reunion.service";
import { PaymentService } from "../../../services/payment/payment.service";
import { MatRadioChange } from '@angular/material/radio';
import { MatStepper } from '@angular/material/stepper';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
//For Dialog
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { LoginMessageComponent } from '../../dialog/login-message/login-message.component';
import { UserEditMyProfileComponent } from '../../dialog/user-edit-my-profile/user-edit-my-profile.component';
import { UserEditOthersProfileComponent } from '../../dialog/user-edit-others-profile/user-edit-others-profile.component';
import { DeleteConfirmationComponent } from '../../dialog/delete-confirmation/delete-confirmation.component';
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { TOOLTIP_PANEL_CLASS } from '@angular/material/tooltip';
import { PaymentMaster, LineItem, RecurringPayment } from "../../../models/payment/paymentMaster";
import { DonationLogComponent } from '../../dialog/donation-log/donation-log.component';
import { ConferenceHistoryComponent } from '../../dialog/conference-history/conference-history.component';
import { MembershipSettingComponent } from '../../dialog/membership-setting/membership-setting.component';
import {JCFNAccessConfirmationComponent} from '../../dialog/jcfnaccess-confirmation/jcfnaccess-confirmation.component';
import {EC20BeyondReunion} from '../../../models/conference/EC20BeyondReunion';
import { UploadDocumentsComponent } from '../../dialog/upload-documents/upload-documents.component';

export interface DialogData {
  Id: string
}
interface MyList {
  display: string;
  value: string;
}

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
  firstFormGroup: FormGroup;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  myMessage: string;
  strAccessJCFN:string;
  step = 0;
  mySelectedLanguage: string;
  loaded: boolean = true;
  searchingRegistrant: string;
  showCreditCardPayment: boolean = false;
  showBankPayment: boolean = false;
  deleting: boolean = false;
  strCommittee: string;
  supportMembershipActive: string;
  hideMembershipChangeOption:boolean;
  supportingMemberValidity?:boolean;
  recurringSign?:boolean;
  lastValidDate?:Date;

  AttendJapanRegionalNetworkAppliedNameJP: string;
  AttendJapanRegionalNetworkAsignedNameJP: string;

  user: User = {
    PasswordHash: null,
    Id: null,
    UserName: null,
    Email: null,
    FirstName: null,
    LastName: null,
    Password: null,
    Prefix: null,
    Shimei: null,
    Myoji: null,
    Street: null,
    Street2: null,
    City: null,
    State: null,
    Zip: null,
    Country: null,
    Yubin_Bango: null,
    To_Do_Fu_Ken: null,
    Shi_Gun_Ku: null,
    Cho_Son: null,
    Apartment_Etc: null,
    To_Do_Fu_Ken_JP: null,
    Shi_Gun_Ku_JP: null,
    Cho_Son_JP: null,
    Apartment_Etc_JP: null,
    Gender: null,
    DOB: null,
    TelNo: null,
    TimezoneName: null,
    UTCdiff: null,
    Token: null,
    Occupation: null,
    MemberStatus: null,
    Married: null,
    ZoomAccount: null,
    Christian: null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    RegisteredDate: null,
    LastAccessedDate: null,
    FaithQuesCompletedDate:null,
    //From siteground
    LoginId: null,
    MiddleNameEN: null,
    MiddleNameJP: null,
    PrefixJP: null,
    OccupationDetail: null,
    Organization: null,
    LivingCountry: null,
    PhoneEN: null,
    PhoneMobileEN: null,
    PhoneJP: null,
    PhoneMobileJP: null,
    ChurchNameEN: null,
    ChurchNameJP: null,
    MemberType: null,
    MemberDetail: null,
    MemberMemo: null,
    Shokaisha: null,
    Reason: null,
    NewsLetters: null,
    StaffReports: null,
    OtherLetters: null,
    NewsLetterLanguage: null,
    BasicRoles: null,
    //Added new
    MigrateFromSiteGround: null,
    AdminAccess: null,
    JCFNAccess: null,
    SupportingMember: null,
    LastAddressUpdatedDate: null,
    AcceptMembershipTerm: null,
    MembershipType: null
  };
  lineItem: LineItem = {
    itemId: 0,
    name: null,
    description: null,
    quantity: 0,
    unitPrice: 0
  }
  lineItems: LineItem[] = [];
  recurringPayment: RecurringPayment = {
    RecurringPaymentId: 0,
    RecurringId: null,
    Interval: null,
    StartDate: null,
    EndDate: null,
    CreatedDate: null
  }
  paymentMaster: PaymentMaster = {
    PaymentMasterId: 0,
    FirstName: null,
    LastName: null,
    Email: null,
    DataDescriptor: null,
    DataValue: null,
    ItemName: null,
    Amount: null,
    Recurring: null,
    RecurringId: null,
    CreatedDate: null,
    ProviderName: null,
    lineItems: this.lineItems,
    recurringPayment: this.recurringPayment
  }

    registrant: EC20BeyondReunion={
    EC20BeyondReunionId:0,
    UserId:null,
    Email: null,
    FirstName: null,
    LastName: null,
    Language: null,
    RegisteredDate:null,
    LastAccessedDate: null,
    Attend:null,
    CanceledDate:null
  }


  constructor(
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    public authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private paymentService: PaymentService,
    private dialog: MatDialog,
    private ec20BeyondReunionService:Ec20BeyondReunionService
  ) { }

  ngOnInit(): void {
    this.mySelectedLanguage = this.router.url.includes('EN') == true ? "EN" : "JP";
    this.firstFormGroup = this._formBuilder.group({
      myJCFNMenuAccess:[''],
    });
console.log(1);
    this.registrant.Attend=true;

    if(sessionStorage.getItem("searchingMemberId") != null){
      console.log(2);
      this.loaded = false;
     // this.hideMembershipChangeOption=true;
      var searchingMemberId = sessionStorage.getItem("searchingMemberId");
      console.log('Going to get user by ID: '+ searchingMemberId);
      this.userService.getUserProfileById(searchingMemberId).subscribe(result => {
        this.user = result;
        this.checkSupportingMemberValidity(this.user.UserName);
        this.setMyJCFNAccess();
        this.setEC20BeyondReunion();
        this.loaded = true;
      });
    }else if (sessionStorage.getItem("loginUserName") != null) {
      console.log(3);
      var loginUser = sessionStorage.getItem("loginUserName")
      this.loaded = false;
      //this.hideMembershipChangeOption=false;
      this.userService.getUserProfile(loginUser).subscribe(result => {
        this.user = result;
        if (this.user != null) {
          sessionStorage.setItem("loginUserFirstName", this.user.FirstName);
          sessionStorage.setItem("loginUserAccessJCFN", this.user.JCFNAccess != null ? this.user.JCFNAccess.toString() : 'false');
          sessionStorage.setItem("loginUserAccessAdmin", this.user.AdminAccess != null ? this.user.AdminAccess.toString() : 'false');
          //this.checkSupportingMembershipStatus(loginUser);
          this.checkSupportingMemberValidity(loginUser);
          this.setMyJCFNAccess();
          this.setEC20BeyondReunion();
          console.log("#############################"+this.user.MembershipType);
        }
       this.loaded = true;;
      });
    } else {
      this.loaded = true;
    }
  }

  setMyJCFNAccess(){
    if(this.user.JCFNAccess==true){
      this.strAccessJCFN='true';
    }
  }

  setEC20BeyondReunion(){
    this.loaded=false;
    this.ec20BeyondReunionService.getEC20BeyondReunionByUser(this.user).subscribe(
      result => {
        this.registrant = result;
        this.loaded = true;
      },
      error=>{
        this.loaded = true;
      });
  }

  // checkSupportingMembershipStatus(loginUser: string) {
  //   this.loaded = false;
  //   this.paymentService.getLatestMembershipPaymentInfoByUserName(loginUser).subscribe(result => {
  //     this.paymentMaster = result;
  //     var today = new Date();
  //     var myday = today.getDate();
  //     var myMonth = today.getMonth();
  //     var myYear = today.getFullYear() - 1;
  //     var newDate = new Date(myYear, myMonth, myday);
  //     if (this.paymentMaster != null) {
  //       if (this.user.SupportingMember == true && this.paymentMaster.Recurring == true) {
  //         this.supportMembershipActive = 'done';
  //       } else if (this.user.SupportingMember == true && this.paymentMaster.Recurring == false &&
  //         new Date(this.paymentMaster.CreatedDate).getTime() >= newDate.getTime()) {
  //         this.supportMembershipActive = 'done';
  //       } else if (this.user.SupportingMember == true && this.paymentMaster.Recurring == false &&
  //         new Date(this.paymentMaster.CreatedDate).getTime() < newDate.getTime()) {
  //         this.supportMembershipActive = 'processing';
  //       } else {
  //       }
  //     } else {
  //       if (this.user.SupportingMember == true) {
  //         this.supportMembershipActive = 'processing';
  //       } else {
  //       }
  //     }
  //     this.loaded = true;
  //     console.log('supportMembershipActive: ' + this.supportMembershipActive);
  //   });
  // }

  checkSupportingMemberValidity(loginUser:string){
    this.loaded = false;
    this.paymentService.getLatestMembershipPaymentInfoByUserName(loginUser).subscribe(result => {
      this.paymentMaster = result;
      console.log(this.paymentMaster.FirstName);
      var today = new Date();
      var myDay = today.getDate();
      var myMonth = today.getMonth();
      var myYear = today.getFullYear() - 1;
      var newDate = new Date(myYear, myMonth, myDay);
      var startDate  = this.paymentMaster.CreatedDate;
      console.log("created date: "+this.paymentMaster.CreatedDate);
     var d = new Date(this.paymentMaster.CreatedDate);
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    this.lastValidDate = new Date(year + 1, month, day);
      if (this.paymentMaster != null) {
        if (this.user.MembershipType.toLowerCase()=="supporting" && this.paymentMaster.Recurring == true) {
          this.supportingMemberValidity=true;
          this.recurringSign=true;
          this.lastValidDate=null;
        } else if (this.user.MembershipType.toLowerCase()=="supporting" && this.paymentMaster.Recurring != true &&
        new Date(this.paymentMaster.CreatedDate).getTime() >= newDate.getTime()) {
          this.supportingMemberValidity=true;
          this.recurringSign=false;
        } else if (this.user.MembershipType.toLowerCase()=="supporting" && this.paymentMaster.Recurring != true &&
        new Date(this.paymentMaster.CreatedDate).getTime() <= newDate.getTime()) {
          this.supportingMemberValidity=false;
          this.recurringSign=false;
        } else {
          this.supportingMemberValidity=null;
          this.recurringSign=null;
          this.lastValidDate=null;
        }
      } else {
        this.supportingMemberValidity=null;
        this.recurringSign=null;
        this.lastValidDate=null;
      }
      this.loaded = true;
    });
  }

  onClickBecommingSupportingMember() {
    this.router.navigate(['payment/supportingMembership/' + this.getMyLanguage()]);
  }

  getMyLanguage(): string {
    return this.router.url.includes('EN') == true ? 'EN' : 'JP';
  }

  onChangeJCFNAccess($event:MatRadioChange){
    /**********************************************************
     *  I don't know but MatRadioChange returns always string,
     * so it need to be converted to boolean
    ***********************************************************/
    this.user.JCFNAccess = $event.value=='true'? true:false;
    this.loaded=false;

    if(this.user.JCFNAccess==true){
      const dialogRef = this.dialog.open(JCFNAccessConfirmationComponent, {
        width: '300px',
        data: {
          Id: this.user.Id
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        var confirmedAction = result;
        console.log("Confirmed Action: " + confirmedAction);
        if (confirmedAction === this.user.Id) {
          this.updateJCFNAccess();
        } else {
          this.user.JCFNAccess=!this.user.JCFNAccess
          this.myMessage = this.getMyLanguage() == "EN" ? "Access approval cancelled." : "権限変更がキャンセルされました";
          this.openSnackBar('error', 'close');
          this.loaded = true;
        }
      });
    }else{
      this.updateJCFNAccess();
    }
  }

  updateJCFNAccess(){
　　// 1. Save User Info
    this.userService.updateUser(this.user).subscribe(
      data => {
        this.myMessage = this.router.url.includes('EN') == true ?
        "Updated successfully" : "データが更新されました";
      this.openSnackBar('success', 'close');
      this.loaded = true;
      },
      error => {
        this.myMessage = "Data save failed."
        this.openSnackBar('error', 'close');
        this.loaded = true;
      }
    )
  }

  setStep(index: number) {
    this.step = index;
  }
  nextStep() {
    this.step++;
  }
  prevStep() {
    this.step--;
  }

  onClickEditRegistrationPersonal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight = '800px'; //Desktop
    dialogConfig.maxWidth = '1000px';//Desktoop
    dialogConfig.height = '100%';//Mobile
    dialogConfig.width = '100%';//Mobile
    dialogConfig.panelClass = 'custom-modalbox'; //Refer global level
    dialogConfig.data = { Id: this.user.Id };
    const dialogRef = this.dialog.open(UserEditMyProfileComponent, dialogConfig);
    // const dialogRef = this.dialog.open(ParticipantEditPersonalComponent, {
    //   disableClose: true,
    //   autoFocus: true,
    //   maxWidth: '1000px',//Desktop
    //   maxHeight: '800px',//Desktop
    //   height: '100%', //Moble
    //   width: '100%', //Mobilel
    //   data: { Id: this.user.Id },
    //   panelClass: 'custom-modalbox'
    // });
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
        console.log("returned AS400Code: " + confirmedAction.AS400Code);
      }
      //Retreive updated registration Info.
      this.ngOnInit();

    })
  }

  onConfirmDelete() {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      width: '250px',
      data: {
        Id: this.user.Id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      console.log("Confirmed Action: " + confirmedAction);
      if (confirmedAction === this.user.Id) {
        this.DeleteRegistrantById();
      } else {
        this.myMessage = this.getMyLanguage() == "EN" ? "Delete data cancelled." : "データ削除がキャンセルされました。";
        this.openSnackBar('error', 'close');
        this.loaded = true;
      }
    });
  }

  DeleteRegistrantById() {
    this.deleting = true;
    var deletingId = this.user.Id;
    // this.userService
    //   .deleteUserById(deletingId)
    //   .subscribe(
    //     data => {
    //success
    this.userService
      .deleteUserById(deletingId)
      .subscribe(
        result => {
          this.myMessage = this.getMyLanguage() == "EN" ? "Deleted data successfully." : "データが削除されました。";
          this.openSnackBar('success', 'close');
          this.deleting = false;
        },
        error => {
          this.myMessage = this.getMyLanguage() == "EN" ? "Delete data failed." : "データ削除に失敗しました。";
          this.openSnackBar('error', 'close');
          this.deleting = false;
        }
      )
    this.deleting = false;;
    // },
    // error => {
    //   this.myMessage = "Delete personal data failed."
    //   this.openSnackBar('error', 'close');
    //   this.deleting = false;
    // }
    //)
  }

  getUpdatedUserById(Id:string){
    this.loaded=false;
    if(this.user!=null){
      this.userService.getUserProfileById(this.user.Id).subscribe(result => {
        this.user = result;
        this.loaded=true;
      });
    }else{
      this.loaded=true;
    }

  }
  onClickBasicInfo(){
    console.log('modifying user id: '+this.user.Id);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1000px';//Desktoop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ Id: this.user.Id };
    const dialogRef = this.dialog.open(UserEditOthersProfileComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
     this.getUpdatedUserById(this.user.Id);
    })
  }
  onClickDonationHistory(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1000px';//Desktoop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ Id: this.user.Id };
    const dialogRef = this.dialog.open(DonationLogComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
     this.getUpdatedUserById(this.user.Id);
    })
  }
  onClickConferenceHistory(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1000px';//Desktoop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ Id: this.user.Id };
    const dialogRef = this.dialog.open(ConferenceHistoryComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
     this.getUpdatedUserById(this.user.Id);
    })
  }
  onClickMemberSetting(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1000px';//Desktoop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ Id: this.user.Id };
    const dialogRef = this.dialog.open(MembershipSettingComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
     this.getUpdatedUserById(this.user.Id);
    })
  }

  onClickUploadDocuments(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1000px';//Desktoop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ Id: this.user.Id };
    const dialogRef = this.dialog.open(UploadDocumentsComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
     this.getUpdatedUserById(this.user.Id);
    })
  }

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 5000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 10000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }

  checkSupportMembershipPayment() {
    return this.supportMembershipActive;
  }

  onClickBecomeMember() {
    this.router.navigate(["/members/acceptTerms/" + this.getMyLanguage()]);
  }

  onClickRegistration() {
    this.router.navigate(["/registration/ec20BeyondReunionApply/" + this.getMyLanguage()]);
  }

  onClickChangeMembershipType(){
    this.router.navigate(["/members/selectMemberType/" + this.getMyLanguage()]);
  }

  onClickChangeMembershipTypeToSupporting(){
    this.router.navigate(["/members/supportingTop/" + this.getMyLanguage()]);
  }

}
