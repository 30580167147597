import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { AppConfig } from "../../../app.config";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { User } from "../../../models/user";
import { ECConference } from "../../../models/conference/ECConference";
import { UserConference } from "../../../models/conference/userConference";
//import { MinUserConference } from "../../models/minUserConference";
import { AuthService } from '../../auth/auth.service';
import { NewUser } from 'src/app/models/mailchimp/newUser';
import { UserConferenceTemplate } from 'src/app/models/conference/userConferenceTemplate';
import { UserConferenceGridTemplate } from 'src/app/models/conference/userConferenceGridTemplate';
import { SubProfile } from 'src/app/models/conference/subProfile';
import { UserConferenceSubProfile } from 'src/app/models/conference/userConferenceSubProfile';

@Injectable({
  providedIn: 'root'
})
export class EcRegistrationService {

  constructor(
    private http: HttpClient,
    private config: AppConfig,
    private authService: AuthService) { }


  getMasterConferenceById(Id: String): Observable<ECConference> {
    return this.http.get<ECConference>(
      this.config.apiMyUrl + "/api/Conference/EC/ECConference/getMasterConferenceById/" + Id,
      { headers: this.authService.getJWT() }
    );
  }

  getConferenceByEmail(email: String): Observable<ECConference> {
    return this.http.get<ECConference>(
      this.config.apiMyUrl + "/api/Conference/EC/ECConference/getConferenceByEmail/" + email,
      { headers: this.authService.getJWT() }
    );
  }

  getConferenceByUserId(userId: String): Observable<ECConference> {
    return this.http.get<ECConference>(
      this.config.apiMyUrl + "/api/Conference/EC/ECConference/getConferenceByUserId/" + userId,
      { headers: this.authService.getJWT() }
    );
  }

  getSubProfileByUserId(userId: String): Observable<SubProfile> {
    return this.http.get<SubProfile>(
      this.config.apiMyUrl + "/api/Conference/EC/ECConference/getSubProfileByUserId/" + userId,
      { headers: this.authService.getJWT() }
    );
  }

  upsertSubProfile(model: SubProfile) {
    return this.http.post(
      this.config.apiMyUrl + "/api/Conference/EC/ECConference/upsertSubProfile",
      model,
      { headers: this.authService.getJWT() }
    );
  }

  upsertConference(conference: ECConference) {
    return this.http.post(
      this.config.apiMyUrl + "/api/Conference/EC/ECConference/upsertConference",
      conference,
      { headers: this.authService.getJWT() }
    );
  }

  approveVolunteers(conference: ECConference) {
    return this.http.post(
      this.config.apiMyUrl + "/api/Conference/EC/ECConference/approveVolunteers",
      conference,
      { headers: this.authService.getJWT() }
    );
  }

  updateApplicationFeePaidDate(conference: ECConference) {
     return this.http.post(
       this.config.apiMyUrl + "/api/Conference/EC/ECConference/updateApplicationFeePaidDate",
       conference,
       { headers: this.authService.getJWT() }
     );
   }

   updateConferenceFeePaidDate(conference: ECConference) {
    return this.http.post(
      this.config.apiMyUrl + "/api/Conference/EC/ECConference/updateConferenceFeePaidDate",
      conference,
      { headers: this.authService.getJWT() }
    );
  }

   SendConfirmationEmailToUserECApplicationFeeByPaypal(conference: ECConference) {
    return this.http.post(
      this.config.apiMyUrl + "/api/Conference/EC/ECConference/sendConfirmationEmailToUserECApplicationFeeByPaypal",
      conference, { headers: this.authService.getJWT()  }
    );
  }

  sendConfirmationEmailToUserECConferenceFeeByPaypal(conference: ECConference) {
    return this.http.post(
      this.config.apiMyUrl + "/api/Conference/EC/ECConference/sendConfirmationEmailToUserECConferenceFeeByPaypal",
      conference, { headers: this.authService.getJWT()  }
    );
  }

  addUserToMailchimp(newUser: NewUser) {

    console.log('I am here??');
    // var mailchimpURL = "https://mailchimp-test1.azurewebsites.net/api/mailchimphttptrigger1"

    return this.http.post(
      //this.config.apiMailchimp+"/api/mailchimphttptrigger1",
      '/mailchimp-api/api/mailchimphttptrigger1'
      ,

      newUser
    );

  }

  checkLoginUserAccessEC(): boolean {
   // console.log("checkLoginUserAccessEC: "+sessionStorage.getItem("loginUserAccessEC"));
    return sessionStorage.getItem("loginUserAccessEC") === 'true';
  }

  getUserConferenceBySearchKey(userConference: UserConference): Observable<UserConference[]> {
    console.log('hey')
    console.log(userConference.FirstName)
    return this.http.post<UserConference[]>(
      this.config.apiMyUrl + "/api/Conference/EC/ECConference/getUserConferenceBySearchKey",
      userConference,
      { headers: this.authService.getJWT() }
    );
  }

  GetUserConferenceBySearchKey(conference: ECConference, includeFamilyVolunteer:boolean): Observable<UserConference[]> {
   if(includeFamilyVolunteer){
    return this.http.post<UserConference[]>(
      this.config.apiMyUrl + "/api/Conference/EC/ECConference/getUserConferenceBySearchKeyWithFamilyVolunteer",
      conference,
      { headers: this.authService.getJWT() }
    );
   }else{
    return this.http.post<UserConference[]>(
      this.config.apiMyUrl + "/api/Conference/EC/ECConference/getUserConferenceBySearchKeyWithoutFamilyVolunteer",
      conference,
      { headers: this.authService.getJWT() }
    );
   }
  }

  /************************************************
  * admin/EC/ECparticipantCustom
  *************************************************/
  getUserConferenceTemplateNamesByUserName(userName:String): Observable<string[]>  {
    return this.http.get<string[]>(
      this.config.apiMyUrl +  "/api/Conference/EC/ECConference/getUserConferenceTemplateNamesByUserName/"+userName,
      { headers: this.authService.getJWT()});
  }

  getUserConferenceTemplateByTemplateName(templateName:String): Observable<UserConferenceTemplate>  {
    return this.http.get<UserConferenceTemplate>(
      this.config.apiMyUrl + "/api/Conference/EC/ECConference/getUserConferenceTemplateByTemplateName/"+templateName,
      { headers: this.authService.getJWT()});
  }


/*****************************************************
 * //dialog/EC/admin/ec=participants-template/
 ****************************************************/
  getTemplateNamesByUser(model: UserConferenceGridTemplate): Observable<string[]>  {
    return this.http.post<string[]>(
      this.config.apiMyUrl + "/api/Conference/EC/ECConference/getTemplateNamesByUser",
      model,{ headers: this.authService.getJWT()});
  }
  insertNewTemplate(userConferenceTemplate: UserConferenceGridTemplate) {
    return this.http.post(
      this.config.apiMyUrl + "/api/Conference/EC/ECConference/insertNewTemplate",
      userConferenceTemplate,{ headers: this.authService.getJWT()  });
  }
  getUserConferenceListByTemplateName(templateName: string): Observable<UserConferenceTemplate>  {
    return this.http.get<UserConferenceTemplate>(
      this.config.apiMyUrl + "/api/Conference/EC/ECConference/getUserConferenceListByTemplateName/"+templateName,
      { headers: this.authService.getJWT()});
  }
  deleteUserConferenceListTemplate(model: UserConferenceGridTemplate) {
    return this.http.put(
      this.config.apiMyUrl + "/api/Conference/EC/ECConference/deleteMemberListTemplate",model, { headers: this.authService.getJWT()}
    );
  }

  // postFile(fileToUpload: File): Observable<boolean> {
  //   const endpoint = 'your-destination-url';
  //   const formData: FormData = new FormData();
  //   formData.append('fileKey', fileToUpload, fileToUpload.name);
  //   return this.http
  //     .post(endpoint, formData, { headers: yourHeadersConfig })
  //     .map(() => { return true; })
  //     .catch((e) => this.handleError(e));
  // }
}
