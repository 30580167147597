<div class="myBase">
   <div class="myBackgroundLayer">
      <div class="row">
         <div class="myAccountTitle">
            <h2>
            </h2>
         </div>
      </div>
      <div class="d-flex justify-content-center">
            <mat-card class="supportingMembershipAnounce">
               <div *ngIf="loaded">
                  <form [formGroup]="firstFormGroup" id="paymentForm" method="POST">

                     <div class="paymentOption">
                         <div>
                           <div class="payUSDByCreditCard">
                              <h3 class="paymentTitle">
                                 {{this.router.url.includes('EN')==true?
                                 'A. Pay with a credit card in US dollars.'
                                 :
                                 'A) クレジットカードで米ドルを払う'
                                 }}
                                </h3>

                              <h4>
                                 {{this.router.url.includes('EN')==true?'Do you want to make this payment ($40/year)
                                 recurring?':
                                 '維持会員献金40ドル（年間）を自動引き落としにしますか？'}}
                              </h4>
                              <div *ngIf="paymentProcessing" class="d-flex justify-content-center">
                            <!-- <mat-progress-bar mode="indeterminate" class="myBar"></mat-progress-bar>-->
                            <mat-spinner
                             [diameter]="50"
                            ></mat-spinner>
                            <div *ngIf="this.strRecurring == 'true'" class="progressMessage">
                            {{this.router.url.includes('EN')==true?'Recurring process takes up to 1 min':'自動引き落とし登録は1分程かかります'}}
                            </div>
                          </div>
                              <div class="divSelectRecurring">
                                 <mat-radio-group formControlName="myAcceptRecurring" [(ngModel)]="strRecurring"
                                    (change)="onChangeAcceptRecurring($event)" class="radioBtnMain">
                                    <mat-radio-button value=true class="radioBtnItem">
                                       {{this.router.url.includes('EN')==true?'Yes': 'はい'}}</mat-radio-button>
                                    <mat-radio-button value=false class="radioBtnItem">
                                       {{this.router.url.includes('EN')==true?'No': 'いいえ'}}</mat-radio-button>
                                 </mat-radio-group>

                              </div>
                              <div *ngIf="this.authService.isLoggedIn() && enableEnterPaymentInfoButton">
                                 <input type="hidden" name="dataValue" id="dataValue" />
                                 <input type="hidden" name="dataDescriptor" id="dataDescriptor" />
                                 <!-- TEST -->
                                 <!-- <button class="AcceptUI btnProcessPayment"
                                 data-billingAddressOptions='{"show":show, "required":false}' data-apiLoginID="6H8vc9Km4"
                                 data-clientKey="4Pxk7P8A4bzYZLm8dmHjg8K39mPB7J7u24fr35cJvUj4s3bVmA64ZvGANT3fhyjP"
                                 data-acceptUIFormBtnTxt="Submit Test" data-acceptUIFormHeaderTxt="Payment Information"
                                 data-paymentOptions='{"showCreditCard": false, "showBankAccount": true}'
                                 data-responseHandler="responseHandlerSupportingMembership" (click)="onClickEnterPaymentInfo()">
                                 {{this.router.url.includes('EN')==true?'Enter Payment Info': '支払い方法を入力する'}}
                              </button> -->
                                 <button class="AcceptUI btnProcessPayment"
                                    data-billingAddressOptions='{"show":false, "required":false}'
                                    data-apiLoginID="2vp8ZSGt45k"
                                    data-clientKey="4MTc2hmJ3j6aS6tYWy8F2yzftfbPVhnVc27ym2ZJ49ukJm7skDfA8sS9d3Qfc8eL"
                                    data-acceptUIFormBtnTxt="Pay" data-acceptUIFormHeaderTxt="Payment Information"
                                    data-paymentOptions='{"showCreditCard": true, "showBankAccount": false}'
                                    data-responseHandler="responseHandlerSupportingMembership"
                                    (click)="onClickEnterPaymentInfo()">
                                    {{this.router.url.includes('EN')==true?'Enter Payment Info': '支払い情報を入力する'}}
                                 </button>
                              </div>
                           </div>


                          <div class="payByOthers">
                            <h3  class="paymentTitle">
                              {{this.router.url.includes('EN')==true?
                              'B. Pay in Japanese yen with a credit card.'
                              :
                              'B) クレジットカードで日本円で支払う。'
                              }}
                            </h3>
                            <div *ngIf="this.router.url.includes('EN')!=true">
                              <a href="https://jcfn.org/settlement/supportingmemberJP.php"
                                target="_blank">
                                こちらをクリック
                              </a>
                            </div>
                            <div *ngIf="this.router.url.includes('EN')==true">
                              <a href="https://jcfn.org/settlement/supportingmemberJP.php"
                                target="_blank">
                                Click here.
                              </a>
                            </div>
                          </div>
                          <div class="payByOthers">
                            <h3  class="paymentTitle">
                              {{this.router.url.includes('EN')==true?
                              'C. Other ways to make a payment.'
                              :
                              'C) その他でのお支払いの場合'
                              }}
                            </h3>
                            <h4>
                              {{this.router.url.includes('EN')==true?
                              '(Pay with a credit card in JP yen, via Japanese Postal Giro, via Japanese Bank Transfer, or with a Personal Check in US dollars.)'
                              :
                              '郵便振替、銀行振り込み、米小切手等'
                              }}
                            </h4>
                            <div *ngIf="this.router.url.includes('EN')!=true">
                              <a href="https://jcfn.org/jcfnhome/index.php?option=com_content&view=article&id=759:2013-04-12-01-58-27&catid=178&lang=ja&Itemid=515"
                                target="_blank">
                                こちらをクリック
                              </a>
                            </div>
                            <div *ngIf="this.router.url.includes('EN')==true">
                              <a href="https://jcfn.org/jcfnhome/index.php?option=com_content&view=article&id=760:2013-04-12-01-58-39&catid=179&lang=en&Itemid=540"
                                target="_blank">
                                Click here.
                              </a>
                            </div>
                          </div>

                        </div>
                        <!-- <div
                           *ngIf="this.authService.isLoggedIn() && this.checkSupportMembershipPayment()=='processing'">
                           <div  class="d-flex justify-content-center">
                              <h3>
                                 {{this.router.url.includes('EN')==true?'You have already applied Support membership.':
                                 '維持会員申し込み済'}}<br>
                                 {{this.router.url.includes('EN')==true?'We have not confirmed payment receipt.':
                                 '現在、入金確認待ちです。'}}
                              </h3>
                           </div>
                        </div>
                        <div *ngIf="this.authService.isLoggedIn() && this.checkSupportMembershipPayment()=='done'">
                           <div  class="d-flex justify-content-center" >
                              <h3>
                                 {{this.router.url.includes('EN')==true?'You have already applied Support membership.':
                                 '維持会員申し込み済'}}<br>
                                 {{this.router.url.includes('EN')==true?'Payment is confirmed already.': '入金確認済。'}}
                              </h3>
                           </div>
                        </div> -->
                     </div>

                  </form>
               </div>
            </mat-card>
             </div>
            <!-- <div *ngIf="!loaded" class="d-flex justify-content-center">
               <div class="d-flex justify-content-center">
                  <div *ngIf="!loaded" class="processing"> {{this.router.url.includes('EN')==true?'Processing...':
                     '処理中....'}}
                     <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                  </div>
               </div>
            </div> -->
   <br>
      <div class="d-flex justify-content-center">
                     <button mat-button (click)="onClickGoBack()" class="btn btn-link">
               {{this.router.url.includes('EN')==true?'Go Back': '戻る'}}
            </button>
      </div>
   </div>
</div>
