<div class="myBase">
  <div class="bg-layer">
    <div class="btnBack">
      <button type="button" class="btn btn-link" (click)="onClickClose()">
        <i class="fas fa-times-circle fa-2x"></i>&nbsp;&nbsp; {{this.router.url.includes('EN')==true?'Close': '閉じる'}}
      </button>
    </div>
    <app-summary-person></app-summary-person>
  </div>
 </div>
 <div class="memoForDevelopper  d-flex justify-content-center">
  conference/EC/summary-person in /dialog/EC/summary-person-embed
 </div>
