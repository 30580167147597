import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
//For Dialog
import {LoginDialogEcComponent} from "../../dialog/login-dialog-ec/login-dialog-ec.component";

export interface DialogData {
  myLanguage:string,
  myEmail:string
}

@Component({
  selector: 'app-login-message-ec',
  templateUrl: './login-message-ec.component.html',
  styleUrls: ['./login-message-ec.component.css']
})
export class LoginMessageEcComponent implements OnInit {
  loaded:boolean=true;
  firstFormGroup: FormGroup;
  myMessage:string;
  mySelectedLanguage:string;

  constructor(
    public router: Router,
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<LoginMessageEcComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog
  ){}

  ngOnInit() {
    this.mySelectedLanguage = this.router.url.includes('EN')==true?"EN":"JP";
    this.firstFormGroup = this._formBuilder.group({
    });
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClickGoToLogin(){
    // const dialogRef = this.dialog.open(LoginDialogEcComponent, {
    //   width: '520px',
    //   height:'570px',
    //   data: {
    //     myLanguage:this.mySelectedLanguage,
    //     myEmail:this.data.myEmail
    //   }
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   var redirectPage = result;
    // //  this.redirectPage(this.codeMaster.CodeType);
    //   if(result!=null){
    //     console.log("Confirmed Action: "+redirectPage);
    //   }
    // })
    // this.dialogRef.close();


    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='580px'; //Desktop
    dialogConfig.maxWidth='500px';//Desktoop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data = { myEmail: this.data.myEmail };
    //const  dialogRef = this.dialog.open(CheckHavingAccountECComponent,dialogConfig);
    const  dialogRef = this.dialog.open(LoginDialogEcComponent,dialogConfig);
    //const  dialogRef = this.dialog.open(LoginSimpleEcComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var redirectPage = result;
      if(result!=null){
        console.log("Confirmed Action: "+redirectPage);
      }
    })
    this.dialogRef.close();

  }
}
