<h1 mat-dialog-title>{{ this.router.url.includes('EN')==true?"JCFN Access Approval Confirmation":"JCFNアクセス変更確認"}}</h1>
<div mat-dialog-content>
 <!-- <p>{{ data.mySelectedLanguage=="EN"?"I Confirmed this amount is correct":"金額が正しいことを確認しました。"}}</p> -->
 <!-- <mat-label>
  {{this.router.url.includes('EN')==true?"Name":"名前"}}
 </mat-label> -->
 <div class="row">
  <div>
  </div>
  <div>
   {{ this.router.url.includes('EN')==true?"Are you sure to approve?":"権限を与えますか？"}}
  </div>
 </div>
</div>
<div mat-dialog-actions>
 <button mat-button (click)="onNoClick()">
  {{ this.router.url.includes('EN')==true?"Cancel":"キャンセル"}}</button>
 <button mat-button [mat-dialog-close]="data.Id" cdkFocusInitial (click)="onApproveClick()">
  {{ this.router.url.includes('EN')==true?"Approve Now":"実行"}}</button>
</div>