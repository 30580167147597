import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { UserService} from "../../../services/user/user.service";
import { Router, ActivatedRoute } from "@angular/router";
import { LoginCheckEmailComponent} from '../../dialog/login-check-email/login-check-email.component';
import { PasswordRecoveryEnterEmailComponent} from '../../dialog/password-recovery-enter-email/password-recovery-enter-email.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import  { User} from "../../../models/user";
import { AuthService } from "../../../services/auth/auth.service";
//For Dialog
import {LoginDialogComponent} from "../../dialog/login-dialog/login-dialog.component";

@Component({
  selector: 'app-enter-new-password-child',
  templateUrl: './enter-new-password-child.component.html',
  styleUrls: ['./enter-new-password-child.component.css']
})
export class EnterNewPasswordChildComponent implements OnInit {
  hideMyPassword: boolean = true;
  loaded:boolean=true;
  myFormGroup: FormGroup;
  password: string;
  rePassword: string;
  token:string;
  //tempToken:string[];
  mySelectedLanguage:string;
  myNewPassword:string;
  myReNewPassword:string;


  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  myMessage:string;

  user: User = {
    PasswordHash:null,
    Id:null,
    UserName:null,
    Email:null,
    FirstName:null,
    LastName:null,
    Password:null,
    Prefix:null,
    Shimei:null,
    Myoji:null,
    Street:null,
    Street2:null,
    City:null,
    State:null,
    Zip:null,
    Country:null,
    Yubin_Bango:null,
    To_Do_Fu_Ken:null,
    Shi_Gun_Ku:null,
    Cho_Son:null,
    Apartment_Etc:null,
    To_Do_Fu_Ken_JP:null,
    Shi_Gun_Ku_JP:null,
    Cho_Son_JP:null,
    Apartment_Etc_JP:null,
    Gender:null,
    DOB:null,
    TelNo:null,
    TimezoneName:null,
    UTCdiff:null,
    Token:null,
    Occupation:null,
    MemberStatus:null,
    Married:null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount:null,
    RegisteredDate:null,
    LastAccessedDate:null,
    //From siteground
    LoginId:null,
    MiddleNameEN:null,
    MiddleNameJP:null,
    PrefixJP:null,
    OccupationDetail:null,
    Organization:null,
    LivingCountry:null,
    PhoneEN:null,
    PhoneMobileEN:null,
    PhoneJP:null,
    PhoneMobileJP:null,
    ChurchNameEN:null,
    ChurchNameJP:null,
    MemberType:null,
    MemberDetail:null,
    MemberMemo:null,
    Shokaisha:null,
    Reason:null,
    NewsLetters:null,
    StaffReports:null,
    OtherLetters:null,
    NewsLetterLanguage:null,
    BasicRoles:null,
    //Added new
    MigrateFromSiteGround:null,
    AdminAccess:null,
    JCFNAccess:null,
    SupportingMember:null,
    LastAddressUpdatedDate:null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };

  constructor(
    private userService: UserService,
    public router: Router,
    public route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EnterNewPasswordChildComponent>,
    private dialog:MatDialog
  ) { }

  tempToken:any[]=[];
  tempToken2nd:any[]=[];
  tempEmail:any[]=[];

  ngOnInit() {
    this.myFormGroup = this._formBuilder.group({
      myPassword: [null, [Validators.required, Validators.minLength(8),Validators.pattern('^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$')]],
     // myPassword: ['', Validators.required],
      myRePassword: ['', Validators.required],
    });

    this.mySelectedLanguage="JP";

    console.log("Token: "+ this.user.Token);
    console.log("Email: "+ this.user.Email);
    console.log("URL:"+ this.router.url)
    if(this.router.url.includes('token')==true){
      console.log("Yes token is in");
      this.tempToken =  this.router.url.split("token");
      console.log("this.tempToken[0]:"+ this.tempToken[0]);
      console.log("this.tempToken[1]:"+ this.tempToken[1]);
      if(this.tempToken.length>1){
        if(this.tempToken[1].includes('recovery')==true){
          console.log('Yes email is in')
          this.tempToken2nd= this.tempToken[1].split("recovery");
          this.user.Token=this.tempToken2nd[0];
          this.user.Email = this.tempToken2nd[1];

          console.log('MY EMAIL:'+ this.user.Email);


          while(this.user.Token.indexOf('$')>-1){
            this.user.Token = this.user.Token.replace('$', '/');
          }
          while(this.user.Token.indexOf('&')>-1){
            this.user.Token = this.user.Token.replace('&', '+');
          }
          while(this.user.Token.indexOf('#')>-1){
            this.user.Token = this.user.Token.replace('#', '==');
          }

          console.log("Born again Token: "+ this.user.Token);
          console.log("Email: "+ this.user.Email);
        }
      }
    }
  }

  onSubmit() {
    if (this.myFormGroup.status == "VALID") {
      if (this.myFormGroup.get('myPassword').value != this.myFormGroup.get('myRePassword').value ) {
        this.myMessage = this.mySelectedLanguage=="EN"?"Password does not match.":"パスワードがマッチしていません。";
        this.openSnackBar('error', 'close');
      } else {
        this.loaded = false;
        this.user.Password = this.myFormGroup.get('myPassword').value;
        console.log('email !: '+ this.user.Email);
        console.log('password !: '+ this.user.Password);
        this.userService.passwordRecovery(this.user.Email, this.user).subscribe(
          data => {
            this.myMessage = this.mySelectedLanguage=="EN"?"Password changed successfully.":"パスワードが変更されました。";
            this.openSnackBar('success', 'close');
            this.dialogRef.close();
            this.router.navigate(["/home/" + this.mySelectedLanguage]);
            this.loaded = true;
          },
          error => {
            this.myMessage = this.mySelectedLanguage=="EN"?"Password Reset failed.":"パスワードリセットに失敗しました。";
            this.openSnackBar('error', 'close');
            this.loaded = true;
          }
        );
      }
    }
  }

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 5000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 20000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }

  onClickCancel(){
    this.router.navigate(["/home/" + this.mySelectedLanguage]);
    this.dialogRef.close();
  }

  onClickBack(){
    this.router.navigate(["/home/" + this.mySelectedLanguage]);
    this.dialogRef.close();
  }

}
