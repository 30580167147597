import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService } from "../../../services/auth/auth.service";

@Component({
  selector: 'app-processed-successfully',
  templateUrl: './processed-successfully.component.html',
  styleUrls: ['./processed-successfully.component.css']
})
export class ProcessedSuccessfullyComponent implements OnInit {
  mySelectedLanguage:string;

  constructor(
    public router: Router,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.mySelectedLanguage = this.router.url.includes('EN')==true?"EN":"JP";
  }

}
