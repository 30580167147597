<div class="myBody  myBodySub">
  <div class="myHeaderBase d-flex justify-content-center">
    <div class="myHeaderCore">
      <div>
        <!-- <img src='../../../../../assets/images/thumbnail_ec22logo.jpg'  class="ecLogo"> -->
      </div>
    </div>
  </div>
  <div class="myMain">
    <div clas="myMainLeft">
      <div class="myMainHalfTop">
        <!-- Left Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Left Bottom -->
      </div>
    </div>
    <div class="myMainCenter">
      <div class="myMainHalfTop d-flex justify-content-center">
        <!-- Main Top -->

        <mat-card class="mainCard b-radius">
          <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
          <div *ngIf="!loaded">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <!-- <form *ngIf="userFromServer | async; else loading"  -->
          <form [formGroup]="myFormGroup" (ngSubmit)="onSubmit()">
            <div class="float-end stepNumber">7/8</div>
            <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Scholarship':
              '奨学金'}}
            </h2>
            <div class="stepTitle2nd">
              {{this.router.url.includes('EN')==true?'Are you applying for a scholarship?':
              '奨学金を申込みますか。'}}
            </div>
            <div class="d-flex justify-content-center">
              <mat-radio-group formControlName="myApplyScholarship" (change)="onChangeApplyScholarship($event)"
                class="rbGroup">
                <mat-radio-button value=true class="rbItem" [checked]="conference.ApplyScholarship==true">
                  {{this.router.url.includes('EN')==true?'Yes': 'はい'}}</mat-radio-button>
                <mat-radio-button value=false class="rbItem" [checked]="conference.ApplyScholarship==false">
                  {{this.router.url.includes('EN')==true?'No': 'いいえ'}}</mat-radio-button>
              </mat-radio-group>
              <mat-error
                *ngIf="myFormGroup.get('myApplyScholarship').hasError('required')  && myFormGroup.get('myApplyScholarship').touched">
                {{this.router.url.includes('EN')==true?'Japan Regional Meeting is required': '未入力'}}
              </mat-error>
            </div>
            <br>
            <br>


            <div class="stepTitle2nd">
              <div *ngIf="this.router.url.includes('EN')!=true">
                {{'奨学金を申し込む方は、こちらのフォームにご記入ください。'}}
                <a href="https://forms.gle/HNWgzaEcHeV2p6zu7"
                  target="_blank">
                  {{ 'GRC23奨学金申込書' }}
                </a>
              </div>
              <div *ngIf="this.router.url.includes('EN')==true">
                {{'If you are applying for a scholarship, please fill out this form.'}}
                <a href="https://forms.gle/HNWgzaEcHeV2p6zu7"
                  target="_blank">
                  {{ 'Scholarship Application Form' }}
                </a>
              </div>
            </div>



            <div class=" position-absolute bottom-0 start-50 translate-middle-x">
              <div class="d-flex justify-content-center">
                <button type="button" class="btn btnNext" (click)="onClickGoBack()">
                  {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                </button>
                <button [disabled]="!myFormGroup.valid" type="button" class="btn btnNext"
                  (click)="onClickGoForward()">
                  {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                </button>
              </div>
              <div class="d-flex justify-content-center">
                <button type="button" class="btn link" (click)="cancelProcess()">
                  {{this.router.url.includes('EN')==true?'Cancel': 'キャンセル'}}
                </button>
              </div>
            </div>
            <!-- <button type="submit" [disabled]="!myFormGroup.valid">Submit</button> -->
          </form>
        </mat-card>
      </div>
      <div class="myMainHalfBottom">
        <!-- Main Bottom -->
      </div>
    </div>
    <div class="myMainRight">
      <div class="myMainHalfTop">
        <!-- Right Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Right Bottom -->
      </div>
    </div>
  </div>
  <!-- <footer>footer</footer> -->
</div>
