import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router, ActivatedRoute } from "@angular/router";
export interface DialogData {
  TemplateName: string;
}

@Component({
  selector: 'app-delete-template-confirmation',
  templateUrl: './delete-template-confirmation.component.html',
  styleUrls: ['./delete-template-confirmation.component.css']
})
export class DeleteTemplateConfirmationComponent implements OnInit {

  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<DeleteTemplateConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit() {
    console.log('going to delete'+this.data.TemplateName);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onDeleteClick():void{
    console.log('going to delete');
  }
}
