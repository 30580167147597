<div class="myBase">
  <div class="myBackgroundLayer">
    <div class="myContainer">

      <div class="truckingTitle">
        <div class="col-md-6">
          <h2><i class="fas fa-users fa-2x"></i>&nbsp; Members</h2>
        </div>
      </div>

      <form #mySearchForm="ngForm" (ngSubmit)="onSearchSubmit(mySearchForm)">
        <div class="divSearchBox d-flex">
          <div class="row me-auto">
            <div class="col searchItem">
              <mat-form-field class="containerSearchMd" appearance="outline">
                <mat-label>First Name</mat-label>
                <input [(ngModel)]="memberList.FirstName" name="FirstName" matInput placeholder="First Name"
                  class="matInputBox" />
              </mat-form-field>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearchMd" appearance="outline">
                <mat-label>Last Name</mat-label>
                <input [(ngModel)]="memberList.LastName" name="LastName" matInput placeholder="Last Name"
                  class="matInputBox" />
              </mat-form-field>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearchLg" appearance="outline">
                <mat-label>Email</mat-label>
                <input [(ngModel)]="memberList.Email" name="Email" matInput placeholder="Email" class="matInputBox" />
              </mat-form-field>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearch" appearance="outline">
                <mat-label>Genders</mat-label>
                <mat-select name="SelectedGender" [(ngModel)]="memberList.Gender" class="matInputBox"
                  (selectionChange)="onSelectGender($event.value)">
                  <mat-option *ngFor="let gender of genders" [value]="gender.value" class="matInputBox">
                    {{ gender.display }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearchSm" appearance="outline">
                <mat-label>Min Age</mat-label>
                <input matInput type="number" name='MinAge' [(ngModel)]="memberList.MinAge" class="matInputBox">
              </mat-form-field>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearchSm" appearance="outline">
                <mat-label>Max Age</mat-label>
                <input matInput type="number" name='MaxAge' [(ngModel)]="memberList.MaxAge" class="matInputBox">
              </mat-form-field>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearch" appearance="outline">
                <mat-label>Language</mat-label>
                <mat-select name="SelectedNewsLetterLanguages" [(ngModel)]="memberList.NewsLetterLanguage"
                  class="matInputBox" (selectionChange)="onSelectNewsLetterLanguage($event.value)">
                  <mat-option *ngFor="let newsLetterLanguage of newsLetterLanguages" [value]="newsLetterLanguage.value"
                    class="matInputBox">
                    {{ newsLetterLanguage.display }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearch" appearance="outline">
                <mat-label>Country</mat-label>
                <mat-select name="SelectedCountry" [(ngModel)]="memberList.Country" class="matInputBox"
                  (selectionChange)="onSelectCountry($event.value)">
                  <mat-option *ngFor="let country of countries" [value]="country.value" class="matInputBox">
                    {{ country.display }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearch" appearance="outline">
                <mat-label>都道府県</mat-label>
                <mat-select name="SelectedToDoFuKen" [(ngModel)]="memberList.To_Do_Fu_Ken" class="matInputBox"
                  (selectionChange)="onSelectToDoFuKen($event.value)">
                  <mat-option *ngFor="let to_do_fu_kenJP of to_do_fu_kensJP" [value]="to_do_fu_kenJP.value"
                    class="matInputBox">
                    {{ to_do_fu_kenJP.display }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearchMd" appearance="outline">
                <mat-label>市郡区</mat-label>
                <input [(ngModel)]="memberList.Shi_Gun_Ku" name="Shi_Gun_Ku" matInput placeholder="市郡区"
                  class="matInputBox" />
              </mat-form-field>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearchMd" appearance="outline">
                <mat-label>US State</mat-label>
                <mat-select name="SelectedState" [(ngModel)]="memberList.State" class="matInputBox"
                  (selectionChange)="onSelectState($event.value)">
                  <mat-option *ngFor="let state of states" [value]="state.value" class="matInputBox">
                    {{ state.display }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearchMd" appearance="outline">
                <mat-label>City</mat-label>
                <input [(ngModel)]="memberList.City" name="City" matInput placeholder="City" class="matInputBox" />
              </mat-form-field>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearchLg" appearance="outline">
                <mat-label>Membership:Old DB</mat-label>
                <mat-select name="SelectedMemberType" [(ngModel)]="memberList.MemberType" class="matInputBox"
                  (selectionChange)="onSelectMemberType($event.value)">
                  <mat-option *ngFor="let memberType of memberTypes" [value]="memberType.value" class="matInputBox">
                    {{ memberType.display }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col searchItem">
              <mat-form-field class="containerSearchLg" appearance="outline">
                <mat-label>Membership:New DB</mat-label>
                <mat-select name="SelectedMembershipType" [(ngModel)]="memberList.MyMembershipTypes" multiple
                  class="matInputBox" (selectionChange)="onSelectMembershipType($event)">
                  <mat-option *ngFor="let listMembershipType of listMembershipTypes" [value]="listMembershipType"
                    class="matInputBox">
                    {{ listMembershipType.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>


              <div class="col searchItem">
                <mat-form-field class="containerSearchMd EC20BeyondReunionOuter" appearance="outline">
                  <mat-label>EC20 Reunion Participate</mat-label>
                  <mat-select name="SelectedEC20BeyondReunionAttend" [(ngModel)]="memberList.Attend" class="matInputBox  EC20BeyondReunionInner"
                    (selectionChange)="onSelectEC20BeyondReunionAttend($event.value)">
                    <mat-option *ngFor="let ec20ReunionAttend of ec20ReunionAttends" [value]="ec20ReunionAttend.value" class="matInputBox">
                      {{ ec20ReunionAttend.display }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col searchItem">
                <mat-form-field class="containerSearchMd  EC20BeyondReunionOuter" appearance="outline">
                  <mat-label>EC20 Reunion Language</mat-label>
                  <mat-select name="SelectedEC20BeyondReunionLanguage" [(ngModel)]="memberList.Language" class="matInputBox  EC20BeyondReunionInner"
                    (selectionChange)="onSelectEC20BeyondReunionLanguage($event.value)">
                    <mat-option *ngFor="let ec20ReunionLanguage of ec20ReunionLanguages" [value]="ec20ReunionLanguage.value" class="matInputBox">
                      {{ ec20ReunionLanguage.display }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>



          </div>
        </div>

        <div class="divSearchBox d-flex">
          <div class="row  me-auto" id="btnGroup">
            <div class="col"  id="linkShowAllUsers">
              <button type="button" class="btn btn-link linkToDownload" (click)="showAllUsers()">
                Show all users</button>
            </div>

            <div class="col">
              <button [disabled]="!mySearchForm.form.valid" class="btn btn-primary btnInSearchBox">
                <i class="fa fa-search fa-fw"></i> Search</button>
            </div>
            <div class="col">
              <button (click)="onCancel($event)" [disabled]="!mySearchForm.form.valid"
                class="btn btn-secondary  btnInSearchBox">
                Clear
              </button>
            </div>
            <div class="col">
              <button mat-button (click)="exportToExcel()" class="btn btn-success btnDownload">
                Download
              </button>
            </div>
            <div class="col">
              <h4 *ngIf="showNumberOfUser" id="numberOfUsers">
                {{ cntUser }} Registrant(s) Found
              </h4>
            </div>
          </div>
        </div>

      </form>
      <div>
        <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
        <div *ngIf="!loaded">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <br>
        <div class="registrantList">
          <div>
            <div>
              <!-- <table class="table table-striped table-bordered table-hover tblRegistrant" mat-table
                [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"> -->
                <table class="table table-striped table-bordered table-hover tblRegistrant" mat-table
                [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="LastName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                    Last Name</th>
                  <td mat-cell *matCellDef="let row" class="columnMdTd"> {{row.LastName}}<br>{{row.Myoji}} </td>
                </ng-container>

                <ng-container matColumnDef="FirstName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                    First Name</th>
                  <td mat-cell *matCellDef="let row" class="columnMdTd"> {{row.FirstName}}<br>{{row.Shimei}} </td>
                </ng-container>

                <ng-container matColumnDef="Email" class="columnSm">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                    Email</th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd">
                    <!-- <a href="mailto:{{ row.Email }}" target="_top">
                      Email</a> -->
                      {{row.Email}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Gender">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                    Gender</th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Gender}} </td>
                </ng-container>

                <ng-container matColumnDef="Age">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="age" class="columnSm">
                    Age</th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd"> {{calculate_age(row.DOB)}} </td>
                </ng-container>
                <ng-container matColumnDef="NewsLetterLanguage">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                    News Letter Language</th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.NewsLetterLanguage}} </td>
                </ng-container>
                <ng-container matColumnDef="Country">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                    Current Living Country</th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Country}} </td>
                </ng-container>
                <ng-container matColumnDef="State">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                    都道府県<br>State</th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd">
                    {{row.To_Do_Fu_Ken!=null?row.To_Do_Fu_Ken.split(':')[1]:row.To_Do_Fu_Ken_JP}} <br>
                    {{row.State}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="City">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                    市郡区<br>City</th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd">
                    {{row.Shi_Gun_Ku!=null?row.Shi_Gun_Ku:row.Shi_Gun_Ku_JP}}<br>
                    {{row.City}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="MemberType">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                    Member Type<br>Old Database</th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd"> {{convertMemberType(row.MemberType)}} </td>
                </ng-container>
                <ng-container matColumnDef="MembershipType">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                    Member Type<br>New Database</th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd">
                    {{row.MembershipType}} </td>
                </ng-container>

                <ng-container matColumnDef="EC20BeyondReunionAttend">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm columnEC20BeyondReunion">
                    EC Reunion<br>Participate</th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd">
                    {{row.Attend==true?"Yes":"No"}} </td>
                </ng-container>
                <ng-container matColumnDef="EC20BeyondReunionLanguage">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                    EC Reunion<br>Language</th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd">
                    {{row.Language}} </td>
                </ng-container>

                <ng-container matColumnDef="CreatedDate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm columnEC20BeyondReunion" >
                    Created</th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.CreatedDate  | date: 'shortDate'}} </td>
                </ng-container>
                <ng-container matColumnDef="LastModifiedDate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                    Last Modified </th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.LastModifiedDate  | date: 'shortDate'}} </td>
                </ng-container>


                <ng-container matColumnDef="myEditProfile">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">My Account</th>
                  <td mat-cell *matCellDef="let row" class="columnSmTd">
                    <button mat-button (click)="onClickGoToMyAccount(row)" class="btn btn-link editLink">My Account
                    </button>
                    <!-- <button  mat-button (click)="onClickEditMembership(row)"
                   class="btn  btn-link editLink">メンバーシップ</button> -->
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                </tr>
              </table>

              <mat-paginator [pageSize]="50" [pageSizeOptions]="[5, 10, 25, 100, 200, 300, 400]"></mat-paginator>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
