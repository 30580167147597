import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig} from '@angular/material/dialog';
import { FormBuilder, FormGroup,  Validators,AbstractControl, FormControl} from '@angular/forms';
import { UserService} from "../../../services/user/user.service";
import { Router, ActivatedRoute } from "@angular/router";
import { EnterSearchingEmailComponent} from "../../dialog/enter-searching-email/enter-searching-email.component";
import { UserEditOthersProfileComponent } from '../user-edit-others-profile/user-edit-others-profile.component';

export interface DialogData {
  myLanguage:string
}

@Component({
  selector: 'app-check-having-account',
  templateUrl: './check-having-account.component.html',
  styleUrls: ['./check-having-account.component.css']
})
export class CheckHavingAccountComponent implements OnInit {
  loaded:boolean=true;
  firstFormGroup: FormGroup;
  myMessage:string;

  constructor(
    public router: Router,
    private _formBuilder: FormBuilder,
    private userService: UserService,
    public dialogRef: MatDialogRef<CheckHavingAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog
  ){}

  ngOnInit() {
    //sessionStorage.clear();
    this.firstFormGroup = this._formBuilder.group({

    });
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClickEnterSearchingEmail(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    // dialogConfig.maxHeight='500px'; //Desktop
     dialogConfig.maxWidth='600px';//Desktoop
    // dialogConfig.height='100%';//Mobile
     dialogConfig.width='95%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={myLanguage:this.getMyLanguage()};
    const  dialogRef = this.dialog.open(EnterSearchingEmailComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var redirectPage = result;
    //  this.redirectPage(this.codeMaster.CodeType);
      if(result!=null){
        console.log("Confirmed Action: "+redirectPage);
      }
    })
    this.dialogRef.close();
  }

  onClickCreateUser(){
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
 // dialogConfig.autoFocus = false;
   dialogConfig.maxHeight='800px'; //Desktop
  dialogConfig.maxWidth='1000px';//Desktoop
  // dialogConfig.height='100%';//Mobile
  // dialogConfig.width='100%';//Mobile
  dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
  dialogConfig.data={ Id:  ''};
  const dialogRef = this.dialog.open(UserEditOthersProfileComponent,dialogConfig);
  dialogRef.afterClosed().subscribe(result => {
    var confirmedAction = result;
    if (result != null) {
      console.log("Confirmed Action: " + confirmedAction);
      console.log("returned AS400Code: " + confirmedAction.AS400Code);
    }
  })
  this.dialogRef.close();
  }

  onClickBack(){
    this.dialogRef.close();
  }


}
