import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from "@angular/router";
import { UserConference } from 'src/app/models/conference/userConference';
export interface DialogData {
  userId: string
}
@Component({
  selector: 'app-my-account-edit',
  templateUrl: './my-account-edit.component.html',
  styleUrls: ['./my-account-edit.component.css']
})
export class MyAccountEditComponent implements OnInit {

  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<MyAccountEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    //admin画面からユーザーのmy accountを閲覧する度にセッションを作り直す
    sessionStorage.removeItem("myECSearchingUserId");
    sessionStorage.setItem("myECSearchingUserId", this.data.userId);
  }

  ngOnInit(): void {

  }

  onClickClose() {
    //Adminによる作業中のユーザーのセッションを消しておく
    if (sessionStorage.getItem("myECSearchingUserId") != null) {
      sessionStorage.removeItem("myECSearchingUserId");
    }
    sessionStorage.removeItem("myECSearchingUser");
    this.dialogRef.close();
  }
}
