import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA,MatDialogConfig} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { UserService} from "../../../services/user/user.service";
import { Router, ActivatedRoute } from "@angular/router";
import { User } from "../../../models/user";
import { AuthService } from "../../../services/auth/auth.service";
import { LoginCheckEmailComponent} from "../../dialog/login-check-email/login-check-email.component";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PasswordRecoveryEnterEmailECComponent } from '../password-recovery-enter-email-ec/password-recovery-enter-email-ec.component';

export interface DialogData {
  myLanguage:string
  myEmail:string
}


@Component({
  selector: 'app-login-dialog-ec',
  templateUrl: './login-dialog-ec.component.html',
  styleUrls: ['./login-dialog-ec.component.css']
})
export class LoginDialogEcComponent implements OnInit {
  loaded:boolean=true;
  firstFormGroup: FormGroup;
  myMessage:string;
  loading = false;
  mySelectedLanguage:string;
  Email: string;
  Password: string;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  user: User = {
    PasswordHash:null,
    Id:null,
    UserName:null,
    Email:null,
    FirstName:null,
    LastName:null,
    Password:null,
    Prefix:null,
    Shimei:null,
    Myoji:null,
    Street:null,
    Street2:null,
    City:null,
    State:null,
    Zip:null,
    Country:null,
    Yubin_Bango:null,
    To_Do_Fu_Ken:null,
    Shi_Gun_Ku:null,
    Cho_Son:null,
    Apartment_Etc:null,
    To_Do_Fu_Ken_JP:null,
    Shi_Gun_Ku_JP:null,
    Cho_Son_JP:null,
    Apartment_Etc_JP:null,
    Gender:null,
    DOB:null,
    TelNo:null,
    TimezoneName:null,
    UTCdiff:null,
    Token:null,
    Occupation:null,
    MemberStatus:null,
    Married:null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount:null,
    RegisteredDate:null,
    LastAccessedDate:null,
    //From siteground
    LoginId:null,
    MiddleNameEN:null,
    MiddleNameJP:null,
    PrefixJP:null,
    OccupationDetail:null,
    Organization:null,
    LivingCountry:null,
    PhoneEN:null,
    PhoneMobileEN:null,
    PhoneJP:null,
    PhoneMobileJP:null,
    ChurchNameEN:null,
    ChurchNameJP:null,
    MemberType:null,
    MemberDetail:null,
    MemberMemo:null,
    Shokaisha:null,
    Reason:null,
    NewsLetters:null,
    StaffReports:null,
    OtherLetters:null,
    NewsLetterLanguage:null,
    BasicRoles:null,
      //Added new
      MigrateFromSiteGround:null,
      AdminAccess:null,
      JCFNAccess:null,
      SupportingMember:null,
      LastAddressUpdatedDate:null,
      AcceptMembershipTerm:null,
      MembershipType:null
  };


  constructor(
    private authService: AuthService,
    public router: Router,
    private _formBuilder: FormBuilder,
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<LoginDialogEcComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog
  ){}

  ngOnInit() {
    sessionStorage.clear();
 //   if(this.router.url.includes('home')!=true){
      this.user.Email =this.data.myEmail;
      this.firstFormGroup = this._formBuilder.group({
        myEmail: ['', Validators.required],
        myPassword: ['', Validators.required],
      });
 //   }
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClickBack(){
    this.dialogRef.close();
  }

  onClickForgotPassword(){
    // const dialogRef = this.dialog.open(PasswordRecoveryEnterEmailECComponent, {
    //   width: '500px',
    //   height:'530px',
    //   data: {
    //     myEmail:this.data.myEmail
    //   }
    // });
    // this.dialogRef.close();

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='580px'; //Desktop
    dialogConfig.maxWidth='500px';//Desktoop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data = { myEmail: this.user.Email };
    //const  dialogRef = this.dialog.open(CheckHavingAccountECComponent,dialogConfig);
    //const  dialogRef = this.dialog.open(LoginDialogEcComponent,dialogConfig);
    const  dialogRef = this.dialog.open(PasswordRecoveryEnterEmailECComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var redirectPage = result;
      if(result!=null){
        console.log("Confirmed Action: "+redirectPage);
      }
    })
    this.dialogRef.close();
  }

  onCheckLogin() {
    if (this.firstFormGroup.get('myPassword').status== 'VALID') {
      this.loaded = false;
      this.authService.login(this.user.Email, this.user.Password).subscribe(
        result => {
          //Login Sussessfully.
          this.userService.getUserProfile(this.user.Email).subscribe(
            result => {
              this.user = result;
                  this.loaded = true;
                  this.router.navigate(["/conference/EC/myAccount/"+this.getMyLanguage()]);
                  this.dialogRef.close();
            }
          )
        },
        error => {
          this.myMessage = this.getMyLanguage()=="EN"?"Login failed.":"ログインに失敗しました。";
          this.openSnackBar('error', 'close');
          this.loaded = true;
        }
      )
    }
  }

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 5000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 12000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }

}
