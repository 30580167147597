import { Injectable } from '@angular/core';
import { User } from "../../../models/user";
import { EC20BeyondReunion} from "../../../models/conference/EC20BeyondReunion";
import { MemberList} from "../../../models/memberList";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../../../app.config";
import { Observable } from "rxjs";
import { AuthService } from '../../auth/auth.service'; 

@Injectable({
  providedIn: 'root'
})
export class Ec20BeyondReunionService {
  constructor(
    private http: HttpClient,
    private authService:AuthService,
    private config: AppConfig) { }


    attendEC20BeyondReunion(registrant:EC20BeyondReunion):Observable<any>{
      return this.http.post<any>(
        this.config.apiMyUrl + "/api/conference/attendEC20BeyondReunion",
        registrant
      );
    }   

    getEC20BeyondReunionByUser(user:User):Observable<EC20BeyondReunion>{
      return this.http.post<EC20BeyondReunion>(
        this.config.apiMyUrl + "/api/conference/getEC20BeyondReunionByUser",
        user,{ headers: this.authService.getJWT()  }
      );
    }

}
