<div class="myBase">
    <div class="bg-layer">
        <div class="btnBack">
            <button type="button" class="btn btn-link" (click)="onClickClose()">
                <i class="fas fa-times-circle fa-2x"></i>&nbsp;&nbsp;
                {{this.router.url.includes('EN')==true?'Close': '閉じる'}}
            </button>
        </div>

        <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
        <div *ngIf="!loaded">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div *ngIf="loaded">
            <mat-card class="RegistrationCardList">
                <form [formGroup]="firstFormGroup">
                    <mat-card-header>
                        <mat-card-title>
                            Create Template
                        </mat-card-title>
                        <mat-card-subtitle>
                            * ... Required
                        </mat-card-subtitle>
                    </mat-card-header>
                    <mat-form-field appearance="outline" class="registrationInputForm">
                        <mat-label>Columns *</mat-label>
                        <mat-select placeholder="" formControlName="mySelectedCreateColumns"
                            [(ngModel)]="memberListGridTemplate.SelectedColumns" class="matInputBox" ngDefaultControl
                            multiple (selectionChange)="onSelectCreatingColumn(memberListGridTemplate.SelectedColumns)">
                            <mat-option *ngFor="let columnSelection of columnSelections" [value]="columnSelection"
                                class="matInputBox">
                                {{ columnSelection.value=="Shimei"?"Namae":columnSelection.value.replace('_JP','_Kanji').
                            replace('NewsLetterLanguage','News_Letter_Lan').replace('Language','Conference_SG_Lan').
                        replace('Attend','EC20_Reunion_Attend') }}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            No columns selected
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="registrationInputForm">
                        <mat-label>
                            Template Name
                        </mat-label>
                        <input matInput placeholder="" formControlName="myCreateTemplateName"
                            [(ngModel)]="memberListGridTemplate.TemplateName" required class="registrationInputBox"
                            cdkFocusInitial>
                        <mat-error>
                            Template Name is required
                        </mat-error>
                    </mat-form-field>
                    <div *ngIf="!loaded">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </div>
                    <div *ngIf="!loaded" class="progressMessage">
                        Saving...
                    </div>
                    <div class="divUpdateButton  d-flex justify-content-center">
                        <button mat-button (click)="onClickCreateCancel()" class="btnRegistrantUpdate">Cancel</button>
                        <button [disabled]="this.disableCreateButton" mat-button (click)="insertNewTemplate()"
                            class="btnRegistrantUpdate" cdkFocusInitial>Save</button>
                    </div>
                </form>
                <form [formGroup]="secondFormGroup">
                    <br><br><br><br>
                    <mat-card-header>
                        <mat-card-title>
                            Edit Template
                        </mat-card-title>
                        <mat-card-subtitle>
                            * ... Required
                        </mat-card-subtitle>
                    </mat-card-header>

                    <mat-form-field appearance="outline" class="registrationInputForm">
                        <mat-label>My Template *</mat-label>
                        <mat-select name="SelectedMemberType" class="matInputBox" formControlName="myEditTemplateName"
                        [(ngModel)]="memberListGridTemplate.TemplateName"
                            (selectionChange)="onChangeSelectEditingTemplateName($event.value)">
                            <mat-option *ngFor="let templateName of templateNames" [value]="templateName"
                                class="matInputBox">
                                {{ templateName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="registrationInputForm">
                        <mat-label>Columns *</mat-label>
                        <mat-select placeholder="" formControlName="mySelectedEditColumns"
                            [formControl]="editingColumnForm" [(ngModel)]="memberListGridTemplate.SelectedColumns"
                            class="matInputBox" ngDefaultControl multiple
                            (selectionChange)="onSelectEditingColumn(memberListGridTemplate.SelectedColumns)">
                            <mat-option *ngFor="let columnSelection of columnSelections" [value]="columnSelection"
                                class="matInputBox">
                                {{ columnSelection.value=="Shimei"?"Namae":columnSelection.value.replace('_JP','_Kanji').
                            replace('NewsLetterLanguage','News_Letter_Lan').replace('Language','Conference_SG_Lan').
                            replace('Attend','EC20_Reunion_Attend') }}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            No columns selected
                        </mat-error>
                    </mat-form-field>


                    <div *ngIf="editing">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </div>
                    <div *ngIf="editing" class="progressMessage">
                        Editing...
                    </div>
                    <div class="divUpdateButton  d-flex justify-content-center">
                        <button mat-button (click)="onClickEditCancel()" class="btnRegistrantUpdate">Cancel</button>
                        <button mat-button (click)="onConfirmUpdate()" [disabled]="this.disableUpdateButton"
                            class="btnRegistrantUpdate" cdkFocusInitial>Update</button>
                    </div>
                </form>
                <form [formGroup]="thirdFormGroup">
                    <br><br><br><br>
                    <mat-card-header>
                        <mat-card-title>
                            Delete Template
                        </mat-card-title>
                        <mat-card-subtitle>
                            * ... Required
                        </mat-card-subtitle>
                    </mat-card-header>
                    <div class="col searchItem registrationInputForm">
                        <mat-form-field appearance="outline" class="registrationInputForm">
                            <mat-label>My Template *</mat-label>
                            <mat-select name="SelectedMemberType" class="matInputBox"
                                formControlName="myDeleteTemplateName"   [(ngModel)]="memberListGridTemplate.TemplateName"
                                (selectionChange)="onChangeSelectDeletingTemplateName($event.value)">
                                <mat-option *ngFor="let templateName of templateNames" [value]="templateName"
                                    class="matInputBox">
                                    {{ templateName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="deleting">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </div>
                    <div *ngIf="deleting" class="progressMessage">
                        Deleting...
                    </div>
                    <div class="divUpdateButton  d-flex justify-content-center">
                        <button mat-button (click)="onClickDeleteCancel()" class="btnRegistrantUpdate">Cancel</button>
                        <button mat-button (click)="onConfirmDelete()" [disabled]="this.disableDeleteButton"
                            class="btnRegistrantUpdate" cdkFocusInitial>Delete</button>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</div>