<div class="myBase">
  <div class="myBackgroundLayer">
   <div class="row">
    <div class="myAccountTitle">
     <h2>
     </h2>
    </div>
   </div>
   <div class="d-flex justify-content-center">
    <mat-card class="supportingMembershipAnounce">

     <h3>
      {{this.router.url.includes('EN')==true?'Payment unsuccessful': '支払い処理中に問題が発生しました。'}}
     </h3>
    <h3>
     {{this.router.url.includes('EN')==true?'Please contact JCFN office': 'JCFN事務局までご連絡ください'}}<br>
     Email: info@jcfn.org
    </h3>
     <br><br><br>

       <a [routerLink]="this.router.url.includes('EN')==true?'/home/EN': '/home/JP'"
         class="btn btn-link">{{this.router.url.includes('EN')==true?'Home': 'ホーム'}}</a>&nbsp;&nbsp;
         <a [routerLink]="this.router.url.includes('EN')==true?'/conference/EC22/myAccount/EN': '/conference/EC22/myAccount/JP'"
         class="btn btn-link">{{this.router.url.includes('EN')==true?'My Account': 'マイアカウント'}}</a>

    </mat-card>
   </div>
  </div>
 </div>
