import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { AuthService } from "../../../../services/auth/auth.service";
import { GrcRegistrationService } from "../../../../services/conference/grc/grc-registration.service"
import { User } from "../../../../models/user";
//import { Conference } from "../../../models/conference";
import { UserService } from "../../../../services/user/user.service";
import { MatRadioChange } from '@angular/material/radio';
import { MatStepper } from '@angular/material/stepper';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
//For Dialog
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { UserEditOthersProfileComponent } from '../../../dialog/user-edit-others-profile/user-edit-others-profile.component';
import { RelatedPerson } from "../../../../models/conference/RelatedPerson";
import { VolunteerSelection } from "../../../../models/conference/volunteerSelection";
import { UserConference } from 'src/app/models/conference/userConference';
import { UserConferenceSubProfileGRC } from 'src/app/models/conference/userConferenceSubProfileGRC';
import { MatTableDataSource } from '@angular/material/table';
import { AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CheckGroup } from '../../../../models/checkGroup';
import { MyAccountEditComponent } from 'src/app/components/dialog/EC/my-account-edit/my-account-edit.component';
import { GRCConference, GRCRelatedPerson, GRCVolunteerSelection } from 'src/app/models/conference/GRCConference';
import { MyAccountEditGRCComponent } from 'src/app/components/dialog/GRC/my-account-edit-grc/my-account-edit-grc.component';

@Component({
  selector: 'app-grc-participant',
  templateUrl: './grc-participant.component.html',
  styleUrls: ['./grc-participant.component.css']
})
export class GrcParticipantComponent implements OnInit {
  loaded: boolean = true;
  myFormGroup: FormGroup;
  checkGroup: CheckGroup = {
    id: null,
    value: null,
  };
  checkGroups: CheckGroup[] = [];

  genders = [
    { value: null, display: "" },
    { value: "Male", display: "男性" },
    { value: "Female", display: "女性" },
  ];
  languages = [
    { value: null, display: "" },
    { value: "Japanese", display: "日本語" },
    { value: "English", display: "英語" },
    { value: "Bilingual", display: "バイリンガル" },
  ]
  volunteers = [
    { value: null, display: "" },
    { value: 'Prayer', display: 'Prayer' },
    { value: 'Praise Team', display: 'Praise Team' },
    { value: 'Bible Reading', display: 'Bible Reading' },
    { value: 'Skit', display: 'Skit' },
    { value: 'PA', display: 'PA' },
    { value: 'Propresenter', display: 'Propresenter' },
    { value: 'Video/Photo', display: 'Video/Photo' },
    { value: 'Translator', display: 'Translator' },
    { value: 'Interpreter', display: 'Interpreter' },
    { value: 'Usher', display: 'Usher' },
    { value: 'Small Group Leader', display: 'Small Group Leader' },
    { value: 'Workshop Helper', display: 'Workshop Helper' },
    { value: 'Kids Program Helper', display: 'Kids Program Helper' },
    { value: 'Book Store', display: 'Book Store' },
    { value: 'Nursing', display: 'Nursing' },
    { value: 'Reception', display: 'Reception' },
    { value: 'Counselor', display: 'Counselor' },
    { value: 'Sharing Testimony', display: 'Sharing Testimony' }
  ]
  listVolunteers: CheckGroup[] =
    [
      { id: 0, value: 'Prayer' },
      { id: 1, value: 'Praise Team' },
      { id: 2, value: 'Bible Reading' },
      { id: 3, value: 'Skit' },
      { id: 4, value: 'PA' },
      { id: 5, value: 'Propresenter' },
      { id: 6, value: 'Video/Photo' },
      { id: 7, value: 'Translator' },
      { id: 8, value: 'Interpreter' },
      { id: 9, value: 'Usher' },
      { id: 10, value: 'Small Group Leader' },
      { id: 11, value: 'Workshop Helper' },
      { id: 12, value: 'Kids Program Helper' },
      { id: 13, value: 'Book Store' },
      { id: 14, value: 'Nursing' },
      { id: 15, value: 'Reception' },
      { id: 16, value: 'Counselor' },
      { id: 17, value: 'Sharing Testimony' }

    ]

  firstFormGroup: FormGroup;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  myMessage: string;
  mySelectedLanguage: string;
  cntUser: number;
  showNumberOfUser: boolean;
  payment: string;
  scholarship: string;

  displayedColumns: string[] = ['LastName', 'FirstName',
     'Gender', 'Age', 'Student','Christian','SmallGroupLanguage',
 'myVolunteerSelections','myVolunteerSelectionsApproved','myRelatedPersons','RegisteredDate','myEditProfile'
  ];

  dataSource: MatTableDataSource<UserConferenceSubProfileGRC>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  user: User = {
    PasswordHash: null,
    Id: null,
    UserName: null,
    Email: null,
    FirstName: null,
    LastName: null,
    Password: null,
    Prefix: null,
    Shimei: null,
    Myoji: null,
    Street: null,
    Street2: null,
    City: null,
    State: null,
    Zip: null,
    Country: null,
    Yubin_Bango: null,
    To_Do_Fu_Ken: null,
    Shi_Gun_Ku: null,
    Cho_Son: null,
    Apartment_Etc: null,
    To_Do_Fu_Ken_JP: null,
    Shi_Gun_Ku_JP: null,
    Cho_Son_JP: null,
    Apartment_Etc_JP: null,
    Gender: null,
    DOB: null,
    TelNo: null,
    TimezoneName: null,
    UTCdiff: null,
    Token: null,
    Occupation: null,
    MemberStatus: null,
    Married: null,
    ZoomAccount: null,
    Christian: null,
    Baptized: null,
    ReturneeInfo: null,
    HasReturningChurch: null,
    RegisteredDate: null,
    LastAccessedDate: null,
    LoginId: null,
    MiddleNameEN: null,
    MiddleNameJP: null,
    PrefixJP: null,
    OccupationDetail: null,
    Organization: null,
    LivingCountry: null,
    PhoneEN: null,
    PhoneMobileEN: null,
    PhoneJP: null,
    PhoneMobileJP: null,
    ChurchNameEN: null,
    ChurchNameJP: null,
    MemberType: null,
    MemberDetail: null,
    MemberMemo: null,
    Shokaisha: null,
    Reason: null,
    NewsLetters: null,
    StaffReports: null,
    OtherLetters: null,
    NewsLetterLanguage: null,
    BasicRoles: null,
    //Added new
    MigrateFromSiteGround: null,
    AdminAccess: null,
    JCFNAccess: null,
    SupportingMember: null,
    LastAddressUpdatedDate: null,
    AcceptMembershipTerm: null,
    MembershipType: null
  };
  relatedPerson: GRCRelatedPerson = {
    Id: null,
    RelatedId: null,
    Email: null,
    FirstNameRelated: null,
    LastNameRelated: null,
    MyojiRelated: null,
    ShimeiRelated: null,
    DOBRelated: null,
    GenderRelated: null
  };
  volunteerSelection: GRCVolunteerSelection={
    Id:0,
    UserId:null,
    Email:null,
    VolunteerName:null,
    VolunteerAccepted:null,
    AcceptedDate:null
  }

  relatedPersons: RelatedPerson[] = [];
  volunteerSelections: VolunteerSelection[]=[];
  conference = new GRCConference();
  userConference:UserConferenceSubProfileGRC={
    PasswordHash: null,
    Id: null,
    UserName: null,
    Email: null,

    //*** Customized by Oz
    FirstName: null,
    LastName: null,
    Password: null,
    Prefix: null,
    Shimei: null,
    Myoji: null,
    Street: null,
    Street2: null,
    City: null,
    State: null,
    Zip: null,
    Country: null,

    //Japan Address
    Yubin_Bango: null,
    To_Do_Fu_Ken: null,
    Shi_Gun_Ku: null,
    Cho_Son: null,
    Apartment_Etc: null,
    //Japan Address Kanji
    To_Do_Fu_Ken_JP: null,
    Shi_Gun_Ku_JP: null,
    Cho_Son_JP: null,
    Apartment_Etc_JP: null,
    //Other personal info
    Gender: null,
    DOB: null,
    TelNo: null,
    TimezoneName: null,
    UTCdiff: null,
    Token: null, //Need for password recovery
    Occupation: null,
    MemberStatus: null,
    Married: null,
    ZoomAccount: null,
    Christian: null,
    Baptized: null,
    ReturneeInfo: null,
    HasReturningChurch: null,
    RegisteredDate: null,
    LastAccessedDate: null,
    FaithQuesCompletedDate: null,

    //From siteground
    LoginId: null,
    MiddleNameEN: null,
    MiddleNameJP: null,
    PrefixJP: null,
    OccupationDetail: null,
    Organization: null,
    LivingCountry: null,
    PhoneEN: null,
    PhoneMobileEN: null,
    PhoneJP: null,
    PhoneMobileJP: null,
    ChurchNameEN: null,
    ChurchNameJP: null,
    MemberType: null,
    MemberDetail: null,
    MemberMemo: null,
    Shokaisha: null,
    Reason: null,
    NewsLetters: null,
    StaffReports: null,
    OtherLetters: null,
    NewsLetterLanguage: null,
    BasicRoles: null,

    //New addition
    MigrateFromSiteGround: null,
    AdminAccess: null,
    JCFNAccess: null,
    SupportingMember: null,

    LastAddressUpdatedDate: null,

    AcceptMembershipTerm: null,
    MembershipType: null,

    //------------------------------------------------------
    GRCConferenceId: null,
    UserId: null,
    ConferenceYear: null,
    SmallGroupLanguage: null,

    ConferenceFee: null,
    ConferenceDiscountAmount: null,
    ConferenceFeePaidAmount: null,
    ConferenceFeePaidDate: null,
    CouponApproved: null,
    ApplicationFee: null,
    ApplicationFeePaidDate: null,
    BalanceAmount: null,
    BalanceAmountPaidDate: null,

    TransportationMethodComing: null,
    TransportationMethodReturning: null,
    GRCDirectBusAdultNumberComing: null,
    GRCDirectBusChildNumberComing: null,
    GRCDirectBusAdultNumberReturning: null,
    GRCDirectBusChildNumberReturning: null,

    RoomInfo: null,
    Couple: null,
    May3: null,
    May4: null,
    May5: null,
    May6: null,
    KidGroup1: null,
    KidGroup2: null,
    KidGroup3: null,
    KidGroup4: null,
    KidGroup5: null,
    KidGroup6: null,
    myGRCRelatedPersons: this.relatedPersons,
    myGRCVolunteerSelections: this.volunteerSelections,
    Memo: null,

    TermsOfParticipation: null,
    TermsOfParticipationDate: null,
    EmergencyContactFirstName: null,
    EmergencyContactLastName: null,
    EmergencyContactEmail: null,
    EmergencyContactTelNo: null,
    UpdatedDate: null,
    CommitteeAccess: null,

    ApplyScholarship: null,
    ApplyingScholarshipAmount: null,
    ApprovedScholarshipAmount: null,
    ApplyingReason: null,
    PaymentOption: null,


    mySelectedVolunteers: this.checkGroups,
    SmallGroupNo: null,
    RoomNo: null,

  }
  userConferences: UserConferenceSubProfileGRC[];

  constructor(
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    public authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private grcRegistrationService: GrcRegistrationService,
    private dialog: MatDialog,
  ) { }


  mySearch: any = {};

  ngOnInit(): void {
    this.mySelectedLanguage = this.router.url.includes('EN') == true ? "EN" : "JP";
    this.myFormGroup = this._formBuilder.group({
      myFirstName: [null],
      myLastName: [null],
      myEmail: [null],
      myGender: [null],
      mySmallGroupLanguage: [null],
      myVolunteer: [null]
    });
    this.mySearch = JSON.parse(sessionStorage.getItem("mySearchingUser"));
    if (this.mySearch != null) {
      this.userConference.FirstName = this.mySearch.myProfileSearch.FirstName;
      this.userConference.LastName = this.mySearch.myProfileSearch.LastName;
      this.userConference.SmallGroupLanguage = this.mySearch.myProfileSearch.SmallGroupLanguage;
      this.userConference.myGRCVolunteerSelections = this.mySearch.myProfileSearch.myVolunteerSelections;
      this.getUserConferenceBySearchKey(this.userConference);
    } else {
    }
  }

  getMyLanguage(): string {
    return this.router.url.includes('EN') == true ? 'EN' : 'JP';
  }

  async getFormValue() {
    this.userConference.FirstName = this.myFormGroup.get('myFirstName').value;
    this.userConference.LastName = this.myFormGroup.get('myLastName').value;
    this.userConference.Email = this.myFormGroup.get('myEmail').value;
    this.userConference.Gender = this.myFormGroup.get('myGender').value;
    this.userConference.SmallGroupLanguage = this.myFormGroup.get('mySmallGroupLanguage').value;
    this.conference.FirstName = this.myFormGroup.get('myFirstName').value;
    this.conference.LastName = this.myFormGroup.get('myLastName').value;
    this.conference.Email = this.myFormGroup.get('myEmail').value;
    //this.conference.Gender = this.myFormGroup.get('myGender').value;
    this.conference.SmallGroupLanguage = this.myFormGroup.get('mySmallGroupLanguage').value;
 if(this.myFormGroup.get('myVolunteer').value!=null){
  console.log(this.myFormGroup.get('myVolunteer').value.length);
  this.myFormGroup.get('myVolunteer').value.forEach((element: string) => {
    debugger
    //console.log("my volunteer!:"+ element);
    var myVolunteer = this.volunteerSelection;
    myVolunteer.VolunteerName = element;
    //this.volunteerSelection.VolunteerName =element;
    console.log("volunteerName:"+this.volunteerSelection.VolunteerName)
    console.log("volunteerName:"+myVolunteer)
    this.userConference.myGRCVolunteerSelections.push(myVolunteer);
  });

 }else{
  console.log('no volunteer selected');
 }


  return this.userConference;
  }

  async onSearchSubmit({ value, valid }: { value: UserConferenceSubProfileGRC; valid: boolean }) {

    // this.userConference.FirstName = null;
    // this.userConference.LastName = null;
    // this.userConference.Gender = null;
    // this.myFormGroup.patchValue({
    //   myFirstName: null,
    //   myLastName: null,
    //   myEmail:null,
    //   myGender: null,
    //   mySmallGroupLanguage: null,
    //   myVolunteer: null

    // });

    this.userConference = await this.getFormValue();
    this.cntUser = 0;
      sessionStorage.removeItem("mySearchingUser");
      sessionStorage.setItem(
        "mySearchingUser",
        JSON.stringify({ myProfileSearch: this.userConference })
      );
      this.getUserConferenceBySearchKey(this.userConference);
  }

  getUserConferenceBySearchKey(userConference: UserConferenceSubProfileGRC) {
    this.loaded = false;
    this.showNumberOfUser = false;
    this.conference.FirstName = this.userConference.FirstName;
    this.conference.LastName = this.userConference.LastName;
    this.conference.Email=this.userConference.Email;
    this.conference.SmallGroupLanguage = this.userConference.SmallGroupLanguage;
    this.conference.myGRCVolunteerSelections = this.userConference.myGRCVolunteerSelections;
    this.grcRegistrationService.GetUserConferenceBySearchKey(this.conference)
      .subscribe(results => {
        this.userConferences = results;
        this.dataSource = new MatTableDataSource(this.userConferences);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loaded = true;
        var tempEmail: any;
        var cnt = 0;
        this.userConferences.forEach(element => {
          if (tempEmail != element) {
            tempEmail = element.Email;
            cnt++;
          }
          this.cntUser = cnt;
        });
        this.loaded = true;
        this.showNumberOfUser = true;
      },
        error => {
          this.myMessage = "Data search failed."
          this.openSnackBar('error', 'close');
          this.loaded = true;
          this.showNumberOfUser = true
        }
      );
  }


  onCancel($event: any) {
    event.preventDefault(); // This is neccessary to prevent cencel button submit form
    this.userConference.FirstName = null;
    this.userConference.LastName = null;
    this.userConference.Gender = null;
    this.myFormGroup.patchValue({
      myFirstName: null,
      myLastName: null,
      myEmail:null,
      myGender: null,
      mySmallGroupLanguage: null,
      myVolunteer: null

    });

    sessionStorage.removeItem("mySearchingUser");
  }

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 10000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 10000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }

  calculate_age(dob: Date) {
    var diff_ms = Date.now() - new Date(dob).getTime();
    var age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }

  onSelectVolunteer($event: any) {
    // this.volunteerSelections=[]
    // this.conference.myVolunteerSelections= null;
    // this.userConference.myVolunteerSelections=null;
    // $event.value.forEach((item: string) => {
    //   console.log("selected object:"+ item)
    //   //this.volunteerSelection=null;
    //   this.volunteerSelection.VolunteerName =item
    //   this.volunteerSelections.push(this.volunteerSelection);
    // });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onSubmit() {
  }

  onClickGoToMyAccount(row: UserConference) {
    sessionStorage.removeItem("searchingMemberId");
    sessionStorage.setItem("searchingMemberId", row.Id);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight = '800px'; //Desktop
    dialogConfig.maxWidth = '1200px';//Desktoop
    dialogConfig.height = '100%';//Mobile
    dialogConfig.width = '100%';//Mobile
    dialogConfig.panelClass = 'custom-modalbox'; //Refer global level
    dialogConfig.data = { userId: row.UserId };
    const dialogRef = this.dialog.open(MyAccountEditGRCComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
        console.log("returned AS400Code: " + confirmedAction.AS400Code);
      }
    })
  }

  exportToExcel() {
  var headers={
    LastName: "Last Name",
    FirstName: "First Name",
    Shimei:"名",
    Myoji:"姓",
    Email: "Email",
    Gender: "Gender",
    Age: "Age",
   // Student:"学生",
    Christian:"クリスチャン",
    SmallGroupLanguage:"言語",
    Volunteers:"希望奉仕",
    VolunteersApproved:"承認奉仕",
    RelatedPersons:"同伴者",
    May3:"May3",
    May4:"May4",
    May5:"May5",
    May6:"May6",
    RegisteredDate:"登録日",
    ConferenceFee:"参加費",
    ConferenceFeePaidAmount:"参加費支払い額",
    ConferenceFeePaidDate:"参加費支払日",

    To_Do_Fu_Ken_JP:"都道府県",
    SmallGroupNo:"スモールグループNo",
    RoomNo:"ルームNo"
  };
  var itemsFormatted:any=[];
  this.userConferences.forEach(item=>{
    var dt = new Date(item.RegisteredDate);
    var createdMonth = dt.getMonth()+1;
    var createdYear = dt.getFullYear();
    var createdDay = dt.getDate();

    var af = new Date(item.ApplicationFeePaidDate);
    var afMonth = af.getMonth()+1;
    var afYear = af.getFullYear();
    var afDay = af.getDate();

    var cf = new Date(item.ConferenceFeePaidDate);
    var cfMonth = cf.getMonth()+1;
    var cfYear = cf.getFullYear();
    var cfDay = cf.getDate();

    var myVolunteerLists:string='';
    if(item.myGRCVolunteerSelections!=null){
      item.myGRCVolunteerSelections.forEach(item =>
        myVolunteerLists = myVolunteerLists +item.VolunteerName+";"
      );
    }
    var myVolunteerApprovedLists:string='';
    if(item.myGRCVolunteerSelections!=null){
      item.myGRCVolunteerSelections.filter(x=>x.VolunteerAccepted).forEach(item =>
        myVolunteerApprovedLists = myVolunteerApprovedLists +item.VolunteerName+";"
      );
    }
    var myRelatedPersons:string='';
    if(item.myGRCRelatedPersons!=null){
      item.myGRCRelatedPersons.forEach(item =>
        myRelatedPersons = myRelatedPersons+item.FirstNameRelated+item.LastNameRelated+";"
      );
    }

    itemsFormatted.push({
      LastName: item.LastName == null ? "" :item.LastName.replace(/,/g, " "),
      FirstName: item.FirstName == null ? "" :item.FirstName.replace(/,/g, " "),
      Shimei:item.Shimei==null?" ":item.Shimei.replace(/,/g, ""),
      Myoji: item.Myoji==null?" ":item.Myoji.replace(/,/g, ""),
      Email: item.Email == null ? "" : item.Email.replace(/,/g, " "),
      Gender: item.Gender == null ? "" : item.Gender=='Male'?'男性':'女性',
      Age: item.DOB == null ? "" : this.calculate_age(item.DOB),
     //Student:item==true?'はい':'いいえ',
      Christian:item.Christian==true?'はい':'いいえ',
      SmallGroupLanguage: item.SmallGroupLanguage == null ? "" :item.SmallGroupLanguage=='Japanese'?'日本語':item.SmallGroupLanguage,
      Volunteers:myVolunteerLists==null?"":myVolunteerLists,
      VolunteersApproved:myVolunteerApprovedLists==null?"":myVolunteerApprovedLists,
      RelatedPersons:myRelatedPersons==null?"":myRelatedPersons,
      May3:item.May3,
      May4:item.May4,
      May5:item.May5,
      May6:item.May6,
      RegisteredDate:item.RegisteredDate==null?"":createdMonth+"/"+createdDay+"/"+createdYear,
     // ApplicationFee:item.ApplicationFee==null?"":item.ApplicationFee,
     // ApplicationFeePaidDate:item.ApplicationFeePaidDate==null?"":afMonth+"/"+afDay+"/"+afYear,
     // ConferenceDiscountAmount:item.ConferenceDiscountAmount==null?"":item.ConferenceDiscountAmount,
      //ApprovedScholarshipAmount:item.ApprovedScholarshipAmount==null?"":item.ApprovedScholarshipAmount,
      ConferenceFee:item.ConferenceFee==null?"":item.ConferenceFee,
      ConferenceFeePaidAmount:item.ConferenceFeePaidAmount==null?"":item.ConferenceFeePaidAmount,
      ConferenceFeePaidDate:item.ConferenceFeePaidDate==null?"":cfMonth+"/"+cfDay+"/"+cfYear,
      To_Do_Fu_Ken_JP:item.To_Do_Fu_Ken_JP==null?"":item.To_Do_Fu_Ken_JP,
      SmallGroupNo:item.SmallGroupNo==null?"":item.SmallGroupNo,
      RoomNo:item.RoomNo==null?"":item.RoomNo
    });
  });
  var fileTitle = "GRC_Registration";
  this.exportCSVFile(headers, itemsFormatted, fileTitle);
  }

  exportCSVFile(headers: any, items: any, fileTitle: any) {
    if (headers) {
      items.unshift(headers);
    }
    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);
    var csv = this.convertToCSV(jsonObject);
    var exportedFilenmae = fileTitle + ".csv" || "export.csv";
    var blob = new Blob(
      [
        "\uFEFF", // 文字化け防止
        csv],
      { type: "text/csv;charset=utf-8;" });

      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

  }

  convertToCSV(objArray: any) {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";
    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line != "") line += ",";
        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  }
}
