import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { UserService} from "../../../../services/user/user.service";
import { Router, ActivatedRoute,NavigationExtras } from "@angular/router";
import { MatRadioChange } from '@angular/material/radio';
import { ECConference } from 'src/app/models/conference/ECConference';
import { User } from 'src/app/models/user';
import { CancelOrSaveConfirmationPersonalComponent } from 'src/app/components/dialog/cancel-or-save-confirmation-personal/cancel-or-save-confirmation-personal.component';
//For Dialog
import { MatDialog,  MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import { LoginMessageEcComponent } from '../../../dialog/login-message-ec/login-message-ec.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { EcRegistrationService } from 'src/app/services/conference/ec/ec-registration.service';
import { UserEditOthersProfileComponent  } from '../../../dialog/user-edit-others-profile/user-edit-others-profile.component';
import { RequestAddressUpdateComponent} from '../../../dialog/request-address-update/request-address-update.component';
import { AuthService } from "../../../../services/auth/auth.service";
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { RelatedPerson} from "../../../../models/conference/RelatedPerson";
import { CheckHavingAccountECComponent} from "../../../dialog/check-having-account-ec/check-having-account-ec.component";
import { Observable } from 'rxjs';
import { VolunteerSelection} from "../../../../models/conference/volunteerSelection";
//import { BasicSubEmbedComponent } from 'src/app/components/dialog/EC/basic-sub-embed/basic-sub-embed.component';
import { MyAccountEditComponent } from 'src/app/components/dialog/EC/my-account-edit/my-account-edit.component';
import { RegistrationPersonalEmbedComponent } from 'src/app/components/dialog/EC/registration-personal-embed/registration-personal-embed.component';
import { BasicSubEmbedComponent } from 'src/app/components/dialog/EC/basic-sub-embed/basic-sub-embed.component';
//import { BasicSubEmbedComponent } from 'src/app/components/dialog/EC/basic-sub-embed/basic-sub-embed.component';
@Component({
  selector: 'app-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.css']
})
export class BasicComponent implements OnInit {
  loaded: boolean = true;
  myFormGroup:FormGroup;
  myMessage: string;
  //disableGoNext: boolean = true;
  mySelectedLanguage: string;
  strBoolean: string;
  editMode: boolean;
  hideMyPassword: boolean = true;
  userFromServer: Observable<User>;
  disableTextbox =  false;

  user: User = {
    PasswordHash:null,
    Id:null,
    UserName:null,
    Email:null,
    FirstName:null,
    LastName:null,
    Password:null,
    Prefix:null,
    Shimei:null,
    Myoji:null,
    Street:null,
    Street2:null,
    City:null,
    State:null,
    Zip:null,
    Country:null,
    Yubin_Bango:null,
    To_Do_Fu_Ken:null,
    Shi_Gun_Ku:null,
    Cho_Son:null,
    Apartment_Etc:null,
    To_Do_Fu_Ken_JP:null,
    Shi_Gun_Ku_JP:null,
    Cho_Son_JP:null,
    Apartment_Etc_JP:null,
    Gender:null,
    DOB:null,
    TelNo:null,
    TimezoneName:null,
    UTCdiff:null,
    Token:null,
    Occupation:null,
    MemberStatus:null,
    Married:null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount:null,
    RegisteredDate:null,
    LastAccessedDate:null,
    //From siteground
    LoginId:null,
    MiddleNameEN:null,
    MiddleNameJP:null,
    PrefixJP:null,
    OccupationDetail:null,
    Organization:null,
    LivingCountry:null,
    PhoneEN:null,
    PhoneMobileEN:null,
    PhoneJP:null,
    PhoneMobileJP:null,
    ChurchNameEN:null,
    ChurchNameJP:null,
    MemberType:null,
    MemberDetail:null,
    MemberMemo:null,
    Shokaisha:null,
    Reason:null,
    NewsLetters:null,
    StaffReports:null,
    OtherLetters:null,
    NewsLetterLanguage:null,
    BasicRoles:null,
    //Added new
    MigrateFromSiteGround:null,
    AdminAccess:null,
    JCFNAccess:null,
    SupportingMember:null,
    LastAddressUpdatedDate:null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };
  relatedPerson:RelatedPerson={
    Id:null,
    RelatedId:null,
    Email:null,
    FirstNameRelated:null,
    LastNameRelated:null,
    MyojiRelated:null,
    ShimeiRelated:null,
    DOBRelated:null,
    GenderRelated:null
  };
  relatedPersons:RelatedPerson[]=[];
  volunteerSelections:VolunteerSelection[]=[];
  conference: ECConference= {
    ECConferenceId:0,
    UserId: null,
    Email: null,
    UserName: null,
    FirstName: null,
    LastName: null,
    //Program
    ProgramType: null,
    SmallGroupLanguage: null,
    AttendJapanRegionalMeeting:null,
    JapanRegionalMeeting:null,
    ApplyEnglishPartner:null,
    //Payment
    ConferenceFee: null,
    ConferenceDiscountAmount: null,
    ConferenceFeePaidAmount: null,
    ConferenceFeePaidDate: null,
    CouponApproved: null,
    ApplyScholarship: null,
    ApplyingScholarshipAmount: null,
    ApprovedScholarshipAmount: null,
    ApplyingReason: null,
    //Others\
    Volunteers:null,
    Student:null,
    ApplyCounseling: null,
    MySleepingBag:null,
    BringExtraSleepingBag:null,
    CountExtraSleepingBag:null,
    NeedCommingBus:null,
    CommingBusDeparturePlace:null,
    CommingBusDepartureDateTime:null,
    CommingAirline:null,
    CommingFlightNo:null,
    DepartureCity:null,
    CommingInternationalDomestic:null,
    ArrivalDateTime:null,
    NeedReturningBus:null,
    ReturningBusDepartureDateTime:null,
    ReturningAirline:null,
    ReturningFlightNo:null,
    ReturningCity:null,
    ReturningBusDeparturePlace:null,
    ReturningInternationalDomestic:null,
    DepartureDateTime:null,
    RoomInfo:null,
    Couple:null,
    Youth:null,
    YouthGrade: null,
    GuardianFirstName: null,
    GuardianLastName: null,
    GuardianMyoji: null,
    GuardianShimei: null,
    GuardianEmail: null,
    GuardianTelNo: null,
    TermsOfParticipation: null,
    TermsOfParticipationParent: null,
    TermsOfParticipationDate: null,
    TermsOfParticipationDateParent: null,
    RegisteredDate: null,
    UpdatedDate: null,

    AdminAccess: null,
    ECCommitteeAccess: null,
    Memo: null,
    Dec26:null,
    Dec27:null,
    Dec28:null,
    Dec29:null,
    Dec30:null,
    Dec31:null,
    KidGroup1:null,
    KidGroup2:null,
    KidGroup3:null,
    KidGroup4:null,
    myRelatedPersons: this.relatedPersons,
    myVolunteerSelections:this.volunteerSelections,
    BusComingConfirmation:null,
    BusReturningConfirmation:null,
    TermsOfParticipationVenue:null,
    TermsOfParticipationDateVenue:null,
    TermsOfParticipationYouth:null,
    TermsOfParticipationDateYouth:null,
    TermsOfParticipationYouthParent:null,
    TermsOfParticipationDateYouthParent:null,
    EmergencyContactFirstName:null,
    EmergencyContactLastName:null,
    EmergencyContactEmail:null,
    EmergencyContactTelNo:null,
    HasAllergy:null,
    AllergyDetail:null,
    BringInsurance:null,
    MinorHasCustodyInsurance:null,
    MinorHasCustodyInsuranceDate:null,
    InsuranceCompany:null,
    InsuranceAddress:null,
    InsuranceTelNo:null,
    InsurancePolicyNo:null,
    InsuranceGroupNo:null,
    VolunteerAgreeConductCode:null,
    VolunteerAgreeConductCodeDate:null,
    PayingAmount:null,
     //New
    BecomeMentor:null,
    ApplyMentoring:null,
    ReturningDepartureCity:null,
    InsuranceStatus:null,
    DressCodeAgreeYouth:null,
    DressCodeDateYouth:null,
    DressCodeAgreeParent:null,
    DressCodeDateParent:null
}

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private registrationService: UserService,
    private ecRegistrationService : EcRegistrationService,
    private dialog: MatDialog,
    public authService: AuthService,
    private userService: UserService,
    /**************************************************
     * Followings 2 componensts used to call function
     * in those componets from this class
    ***************************************************/
       //  private userEditOthersProfile: UserEditOthersProfileComponent,
      //   private requestAddressUpdate:RequestAddressUpdateComponent
  ) {
    this.route.queryParams.subscribe(params => {
      this.user.FirstName = params["FirstName"];
      this.user.LastName   = params["LastName"];
      console.log("1.First Name: "+ this.conference.FirstName);
      console.log("2.Last Name: "+this.conference.LastName);
  });
   }

  onSubmit() {
  }

  mySearch: any = {};
  ngOnInit(): void {
    this.myFormGroup = this._formBuilder.group({
      myEmail: ['',[Validators.required,Validators.email]],
      myPassword: [null, [Validators.required, Validators.minLength(8),Validators.pattern('^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$')]],
      myFirstName: ['',Validators.required ],
      myLastName: ['', Validators.required],
      myShimei: [''],
      myMyoji: [''],
    });

      this.loaded = false;


    if (sessionStorage.getItem("myECSearchingUserId") != null) {
     /*#####################################################################
     #  A) Admin Work search for registrant
     ######################################################################*/
      console.log("Take their Id");
      var searchingRegistrantId = sessionStorage.getItem("myECSearchingUserId")
      this.userService.getUserProfileById(searchingRegistrantId).subscribe(result => {
      this.user = result;
      this.patchModelValue(this.user);
      this.myFormGroup.get('myPassword').clearValidators();
      this.myFormGroup.get('myPassword').updateValueAndValidity();
      this.loaded = true;
      });
      this.loaded = true;
      this.disableTextbox=true;
    } else {
        /******************************
         *  B) User Info for login user
         ******************************/
      var loginUserName = sessionStorage.getItem("loginUserName");
      if (loginUserName != null) {
        this.editMode = true;
        this.userService.getUserProfile(loginUserName).subscribe(result => {
          this.user = result;
          this.patchModelValue(this.user);
          sessionStorage.setItem("loginUserFirstName", this.user.FirstName);
          this.loaded = true;
          this.disableTextbox=true;
        });
      } else {
        console.log('This is my first time and I am not edit mode');

        /*#####################################################################
        # In case this is first time registration and come back from forward page by back button
        ######################################################################*/
        this.mySearch = JSON.parse(sessionStorage.getItem("myWorkingOnUser"));
        if (this.mySearch != null) {
          console.log('found it!!')
          this.user = this.mySearch.myEditingUser;
          this.patchModelValue(this.user);
          this.loaded = true;
          //this.checkFormValidation();
        }else{
        }
        this.editMode = false;
        this.loaded = true;
      }
      if (this.authService.isLoggedIn()) {
        this.myFormGroup.get('myPassword').clearValidators();
        this.myFormGroup.get('myPassword').updateValueAndValidity();
      }
    }
  }

  patchModelValue(model:User){
    this.myFormGroup.patchValue({
      myEmail: model.Email,
      myFirstName:model.FirstName,
      myLastName:model.LastName,
      myShimei: model.Shimei,
      myMyoji:model.Myoji,
      address: {
        street: '123 Drew Street'
      }
    });
  }
  getFormValue() {
    this.user.Email = this.myFormGroup.get('myEmail').value;
    this.user.Password = this.myFormGroup.get('myPassword').value;
    this.user.FirstName = this.myFormGroup.get('myFirstName').value;
    this.user.LastName = this.myFormGroup.get('myLastName').value;
    this.user.Myoji = this.myFormGroup.get('myMyoji').value;
    this.user.Shimei = this.myFormGroup.get('myShimei').value;
  }

  onEmailChangeEventEC($event: Event) {
    this.user.Email = this.myFormGroup.get('myEmail').value;
    this.userService.emailCheck(this.user.Email).subscribe(user => {
      var user = user;
      if (user != null) {
        //const dialogRef = this.dialog.open(LoginMessageEcComponent, {
        //   width: '500px',
        //   height: '470px',
        //   data: {
        //     myLanguage: this.mySelectedLanguage,
        //     myEmail:this.user.Email
        //   }
        // });
        // dialogRef.afterClosed().subscribe(result => {
        //   var redirectPage = result;
        //   if (result != null) {
        //     console.log("Confirmed Action: " + redirectPage);
        //   }

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;
        dialogConfig.maxHeight='580px'; //Desktop
        dialogConfig.maxWidth='500px';//Desktoop
        dialogConfig.height='100%';//Mobile
        dialogConfig.width='100%';//Mobile
        dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
        dialogConfig.data = { myEmail: this.user.Email };
        //const  dialogRef = this.dialog.open(CheckHavingAccountECComponent,dialogConfig);
        //const  dialogRef = this.dialog.open(LoginDialogEcComponent,dialogConfig);
        const  dialogRef = this.dialog.open(LoginMessageEcComponent,dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          var redirectPage = result;
          if(result!=null){
            console.log("Confirmed Action: "+redirectPage);
          }
        })

      } else {
        console.log("This email is not taken yet.")
      }

    })
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  onClickGoForward(){
    this.getFormValue();
    if(this.router.url.includes('/registration/ec/')){
      sessionStorage.removeItem("myWorkingOnUser");
      sessionStorage.setItem(
        "myWorkingOnUser",
        JSON.stringify({ myEditingUser: this.user })
      );
      this.router.navigate(["/registration/ec/basicSub/" +this.getMyLanguage()]);
    }else{
      sessionStorage.removeItem("myWorkingOnUserAdmin");
      sessionStorage.setItem(
        "myWorkingOnUserAdmin",
        JSON.stringify({ myEditingUser: this.user })
      );
      this.dialog.closeAll();
      this.openForwardEmbed();
    }
  }
  openForwardEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ Id: this.user.Id };
    const dialogRef = this.dialog.open(BasicSubEmbedComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  onClickGoBack(){
    if (this.authService.isLoggedIn()) {
      if(this.router.url.includes('/registration/ec/')){
        this.router.navigate(["conference/EC/myAccount/" +this.getMyLanguage()]);
      }else{
        this.dialog.closeAll();
        this.openBackEmbed();
      }
    }else{
      this.router.navigate(["/" +this.getMyLanguage()]);
    }
  }

  openBackEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ Id: this.user.Id };
    const dialogRef = this.dialog.open(MyAccountEditComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  cancelProcess(){
    this.getFormValue();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='210px'; //Desktop
    dialogConfig.maxWidth='370px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ user: this.user };
    const dialogRef = this.dialog.open(CancelOrSaveConfirmationPersonalComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
      //Retreive updated registration Info.
      //this.ngOnInit();
      let navigationExtras: NavigationExtras = {
        queryParams:this.conference
      };
      if(result!='cancel'){
        if(this.router.url.includes('/registration/ec/')){
          this.router.navigate(["/conference/EC/myAccount/" +this.getMyLanguage()], navigationExtras);
        }else{
          this.dialog.closeAll();
          this.openMyAccountEdit();
        }
        }
    })
  }

  openMyAccountEdit(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ userId: this.user.Id };
    const dialogRef = this.dialog.open(MyAccountEditComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }
}
