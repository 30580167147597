<div class="myBody">
  <div class="myHeaderBase d-flex justify-content-center">
    <div class="myHeaderCore">
      <div>
        <img src='../../../../../assets/images/thumbnail_eclogo.png' class="ecLogo">
      </div>
    </div>
  </div>
  <div class="myMain">
    <div clas="myMainLeft">
      <div class="myMainHalfTop">
        <!-- Left Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Left Bottom -->
      </div>
    </div>
    <div class="myMainCenter">
      <div class="myMainHalfTop d-flex justify-content-center">
        <!-- Main Top -->
        <mat-card class="mainCard b-radius">
          <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
          <div *ngIf="!loaded">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <!-- <form *ngIf="userFromServer | async; else loading"  -->
          <form [formGroup]="myFormGroup" (ngSubmit)="onSubmit()">
            <div class="float-end stepNumber"></div>
            <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Dress Code':
              '服装規定'}}
              <i class="stepTitleSub">
                {{this.router.url.includes('EN')==true?'Required': '必須'}}
              </i>
            </h2>
            <div class="stepTitle">
              <label>
                {{this.router.url.includes('EN')==true?
                'There is no official dress code, but we encourage everyone to dress appropriately and to use good judgment and common sense.'
                :
                '正式な服装規定はありませんが、適切な服装をし、良識と常識を働かせることを推奨します。'}}
              </label><br>
              <label><b>
                {{this.router.url.includes('EN')==true?
                "What NOT to wear:"
                :
                '着用してはいけないもの:'}}
              </b></label><br>
              <label class="stepTitle2nd">
                {{this.router.url.includes('EN')==true?
                '· Clothing with vulgar or suggestive text/images'
                :
                '· 下品または挑発的な文章や画像のある服'}}
              </label><br>

              <label><b>
                {{this.router.url.includes('EN')==true?
                "Girls:"
                :
                '女子:'}}
              </b></label><br>
              <label class="stepTitle2nd">
                {{this.router.url.includes('EN')==true?
                "· Revealing tops (strapless shirts, halter tops, spaghetti strap tanks, bare midriff or low- cut shirts)"
                :
                "· 露出度の高いトップス (ストラップレス シャツ、ホルター トップ、スパゲッティ ストラップ タンクトップ、腹部が露出したシャツ、ローカット シャツ)"}}
              </label><br>
              <label class="stepTitle2nd">
                {{this.router.url.includes('EN')==true?
                "· Short shorts/skirts (shorter than fingertip length when hands are down)"
                :
                "· 短いズボン/スカート （手を下ろして指先より短いもの）"}}
              </label><br>
              <label><b>
                {{this.router.url.includes('EN')==true?
                "Guys:"
                :
                '男子:'}}
              </b></label><br>
              <label class="stepTitle2nd">
                {{this.router.url.includes('EN')==true?
                "· Low-riding pants/shorts (with underwear showing)"
                :
                "· ローライズ ズボン/ショーツ (下着が見えるズボン)"}}
              </label><br>
              <label class="stepTitle2nd">
                {{this.router.url.includes('EN')==true?
                "· Ribbed tank tops (wife beaters)"
                :
                "· リブ付きタンクトップ (ワイフ ビーター)"}}
              </label><br><br>
              <label>
                {{this.router.url.includes('EN')==true?
                'Parents: Please be mindful of the clothing your children are bringing to this camp. If they do not have appropriate clothing, we will have them change into camp-provided clothes.'
                :
                '保護者の皆様: お子様がこのキャンプに持参する服に注意してください。適切な服をお持ちでない場合は、キャンプで用意した服に着替えていただきます。'}}
              </label><br><br>

              <label><b>
                {{this.router.url.includes('EN')==true?
                "Prohibited Items"
                :
                '禁止されているアイテム'}}
              </b></label><br>
              <label>
                {{this.router.url.includes('EN')==true?
                'All youth participants will be required to give up ALL of their electronic devices. This list includes but not limited to:'
                :
                '·すべてのユース参加者は、すべての電子機器を放棄する必要があります。このリストには、以下のものが含まれますが、これらに限定されるものではありません。'}}
              </label><br>
              <label class="stepTitle2nd">
                {{this.router.url.includes('EN')==true?
                "· Cell phones"
                :
                "· 携帯電話"
                }}
              </label><br>
              <label class="stepTitle2nd">
                {{this.router.url.includes('EN')==true?
                "· Music players (iPods) "
                :
                "· 音楽プレーヤー (iPod)"
                }}
              </label><br>
              <label class="stepTitle2nd">
                {{this.router.url.includes('EN')==true?
                "· Tablets (iPads)"
                :
                "· タブレット (iPad)"
                }}
              </label><br>
              <label class="stepTitle2nd">
                {{this.router.url.includes('EN')==true?
                "· Switch"
                :
                "· Switch"
                }}
              </label><br>
              <label class="stepTitle2nd">
                {{this.router.url.includes('EN')==true?
                "· Kindle"
                :
                "· Kindle"
                }}
              </label><br>
              <label class="stepTitle2nd">
                {{this.router.url.includes('EN')==true?
                "· Laptop "
                :
                "· パソコン"
                }}
              </label><br>
              <label class="stepTitle2nd">
                {{this.router.url.includes('EN')==true?
                "· Smartwatch"
                :
                "· スマートウォッチ"
                }}
              </label><br>
              <label>
                {{this.router.url.includes('EN')==true?
                'ANY device that connects to the internet is prohibited. Please do not even bring it to EC as it will be immediately confiscated until the end of the camp.
                If any youth is found with any of these following devices, they will be immediately confiscated and they will be sent home. Parents are responsible for picking up their children if this is the case.'
                :
                'インターネットに接続するあらゆるデバイスは禁止されています。キャンプ終了まで直ちに没収されるため、EC に持ち込まないでください。
                以下のデバイスのいずれかを所持しているユースが見つかった場合、直ちに没収され、帰宅させられます。この場合、保護者は子供を迎えに行く責任があります。'}}
              </label><br><br><br>

              <div>
                <div class="participantConsent">
                  <label>
                    {{this.router.url.includes('EN')==true?
                    'Youth Program Participant'
                    :
                    'ユースプログラム参加者'}}
                  </label><br>
                  <mat-checkbox #checkboxConsentYouth formControlName="myDressCodeAgreeYouth"
                    (change)='onChangeConsentYouth(checkboxConsentYouth.checked)' class="ckb_SG"
                    [checked]="conference.DressCodeAgreeYouth==true"> {{this.router.url.includes('EN')==true?'* I
                    Agree':'*
                    同意します'}}
                  </mat-checkbox>
                  <mat-form-field class="participantConsent">
                    <input matInput placeholder='Date' formControlName="myDressCodeDateYouth"
                      [matDatepicker]="DressCodeDateYouth" class="matInputBox" />
                    <mat-datepicker-toggle matSuffix [for]="DressCodeDateYouth"></mat-datepicker-toggle>
                    <mat-datepicker #DressCodeDateYouth></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <br>
              <div>
                <div class="participantConsent">
                  <label>
                    {{this.router.url.includes('EN')==true?
                    'The parent or legal guardian of the above youth participant'
                    :
                    '上記ユース参加者の親、または法定後見人'}}
                  </label><br>
                  <mat-checkbox #checkboxConsentParent formControlName="myDressCodeAgreeParent"
                    (change)='onChangeConsentParent(checkboxConsentParent.checked)' class="ckb_SG"
                    [checked]="conference.DressCodeAgreeParent==true"> {{this.router.url.includes('EN')==true?'* I
                    Agree':'*
                    同意します'}}
                  </mat-checkbox>
                  <mat-form-field class="participantConsent">
                    <input matInput placeholder='Date' formControlName="myDressCodeDateParent"
                      [matDatepicker]="DressCodeDateParent" class="matInputBox" />
                    <mat-datepicker-toggle matSuffix [for]="DressCodeDateParent"></mat-datepicker-toggle>
                    <mat-datepicker #DressCodeDateParent></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <hr>
            <div>
              <div>


                <div *ngIf="!loaded">
                  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
              </div>
              <br>
            </div>
            <div class="d-flex justify-content-center">
              <div class="processButtonSet">
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn btnProcess" (click)="onClickGoBack()">
                    {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                  </button>
                  <button [disabled]="!myFormGroup.valid" type="button" class="btn btnProcess"
                    (click)="savingConferenceInfo()">
                    {{this.router.url.includes('EN')==true?'Submit': '送信'}}
                  </button>
                </div>
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn link btnCancel" (click)="cancelProcess()">
                    {{this.router.url.includes('EN')==true?'Cancel': 'キャンセル'}}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </mat-card>
      </div>
      <div class="myMainHalfBottom">
        <!-- Main Bottom -->
      </div>
    </div>
    <div class="myMainRight">
      <div class="myMainHalfTop">
        <!-- Right Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Right Bottom -->
      </div>
    </div>
  </div>
  <!-- <footer>footer</footer> -->
</div>

