<div class="WelcomeBase">
 <form [formGroup]="firstFormGroup">
  <div class="btnBack">

   <button type="button" class="btn btn-link" (click)="onClickBack()">
    <i class="fas fa-arrow-circle-left fa-2x"></i>
   </button>
  </div>

  <mat-card class="WelcomeWindow">
   <label class="EmailCheckComment">
    {{this.router.url.includes('EN')==true?'Enter the email linked to your account and look out for a reset password email.':
    'アカウント設定時のEメールアドレスを入力してください。その後ご自分のEmailの受信ボックスをチェックしてください'}}
   </label><br>
 
   <div class="d-flex justify-content-center">
    <i class="fas fa-user fa-2x"></i>&nbsp;
   <mat-form-field appearance="outline" class="registrationInputForm">
    <mat-label>
     Email
    </mat-label>
    <input matInput placeholder="" formControlName="myEmail" [(ngModel)]="user.Email" required
     class="registrationInputBox" >
     <mat-error *ngIf="firstFormGroup.get('myEmail').hasError('required')">
      {{this.router.url.includes('EN')==true?'Email is required': 'Emailが未入力です'}}
    </mat-error>
    <mat-error *ngIf="firstFormGroup.get('myEmail').hasError('email')">
      {{this.router.url.includes('EN')==true?'Invalid Email format': 'Eメールフォーマットが正しくありません'}}
    </mat-error>
   </mat-form-field>
   </div>


   <div *ngIf="!loaded">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
   </div>
   <div *ngIf="!loaded" class="progressMessage">
    {{this.router.url.includes('EN')==true?'Sending ...':'送信中 ...'}}
   </div>
   <div  class="divUpdateButton  d-flex justify-content-center">
    <button mat-button (click)="onPasswordRecoveryTokenRequest()" class="btnSearchEmail" cdkFocusInitial>
     {{this.router.url.includes('EN')==true?'Send':'送信'}}
    </button>
   </div>
<div *ngIf="showMessageUserNotFound" class="divMessageUserNotFound d-flex justify-content-center">
  {{this.router.url.includes('EN')==true?'No data found. Please make sure the Email address is correct.':'該当データが見つかりませんでした。Eメールアドレスが正しいか確認してください'}}
</div>


  </mat-card>
 </form>

</div>
<div class="memoForDevelopper">
  password-recovery-enter-email
 </div>