
    <!-- <head>
        <meta charset="UTF-8" />
        <title>Document</title>
        <link rel="stylesheet" href="style.css" />
    </head> -->
    <div class="body UI_Build_Assistant">
        <header>header</header>
        <div class="UI_Build_Assistant2">
            <nav>left</nav>
            <main>center</main>
            <aside>right</aside>
        </div>
        <footer>footer</footer>
    </div>

