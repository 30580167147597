import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
//For Dialog
import {LoginDialogComponent} from "../../dialog/login-dialog/login-dialog.component";

export interface DialogData {
  myLanguage:string
}

@Component({
  selector: 'app-login-message',
  templateUrl: './login-message.component.html',
  styleUrls: ['./login-message.component.css']
})
export class LoginMessageComponent implements OnInit {
  loaded:boolean=true;
  firstFormGroup: FormGroup;
  myMessage:string;
  mySelectedLanguage:string;

  constructor(
    public router: Router,
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<LoginMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog
  ){}

  ngOnInit() {
    this.mySelectedLanguage = this.router.url.includes('EN')==true?"EN":"JP";
    this.firstFormGroup = this._formBuilder.group({
    });
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClickGoToLogin(){
    const dialogRef = this.dialog.open(LoginDialogComponent, {
      width: '520px',
      height:'600px',
      data: {
        myLanguage:this.mySelectedLanguage
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      var redirectPage = result;
    //  this.redirectPage(this.codeMaster.CodeType);
      if(result!=null){
        console.log("Confirmed Action: "+redirectPage);
      }
    })
    this.dialogRef.close();
  }
}
