<div class="WelcomeBase">
  <form [formGroup]="firstFormGroup">

   <mat-card class="WelcomeWindow">
    <label class="WelcomeQues">
     {{this.router.url.includes('EN')==true?
     'We found your user account in our database with this email address.Please login with this email address and proceed to registration page.'
     :
     'このメールアドレスは既にアカウント登録されています。ログインしてプロセスを続けてください'}}
    </label>
    <div>
     <div class="d-flex justify-content-center">
      <button mat-button (click)="onClickGoToLogin()" class="btnNext" cdkFocusInitial>
       {{this.router.url.includes('EN')==true?'Go to Login ':'ログイン画面へ行く'}}
      </button>
     </div>
    </div>
   </mat-card>
  </form>
 </div>
 <div class="memoForDevelopper">
  /dialog/GRC/login-message-grc
  </div>
