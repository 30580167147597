import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router, ActivatedRoute } from "@angular/router";
import { GrcRegistrationService } from 'src/app/services/conference/grc/grc-registration.service';
import { EcRegistrationService } from 'src/app/services/conference/ec/ec-registration.service';
import { ECConference } from '../../../models/conference/ECConference';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { RelatedPerson} from "../../../models/conference/RelatedPerson";
import { VolunteerSelection} from "../../../models/conference/volunteerSelection";
import { GRCConference } from 'src/app/models/conference/GRCConference';

export interface DialogData {
  conference: ECConference;
  //conference:GRCConference;
}
@Component({
  selector: 'app-cancel-or-save-confirmation',
  templateUrl: './cancel-or-save-confirmation.component.html',
  styleUrls: ['./cancel-or-save-confirmation.component.css']
})
export class CancelOrSaveConfirmationComponent implements OnInit {
  myMessage:string;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    private _snackBar: MatSnackBar,
    public router: Router,
    public dialogRef: MatDialogRef<CancelOrSaveConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private grcRegistrationService : GrcRegistrationService,
    private ecRegistrationService : EcRegistrationService) {}

  relatedPerson:RelatedPerson={
    Id:null,
    RelatedId:null,
    Email:null,
    FirstNameRelated:null,
    LastNameRelated:null,
    MyojiRelated:null,
    ShimeiRelated:null,
    DOBRelated:null,
    GenderRelated:null
  };
  relatedPersons:RelatedPerson[]=[];
  volunteerSelections:VolunteerSelection[]=[];
  //conference= new GRCConference();
  conference: ECConference= {
    ECConferenceId:0,
    UserId: null,
    Email: null,
    UserName: null,
    FirstName: null,
    LastName: null,
    //Program
    ProgramType: null,
    SmallGroupLanguage: null,
    AttendJapanRegionalMeeting:null,
    JapanRegionalMeeting:null,
    ApplyEnglishPartner:null,
    //Payment
    ConferenceFee: null,
    ConferenceDiscountAmount: null,
    ConferenceFeePaidAmount: null,
    ConferenceFeePaidDate: null,
    CouponApproved: null,
    ApplyScholarship: null,
    ApplyingScholarshipAmount: null,
    ApprovedScholarshipAmount: null,
    ApplyingReason: null,
    //Others\
    Volunteers:null,
    Student:null,
    ApplyCounseling: null,
    MySleepingBag:null,
    BringExtraSleepingBag:null,
    CountExtraSleepingBag:null,
    NeedCommingBus:null,
    CommingBusDeparturePlace:null,
    CommingBusDepartureDateTime:null,
    CommingAirline:null,
    CommingFlightNo:null,
    DepartureCity:null,
    CommingInternationalDomestic:null,
    ArrivalDateTime:null,
    NeedReturningBus:null,
    ReturningBusDepartureDateTime:null,
    ReturningAirline:null,
    ReturningFlightNo:null,
    ReturningCity:null,
    ReturningBusDeparturePlace:null,
    ReturningInternationalDomestic:null,
    DepartureDateTime:null,
    RoomInfo:null,
    Couple:null,
    Youth:null,
    YouthGrade: null,
    GuardianFirstName: null,
    GuardianLastName: null,
    GuardianMyoji: null,
    GuardianShimei: null,
    GuardianEmail: null,
    GuardianTelNo: null,
    TermsOfParticipation: null,
    TermsOfParticipationParent: null,
    TermsOfParticipationDate: null,
    TermsOfParticipationDateParent: null,
    RegisteredDate: null,
    UpdatedDate: null,

    AdminAccess: null,
    ECCommitteeAccess: null,
    Memo: null,
    Dec26:null,
    Dec27:null,
    Dec28:null,
    Dec29:null,
    Dec30:null,
    Dec31:null,
    KidGroup1:null,
    KidGroup2:null,
    KidGroup3:null,
    KidGroup4:null,
    myRelatedPersons: this.relatedPersons,
    myVolunteerSelections:this.volunteerSelections,
    BusComingConfirmation:null,
    BusReturningConfirmation:null,
    TermsOfParticipationVenue:null,
    TermsOfParticipationDateVenue:null,
    TermsOfParticipationYouth:null,
    TermsOfParticipationDateYouth:null,
    TermsOfParticipationYouthParent:null,
    TermsOfParticipationDateYouthParent:null,
    EmergencyContactFirstName:null,
    EmergencyContactLastName:null,
    EmergencyContactEmail:null,
    EmergencyContactTelNo:null,
    ApplicationFee:null,
    ApplicationFeePaidDate:null,
    BalanceAmount:null,
    BalanceAmountPaidDate:null,
    HasAllergy:null,
    AllergyDetail:null,
    BringInsurance:null,
    MinorHasCustodyInsurance:null,
    MinorHasCustodyInsuranceDate:null,
    InsuranceCompany:null,
    InsuranceAddress:null,
    InsuranceTelNo:null,
    InsurancePolicyNo:null,
    InsuranceGroupNo:null,
    VolunteerAgreeConductCode:null,
    VolunteerAgreeConductCodeDate:null,
    PayingAmount:null,
     //New
    BecomeMentor:null,
    ApplyMentoring:null,
    ReturningDepartureCity:null,
    InsuranceStatus:null,
    DressCodeAgreeYouth:null,
    DressCodeDateYouth:null,
    DressCodeAgreeParent:null,
    DressCodeDateParent:null
}
  ngOnInit() {
    if(this.data.conference!=null){
      this.conference = this.data.conference;
   }
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  onSaveWithoutSavingClick(): void {
    sessionStorage.removeItem("myConferenceInfo");// This is session being used during conference registration
    // Adminによる調べ中のユーザーのMy Accountに戻る前に作業中のセッションは消しておく
    if (sessionStorage.getItem("myECSearchingUserId") != null) {
      sessionStorage.removeItem("myWorkingOnUserAdmin");
      sessionStorage.removeItem("myWorkingOnConferenceAdmin");
    }
    this.dialogRef.close();
  }

  onSaveAndCancel(): void {
    console.log(1);
    sessionStorage.removeItem("myConferenceInfo");// This is session being used during conference registration
    // Adminによる調べ中のユーザーのMy Accountに戻る前に作業中のセッションは消しておく
    if (sessionStorage.getItem("myECSearchingUserId") != null) {
      sessionStorage.removeItem("myWorkingOnConferenceAdmin");
    }
    console.log(2);
    this.ecRegistrationService.upsertConference(this.conference)
      .subscribe(
        () => {
          //success
          this.myMessage = this.getMyLanguage() == "EN" ? "Conference registration information saved successfully " : "カンファレンス情報が保存されました。";
          this.openSnackBar('success', 'close');
        },
        () => {
          //Error
          this.myMessage = this.getMyLanguage() == "EN" ? "Conference registration update failed." : "問題が生じカンファレンス情報がアップデイトされませんでした。";
          this.openSnackBar('error', 'close');
        }
      )
      this.dialogRef.close();
  }

  onClickCancel(){
    this.dialogRef.close("cancel");
  }

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 10000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 20000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }

}
