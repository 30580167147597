<!-- <h1 mat-dialog-title>Hi {{data.registrantFirstName}}</h1> -->
<!-- <h1 mat-dialog-title>{{ this.router.url.includes('EN')==true?"Cancel":"キャンセル"}}</h1> -->
<div class="myMain">

    <!-- <p>{{ data.mySelectedLanguage=="EN"?"I Confirmed this amount is correct":"金額が正しいことを確認しました。"}}</p> -->
    <!-- <mat-label>
     {{this.router.url.includes('EN')==true?"Name":"名前"}}
    </mat-label> -->


     <div class="d-flex justify-content-center">
      {{ this.router.url.includes('EN')==true?"Are you sure to cancel process?":"プロセスを中止しますか?"}}
     </div>

   <div mat-dialog-actions class="d-flex justify-content-center">
    <button mat-button (click)="onSaveWithoutSavingClick()">
     {{ this.router.url.includes('EN')==true?"Calcel without saving":"保存せずに中止する"}}</button>
    <button mat-button (click)="onSaveAndCancel()">
     {{ this.router.url.includes('EN')==true?"Save and Cancel":"保存してから中止"}}</button>
   </div>

   <div mat-dialog-actions class="d-flex justify-content-center">
    <button mat-button  (click)="onClickCancel()">
     {{ this.router.url.includes('EN')==true?"Cancel":"キャンセル"}}</button>
   </div>
  </div>
