import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { UserService} from "../../../../services/user/user.service";
import { Router, ActivatedRoute,NavigationExtras } from "@angular/router";
import { MatRadioChange } from '@angular/material/radio';
import { ECConference } from 'src/app/models/conference/ECConference';
import { User } from 'src/app/models/user';
import { CancelOrSaveConfirmationComponent } from 'src/app/components/dialog/cancel-or-save-confirmation/cancel-or-save-confirmation.component';
//For Dialog
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA , MatDialogConfig} from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { EcRegistrationService } from 'src/app/services/conference/ec/ec-registration.service';
import { RelatedPerson} from "../../../../models/conference/RelatedPerson";
import { VolunteerSelection} from "../../../../models/conference/volunteerSelection";
import { PayByPaypalComponent } from 'src/app/components/dialog/pay-by-paypal/pay-by-paypal.component';
import { MyAccountEditComponent } from 'src/app/components/dialog/EC/my-account-edit/my-account-edit.component';



interface PaymentOption{
  display: string;
  value: number;
}
@Component({
  selector: 'app-conference-fee-detail',
  templateUrl: './conference-fee-detail.component.html',
  styleUrls: ['./conference-fee-detail.component.css']
})
export class ConferenceFeeDetailComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  loaded:boolean=true;
  myFormGroup: FormGroup;
  myMessage:string;
  mySelectedLanguage: string;
  disableSelectingAttendJapanRegionalMeeting:boolean;
  disableJapanRegionalMeetingSelection:boolean;
  showCalculation?:boolean=true;
  stayAmountMyself:number=0;
  stayAmountKidNo1:number=0;
  stayAmountKidNo2:number=0;
  stayAmountKidNo3:number=0;
  noStayAmountMyself:number=0;
  noStayAmountKidNo1:number=0;
  noStayAmountKidNo2:number=0;
  noStayAmountKidNo3:number=0;
  subTotalMyself?:number=0;
  subTotalKidNo1?:number=0;
  subTotalKidNo2?:number=0;
  subTotalKidNo3?:number=0;
  kidNo1Name:string;
  kidNo2Name:string;
  kidNo3Name:string;
  kidNo1WithNoCharge:boolean=false;
  kidNo2WithNoCharge:boolean=false;
  kidNo3WithNoCharge:boolean=false;
  numberOfKidsUnder18:number = 0
  myChildCount:number=0;
  stayCount:number=0;
  dayTrip:number=0;
  showPreviousDay?:boolean;
  showLodgingSchedule?:boolean;
  StayAllDays?:boolean;
  StayPartially?:boolean;
  Dec27Breakfast?:boolean;
  Dec27Lunch?:boolean;
  Dec27Dinner?:boolean;
  Dec28Breakfast?:boolean;
  Dec28Lunch?:boolean;
  Dec28Dinner?:boolean;
  Dec29Breakfast?:boolean;
  Dec29Lunch?:boolean;
  Dec29Dinner?:boolean;
  Dec30Breakfast?:boolean;
  Dec30Lunch?:boolean;
  Dec30Dinner?:boolean;
  Dec31Breakfast?:boolean;
  Dec31Lunch?:boolean;
  Dec31Dinner?:boolean;
  kidsAge:number[]=[];

  strOnline:string;
  editMode: boolean;
  panelOpenState: boolean = false;
  panelOpenAdult :boolean= false;
  panelOpenRJC:boolean=false;
  panelOpenSeekers:boolean=false;
  panelOpenYouth:boolean=false;

  temporalBalanceAmount?:number;

  paymentOptionJP: PaymentOption[] =
  [
    {
      "display": "前払い金: 80ドル",
      "value": 80
    },
    {
      "display": "全額",
      "value": 500
    },
  ]

  paymentOptionEN: PaymentOption[] =
  [
    {
      "display": "Application Fee",
      "value": 80
    },
    {
      "display": "Full Amount",
      "value": 500
    },
  ]



  user: User = {
    PasswordHash:null,
    Id:null,
    UserName:null,
    Email:null,
    FirstName:null,
    LastName:null,
    Password:null,
    Prefix:null,
    Shimei:null,
    Myoji:null,
    Street:null,
    Street2:null,
    City:null,
    State:null,
    Zip:null,
    Country:null,
    Yubin_Bango:null,
    To_Do_Fu_Ken:null,
    Shi_Gun_Ku:null,
    Cho_Son:null,
    Apartment_Etc:null,
    To_Do_Fu_Ken_JP:null,
    Shi_Gun_Ku_JP:null,
    Cho_Son_JP:null,
    Apartment_Etc_JP:null,
    Gender:null,
    DOB:null,
    TelNo:null,
    TimezoneName:null,
    UTCdiff:null,
    Token:null,
    Occupation:null,
    MemberStatus:null,
    Married:null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount:null,
    RegisteredDate:null,
    LastAccessedDate:null,
    //From siteground
    LoginId:null,
    MiddleNameEN:null,
    MiddleNameJP:null,
    PrefixJP:null,
    OccupationDetail:null,
    Organization:null,
    LivingCountry:null,
    PhoneEN:null,
    PhoneMobileEN:null,
    PhoneJP:null,
    PhoneMobileJP:null,
    ChurchNameEN:null,
    ChurchNameJP:null,
    MemberType:null,
    MemberDetail:null,
    MemberMemo:null,
    Shokaisha:null,
    Reason:null,
    NewsLetters:null,
    StaffReports:null,
    OtherLetters:null,
    NewsLetterLanguage:null,
    BasicRoles:null,

    //Added new
    MigrateFromSiteGround:null,
    AdminAccess:null,
    JCFNAccess:null,
    SupportingMember:null,
    LastAddressUpdatedDate:null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };
  relatedPerson:RelatedPerson={
    Id:null,
    RelatedId:null,
    Email:null,
    FirstNameRelated:null,
    LastNameRelated:null,
    MyojiRelated:null,
    ShimeiRelated:null,
    DOBRelated:null,
    GenderRelated:null
  };
  relatedPersons:RelatedPerson[]=[];
  volunteerSelections:VolunteerSelection[]=[];
  conference: ECConference= {
    ECConferenceId:0,
    UserId: null,
    Email: null,
    UserName: null,
    FirstName: null,
    LastName: null,
    //Program
    ProgramType: null,
    SmallGroupLanguage: null,
    AttendJapanRegionalMeeting:null,
    JapanRegionalMeeting:null,
    ApplyEnglishPartner:null,
    //Payment
    ConferenceFee: null,
    ConferenceDiscountAmount: null,
    ConferenceFeePaidAmount: null,
    ConferenceFeePaidDate: null,
    CouponApproved: null,
    ApplyScholarship: null,
    ApplyingScholarshipAmount: null,
    ApprovedScholarshipAmount: null,
    ApplyingReason: null,
    //Others
    Volunteers:null,
    Student:null,
    ApplyCounseling: null,
    MySleepingBag:null,
    BringExtraSleepingBag:null,
    CountExtraSleepingBag:null,
    NeedCommingBus:null,
    CommingBusDeparturePlace:null,
    CommingBusDepartureDateTime:null,
    CommingAirline:null,
    CommingFlightNo:null,
    DepartureCity:null,
    CommingInternationalDomestic:null,
    ArrivalDateTime:null,
    NeedReturningBus:null,
    ReturningBusDeparturePlace:null,
    ReturningBusDepartureDateTime:null,
    ReturningAirline:null,
    ReturningFlightNo:null,
    ReturningCity:null,
    ReturningInternationalDomestic:null,
    DepartureDateTime:null,
    RoomInfo:null,
    Couple:null,
    Youth:null,
    YouthGrade: null,
    GuardianFirstName: null,
    GuardianLastName: null,
    GuardianMyoji: null,
    GuardianShimei: null,
    GuardianEmail: null,
    GuardianTelNo: null,
    TermsOfParticipation: null,
    TermsOfParticipationParent: null,
    TermsOfParticipationDate: null,
    TermsOfParticipationDateParent: null,
    RegisteredDate: null,
    UpdatedDate: null,

    AdminAccess: null,
    ECCommitteeAccess: null,
    Memo: null,
    Dec26:null,
    Dec27:null,
    Dec28:null,
    Dec29:null,
    Dec30:null,
    Dec31:null,
    KidGroup1:null,
    KidGroup2:null,
    KidGroup3:null,
    KidGroup4:null,
    myRelatedPersons: this.relatedPersons,
    myVolunteerSelections:this.volunteerSelections,
    BusComingConfirmation:null,
    BusReturningConfirmation:null,
    TermsOfParticipationVenue:null,
    TermsOfParticipationDateVenue:null,
    TermsOfParticipationYouth:null,
    TermsOfParticipationDateYouth:null,
    TermsOfParticipationYouthParent:null,
    TermsOfParticipationDateYouthParent:null,
    EmergencyContactFirstName:null,
    EmergencyContactLastName:null,
    EmergencyContactEmail:null,
    EmergencyContactTelNo:null,
    ApplicationFee:null,
    ApplicationFeePaidDate:null,
    BalanceAmount:null,
    BalanceAmountPaidDate:null,
    HasAllergy:null,
    AllergyDetail:null,
    BringInsurance:null,
    MinorHasCustodyInsurance:null,
    MinorHasCustodyInsuranceDate:null,
    InsuranceCompany:null,
    InsuranceAddress:null,
    InsuranceTelNo:null,
    InsurancePolicyNo:null,
    InsuranceGroupNo:null,
    VolunteerAgreeConductCode:null,
    VolunteerAgreeConductCodeDate:null,
    PayingAmount:null,
     //New
    BecomeMentor:null,
    ApplyMentoring:null,
    ReturningDepartureCity:null,
    InsuranceStatus:null,
    DressCodeAgreeYouth:null,
    DressCodeDateYouth:null,
    DressCodeAgreeParent:null,
    DressCodeDateParent:null
}

  constructor(
    private _snackBar: MatSnackBar,
    public router: Router,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private registrationService: UserService,
    private ecRegistrationService : EcRegistrationService,
    private dialog: MatDialog,
  ) {}

  mySearch: any = {};
  ngOnInit() {
    this.loaded = false;
    this.myFormGroup = this._formBuilder.group({
      myDec27: [null],
      myDec28: [null],
      myDec29: [null],
      myDec30: [null],
      myDec31: [null],
      myLodgingSchedule: [null, Validators.required],
      myDec27Breakfast: [null],
      myDec27Lunch: [null],
      myDec27Dinner: [null],
      myDec28Breakfast: [null],
      myDec28Lunch: [null],
      myDec28Dinner: [null],
      myDec29Breakfast: [null],
      myDec29Lunch: [null],
      myDec29Dinner: [null],
      myDec30Breakfast: [null],
      myDec30Lunch: [null],
      myDec30Dinner: [null],
      myDec31Breakfast: [null],
      myDec31Lunch: [null],
      myDec31Dinner: [null],
      myPaymentOption:[null, Validators.required],
    });

    if (sessionStorage.getItem("myECSearchingUserId") != null) {
      console.log("Take their Id");
      var searchingRegistrantId = sessionStorage.getItem("myECSearchingUserId");
      /*****************************************
       *  A) By Admin : User info for searching registrant
       ****************************************/
      this.ecRegistrationService.getConferenceByUserId(searchingRegistrantId).subscribe(result => {
        this.conference = result;
        this.patchModelValue(this.conference);
        this.retrievePersonFromDb(this.conference);
        this.updateStayAmount();
        this.processSetPartialAttending();
        this.setPaymentOption();
        this.loaded = true;
      });
      this.loaded = true;
    } else {
      /*****************************************
       *  A) By User
       ****************************************/
      this.mySearch = JSON.parse(sessionStorage.getItem("myConferenceInfo"));
      if (this.mySearch != null) {
        this.conference = this.mySearch.myConferenceSearch;
        //Temporary solution because total fee calculation module did not complete in Aug.
        //this.conference.ApplicationFee = 80;
        this.patchModelValue(this.conference);
        this.retrievePersonFromDb(this.conference);
        this.updateStayAmount();
        this.processSetPartialAttending();
        this.setPaymentOption();
        this.loaded = true;
      } else {
        this.loaded = true;
      }
    }
  }

  setPaymentOption(){
    if(this.conference.ApplicationFeePaidDate==null &&
    this.conference.ConferenceFeePaidDate==null){
      // 1. First timer
      this.paymentOptionJP =
      [
        {
          "display": "前払い金: 80ドル",
          "value": 80
        },
        {
          "display": "全額 : "+this.conference.ConferenceFee.toString()+" ドル",
          "value": this.conference.ConferenceFee
        },
      ]

      this.paymentOptionEN =
      [
        {
          "display": "Application Fee : $80",
          "value": 80
        },
        {
          "display": "Full Amount: $ "+this.conference.ConferenceFee.toString(),
          "value": this.conference.ConferenceFee
        },
      ]
    }else{
      // 2nd timer
      if(this.conference.ConferenceFeePaidDate ==null && this.conference.ApplicationFeePaidDate!=null){
        this.conference.BalanceAmount = this.conference.ConferenceFee - this.conference.ApplicationFee;
      this.paymentOptionJP =
      [
        {
          "display": "残額: "+(this.conference.ConferenceFee - this.conference.ApplicationFee).toString()+" ドル",
          "value": this.conference.ConferenceFee - this.conference.ApplicationFee
        },
      ]
      this.paymentOptionEN =
      [
        {
          "display": "Balance Amount: $ "+(this.conference.ConferenceFee - this.conference.ApplicationFee).toString(),
          "value": this.conference.ConferenceFee - this.conference.ApplicationFee
        },
      ]
      console.log('conference fee:'+this.conference.ConferenceFee);
      console.log('application fee:'+this.conference.ApplicationFee);
    }else if(this.conference.ConferenceFeePaidDate != null){
      this.paymentOptionJP =
      [
        {
          "display": "",
          "value": null
        },
      ]

      this.paymentOptionEN =
      [
        {
          "display": "",
          "value": null
        },
      ]
    }else{

    }
    }
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  retrievePersonFromDb(model: ECConference) {
    if (model.myRelatedPersons != null && model.myRelatedPersons.length > 0) {
      model.myRelatedPersons.forEach(person => {
       //this.kidsAge.push(this.getAge(person.DOBRelated));
       this.kidsAge.push(this.calculateAge(person.DOBRelated));
      });
    }
  }

  getAge(date:Date) {
    var ECFirstDate =  new Date('12/27/2024');
    var birthDate = date; //new Date(dateString);
    var age = ECFirstDate.getFullYear() - birthDate.getFullYear();
    var m = ECFirstDate.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && ECFirstDate.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

calculateAge(date:Date) {
  //var ageDifMs = Date.now() - new Date(date).getTime();
  var ECFirstDate =  new Date('12/27/2024');
  var ageDifMs = ECFirstDate.getTime() - new Date(date).getTime();
  var ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

  //OK
  patchModelValue(model:ECConference){
    var allOrPartial='';
    if(
      this.conference.Dec27==null &&
      this.conference.Dec28==null &&
      this.conference.Dec29==null &&
      this.conference.Dec30==null &&
      this.conference.Dec31==null
    ){
      this.showPreviousDay=false;
      this.showLodgingSchedule=false;
      this.StayAllDays=null;
      this.StayPartially=null;
    }else if(
      model.Dec27=='StayDec27' &&
      model.Dec28=='StayDec28' &&
      model.Dec29=='StayDec29' &&
      model.Dec30=='StayDec30' &&
      model.Dec31=='StayDec31'
    ){
      allOrPartial = "StayAllDays";
      this.StayAllDays=true;
      this.StayPartially=false;
      this.showPreviousDay=true;
      this.showLodgingSchedule=false;
    }
    else{
      allOrPartial = "StayPartially";
      this.StayAllDays=false;
      this.StayPartially=true;
      this.showPreviousDay=false;
      this.showLodgingSchedule=true;
    }
    this.myFormGroup.patchValue({
      myLodgingSchedule:allOrPartial,
      myDec27:model.Dec27,
      myDec28:model.Dec28,
      myDec29:model.Dec29,
      myDec30:model.Dec30,
      myDec31:model.Dec31,
      myPaymentOption:model.ConferenceFeePaidDate!=null?model.ConferenceFee:null
    });
    // if(this.conference.ConferenceFee!=null){
    //   this.showCalculation=true;
    // }else{
    //   this.showCalculation=false;
    // }
  }

  getFormValue() {
   // this.conference.AttendJapanRegionalMeeting =$event.value == 'true' ? true : false;
    //this.conference.AttendJapanRegionalMeeting = this.myFormGroup.get('myAttendingJapanRegionalMeeting').value;
    //this.conference.JapanRegionalMeeting = this.myFormGroup.get('myJapanRegionalMeetingSelection').value;
  }

  //OK
  updateStayAmount() {
    if (this.StayAllDays) {
      this.stayAmountMyself = this.getStayAllDaysAmountMyself();
      var count = 1;
      if (this.conference.myRelatedPersons != null && this.conference.myRelatedPersons.length > 0) {
        this.conference.myRelatedPersons.sort(x=>x.Id).forEach(person => {
          var age = this.calculateAge(person.DOBRelated);
          var childName = person.FirstNameRelated;
          var childCare = person.ChildCare;
          if (age < 18) { //remove spouse
            var stayAmountForKid = this.getStayAllDaysAmountForKid(age, childCare);
            if (count == 1) {
              this.stayAmountKidNo1 = stayAmountForKid;
            } else if (count == 2) {
              this.stayAmountKidNo2 = stayAmountForKid;
            } else if (count == 3) {
              this.stayAmountKidNo3 = stayAmountForKid;
            }
            count++;
          }
        });
      }
    } else if (this.StayPartially) {
      this.stayAmountMyself = 0;
      this.stayAmountKidNo1 = 0;
      this.stayAmountKidNo2 = 0;
      this.stayAmountKidNo3 = 0;
    }
    this.noStayAmountMyself = 0;
    this.noStayAmountKidNo1 = 0;
    this.noStayAmountKidNo2 = 0;
    this.noStayAmountKidNo3 = 0;
    var count = 1;
    if (this.conference.myRelatedPersons != null && this.conference.myRelatedPersons.length > 0) {
      this.conference.myRelatedPersons.sort(x=>x.Id).forEach(person => {
        var age = this.calculateAge(person.DOBRelated);
        var childName = person.FirstNameRelated;;
        if (age < 18) { //remove spouse
          if (count == 1) {
            this.kidNo1Name = childName;
          } else if (count == 2) {
            this.kidNo2Name = childName;
          } else if (count == 3) {
            this.kidNo3Name = childName;
          }
          count++;
        }else{
        }
      });
    }
  }
  //OK
  getStayAllDaysAmountMyself(){
    var stayAllDaysAmount:number=0;
    var today = new Date();
    var indexDate:Date = null;
    indexDate = this.conference.ApplicationFeePaidDate!=null?this.conference.ApplicationFeePaidDate:
    (this.conference.ConferenceFeePaidDate!=null?this.conference.ConferenceFeePaidDate:today);
    console.log(this.conference.ApplicationFeePaidDate)
    console.log(today)
    console.log(indexDate)
    console.log('index date:'+ indexDate)
    var firstEarlyBirdDate = new Date("2024-10-01");
    var secondEarlyBirdDate = new Date("2024-11-05");
    if (Date.parse(indexDate.toString()) <  firstEarlyBirdDate.getTime()) {
      if (this.conference.Student) {
        stayAllDaysAmount = 470;
      } else if (this.conference.Couple) {
        stayAllDaysAmount = 510;
      } else {
        stayAllDaysAmount = 550;
      }
      console.log('I am super early bird')
    } else if (Date.parse(indexDate.toString()) < secondEarlyBirdDate.getTime()) {
      if (this.conference.Student) {
        stayAllDaysAmount = 490;
      } else if (this.conference.Couple) {
        stayAllDaysAmount = 530;
      } else {
        stayAllDaysAmount = 570;
      }
      console.log('I am early bird')
    } else {
      if (this.conference.Student) {
        stayAllDaysAmount = 510;
      } else if (this.conference.Couple) {
        stayAllDaysAmount = 550;
      } else {
        stayAllDaysAmount = 590;
      }
      console.log('I am not early bird')
    }
    return stayAllDaysAmount;
  }
  //OK
  getStayAllDaysAmountForKid(age:number, childCare:boolean){
    var stayAllDaysAmount:number=0;
    var today = new Date();
    var indexDate:Date = null;
    console.log('index date:'+ indexDate)
    indexDate = this.conference.ApplicationFeePaidDate!=null?this.conference.ApplicationFeePaidDate:
    (this.conference.ConferenceFeePaidDate!=null?this.conference.ConferenceFeePaidDate:today);
    var firstEarlyBirdDate = new Date("2024-10-01");
    var secondEarlyBirdDate = new Date("2024-11-05");
    if (Date.parse(indexDate.toString()) < firstEarlyBirdDate.getTime()) {
      if((age==0 || age==1 || age==2) && !childCare){
        stayAllDaysAmount=0;
      }else if((age==0 || age==1 || age==2) && childCare){
        stayAllDaysAmount=80;
      }else if(age>=3 && age<=7){
        stayAllDaysAmount=200;
      }else if(age>=8){
        stayAllDaysAmount=360;
      }
    } else if (Date.parse(indexDate.toString()) < secondEarlyBirdDate.getTime()) {
      if((age==0 || age==1 || age==2) && !childCare){
        stayAllDaysAmount=0;
      }else if((age==0 || age==1 || age==2) && childCare){
        stayAllDaysAmount=90;
      }else if(age>=3 && age<=7){
        stayAllDaysAmount=210;
      }else if(age>=8){
        stayAllDaysAmount=370;
      }
    } else {
      if((age==0 || age==1 || age==2) && !childCare){
        stayAllDaysAmount=0;
      }else if((age==0 || age==1 || age==2) && childCare){
        stayAllDaysAmount=100;
      }else if(age>=3 && age<=7){
        stayAllDaysAmount=220;
      }else if(age>=8){
        stayAllDaysAmount=380;
      }}
    return stayAllDaysAmount;
  }

  async processSetPartialAttending(){
    this.updateStayCount();
    this.updateAttendPartialParticipationFee();
    this.updateAttendPartialParticipationFeeForKid();
    this.updateTotalAmount();
  }

  //OK
  updateStayCount(){
    this.stayCount=0;
    this.dayTrip=0;
    if(this.conference.Dec27=='StayDec27'){
      this.stayCount++;
    }else if(this.conference.Dec27=='NoStayDec27'){
      this.dayTrip++;
    }
    if(this.conference.Dec28=='StayDec28'){
      this.stayCount++;
    }else if(this.conference.Dec28=='NoStayDec28'){
      this.dayTrip++;
    }
    if(this.conference.Dec29=='StayDec29'){
      this.stayCount++;
    }else if(this.conference.Dec29=='NoStayDec29'){
      this.dayTrip++;
    }
    if(this.conference.Dec30=='StayDec30'){
      this.stayCount++;
    }else if(this.conference.Dec30=='NoStayDec30'){
      this.dayTrip++;
    }
    if(this.conference.Dec31=='StayDec31'){
      this.stayCount++;
    }else if(this.conference.Dec31=='NoStayDec31'){
      this.dayTrip++;
    }
  }
  //OK
  updateAttendPartialParticipationFee() {
    if (this.conference.Student) {
      switch (this.stayCount) {
        case 0:
          this.stayAmountMyself = 0;
          break;
        case 1:
          this.stayAmountMyself = 210;
          break;
        case 2:
          this.stayAmountMyself = 270;
          break;
        case 3:
          this.stayAmountMyself = 340;
          break;
        case 4:
          this.stayAmountMyself = 400;
          break;
        case 5:
          this.stayAmountMyself = this.getStayAllDaysAmountMyself();
          break;
      }
      this.noStayAmountMyself = 70 * this.dayTrip;
    } else if (this.conference.Couple) {
      switch (this.stayCount) {
        case 0:
          this.stayAmountMyself = 0;
          break;
        case 1:
          this.stayAmountMyself = 220;
          break;
        case 2:
          this.stayAmountMyself = 285;
          break;
        case 3:
          this.stayAmountMyself = 360;
          break;
        case 4:
          this.stayAmountMyself = 430;
          break;
        case 5:
          this.stayAmountMyself = this.getStayAllDaysAmountMyself();
          break;
      }
      this.noStayAmountMyself = 75 * this.dayTrip;
    } else {
      switch (this.stayCount) {
        case 0:
          this.stayAmountMyself = 0;
          break;
        case 1:
          this.stayAmountMyself = 230;
          break;
        case 2:
          this.stayAmountMyself = 300;
          break;
        case 3:
          this.stayAmountMyself = 380;
          break;
        case 4:
          this.stayAmountMyself = 460;
          break;
        case 5:
          this.stayAmountMyself = this.getStayAllDaysAmountMyself();
          break;
      }
      this.noStayAmountMyself = 80 * this.dayTrip;
    }
  }

  //OK
  updateAttendPartialParticipationFeeForKid(){
    this.myChildCount=0;
    var stayAmountForKid=0;
    var noStayAmountForKid=0;
    if (this.conference.myRelatedPersons != null && this.conference.myRelatedPersons.length > 0) {
          this.conference.myRelatedPersons.sort(x=>x.Id).forEach(person => {
      var age = this.calculateAge(person.DOBRelated);
      var childCare = person.ChildCare;
      if(age<18){ // remove adult
        switch(this.stayCount){
          case 1:
            if((age==0 || age==1 || age==2) && !childCare){
              stayAmountForKid=0;
            }else if((age==0 || age==1 || age==2) && childCare){
              stayAmountForKid=40;
            }else if(age>=3 && age<=7){
              stayAmountForKid=110;
            }else if(age>=8){
              stayAmountForKid=150;
            };
            break;
          case 2:
            if((age==0 || age==1 || age==2) && !childCare){
              stayAmountForKid=0;
            }else if((age==0 || age==1 || age==2) && childCare){
              stayAmountForKid=50;
            }else if(age>=3 && age<=7){
              stayAmountForKid=140;
            }else if(age>=8){
              stayAmountForKid=200;
            };
            break;
          case 3:
            if((age==0 || age==1 || age==2) && !childCare){
              stayAmountForKid=0;
            }else if((age==0 || age==1 || age==2) && childCare){
              stayAmountForKid=60;
            }else if(age>=3 && age<=7){
              stayAmountForKid=170;
            }else if(age>=8){
              stayAmountForKid=250;
            };
            break;
          case 4:
            if((age==0 || age==1 || age==2) && !childCare){
              stayAmountForKid=0;
            }else if((age==0 || age==1 || age==2) && childCare){
              stayAmountForKid=70;
            }else if(age>=3 && age<=7){
              stayAmountForKid=200;
            }else if(age>=8){
              stayAmountForKid=300;
            };
            break;
          case 5:
            stayAmountForKid = this.getStayAllDaysAmountForKid(age, childCare);
            break;
        }
        // if((age==0 || age==1) && !childCare){
        //   noStayAmountForKid=0;
        // }else if((age==0 || age==1) && childCare){
        //   noStayAmountForKid=20*this.dayTrip;
        // }else if(age>=2 && age<=5){
        //   noStayAmountForKid=20*this.dayTrip;
        // }else if(age>=6){
        //   noStayAmountForKid=30*this.dayTrip;
        // };
        if((age==0 || age==1 || age==2) && !childCare){
          noStayAmountForKid=0;
        }else if((age==0 || age==1 || age==2) && childCare){
          noStayAmountForKid=30*this.dayTrip;
        }else if(age>=3 && age<=7){
          noStayAmountForKid=40*this.dayTrip;
        }else if(age>=8){
          noStayAmountForKid=40*this.dayTrip;
        };
        this.myChildCount++;
        if(this.myChildCount==1){
          this.stayAmountKidNo1 = stayAmountForKid;
          this.noStayAmountKidNo1=noStayAmountForKid;
          console.log("Watch: "+this.noStayAmountKidNo1)
        }else if(this.myChildCount==2){
          this.stayAmountKidNo2 = stayAmountForKid;
          this.noStayAmountKidNo2=noStayAmountForKid;
          console.log("Watch: "+this.noStayAmountKidNo2)
        }else if(this.myChildCount==3){
          this.stayAmountKidNo3 = stayAmountForKid;
          this.noStayAmountKidNo3=noStayAmountForKid;
        }
      }
      });
    }
  }

  //OK
  updateTotalAmount(){
    this.numberOfKidsUnder18=0;
    if(this.StayAllDays){
      this.conference.ConferenceFee=
      this.stayAmountMyself+
      this.stayAmountKidNo1+
      this.stayAmountKidNo2+
      this.stayAmountKidNo3;
      this.subTotalMyself = this.stayAmountMyself;
      this.subTotalKidNo1 = this.stayAmountKidNo1;
      this.subTotalKidNo2 = this.stayAmountKidNo2;
      this.subTotalKidNo3 = this.stayAmountKidNo3;
    }else if(this.StayPartially){
      this.conference.ConferenceFee=
     this.stayAmountMyself + this.noStayAmountMyself+
     this.stayAmountKidNo1 + this.noStayAmountKidNo1+
     this.stayAmountKidNo2 + this.noStayAmountKidNo2+
     this.stayAmountKidNo3 + this.noStayAmountKidNo3;
     this.subTotalMyself = this.stayAmountMyself + this.noStayAmountMyself;
     this.subTotalKidNo1 = this.stayAmountKidNo1 + this.noStayAmountKidNo1;
     this.subTotalKidNo2 = this.stayAmountKidNo2 + this.noStayAmountKidNo2;
     this.subTotalKidNo3 = this.stayAmountKidNo3 + this.noStayAmountKidNo3;
    }
    if (this.conference.myRelatedPersons != null && this.conference.myRelatedPersons.length > 0) {
      this.conference.myRelatedPersons.sort(x=>x.Id).forEach(person => {
        var childAge=this.calculateAge(person.DOBRelated);
        if (childAge < 18) {
          this.numberOfKidsUnder18 ++
          if(childAge<2){
            if(this.numberOfKidsUnder18==1){
              this.kidNo1WithNoCharge=true;
            }else if(this.numberOfKidsUnder18==2){
              this.kidNo2WithNoCharge=true;
            }else if(this.numberOfKidsUnder18==3){
              this.kidNo3WithNoCharge=true;
            }
          }
        }
      })
    }

    // if (this.conference.Dec27 == 'StayDec27') {
    //   this.conference.ConferenceFee = this.conference.ConferenceFee + 80;
    //   this.subTotalMyself = this.subTotalMyself+80;

    //   if (this.numberOfKidsUnder18 > 0) {
    //     this.conference.ConferenceFee = this.conference.ConferenceFee + 80 * this.numberOfKidsUnder18 ;
    //     switch(this.numberOfKidsUnder18){
    //       case 1:
    //         if(!this.kidNo1WithNoCharge){
    //           this.subTotalKidNo1 = this.subTotalKidNo1+80;
    //         }
    //         break;
    //       case 2:
    //         if(!this.kidNo1WithNoCharge){
    //           this.subTotalKidNo1 = this.subTotalKidNo1+80;
    //         }
    //         if(!this.kidNo2WithNoCharge){
    //           this.subTotalKidNo2 = this.subTotalKidNo2+80;
    //         }
    //         break;
    //       case 3:
    //         if(!this.kidNo1WithNoCharge){
    //           this.subTotalKidNo1 = this.subTotalKidNo1+80;
    //         }
    //         if(!this.kidNo2WithNoCharge){
    //           this.subTotalKidNo2 = this.subTotalKidNo2+80;
    //         }
    //         if(!this.kidNo3WithNoCharge){
    //           this.subTotalKidNo3 = this.subTotalKidNo3+80;
    //         }
    //         break;
    //     }
    //   }
    // }

    // if(this.conference.MySleepingBag=='BorrowMySleepingBag'){
    //   //console.log("Borrow Sleeping Bag: "+15);
    //   this.conference.ConferenceFee=this.conference.ConferenceFee+15;
    //   this.subTotalMyself=this.subTotalMyself+15;
    // }
    // if(this.conference.BringExtraSleepingBag==true && this.conference.CountExtraSleepingBag>0){
    //  // console.log("Rent out extra sleeping bag: "+this.conference.CountExtraSleepingBag);
    //   this.conference.ConferenceFee = this.conference.ConferenceFee-10*this.conference.CountExtraSleepingBag
    //   this.subTotalMyself = this.subTotalMyself-10*this.conference.CountExtraSleepingBag;
    // }
    if(this.conference.NeedCommingBus==true){
      //console.log("Comming bus required: "+50);
      this.conference.ConferenceFee = this.conference.ConferenceFee+50;
      this.subTotalMyself = this.subTotalMyself+50;
      if (this.numberOfKidsUnder18 > 0) {
        switch(this.numberOfKidsUnder18){
          case 1:
            if(!this.kidNo1WithNoCharge){
              this.subTotalKidNo1 = this.subTotalKidNo1+50;
            }
            break;
          case 2:
            if(!this.kidNo1WithNoCharge){
              this.subTotalKidNo1 = this.subTotalKidNo1+50;
            }
            if(!this.kidNo2WithNoCharge){
              this.subTotalKidNo2 = this.subTotalKidNo2+50;
            }
            break;
          case 3:
            if(!this.kidNo1WithNoCharge){
              this.subTotalKidNo1 = this.subTotalKidNo1+50;
            }
            if(!this.kidNo2WithNoCharge){
              this.subTotalKidNo2 = this.subTotalKidNo2+50;
            }
            if(!this.kidNo3WithNoCharge){
              this.subTotalKidNo3 = this.subTotalKidNo3+50;
            }
            break;
        }
      }else{
        console.log('no kids!')
      }
    }else{
      console.log('no bus needed for coming')
    }
    if(this.conference.NeedReturningBus==true){
     //console.log("Returning bus required: "+50);
      this.conference.ConferenceFee = this.conference.ConferenceFee+50;
      this.subTotalMyself = this.subTotalMyself+50;
      if (this.numberOfKidsUnder18 > 0) {
        switch(this.numberOfKidsUnder18){
          case 1:
            if(!this.kidNo1WithNoCharge){
              this.subTotalKidNo1 = this.subTotalKidNo1+50;
            }
            break;
          case 2:
            if(!this.kidNo1WithNoCharge){
              this.subTotalKidNo1 = this.subTotalKidNo1+50;
            }
            if(!this.kidNo2WithNoCharge){
              this.subTotalKidNo2 = this.subTotalKidNo2+50;
            }
            break;
          case 3:
            if(!this.kidNo1WithNoCharge){
              this.subTotalKidNo1 = this.subTotalKidNo1+50;
            }
            if(!this.kidNo2WithNoCharge){
              this.subTotalKidNo2 = this.subTotalKidNo2+50;
            }
            if(!this.kidNo3WithNoCharge){
              this.subTotalKidNo3 = this.subTotalKidNo3+50;
            }
            break;
        }
      }
      else{
        console.log('no kids!!')
      }
    }else{
      console.log('no bus needed for returning')
    }

    //Total by adding all subTotals
    this.conference.ConferenceFee = this.subTotalMyself+this.subTotalKidNo1+this.subTotalKidNo2+this.subTotalKidNo3;
    console.log('subTotalMyself: '+this.subTotalMyself)
    console.log('subTotalKidNo1: '+this.subTotalKidNo1)
    console.log('subTotalKidNo2: '+this.subTotalKidNo2)
    console.log('subTotalKidNo3: '+this.subTotalKidNo3)
    console.log('sum of all subs: '+(this.subTotalMyself+this.subTotalKidNo1+this.subTotalKidNo2+this.subTotalKidNo3))
    console.log('conference fee before discount: '+this.conference.ConferenceFee)



    this.temporalBalanceAmount = this.conference.ConferenceFee;
    if(this.conference.ApplicationFee!=null && this.conference.ApplicationFeePaidDate!=null){
      this.temporalBalanceAmount = this.temporalBalanceAmount-this.conference.ApplicationFee;
      //Application Feeはディスカウントや奨学金とは違ってConferecne Feeとは別枠で管理。
      console.log('Application Fee: '+this.conference.ApplicationFee);
    }
    if(this.conference.ConferenceDiscountAmount!=null){
      this.temporalBalanceAmount = this.temporalBalanceAmount-this.conference.ConferenceDiscountAmount;
      this.conference.ConferenceFee = this.conference.ConferenceFee-this.conference.ConferenceDiscountAmount;
    }
    console.log('Conference Fee Discount Amount: '+this.conference.ConferenceDiscountAmount);
    console.log('Temporal Balance Amount: '+this.temporalBalanceAmount);
    if(this.conference.ApprovedScholarshipAmount!=null){
      this.temporalBalanceAmount = this.temporalBalanceAmount-this.conference.ApprovedScholarshipAmount;
      this.conference.ConferenceFee= this.conference.ConferenceFee-this.conference.ApprovedScholarshipAmount;
      console.log('pay---pay---pay--pay');

      console.log('Scolarship: '+this.conference.ApprovedScholarshipAmount);
      console.log('Tempral Balance Amount: '+this.temporalBalanceAmount);
      console.log('Conference Fee: '+this.conference.ConferenceFee);



    }
    console.log("Total Amount: "+this.temporalBalanceAmount);
  }

  onSubmit() {
  }

  onClickGoForward(){
    this.getFormValue();
    sessionStorage.removeItem("myWorkingOnConference");
    sessionStorage.setItem(
      "myWorkingOnConference",
      JSON.stringify({ myEdittingConference: this.conference })
    );
//    this.router.navigate(["/registration/ec/scholarship/" +this.getMyLanguage()]);
  }

  onClickGoBack(){
    if(this.router.url.includes('/registration/ec/')){
      this.router.navigate(["/conference/EC/myAccount/" +this.getMyLanguage()]);
    }else{
      this.dialog.closeAll();
      this.openBackEmbed();
    }
  }
  openBackEmbed(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ userId: this.conference.UserId };
    const dialogRef = this.dialog.open(MyAccountEditComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  cancelProcess(){
    this.getFormValue();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='210px'; //Desktop
    dialogConfig.maxWidth='370px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ conference: this.conference };
    const dialogRef = this.dialog.open(CancelOrSaveConfirmationComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        //console.log("Confirmed Action: " + confirmedAction);
      }
      //Retreive updated registration Info.
      //this.ngOnInit();
      let navigationExtras: NavigationExtras = {
        queryParams:this.conference
      };
      if(result!='cancel'){
        if(this.router.url.includes('/registration/ec/')){
          this.router.navigate(["/conference/EC/myAccount/" +this.getMyLanguage()], navigationExtras);
        }else{
          this.dialog.closeAll();
          this.openMyAccountEdit();
        }
        }
    })
  }

  openMyAccountEdit(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1200px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ userId: this.conference.UserId };
    const dialogRef = this.dialog.open(MyAccountEditComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
      }
    })
  }

  onClickPaymentByPaypal() {
    //this.onSaveAndCancel();
    console.log('paying user id: '+this.user.Id);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='450px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ conference: this.conference };
    sessionStorage.removeItem("myWorkingOnConference");
    sessionStorage.setItem(
      "myWorkingOnConference",
      JSON.stringify({ myEditingConference: this.conference })
    );

    const dialogRef = this.dialog.open(PayByPaypalComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
        console.log("returned AS400Code: " + confirmedAction.AS400Code);
      }
      //Retrieve updated registration Info.
      this.ngOnInit();
    })
   // this.ngOnInit(); //これをやっておかないとPaypalのウィンドウをクローズした後、支払い額を選択していないというエラーが出てしまう。
   this.ngOnInit();
  }


   onSelectPaymentAmount(model: string){
    try{
      var paymentAmount= parseInt(model);
      if(paymentAmount==80){
        this.conference.PayingAmount=paymentAmount;
        this.conference.ApplicationFee=paymentAmount;
        console.log('pay $80');
        console.log('payment amount: $'+ paymentAmount);
        console.log('conference fee: $'+this.conference.ConferenceFee);
        console.log('Application fee: $' +this.conference.ApplicationFee);
      }else if(this.conference.ConferenceFee == paymentAmount && this.conference.ConferenceFee!=null){
        // B) Full Amount for 1st timer
        console.log('pay full amount');
        console.log('payment amount: $'+ paymentAmount);
        console.log('conference fee: $'+this.conference.ConferenceFee);
        console.log('Application fee: $' +this.conference.ApplicationFee);
        this.conference.ApplicationFee=null;
        this.conference.PayingAmount=paymentAmount;
      }else if(this.conference.ConferenceFee!=null){
        // C) Balance Amount for 2nd timer
        console.log('pay balance $'+ (this.conference.ConferenceFee-80));
        this.conference.BalanceAmount = this.conference.ConferenceFee - 80;
        this.conference.PayingAmount= this.conference.BalanceAmount;
        console.log('conference amount: $'+ this.conference.ConferenceFee);
        console.log('balance amount: $'+ this.conference.BalanceAmount);
        console.log('paying amount: $'+this.conference.PayingAmount);
      } else{}

    }catch(e){}

    if(model!=null ){}

  }


  // onSaveAndCancel(): void {
  //   sessionStorage.removeItem("myConferenceInfo");// This is session being used during conference registration
  //   // Adminによる調べ中のユーザーのMy Accountに戻る前に作業中のセッションは消しておく
  //   if (sessionStorage.getItem("myECSearchingUserId") != null) {
  //     sessionStorage.removeItem("myWorkingOnConferenceAdmin");
  //   }
  //   this.ecRegistrationService.upsertConference(this.conference)
  //     .subscribe(
  //       () => {
  //         //success
  //         // this.myMessage = this.getMyLanguage() == "EN" ? "Conference registration information saved successfully " : "カンファレンス情報が保存されました。";
  //         // this.openSnackBar('success', 'close');
  //       },
  //       () => {
  //         //Error
  //         // this.myMessage = this.getMyLanguage() == "EN" ? "Conference registration update failed." : "問題が生じカンファレンス情報がアップデイトされませんでした。";
  //         // this.openSnackBar('error', 'close');
  //       }
  //     )
  // }

  // openSnackBar(message: string, action: string) {
  //   if (message == "success") {
  //     this._snackBar.open(this.myMessage, action, {
  //       duration: 10000,
  //       panelClass: ["customPanel_Success"],
  //       horizontalPosition: this.horizontalPosition,
  //       verticalPosition: this.verticalPosition,
  //     });
  //   } else if (message = "error") {
  //     this._snackBar.open(this.myMessage, action, {
  //       duration: 20000,
  //       panelClass: ["customPanel_Error"],
  //       horizontalPosition: this.horizontalPosition,
  //       verticalPosition: this.verticalPosition,
  //     });
  //   }
  // }
}





