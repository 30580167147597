import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
@Component({
  selector: 'app-email-sent-message',
  templateUrl: './email-sent-message.component.html',
  styleUrls: ['./email-sent-message.component.css']
})
export class EmailSentMessageComponent implements OnInit {
  firstFormGroup: FormGroup;

  constructor(
    public router: Router,
    private _formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
    });
  }


}
