<div class="myBase">
  <div class="myBackgroundLayer">
   <div class="row">
    <div class="myAccountTitle">
     <h2>
     </h2>
    </div>
   </div>
   <div *ngIf="!loaded">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
 
   <div *ngIf="loaded"  class="d-flex justify-content-center">
    <mat-card class="supportingMembershipAnounce">
      <form [formGroup]="firstFormGroup">
     <h3>
      {{this.router.url.includes('EN')==true?'Regular member': '一般会員'}}
     </h3>
     <br><br>
     <div class="ckbZoomAccount">
      <mat-checkbox 
      formControlName="myAcceptRegularMember" 
      #checkboxAgree
       (change)='onChangeAgree(checkboxAgree.checked)'
       [(ngModel)]="toBeRegularMember"
        class="ckb_SG"
        required
        >
        {{this.router.url.includes('EN')==true?'Become a regular member': '一般会員になる'}}
      </mat-checkbox>
    </div>
     <br><br>
     <br>
     <div class="d-flex justify-content-center">
      <button 
      type="button" 
      class="btn btnProcessPayment" 
      (click)="onClickBecomeRegularMember()"
      [disabled]="toBeRegularMember!=true"
      >
      {{this.router.url.includes('EN')==true?'Send': '送信'}}
      </button>
     </div>
 
     <!-- <button mat-button (click)="onClickGoBack()" class="btn btn-link">
       {{this.router.url.includes('EN')==true?'Go Back': '戻る'}}
     </button>
      -->
 
     <a [routerLink]="this.router.url.includes('EN')==true?'/home/EN': '/home/JP'"
     class="btn btn-link">{{this.router.url.includes('EN')==true?'Home': 'ホーム'}}</a>&nbsp;&nbsp;
     <a [routerLink]="this.router.url.includes('EN')==true?'/members/myProfile/EN': '/members/myProfile/JP'"
     class="btn btn-link">{{this.router.url.includes('EN')==true?'My Account': 'マイアカウント'}}</a>
 
      </form>
    </mat-card>
 
 
    
   </div>
   <div  class="d-flex justify-content-center">
    <button mat-button (click)="onClickGoBack()" class="btn btn-link">
       {{this.router.url.includes('EN')==true?'Go Back': '戻る'}}
    </button>
   </div>
  </div>
 </div>