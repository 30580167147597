<div class="myBody">
    <div class="myHeaderBase d-flex justify-content-center">
        <div class="myHeaderCore">
            <div>
                <img src='../../../../../assets/images/thumbnail_eclogo.png'  class="ecLogo">
            </div>
        </div>
    </div>
    <div class="myMain">
        <div clas="myMainLeft">
            <div class="myMainHalfTop">
                <!-- Left Top -->
            </div>
            <div class="myMainHalfBottom">
                <!-- Left Bottom -->
            </div>
        </div>
        <div class="myMainCenter">
            <div class="myMainHalfTop d-flex justify-content-center">
                <!-- Main Top -->

                <mat-card class="mainCard b-radius">
                        <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
                        <div *ngIf="!loaded">
                            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                        </div>
                        <!-- <form *ngIf="userFromServer | async; else loading"  -->
                            <form
                            [formGroup]="myFormGroup"
                            (ngSubmit)="onSubmit()"
                            >
                        <div class="float-end stepNumber">6/12</div>
                        <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Mentoring':
                            'メンタリング'}}
                            <i class="stepTitleSub">
                                {{this.router.url.includes('EN')==true?'Required': '必須'}}
                            </i>
                        </h2>

                        <div class="stepTitle2nd">
                            {{this.router.url.includes('EN')==true?
                            'One-time mentoring sessions will be offered during EC. Would you be willing to use your time to help someone who needs life advice or wants to have their concerns heard? Would you also like to take this rare opportunity to talk to a senior person of faith and have them listen to you?':
                            'EC期間中、ワンタイムメンタリングセッションを提供します。人生のアドバイスを必要としている人、悩みを聞いて欲しい人のために、あなたの時間を使っていただけませんか？また、この貴重な機会に信仰の先輩に話を聞き、また聞いてもらいませんか？'}}
                            <!-- <a href='https://sites.google.com/secondlevel-soulcare.org/ec-2020/%E3%83%9B%E3%83%BC%E3%83%A0' target="_blank">{{this.router.url.includes('EN')==true?'': 'ウェブサイト'}}</a><br> -->
                            <!-- <a href="https://sites.google.com/secondlevel-soulcare.org/ec-2020/%E6%97%A5%E6%9C%AC%E8%AA%9E?authuser=0" target="_blank">{{this.router.url.includes('EN')==true?'Website Link': 'ウェブサイトリンク'}}</a> <br> -->
                        </div>
                        <div *ngIf="conference.ProgramType=='Youth'" class="stepTitle2nd">
                          <u>{{this.router.url.includes('EN')==true?'Youth are not eligible.':
                            'ユースは対象外'}}
                          </u>
                        </div>
                        <br>
                        <div class="stepTitle3rd">
                          {{this.router.url.includes('EN')==true?'1. I would like to serve as a mentor.':'1. メンターとして奉仕することができます。'}}
                        </div>
                         <div  *ngIf="loaded" class="d-flex justify-content-center">
                            <mat-radio-group formControlName="myBecomeMentor"
                               (change)="onChangeBecomingMentorSelection($event)" class="rbGroup">
                               <mat-radio-button value=true class="rbItem"
                               [checked]="conference.BecomeMentor==true"
                               [disabled]="conference.ProgramType=='Youth'"
                               >
                                  {{this.router.url.includes('EN')==true?'Yes': 'はい'}}</mat-radio-button>
                               <mat-radio-button value=false class="rbItem"
                               [checked]="conference.BecomeMentor==false"
                               [disabled]="conference.ProgramType=='Youth'"
                               >
                                  {{this.router.url.includes('EN')==true?'No': 'いいえ'}}</mat-radio-button>
                            </mat-radio-group>
                         </div><br>
                         <div class="stepTitle3rd">
                          {{this.router.url.includes('EN')==true?
                          'We will contact those who have expressed interest at a later date. Please note that due to factors such as the number of people requesting mentoring and the matching of desired fields, there is a possibility that you may not be selected as a mentor.':
                          '希望した方にはこちらから後日連絡をさせていただきます。メンタリングの希望人数や希望する分野等のマッチング等によりメンターをしていただかない可能性もありますのであらかじめご了承ください。'}}
                      </div>
                         <br><br><br>
                        <div class="stepTitle3rd">
                          {{this.router.url.includes('EN')==true?'2. I would like to receive a mentoring session':'2. メンタリングセッションを受けたいです。'}}
                        </div>
                         <div  *ngIf="loaded" class="d-flex justify-content-center">
                            <mat-radio-group formControlName="myApplyMentoring"
                               (change)="onChangeTakingMentoringSelection($event)" class="rbGroup">
                               <mat-radio-button value=true class="rbItem"
                               [checked]="conference.ApplyMentoring==true"
                               [disabled]="conference.ProgramType=='Youth'"
                               >
                                  {{this.router.url.includes('EN')==true?'Yes': 'はい'}}</mat-radio-button>
                               <mat-radio-button value=false class="rbItem"
                               [checked]="conference.ApplyMentoring==false"
                               [disabled]="conference.ProgramType=='Youth'"
                               >
                                  {{this.router.url.includes('EN')==true?'No': 'いいえ'}}</mat-radio-button>
                            </mat-radio-group>
                         </div><br>
                         <div class="stepTitle3rd">
                          {{this.router.url.includes('EN')==true?
                          'We will contact you separately if you wish to have a mentoring session.':
                          'メンタリングを希望される方には別途こちらからコンタクトさせていただきます。'}}<br>
                          {{this.router.url.includes('EN')==true?
                          'We will keep you updated on the details through Social Media and other means':
                          '内容については逐次SNS等でお知らせいたします。'}}&nbsp;&nbsp;
                          <!-- <a href='https://sites.google.com/secondlevel-soulcare.org/ec2023/' target="_blank">{{this.router.url.includes('EN')==true?'': 'ウェブサイト'}}</a>
                         <a href="https://sites.google.com/secondlevel-soulcare.org/ec2023/english" target="_blank">{{this.router.url.includes('EN')==true?'Website Link': ''}}</a> <br> -->
                      </div>

                        <div class=" position-absolute bottom-0 start-50 translate-middle-x">
                            <div class="d-flex justify-content-center">
                                <button type="button" class="btn btnProcess" (click)="onClickGoBack()">
                                    {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                                </button>
                                <button [disabled]="!myFormGroup.valid" type="button" class="btn btnProcess"
                                    (click)="onClickGoForward()">
                                    {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                                </button>
                            </div>
                            <div class="d-flex justify-content-center">
                                <button type="button" class="btn link btnCancel"
                                    (click)="cancelProcess()">
                                    {{this.router.url.includes('EN')==true?'Cancel': 'キャンセル'}}
                                  </button>
                            </div>
                        </div>
                        <!-- <button type="submit" [disabled]="!myFormGroup.valid">Submit</button> -->
                    </form>
                </mat-card>
            </div>
            <div class="myMainHalfBottom">
                <!-- Main Bottom -->
            </div>
        </div>
        <div class="myMainRight">
            <div class="myMainHalfTop">
                <!-- Right Top -->
            </div>
            <div class="myMainHalfBottom">
                <!-- Right Bottom -->
            </div>
        </div>
    </div>
    <!-- <footer>footer</footer> -->
</div>
