import { Component, Inject, OnInit } from '@angular/core';
import { UserService } from "../../../services/user/user.service";
import { Router, ActivatedRoute } from "@angular/router";

import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
//import { CancelProcessConfirmationComponent } from '../../dialog/cancel-process-confirmation/cancel-process-confirmation.component';
//import { EditConferenceNeedDinnerComponent } from '../../dialog/edit-conference-need-dinner/edit-conference-need-dinner.component';


import { MatDialog, MatDialogRef, MAT_DIALOG_DATA,MatDialogConfig} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { LoginCheckEmailComponent} from "../../dialog/login-check-email/login-check-email.component";

import { MatRadioChange } from '@angular/material/radio';
import { ECConference } from 'src/app/models/conference/ECConference';
import { VolunteerSelection } from 'src/app/models/conference/volunteerSelection';
import { RelatedPerson } from 'src/app/models/conference/RelatedPerson';
import { EcRegistrationService } from 'src/app/services/conference/ec/ec-registration.service';

export interface DialogData {
  conference:ECConference
}

declare var paypal:any;

@Component({
  selector: 'app-pay-by-paypal',
  templateUrl: './pay-by-paypal.component.html',
  styleUrls: ['./pay-by-paypal.component.css']
})
export class PayByPaypalComponent implements OnInit {
  loaded: boolean = true;
  myMessage: string;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  relatedPersons:RelatedPerson[]=[];
  volunteerSelections:VolunteerSelection[]=[];

  conference: ECConference= {
    ECConferenceId:0,
    UserId: null,
    Email: null,
    UserName: null,
    FirstName: null,
    LastName: null,
    //Program
    ProgramType: null,
    SmallGroupLanguage: null,
    AttendJapanRegionalMeeting:null,
    JapanRegionalMeeting:null,
    //Payment
    ConferenceFee: null,
    ConferenceDiscountAmount: null,
    ConferenceFeePaidAmount: null,
    ConferenceFeePaidDate: null,
    CouponApproved: null,
    ApplyScholarship: null,
    ApplyingScholarshipAmount: null,
    ApprovedScholarshipAmount: null,
    ApplyingReason: null,
    //Others
    Volunteers:null,
    ApplyCounseling: null,
    MySleepingBag:null,
    BringExtraSleepingBag:null,
    CountExtraSleepingBag:null,
    NeedCommingBus:null,
    CommingBusDeparturePlace:null,
    CommingBusDepartureDateTime:null,
    CommingAirline:null,
    CommingFlightNo:null,
    DepartureCity:null,
    CommingInternationalDomestic:null,
    ArrivalDateTime:null,
    NeedReturningBus:null,
    ReturningBusDeparturePlace:null,
    ReturningBusDepartureDateTime:null,
    ReturningAirline:null,
    ReturningFlightNo:null,
    ReturningCity:null,
    ReturningInternationalDomestic:null,
    DepartureDateTime:null,
    RoomInfo:null,
    Couple:null,
    Youth:null,
    YouthGrade: null,
    GuardianFirstName: null,
    GuardianLastName: null,
    GuardianMyoji: null,
    GuardianShimei: null,
    GuardianEmail: null,
    GuardianTelNo: null,
    TermsOfParticipation: null,
    TermsOfParticipationParent: null,
    TermsOfParticipationDate: null,
    TermsOfParticipationDateParent: null,
    RegisteredDate: null,
    UpdatedDate: null,
    AdminAccess: null,
    ECCommitteeAccess: null,
    Memo: null,
    Dec26:null,
    Dec27:null,
    Dec28:null,
    Dec29:null,
    Dec30:null,
    Dec31:null,
    KidGroup1:null,
    KidGroup2:null,
    KidGroup3:null,
    KidGroup4:null,
    myRelatedPersons: this.relatedPersons,
    myVolunteerSelections:this.volunteerSelections,
    BusComingConfirmation:null,
    BusReturningConfirmation:null,
    TermsOfParticipationVenue:null,
    TermsOfParticipationDateVenue:null,
    TermsOfParticipationYouth:null,
    TermsOfParticipationDateYouth:null,
    TermsOfParticipationYouthParent:null,
    TermsOfParticipationDateYouthParent:null,
    EmergencyContactFirstName:null,
    EmergencyContactLastName:null,
    EmergencyContactEmail:null,
    EmergencyContactTelNo:null,
    ApplicationFee:null,
    ApplicationFeePaidDate:null,
    BalanceAmount:null,
    BalanceAmountPaidDate:null,
    HasAllergy:null,
    AllergyDetail:null,
    BringInsurance:null,
    MinorHasCustodyInsurance:null,
    MinorHasCustodyInsuranceDate:null,
    InsuranceCompany:null,
    InsuranceAddress:null,
    InsuranceTelNo:null,
    InsurancePolicyNo:null,
    InsuranceGroupNo:null,
    VolunteerAgreeConductCode:null,
    VolunteerAgreeConductCodeDate:null,
    PayingAmount:null,
     //New
    BecomeMentor:null,
    ApplyMentoring:null,
    ReturningDepartureCity:null,
    InsuranceStatus:null,
    DressCodeAgreeYouth:null,
    DressCodeDateYouth:null,
    DressCodeAgreeParent:null,
    DressCodeDateParent:null
  }

  product = {
    price: this.conference.PayingAmount,
    description: 'EC Conference Fee for: '+this.conference.FirstName+' '+this.conference.LastName,
    img: 'assets/tesla.jpg'
  }
  paidFor = false;

  constructor(
    private _snackBar: MatSnackBar,
    public router: Router,
    private userService: UserService,
    public dialogRef: MatDialogRef<PayByPaypalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog,
    private ecRegistrationService : EcRegistrationService,
  ) { }

  ngOnInit(): void {
    //1. Get conference info for loginUser
    if (this.data.conference != null) {
      this.conference = this.data.conference;
      this.product.price = this.conference.PayingAmount;
      this.product.description='EC Conference Fee for: '+this.conference.FirstName+' '+this.conference.LastName;

      // 2. Payment : Below coding is from paypal.
      paypal
        .Buttons({
          createOrder: (data: any, actions: any) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: this.product.description,
                  amount: {
                    currency_code: 'USD',
                    value: this.product.price
                  }
                }
              ],
              'application_context': {
                'shipping_preference': 'NO_SHIPPING'
              }
            });
            // return actions.order.capture().then(function(details:any) {
            //   alert('Transaction completed by ' + details.payer.name.given_name);
            // });
          },

          onApprove: async (data: any, actions: any) => {
            const order = await actions.order.capture();
            console.log('***********************');
            console.log(data);
            console.log('~~~~~~~~~~~~~~~~~~~~~~~~~');
            this.paidFor = true;
            console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
            console.log(order);
            alert('Transaction completed by ' + order.payer.name.given_name + ". Your status will be updated as soon as the payment is confirmed. Thank you");
            //this.cancelProcess();
            //********  Going to update user's payment date: ******************//
            if(this.conference.ApplicationFee!=null && this.conference.ApplicationFeePaidDate ==null){
              // a) In case user pay application fee.
              this.conference.ApplicationFeePaidDate = new Date();
              this.updateApplicationFeePaidDate(this.conference);
            }else if(this.conference.ApplicationFee==null){
              // b) In case user pay full amount
              this.conference.ConferenceFeePaidDate = new Date();
              this.conference.ConferenceFeePaidAmount= this.conference.ConferenceFee;
              this.updateConferenceFeePaidDate(this.conference);
            }else if(this.conference.ApplicationFee !=null && this.conference.ApplicationFeePaidDate!=null){
              // c) In case user pay balance amount
              this.conference.BalanceAmountPaidDate = new Date();
              this.conference.BalanceAmount = this.conference.ConferenceFee - this.conference.ApplicationFee;
              this.conference.ConferenceFeePaidDate = new Date();
              this.conference.ConferenceFeePaidAmount= this.conference.ConferenceFee;
              this.updateConferenceFeePaidDate(this.conference);
            }
            console.log('###################################');
            this.dialogRef.close();
          },

          onError: (err: any) => {
            console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%');
            console.log(err);
            console.log('&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&');
          },
          style: {
            layout: 'vertical',
            color: 'gold',
            shape: 'pill',
            label: 'paypal',
            height: 40
          }
        })
        // .render(this.paypalElement.nativeElement);
        .render('#myPaypalContainer');
    }
  }

  getMyLanguage(): string {
    return this.router.url.includes('EN') == true ? 'EN' : 'JP';
  }

  updateApplicationFeePaidDate(conference: ECConference) {
    console.log('going to update payment date');
    this.ecRegistrationService
      .updateApplicationFeePaidDate(this.conference)
      .subscribe(
        data => {
          //success
          this.myMessage = this.getMyLanguage() == "EN" ? "Payment Completed Successfully" : "支払いが完了しました。";
          this.openSnackBar('success', 'close');
          console.log('I am going to send confirmation email now!')
          this.sendRegistrationConfirmationEmail(this.conference);
          this.loaded = true;
        },
        error => {
          this.myMessage = this.getMyLanguage() == "EN" ? "Payment failed" : "問題が生じ支払いが完了しませんでした";
          this.openSnackBar('error', 'close');
          this.loaded = true;
        }
      )
  }

  updateConferenceFeePaidDate(conference: ECConference) {
    console.log('going to update payment date');
    this.conference.ConferenceFeePaidDate = new Date();
    this.ecRegistrationService
      .updateConferenceFeePaidDate(this.conference)
      .subscribe(
        data => {
          //success
          console.log('I am going to send confirmation email now!')
          this.sendRegistrationConfirmationEmail(this.conference);
          this.loaded = true;
          // this.myMessage = this.getMyLanguage() == "EN" ? "Payment Completed Successfully" : "支払いが完了しました。";
          // this.openSnackBar('success', 'close');
          this.router.navigate(["/payment/successfullyProcessedEC/" +this.getMyLanguage()]);

        },
        error => {
          this.myMessage = this.getMyLanguage() == "EN" ? "Payment failed" : "問題が生じ支払いが完了しませんでした";
          this.openSnackBar('error', 'close');
          this.loaded = true;
        }
      )
  }

  sendRegistrationConfirmationEmail(conference: ECConference) {
    console.log('going to send confirmation email');
    this.ecRegistrationService
     // .SendConfirmationEmailToUserECApplicationFeeByPaypal(conference)
        .sendConfirmationEmailToUserECConferenceFeeByPaypal(conference) // on and after 11/21/22
      .subscribe(
        data => {
          //success
        },
        error => {
          this.myMessage = this.getMyLanguage() == "EN" ? "Confirmation Mail failed to send due to unknown reason" :
            "問題が生じ確認メールが送信されませんでした";
          this.openSnackBar('error', 'close');
          this.loaded = true;
        }
      )
  }

  // cancelProcess(){
  //   const dialogRef = this.dialog.open(CancelProcessConfirmationComponent, {
  //     width: '250px',
  //     data: {
  //       conference: this.conference
  //     }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     var confirmedAction = result;
  //     console.log("Confirmed Action: " + confirmedAction);
  //     if (confirmedAction === this.conference) {
  //       this.dialogRef.close();
  //     } else {
  //     }
  //   });
  // }

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 5000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 20000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }

  onClickBack(){
    this.dialogRef.close();
  }
}
