import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,  Validators, AbstractControl, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../../../services/auth/auth.service";
import { User } from "../../../models/user";
import { UserService } from "../../../services/user/user.service";
import { MatRadioChange } from '@angular/material/radio';
import { MatStepper } from '@angular/material/stepper';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
//For Dialog
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { LoginMessageComponent } from '../../dialog/login-message/login-message.component';
import { UserEditMyProfileComponent } from '../../dialog/user-edit-my-profile/user-edit-my-profile.component';
import { UserEditOthersProfileComponent } from '../../dialog/user-edit-others-profile/user-edit-others-profile.component';
import { MyAccountImportDialogComponent } from '../../dialog/my-account-import-dialog/my-account-import-dialog.component';
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
/***** Angular Table ****************/
import { AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CheckGroup } from '../../../models/checkGroup';
import { MemberList } from '../../../models/memberList';
import { Sort } from '@angular/material/sort';
import { UpsertTemplateComponent } from '../../dialog/upsert-template/upsert-template.component';
import { MemberListTemplate } from 'src/app/models/MemberListTemplate';

interface MyList {
  display: string;
  value: string;
}

@Component({
  selector: 'app-members-list-custom',
  templateUrl: './members-list-custom.component.html',
  styleUrls: ['./members-list-custom.component.css']
})
export class MembersListCustomComponent implements OnInit {
  genders = [
    { value: "All", display: "All" },
    { value: "Male", display: "Male" },
    { value: "Female", display: "Female" }
  ];

  memberTypes = [
    { value: "J", display: "Associate Member(準会員)" },
    { value: "H", display: "Non-Member(非会員)" },
    { value: "E", display: "Former Supporting Member(元維持会員)" },
    { value: "S", display: "Regular Member(一般会員)" },
    { value: "P", display: "Supporting Member(維持会員)" },
    { value: "U", display: "Supporting Member (Donation Outstanding)維持会員(献金確認待ち)''" }
  ];


  indexDate:Date;
  checkGroup: CheckGroup = {
    id: null,
    value: null,
  };
  checkGroups: CheckGroup[] = [];

  public membershipTypes = [
    { value: "All", display: "All" },
    { value: "Supporting", display: "Supporting Member" },
    { value: "Regular", display: "Regular Member" },
    { value: "Other", display: "Other" }
  ];
  listMembershipTypes: CheckGroup[] =
    [
      { id: 0, value: "All" },
      { id: 1, value: 'Supporting' },
      { id: 2, value: 'Regular' },
      { id: 3, value: 'Others' }]

  listNewsLetters: CheckGroup[] =
  [
    { id: 0, value: "All" },
    { id: 1, value: 'News Letter' },
    { id: 2, value: 'Director Letter' },
    { id: 3, value: 'Prayer Warrior' },
    { id: 4, value: 'Others' },
  ]

  receivingMethods = [
    { value: "All", display: "All" },
    { value: "E", display: "Email" },
    { value: "P", display: "Paper" }
  ];

  activeStatuses = [
    { value: null, display: "All" },
    { value: true, display: "Active" },
    { value: false, display: "Inactive" }
  ];

  newsLetterLanguages: MyList[] = [
    {
      "display": "All",
      "value": "All"
    },
    {
      "display": "Japanese",
      "value": "Japanese"
    },
    {
      "display": "English",
      "value": "English"
    },
    {
      "display": "Bilingual",
      "value": "Bilingual"
    }
  ]

  countries: MyList[] =
    [
      {
        "display": "All",
        "value": "All"
      },
      {
        "display": "Japan",
        "value": "Japan",
      },
      {
        "display": "United States",
        "value": "United States",
      },
      {
        "display": "Others",
        "value": "Others",
      },
    ]

  states: MyList[] =
    [
      {
        "display": "All",
        "value": "All"
      },
      {
        "display": "Alabama",
        "value": "AL"
      },
      {
        "display": "Alaska",
        "value": "AK"
      },
      {
        "display": "American Samoa",
        "value": "AS"
      },
      {
        "display": "Arizona",
        "value": "AZ"
      },
      {
        "display": "Arkansas",
        "value": "AR"
      },
      {
        "display": "California",
        "value": "CA"
      },
      {
        "display": "Colorado",
        "value": "CO"
      },
      {
        "display": "Connecticut",
        "value": "CT"
      },
      {
        "display": "Delaware",
        "value": "DE"
      },
      {
        "display": "District Of Columbia",
        "value": "DC"
      },
      {
        "display": "Federated States Of Micronesia",
        "value": "FM"
      },
      {
        "display": "Florida",
        "value": "FL"
      },
      {
        "display": "Georgia",
        "value": "GA"
      },
      {
        "display": "Guam",
        "value": "GU"
      },
      {
        "display": "Hawaii",
        "value": "HI"
      },
      {
        "display": "Idaho",
        "value": "ID"
      },
      {
        "display": "Illinois",
        "value": "IL"
      },
      {
        "display": "Indiana",
        "value": "IN"
      },
      {
        "display": "Iowa",
        "value": "IA"
      },
      {
        "display": "Kansas",
        "value": "KS"
      },
      {
        "display": "Kentucky",
        "value": "KY"
      },
      {
        "display": "Louisiana",
        "value": "LA"
      },
      {
        "display": "Maine",
        "value": "ME"
      },
      {
        "display": "Marshall Islands",
        "value": "MH"
      },
      {
        "display": "Maryland",
        "value": "MD"
      },
      {
        "display": "Massachusetts",
        "value": "MA"
      },
      {
        "display": "Michigan",
        "value": "MI"
      },
      {
        "display": "Minnesota",
        "value": "MN"
      },
      {
        "display": "Mississippi",
        "value": "MS"
      },
      {
        "display": "Missouri",
        "value": "MO"
      },
      {
        "display": "Montana",
        "value": "MT"
      },
      {
        "display": "Nebraska",
        "value": "NE"
      },
      {
        "display": "Nevada",
        "value": "NV"
      },
      {
        "display": "New Hampshire",
        "value": "NH"
      },
      {
        "display": "New Jersey",
        "value": "NJ"
      },
      {
        "display": "New Mexico",
        "value": "NM"
      },
      {
        "display": "New York",
        "value": "NY"
      },
      {
        "display": "North Carolina",
        "value": "NC"
      },
      {
        "display": "North Dakota",
        "value": "ND"
      },
      {
        "display": "Northern Mariana Islands",
        "value": "MP"
      },
      {
        "display": "Ohio",
        "value": "OH"
      },
      {
        "display": "Oklahoma",
        "value": "OK"
      },
      {
        "display": "Oregon",
        "value": "OR"
      },
      {
        "display": "Palau",
        "value": "PW"
      },
      {
        "display": "Pennsylvania",
        "value": "PA"
      },
      {
        "display": "Puerto Rico",
        "value": "PR"
      },
      {
        "display": "Rhode Island",
        "value": "RI"
      },
      {
        "display": "South Carolina",
        "value": "SC"
      },
      {
        "display": "South Dakota",
        "value": "SD"
      },
      {
        "display": "Tennessee",
        "value": "TN"
      },
      {
        "display": "Texas",
        "value": "TX"
      },
      {
        "display": "Utah",
        "value": "UT"
      },
      {
        "display": "Vermont",
        "value": "VT"
      },
      {
        "display": "Virgin Islands",
        "value": "VI"
      },
      {
        "display": "Virginia",
        "value": "VA"
      },
      {
        "display": "Washington",
        "value": "WA"
      },
      {
        "display": "West Virginia",
        "value": "WV"
      },
      {
        "display": "Wisconsin",
        "value": "WI"
      },
      {
        "display": "Wyoming",
        "value": "WY"
      }
    ]
  to_do_fu_kensJP: MyList[] =
    [
      {
        "display": "All",
        "value": "All:All"
      },
      {
        "display": "北海道",
        "value": "Hokkaido:北海道"
      },
      {
        "display": "青森県",
        "value": "Aomori:青森県"
      },
      {
        "display": "岩手県",
        "value": "Iwate:岩手県"
      },
      {
        "display": "宮城県",
        "value": "Miyagi:宮城県"
      },
      {
        "display": "秋田県",
        "value": "Akita:秋田県"
      },
      {
        "display": "山形県",
        "value": "Yamagata:山形県"
      },
      {
        "display": "福島県",
        "value": "Fukushima:福島県"
      },
      {
        "display": "茨城県",
        "value": "Ibaraki:茨城県"
      },
      {
        "display": "栃木県",
        "value": "Tochigi:栃木県"
      },
      {
        "display": "群馬県",
        "value": "Gunma:群馬県"
      },
      {
        "display": "埼玉県",
        "value": "Saitama:埼玉県"
      },
      {
        "display": "千葉県",
        "value": "Chiba:千葉県"
      },
      {
        "display": "東京都",
        "value": "Tokyo:東京都"
      },
      {
        "display": "神奈川県",
        "value": "Kanagawa:神奈川県"
      },
      {
        "display": "新潟県",
        "value": "Niigata:新潟県"
      },
      {
        "display": "富山県",
        "value": "Toyama:富山県"
      },
      {
        "display": "石川県",
        "value": "Ishikawa:石川県"
      },
      {
        "display": "福井県",
        "value": "Fukui:福井県"
      },
      {
        "display": "山梨県",
        "value": "Yamanashi:山梨県"
      },
      {
        "display": "長野県",
        "value": "Nagano:長野県"
      },
      {
        "display": "岐阜県",
        "value": "Gifu:岐阜県"
      },
      {
        "display": "静岡県",
        "value": "Shizuoka:静岡県"
      },
      {
        "display": "愛知県",
        "value": "Aichi:愛知県"
      },
      {
        "display": "三重県",
        "value": "Mie:三重県"
      },
      {
        "display": "滋賀県",
        "value": "Shiga:滋賀県"
      },
      {
        "display": "京都府",
        "value": "Kyoto:京都府"
      },
      {
        "display": "大阪府",
        "value": "Osaka:大阪府"
      },
      {
        "display": "兵庫県",
        "value": "Hyogo:兵庫県"
      },
      {
        "display": "奈良県",
        "value": "Nara:奈良県"
      },
      {
        "display": "和歌山県",
        "value": "Wakayama:和歌山県"
      },
      {
        "display": "鳥取県",
        "value": "Tottori:鳥取県"
      },
      {
        "display": "島根県",
        "value": "Shimane:島根県"
      },
      {
        "display": "岡山県",
        "value": "Okayama:岡山県"
      },
      {
        "display": "広島県",
        "value": "Hiroshima:広島県"
      },
      {
        "display": "山口県",
        "value": "Yamaguchi:山口県"
      },
      {
        "display": "徳島県",
        "value": "Tokushima:徳島県"
      },
      {
        "display": "香川県",
        "value": "Kagawa:香川県"
      },
      {
        "display": "愛媛県",
        "value": "Ehime:愛媛県"
      },
      {
        "display": "高知県",
        "value": "Kochi:高知県"
      },
      {
        "display": "福岡県",
        "value": "Fukuoka:福岡県"
      },
      {
        "display": "佐賀県",
        "value": "Saga:佐賀県"
      },
      {
        "display": "長崎県",
        "value": "Nagasaki:長崎県"
      },
      {
        "display": "熊本県",
        "value": "Kumamoto:熊本県"
      },
      {
        "display": "大分県",
        "value": "Oita:大分県"
      },
      {
        "display": "宮崎県",
        "value": "Miyazaki:宮崎県"
      },
      {
        "display": "鹿児島県",
        "value": "Kagoshima:鹿児島県"
      },
      {
        "display": "沖縄県",
        "value": "Okinawa:沖縄県"
      }
    ]
  to_do_fu_kensEN: MyList[] =
    [
      {
        "display": "Hokkaido",
        "value": "Hokkaido:北海道"
      },
      {
        "display": "Aomori",
        "value": "Aomori:青森県"
      },
      {
        "display": "Iwate",
        "value": "Iwate:岩手県"
      },
      {
        "display": "Miyagi",
        "value": "Miyagi:宮城県"
      },
      {
        "display": "Akita",
        "value": "Akita:秋田県"
      },
      {
        "display": "Yamagata",
        "value": "Yamagata:山形県"
      },
      {
        "display": "Fukushima",
        "value": "Fukushima:福島県"
      },
      {
        "display": "Ibaraki",
        "value": "Ibaraki:茨城県"
      },
      {
        "display": "Tochigi",
        "value": "Tochigi:栃木県"
      },
      {
        "display": "Gunma",
        "value": "Gunma:群馬県"
      },
      {
        "display": "Saitama",
        "value": "Saitama:埼玉県"
      },
      {
        "display": "Chiba",
        "value": "Chiba:千葉県"
      },
      {
        "display": "Tokyo",
        "value": "Tokyo:東京都"
      },
      {
        "display": "Kanagawa",
        "value": "Kanagawa:神奈川県"
      },
      {
        "display": "Niigata",
        "value": "Niigata:新潟県"
      },
      {
        "display": "Toyama",
        "value": "Toyama:富山県"
      },
      {
        "display": "Ishikawa",
        "value": "Ishikawa:石川県"
      },
      {
        "display": "Fukui",
        "value": "Fukui:福井県"
      },
      {
        "display": "Yamanashi",
        "value": "Yamanashi:山梨県"
      },
      {
        "display": "Nagano",
        "value": "Nagano:長野県"
      },
      {
        "display": "Gifu",
        "value": "Gifu:岐阜県"
      },
      {
        "display": "Shizuoka",
        "value": "Shizuoka:静岡県"
      },
      {
        "display": "Aichi",
        "value": "Aichi:愛知県"
      },
      {
        "display": "Mie",
        "value": "Mie:三重県"
      },
      {
        "display": "Shiga",
        "value": "Shiga:滋賀県"
      },
      {
        "display": "Kyoto",
        "value": "Kyoto:京都府"
      },
      {
        "display": "Osaka",
        "value": "Osaka:大阪府"
      },
      {
        "display": "Hyogo",
        "value": "Hyogo:兵庫県"
      },
      {
        "display": "Nara",
        "value": "Nara:奈良県"
      },
      {
        "display": "Wakayama",
        "value": "Wakayama:和歌山県"
      },
      {
        "display": "Tottori",
        "value": "Tottori:鳥取県"
      },
      {
        "display": "Shimane",
        "value": "Shimane:島根県"
      },
      {
        "display": "Okayama",
        "value": "Okayama:岡山県"
      },
      {
        "display": "Hiroshima",
        "value": "Hiroshima:広島県"
      },
      {
        "display": "Yamaguchi",
        "value": "Yamaguchi:山口県"
      },
      {
        "display": "Tokushima",
        "value": "Tokushima:徳島県"
      },
      {
        "display": "Kagawa",
        "value": "Kagawa:香川県"
      },
      {
        "display": "Ehime",
        "value": "Ehime:愛媛県"
      },
      {
        "display": "Kochi",
        "value": "Kochi:高知県"
      },
      {
        "display": "Fukuoka",
        "value": "Fukuoka:福岡県"
      },
      {
        "display": "Saga",
        "value": "Saga:佐賀県"
      },
      {
        "display": "Nagasaki",
        "value": "Nagasaki:長崎県"
      },
      {
        "display": "Kumamoto",
        "value": "Kumamoto:熊本県"
      },
      {
        "display": "Oita",
        "value": "Oita:大分県"
      },
      {
        "display": "Miyazaki",
        "value": "Miyazaki:宮崎県"
      },
      {
        "display": "Kagoshima",
        "value": "Kagoshima:鹿児島県"
      },
      {
        "display": "Okinawa",
        "value": "Okinawa:沖縄県"
      }
    ]

 templateNames:String[]=[];

  firstFormGroup: FormGroup;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  myMessage: string;
  loaded: boolean = true;
  mySelectedLanguage: string;
  cntUser: number;
  showNumberOfUser: boolean;
  myUserName: string;


  dataSource: MatTableDataSource<MemberList>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] =[];
  tempDisplayedColumns: string[] =[];
  defaultDisplayColumn: string[] = [ 'LastName', 'FirstName', 'Email', 'Gender', 'Age',
  'NewsLetterLanguage', 'Country', 'State', 'City',  'MembershipType','SupportingMembershipRecurring','SupportingMembershipCreatedDate',
 'CreatedDate', 'LastModifiedDate', 'myEditProfile'];

  user: User = {
    PasswordHash: null,
    Id: null,
    UserName: null,
    Email: null,
    FirstName: null,
    LastName: null,
    Password: null,
    Prefix: null,
    Shimei: null,
    Myoji: null,
    Street: null,
    Street2: null,
    City: null,
    State: null,
    Zip: null,
    Country: null,
    Yubin_Bango: null,
    To_Do_Fu_Ken: null,
    Shi_Gun_Ku: null,
    Cho_Son: null,
    Apartment_Etc: null,
    To_Do_Fu_Ken_JP: null,
    Shi_Gun_Ku_JP: null,
    Cho_Son_JP: null,
    Apartment_Etc_JP: null,
    Gender: null,
    DOB: null,
    TelNo: null,
    TimezoneName: null,
    UTCdiff: null,
    Token: null,
    Occupation: null,
    MemberStatus: null,
    Married: null,
    Christian: null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    ZoomAccount: null,
    RegisteredDate: null,
    LastAccessedDate: null,
    //From siteground
    LoginId: null,
    MiddleNameEN: null,
    MiddleNameJP: null,
    PrefixJP: null,
    OccupationDetail: null,
    Organization: null,
    LivingCountry: null,
    PhoneEN: null,
    PhoneMobileEN: null,
    PhoneJP: null,
    PhoneMobileJP: null,
    ChurchNameEN: null,
    ChurchNameJP: null,
    MemberType: null,
    MemberDetail: null,
    MemberMemo: null,
    Shokaisha: null,
    Reason: null,
    NewsLetters: null,
    StaffReports: null,
    OtherLetters: null,
    NewsLetterLanguage: null,
    BasicRoles: null,
    //Added new
    MigrateFromSiteGround: null,
    AdminAccess: null,
    JCFNAccess: null,
    SupportingMember: null,
    LastAddressUpdatedDate: null,
    AcceptMembershipTerm: null,
    MembershipType: null
  };
  users: User[];
  memberList: MemberList = {
    Id: null,
    UserName: null,
    Email: null,
    FirstName: null,
    LastName: null,
    Password: null,
    Prefix: null,
    Shimei: null,
    Myoji: null,
    Street: null,
    Street2: null,
    City: null,
    State: null,
    Zip: null,
    Country: null,
    Yubin_Bango: null,
    To_Do_Fu_Ken: null,
    Shi_Gun_Ku: null,
    Cho_Son: null,
    Apartment_Etc: null,
    To_Do_Fu_Ken_JP: null,
    Shi_Gun_Ku_JP: null,
    Cho_Son_JP: null,
    Apartment_Etc_JP: null,
    Gender: null,
    DOB: null,
    TelNo: null,
    TimezoneName: null,
    UTCdiff: null,
    Token: null,
    Occupation: null,
    MemberStatus: null,
    Married: null,
    Christian: null,
    ZoomAccount: null,
    LivingCountry: null,
    NewsLetterLanguage: null,
    LastAddressUpdatedDate: null,
    AcceptMembershipTerm: null,
    MemberType: null,
    MembershipType: null,
    SupportingMembershipRecurring:null,
    SupportingMembershipCreatedDate:null,
    MyMembershipTypes: this.checkGroups,
    MinAge: null,
    MaxAge: null,
    RegisteredDate: null,
    LastAccessedDate: null,
    AdminAccess: null,
    JCFNAccess: null,
    CreatedDate: null,
    LastModifiedDate: null,

    ConferenceName: null,
    Language: null,
    Attend: null,
    NewsLetters:null,
    MyNewsLetters:this.checkGroups,
    ReceivingMethod:null,
    ActiveStatus:null
  };
  memberLists: MemberList[] = [];

  //bools series
  memberListTemplate:MemberListTemplate={
    MemberListGridTemplateId:0,
    TemplateName:null,
    UserName:null,
    Registereddate:null,
    LastName:null,
    FirstName:null,
    Myoji:null,
    Shimei:null,
    Prefix:null,
    Email:null,
    Gender:null,
    DOB:null,
    TelNo:null,
    LivingCountry:null,
    Street:null,
    Street2:null,
    City:null,
    State:null,
    Zip:null,
    Country:null,
    Yubin_Bango:null,
    To_Do_Fu_Ken:null,
    Shi_Gun_Ku:null,
    Cho_Son:null,
    Apartment_Etc:null,
    To_Do_Fu_Ken_JP:null,
    Shi_Gun_Ku_JP:null,
    Cho_Son_JP:null,
    Apartment_Etc_JP:null,
    Occupation:null,
    Married:null,
    ZoomAccount:null,
    Christian:null,
    NewsLetterLanguage:null,
    MemberType:null,
    MembershipType:null,
    CreatedDate:null,
    //EC20 Reunion
    Language:null,
    Attend:null,

    NewsLetters:null,
    ReceivingMethod:null,
    ActiveStatus:null
  }

  constructor(
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    public authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog
  ) { }

  mySearch: any = {};

  ngOnInit(): void {
    this.mySelectedLanguage = this.router.url.includes('EN') == true ? "EN" : "JP";
    this.myUserName = sessionStorage.getItem("loginUserName");
    this.firstFormGroup = this._formBuilder.group({
    });
    this.mySearch = JSON.parse(sessionStorage.getItem("mySearchingUser"));
    if (this.mySearch != null) {
      this.memberList.FirstName = this.mySearch.myProfileSearch.FirstName;
      this.memberList.LastName = this.mySearch.myProfileSearch.LastName;
      this.memberList.Email = this.mySearch.myProfileSearch.Email;
      this.memberList.Gender = this.mySearch.myProfileSearch.Gender;
      this.memberList.MinAge = this.mySearch.myProfileSearch.MinAge;
      this.memberList.MaxAge = this.mySearch.myProfileSearch.MaxAge;
      this.memberList.NewsLetterLanguage = this.mySearch.myProfileSearch.NewsLetterLanguage;
      this.memberList.Country = this.mySearch.myProfileSearch.Country;
      this.memberList.To_Do_Fu_Ken = this.mySearch.myProfileSearch.To_Do_Fu_Ken;
      this.memberList.Shi_Gun_Ku = this.mySearch.myProfileSearch.Shi_Gun_Ku;
      this.memberList.State = this.mySearch.myProfileSearch.State;
      this.memberList.City = this.mySearch.myProfileSearch.City;
      this.memberList.MembershipType = this.mySearch.myProfileSearch.MembershipType;
      this.getMemberListsBySearchKey(this.memberList);
    } else {
    }
    this.setIndexDate();
    this.getTemplateNamesByUser(this.myUserName);
    this.updateDisplayColumns( this.defaultDisplayColumn);
  }

  updateDisplayColumns(columns: string[]){
    this.displayedColumns =columns;
  }

  getTemplateNamesByUser(userName:string) {
    if (userName!=null) {
      this.loaded = false;
      this.user.UserName = userName;
      this.userService.getTemplateNamesByUser(this.user)
        .subscribe(results => {
           this.templateNames.push.apply(this.templateNames,results);
           this.loaded = true;
        },
          error => {
          }
        );
    }
    this.templateNames.unshift("Default");
  }

  onChangeSelectTemplateName(selectedItem: any) {
    this.loaded = false;
    if (selectedItem != null) {
      if(selectedItem=="Default"){
        this.displayedColumns = this.defaultDisplayColumn;
        this.loaded=true;
      }else{
        this.userService.getMemberListTemplateByTemplateName(selectedItem)
        .subscribe(results => {
          this.memberListTemplate = results;
          this.updateDisplayColumn();
          this.loaded = true;
        },
          error => {
          }
        );
      }

    }
  }

  updateDisplayColumn(){
    this.displayedColumns=[];
    this.tempDisplayedColumns=[];
    if(this.memberListTemplate.LastName==true){
      this.tempDisplayedColumns.push("LastName");
    }
    if(this.memberListTemplate.FirstName==true){
      this.tempDisplayedColumns.push("FirstName");
    }
    if(this.memberListTemplate.Myoji==true){
      this.tempDisplayedColumns.push("Myoji");
    }
    if(this.memberListTemplate.Shimei==true){
      this.tempDisplayedColumns.push("Shimei");
    }
    if(this.memberListTemplate.Prefix==true){
      this.tempDisplayedColumns.push("Prefix");
    }
    if(this.memberListTemplate.Email==true){
      this.tempDisplayedColumns.push("Email");
    }
    if(this.memberListTemplate.Gender==true){
      this.tempDisplayedColumns.push("Gender");
    }
    if(this.memberListTemplate.DOB==true){
      this.tempDisplayedColumns.push("Age");
    }
    if(this.memberListTemplate.TelNo==true){
      this.tempDisplayedColumns.push("TelNo");
    }
    if(this.memberListTemplate.LivingCountry==true){
      this.tempDisplayedColumns.push("LivingCountry");
    }
    if(this.memberListTemplate.Street==true){
      this.tempDisplayedColumns.push("Street");
    }
    if(this.memberListTemplate.Street2==true){
      this.tempDisplayedColumns.push("Street2");
    }
    if(this.memberListTemplate.City==true){
      this.tempDisplayedColumns.push("City");
    }
    if(this.memberListTemplate.State==true){
      this.tempDisplayedColumns.push("State");
    }
    if(this.memberListTemplate.Zip==true){
      this.tempDisplayedColumns.push("Zip");
    }
    if(this.memberListTemplate.Country==true){
      this.tempDisplayedColumns.push("Country");
    }
    if(this.memberListTemplate.Yubin_Bango==true){
      this.tempDisplayedColumns.push("Yubin_Bango");
    }
    if(this.memberListTemplate.To_Do_Fu_Ken==true){
      this.tempDisplayedColumns.push("To_Do_Fu_Ken");
    }
    if(this.memberListTemplate.Shi_Gun_Ku==true){
      this.tempDisplayedColumns.push("Shi_Gun_Ku");
    }
    if(this.memberListTemplate.Cho_Son==true){
      this.tempDisplayedColumns.push("Cho_Son");
    }
    if(this.memberListTemplate.Apartment_Etc==true){
      this.tempDisplayedColumns.push("Apartment_Etc");
    }
    if(this.memberListTemplate.To_Do_Fu_Ken_JP==true){
      this.tempDisplayedColumns.push("To_Do_Fu_Ken_JP");
    }
    if(this.memberListTemplate.Shi_Gun_Ku_JP==true){
      this.tempDisplayedColumns.push("Shi_Gun_Ku_JP");
    }
    if(this.memberListTemplate.Cho_Son_JP==true){
      this.tempDisplayedColumns.push("Cho_Son_JP");
    }
    if(this.memberListTemplate.Apartment_Etc_JP==true){
      this.tempDisplayedColumns.push("Apartment_Etc_JP");
    }
    if(this.memberListTemplate.Occupation==true){
      this.tempDisplayedColumns.push("Occupation");
    }
    if(this.memberListTemplate.Married==true){
      this.tempDisplayedColumns.push("Married");
    }
    if(this.memberListTemplate.ZoomAccount==true){
      this.tempDisplayedColumns.push("ZoomAccount");
    }
    if(this.memberListTemplate.Christian==true){
      this.tempDisplayedColumns.push("Christian");
    }
    if(this.memberListTemplate.NewsLetterLanguage==true){
      this.tempDisplayedColumns.push("NewsLetterLanguage");
    }
    if(this.memberListTemplate.MemberType==true){
      this.tempDisplayedColumns.push("MemberType");
    }
    if(this.memberListTemplate.MembershipType==true){
      this.tempDisplayedColumns.push("MembershipType");
    }
    if(this.memberListTemplate.SupportingMembershipRecurring==true){
      this.tempDisplayedColumns.push("SupportingMembershipRecurring");
    }
    if(this.memberListTemplate.SupportingMembershipCreatedDate==true){
      this.tempDisplayedColumns.push("SupportingMembershipCreatedDate");
    }
    if(this.memberListTemplate.CreatedDate==true){
      this.tempDisplayedColumns.push("CreatedDate");
    }
    // if(this.memberListTemplate.Language==true){
    //   this.tempDisplayedColumns.push("EC20BeyondReunionLanguage");
    // }
    // if(this.memberListTemplate.Attend==true){
    //   this.tempDisplayedColumns.push("EC20BeyondReunionAttend");
    // }
    if(this.memberListTemplate.NewsLetters==true){
      this.tempDisplayedColumns.push("NewsLetters");
    }
    if(this.memberListTemplate.ReceivingMethod==true){
      this.tempDisplayedColumns.push("ReceivingMethod");
    }
    if(this.memberListTemplate.ActiveStatus==true){
      this.tempDisplayedColumns.push("ActiveStatus");
    }

    this.tempDisplayedColumns.forEach(element => {
      console.log(element);
    });
    this.displayedColumns = this.tempDisplayedColumns;
    //this.ngOnInit();
  }

  getMyLanguage(): string {
    return this.router.url.includes('EN') == true ? 'EN' : 'JP';
  }

  onSearchSubmit({ value, valid }: { value: MemberList; valid: boolean }) {
    this.memberLists = null;
    this.cntUser = 0;
    this.memberList.FirstName =
      value.FirstName == null
        ? null
        : value.FirstName.trim() == ""
          ? null
          : value.FirstName.trim();
    this.memberList.LastName =
      value.LastName == null
        ? null
        : value.LastName.trim() == ""
          ? null
          : value.LastName.trim();
    this.memberList.Email =
      value.Email == null
        ? null
        : value.Email.trim() == ""
          ? null
          : value.Email.trim();
    this.memberList.Shi_Gun_Ku =
      value.Shi_Gun_Ku == null
        ? null
        : value.Shi_Gun_Ku.trim() == ""
          ? null
          : value.Shi_Gun_Ku.trim();
    this.memberList.City =
      value.City == null
        ? null
        : value.City.trim() == ""
          ? null
          : value.City.trim();

    if (!valid) {
      this.myMessage = this.getMyLanguage() == "EN" ? "Please fill out the form correctly." : "正しく入力がなされていません。";
      this.openSnackBar('error', 'close');
      this.loaded = true;
    }
    else if (
      this.memberList.FirstName == null &&
      this.memberList.LastName == null &&
      this.memberList.Email == null &&
      this.memberList.Gender == null &&
      this.memberList.MinAge == null &&
      this.memberList.MaxAge == null &&
      this.memberList.NewsLetterLanguage == null &&
      this.memberList.Country == null &&
      this.memberList.To_Do_Fu_Ken == null &&
      this.memberList.Shi_Gun_Ku == null &&
      this.memberList.State == null &&
      this.memberList.City == null &&
      this.memberList.MemberType == null &&
      this.memberList.MyMembershipTypes == null &&
      this.memberList.SupportingMembershipRecurring == null &&
      this.memberList.SupportingMembershipCreatedDate == null &&
      this.memberList.Attend == null &&
      this.memberList.Language == null &&
      this.memberList.MyNewsLetters==null &&
      this.memberList.ReceivingMethod == null &&
      this.memberList.ActiveStatus==null
    ) {
      this.loaded = true;
      this.myMessage = this.getMyLanguage() == "EN" ? "検索キーがブランクです" : "All Search key blank";
      this.openSnackBar('error', 'close');
      this.loaded = true;
    }
    else {
      sessionStorage.removeItem("mySearchingUser");
      sessionStorage.setItem(
        "mySearchingUser",
        JSON.stringify({ myProfileSearch: this.memberList })
      );
      this.getMemberListsBySearchKey(this.memberList);
    }
  }

  showAllUsers() {
    this.getMemberListsBySearchKey(this.memberList);
  }

  getMemberListsBySearchKey(memberList: MemberList) {
    this.loaded = false;
    this.showNumberOfUser = false;
    this.userService.getMemberListsBySearchKey(memberList)
      .subscribe(results => {
        this.memberLists = results;
        // Assign the data to the data source for the table to render
        this.dataSource = new MatTableDataSource(this.memberLists);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loaded = true;

        var tempEmail: any;
        var cnt = 0;
        this.memberLists.forEach(element => {
          if (tempEmail != element) {
            tempEmail = element.Email;
            cnt++;
          }
          this.cntUser = cnt;
        });

        var today = new Date();
        var myDay = today.getDate();
        var myMonth = today.getMonth();
        var myYear = today.getFullYear() - 1;
        var indexDate = new Date(myYear, myMonth, myDay);
        this.memberLists.forEach(obj=>
          obj.MembershipType=='Supporting'?
          (obj.SupportingMembershipRecurring==true?
            obj.MembershipType='Supporting':
          (new Date(obj.SupportingMembershipCreatedDate).getTime()>=indexDate.getTime()?
          obj.MembershipType='Supporting':
          obj.MembershipType='Regular')
          )
          :
          obj.MembershipType='')


        this.loaded = true;
        this.showNumberOfUser = true;
      },
        error => {
          // this.myMessage = "Data search failed."
          // this.openSnackBar('error', 'close');
          // this.loaded = true;
          // this.showNumberOfUser = true
        }
      );
  }

  setIndexDate(){
      var today = new Date();
      var myDay = today.getDate();
      var myMonth = today.getMonth();
      var myYear = today.getFullYear() - 1;
      this.indexDate = new Date(myYear, myMonth, myDay);
   //   new Date(this.paymentMaster.CreatedDate).getTime() <= newDate.getTime()



  }

  onCancel($event: any) {
    event.preventDefault(); // This is neccessary to prevent cencel button submit form
    this.memberList.FirstName = null;
    this.memberList.LastName = null;
    this.memberList.Email = null;
    this.memberList.Gender = null;
    this.memberList.MinAge = null;
    this.memberList.MaxAge = null;
    this.memberList.NewsLetterLanguage = null;
    this.memberList.Country = null,
    this.memberList.To_Do_Fu_Ken = null,
    this.memberList.Shi_Gun_Ku = null,
    this.memberList.State = null,
    this.memberList.City = null
    this.memberList.MemberType = null;
    this.memberList.MyMembershipTypes = null;
    this.memberList.Attend = null;
    this.memberList.Language = null;
    this.memberList.MyNewsLetters=null;
    this.memberList.ReceivingMethod=null;
    this.memberList.ActiveStatus=null;
    sessionStorage.removeItem("mySearchingUser");
  }

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 5000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 10000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }

  onClickGoToMyAccount(row: User) {
    sessionStorage.removeItem("searchingMemberId");
    sessionStorage.setItem("searchingMemberId", row.Id);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight = '800px'; //Desktop
    dialogConfig.maxWidth = '1000px';//Desktoop
    dialogConfig.height = '100%';//Mobile
    dialogConfig.width = '100%';//Mobile
    dialogConfig.panelClass = 'custom-modalbox'; //Refer global level
    dialogConfig.data = { Id: row.Id };
    const dialogRef = this.dialog.open(MyAccountImportDialogComponent, dialogConfig);
    //  {
    //   width: '1000px',
    //   height: '800px',
    //   data: {
    //     Id: row.Id
    //   }
    // });
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
        console.log("returned AS400Code: " + confirmedAction.AS400Code);
      }
      //     this.getUsersBySearchKey(this.user);
    })
  }

  onClickGoToUpsertTemplate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight = '800px'; //Desktop
    dialogConfig.maxWidth = '1000px';//Desktoop
    dialogConfig.height = '100%';//Mobile
    dialogConfig.width = '100%';//Mobile
    dialogConfig.panelClass = 'custom-modalbox'; //Refer global level
    this.myUserName = sessionStorage.getItem("loginUserName");
    dialogConfig.data = { UserName: this.myUserName };
    const dialogRef = this.dialog.open(UpsertTemplateComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
        console.log("returned AS400Code: " + confirmedAction.AS400Code);
      }
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onSelectGender(model: string) {
  }

  onSelectNewsLetterLanguage(model: string) {
  }

  onSelectCountry(model: string) {
  }

  onSelectState(model: string) {
  }

  onSelectToDoFuKen(model: string) {
  }

  onSelectMemberType(model: string) {
  }

  calculate_age(dob: Date) {
    var diff_ms = Date.now() - new Date(dob).getTime();
    var age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }

  convertMemberType(type: String) {
    var mylanguage = this.getMyLanguage();
    var memberType = '';
    switch (type) {
      case "J":
        memberType = mylanguage == "EN" ? "Associate Member" : "準会員";
        break;
      case "H":
        memberType = mylanguage == "EN" ? "Non-Member" : "非会員";
        break;
      case "E":
        memberType = mylanguage == "EN" ? "Former Supporting Member" : "元維持会員";
        break;
      case "S":
        memberType = mylanguage == "EN" ? "Regular Member" : "一般会員";
        break;
      case "P":
        memberType = mylanguage == "EN" ? "Supporting Member" : "維持会員";
        break;
      case "U":
        memberType = mylanguage == "EN" ? "Supporting Member (Donation Outstanding)" : "維持会員(献金確認待ち)"
        break;
      default:
        memberType = "";
        break;
    }
    return memberType;
  }

  exportToExcel() {
    event.preventDefault(); // This is neccessary to prevent cencel button submit form
    // var headers = {
    //   Myoji: "姓",
    //   Shimei: "名",
    //   LastName: "Last Name",
    //   FirstName: "First Name",
    //   Email: "Email",
    //   Gender: "Gender",
    //   Age: "Age",
    //   NewsLetterLanguage: "News Letter Language",
    //   CurrentLivingCountry: "Current Living Country",
    //   Yubin_Bango: "郵便番号",
    //   To_Do_Fu_Ken: "都道府県",
    //   Shi_Gun_Ku: "市郡区",
    //   Cho_Son: "町村",
    //   Apartment_Etc: "アパートメント等",
    //   Street: "Street",
    //   City: "City",
    //   State: "State",
    //   Zip: "Zip",
    //   MemberType: "Member Type (Old database)",
    //   MembershipType: "Member Type (New database)",
    //   ec20ReunionAttend: "EC20 Reunion Participate",
    //   ec20ReunionLanguage: "EC20 Reunion Language",
    //   CreatedDate: "Created Date",
    //   LastModifiedDate: "Last Modified Date",
    // };

    var dynamicHeaders = {
    };
    if (this.displayedColumns.includes("LastName")) {
      Object.assign(dynamicHeaders, { LastName: "Last Name" });
    }
    if (this.displayedColumns.includes("FirstName")) {
      Object.assign(dynamicHeaders, { FirstName: "First Name" });
    }
    if (this.displayedColumns.includes("Myoji")) {
      Object.assign(dynamicHeaders, { Myoji: "苗字" });
    }
    if (this.displayedColumns.includes("Shimei")) {
      Object.assign(dynamicHeaders, { Shimei: "氏名" });
    }
    if (this.displayedColumns.includes("Prefix")) {
      Object.assign(dynamicHeaders, { Prefix: "Prefix" });
    }
    if (this.displayedColumns.includes("Email")) {
      Object.assign(dynamicHeaders, { Email: "Email" });
    }
    if (this.displayedColumns.includes("Gender")) {
      Object.assign(dynamicHeaders, { Gender: "Gender" });
    }
    if (this.displayedColumns.includes("Age")) {
      Object.assign(dynamicHeaders, { Age: "Age" });
    }
    if (this.displayedColumns.includes("TelNo")) {
      Object.assign(dynamicHeaders, { TelNo: "Tel No" });
    }
    if (this.displayedColumns.includes("LivingCountry")) {
      Object.assign(dynamicHeaders, { LivingCountry: "Living Country" });
    }
    if (this.displayedColumns.includes("Street")) {
      Object.assign(dynamicHeaders, { Street: "Street" });
    }
    if (this.displayedColumns.includes("Street2")) {
      Object.assign(dynamicHeaders, { Street2: "Street2" });
    }
    if (this.displayedColumns.includes("City")) {
      Object.assign(dynamicHeaders, { City: "City" });
    }
    if (this.displayedColumns.includes("State")) {
      Object.assign(dynamicHeaders, { State: "State" });
    }
    if (this.displayedColumns.includes("Zip")) {
      Object.assign(dynamicHeaders, { Zip: "Zip" });
    }
    if (this.displayedColumns.includes("Country")) {
      Object.assign(dynamicHeaders, { Country: "Country" });
    }
    if (this.displayedColumns.includes("Yubin_Bango")) {
      Object.assign(dynamicHeaders, { Yubin_Bango: "郵便番号" });
    }
    if (this.displayedColumns.includes("To_Do_Fu_Ken")) {
      Object.assign(dynamicHeaders, { To_Do_Fu_Ken: "都道府県" });
    }
    if (this.displayedColumns.includes("Shi_Gun_Ku")) {
      Object.assign(dynamicHeaders, { Shi_Gun_Ku: "市郡区" });
    }
    if (this.displayedColumns.includes("Cho_Son")) {
      Object.assign(dynamicHeaders, { Cho_Son: "町村" });
    }
    if (this.displayedColumns.includes("Apartment_Etc")) {
      Object.assign(dynamicHeaders, { Apartment_Etc: "アパートメント等" });
    }
    if (this.displayedColumns.includes("To_Do_Fu_Ken_JP")) {
      Object.assign(dynamicHeaders, { To_Do_Fu_Ken_JP: "都道府県" });
    }
    if (this.displayedColumns.includes("Shi_Gun_Ku_JP")) {
      Object.assign(dynamicHeaders, { Shi_Gun_Ku_JP: "市郡区" });
    }
    if (this.displayedColumns.includes("Cho_Son_JP")) {
      Object.assign(dynamicHeaders, { Cho_Son_JP: "町村" });
    }
    if (this.displayedColumns.includes("Apartment_Etc_JP")) {
      Object.assign(dynamicHeaders, { Apartment_Etc_JP: "アパートメント等" });
    }
    if (this.displayedColumns.includes("Occupation")) {
      Object.assign(dynamicHeaders, { Occupation: "職業" });
    }
    if (this.displayedColumns.includes("Married")) {
      Object.assign(dynamicHeaders, { Married: "Married" });
    }
    if (this.displayedColumns.includes("ZoomAccount")) {
      Object.assign(dynamicHeaders, { ZoomAccount: "Zoom Account" });
    }
    if (this.displayedColumns.includes("Christian")) {
      Object.assign(dynamicHeaders, { Christian: "Christian" });
    }
    if (this.displayedColumns.includes("NewsLetterLanguage")) {
      Object.assign(dynamicHeaders, { NewsLetterLanguage: "News Letter Language" });
    }
    if (this.displayedColumns.includes("MemberType")) {
      Object.assign(dynamicHeaders, { MemberType: "Member Type" });
    }
    if (this.displayedColumns.includes("MembershipType")) {
      Object.assign(dynamicHeaders, { MembershipType: "Membership Type" });
    }
    if (this.displayedColumns.includes("CreatedDate")) {
      Object.assign(dynamicHeaders, { CreatedDate: "Created Date" });
    }
    if (this.displayedColumns.includes("LastModifiedDate")) {
      Object.assign(dynamicHeaders, { LastModifiedDate: "Last Modified Date" });
    }
    // if (this.displayedColumns.includes("EC20BeyondReunionAttend")) {
    //   Object.assign(dynamicHeaders, { Attend: "EC20 Beyond Reunion Attend" });
    // }
    // if (this.displayedColumns.includes("EC20 Beyond Reunion Language")) {
    //   Object.assign(dynamicHeaders, { Language: "Language" });
    // }
    if (this.displayedColumns.includes("NewsLetters")) {
      Object.assign(dynamicHeaders, { NewsLetters: "News Letters" });
    }
    if (this.displayedColumns.includes("ReceivingMethod")) {
      Object.assign(dynamicHeaders, { ReceivingMethod: "Receiving Method" });
    }
    if (this.displayedColumns.includes("ActiveStatus")) {
      Object.assign(dynamicHeaders, { ActiveStatus: "Active Status" });
    }

    var itemsFormatted: any = [];


    // var dynamicItems:any = [];
    var itemsFormatted2: any = [];
    var i=1;
    this.memberLists.forEach(item => {
      var dt = new Date(item.CreatedDate);
      var createdMonth = dt.getMonth() + 1;
      var createdYear = dt.getFullYear();
      var createdDay = dt.getDate();
      var dt2 = new Date(item.LastModifiedDate);
      var modifiedMonth = dt2.getMonth() + 1;
      var modifiedYear = dt2.getFullYear();
      var modifiedDay = dt2.getDate();
      var dynamicItems = {
      }
      if (this.displayedColumns.includes("LastName")) {
       // dynamicItems2.push({LastName: item.LastName == null ? "" : item.LastName.replace(/,/g, " ")});
        Object.assign(dynamicItems, { LastName: item.LastName == null ? "" : item.LastName.replace(/,/g, " ") });
      }
      if (this.displayedColumns.includes("FirstName")) {
        Object.assign(dynamicItems, { FirstName: item.FirstName == null ? "" : item.FirstName.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("Myoji")) {
        Object.assign(dynamicItems, { Myoji: item.Myoji == null ? "" : item.Myoji.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("Shimei")) {
        Object.assign(dynamicItems, { Shimei: item.Shimei == null ? "" : item.Shimei.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("Prefix")) {
        Object.assign(dynamicItems, { Prefix: item.Prefix == null ? "" : item.Prefix.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("Email")) {
        Object.assign(dynamicItems, { Email: item.Email == null ? "" : item.Email.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("Gender")) {
        Object.assign(dynamicItems, { Gender: item.Gender == null ? "" : item.Gender.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("Age")) {
        Object.assign(dynamicItems, { DOB: item.DOB == null ? "" : this.calculate_age(item.DOB), });
      }
      if (this.displayedColumns.includes("TelNo")) {
        Object.assign(dynamicItems, { Email: item.TelNo == null ? "" : item.TelNo.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("LivingCountry")) {
        Object.assign(dynamicItems, { Email: item.LivingCountry == null ? "" : item.LivingCountry.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("Street")) {
        Object.assign(dynamicItems, { Street: item.Street == null ? "" : item.Street.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("Street2")) {
        Object.assign(dynamicItems, { Street2: item.Street2 == null ? "" : item.Street2.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("City")) {
        Object.assign(dynamicItems, { City: item.City == null ? "" : item.City.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("State")) {
        Object.assign(dynamicItems, { State: item.State == null ? "" : item.State.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("Zip")) {
        Object.assign(dynamicItems, { Zip: item.Zip == null ? "" : item.Zip.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("Country")) {
        Object.assign(dynamicItems, { Country: item.Country == null ? "" : item.Country.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("Yubin_Bango")) {
        Object.assign(dynamicItems, { Yubin_Bango: item.Yubin_Bango == null ? "" : item.Yubin_Bango.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("To_Do_Fu_Ken")) {
        Object.assign(dynamicItems, { To_Do_Fu_Ken: item.To_Do_Fu_Ken == null ? "" : item.To_Do_Fu_Ken.split(':')[1] == null ? "" : item.To_Do_Fu_Ken.split(':')[1].replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("Shi_Gun_Ku")) {
        Object.assign(dynamicItems, { Shi_Gun_Ku: item.Shi_Gun_Ku == null ? "" : item.Shi_Gun_Ku.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("Cho_Son")) {
        Object.assign(dynamicItems, { Cho_Son: item.Cho_Son == null ? "" : item.Cho_Son.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("Apartment_Etc")) {
        Object.assign(dynamicItems, { Apartment_Etc: item.Apartment_Etc == null ? "" : item.Apartment_Etc.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("To_Do_Fu_Ken_JP")) {
        Object.assign(dynamicItems, { To_Do_Fu_Ken_JP: item.To_Do_Fu_Ken_JP == null ? "" : item.To_Do_Fu_Ken_JP.split(':')[1] == null ? "" : item.To_Do_Fu_Ken_JP.split(':')[1].replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("Shi_Gun_Ku_JP")) {
        Object.assign(dynamicItems, { Shi_Gun_Ku_JP: item.Shi_Gun_Ku_JP == null ? "" : item.Shi_Gun_Ku_JP.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("Cho_Son_JP")) {
        Object.assign(dynamicItems,{ 市郡区: item.Cho_Son_JP == null ? "" : item.Cho_Son_JP.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("Apartment_Etc_JP")) {
        Object.assign(dynamicItems,{ アパートメント等: item.Apartment_Etc_JP == null ? "" : item.Apartment_Etc_JP.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("Occupation")) {
        Object.assign(dynamicItems,{ Occupation: item.Occupation == null ? "" : item.Occupation.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("Married")) {
        Object.assign(dynamicItems,{ Married: item.Married == null ? "" : "", });
      }
      if (this.displayedColumns.includes("ZoomAccount")) {
        Object.assign(dynamicItems,{ ZoomAccount: item.ZoomAccount == null ? "" : item.ZoomAccount.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("Christian")) {
        Object.assign(dynamicItems,{ Christian: item.Christian == null ? "" : "", });
      }
      if (this.displayedColumns.includes("NewsLetterLanguage")) {
        Object.assign(dynamicItems,{ NewsLetterLanguage: item.NewsLetterLanguage == null ? "" : item.NewsLetterLanguage.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("MemberType")) {
        Object.assign({ MembershipType: item.MemberType == null ? "" : item.MemberType.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("MembershipType")) {
        Object.assign(dynamicItems,{ MembershipType: item.MembershipType == null ? "" : item.MembershipType.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("CreatedDate")) {
        Object.assign(dynamicItems,{ CreatedDate: item.CreatedDate == null ? "" : createdMonth + "/" + createdDay + "/" + createdYear, });
      }
      if (this.displayedColumns.includes("LastModifiedDate")) {
        Object.assign(dynamicItems,{ LastModifiedDate: item.LastModifiedDate == null ? "" : modifiedMonth + "/" + modifiedDay + "/" + modifiedYear, });
      }
      // if (this.displayedColumns.includes("EC20BeyondReunionAttend")) {
      //   Object.assign(dynamicItems,{ Attend: item.Attend == null ? "" : "", });
      // }
      // if (this.displayedColumns.includes("EC20 Beyond Reunion Language")) {
      //   Object.assign(dynamicItems,{ MembershipType: item.MemberType == null ? "" : item.MemberType.replace(/,/g, " "), });
      // }
      if (this.displayedColumns.includes("NewsLetters")) {
        Object.assign(dynamicItems,{ NewsLetters: item.NewsLetters == null ? "" : item.NewsLetters.replace(/,/g, " "), });
      }
      if (this.displayedColumns.includes("ReceivingMethod")) {
        Object.assign(dynamicItems,{ ReceivingMethod: item.ReceivingMethod == null ? "" : (item.ReceivingMethod=='E'?'Email':'Paper'), });
      }
      if (this.displayedColumns.includes("ActiveStatus")) {
        Object.assign(dynamicItems,{ ActiveStatus: item.ActiveStatus == null ? "" : item.ActiveStatus==true?'Yes':'No', });
      }


      itemsFormatted2.push(dynamicItems);
    });
    var fileTitle = "JCFN_members";
    this.exportCSVFile(dynamicHeaders, itemsFormatted2, fileTitle);
  }
  exportCSVFile(headers2: any, items: any, fileTitle: any) {
    if (headers2) {
      items.unshift(headers2);
    }
    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);
    var csv = this.convertToCSV(jsonObject);
    var exportedFilenmae = fileTitle + ".csv" || "export.csv";
    var blob = new Blob(
      [
        "\uFEFF", // 文字化け防止
        csv],
      { type: "text/csv;charset=utf-8;" });

      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

  }
  convertToCSV(objArray: any) {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";
    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line != "") line += ",";
        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  }

  onSelectMembershipType($event: any) {
    console.log("selected object:" + $event.value)
    // $event.value.forEach(element => {
    //   console.log(element.value);
    // });
  }

  onSelectNewsLetters($event: any) {
    console.log("selected object:" + $event.value)
    // $event.value.forEach(element => {
    //   console.log(element.value);
    // });
  }

  onSelectReceivingMethod(model: string) {
  }

  onSelectActiveStatus(model: string) {
  }

  // onSelectEC20BeyondReunionAttend($event: any) {
  //   console.log("selecte object:" + $event.value)
  // }

  // onSelectEC20BeyondReunionLanguage($event: any) {
  //   console.log("selecte object:" + $event.value)
  // }
}
