<div class="myBase">
 <div class="bg-layer">
   <div class="btnBack">
     <button type="button" class="btn btn-link" (click)="onClickClose()">
       <i class="fas fa-times-circle fa-2x"></i>&nbsp;&nbsp; {{this.router.url.includes('EN')==true?'Close': '閉じる'}}
     </button>
   </div>
   
      <div class="d-flex justify-content-center">
        <mat-card class="profileDetail">
          <h4>
            {{this.router.url.includes('EN')==true?'Under development. We will contact you when
            it is available.'
            :
            '現在開発中です。閲覧可能になり次第、ご連絡差し上げます'}}
          </h4>
        </mat-card>
      </div>

 </div>
</div>