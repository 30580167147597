<div class="WelcomeBase">
 <form [formGroup]="firstFormGroup">
  <mat-card class="WelcomeWindow">
   <label class="EmailCheckComment">
    {{this.router.url.includes('EN')==true?
    'Thanks for submitting your email. We`ve sent you an email with the information needed to reset your password. The
    email might take a couple of minutes to reach your account. Please check your junk mail to ensure you receive it.':
    'Emailありがとうございます。パスワード再設定のメールが送られました。メールの受信には数分かかる場合がございます。またスパムメール扱いになっていないか念のためご確認ください。'
    }}
   </label>
   <div class="divUpdateButton  d-flex justify-content-center">
    <button mat-button (click)="onNoClick()" class="btn btn-link">
     {{this.router.url.includes('EN')==true?'Close': '閉じる'}}
    </button>
   </div>
  </mat-card>
 </form>
</div>
<div class="memoForDevelopper">
 password-recovery-email-sent
 </div>