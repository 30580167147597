import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../../app.config";
import { Observable } from "rxjs";
import { AuthService } from '../auth/auth.service'; 
import { PaymentMaster } from 'src/app/models/payment/paymentMaster';
import { PaymentMasterCore} from '../../models/payment/paymentMasterCore';
import { User} from '../../models/user';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private http: HttpClient,
    private authService:AuthService,
    private config: AppConfig) { }

    makePayment(model:PaymentMaster):Observable<any>{
      return this.http.post<any>(
        this.config.apiMyUrl + "/api/payment/makePayment",
         model
      );
    }

    makeRecurringPayment(model:PaymentMaster):Observable<any>{
      return this.http.post<any>(
        this.config.apiMyUrl + "/api/payment/makeRecurringPayment",
         model
      );
    }

    getLatestMembershipPaymentInfoByUserName(userName: string): Observable<any> {
      return this.http.get(
        this.config.apiMyUrl + "/api/payment/getLatestMembershipPaymentInfoByUserName/" + userName
      );
    }

    getAllItemNames(): Observable<any> {
      return this.http.get(
        this.config.apiMyUrl + "/api/payment/getAllItemNames",
        { headers: this.authService.getJWT()  }
      );
    }

    InsertSupportingMembershipPaymentInfo(model:PaymentMasterCore): Observable<any> {
      return this.http.post<any>(
        this.config.apiMyUrl + "/api/payment/insertSupportingMembershipPaymentInfo",model,
        { headers: this.authService.getJWT()  }
      );
    }
}
