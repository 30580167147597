import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA,MatDialogConfig} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { UserService} from "../../../services/user/user.service";
import { Router, ActivatedRoute } from "@angular/router";
import { EnterSearchingEmailComponent} from '../../dialog/enter-searching-email/enter-searching-email.component';
import { PasswordRecoveryEnterEmailComponent} from '../../dialog/password-recovery-enter-email/password-recovery-enter-email.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import  { User} from "../../../models/user";
import { AuthService } from "../../../services/auth/auth.service";
import { RequestAddressUpdateComponent} from '../../dialog/request-address-update/request-address-update.component';

export interface DialogData {
  myEmail:string
}

@Component({
  selector: 'app-login-enter-password',
  templateUrl: './login-enter-password.component.html',
  styleUrls: ['./login-enter-password.component.css']
})
export class LoginEnterPasswordComponent implements OnInit {
  firstFormGroup: FormGroup;
  loaded:boolean=true;
  userSearched:boolean=true;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  myMessage:string;
  showMessageUserFound:boolean=false;

  user: User = {
    PasswordHash:null,
    Id: null,
    UserName: null,
    Email: null,
    FirstName: null,
    LastName: null,
    Password: null,
    Prefix: null,
    Shimei: null,
    Myoji: null,
    Street: null,
    Street2: null,
    City: null,
    State: null,
    Zip: null,
    Country: null,
    Yubin_Bango: null,
    To_Do_Fu_Ken: null,
    Shi_Gun_Ku: null,
    Cho_Son: null,
    Apartment_Etc: null,
    To_Do_Fu_Ken_JP: null,
    Shi_Gun_Ku_JP: null,
    Cho_Son_JP: null,
    Apartment_Etc_JP: null,
    Gender: null,
    DOB: null,
    TelNo: null,
    TimezoneName:null,
    UTCdiff:null,
    Token:null,
    Occupation:null,
    MemberStatus:null,
    Married:null,
    ZoomAccount:null,
    Christian:null,
    Baptized:null,
    ReturneeInfo:null,
    HasReturningChurch:null,
    RegisteredDate:null,
    LastAccessedDate:null,
    //From siteground
    LoginId:null,
    MiddleNameEN:null,
    MiddleNameJP:null,
    PrefixJP:null,
    OccupationDetail:null,
    Organization:null,
    LivingCountry:null,
    PhoneEN:null,
    PhoneMobileEN:null,
    PhoneJP:null,
    PhoneMobileJP:null,
    ChurchNameEN:null,
    ChurchNameJP:null,
    MemberType:null,
    MemberDetail:null,
    MemberMemo:null,
    Shokaisha:null,
    Reason:null,
    NewsLetters:null,
    StaffReports:null,
    OtherLetters:null,
    NewsLetterLanguage:null,
    BasicRoles:null,
    //Added new
    MigrateFromSiteGround:null,
    AdminAccess:null,
    JCFNAccess:null,
    SupportingMember:null,
    LastAddressUpdatedDate:null,
    AcceptMembershipTerm:null,
    MembershipType:null
  };

  loading = false;
  Email: string;
  Password: string;
  mySelectedLanguage: string;
  showPasswordGroup:boolean=false;

showSearchGroup:boolean=true;
  constructor(
    private authService: AuthService,
    private userService:UserService,
    public router: Router,
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private registrationService: UserService,
    public dialogRef: MatDialogRef<EnterSearchingEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog
  ){}

  ngOnInit() {

    this.firstFormGroup = this._formBuilder.group({
      myPassword: [null, [Validators.required]]
    });
    this.showMessageUserFound=true;
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClickLogin(){
    if (this.firstFormGroup.get('myPassword').status== 'VALID') {
      this.loaded = false;
      this.authService.login(this.data.myEmail, this.user.Password).subscribe(
        result => {
          //Login Sussessfully.
          this.userService.getUserProfile(this.data.myEmail).subscribe(
            result => {
              this.user = result;
              if(this.user.LastAddressUpdatedDate!=null){
                var updatedDate = new Date(this.user.LastAddressUpdatedDate);
                var todayDate = new Date();
                var duration = todayDate.valueOf()-updatedDate.valueOf();
                var diffDays = duration / (1000 * 3600 * 24);
                // console.log('updatedDate: '+ updatedDate);
                console.log('todayDAte: '+ todayDate);
                console.log('updatedDate.valueOf(): '+updatedDate.valueOf());
                console.log('todayDate.valueOf(): '+todayDate.valueOf());
                console.log('duration:' + duration);
                console.log('day diff:'+ duration / (1000 * 3600 * 24));
                if(diffDays>320){
                  //Open address update notice window
                  this.loaded = true;
                  this.requestAddressUpdate();
                  this.dialogRef.close();
                }else{
                  this.loaded = true;
                 //this.router.navigate(["/payment/supportingMembership/"+this.getMyLanguage()]);
                 //this.router.navigate(["/members/selectMemberType/"+this.getMyLanguage()]);
                 //this.router.navigate(["/members/acceptTerms/"+this.getMyLanguage()]);
                  this.router.navigate(["/members/myProfile/"+this.getMyLanguage()]);
                  this.dialogRef.close();
                }
              }else{
                //Open address update notice window
                this.loaded = true;
                this.requestAddressUpdate();
                this.dialogRef.close();
              }
            }
          )
        },
        error => {
          this.myMessage = this.getMyLanguage()=="EN"?"Login failed.":"ログインに失敗しました。";
          this.openSnackBar('error', 'close');
          this.loaded = true;
        }
      )
    }
  }

  requestAddressUpdate(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    //dialogConfig.maxHeight = '500px'; //Desktop
    dialogConfig.maxWidth = '600px';//Desktoop
    //dialogConfig.height = '100%';//Mobile
    dialogConfig.width = '95%';//Mobile
    dialogConfig.panelClass = 'custom-modalbox'; //Refer global level
    dialogConfig.data = { model: this.user };
    const dialogRef = this.dialog.open(RequestAddressUpdateComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var redirectPage = result;
      //  this.redirectPage(this.codeMaster.CodeType);
      if (result != null) {
        console.log("Confirmed Action: " + redirectPage);
      }
    })
  }

  onClickForgotPassword(){
    const dialogRef = this.dialog.open(PasswordRecoveryEnterEmailComponent, {
      width: '600px',
      height:'500px',
      data: {
        myEmail:this.data.myEmail
      }
    });
    this.dialogRef.close();
  }

  onClickBack(){
    const dialogRef = this.dialog.open(EnterSearchingEmailComponent, {
      width: '600px',
      height:'500px',
      data: {
        myLanguage:this.mySelectedLanguage
      }
    });
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 5000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 12000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }


}
