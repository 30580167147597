<div class="myBody">
    <div class="myHeaderBase d-flex justify-content-center">
      <div class="myHeaderCore">
        <div>
          <img src='../../../../../assets/images/thumbnail_eclogo.png' class="ecLogo">
        </div>
      </div>
    </div>
    <div class="myMain">
      <div clas="myMainLeft">
        <div class="myMainHalfTop">
          <!-- Left Top -->
        </div>
        <div class="myMainHalfBottom">
          <!-- Left Bottom -->
        </div>
      </div>
      <div class="myMainCenter">
        <div class="myMainHalfTop d-flex justify-content-center">
          <!-- Main Top -->

          <mat-card class="mainCard b-radius">
            <!-- <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4> -->
            <!-- <div *ngIf="!loaded">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div> -->
              <!-- <form *ngIf="userFromServer | async; else loading"  -->
               <form
              [formGroup]="myFormGroup"
              (ngSubmit)="onSubmit()"
              >
              <div class="float-end stepNumber"></div>
              <!-- <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Summary':
                'サマリー'}}
              </h2> -->

                   <div class="jumbotron p-3 p-md-5 text-white rounded " id="registrationInfoSummaryHeader">
                    <div class="col-md-6 px-0">
                      <h4>{{this.router.url.includes('EN')==true?'Summary': 'サマリー'}}</h4>
                    </div>
                  </div>
                  <hr>
                  <div class="registrationInfoSummaryItem">
                    <h4> {{this.router.url.includes('EN')==true?'Personal Information1:': '個人情報1：'}}</h4>
                    <div class="row summaryTable">
                      <div class="col-10 col-sm-3 col-md-2">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?'Last Name': '姓（ローマ字）'}}
                        </div>
                        <div class="summaryValue">
                          {{user.LastName}}
                        </div>
                      </div>
                      <div class="col-10 col-sm-3 col-md-2">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?'First Name': '名 (ローマ字)'}}
                        </div>
                        <div class="summaryValue">
                          {{user.FirstName}}
                        </div>
                      </div>
                    </div>
                    <div class="row summaryTable">
                      <div class="col-10 col-sm-3 col-md-2">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?'Kanji - Last Name': '姓（漢字）'}}
                        </div>
                        <div class="summaryValue">
                          {{user.Myoji}}
                        </div>
                      </div>
                      <div class="col-10 col-sm-3 col-md-2">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?'Kanji - First Name': '名 (漢字)'}}
                        </div>
                        <div class="summaryValue">
                          {{user.Shimei}}
                        </div>
                      </div>
                    </div>
                    <div class="row summaryTable">
                      <div class="col-10 col-sm-3 col-md-4">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?'Email': 'Eメール'}}
                        </div>
                        <div class="summaryValue">
                          {{user.Email}}
                        </div>
                      </div>
                      <!-- <div class="col-10 col-sm-3 col-md-3">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?'Tel#': '電話番号'}}
                        </div>
                        <div class="summaryValue">
                          {{user.TelNo }}
                        </div>
                      </div> -->
                    </div>
                    <div class="divEditButton">
                      <button class="btn btnEdit float-end" (click)="goToBasic()">
                        {{this.router.url.includes('EN')==true?'Edit': '編集'}}
                      </button>
                      <div *ngIf="!user.FirstName || !user.LastName" class="float-end missingRequiredInfo">
                        {{this.router.url.includes('EN')==true?'Required Entry Missing': '必要項目が入力されていません'}}
                      </div>
                    </div>
                    <br>
                  </div>
                  <hr>

                  <div class="registrationInfoSummaryItem">
                    <h4> {{this.router.url.includes('EN')==true?'Personal Information2:': '個人情報2：'}}</h4>

                    <div class="row summaryTable">
                      <div class="col-10 col-sm-3 col-md-4">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?'Gender': '性別'}}
                        </div>
                        <div class="summaryValue">
                          <!-- {{this.router.url.includes('EN')==true?user.Gender:(user.Gender=="Male"?"男性":(user.Gender="Female"?"女性":""))}} -->


                            {{this.router.url.includes('EN')==true?
                            (user.Gender)
                            :
                            (user.Gender=='Male'?'男性':(user.Gender=='Female'?'女性':''))
                          }}


                        </div>
                      </div>
                      <div class="col-10 col-sm-3 col-md-3">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?'DOB': '生年月日'}}
                        </div>
                        <div class="summaryValue">
                          {{user.DOB | date: 'MM/dd/yy' }}
                        </div>
                      </div>
                    </div>

                    <div class="divEditButton">
                      <button class="btn btn-info btn-lg btnEdit float-end" (click)="goToBasicSub()">
                        {{this.router.url.includes('EN')==true?'Edit': '編集'}}
                      </button>
                      <div *ngIf=" !user.Gender ||  !user.DOB " class="float-end missingRequiredInfo">
                        {{this.router.url.includes('EN')==true?'Required Entry Missing': '必要項目が入力されていません'}}
                      </div>
                    </div>
                    <br>
                  </div>
                  <hr>

                  <div class="registrationInfoSummaryItem">
                    <h4> {{this.router.url.includes('EN')==true?'Address:': '住所：'}}</h4>

                    <div *ngIf="user.Country!='Japan'">
                      <div class="row summaryTable">
                        <div class="col-6 col-sm-3 col-md-2">
                          <div class="summaryTitle">
                            {{this.router.url.includes('EN')==true?'Country': '国'}}:
                          </div>
                          <div class="summaryValue">
                            {{user.Country}}
                          </div>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                          <div class="summaryTitle">
                            {{this.router.url.includes('EN')==true?'Street': 'ストリート'}}
                          </div>
                          <div class="summaryValue">
                            {{user.Street}}
                          </div>
                        </div>
                        <div class="col-6 col-sm-3 col-md-2">
                          <div class="summaryTitle">
                            {{this.router.url.includes('EN')==true?'City': '市'}}
                          </div>
                          <div class="summaryValue">
                            {{user.City}}
                          </div>
                        </div>
                        <div class="col-6 col-sm-3 col-md-1">
                          <div class="summaryTitle">
                            {{this.router.url.includes('EN')==true?'State': '州'}}
                          </div>
                          <div class="summaryValue">
                            {{user.State}}
                          </div>
                        </div>
                        <div class="col-6 col-sm-3 col-md-1">
                          <div class="summaryTitle">
                            {{this.router.url.includes('EN')==true?'Zip': 'Zip'}}
                          </div>
                          <div class="summaryValue">
                            {{user.Zip}}
                          </div>
                        </div>
                      </div>
                      <div class="row summaryTable">
                        <div class="col-6 col-sm-3 col-md-1">
                          <div class="summaryTitle">
                            {{this.router.url.includes('EN')==true?'Tel#': '電話番号'}}
                          </div>
                          <div class="summaryValue">
                            {{user.TelNo}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="user.Country=='Japan'">
                      <div class="row summaryTable">
                        <div class="col-10 col-sm-3 col-md-3">
                          <div class="summaryTitle">
                            {{this.router.url.includes('EN')==true?'Country': '国'}}:
                          </div>
                          <div class="summaryValue">
                            日本
                          </div>
                        </div>
                        <div class="col-10 col-sm-3 col-md-3">
                          <div class="summaryTitle">
                            {{this.router.url.includes('EN')==true?'Yubin Bango': '郵便番号'}}
                          </div>
                          <div class="summaryValue">
                            {{user.Yubin_Bango}}
                          </div>
                        </div>
                        <div class="col-10 col-sm-3 col-md-4">
                          <div class="summaryTitle">
                            {{this.router.url.includes('EN')==true?'To_Do_Fu_Ken': '都道府県'}}
                          </div>
                          <div class="summaryValue">
                            {{user.To_Do_Fu_Ken}}
                          </div>
                        </div>
                      </div>
                      <div class="row summaryTable">
                        <div class="col-10 col-sm-3 col-md-3">
                          <div class="summaryTitle">
                            {{this.router.url.includes('EN')==true?'Shi_Gun_Ku': '市郡区'}}
                          </div>
                          <div class="summaryValue">
                            {{user.Shi_Gun_Ku}}
                          </div>
                        </div>
                        <div class="col-10 col-sm-3 col-md-4">
                          <div class="summaryTitle">
                            {{this.router.url.includes('EN')==true?'Cho_Son': '町村'}}
                          </div>
                          <div class="summaryValue">
                            {{user.Cho_Son}}
                          </div>
                        </div>
                        <div class="col-10 col-sm-3 col-md-4">
                          <div class="summaryTitle">
                            {{this.router.url.includes('EN')==true?'Apartment etc': 'アパート名等'}}
                          </div>
                          <div class="summaryValue">
                            {{user.Apartment_Etc}}
                          </div>
                        </div>
                      </div>
                      <div class="row summaryTable">
                        <div class="col-6 col-sm-3 col-md-1">
                          <div class="summaryTitle">
                            {{this.router.url.includes('EN')==true?'Tel#': '電話番号'}}
                          </div>
                          <div class="summaryValue">
                            {{user.TelNo}}
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="divEditButton">
                      <button class="btn btn-info btn-lg btnEdit float-end" (click)="goToAddressInfo()">
                        {{this.router.url.includes('EN')==true?'Edit': '編集'}}
                      </button>
                      <div *ngIf=" !user.Gender ||  !user.DOB " class="float-end missingRequiredInfo">
                        {{this.router.url.includes('EN')==true?'Required Entry Missing': '必要項目が入力されていません'}}
                      </div>
                    </div>
                    <br>
                  </div>
                  <hr>

                  <div class="registrationInfoSummaryItem">
                    <h4> {{this.router.url.includes('EN')==true?'Personal Information3:': '個人情報3：'}}</h4>

                    <div class="row summaryTable">
                      <div class="col-8 col-sm-4 col-md-3">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?'Are you Christian?': 'クリスチャンですか?'}}
                        </div>
                        <div class="summaryValue">
                          {{this.router.url.includes('EN')==true?
                          user.Christian==true?'Yes':'No'
                          :
                          user.Christian==true?'はい':'いいえ'}}
                        </div>
                      </div>
                      <div class="col-8 col-sm-4 col-md-3">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?'Are you baptized?': '洗礼を受けていますか？'}}
                        </div>
                        <div class="summaryValue">
                          {{this.router.url.includes('EN')==true?
                          user.Baptized==true?'Yes':'No'
                          :
                          user.Baptized==true?'はい':'いいえ'}}
                        </div>
                      </div>

                      <div class="col-8 col-sm-4 col-md-3">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?'Your overseas experiences': 'あなたの帰国情報'}}
                        </div>
                        <div class="summaryValue">
                          {{
                            this.router.url.includes('EN')==true?
                            user.ReturneeInfo
                            :
                            user.ReturneeInfo=='I will return in 2025'?'2025年に帰国予定'
                            :(user.ReturneeInfo=='I will return in 2026-2028'?'2026-2028年に帰国予定'
                            :(user.ReturneeInfo=='I will return after 2029'?'2029年以降に帰国予定'
                            :(user.ReturneeInfo=='Plans to live permanently in a country other than Japan'?'日本以外の国に永住予定'
                            :(user.ReturneeInfo=='I have return in 2022'?'2024年までに帰国'
                            :(user.ReturneeInfo=='I have returned in 2021-2023'?'2021-2023年に帰国'
                            :(user.ReturneeInfo=='I have returned before 2020'?'2020年以前に帰国'
                            :(user.ReturneeInfo=='Residing in Japan as a foreigner'?'日本に外国人として在住'
                            :(user.ReturneeInfo=='No experience living abroad'?'海外滞在経験はありません'
                            :user.ReturneeInfo))))))))


                          }}
                        </div>
                      </div>
                    </div>

                    <div class="divEditButton">
                      <button class="btn btn-info btn-lg btnEdit float-end" (click)="goToFaithInfo()">
                        {{this.router.url.includes('EN')==true?'Edit': '編集'}}
                      </button>
                      <div *ngIf=" !user.Gender ||  !user.DOB " class="float-end missingRequiredInfo">
                        {{this.router.url.includes('EN')==true?'Required Entry Missing': '必要項目が入力されていません'}}
                      </div>
                    </div>
                    <br>
                  </div>
                  <hr>



                <br>
                <div *ngIf="!loaded">
                  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
                <div *ngIf="!loaded" class="progressMessage">
                  {{this.router.url.includes('EN')==true?'Saving...': '保存中...'}}
                </div>













              <div class=" position-absolute bottom-0 start-50 translate-middle-x">
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn btnProcess" (click)="onClickGoBack()">
                    {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                  </button>
                  <!-- <button  [disabled]="!myFormGroup.valid" type="button" class="btn btnProcess"
                   (click)="onClickGoForward()">
                    {{this.router.url.includes('EN')==true?'Submit': '送信'}}
                  </button> -->

                  <button [disabled]="!myFormGroup.valid" type="button" class="btn btnProcess"
                  (click)="savingPersonalInfo()">
                  {{this.router.url.includes('EN')==true?'Submit': '送信'}}
                  </button>

                </div>
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn link btnCancel" (click)="cancelProcess()">
                    {{this.router.url.includes('EN')==true?'Cancel': 'キャンセル'}}
                  </button>
                </div>
              </div>

            </form>
             <!-- <button type="submit" [disabled]="!myFormGroup.valid">Submit</button> -->
          </mat-card>
        </div>
        <div class="myMainHalfBottom">
          <!-- Main Bottom -->
        </div>
      </div>
      <div class="myMainRight">
        <div class="myMainHalfTop">
          <!-- Right Top -->
        </div>
        <div class="myMainHalfBottom">
          <!-- Right Bottom -->
        </div>
      </div>
    </div>
    <!-- <footer>footer</footer> -->
  </div>
