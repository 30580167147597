<div class="myBody  myBodySub">
  <div class="myHeaderBase d-flex justify-content-center">
    <div class="myHeaderCore">
      <div>
        <!-- <img src='../../../../../assets/images/thumbnail_ec22logo.jpg' class="ecLogo"> -->
      </div>
    </div>
  </div>
  <div class="myMain">
    <div clas="myMainLeft">
      <div class="myMainHalfTop">
        <!-- Left Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Left Bottom -->
      </div>
    </div>
    <div class="myMainCenter">
      <div class="myMainHalfTop d-flex justify-content-center">
        <!-- Main Top -->
        <mat-card class="mainCard b-radius">
          <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
          <!-- <form *ngIf="userFromServer | async; else loading"  -->
          <form [formGroup]="myFormGroup" (ngSubmit)="onSubmit()">
            <div class="float-end stepNumber"></div>
            <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Terms of Participation':
              '参加規約'}}
              <i class="stepTitleSub">
                {{this.router.url.includes('EN')==true?'Required': '必須'}}
              </i>
            </h2>
            <div class="stepTitle">
              <!-- <label><b>
                  {{this.router.url.includes('EN')==true?"Japanese Christian Fellowship Network General Release and
                  Waiver of Rights":
                  'Equipper Conference参加同意、免責事項と権利放棄について'}}
                </b></label><br> -->
              <div>
                <div class="make-scrollable scrollBackGround" flex>
                  <div *ngIf="this.router.url.includes('EN')==true">
                    <b>ACKNOWLEDGMENT AND RELEASE OF LIABILITY</b><br>
                    I understand and agree that my participation or the participation of my child or dependent, for whom
                    I am legally responsible, in such activities is a voluntary activity entered into for the purpose of
                    personal development and recreation. I expressly assume all risks of participating in the event. I
                    hereby waive and release any and all claims, actions, lawsuits and proceedings that I or my child or
                    dependent might have against Global Returnees Conference (hereafter GRC) and sponsoring
                    organization, Japanese Christian Fellowship Network (hereafter JCFN) arising out of any injury that
                    I or my child or dependent might sustain. I understand and agree that I am solely responsible for
                    any medical expenses and other costs and expenses arising out of such injuries. In the event of an
                    apparent medical emergency, I authorize GRC personnel or volunteers to obtain medical emergency
                    help. I have carefully read this release in its entirety, understand it, and sign it on the
                    registration form voluntarily, and am over 18 years of age. I understand and agree to the above
                    provisions and hereby authorize the participation of my child or dependent in the described event.
                    <br><br>
                    <b>RIGHT OF PUBLICITY WAIVER</b><br>
                    I understand and agree that my participation or the participation of my child or dependent, for whom
                    I am legally responsible, in Global Returnees Conference (hereafter GRC), give chances of being
                    recorded by GRC and sponsoring organization, Japanese Christian Fellowship Network (hereafter JCFN),
                    and other co-sponsoring organizations for the record including photo, audio, video, writings, and
                    other methods. (Hereafter, all processes of recording will be indicated as "records.") I hereby
                    grant GRC, JCFN and co-sponsoring organizations which request and receive permission to use these
                    records by GRC planning committee to use my name and age according to the purposes that GRC planning
                    committee permits and for the purposes of promoting GRC ministries and meet their interests. GRC can
                    do so unlimitedly without my permission or compensation. I hereby waive and release any and all
                    claims, actions, lawsuits and proceedings that I or my child or dependent might have against Global
                    Returnees Conference arising out of any and all records usage. I hereby waive and release my right
                    to erase, dismiss, destroy, and deny any and all records that include me.
                    <br><br>
                    <b>CONSENT TO COVID-19 CONTROL</b><br>
                    COVID-19 and other infectious conditions are constantly changing. In order to ensure the safety of
                    the event, updated measures will be presented at the time of the event, if necessary. I ( If a
                    minor, the parent or guardian) agree to participate in accordance with the infection control
                    measures taken by the GRC.
                    <br><br>
                    <b>TO THE LEGAL GUARDIAN</b><br>
                    I, the parent or guardian of the above named Participant, give my voluntary consent to his or her
                    participation in such activities. I certify this by signing it voluntarily on this form.
                    <br><br>
                  </div>
                  <div *ngIf="this.router.url.includes('EN')!=true">
                    <b>免責事項について</b><br>
                    私（未成年の場合、親権者、または保護者）は、グローバルリターニーズコンファレンス（以下、GRC）に自己の向上やレクリエーションの目的で、自分の意志から参加することを理解し、同意します。私（未成年の場合、親権者、または保護者）は、参加に伴うあらゆる危険を想定していることを表明するとともに、私（未成年の場合、親権者、または保護者）は、起こりうるあらゆる損害に関し、GRC、また、主催団体のジャパニーズ・クリスチャン・フェローシップ・ネットワーク（以下、JCFN）に対し、参加申込者が持つあらゆるまた全ての要求、法的措置、訴訟、訴訟手続きを放棄また譲渡します。私は、そのような損害から生ずるあらゆる医療費やその他の費用について自己負担するということを理解し、同意します。万一の医療的な緊急事態には、GRC／JCFNスタッフ、またボランティアに取るべき医療措置を委ねます。私（18歳以上）は、この文章全体を注意深く読み、理解し、申込書に自分の意志で署名します。私は、上記の文言すべてを理解、同意し、私の子供、被扶養家族の参加についても承認します。
                    <br><br>
                    <b>肖像権放棄について</b><br>
                    Global Returnees
                    Conference（以下、GRC）に参加したことで、運営主催であるジャパニーズ・クリスチャン・フェローシップ・ネットワーク（以下、JCFN）、GRC実行委員会、協賛団体が、私を撮影、録音、録画、筆記等またはこれらに準ずる方法により記録（以下録音や録画を文字または写真にして使用する等他の媒体に転換する場合も含み、合わせて｢記録｣といいます）する場合があり、記録、GRCの結果並びに私の名前及び年齢を、JCFN、及び協賛団体でGRC実行委員会に事前にその使用を希望し、GRC実行委員会がGRCにおけるミニストリー内容等を考慮の上でその使用を確認した団体が、GRC開催後に、その希望する、時、場所及び方法（テレビ、ラジオ、ウェブサイト、新聞、雑誌を含むがこれらに限られない）で、無期限かつ無制限に、私から確認や許可を得ずに、無償で使うこと、本大会関係団体の故意または過失に関わらず、記録等に関して生ずる損害について、GRC関係団体に対して一切責任を追求しないこと並びに本同意書のいかなる部分についても、無効、取消し、解除または解約するような行動を取らないことを理解し、同意します。
                    <br><br>
                    <b>コロナ対策への同意</b><br>
                    コロナなどの感染状況は常に変化しています。安全に開催するため、必要に応じて開催時に更新された対策を提示します。私（未成年の場合、親権者、または保護者）は、GRCの講じる感染対策に従い、参加することを同意します。
                    <br><br>
                    <b>保護者の方へ</b><br>
                    上記申込者の親権者、保護者である私は、上記申込者のGRCへの参加を、自分の意志により承認します。私は、自分の意志によって申込書に署名することにより、そのことを保証します。
                    <br><br>
                  </div>
                </div>
              </div>
              <br>
              <label class="stepTitle2nd">
                {{this.router.url.includes('EN')==true?
                'I (the participant or, if the participant is a minor, the parent or legal guardian) am at least 18
                years of age and have read and understand the above, I have read and understand the "ACKNOWLEDGMENT AND
                RELEASE OF LIABILITY, RIGHT OF PUBLICITY WAIVER, and CONSENT TO COVID-19 CONTROL" above. I also agree to
                its contents. By checking the box below, I express my consent.'
                :
                '私（参加者または参加者が未成年の場合、親権者、または保護者）は、18歳以上であり、上記の「免責事項と肖像権放棄、コロナ対策への同意について」を読み理解しました。また、その内容に同意します。下のチェックボックスをチェックすることによって私の同意を表明します。'}}
              </label>
              <label class="stepTitle2nd" style="color:darkblue;">
                {{this.router.url.includes('EN')==true?
                'IMPORTANT!!! Please read the "ACKNOWLEDGMENT AND RELEASE OF LIABILITY, RIGHT OF PUBLICITY WAIVER, and
                CONSENT TO COVID-19 CONTROL" and agree to it. No application will be accepted without consent. If the
                participant is under 18 years of age, the consent of a parent or legal guardian is required.'
                :
                '重要！「免責事項と肖像権放棄、コロナ対策への同意について」を一読の後、同意してください。同意なしでは申し込みを受け付けられません。参加者が未成年の場合は親権者、保護者の同意が必要となります。'}}
              </label>
              <div>
                <div class="participantConsent">
                  <mat-checkbox #checkboxConsent formControlName="myTermsOfParticipation"
                    (change)='onChangeConsent(checkboxConsent.checked)' class="ckb_SG"
                    [checked]="conference.TermsOfParticipation==true"> {{this.router.url.includes('EN')==true?'* I
                    Agree':'*
                    同意します'}}
                  </mat-checkbox>
                  <mat-form-field class="participantConsent">
                    <input matInput placeholder='Date' formControlName="myTermsOfParticipationDate"
                      [matDatepicker]="TermsOfParticipationDate" class="matInputBox" />
                    <mat-datepicker-toggle matSuffix [for]="TermsOfParticipationDate"></mat-datepicker-toggle>
                    <mat-datepicker #TermsOfParticipationDate></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div *ngIf="!loaded">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
<br><br> <br><br> <br><br>
            <div class="d-flex justify-content-center">
              <div class="processButtonSet">
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn btnNext" (click)="onClickGoBack()">
                    {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                  </button>
                  <button [disabled]="!myFormGroup.valid" type="button" class="btn btnNext"
                    (click)="savingConferenceInfo()">
                    {{this.router.url.includes('EN')==true?'Submit': '送信'}}
                  </button>
                </div>
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn link btnCancel" (click)="cancelProcess()">Cancel</button>
                </div>
              </div>
            </div>

            <!-- <div class=" position-absolute bottom-0 start-50 translate-middle-x">
              <div class="d-flex justify-content-center">
                <button type="button" class="btn btnProcess" (click)="onClickGoBack()">
                  {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                </button>
                <button  [disabled]="!myFormGroup.valid" type="button" class="btn btnProcess"
                (click)="savingConferenceInfo()">
                {{this.router.url.includes('EN')==true?'Submit': '送信'}}
                </button>
              </div>
              <div class="d-flex justify-content-center">
                <button type="button" class="btn link btnCancel" (click)="cancelProcess()">Cancel</button>
              </div>
            </div> -->

          </form>
        </mat-card>
      </div>
      <div class="myMainHalfBottom">
        <!-- Main Bottom -->
      </div>
    </div>
    <div class="myMainRight">
      <div class="myMainHalfTop">
        <!-- Right Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Right Bottom -->
      </div>
    </div>
  </div>
  <!-- <footer>footer</footer> -->
</div>
