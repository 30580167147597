import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,  Validators, AbstractControl, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../../../services/auth/auth.service";
import { User } from "../../../models/user";
import  { MemberList} from "../../../models/memberList";
import {CheckGroup} from '../../../models/checkGroup';
import { UserService } from "../../../services/user/user.service";
import { MatRadioChange } from '@angular/material/radio';
import { MatStepper } from '@angular/material/stepper';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
//For Dialog
import { MatDialog , MatDialogConfig} from "@angular/material/dialog";
import { LoginMessageComponent } from '../../dialog/login-message/login-message.component';
import { UserEditMyProfileComponent } from '../../dialog/user-edit-my-profile/user-edit-my-profile.component';
import { UserEditOthersProfileComponent  } from '../../dialog/user-edit-others-profile/user-edit-others-profile.component';
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import { PaymentService } from "../../../services/payment/payment.service";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
/***** Angular Table ****************/
import { AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';


import { PaymentMaster, LineItem, RecurringPayment } from "../../../models/payment/paymentMaster";

interface MyList{
	display:string;
  value:string;
}

@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.css']
})
export class PaymentListComponent implements OnInit {
  checkGroup: CheckGroup = {
    id: null,
    value: null,
  };
  checkGroups: CheckGroup[] = [];

  paymentTypes = [
    { value: "All", display: "All" },
    { value: "notRecurring", display: "Not Recurring" },
    { value: "recurring", display: "Recurring" }
  ];

  itemNames:MyList[];

  firstFormGroup: FormGroup;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  myMessage: string;
  loaded: boolean = true;
  mySelectedLanguage: string;
  cntUser: number;
  showNumberOfUser: boolean;

  displayedColumns: string[] = ['LastName', 'FirstName','ItemName','ProviderName','Recurring','PaymentDate'];
  dataSource: MatTableDataSource<User>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

user: User = {
  PasswordHash: null,
  Id: null,
  UserName: null,
  Email: null,
  FirstName: null,
  LastName: null,
  Password: null,
  Prefix: null,
  Shimei: null,
  Myoji: null,
  Street: null,
  Street2: null,
  City: null,
  State: null,
  Zip: null,
  Country: null,
  Yubin_Bango: null,
  To_Do_Fu_Ken: null,
  Shi_Gun_Ku: null,
  Cho_Son: null,
  Apartment_Etc: null,
  To_Do_Fu_Ken_JP: null,
  Shi_Gun_Ku_JP: null,
  Cho_Son_JP: null,
  Apartment_Etc_JP: null,
  Gender: null,
  DOB: null,
  TelNo: null,
  TimezoneName: null,
  UTCdiff: null,
  Token: null,
  Occupation: null,
  MemberStatus: null,
  Married: null,
  Christian: null,
  Baptized:null,
  ReturneeInfo:null,
  HasReturningChurch:null,
  ZoomAccount: null,
  RegisteredDate: null,
  LastAccessedDate: null,
  //From siteground
  LoginId: null,
  MiddleNameEN: null,
  MiddleNameJP: null,
  PrefixJP: null,
  OccupationDetail: null,
  Organization: null,
  LivingCountry: null,
  PhoneEN: null,
  PhoneMobileEN: null,
  PhoneJP: null,
  PhoneMobileJP: null,
  ChurchNameEN: null,
  ChurchNameJP: null,
  MemberType: null,
  MemberDetail: null,
  MemberMemo: null,
  Shokaisha: null,
  Reason: null,
  NewsLetters: null,
  StaffReports: null,
  OtherLetters: null,
  NewsLetterLanguage: null,
  BasicRoles: null,
  //Added new
  MigrateFromSiteGround: null,
  AdminAccess: null,
  JCFNAccess: null,
  SupportingMember: null,
  LastAddressUpdatedDate:null,
  AcceptMembershipTerm:null,
  MembershipType:null
};
users: User[];

memberList: MemberList = {
  Id: null,
  UserName: null,
  Email: null,
  FirstName: null,
  LastName: null,
  Password: null,
  Prefix: null,
  Shimei: null,
  Myoji: null,
  Street: null,
  Street2: null,
  City: null,
  State: null,
  Zip: null,
  Country: null,
  Yubin_Bango: null,
  To_Do_Fu_Ken: null,
  Shi_Gun_Ku: null,
  Cho_Son: null,
  Apartment_Etc: null,
  To_Do_Fu_Ken_JP: null,
  Shi_Gun_Ku_JP: null,
  Cho_Son_JP: null,
  Apartment_Etc_JP: null,
  Gender: null,
  DOB: null,
  TelNo: null,
  TimezoneName: null,
  UTCdiff: null,
  Token: null,
  Occupation: null,
  MemberStatus: null,
  Married: null,
  Christian: null,
  ZoomAccount: null,
  LivingCountry: null,
  NewsLetterLanguage: null,
  LastAddressUpdatedDate: null,
  AcceptMembershipTerm:null,
  MemberType:null,
  MembershipType:null,
  MyMembershipTypes:this.checkGroups,
  MinAge:null,
  MaxAge:null,
  RegisteredDate: null,
  LastAccessedDate: null,
  AdminAccess: null,
  JCFNAccess: null,
  CreatedDate:null,
  LastModifiedDate:null,

  ConferenceName:null,
  Language:null,
  Attend:null,
  NewsLetters:null,
  MyNewsLetters:this.checkGroups,
  ReceivingMethod:null,
  ActiveStatus:null
};
memberLists:MemberList[]=[];

lineItem: LineItem = {
  itemId: 0,
  name: null,
  description: null,
  quantity: 0,
  unitPrice: 0
}
lineItems: LineItem[] = [];
recurringPayment: RecurringPayment = {
  RecurringPaymentId: 0,
  RecurringId: null,
  Interval: null,
  StartDate: null,
  EndDate: null,
  CreatedDate: null
}
paymentMaster: PaymentMaster = {
  PaymentMasterId: 0,
  FirstName: null,
  LastName: null,
  Email: null,
  DataDescriptor: null,
  DataValue: null,
  ItemName: null,
  Amount: null,
  Recurring: null,
  RecurringId: null,
  CreatedDate: null,
  ProviderName:null,
  lineItems: this.lineItems,
  recurringPayment: this.recurringPayment
}

  constructor(
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    public authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog,
    private paymentService: PaymentService
  ) { }

  mySearch: any = {};

  ngOnInit(): void {
    this.mySelectedLanguage = this.router.url.includes('EN') == true ? "EN" : "JP";
    this.firstFormGroup = this._formBuilder.group({
    });

  this.getAllItemNames();
  }

  getAllItemNames(){
    this.loaded = false;
    this.paymentService.getAllItemNames()
      .subscribe(results => {
        this.itemNames = results;
        this.loaded = true;
      },
        error => {

        }
      );
  }

  getMyLanguage(): string {
    return this.router.url.includes('EN') == true ? 'EN' : 'JP';
  }

  onSearchSubmit({ value, valid }: { value: User; valid: boolean }) {
    this.memberLists = null;
    this.cntUser = 0;
    this.memberList.FirstName =
      value.FirstName == null
        ? null
        : value.FirstName.trim() == ""
          ? null
          : value.FirstName.trim();
    this.memberList.LastName =
      value.LastName == null
        ? null
        : value.LastName.trim() == ""
          ? null
          : value.LastName.trim();
    this.memberList.Email =
      value.Email == null
        ? null
        : value.Email.trim() == ""
          ? null
          : value.Email.trim();

    if (!valid) {
      this.myMessage = this.getMyLanguage()=="EN"?"Please fill out the form correctly.":"正しく入力がなされていません。";
      this.openSnackBar('error', 'close');
      this.loaded = true;
    } else {
      sessionStorage.removeItem("mySearchingUser");
      sessionStorage.setItem(
        "mySearchingUser",
        JSON.stringify({ myProfileSearch: this.memberList })
      );
      this.getUsersBySearchKey(this.memberList);
    }
  }

  getUsersBySearchKey(memberList: MemberList) {
    this.loaded = false;
    this.showNumberOfUser = false;
    this.userService.getMemberListsBySearchKey(memberList)
      .subscribe(results => {
        this.memberLists = results;
        // Assign the data to the data source for the table to render
        this.dataSource = new MatTableDataSource(this.users);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loaded = true;

        var tempEmail: any;
        var cnt = 0;
        this.users.forEach(element => {
          if (tempEmail != element) {
            tempEmail = element.Email;
            cnt++;
          }
          this.cntUser = cnt;
        });
        this.loaded = true;
        this.showNumberOfUser = true;
      },
        error => {
          // this.myMessage = "Data search failed."
          // this.openSnackBar('error', 'close');
          // this.loaded = true;
          // this.showNumberOfUser = true
        }
      );
  }

  onCancel($event: any) {
    event.preventDefault(); // This is neccessary to prevent cencel button submit form
    this.user.FirstName = null;
    this.user.LastName = null;
    this.user.Email = null;
    this.user.Gender = null;
    this.user.MemberStatus=null;
    this.user.MemberType=null;
    this.user.SupportingMember=null;
    sessionStorage.removeItem("mySearchingUser");
  }

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 5000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 10000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }

  onClickEditProfile(row: User) {
    sessionStorage.removeItem("searchingRegistrantId");
    sessionStorage.setItem("searchingRegistrantId", row.Id);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='800px'; //Desktop
    dialogConfig.maxWidth='1000px';//Desktoop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ Id: row.Id };
    const dialogRef = this.dialog.open(UserEditOthersProfileComponent,dialogConfig);
    //  {
    //   width: '1000px',
    //   height: '800px',
    //   data: {
    //     Id: row.Id
    //   }
    // });
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
        console.log("returned AS400Code: " + confirmedAction.AS400Code);
      }
      this.getUsersBySearchKey(this.memberList);
    })
  }

  onClickEditPayment(row: User): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='900px'; //Desktop
    dialogConfig.maxWidth='1300px';//Desktoop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data={ Id: row.Id };
    const dialogRef = this.dialog.open(UserEditOthersProfileComponent,dialogConfig);
    // , {
    //   width: '1300px',
    //   height: '900px',
    //   data: {
    //     Id: row.Id
    //   }
    // });
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
        console.log("returned AS400Code: " + confirmedAction.AS400Code);
      }
      this.getUsersBySearchKey(this.memberList);
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onSelectPaymentType(model: string) {
  }
  onSelectItemName(model: string) {
  }

  // exportToExcel() {
  //   event.preventDefault(); // This is neccessary to prevent cencel button submit form
  //   var headers = {
  //     FirstName: "First Name",
  //     LastName: "Last Name",
  //     Email: "Email",
  //     Zoom:"Zoom",
  //     Gender: "Gender",
  //     Age: "Age",
  //     MemberStatus:"Member Status",
  //     MemberType:"Member Type",
  //     SupportingMember:"Supporting Member"
  //   };
  //   var itemsFormatted: any = [];
  //   this.users.forEach(item => {
  //     var dt = new Date(item.RegisteredDate);
  //     var tempMonth = dt.getMonth()+1;
  //     var tempYear = dt.getFullYear();
  //     var tempDay = dt.getDate();


  //     itemsFormatted.push({
  //       FirstName: item.FirstName == null ? "" : item.FirstName.replace(/,/g, " "),
  //       LastName: item.LastName == null ? "" : item.LastName.replace(/,/g, " "),
  //       Email: item.Email == null ? "" : item.Email,
  //       Gender: item.Gender == null ? "" : item.Gender,
  //       Age: item.DOB == null ? "" : this.calculate_age(item.DOB),
  //       MemberStatus: item.MemberStatus == null ? "" : item.MemberStatus,
  //       MemberType: item.MemberType == null ? "" : item.MemberType,
  //       SupportingMember: item.SupportingMember == null ? "" : item.SupportingMember == true ? 'Yes' : 'No',
  //     });
  //   });
  //   var fileTitle = "codeMaster";
  //   this.exportCSVFile(headers, itemsFormatted, fileTitle);
  // }
  // exportCSVFile(headers: any, items: any, fileTitle: any) {
  //   if (headers) {
  //     items.unshift(headers);
  //   }
  //   // Convert Object to JSON
  //   var jsonObject = JSON.stringify(items);
  //   var csv = this.convertToCSV(jsonObject);
  //   var exportedFilenmae = fileTitle + ".csv" || "export.csv";
  //   var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  //   if (navigator.msSaveBlob) {
  //     // IE 10+
  //     navigator.msSaveBlob(blob, exportedFilenmae);
  //   } else {
  //     var link = document.createElement("a");
  //     if (link.download !== undefined) {
  //       // feature detection
  //       // Browsers that support HTML5 download attribute
  //       var url = URL.createObjectURL(blob);
  //       link.setAttribute("href", url);
  //       link.setAttribute("download", exportedFilenmae);
  //       link.style.visibility = "hidden";
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     }
  //   }
  // }
  // convertToCSV(objArray: any) {
  //   var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  //   var str = "";
  //   for (var i = 0; i < array.length; i++) {
  //     var line = "";
  //     for (var index in array[i]) {
  //       if (line != "") line += ",";
  //       line += array[i][index];
  //     }
  //     str += line + "\r\n";
  //   }
  //   return str;
  // }

}
