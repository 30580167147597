<div class="myBody">
    <div class="myHeaderBase d-flex justify-content-center">
        <div class="myHeaderCore">
            <div>
                <img src='../../../../../assets/images/thumbnail_eclogo.png'  class="ecLogo">
            </div>
        </div>
    </div>
    <div class="myMain">
        <div clas="myMainLeft">
            <div class="myMainHalfTop">
                <!-- Left Top -->
            </div>
            <div class="myMainHalfBottom">
                <!-- Left Bottom -->
            </div>
        </div>
        <div class="myMainCenter">
            <div class="myMainHalfTop d-flex justify-content-center">
                <!-- Main Top -->

                <mat-card class="mainCard b-radius">
                        <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
                        <div *ngIf="!loaded">
                            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                        </div>

                        <!-- <form *ngIf="userFromServer | async; else loading"  -->
                        <form
                        [formGroup]="myFormGroup"
                        (ngSubmit)="onSubmit()"
                        >
                        <div class="float-end stepNumber">5/12</div>
                        <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'English Conversation Partners':
                            'イングリッシュ・カンバセーション・パートナー'}}
                             <i class="stepTitleSub">
                                {{this.router.url.includes('EN')==true?'Required': '必須'}}
                            </i>
                        </h2>

                        <div class="stepTitle2nd">
                            {{this.router.url.includes('EN')==true?'English Conversation Partners is for Japanese speakers to get to know English speakers AND for English speakers to get to know Japanese people and culture.':
                            'イングリッシュ・カンバセーション・パートナーは、EC中の食事の時間を利用して、英語を話す人と日本語を話す人たちが友達になり、互いの文化や言語を学ぶ目的で行われます。'}}
                        </div>
                        <div *ngIf="conference.ProgramType=='Youth'" class="stepTitle2nd">
                          <u>{{this.router.url.includes('EN')==true?'Youth are not eligible.':
                            'ユースは対象外'}}
                          </u>
                        </div>
                         <div  *ngIf="loaded" class="d-flex justify-content-center">
                            <mat-radio-group formControlName="myApplyingEnglishPartner"
                               (change)="onChangeEnglishPartnersSelection($event)" class="rbGroup">
                               <mat-radio-button value=true class="rbItem"
                               [checked]="conference.ApplyEnglishPartner==true"
                               [disabled]="conference.ProgramType=='Youth'"
                               >
                                  {{this.router.url.includes('EN')==true?'Attend': '参加する'}}</mat-radio-button>
                               <mat-radio-button value=false class="rbItem"
                               [checked]="conference.ApplyEnglishPartner==false"
                               [disabled]="conference.ProgramType=='Youth'"
                               >
                                  {{this.router.url.includes('EN')==true?'Not attend': '参加しない'}}</mat-radio-button>
                            </mat-radio-group>
                         </div>
                        <div class=" position-absolute bottom-0 start-50 translate-middle-x">
                            <div class="d-flex justify-content-center">
                                <button type="button" class="btn btnProcess" (click)="onClickGoBack()">
                                    {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                                </button>
                                <button  [disabled]="!myFormGroup.valid" type="button" class="btn btnProcess"
                                    (click)="onClickGoForward()">
                                    {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                                </button>
                            </div>
                            <div class="d-flex justify-content-center">
                                <button type="button" class="btn link btnCancel"
                                    (click)="cancelProcess()">
                                    {{this.router.url.includes('EN')==true?'Cancel': 'キャンセル'}}
                                  </button>
                            </div>
                        </div>
                        <!-- <button type="submit" [disabled]="!myFormGroup.valid">Submit</button> -->
                    </form>
                </mat-card>
            </div>
            <div class="myMainHalfBottom">
                <!-- Main Bottom -->
            </div>
        </div>
        <div class="myMainRight">
            <div class="myMainHalfTop">
                <!-- Right Top -->
            </div>
            <div class="myMainHalfBottom">
                <!-- Right Bottom -->
            </div>
        </div>
    </div>
    <!-- <footer>footer</footer> -->
</div>
