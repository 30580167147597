<div class="myBody myBodySub">
  <div class="myHeaderBase d-flex justify-content-center">
    <div class="myHeaderCore">
      <div>
     <!-- <img src='../../../../../assets/images/GRC23.png' class="grcLogo"> -->
      </div>
    </div>
  </div>
  <div class="myMain">
    <div clas="myMainLeft">
      <div class="myMainHalfTop">
        <!-- Left Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Left Bottom -->
      </div>
    </div>
    <div class="myMainCenter">
      <div class="myMainHalfTop d-flex justify-content-center">
        <!-- Main Top -->

        <mat-card class="mainCard b-radius">
          <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
          <div *ngIf="!loaded">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>

          <!-- <form *ngIf="userFromServer | async; else loading"  -->
          <form
            [formGroup]="myFormGroup"
            (ngSubmit)="onSubmit()"
            >
            <div class="float-end stepNumber">2/4</div>
            <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Personal Information':
              '個人情報'}}
              <i class="stepTitleSub">
                {{this.router.url.includes('EN')==true?'* ... Required': '* ... 必須項目'}}
              </i>
            </h2>

            <div>
              <div class="stepTitle2nd">
                <label>
                  {{this.router.url.includes('EN')==true?'Gender *:': '性別 *：'}}<br>
                  {{this.router.url.includes('EN')==true?
                  'Required for rooming and grouping small group. Please email to info@globalreturnees.org for any questions.'
                  : '部屋割、スモールグループ分けのために必要です。質問のある方はinfo@globalreturnees.orgまで。'}}
                </label>
              </div>
              <div class="registrationInputFormLg">
                <mat-radio-group formControlName="myGender"
                  (change)="onChangeGenderSelection($event)" class="radioBtnMain">
                  <mat-radio-button value="Male" [checked]="user.Gender=='Male'" class="radioBtnItem">
                    {{this.router.url.includes('EN')==true?'Male': '男性'}}</mat-radio-button>
                  <mat-radio-button value="Female" [checked]="user.Gender=='Female'" class="radioBtnItem">
                    {{this.router.url.includes('EN')==true?'Female': '女性'}}</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="myFormGroup.get('myGender').hasError('required')  && myFormGroup.get('myGender').touched">
                  {{this.router.url.includes('EN')==true?'Gender is required': '未入力'}}
                </mat-error>
              </div>
            </div>
            <br><br>
            <div>
              <div class="stepTitle">
                <label>
                  {{this.router.url.includes('EN')==true?'Date of birth *:': '生年月日 *：'}}
                </label><br>
                <label>
                  {{this.router.url.includes('EN')==true?
                  'Required for rooming and grouping small group. Please email to info@globalreturnees.org for any questions.'
                  : '部屋割、スモールグループ分けのために必要です。質問のある方はinfo@globalreturnees.orgまで。'}}
                </label>
              </div>
              <mat-form-field appearance="outline" class="registrationInputForm">
                <mat-label>
                  {{this.router.url.includes('EN')==true?'Date of Birth': '生年月日'}}
                </mat-label>
                <input matInput placeholder="MM/dd/yyyy" formControlName="myDOB"
                  (dateChange)="onChangeDOB($event)" [matDatepicker]="DOB" />
                <mat-datepicker-toggle matSuffix [for]="DOB"></mat-datepicker-toggle>
                <mat-datepicker #DOB></mat-datepicker>
                <mat-error *ngIf="myFormGroup.get('myDOB').hasError('required')">
                  {{this.router.url.includes('EN')==true?'DOB is required': '未入力'}}
                </mat-error>
              </mat-form-field>
            </div>
            <br>

            <div class=" position-absolute bottom-0 start-50 translate-middle-x">
              <div class="d-flex justify-content-center">
                <button type="button" class="btn btnNext" (click)="onClickGoBack()">
                  {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                </button>
                <button [disabled]="!myFormGroup.valid" type="button" class="btn btnNext" (click)="onClickGoForward()">
                  {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                </button>
              </div>
              <div class="d-flex justify-content-center">
                <button type="button" class="btn link btnCancel" (click)="cancelProcess()">Cancel</button>
              </div>
            </div>
            <!-- <button type="submit" [disabled]="!myFormGroup.valid">Submit</button> -->
          </form>
        </mat-card>
      </div>
      <div class="myMainHalfBottom">
        <!-- Main Bottom -->
      </div>
    </div>
    <div class="myMainRight">
      <div class="myMainHalfTop">
        <!-- Right Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Right Bottom -->
      </div>
    </div>
  </div>
  <!-- <footer>footer</footer> -->
</div>
