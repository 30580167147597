<div class="WelcomeBase">
  <div class="btnBack">
      <button type="button" class="btn btn-link" (click)="onClickBack()">
          <i class="fas fa-times-circle fa-2x"></i>
      </button>
  </div>
  <mat-card class="WelcomeWindow">
      <!-- <form *ngIf="userFromServer | async; else loading"  -->
      <form [formGroup]="myFormGroup" (ngSubmit)="onSubmit()">

          <mat-card-header>
              <mat-card-title class="gradient-1s">
                  {{this.router.url.includes('EN')==true?'Welcome to GRC2023 Registration':'GRC2023 レジストレーションサイトへようこそ'}}
              </mat-card-title>
          </mat-card-header>

          <div class="d-flex justify-content-center">
              <i class="fas fa-user fa-2x"></i>&nbsp;
              <mat-form-field appearance="outline" class="registrationInputForm">
                  <mat-label>
                      Email
                  </mat-label>
                  <input matInput placeholder="" formControlName="myEmail" class="registrationInputBox">
                  <mat-error *ngIf="myFormGroup.get('myEmail').hasError('required')">
                      {{this.router.url.includes('EN')==true?'Email is required': 'Emailが未入力です'}}
                  </mat-error>
                  <mat-error *ngIf="myFormGroup.get('myEmail').hasError('email')">
                      {{this.router.url.includes('EN')==true?'Invalid Email format': 'Eメールフォーマットが正しくありません'}}
                  </mat-error>
              </mat-form-field>
          </div>

          <div class="d-flex justify-content-center">
              <i class="fa fa-lock fa-2x"></i>&nbsp;
              <mat-form-field appearance="fill" class="registrationInputForm">
                  <mat-label>
                      {{this.router.url.includes('EN')==true?'Password': 'パスワード'}}
                  </mat-label>
                  <input matInput placeholder="" formControlName="myPassword" type="password" #myInputPassword
                      class="registrationInputBox">
                  <mat-error *ngIf="myFormGroup.get('myPassword').hasError('required')">
                      {{this.router.url.includes('EN')==true?'Password is required': '未入力'}}
                  </mat-error>
              </mat-form-field>
          </div>

          <div *ngIf="!loaded">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <div *ngIf="!loaded" class="progressMessage">
              {{this.router.url.includes('EN')==true?'Searching ...':'検索中 ...'}}
          </div>
          <div class="divUpdateButton  d-flex justify-content-center">
              <button mat-button (click)="onCheckLogin()" class="btnNext" cdkFocusInitial>
                  {{this.router.url.includes('EN')==true?'Login':'ログイン'}}
              </button>
          </div>
          <button mat-button (click)="onClickForgotPassword()" class="btn btn-link">
              {{this.router.url.includes('EN')==true?'Password Forgot?': 'パスワードを忘れた場合'}}
          </button>
          <button mat-button  (click)="onClickCreateUserNew()" class="btn btn-link float-end">
              {{this.router.url.includes('EN')==true?'Create New Account': '新規作成'}}
          </button>
      </form>
  </mat-card>
</div>
<div class="memoForDevelopper">
  dialog/GRC/login-grc
</div>
