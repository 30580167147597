<div class="myBody myBodySub">
  <div class="myHeaderBase d-flex justify-content-center">
      <div class="myHeaderCore">
          <div>
               <!-- <img src='../../../../../assets/images/GRC23.png' class="grcLogo"> -->
          </div>
      </div>
  </div>
  <div class="myMain">
      <div clas="myMainLeft">
          <div class="myMainHalfTop">
              <!-- Left Top -->
          </div>
          <div class="myMainHalfBottom">
              <!-- Left Bottom -->
          </div>
      </div>
      <div class="myMainCenter">
          <div class="myMainHalfTop d-flex justify-content-center">
              <!-- Main Top -->

              <mat-card class="mainCard b-radius">
                  <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
                  <div *ngIf="!loaded">
                      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                  </div>

                  <!-- <form *ngIf="userFromServer | async; else loading"  -->
                      <form
                      [formGroup]="myFormGroup"
                      (ngSubmit)="onSubmit()"
                      >
                      <div class="float-end stepNumber">3/4</div>
                      <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Personal Information':
                          '個人情報'}}
                          <i class="stepTitleSub">
                              {{this.router.url.includes('EN')==true?'* ... Required': '* ... 必須項目'}}
                          </i>
                      </h2>

                      <div class="stepTitle2nd">
                      {{this.router.url.includes('EN')==true?'Address *':
                      '住所 *'}}
                      </div>
                      <div class="registrationInputFormLg">
                          <mat-radio-group formControlName="myCountry"
                              (change)="onChangeCountrySelection($event)" class="radioBtnMain" >
                              <mat-radio-button class="radioBtnItem" value="Japan">
                                  {{this.router.url.includes('EN')==true?'Japan': '日本'}}</mat-radio-button>
                              <mat-radio-button class="radioBtnItem" value="United States">
                                  {{this.router.url.includes('EN')==true?'United States': 'アメリカ'}}</mat-radio-button>
                              <mat-radio-button class="radioBtnItem"  value="Other">
                                  {{this.router.url.includes('EN')==true?'Other': '他'}}
                              </mat-radio-button>
                          </mat-radio-group>
                          <mat-error *ngIf="myFormGroup.get('myCountry').hasError('required') && myFormGroup.get('myCountry').touched">
                              {{this.router.url.includes('EN')==true?'Country is required': '未入力'}}
                          </mat-error>
                      </div>
                      <br>
                      <div *ngIf="showUSAddress">
                          <mat-form-field appearance="outline" class="registrationInputForm">
                              <mat-label>
                                  {{this.router.url.includes('EN')==true?'Street': 'ストリート'}}
                              </mat-label>
                              <input matInput placeholder="" formControlName="myStreet"
                                  required class="registrationInputBox">
                              <mat-error *ngIf="myFormGroup.get('myStreet').hasError('required')">
                                  {{this.router.url.includes('EN')==true?'Street is required': '未入力'}}
                              </mat-error>
                          </mat-form-field>

                          <mat-form-field appearance="outline" class="registrationInputForm">
                              <mat-label>
                                  {{this.router.url.includes('EN')==true?'City': '市'}}
                              </mat-label>
                              <input matInput placeholder="" formControlName="myCity"
                                  class="registrationInputBox">
                              <mat-error *ngIf="myFormGroup.get('myCity').hasError('required')">
                                  {{this.router.url.includes('EN')==true?'City is required': '未入力'}}
                              </mat-error>
                          </mat-form-field>

                          <mat-form-field appearance="outline" class="registrationInputForm">
                              <mat-label>
                                  {{this.router.url.includes('EN')==true?'State': '州'}}
                              </mat-label>
                              <mat-select placeholder="" formControlName="myState"
                                  class="registrationInputBoxDDL">
                                  <mat-option *ngFor="let state of states" [value]="state.value" class="matInputBox">
                                      {{ state.display }}
                                  </mat-option>
                              </mat-select>
                              <mat-error *ngIf="myFormGroup.get('myState').hasError('required')">
                                  {{this.router.url.includes('EN')==true?'State is required': '未入力'}}
                              </mat-error>
                          </mat-form-field>

                          <mat-form-field appearance="outline" class="registrationInputForm">
                              <mat-label>
                                  {{this.router.url.includes('EN')==true?'Zip': 'Zip'}}
                              </mat-label>
                              <input matInput placeholder="" formControlName="myZip"
                                  class="registrationInputBox">
                              <mat-error *ngIf="myFormGroup.get('myZip').hasError('required')">
                                  {{this.router.url.includes('EN')==true?'Zip is required': '未入力'}}
                              </mat-error>
                          </mat-form-field>
                      </div>

                      <div *ngIf="showJapanAddress">
                          <mat-form-field appearance="outline" class="registrationInputForm">
                              <mat-label>
                                  {{this.router.url.includes('EN')==true?'Yubin Bango (=Zip)': '郵便番号'}}
                              </mat-label>
                              <input matInput placeholder="" formControlName="myYubin_Bango"
                                  class="registrationInputBox">
                              <mat-error *ngIf="myFormGroup.get('myYubin_Bango').hasError('required')">
                                  {{this.router.url.includes('EN')==true?'Yubin Bango is required': '未入力'}}
                              </mat-error>
                          </mat-form-field>

                          <mat-form-field appearance="outline" class="registrationInputForm">
                              <mat-label>
                                  {{this.router.url.includes('EN')==true?'ToDoFuKen (=State)': '都道府県'}}
                              </mat-label>
                              <div *ngIf="this.router.url.includes('EN')==true">
                                  <mat-select placeholder="" formControlName="myTo_Do_Fu_Ken"
                                       class="registrationInputBox">
                                      <mat-option *ngFor="let to_do_fu_ken of to_do_fu_kensEN"
                                          [value]="to_do_fu_ken.value" class="matInputBox">
                                          {{ to_do_fu_ken.display }}
                                      </mat-option>
                                  </mat-select>
                              </div>
                              <div *ngIf="this.router.url.includes('EN')==false">
                                  <mat-select placeholder="" formControlName="myTo_Do_Fu_Ken"
                                       class="registrationInputBox">
                                      <mat-option *ngFor="let to_do_fu_ken of to_do_fu_kensJP"
                                          [value]="to_do_fu_ken.value" class="matInputBox">
                                          {{ to_do_fu_ken.display }}
                                      </mat-option>
                                  </mat-select>
                              </div>
                              <mat-error *ngIf="myFormGroup.get('myTo_Do_Fu_Ken').hasError('required')">
                                  {{this.router.url.includes('EN')==true?'To_Do_Fu_Ken is required': '未入力'}}
                              </mat-error>
                          </mat-form-field>

                          <mat-form-field appearance="outline" class="registrationInputForm">
                              <mat-label>
                                  {{this.router.url.includes('EN')==true?'Shi_Gun_Ku (=City)': '市郡区'}}
                              </mat-label>
                              <input matInput placeholder="" formControlName="myShi_Gun_Ku"
                                 class="registrationInputBox">
                              <mat-error *ngIf="myFormGroup.get('myShi_Gun_Ku').hasError('required')">
                                  {{this.router.url.includes('EN')==true?'Shi_Gun_Ku is required': '未入力'}}
                              </mat-error>
                          </mat-form-field>

                          <mat-form-field appearance="outline" class="registrationInputForm">
                              <mat-label>
                                  {{this.router.url.includes('EN')==true?'Cho_Son (=Street)': '町村　丁目番地'}}
                              </mat-label>
                              <input matInput placeholder="" formControlName="myCho_Son"
                                  class="registrationInputBox">
                              <mat-error *ngIf="myFormGroup.get('myCho_Son').hasError('required')">
                                  {{this.router.url.includes('EN')==true?'Cho_Son is required': '未入力'}}
                              </mat-error>
                          </mat-form-field>

                          <mat-form-field appearance="outline" class="registrationInputForm">
                              <mat-label>
                                  {{this.router.url.includes('EN')==true?'Apartment_Etc': 'アパート名等'}}
                              </mat-label>
                              <input matInput placeholder="" formControlName="myApartment_Etc"
                                   class="registrationInputBox">
                          </mat-form-field>
                      </div>

                      <div *ngIf="showOtherAddress">
                          <mat-form-field appearance="outline" class="registrationInputForm">
                              <mat-label>
                                  {{this.router.url.includes('EN')==true?'Street': 'ストリート'}}
                              </mat-label>
                              <input matInput placeholder="" formControlName="myStreet"
                                  required class="registrationInputBox">
                              <mat-error *ngIf="myFormGroup.get('myStreet').hasError('required')">
                                  {{this.router.url.includes('EN')==true?'Street is required': '未入力'}}
                              </mat-error>
                          </mat-form-field>

                          <mat-form-field appearance="outline" class="registrationInputForm">
                              <mat-label>
                                  {{this.router.url.includes('EN')==true?'City': '市'}}
                              </mat-label>
                              <input matInput placeholder="" formControlName="myCity"
                                  class="registrationInputBox">
                              <mat-error *ngIf="myFormGroup.get('myCity').hasError('required')">
                                  {{this.router.url.includes('EN')==true?'City is required': '未入力'}}
                              </mat-error>
                          </mat-form-field>

                          <mat-form-field appearance="outline" class="registrationInputForm">
                              <mat-label>
                                  {{this.router.url.includes('EN')==true?'State': '州'}}
                              </mat-label>

                              <input matInput placeholder="" formControlName="myState"
                                  required class="registrationInputBox">
                              <mat-error *ngIf="myFormGroup.get('myState').hasError('required')">
                                  {{this.router.url.includes('EN')==true?'State is required': '未入力'}}
                              </mat-error>
                          </mat-form-field>

                          <mat-form-field appearance="outline" class="registrationInputForm">
                              <mat-label>
                                  {{this.router.url.includes('EN')==true?'Zip': 'Zip'}}
                              </mat-label>
                              <input matInput placeholder="" formControlName="myZip"
                                  class="registrationInputBox">
                              <mat-error *ngIf="myFormGroup.get('myZip').hasError('required')">
                                  {{this.router.url.includes('EN')==true?'Zip is required': '未入力'}}
                              </mat-error>
                          </mat-form-field>

                          <mat-form-field appearance="outline" class="registrationInputForm">
                              <mat-label>
                                  {{this.router.url.includes('EN')==true?'Country': '国'}}
                              </mat-label>
                              <input matInput placeholder="" formControlName="myCountry"
                                  class="registrationInputBox" (change)="onUpdateOtherCountry(user.Country)">
                              <mat-error *ngIf="myFormGroup.get('myCountry').hasError('required')">
                                  {{this.router.url.includes('EN')==true?'Country is required': '未入力'}}
                              </mat-error>
                          </mat-form-field>
                      </div>

                      <div class=" position-absolute bottom-0 start-50 translate-middle-x">
                          <div class="d-flex justify-content-center">
                              <button type="button" class="btn btnNext" (click)="onClickGoBack()">
                                  {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                              </button>
                              <button [disabled]="!myFormGroup.valid" type="button" class="btn btnNext"
                                  (click)="onClickGoForward()">
                                  {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                              </button>
                          </div>
                          <div class="d-flex justify-content-center">
                              <button type="button" class="btn link btnCancel"
                                  (click)="cancelProcess()">Cancel</button>
                          </div>
                      </div>
                      <!-- <button type="submit" [disabled]="!myFormGroup.valid">Submit</button> -->
                  </form>
              </mat-card>
          </div>
          <div class="myMainHalfBottom">
              <!-- Main Bottom -->
          </div>
      </div>
      <div class="myMainRight">
          <div class="myMainHalfTop">
              <!-- Right Top -->
          </div>
          <div class="myMainHalfBottom">
              <!-- Right Bottom -->
          </div>
      </div>
  </div>
  <!-- <footer>footer</footer> -->
</div>
