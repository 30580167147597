<div class="myBody">
  <div class="myHeaderBase d-flex justify-content-center">
    <div class="myHeaderCore">
      <div>
        <img src='../../../../../assets/images/thumbnail_eclogo.png'  class="ecLogo">
    </div>
    </div>
  </div>
  <div class="myMain">
    <div clas="myMainLeft">
      <div class="myMainHalfTop">
        <!-- Left Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Left Bottom -->
      </div>
    </div>
    <div class="myMainCenter">
      <div class="myMainHalfTop d-flex justify-content-center">
        <!-- Main Top -->

        <mat-card class="mainCard b-radius">
          <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
          <div *ngIf="!loaded">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>

          <form [formGroup]="myFormGroup" (ngSubmit)="onSubmit()">
            <div class="float-end stepNumber">1/2</div>
            <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Fee Calculation':
              '料金計算'}}
            </h2>

            <div *ngIf="showCalculation">
              <div class="d-flex justify-content-center">
                <div class="registrationCostDetail">
                  <div class="row summaryTable2">

                    <div class="row">
                    <div class="col-6 col-sm-6 col-md-3">
                      {{this.router.url.includes('EN')==true?'':''}}
                    </div>
                    <div class="col-6 col-sm-6 col-md-2 manType">
                      {{this.router.url.includes('EN')==true?'You':conference.FirstName}}
                    </div>
                    <div *ngIf="numberOfKidsUnder18>=1" class="col-6 col-sm-6 col-md-2 manType">
                      {{this.router.url.includes('EN')==true?'Child':kidNo1Name}}
                    </div>
                    <div *ngIf="numberOfKidsUnder18>=2" class="col-6 col-sm-6 col-md-2 manType">
                      {{this.router.url.includes('EN')==true?'Child':kidNo2Name}}
                    </div>
                    <div *ngIf="numberOfKidsUnder18>=3" class="col-6 col-sm-6 col-md-2 manType">
                      {{this.router.url.includes('EN')==true?'Child':kidNo3Name}}
                    </div>
                    </div>

                    <div  class="row">
                    <div>
                      {{this.router.url.includes('EN')==true?'Lodging:':'宿泊：'}}
                    </div>
                    <div class="col-6 col-sm-6 col-md-3">
                      <div class="summaryTitle">
                        {{this.router.url.includes('EN')==true?
                        (this.conference.Dec27 == 'StayDec27'? stayCount+1+'Days': stayCount+'Days')
                        :
                        (this.conference.Dec27 == 'StayDec27'? stayCount+1+'泊': stayCount+'泊')
                        }}
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-2">
                      <div class="summaryValue">
                        {{this.stayAmountMyself | currency}}
                      </div>
                    </div>
                    <div *ngIf="numberOfKidsUnder18>=1" class="col-6 col-sm-6 col-md-2">
                      <div class="summaryValue">
                        {{stayAmountKidNo1 | currency}}
                      </div>
                    </div>
                    <div *ngIf="numberOfKidsUnder18>=2" class="col-6 col-sm-6 col-md-2">
                      <div class="summaryValue">
                        {{stayAmountKidNo2 | currency}}
                      </div>
                    </div>
                    <div *ngIf="numberOfKidsUnder18>=3" class="col-6 col-sm-6 col-md-2">
                      <div class="summaryValue">
                        {{stayAmountKidNo3 | currency}}
                      </div>
                    </div>
                  </div>

                    <div  class="row">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Day trip:':'日帰り：'}}
                      </div>
                      <div class="col-6 col-sm-6 col-md-3">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          dayTrip+'Days'
                          :
                          dayTrip+'日'
                          }}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{noStayAmountMyself| currency}}
                        </div>
                      </div>
                      <div *ngIf="numberOfKidsUnder18>=1" class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{noStayAmountKidNo1| currency}}
                        </div>
                      </div>
                      <div *ngIf="numberOfKidsUnder18>=2" class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{noStayAmountKidNo2| currency}}
                        </div>
                      </div>
                      <div *ngIf="numberOfKidsUnder18>=3" class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{noStayAmountKidNo3| currency}}
                        </div>
                      </div>
                    </div>

                    <!-- <div *ngIf="conference.Dec27=='StayDec27'" class="row">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Stay EC Previous Day:':'前泊：'}}
                      </div>
                      <div class="col-6 col-sm-6 col-md-3">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          'Dec 27'
                          :
                          '12月27日'
                          }}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{'$80'}}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div *ngIf="numberOfKidsUnder18>=1 && !kidNo1WithNoCharge; else myBlank" class="summaryValue">
                          {{'$80'}}
                        </div>
                        <ng-template #myBlank>
                          {{''}}
                        </ng-template>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div  *ngIf="numberOfKidsUnder18>=2 && !kidNo2WithNoCharge; else myBlank" class="summaryValue">
                          {{'$80'}}
                        </div>
                        <ng-template #myBlank>
                          {{''}}
                        </ng-template>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div  *ngIf="numberOfKidsUnder18>=3  && !kidNo3WithNoCharge; else myBlank" class="summaryValue">
                          {{'$80'}}
                        </div>
                        <ng-template #myBlank>
                          {{''}}
                        </ng-template>
                      </div>
                    </div> -->

                    <!-- <div *ngIf="conference.MySleepingBag=='BorrowMySleepingBag'" class="row">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Sleeping Bag:':'寝袋：'}}
                      </div>
                      <div class="col-6 col-sm-6 col-md-3">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          conference.MySleepingBag=='BorrowMySleepingBag'?'I need':''
                          :
                          conference.MySleepingBag=='BorrowMySleepingBag'?'借りる':''
                          }}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div *ngIf="conference.MySleepingBag=='BorrowMySleepingBag'" class="summaryValue">
                          {{'$15'}}<br>
                        </div>
                      </div>
                    </div> -->

                    <!-- <div *ngIf="conference.BringExtraSleepingBag==true" class="row">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Extra Sleeping Bag:':'寝袋エクストラ：'}}
                      </div>
                      <div class="col-6 col-sm-6 col-md-3">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          conference.BringExtraSleepingBag==true?'I can rent out':''
                          :
                          conference.BringExtraSleepingBag==true?'貸し出せる':''
                          }}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{this.router.url.includes('EN')==true?
                          conference.BringExtraSleepingBag==true?'- $'+10*this.conference.CountExtraSleepingBag:''
                          :
                          conference.BringExtraSleepingBag==true?'- $'+10*this.conference.CountExtraSleepingBag:''
                          }}
                        </div>
                      </div>
                    </div> -->

                    <div *ngIf="conference.NeedCommingBus==true" class="row">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Bus to venue:':'会場へのバス：'}}
                      </div>
                      <div class="col-6 col-sm-6 col-md-3">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          conference.NeedCommingBus==true?'I need.':'I do not need.'
                          :
                          conference.NeedCommingBus==true?'必要':'不要'
                          }}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{'$50'}}
                        </div>
                      </div>
                      <div  class="col-6 col-sm-6 col-md-2">
                        <div *ngIf="numberOfKidsUnder18>=1  && !kidNo1WithNoCharge; else myBlank" class="summaryValue">
                          {{'$50'}}
                        </div>
                        <ng-template #myBlank>
                          {{''}}
                        </ng-template>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div  *ngIf="numberOfKidsUnder18>=2  && !kidNo2WithNoCharge; else myBlank" class="summaryValue">
                          {{'$50'}}
                        </div>
                        <ng-template #myBlank>
                          {{''}}
                        </ng-template>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div  *ngIf="numberOfKidsUnder18>=3  && !kidNo3WithNoCharge; else myBlank" class="summaryValue">
                          {{'$50'}}
                        </div>
                        <ng-template #myBlank>
                          {{''}}
                        </ng-template>
                      </div>
                    </div>

                    <div *ngIf="conference.NeedReturningBus==true" class="row">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Bus to airport:':'空港へのバス：'}}
                      </div>
                      <div class="col-6 col-sm-6 col-md-3">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          conference.NeedReturningBus==true?'I need.':'I do not need.'
                          :
                          conference.NeedReturningBus==true?'必要':'不要'
                          }}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{'$50'}}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div  *ngIf="numberOfKidsUnder18>=1  && !kidNo1WithNoCharge; else myBlank" class="summaryValue">
                          {{'$50'}}
                        </div>
                        <ng-template #myBlank>
                          {{''}}
                        </ng-template>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div  *ngIf="numberOfKidsUnder18>=2  && !kidNo2WithNoCharge; else myBlank"  class="summaryValue">
                          {{'$50'}}
                        </div>
                        <ng-template #myBlank>
                          {{''}}
                        </ng-template>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div  *ngIf="numberOfKidsUnder18>=3  && !kidNo3WithNoCharge; else myBlank" class="summaryValue">
                          {{'$50'}}
                        </div>
                        <ng-template #myBlank>
                          {{''}}
                        </ng-template>
                      </div>
                    </div>

                    <div  class="row">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Sub Total:':'小計：'}}
                      </div>
                      <div class="col-6 col-sm-6 col-md-3">
                        <div class="summaryTitle">

                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div class="summaryValue">
                          {{subTotalMyself| currency}}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div *ngIf="numberOfKidsUnder18>=1" class="summaryValue">
                          {{subTotalKidNo1| currency}}
                        </div>
                      </div>
                      <div  class="col-6 col-sm-6 col-md-2">
                        <div *ngIf="numberOfKidsUnder18>=2" class="summaryValue">
                          {{subTotalKidNo2| currency}}
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-2">
                        <div  *ngIf="numberOfKidsUnder18>=3" class="summaryValue">
                          {{subTotalKidNo3| currency}}
                        </div>
                      </div>
                    </div>

                    <div *ngIf="conference.ConferenceDiscountAmount!=null" class="row">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Discount :':'割引：'}}
                      </div>
                      <div class="col-9 col-sm-9 col-md-12">
                        <div class="discountValues">
                         - &nbsp;{{conference.ConferenceDiscountAmount | currency}}
                        </div>
                      </div>
                    </div>

                    <div *ngIf="conference.ApplyingScholarshipAmount!=null || conference.ApprovedScholarshipAmount!=null " class="row">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Scholarship :':'奨学金：'}}
                      </div>
                      <div class="col-6 col-sm-6 col-md-3">
                        <div class="summaryTitle">
                          {{this.router.url.includes('EN')==true?
                          ('Applied: '+ conference.ApplyingScholarshipAmount )
                          :
                          ('申請額: '+ conference.ApplyingScholarshipAmount)
                          }}
                        </div>
                      </div>
                      <div class="col-9 col-sm-9 col-md-12">
                        <div class="discountValues">
                          {{this.router.url.includes('EN')==true?
                          'Approved:':'承認額:'}}
                         - &nbsp;{{conference.ApprovedScholarshipAmount | currency}}
                        </div>
                      </div>
                    </div>

                    <div *ngIf="conference.ApplicationFeePaidDate!=null" class="row">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Application Fee Paid:':'申込金支払い済：'}}
                        {{conference.ApplicationFeePaidDate | date:'shortDate' }}
                      </div>
                      <div class="col-9 col-sm-9 col-md-12">
                        <div class="discountValues">
                          - &nbsp;{{conference.ApplicationFee | currency}}
                        </div>
                      </div>
                    </div>
                    <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                    <div>
                      {{this.router.url.includes('EN')==true?'Balance:':'残額：'}}
                    </div>
                    <div class="col-9 col-sm-9 col-md-12">
                      <div class="summaryValueTotal">
                        {{this.temporalBalanceAmount | currency}}
                      </div>
                    </div>

                    <div *ngIf="conference.ConferenceFeePaidDate!=null">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div >
                        {{this.router.url.includes('EN')==true?'Total Paid Amount :':'総支払い額：'}}
                        {{conference.ConferenceFeePaidDate | date:'shortDate' }}
                      </div>
                      <div class="col-9 col-sm-9 col-md-12">
                        <div class="paidValues">
                          - &nbsp;{{conference.ConferenceFeePaidAmount | currency}}
                        </div>
                      </div>
                    </div>

                    <div *ngIf="conference.ConferenceFeePaidDate!=null">
                      <hr class="col-6 col-sm-6 col-md-3 calculationDevider">
                      <div>
                        {{this.router.url.includes('EN')==true?'Due to Pay:':'残額：'}}
                      </div>
                      <div class="col-9 col-sm-9 col-md-12">
                        <div class="unPaidValues">
                          &nbsp;{{conference.ConferenceFee - conference.ConferenceFeePaidAmount | currency}}
                        </div>
                      </div>
                    </div>



                  </div>

                </div>
              </div>
            </div>

            <br>
            <div class="d-flex justify-content-center">
              <mat-form-field appearance="outline" class="registrationInputForm">
                <mat-label>
                  {{this.router.url.includes('EN')!=true?'支払い額を選んでください': 'Select the payment amount'}}
                </mat-label>
                <mat-select  placeholder=""
                  formControlName="myPaymentOption" class="matInputBox" ngDefaultControl
                  (selectionChange)="onSelectPaymentAmount($event.value)">
                  <div *ngIf="this.router.url.includes('EN')==true">
                    <mat-option *ngFor="let paymentOption of paymentOptionEN" [value]="paymentOption.value" class="matInputBox">
                      {{ paymentOption.display }}
                    </mat-option>
                  </div>
                  <div *ngIf="this.router.url.includes('EN')==false">
                    <mat-option *ngFor="let paymentOption of paymentOptionJP" [value]="paymentOption.value" class="matInputBox">
                      {{ paymentOption.display }}
                    </mat-option>
                  </div>
                </mat-select>
              </mat-form-field><br>
              <!-- <mat-error
                *ngIf="myFormGroup.get('myPaymentOption').hasError('required')  && myFormGroup.get('myPaymentOption').touched">
                {{this.router.url.includes('EN')==true?'Need to select': '未選択'}}
              </mat-error> -->
            </div>






            <div class="d-flex justify-content-center">
              <div class="processButtonSet">
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn btnProcess" (click)="onClickGoBack()">
                    {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                  </button>
                  <!-- <div *ngIf="conference.ConferenceFeePaidDate==null"> -->
                    <div>
                    <button  [disabled]="!myFormGroup.valid || this.conference.ConferenceFeePaidDate!=null"  mat-button (click)="onClickPaymentByPaypal()" class="btn btnProcess" cdkFocusInitial>
                      {{this.router.url.includes('EN')==true?'Process Payment':'支払いに進む'}}
                    </button>
                  </div>

                </div>
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn link btnCancel" (click)="cancelProcess()">
                    {{this.router.url.includes('EN')==true?'Cancel': 'キャンセル'}}
                  </button>
                </div>
              </div>
            </div>

          </form>


        </mat-card>
      </div>
      <div class="myMainHalfBottom">
        <!-- Main Bottom -->
      </div>
    </div>
    <div class="myMainRight">
      <div class="myMainHalfTop">
        <!-- Right Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Right Bottom -->
      </div>
    </div>
  </div>
  <!-- <footer>footer</footer> -->
</div>
