<div class="myBody  myBodySub">
  <div class="myHeaderBase d-flex justify-content-center">
    <div class="myHeaderCore">
      <div>
        <!-- <img src='../../../../../assets/images/thumbnail_ec22logo.jpg' class="ecLogo"> -->
      </div>
    </div>
  </div>
  <div class="myMain">
    <div clas="myMainLeft">
      <div class="myMainHalfTop">
        <!-- Left Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Left Bottom -->
      </div>
    </div>
    <div class="myMainCenter">
      <div class="myMainHalfTop d-flex justify-content-center">
        <!-- Main Top -->

        <mat-card class="mainCard b-radius">
          <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
          <div *ngIf="!loaded">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
            <!-- <form *ngIf="userFromServer | async; else loading"  -->
             <form
            [formGroup]="myFormGroup"
            (ngSubmit)="onSubmit()"
            >
            <div class="float-end stepNumber">4/4</div>
            <!-- <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Conference Information':
              'カンファレンス情報'}}
            </h2> -->

              <mat-card class="RegistrationCardList">
                <div class="jumbotron p-3 p-md-5 text-white rounded " id="registrationInfoSummaryHeader">
                  <div class="col-md-6 px-0">
                    <h4>{{this.router.url.includes('EN')==true?'Summary': 'サマリー'}}</h4>
                  </div>
                </div>
                <br><br>
                <div class="registrationInfoSummaryItem">
                  <h4> {{this.router.url.includes('EN')==true?'Small Group Language:': 'スモールグループ言語：'}}</h4>
                  <div class="row summaryTable">
                    <div class="col-10 col-sm-3 col-md-4">

                      <div class="summaryValue">
                        {{this.router.url.includes('EN')==true?
                        conference.SmallGroupLanguage
                        :
                        conference.SmallGroupLanguage=='Japanese'?'日本語':
                        (conference.SmallGroupLanguage=='English'?'英語':'バイリンガル')}}

                      </div>
                    </div>
                  </div>
                  <div class="divEditButton">
                    <button class="btn btn-info btn-lg btnEdit float-end" (click)="goToSmallGroupLanguage()">
                      {{this.router.url.includes('EN')==true?'Edit': '編集'}}
                    </button>
                  </div>
                </div>
                <br>
                <hr>

                <div >
                 <div class="registrationInfoSummaryItem">
                  <h4> {{this.router.url.includes('EN')==true?'Volunteer:' : 'ボランティア：'}}</h4>
                  <div class="row summaryTable">
                    <div class="col-10 col-sm-3 col-md-4">
                      <div class="summaryValue">
                        <div *ngFor = "let volunteer of conference.myGRCVolunteerSelections">
                          {{volunteer.VolunteerName}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="divEditButton">
                    <button class="btn btn-info btn-lg btnEdit float-end" (click)="goToVolunteers()">
                      {{this.router.url.includes('EN')==true?'Edit': '編集'}}
                    </button>
                  </div>
                </div>
                </div>
                <br>
                <hr>
                <div class="registrationInfoSummaryItem">
                  <h4> {{this.router.url.includes('EN')==true?'Couple:': '夫婦：'}}</h4>
                  <div class="row summaryTable">
                    <div class="col-10 col-sm-3 col-md-4">
                      <div class="summaryValue">
                        {{this.router.url.includes('EN')==true?
                        conference.Couple==true?'Yes':'No'
                        :
                        conference.Couple==true?'はい':'いいえ'}}
                      </div>
                    </div>
                  </div>
                  <div class="divEditButton">
                    <button class="btn btn-info btn-lg btnEdit float-end" (click)="goToStudent()">
                      {{this.router.url.includes('EN')==true?'Edit': '編集'}}
                    </button>
                  </div>
                </div>
                <br>
                <hr>
                <div class="registrationInfoSummaryItem">
                  <h4> {{this.router.url.includes('EN')==true?'Transportation:': 'トランスポーテーション：'}}</h4>
                   <div class="row summaryTable">
                   <div>
                    <b>
                      {{this.router.url.includes('EN')==true?'Coming:': '行き：'}}
                    </b>
                   </div>
                    <div class="col-10 col-sm-4 col-md-4">
                      <!-- <div class="summaryTitle">
                        {{this.router.url.includes('EN')==true?
                        'Do you need Bus transportation to the venue?'
                        :
                        '会場までのバス送迎必要ですか？'}}
                      </div> -->
                      <div class="summaryValue">
                        {{this.router.url.includes('EN')==true?
                        conference.TransportationMethodComing
                        :
                        (conference.TransportationMethodComing=='Car'?'車':
                        (conference.TransportationMethodComing=='Train'?'電車':
                        (conference.TransportationMethodComing=='GRC direct bus'?'GRC直行バス':
                        (conference.TransportationMethodComing=='Express bus'?'高速バス':''))))}}
                      </div>
                    </div>

                  </div>
                  <div class="row summaryTable">
                    <div>
                     <b>
                       {{this.router.url.includes('EN')==true?'Returning:': '帰り：'}}
                     </b>
                    </div>
                     <div class="col-10 col-sm-4 col-md-4">
                       <!-- <div class="summaryTitle">
                         {{this.router.url.includes('EN')==true?
                         'Do you need Bus transportation to LAX?'
                         :
                         '空港までのバス送迎必要ですか？'}}
                       </div> -->
                       <div class="summaryValue">
                        {{this.router.url.includes('EN')==true?
                        conference.TransportationMethodReturning
                        :
                        (conference.TransportationMethodReturning=='Car'?'車':
                        (conference.TransportationMethodReturning=='Train'?'電車':
                        (conference.TransportationMethodReturning=='GRC direct bus'?'GRC直行バス':
                        (conference.TransportationMethodReturning=='Express bus'?'高速バス':''))))}}
                      </div>
                     </div>

                   </div>

                  <div class="divEditButton">
                    <button class="btn btn-info btn-lg btnEdit float-end" (click)="goToTransportation()">
                      {{this.router.url.includes('EN')==true?'Edit': '編集'}}
                    </button>
                  </div>
                </div>
                <br>


                <hr>
                <div class="registrationInfoSummaryItem">
                  <h4> {{this.router.url.includes('EN')==true?'Room Info:': 'ルーム：'}}</h4>
                  <div class="row summaryTable">
                    <div class="col-10 col-sm-3 col-md-10">
                      <div class="summaryValue">
                      {{this.router.url.includes('EN')==true?
                      conference.RoomInfo=='AnyoneIsFine'?'Anyone is fine.':
                      (conference.RoomInfo=='WithFamily'?'With Family':'Share with others')
                      :
                      conference.RoomInfo=='AnyoneIsFine'?'誰でも良し':
                      (conference.RoomInfo=='WithFamily'?'家族と同室':'その他と同室')
                    }}
　                      <br>
                         <div *ngFor = "let person of conference.myGRCRelatedPersons">
                          {{person.FirstNameRelated}} {{person.LastNameRelated}} ,
                          {{person.MyojiRelated}}  {{person.ShimeiRelated}} -
                          {{person.DOBRelated | date: 'MM/dd/yyyy'}} -
                          {{person.ChildCare==true?(this.router.url.includes('EN')==true?'Need Child Care':'チャイルドケア必要'):'' }} -
                          {{this.router.url.includes('EN')==true?
                            person.GenderRelated
                            :
                            person.GenderRelated=='Woman'?'女性':(person.GenderRelated=='Man'?'男性':'')}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="divEditButton">
                    <button class="btn btn-info btn-lg btnEdit float-end" (click)="goToRoomInfo()">
                      {{this.router.url.includes('EN')==true?'Edit': '編集'}}
                    </button>
                  </div>
                </div>
                <br>
                <hr>
                <div class="registrationInfoSummaryItem">
                  <h4> {{this.router.url.includes('EN')==true?'Participation Schedule:': '参加日程：'}}</h4>
                  <div >

                        <div class="row summaryTable">
                          <div class="col summaryTitle">
                            {{this.router.url.includes('EN')==true?'May 3 Wed': '5月3日 (水)'}}
                          </div>
                           <div class="col summaryValue">
                              <div *ngIf="conference.May3=='NoParticipateMay3'">
                                  {{this.router.url.includes('EN')==true?'No Participate': '参加しない。'}}
                              </div>
                              <div *ngIf="conference.May3=='NoStayMay3'">
                                  {{this.router.url.includes('EN')==true?'No Stay': '日帰り'}}
                              </div>
                              <div *ngIf="conference.May3=='StayMay3'">
                                  {{this.router.url.includes('EN')==true?'Stay': '宿泊'}}
                              </div>
                          </div>
                        </div>
                        <div class="row summaryTable">
                          <div class="col summaryTitle">
                            {{this.router.url.includes('EN')==true?'May 4 Thu': '5月4日 (木)'}}
                          </div>
                           <div class="col summaryValue">
                              <div *ngIf="conference.May4=='NoParticipateMay4'">
                                  {{this.router.url.includes('EN')==true?'No Participate': '参加しない。'}}
                              </div>
                              <div *ngIf="conference.May4=='NoStayMay4'">
                                  {{this.router.url.includes('EN')==true?'No Stay': '日帰り'}}
                              </div>
                              <div *ngIf="conference.May4=='StayMay4'">
                                  {{this.router.url.includes('EN')==true?'Stay': '宿泊'}}
                              </div>
                          </div>
                        </div>
                        <div class="row summaryTable">
                          <div class="col summaryTitle">
                            {{this.router.url.includes('EN')==true?'May 5 Thu': '5月5日 (金)'}}
                          </div>
                           <div class="col summaryValue">
                              <div *ngIf="conference.May5=='NoParticipateMay5'">
                                  {{this.router.url.includes('EN')==true?'No Participate': '参加しない。'}}
                              </div>
                              <div *ngIf="conference.May5=='NoStayMay5'">
                                  {{this.router.url.includes('EN')==true?'No Stay': '日帰り'}}
                              </div>
                              <div *ngIf="conference.May5=='StayMay5'">
                                  {{this.router.url.includes('EN')==true?'Stay': '宿泊'}}
                              </div>
                          </div>
                        </div>
                        <div class="row summaryTable">
                          <div class="col summaryTitle">
                            {{this.router.url.includes('EN')==true?'May 6 Thu': '5月6日 (土)'}}
                          </div>
                           <div class="col summaryValue">
                              <div *ngIf="conference.May6=='NoParticipateMay6'">
                                  {{this.router.url.includes('EN')==true?'No Participate': '参加しない。'}}
                              </div>
                              <div *ngIf="conference.May6=='NoStayMay6'">
                                  {{this.router.url.includes('EN')==true?'No Stay': '日帰り'}}
                              </div>
                              <div *ngIf="conference.May6=='StayMay6'">
                                  {{this.router.url.includes('EN')==true?'Stay': '宿泊'}}
                              </div>
                          </div>
                        </div>
                  </div>
                  <div class="divEditButton">
                    <button class="btn btn-info btn-lg btnEdit float-end" (click)="goToParticipationSchedule()">
                      {{this.router.url.includes('EN')==true?'Edit': '編集'}}
                    </button>
                  </div>
                </div>

                <br>

              </mat-card>
              <br>


              <div *ngIf="!loaded">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
              </div>
              <div *ngIf="!loaded" class="progressMessage">
                {{this.router.url.includes('EN')==true?'Saving...': '保存中...'}}
              </div>

            <!-- <div class=" position-absolute bottom-0 start-50 translate-middle-x"> -->
              <div class="d-flex justify-content-center">
                <button type="button" class="btn btnNext" (click)="onClickGoBack()">
                  {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                </button>
                <button  [disabled]="!myFormGroup.valid" type="button" class="btn btnNext" (click)="onClickGoForward()">
                  {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                </button>
              </div>
              <div class="d-flex justify-content-center">
                <button type="button" class="btn link btnCancel" (click)="cancelProcess()">Cancel</button>
              </div>
            <!-- </div> -->

          </form>
           <!-- <button type="submit" [disabled]="!myFormGroup.valid">Submit</button> -->
        </mat-card>
      </div>
      <div class="myMainHalfBottom">
        <!-- Main Bottom -->
      </div>
    </div>
    <div class="myMainRight">
      <div class="myMainHalfTop">
        <!-- Right Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Right Bottom -->
      </div>
    </div>
  </div>
  <!-- <footer>footer</footer> -->
</div>
