import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
@Component({
  selector: 'app-successfully-processed-ec',
  templateUrl: './successfully-processed-ec.component.html',
  styleUrls: ['./successfully-processed-ec.component.css']
})
export class SuccessfullyProcessedECComponent implements OnInit {
  mySelectedLanguage:string;

  constructor(
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.mySelectedLanguage = this.router.url.includes('EN')==true?"EN":"JP";
  }
}
