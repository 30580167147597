<div class="body">
    <div class="myHeaderBase d-flex justify-content-center">
        <div class="myHeaderCore">
            <h2>
                EC2022
            </h2>

        </div>
    </div>


    <!-- <div class="myMainBase d-flex justify-content-center">
        <div class="myMainCore">

            <mat-card class="mainCard2 b-radius">Simple card</mat-card>
        </div>
    </div>

    <hr> -->

    <div class="main d-flex justify-content-center">
        <div class="mainCore" >
            <div class="myMainHalfTop">
                Main Top
                <mat-card class="mainCard b-radius">Simple card</mat-card>
            </div>
            <div class="myMainHalfBottom">
                Main Bottom
            </div>
        </div>
    </div>
    <footer>footer</footer>
</div>

