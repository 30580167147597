<div class="myBody">
    <div class="myHeaderBase d-flex justify-content-center">
        <div class="myHeaderCore">
            <div>
                <img src='../../../../../assets/images/thumbnail_eclogo.png' class="ecLogo">
            </div>
        </div>
    </div>
    <div class="myMain">
        <div clas="myMainLeft">
            <div class="myMainHalfTop">
                <!-- Left Top -->
            </div>
            <div class="myMainHalfBottom">
                <!-- Left Bottom -->
            </div>
        </div>
        <div class="myMainCenter">
            <div class="myMainHalfTop d-flex justify-content-center">
                <!-- Main Top -->

                <mat-card class="mainCard b-radius">
                    <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
                    <div *ngIf="!loaded">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </div>
                    <!-- <form *ngIf="userFromServer | async; else loading"  -->
                    <form [formGroup]="myFormGroup" (ngSubmit)="onSubmit()">
                        <div class="float-end stepNumber">7/12</div>
                        <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Student, Couple':
                            '学生, 夫婦'}}
                            <i class="stepTitleSub">
                                {{this.router.url.includes('EN')==true?'Required': '必須'}}
                            </i>
                        </h2>
                        <div class="stepTitle3rd">
                            {{this.router.url.includes('EN')==true?'1. Are you a student?':
                            '1. 学生ですか？'}}
                        </div>
                        <div *ngIf="loaded" class="d-flex justify-content-center">
                            <mat-radio-group formControlName="myStudent" (change)="onChangeStudentSelection($event)"
                                class="rbGroup">
                                <mat-radio-button value=true class="rbItem"
                                [checked]="conference.Student==true"
                                >
                                    {{this.router.url.includes('EN')==true?'Yes': 'はい'}}</mat-radio-button>
                                <mat-radio-button value=false class="rbItem"
                                [checked]="conference.Student==false"
                                >
                                    {{this.router.url.includes('EN')==true?'No': 'いいえ'}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <mat-error
                            *ngIf="myFormGroup.get('myStudent').hasError('required')  && myFormGroup.get('myStudent').touched">
                            {{this.router.url.includes('EN')==true?'Program Type is required': '未入力'}}
                        </mat-error>
                        <br><br><br>

                        <div class="stepTitle3rd">
                          {{this.router.url.includes('EN')==true?'2. Are you and your spouse attending this conference?':
                          '2. 夫婦で参加しますか？'}}
                        </div>
                        <div *ngIf="loaded" class="d-flex justify-content-center">
                          <mat-radio-group formControlName="myCouple" (change)="onChangeCoupleSelection($event)"
                              class="rbGroup">
                              <mat-radio-button value=true class="rbItem"
                              [checked]="conference.Couple==true"
                              >
                                  {{this.router.url.includes('EN')==true?'Yes': 'はい'}}</mat-radio-button>
                              <mat-radio-button value=false class="rbItem"
                              [checked]="conference.Couple==false"
                              >
                                  {{this.router.url.includes('EN')==true?'No': 'いいえ'}}</mat-radio-button>
                          </mat-radio-group>
                      </div>
                      <mat-error
                          *ngIf="myFormGroup.get('myCouple').hasError('required')  && myFormGroup.get('myCouple').touched">
                          {{this.router.url.includes('EN')==true?'Program Type is required': '未入力'}}
                      </mat-error>



                        <div class=" position-absolute bottom-0 start-50 translate-middle-x">
                            <div class="d-flex justify-content-center">
                                <button type="button" class="btn btnProcess" (click)="onClickGoBack()">
                                    {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                                </button>
                                <button [disabled]="!myFormGroup.valid" type="button" class="btn btnProcess"
                                    (click)="onClickGoForward()">
                                    {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                                </button>
                            </div>
                            <div class="d-flex justify-content-center">
                                <button type="button" class="btn link btnCancel"
                                    (click)="cancelProcess()">
                                    {{this.router.url.includes('EN')==true?'Cancel': 'キャンセル'}}
                                  </button>
                            </div>
                        </div>
                        <!-- <button type="submit" [disabled]="!myFormGroup.valid">Submit</button> -->
                    </form>
                </mat-card>
            </div>
            <div class="myMainHalfBottom">
                <!-- Main Bottom -->
            </div>
        </div>
        <div class="myMainRight">
            <div class="myMainHalfTop">
                <!-- Right Top -->
            </div>
            <div class="myMainHalfBottom">
                <!-- Right Bottom -->
            </div>
        </div>
    </div>
    <!-- <footer>footer</footer> -->
</div>
