<div class="WelcomeBase">
  <br><br>
  <div class="btnBack">
      <button type="button" class="btn btn-link backButton" (click)="onClickBack()">
          <i class="fas fa-times-circle fa-2x"></i>
      </button>
  </div>
  <div class="d-flex justify-content-center">
     <!-- {{this.router.url.includes('EN')==true?'Payment Amount $: '+
     ((conference.ApplicationFee!=null && conference.ApplicationFeePaidDate==null)?conference.ApplicationFee:(
      conference.ApplicationFeePaidDate!=null?(conference.ConferenceFee - conference.ApplicationFee):conference.ConferenceFee
     ))
     :
     '支払い額 $: ' +
   ((conference.ApplicationFee!=null && conference.ApplicationFeePaidDate==null)?conference.ApplicationFee:(
      conference.ApplicationFeePaidDate!=null?(conference.ConferenceFee - conference.ApplicationFee):conference.ConferenceFee
     ))
    }} -->
    {{this.router.url.includes('EN')==true?'Payment Amount $: '+conference.PayingAmount:'支払い額 $: ' +conference.PayingAmount}}
  </div>
<br><br>

  <div class="divPaypal">
      <div id="myPaypalContainer"></div>
      <div #paypal></div>
  </div>


</div>
<!-- <div class="memoForDevelopper">
 pay-by-paypal
</div> -->
