
<h1 mat-dialog-title>{{ this.router.url.includes('EN')==true?"Delete Confirmation":"削除確認"}}</h1>
<div mat-dialog-content>
 <mat-label>
  {{this.router.url.includes('EN')==true?"Name":"名前 "}}&nbsp;{{this.data.TemplateName}}
 </mat-label>
 <div class="row">
  <div>
  </div>
  <div>
   {{ this.router.url.includes('EN')==true?"Are you sure to delete?":"削除しますか？"}}
  </div>
 </div>
</div>
<div mat-dialog-actions>
 <button mat-button (click)="onNoClick()">
  {{ this.router.url.includes('EN')==true?"Cancel":"キャンセル"}}</button>
 <button mat-button [mat-dialog-close]="data.TemplateName" cdkFocusInitial (click)="onDeleteClick()">
  {{ this.router.url.includes('EN')==true?"Delete Now":"実行"}}</button>
</div>