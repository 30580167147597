import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators,AbstractControl, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import {LoginGRCComponent} from "../../../dialog/GRC/login-grc/login-grc.component";

export interface DialogData {
  myLanguage:string,
  myEmail:string
}
@Component({
  selector: 'app-login-message-grc',
  templateUrl: './login-message-grc.component.html',
  styleUrls: ['./login-message-grc.component.css']
})
export class LoginMessageGRCComponent implements OnInit {
  loaded:boolean=true;
  firstFormGroup: FormGroup;
  myMessage:string;
  mySelectedLanguage:string;

  constructor(
    public router: Router,
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<LoginMessageGRCComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog
  ){}

  ngOnInit() {
    this.mySelectedLanguage = this.router.url.includes('EN')==true?"EN":"JP";
    this.firstFormGroup = this._formBuilder.group({
    });
  }

  getMyLanguage():string{
    return this.router.url.includes('EN')==true?'EN':'JP';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClickGoToLogin(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight='605px'; //Desktop
    dialogConfig.maxWidth='500px';//Desktop
    dialogConfig.height='100%';//Mobile
    dialogConfig.width='100%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
    dialogConfig.data = { myEmail: this.data.myEmail };
    const  dialogRef = this.dialog.open(LoginGRCComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var redirectPage = result;
      if(result!=null){
        console.log("Confirmed Action: "+redirectPage);
      }
    })
    this.dialogRef.close();

  }
}
