<div class="myBody  myBodySub">
  <div class="myHeaderBase d-flex justify-content-center">
    <div class="myHeaderCore">
      <div>
        <!-- <img src='../../../../../assets/images/thumbnail_ec22logo.jpg' class="ecLogo"> -->
      </div>
    </div>
  </div>
  <div class="myMain">
    <div clas="myMainLeft">
      <div class="myMainHalfTop">
        <!-- Left Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Left Bottom -->
      </div>
    </div>
    <div class="myMainCenter">
      <div class="myMainHalfTop d-flex justify-content-center">
        <!-- Main Top -->

        <mat-card class="mainCard b-radius">
          <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
          <div *ngIf="!loaded">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <form [formGroup]="myFormGroup" (ngSubmit)="onSubmit()">
            <div class="float-end stepNumber">5/8</div>
            <h2 class="stepTitle">{{this.router.url.includes('EN')==true?'Room Information':
              '同室希望'}}
              <i class="stepTitleSub">
                {{this.router.url.includes('EN')==true?'Required': '必須'}}
              </i>
            </h2>

            <div>

              {{this.router.url.includes('EN')==true?'Spouses and family members over the age of 13 (middle school student) must submit their own application. '+
              ' If you do not have an account, please Please create an account before applying. To create an account, you must have your own email address.'+
              ' If you do not have an email address, you will need to create one. If you do not have an email address, please create one and then register.'+
              ' If you do not have an email address, please create one before signing up. If you are unable to create an email address due to unavoidable circumstances, '+
              ' please let us know. Children under 12 years (elementary school student) of age do not have to register individually, but by entering their information in the table below, '+
              ' you are making a provisional application.':
              '配偶者と13歳（中学生）以上の家族は各自お申し込みを提出する必要があります。ｱｶｳﾝﾄのない方は、ｱｶｳﾝﾄを作成してからお申し込みください。ｱｶｳﾝﾄを作成する際には、'+
              '独自のEﾒｰﾙｱﾄﾞﾚｽを持つ必要があります。Eﾒｰﾙｱﾄﾞﾚｽをお持ちでない場合は、Eﾒｰﾙｱﾄﾞﾚｽを作成した後、ご登録ください。やむをえない事情で、'+
              'Eﾒｰﾙｱﾄﾞﾚｽの作成が出来ない方はご連絡ください。12歳（小学生）以下のお子様は個別に申し込みをされなくても、下の表にて情報を入力していただくことによって'+
              '仮申し込みをされた事になります。'}}<br>
               <div class="stepTitle2nd">
                {{
                  this.router.url.includes('EN')==true?
                  'Basically, room assignments are made by small groups.'
                  :
                  '基本的にスモールグループごとの部屋割になります。'
                }}
<span class="memoRed">
 {{
                  this.router.url.includes('EN')==true?
                  '「Please inquire about room assignments at the office: info@globalreturnees.org」'
                  :
                  '「部屋割についてのご相談は、事務局info@globalreturnees.orgまで」'
                }}
</span>

              </div>
              <br>
              <mat-radio-group formControlName="myRoomShareType" (change)="onChangemyRoomShareType($event)"
                class="rbGroup">
                <mat-radio-button value="AnyoneIsFine" class="rbItem" [checked]="conference.RoomInfo=='AnyoneIsFine'">
                  {{this.router.url.includes('EN')==true?'Anyone is fine.': '誰でも良し'}}
                </mat-radio-button>
                <mat-radio-button value="WithFamily" class="rbItem" [checked]="conference.RoomInfo=='WithFamily'">
                  {{this.router.url.includes('EN')==true?'With Family': '家族と同室'}}</mat-radio-button>
                 <mat-radio-button value="ShareWithOthers" class="rbItem"
                  [checked]="conference.RoomInfo=='ShareWithOthers'">
                  {{this.router.url.includes('EN')==true?'Share with others':
                  'その他と同室 '}}<br>
                   <!-- {{this.router.url.includes('EN')==true?'(List persons wishing to share a room-Up to 3 people)':
                  '（同室希望者名　3名まで）'}} -->
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <br>
            <div *ngIf="showRelatedPerson">
              <table class="table " formArrayName="relativePersonsOz">
                <tr *ngFor="let myRelative of relativePersonsOz().controls; let i=index" [formGroupName]="i">
                  <td>
                    <mat-form-field appearance="outline" class="registrationInputFormSm">
                      <mat-label>
                        {{this.router.url.includes('EN')==true?'Last Name - Kanji': '姓　漢字'}}
                      </mat-label>
                      <input matInput placeholder="" formControlName="MyojiRelated" class="registrationInputBox">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="registrationInputFormSm">
                      <mat-label>
                        {{this.router.url.includes('EN')==true?'First Name - Kanji': '名 漢字'}}
                      </mat-label>
                      <input matInput placeholder="" formControlName="ShimeiRelated" class="registrationInputBox">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="registrationInputFormSm">
                      <mat-label>
                        {{this.router.url.includes('EN')==true?'Last Name': '姓　ローマ字'}}
                      </mat-label>
                      <input matInput placeholder="" formControlName="LastNameRelated" class="registrationInputBox">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="registrationInputFormSm">
                      <mat-label>
                        {{this.router.url.includes('EN')==true?'First Name': '名　ローマ字'}}
                      </mat-label>
                      <input matInput placeholder="" formControlName="FirstNameRelated" class="registrationInputBox">
                    </mat-form-field>


                    <mat-form-field *ngIf="conference.RoomInfo=='WithFamily'" appearance="outline"
                      class="registrationInputFormMd">
                      <mat-label>
                        {{this.router.url.includes('EN')==true?'Date of Birth': '生年月日'}}
                      </mat-label>
                      <input matInput placeholder="MM/dd/yyyy" formControlName="DOBRelated"
                        (dateChange)="onChangeDOB($event,i)" [matDatepicker]="DOB" />
                      <mat-datepicker-toggle matSuffix [for]="DOB"></mat-datepicker-toggle>
                      <mat-datepicker #DOB></mat-datepicker>
                      <mat-error *ngIf="myFormGroup.get('DOBRelated').hasError('required')">
                        {{this.router.url.includes('EN')==true?'DOB is required': '未入力'}}
                      </mat-error>
                    </mat-form-field>
                    <span *ngIf="conference.RoomInfo=='WithFamily'" class="quesChildCare">
                      <mat-checkbox #needChildCare formControlName="ChildCare"
                      (change)="onChangeNeedChildCare(needChildCare.checked)">
                      {{this.router.url.includes('EN')==true?'Need':
                      'チャイルド'}}<br>
                      {{this.router.url.includes('EN')==true?'Child care':
                      'ケア必要'}}<br>
                      {{this.router.url.includes('EN')==true?'(age: 0-2)':
                      '(0～2歳)'}}<br>
                    </mat-checkbox>
                    </span>
                    <span *ngIf="conference.RoomInfo=='WithFamily'" class="quesChildCare">
                      <mat-checkbox #needChildProgram formControlName="ChildProgram"
                      (change)="onChangeNeedChildProgram(needChildProgram.checked)">
                      {{this.router.url.includes('EN')==true?'Need':
                      '子どもプログ'}}<br>
                      {{this.router.url.includes('EN')==true?'Child Program':
                      'ラム必要'}}<br>
                      {{this.router.url.includes('EN')==true?'(age: 3-12)':
                      '(3～12歳)'}}<br>
                    </mat-checkbox>
                    </span>
                    <mat-form-field *ngIf="conference.RoomInfo=='WithFamily'" appearance="outline"
                      class="registrationInputFormXSm">
                      <mat-label>{{this.router.url.includes('EN')==true?'Gender': '性別'}}</mat-label>
                      <mat-select placeholder="" formControlName="GenderRelated" class="matInputBox" ngDefaultControl>
                        <div *ngIf="this.router.url.includes('EN')==true">
                        <mat-option *ngFor="let gender of gendersEN" [value]="gender.value" class="matInputBox">
                          {{ gender.display }}
                        </mat-option>
                        </div>
                        <div *ngIf="this.router.url.includes('EN')!=true">
                          <mat-option *ngFor="let gender of gendersJP" [value]="gender.value" class="matInputBox">
                            {{ gender.display }}
                          </mat-option>
                        </div>

                      </mat-select>
                    </mat-form-field>
                    <span class="saveRelative">
                                         <button (click)="removePerson(i)" class="btn btnSave">
                      {{this.router.url.includes('EN')==true?'Remove': '削除'}}
                    </button>
                    </span>

                  </td>
                </tr>
                <tr>
                  <th width="150px"><button type="button" (click)="addPerson()" class="btn btnAdd">
                      {{this.router.url.includes('EN')==true?'+ Add Person': '+ 同伴者追加'}}
                    </button></th>
                </tr>
              </table>
            </div>
            <div class="d-flex justify-content-center">
              <div class="processButtonSet">
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn btnNext" (click)="onClickGoBack()">
                    {{this.router.url.includes('EN')==true?'Back': '戻る'}}
                  </button>
                  <button [disabled]="!myFormGroup.valid" type="button" class="btn btnNext"
                    (click)="onClickGoForward()">
                    {{this.router.url.includes('EN')==true?'Next': '次へ'}}
                  </button>
                </div>
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn link btnCancel" (click)="cancelProcess()">Cancel</button>
                </div>
              </div>
            </div>

            <!-- <button type="submit" [disabled]="!myFormGroup.valid">Submit</button> -->
          </form>
        </mat-card>
      </div>
      <div class="myMainHalfBottom">
        <!-- Main Bottom -->
      </div>
    </div>
    <div class="myMainRight">
      <div class="myMainHalfTop">
        <!-- Right Top -->
      </div>
      <div class="myMainHalfBottom">
        <!-- Right Bottom -->
      </div>
    </div>
  </div>
  <!-- <footer>footer</footer> -->
</div>
