import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth/auth.service";

import {MatMenuModule} from '@angular/material/menu'
import { EcRegistrationService } from 'src/app/services/conference/ec/ec-registration.service';
import { GrcRegistrationService} from 'src/app/services/conference/grc/grc-registration.service';
//For Dialog
import { MatDialog,MatDialogConfig} from "@angular/material/dialog";
import {LoginDialogComponent} from "../dialog/login-dialog/login-dialog.component";


@Component({
  selector: 'app-navbar-three',
  templateUrl: './navbar-three.component.html',
  styleUrls: ['./navbar-three.component.css']
})
export class NavbarThreeComponent implements OnInit {
  myMessage:string;
  mySelectedLanguage:string;


  constructor(
    public authService: AuthService,
    public router: Router,
    private dialog:MatDialog,
    public ecRegistrationService : EcRegistrationService,
    public grcRegistrationService:GrcRegistrationService
  ) {}

  ngOnInit() {
    this.mySelectedLanguage = this.router.url.includes('EN')==true?"EN":"JP";
  }

  onClickLogOut() {
    if (this.authService.logout()) {
      this.router.navigate(["/home"]);
    }
  }


  onClickRegistration(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
   // dialogConfig.maxHeight='590px'; //Desktop
    dialogConfig.maxWidth='600px';//Desktoop
  //dialogConfig.height='100%';//Mobile
    dialogConfig.width='95%';//Mobile
    dialogConfig.panelClass= 'custom-modalbox'; //Refer global level
 //   dialogConfig.data={ Id: this.user.Id };
    const  dialogRef = this.dialog.open(LoginDialogComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var redirectPage = result;
    //  this.redirectPage(this.codeMaster.CodeType);
      if(result!=null){
        console.log("Confirmed Action: "+redirectPage);
      }
    })
  }

  collapseNavbar() {
    const navbar = document.querySelector('.navbar-collapse');
    if (navbar && navbar.classList.contains('show')) {
      navbar.classList.remove('show');
    }
  }
}
