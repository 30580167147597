<div class="myBase">
  <div class="bg-layer">
    <div class="btnBack">
      <button type="button" class="btn btn-link" (click)="onClickClose()">
        <i class="fas fa-times-circle fa-2x"></i>&nbsp;&nbsp; {{this.router.url.includes('EN')==true?'Close': '閉じる'}}
      </button>
    </div>
    <div *ngIf="componentPage=='programType'">
      <app-program-type></app-program-type>
    </div>
    <div *ngIf="this.componentPage=='smallGroupLanguage'">
      <app-small-group-language></app-small-group-language>
    </div>
    <div *ngIf="this.componentPage=='japanRegionalMeeting'">
      <app-japan-regional-meeting></app-japan-regional-meeting>
    </div>
    <div *ngIf="this.componentPage=='volunteers'">
      <app-volunteers></app-volunteers>
    </div>
    <div *ngIf="this.componentPage=='englishPartners'">
      <app-english-partners></app-english-partners>
    </div>
    <div *ngIf="this.componentPage=='counseling'">
      <app-counseling></app-counseling>
    </div>
    <div *ngIf="this.componentPage=='student'">
      <app-student></app-student>
    </div>
    <div *ngIf="this.componentPage=='sleepingBag'">
      <app-sleeping-bag></app-sleeping-bag>
    </div>
    <div *ngIf="this.componentPage=='transportation'">
      <app-transportation></app-transportation>
    </div>
    <div *ngIf="this.componentPage=='roomInfo'">
      <app-room-info></app-room-info>
    </div>
    <div *ngIf="this.componentPage=='lodgingSchedule'">
      <app-lodging-schedule></app-lodging-schedule>
    </div>
    <div *ngIf="this.componentPage=='scholarship'">
      <app-scholarship></app-scholarship>
    </div>
    <div *ngIf="this.componentPage=='summaryConference'">
      <app-summary-conference></app-summary-conference>
    </div>
    <div *ngIf="this.componentPage=='termsOfParticipation'">
      <app-terms-of-participation></app-terms-of-participation>
    </div>
  </div>
 </div>
 <div class="memoForDevelopper d-flex justify-content-center">
  dialog/EC/registration-conference-embed
 </div>


